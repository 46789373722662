import { Grid } from '@material-ui/core'
import { getCauseTypeText } from 'property-taxation-edit-page/helper-functions'
import React from 'react'
import { TEXT_CAUSE } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

type CauseTypeProps = {
    causeType: string
    styles: any
}

export const CauseType: React.FC<CauseTypeProps> = (props) => {
    const { causeType, styles } = props
    return (
        <Grid container spacing={1} className={styles.innerContainer}>
            <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                {tr(TEXT_CAUSE)}:
            </Grid>
            <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                {getCauseTypeText(causeType)}
            </Grid>
        </Grid>
    )
}
