import { Grid } from '@material-ui/core'
// @ts-ignore
import Lightbox from 'react-lightbox-component'
import { LightboxImage } from 'models/lightbox-image'
import { Taxation } from 'models/taxation'
import { getComplaintPhotos } from 'property-taxation-edit-page/helper-functions'
import React from 'react'
import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import { ComplaintCause } from 'tribunal-page/models/complaint-cause.type'
import { TEXT_BUILDING_PHOTOS_LABEL } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { CauseType } from './cause-type'
import { ComplaintPanel } from './complaint-panel'
import { Complaint } from 'tribunal-page/models/complaint.type'

type BuildingPhotosComplaintProps = {
    styles: any
    cause: ComplaintCause<ComplaintCauseType.BuildingPhotos>
    taxation: Taxation
    complaint: Complaint
    index: number
    complaintIndex: number
}

export const BuildingPhotosComplaint: React.FC<BuildingPhotosComplaintProps> = (props) => {
    const { styles, cause, taxation, complaint, index, complaintIndex } = props

    return (
        <ComplaintPanel styles={styles} cause={cause} taxation={taxation} complaint={complaint} index={index} complaintIndex={complaintIndex}>
            <Grid container spacing={2} className={styles.panelBox}>
                <CauseType causeType={cause.type} styles={styles} />
                <Grid item xs={12} container spacing={2} className={styles.panelBox}>
                    <Grid item xs={12} className={styles.panelContent__label}>
                        {tr(TEXT_BUILDING_PHOTOS_LABEL)}:
                    </Grid>
                    <Lightbox
                        images={getComplaintPhotos(cause)}
                        thumbnailWidth="384px"
                        thumbnailHeight="216px"
                        renderImageFunc={(
                            id: number,
                            image: LightboxImage,
                            toggleLightbox: any,
                            width: string,
                            height: string,
                        ) => {
                            return (
                                <div
                                    key={id}
                                    className="lightbox-img-thumbnail  lightbox-img-thumbnail--cubit"
                                    style={{
                                        width: '100%',
                                        maxWidth: width,
                                        height: height,
                                        overflow: 'hidden',
                                        position: 'relative',
                                    }}
                                    onClick={toggleLightbox.bind(null, id)}
                                >
                                    <img
                                        src={image.src}
                                        alt={image.title}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translate(0, -50%)',
                                        }}
                                    />
                                </div>
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </ComplaintPanel>
    )
}
