import React, { useState } from 'react'
import { WrappedFieldArrayProps, submit, formValueSelector, reset, isPristine } from 'redux-form'
import { TribunalTaxationCommentDetails } from './tribunal-taxation-comment-details'
import { TribunalTaxationComment } from './tribunal-taxation-comment.type'
import { IconButton, Grid, Button, Popover } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useUserId, useUser } from 'auth/auth-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'
import { tr } from 'utils/translations/translate'
import { TEXT_CANCEL, TEXT_SAVE, TEXT_COMMENT_NOUN } from 'utils/translations/keys'
import { AppState } from 'app/app-store'
import { Permission } from 'common/enums/permission'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import classes from './tribunal-taxation-comments.module.css'
import { userHasPermission } from 'utils'

type TaxationPropertyBuildingsFieldArrayProps = {
    form: string
}

export const TribunalTaxationCommentsFieldArray: React.FC<WrappedFieldArrayProps<TribunalTaxationComment> &
    TaxationPropertyBuildingsFieldArrayProps> = props => {
    const { form, fields } = props
    const dispatch = useDispatch()

    const [addingComment, setAddingComment] = useState(false)

    const isFormPristine = useSelector(isPristine(form))

    const submitForm = () => {
        dispatch(submit(form))
        setAddingComment(false)
    }

    const handleCancelEdit = () => {
        dispatch(reset(form))
        setAddingComment(false)
    }

    const currentUser = useUser()
    const currentUserId = useUserId()

    const selector = formValueSelector(form)
    const state = useSelector((state: AppState) => state)
    const comments: TribunalTaxationComment[] = selector(state, fields.name)

    const canAddComment =
        !(comments && find(comments, { userId: currentUserId })) &&
        userHasPermission(currentUser, Permission.ViewTribunalReady) &&
        isFormPristine

    const canViewAllComments = userHasPermission(currentUser, Permission.ViewTribunalAll)

    const handleAddComment = () => {
        const comment = { userId: currentUserId } as TribunalTaxationComment
        setAddingComment(true)
        fields.push(comment)
    }

    const renderComments = () =>
        fields.map((commentFieldName: string, index: number) => (
            <TribunalTaxationCommentDetails
                key={index}
                commentIndex={index}
                commentFieldName={commentFieldName}
                comment={comments && comments[index]}
                form={form}
                addingNewComment={addingComment}
            />
        ))

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <>
            {canAddComment && (
                <>
                    <IconButton onClick={handleAddComment}>
                        <AddCircleIcon />
                    </IconButton>
                    {tr(TEXT_COMMENT_NOUN)}
                </>
            )}
            {canViewAllComments ? (
                comments &&
                comments.length > 0 && (
                    <>
                        <div className={classes.commentsCountWrap} onClick={handlePopoverOpen}>
                            <ChatBubbleIcon className={classes.commentsCountIcon}></ChatBubbleIcon>
                            <div className={classes.commentsCountText}>{comments.length}</div>
                        </div>

                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            style={{ padding: '8px' }}
                        >
                            <div className={classes.commentsWrapPopover}>{renderComments()}</div>
                        </Popover>
                    </>
                )
            ) : (
                <div className={classes.commentsWrapInline}>{renderComments()}</div>
            )}

            {addingComment && (
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={handleCancelEdit}>{tr(TEXT_CANCEL)}</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={submitForm} color="primary">
                            {tr(TEXT_SAVE)}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
