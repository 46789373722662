import React, { useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    makeStyles,
    Theme,
    Tab,
    Tabs,
    AppBar,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { tr } from 'utils/translations/translate'
import { TEXT_HOUSING_TAX, TEXT_TAX, TEXT_TAXATIONS } from 'utils/translations/keys'
import { getTaxationStatusTranslationKey } from 'utils'
import { getTaxationUrl, getTaxationType, Taxation, TaxationState, TaxationType } from 'models/taxation'
import { BuildingsTables } from './buildings-tables'
import { TaxationTables } from './taxation-tables'
import { ChevronRight } from '@material-ui/icons'
import { navigate } from '@reach/router'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { useSelector } from 'react-redux'
import { getTaxDate, getTaxYear } from './functions'
import { sortBy } from 'lodash'
import { TaxValue } from './tax-value'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
            flexWrap: 'wrap',
            paddingBottom: 0
        },
        panelSummary: {
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },
        panelOverrides: {
            marginBottom: 4,
            '&:before': {
                display: 'none',
            }
        },
        appBar: {
            borderRadius: '4px 4px 0 0',
            backgroundColor: theme.palette.primary.contrastText,
            marginBottom: 2,
        },
        tabsContainer: {
            color: '#7c7c7c',
            '& .Mui-selected': {
                color: theme.palette.primary.main,
            },
            '& .MuiTab-root': {
                fontWeight: 600,
                fontSize: 16,
            }
        },
        taxlistItem: {
            marginBottom: 3,
            padding: '0 24px',
            backgroundColor: theme.palette.primary.contrastText,
            height: 50,
            borderRadius: 4,
            cursor: 'pointer'
        },
        label: {
            fontSize: 18,
            fontWeight: 600
        },
        status: {

            fontSize: 16,
        },
        flexed: {
            display: 'flex',
        },
        icon: {
            color: '#7c7c7c',
        },
        bold: {
            fontWeight: 600
        }
    }
})

type TaxationsPanelsProps = {
    taxations: Taxation[]
}

export const TaxationsPanels: React.FC<TaxationsPanelsProps> = (props) => {
    const { taxations } = props
    const taxes = sortBy(
        taxations.filter(t => (t.state === TaxationState.TribunalFinished && ((t.start as any).substring(0, 4) < '2023')) || (t.type === TaxationType.SettingsUpdateResult && ((t.start as any).substring(0, 4) > '2022'))),
        'start'
    ).reverse()


    const sortedTaxations = sortBy(
        taxations.filter((taxation: Taxation) => !!taxation.start && taxation.type !== TaxationType.SettingsUpdateResult),
        'start'
    ).reverse()

    const styles = useStyles()
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    const [tabIndex, setTabIndex] = useState(1);
    const handleChange = (event: React.ChangeEvent<{}>, index: number) => {
        setTabIndex(index);
    };

    return (
        <div>
            <AppBar position="static" elevation={0} className={styles.appBar}>
                <Tabs value={tabIndex} onChange={handleChange} className={styles.tabsContainer}>
                    <Tab label={tr(TEXT_TAX)} />
                    <Tab label={tr(TEXT_TAXATIONS)} />
                </Tabs>
            </AppBar>
            <div
                role="tabpanel"
                hidden={tabIndex !== 0}
            >
                {taxes.map((taxation: Taxation, index: number) => (
                    <Accordion key={index} className={styles.panelOverrides} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.panelSummary}>
                            <Grid container justifyContent="space-between" alignItems="center" className={styles.bold}>
                                <Grid item>{`${getTaxYear(taxation)} ${tr(TEXT_HOUSING_TAX)}`}</Grid>
                                <Grid item>
                                    <TaxValue calculation={taxation.calculationDetails} taxation={taxation} />
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails className={styles.panelContent}>
                            <BuildingsTables taxation={taxation} />
                            <TaxationTables taxation={taxation} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
            <div
                role="tabpanel"
                hidden={tabIndex !== 1}
            >
                {sortedTaxations.map((taxation: Taxation, index: number) => (
                    <Grid container justifyContent="space-between" alignItems="center" key={index} className={styles.taxlistItem} onClick={() => navigate(getTaxationUrl(taxation))}>
                        <Grid item className={styles.label}>{`${getTaxDate(taxation, language)} ${getTaxationType(taxation)}`}</Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item className={styles.status}>{tr(getTaxationStatusTranslationKey(taxation.state))}</Grid>
                                <Grid item className={styles.flexed}>
                                    <ChevronRight className={styles.icon} />

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </div>
        </div>
    )
}
