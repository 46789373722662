import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import LayoutDefault from 'common/layout/layout-default'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionSearchSelectTab } from 'search-page/search-actions'
import { SearchFiltersPanel } from 'shared-components/src/search-filters-panel/search-filters-panel'
import { SearchResults } from './search-reducer'
import SearchToolbar from './search-toolbar/search-toolbar'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from '@reach/router'
import { useCompanies, useUsers } from 'app/app-selectors'
import { AppState } from 'app/app-store'
import { useUserId } from 'auth/auth-selectors'
import { attributeKeys } from 'common/attributes/attribute'
import { actionGetAttributes } from 'common/attributes/attribute-actions'
import Navigation from 'common/navigation/navigation'
import { sortBy } from 'lodash'
import { actionGetTaxSettings } from 'settings-page/settings-actions'
import { TEXT_OF, TEXT_OWNERS, TEXT_PROPERTY } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { OwnersTable } from './owners-table/owners-table'
import PropertiesResults from './properties-results/properties-results'
import { PropertiesTableName } from './properties-results/properties-table'
import { searchFiltersFormFields } from './search-filters-form-fields'
import SearchMap from './search-map/search-map'
import SearchSelectionContextBar from './search-selection-context-bar/search-selection-context-bar'

const SearchPage: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch()

    const currentUserId = useUserId()
    const users = useUsers()
    const showMap: boolean = useSelector((state: AppState) => state.search.showMap)
    const usersArray = sortBy(
        sortBy(
            Object.keys(users).map((key) => users[key]),
            (u) => u.name,
        ),
        (u) => u.id !== currentUserId,
    )
    const usersWithoutCompanyArray = usersArray.filter((u) => !u.taxCompanyId)

    const companies = useCompanies()
    const activeTabIndex: number = useSelector((state: AppState) => state.search.activeTabIndex)
    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)
    const attributes: string[] = useSelector((state: AppState) => state.attributes[attributeKeys.BUILDING])
    const tabChangeHandler = (event: any, tabIndex: number) => {
        dispatch(actionSearchSelectTab(tabIndex))
    }

    let results: SearchResults = useSelector((state: AppState) => state.search.results)

    useEffect(() => {
        if (!settings?.loaded) {
            setTimeout(() => {
                dispatch(actionGetTaxSettings())
            }, 0)
        }
    }, [settings, dispatch])

    useEffect(() => {
            setTimeout(() => {
                dispatch(actionGetAttributes(attributeKeys.BUILDING))
            }, 0)
    }, [])

    const renderTab = (tabIndex: number) => {
        const tabLabels = [
            getTabLabel(results[PropertiesTableName].items.length, results[PropertiesTableName].total),
            `${tr(TEXT_OWNERS)}`,
        ]
        return results[PropertiesTableName].items.length > 0 ? <Tab label={tabLabels[tabIndex]} /> : null
    }
    const renderTabContent = (tabIndex: number) => {
        const tabComponents = [
            <PropertiesResults results={results.properties} companies={companies} users={users} />,
            <OwnersTable results={results.owners} />,
        ]
        return results[PropertiesTableName].isLoading ? (
            <Box padding={2} textAlign="center">
                <CircularProgress size={28} />
            </Box>
        ) : results[PropertiesTableName].items.length > 0 ? (
            tabComponents[tabIndex]
        ) : null
    }

    return (
        <LayoutDefault
            toolbar={<SearchToolbar />}
            contextbar={<SearchSelectionContextBar companies={companies} users={users} />}
            navigation={<Navigation />}
        >
            <SearchFiltersPanel
                formFields={searchFiltersFormFields(
                    companies,
                    currentUserId,
                    users,
                    usersWithoutCompanyArray,
                    settings,
                    attributes,
                )}
            />
            {showMap ? (
                <SearchMap />
            ) : (
                <>
                    <Tabs
                        value={activeTabIndex}
                        onChange={tabChangeHandler}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {renderTab(activeTabIndex)}
                    </Tabs>
                    {renderTabContent(activeTabIndex)}
                </>
            )}
        </LayoutDefault>
    )
}

export { SearchPage }

const getTabLabel = (count: number, totalCount: number) => {
    return count < totalCount
        ? `${tr(TEXT_PROPERTY)} (${count} ${tr(TEXT_OF)} ${totalCount})`
        : `${tr(TEXT_PROPERTY)} (${count})`
}
