import Feature from 'ol/Feature'
import { Cluster as ClusterSource } from 'ol/source'
import VectorSource from 'ol/source/Vector'

const clusteredFeatures = (features: Feature[], distance?: number): ClusterSource =>
    new ClusterSource({
        source: new VectorSource({
            features,
        }),
        distance: distance,
    })

export default clusteredFeatures
