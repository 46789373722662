import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { makeStyles, Theme, Paper } from '@material-ui/core'
import Navigation from 'common/navigation/navigation'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { useDispatch, useSelector } from 'react-redux'
import {
    actionCreateTemplate,
    actionUpdateTemplate,
    actionGetTemplate,
} from 'settings-page/settings-actions'
import { AppState } from '../app/app-store'
import { Field } from 'redux-form'
import { DocumentConstants, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { TemplatesToolbar } from './templates-toolbar'
import { TemplateForm } from './templates-form'
import { RouteComponentProps } from '@reach/router'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { stripHtml } from 'common/editor/editor-helper'
import { DocumentTypes } from 'common/enums/document-types'
import { convertToRaw } from 'draft-js'
import { getEditorState } from 'common/editor/editor'
import { stateToHTML } from 'draft-js-export-html'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { flowOptions, typeOptions } from './field-options'

type TextTemplatesProps = {
    id: string
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        sidebarContainer: {
            padding: theme.spacing(2),
            height: '100%'
        },
        sidebarItem: {
            marginBottom: theme.spacing(2)
        },
    }
})

export const TextTemplateEdit: React.FC<RouteComponentProps<TextTemplatesProps>> = (props) => {
    const { id = '' } = props
    const classes = useStyles()
    const dispatch = useDispatch()

    const textTemplates: any = useSelector((state: AppState) => state.taxSettings[DocumentTypes.TEXT])
    const template: any = Object.values(textTemplates.templates).find((t: any) => t.id === id)
    
    const initialFormContent = template
        ? {
            ...template,
            body: getEditorState(template.state),
          }
        : id !== DocumentConstants.new
        ? undefined
        : {
            body: getEditorState(null),
          }
    const handleSubmit = (values: any) => {
        const htmlBody: any = stateToHTML(values.body.getCurrentContent())
        const textBody: string = stripHtml(htmlBody)
        const content = convertToRaw(values.body.getCurrentContent())

        const saveObject: any = {
            ...values,
            documentType: DocumentTypes.TEXT,
            state: content,
            bodyText: textBody,
            body: null,
        }
        if (!template) {
            dispatch(actionCreateTemplate(saveObject))
        } else {
            dispatch(actionUpdateTemplate(saveObject))
        }
    }

    const sidebar = () => (
        <Paper elevation={0} className={classes.sidebarContainer}>
            <Field 
                name={TaxFormFieldNames.name} 
                component={CubitTextFieldAdapter} 
                label={tr(t.TEXT_TITLE)} 
                className={classes.sidebarItem}
            />
            <Field
                name={TaxFormFieldNames.type}
                component={CubitSelectAdapter}
                label={tr(t.TEXT_TYPE)}
                options={typeOptions}
                className={classes.sidebarItem}
                valueIsObject={false}
            />
            <Field
                name={TaxFormFieldNames.pageBreak}
                component={CubitSelectAdapter}
                label={tr(t.TEXT_FLOW)}
                options={flowOptions}
                valueIsObject={false}
            />
        </Paper>
    )

    useEffect(() => {
        if (id !== 'new' && !template) {
            dispatch(actionGetTemplate(id))
        }
    }, [dispatch, id, template])

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={<TemplatesToolbar title={template?.name ? template.name : tr(t.TEXT_TEXTS)} edit={true} />}
        >
            {initialFormContent &&
                <TemplateForm 
                    onSubmit={handleSubmit} 
                    initialValues={initialFormContent} 
                    Sidebar={sidebar}
                />
            }
        </LayoutDefault>
    )
}
