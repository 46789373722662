import { useEffect, useState } from 'react'
import { Coordinate } from 'ol/coordinate'
import { fromLonLat } from 'ol/proj'

const useGeolocation = (projection: string | undefined = undefined) => {
    const [position, setPosition] = useState<Coordinate | undefined>(undefined)

    // Should be position: GeolocationPosition, but this Typescript bug is fixed from v4.1.x
    const onSuccess = (position: any) => {
        const { latitude, longitude } = position.coords
        const pos = fromLonLat([longitude, latitude], projection)
        setPosition(pos)
    }

    // Should be position: GeolocationPositionError, but this Typescript bug is fixed from v4.1.x
    const onError = (e: any) => {
        const text = `GEOLOCATION: ${e.code}, ${e.message}`
        e.code === 1 ? console.warn(text) : console.error(text)
        setPosition(undefined)
    }

    useEffect(() => {
        // Do not use ol.Geolocation v6, not working correctly.
        let watchId: number = navigator.geolocation.watchPosition(onSuccess, onError)

        return () => navigator.geolocation.clearWatch(watchId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [position] as const
}

export default useGeolocation
