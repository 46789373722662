import { AnyAction } from 'redux'
import { SettingsGroup } from './settings-group.type'

export const ACTION_SETTINGS_GROUPS_GET = 'ACTION_SETTINGS_GROUPS_GET'
export const ACTION_SETTINGS_GROUPS_GET_SUCCESS = 'ACTION_SETTINGS_GROUPS_GET_SUCCESS'

export const actionSettingsGroupsGet = (): AnyAction => ({
    type: ACTION_SETTINGS_GROUPS_GET,
})

export const actionSettingsGroupsGetSuccess = (groups: SettingsGroup[]): AnyAction => ({
    type: ACTION_SETTINGS_GROUPS_GET_SUCCESS,
    groups,
})
