import { find } from "lodash";
import { Building, BuildingConstants, getBuildingArea, unusedBuildingStatuses } from "models/building";
import { Property, VerifiableProperty } from "models/property";
import { Taxation } from "models/taxation";

export const untaxableBuildingsFilter = (b: Building, buildings: Building[], settings: any) => {
    if (b.newlyCreated) {
        return true
    }
    //general rules
    const unused = b.status && unusedBuildingStatuses.includes(b.status)
    // const area = getBuildingArea(b)
    // const minAreaSetting = settings?.minBuildingArea ?? BuildingConstants.smallArea
    // const tooSmall = area ? (area < minAreaSetting) : false
    return !unused //|| !tooSmall)
}

export const isBuildingExempt = (b: Building, settings: any) => {
    if (b.newlyCreated) {
        return true
    }
    const area = getBuildingArea(b)
    const minAreaSetting = settings?.minBuildingArea ?? BuildingConstants.smallArea
    const tooSmall = area ? (area < minAreaSetting) : false
    return tooSmall
}

export const getPropertyFactorMultiplier = (property: Property | VerifiableProperty) => {
    return (property.locationFactor ?? 1) * (property.zoneFactor ?? 1)
}

export const getAdjustedFactorsMultiplier = (taxation: Taxation) => {
    return 1
    //return (1 + getOfficeAdjustment({ settings: [taxation.yearSettings] }) / 100)
}

export const getUseOptions = (settings: any) =>
    Object.values(settings.buildingUsages).map((use: any) => {
        return {
            label: use.name,
            value: use.name,
        }
    })
export const getFloorOptions = (settings: any) => {
    return [
        { label: 'H01', value: 'H01' },
        { label: 'H02', value: 'H02' },
        { label: 'H03', value: 'H03' },
        { label: 'H04', value: 'H04' },
        { label: 'H05', value: 'H05' },
        { label: 'H06', value: 'H06' },
        { label: 'K01', value: 'K01' },
        { label: 'K02', value: 'K02' },
        { label: 'L01', value: 'L01' },
        { label: 'L02', value: 'L02' },
        { label: 'U01', value: 'U01' },
        { label: 'U02', value: 'U02' },
    ]
}
export const getSettingsFloorFactor = (floorName: string, settings: any) => {
    if (!floorName) {
        return 1
    }
    return find(settings.floorFactors, (floorFactor) => floorFactor.name[0] === floorName[0])?.factor ?? 1
}
export const getSettingsUsageFactor = (usage: string, settings: any) => {
    return find(settings.buildingUsages, (usageInfo) => usageInfo.name === usage).price
}
