import { makeReducer } from 'utils'
import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import {
    ACTION_GET_COMPLAINTS,
    ACTION_GET_COMPLAINTS_SUCCESS,
    ACTION_UPDATE_COMPLAINT,
    ACTION_UPDATE_COMPLAINT_SUCCESS,
    ACTION_SET_COMPLAINT_VALUE,
} from './complaint-actions'
import { Complaint } from 'tribunal-page/models/complaint.type'

export type ComplaintState = { [taxationId: string]: Complaint[] }

type ComplaintReducer = ActionReducer<ComplaintState>

const getComplaints: ComplaintReducer = state => state

const getComplaintsSuccess: ComplaintReducer = (state, action) => ({
    ...state,
    [action.id]: action.complaints
})

const updateComplaint: ComplaintReducer = state => state

const updateComplaintSuccess: ComplaintReducer = (state, action) => {
    const complaints: Complaint[] = state[action.complaint.taxationId]
    const index: number = complaints.findIndex((c: Complaint) => c.id === action.complaint.id)
    complaints[index] = action.complaint
    state[action.complaint.taxationId] = complaints
    return state
}

const setComplaintValues: ComplaintReducer = state => state

const reducers: Reducers<ComplaintState> = {
    [ACTION_GET_COMPLAINTS]: getComplaints,
    [ACTION_GET_COMPLAINTS_SUCCESS]: getComplaintsSuccess,
    [ACTION_UPDATE_COMPLAINT]: updateComplaint,
    [ACTION_UPDATE_COMPLAINT_SUCCESS]: updateComplaintSuccess,
    [ACTION_SET_COMPLAINT_VALUE]: setComplaintValues,
}

export const complaintReducer = makeReducer<ComplaintState>(reducers, {})
