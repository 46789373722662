export type TribunalGroup = {
    description: string
    id: string
    name: string
    type: TribunalGroupType
    conflictsReviewedBy: string[]
}

export enum TribunalGroupType {
    Unassigned = 'UNASSIGNED',
    HasComment = 'HAS_COMMENT',
    HasChanges = 'HAS_CHANGES',
    UserCreated = 'USER_CREATED',
    Declined = 'DECLINED',
}
