import { makeReducer } from 'utils'
import { SettingsGroup } from './settings-group.type'
import { ActionReducer, Reducers } from 'utils/make-reducer'
import { ACTION_SETTINGS_GROUPS_GET, ACTION_SETTINGS_GROUPS_GET_SUCCESS } from './groups-actions'

export type SettingsGroupsState = SettingsGroup[]

type SettingsGroupsReducer = ActionReducer<SettingsGroupsState>

const getSettingsGroupsGroups: SettingsGroupsReducer = state => state
const getSettingsGroupsGroupsSuccess: SettingsGroupsReducer = (state, action) => action.groups

const reducers: Reducers<SettingsGroupsState> = {
    [ACTION_SETTINGS_GROUPS_GET]: getSettingsGroupsGroups,
    [ACTION_SETTINGS_GROUPS_GET_SUCCESS]: getSettingsGroupsGroupsSuccess,
}

const initialState: SettingsGroup[] = []

export const settingsGroupsReducer = makeReducer<SettingsGroupsState>(reducers, initialState)
