import { Property } from "models/property";
import { Taxation, TaxationState } from "models/taxation";

export const getFullMatrikkelAddress = (p: Property) => {
    return `${p.holdingNumber}/${p.subHoldingNumber}/${p.leaseNumber}/${p.sectionNumber}`
}

export const isActive = (state: string) => {
    if (state === TaxationState.TribunalStarted) {
        return true
    }
    return false
}

export const getTotalValue = (taxation: Taxation) => {
    const calc = taxation.calculationDetails
    return calc.dwellingBuildingsValue + calc.dwellingLandValue + calc.otherBuildingsValue + calc.otherLandValue
}