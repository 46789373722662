import React from 'react'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { Grid, Button } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { useSelector, useDispatch } from 'react-redux'
import { isDirty, isSubmitting, reset, startSubmit } from 'redux-form'
import { TaxFormName } from 'common/enums/form-name.enum'
import { actionTaxSettingsFormReset } from '../settings-page/settings-actions'

type TaxToolbarProps = {
    title: string
    hideActions?: boolean
    canCancel: boolean
}
export const TaxToolbar: React.FC<TaxToolbarProps> = (props) => {
    const { 
        title,
        hideActions = false,
        canCancel,
    } = props

    const dirty: boolean = useSelector((state: any) => isDirty(TaxFormName.TaxationSettings)(state))
    const submitting: boolean = useSelector((state: any) => isSubmitting(TaxFormName.TaxationSettings)(state))
    const dispatch = useDispatch()

    return (
        <TitleToolbar title={title}>
            {((!hideActions && dirty) || (!hideActions && canCancel)) &&
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button
                            color="inherit"
                            type="button"
                            onClick={() => {
                                dispatch(reset(TaxFormName.TaxationSettings))
                                dispatch(actionTaxSettingsFormReset(true))
                            }}
                            disabled={!canCancel}
                        >
                            {tr(t.TEXT_CANCEL)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="inherit"
                            type="button"
                            onClick={() => {
                                dispatch(startSubmit(TaxFormName.TaxationSettings))
                            }}
                            disabled={!dirty || submitting}
                        >
                            {tr(t.TEXT_SAVE_CHANGES)}
                        </Button>
                    </Grid>
                </Grid>
            }
        </TitleToolbar>
    )
}
