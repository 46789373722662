import { Language } from 'shared-components/src/settings/language/language.enum'

export default {
    lantersUrl: process.env.REACT_APP_LANTERN_URL,
    plantsUrl: process.env.REACT_APP_PLANTS_URL,
    auditFrontUrl: process.env.REACT_APP_AUDIT_FRONT_URL,


    language: Language.Norwegian,
    localStorageLangKey: 'language',
}
