import { PropertyValueType } from './property'

export enum CompanyType {
    Admin = 'ADMIN',
    Tribunal = 'NEMND',
    Taxation = 'TAXATION',
}

export type Company = {
    id: string
    name: string
    type: CompanyType
    zones: string[]
    /** List of user ids */
    users: string[]
    propertyTypes: PropertyValueType[]
}
