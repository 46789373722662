import React from 'react'
import { AppState } from 'app/app-store'
import Map, { Layers, PositionOverlay, TileLayer } from 'map'
import { Controls } from 'map/controls'
import useGeolocation from 'map/hooks'
import { wms } from 'map/source'
import { HousingUnit } from 'models/housing-unit'
import { Plot } from 'models/plot'
import { Property } from 'models/property'
import { Feature } from 'ol'
import { Coordinate } from 'ol/coordinate'
import { FeatureLike } from 'ol/Feature'
import { Geometry, Point } from 'ol/geom'
import { transform } from 'ol/proj'
import { Fill, Stroke, Style, Text } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { useSelector } from 'react-redux'
import { isPropertyTaxable } from 'utils'
import { SearchResult } from '../search-reducer'
import PolygonSelectControl from './controls/polygon-select-control'
import { Marker } from './features/styles'
import PropertiesLayer from './layers/properties-layer'
import {
    HOUSING_UNIT_PROJECTION,
    registerProjections,
    SEARCH_MAP_PROJECTION,
    toSearchMapProjection,
} from './projections'
import PropertiesMapCard from './properties-map-card'

const hasPropertyCoordinates = (item: any) => {
    let huCoords = item.housingUnits.length > 0 &&
        item.housingUnits.some(
            (h: HousingUnit) => h.address.coordinates && h.address.coordinates.x && h.address.coordinates.y,
        )
    if (!huCoords) {
        return item.plots.some(
            (p: Plot) => p.coordinates && p.coordinates.x && p.coordinates.y,
        )
    }
    return huCoords
}

const transfromPropertyCoordinate = (item: Property): Coordinate => {
    const unit = item.housingUnits.find(
        (h: HousingUnit) => h.address.coordinates && h.address.coordinates.x && h.address.coordinates.y,
    )

    if (!unit) {
        const plot = item.plots.find((p: Plot) => p.coordinates?.x && p.coordinates?.y)
        return plot
            ? transform([plot.coordinates.x, plot.coordinates.y], HOUSING_UNIT_PROJECTION, SEARCH_MAP_PROJECTION)
            : [0, 0]
    }

    return transform(
        [unit.address.coordinates.x, unit.address.coordinates.y],
        HOUSING_UNIT_PROJECTION,
        SEARCH_MAP_PROJECTION,
    )
}

const features = (properties: SearchResult<any>): Feature<Point>[] =>
    properties.items.filter(hasPropertyCoordinates).map((item) => {
        const marker = new Feature({
            geometry: new Point(transfromPropertyCoordinate(item)),
        })
        marker.set('unitId', item.id)
        marker.set('selected', false)
        marker.set('selectable', isPropertyTaxable(item))

        return marker
    })

const style = (feature: FeatureLike) => {
    const size = feature.get('features').length
    const cluster = feature.get('features')
    const selectedMap = cluster.map((f: any) => f.get('selected'))
    const isSelected = selectedMap.some((value: any) => value)
    const selectedCount = selectedMap.filter((value: any) => value).length

    const selectableCount = cluster.filter((f: Feature<Geometry>) => f.get('selectable')).length
    if (size === 1) {
        const item: Feature<Geometry> = feature.get('features')[0]
        if (!item.get('selectable')) return Marker.passiveIcon
        if (isSelected) {
            return Marker.selectedIcon
        }
        return Marker.icon
    } else {
        return new Style({
            image: new CircleStyle({
                radius: 20,
                stroke: new Stroke({
                    color: '#fff',
                }),
                fill: new Fill({
                    color: selectableCount === 0 ? '#aaa' : isSelected ? '#00cc00' : '#333333',
                }),
            }),
            text: new Text({
                text:
                    selectedCount > 0 && selectedCount !== size
                        ? `${selectedCount} / ${size.toString()}`
                        : size.toString(),
                fill: new Fill({
                    color: '#fff',
                }),
            }),
        })
    }
}

const SearchMap: React.FC = () => {
    registerProjections()
    const properties: SearchResult<any> = useSelector((state: AppState) => state.search.results.properties)
    const loading = useSelector((state: AppState) => state.search.results['properties'].isLoading)
    const center = toSearchMapProjection([10.1525, 60.205278]) // Ringerike coordinates
    const [position] = useGeolocation(SEARCH_MAP_PROJECTION)

    return (
        <Map
            zoom={11}
            loading={loading}
            // maxZoom={20} too much zoom will throw error, but the customer cannot see building numbers.
            initCenter={position || center}
            projection={SEARCH_MAP_PROJECTION}
        >
            <PositionOverlay position={position} />
            <Controls>
                <PolygonSelectControl />
            </Controls>
            <Layers>
                <TileLayer
                    source={wms('http://opencache.statkart.no/gatekeeper/gk/gk.open?', {
                        LAYERS: 'topo4',
                        VERSION: '1.1.1',
                    })}
                />
                <PropertiesLayer distance={parseInt('30', 10)} style={style}>
                    {features(properties)}
                </PropertiesLayer>
            </Layers>
            <PropertiesMapCard />
        </Map>
    )
}

export default SearchMap
