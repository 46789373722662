import React from 'react'
import { makeStyles, Theme, Grid } from '@material-ui/core'
import { ActivityLogType } from './activity-log-type.enum'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssignmentIcon from '@material-ui/icons/Assignment'
import classNames from 'classnames'
import { ActivityLogEntry } from 'models/activity-log'
import { getDateTimeFromISOString } from 'utils'

const useStyles = makeStyles((theme: Theme) => {
    return {
        item: {
            backgroundColor: 'white',
            marginBottom: '0.5rem',
            padding: theme.spacing(1, 2, 1, 4),
            position: 'relative',
        },
        itemIconContainer: {
            top: '5px',
            left: '5px',
            position: 'absolute',
        },
        itemIcon: {
            fontSize: '1.5rem',
        },
        itemHeading: {
            fontWeight: 500,
            paddingBottom: theme.spacing(1),
        },
        iconA: {
            color: 'green',
        },
        iconB: {
            color: 'purple',
        },
        iconC: {
            color: 'orange',
        },

        itemA: {
            borderLeft: '0.25rem solid green',
        },
        itemB: {
            borderLeft: '0.25rem solid purple',
        },
        itemC: {
            borderLeft: '0.25rem solid orange',
        },
    }
})

export const ActivityLogItem: React.FC<{ type: ActivityLogType; entry: ActivityLogEntry }> = props => {
    const { type, entry } = props
    const styles = useStyles()

    const renderIcon = (type: ActivityLogType): JSX.Element => {
        const iconsByType = {
            [ActivityLogType.A]: <OfflineBoltIcon className={styles.iconA} />,
            [ActivityLogType.B]: <AccountCircleIcon className={styles.iconB} />,
            [ActivityLogType.C]: <AssignmentIcon className={styles.iconC} />,
        }
        return iconsByType[type]
    }

    return (
        <div
            className={classNames(styles.item, {
                [styles.itemA]: type === ActivityLogType.A,
                [styles.itemB]: type === ActivityLogType.B,
                [styles.itemC]: type === ActivityLogType.C,
            })}
        >
            <div className={styles.itemIconContainer}>{renderIcon(type)}</div>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={4} justifyContent="space-between" className={styles.itemHeading}>
                        <Grid item>{entry.userName}</Grid>
                        <Grid item>{getDateTimeFromISOString((entry.timeStamp as unknown) as string)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            </Grid>
        </div>
    )
}
