import React, { useState, useEffect, useMemo } from 'react'
import LayoutDefault from 'common/layout/layout-default'

import Navigation from 'common/navigation/navigation'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles, Theme, Grid, Card, IconButton, TextField, InputAdornment, CircularProgress } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import { TribunalGroup } from 'tribunal-page/models/tribunal-group'
import { AppState } from 'app/app-store'
import { find, includes, filter } from 'lodash'

import { actionTribunalGroupsGetFull } from 'tribunal-page/tribunal-actions'
import { TribunalGroupTaxationsTable, TribunalGroupTablePrefix } from './tribunal-group-taxations-table'
import { Taxation } from 'models/taxation'
import { tr } from 'utils/translations/translate'
import { TEXT_FILTER } from 'utils/translations/keys'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { getPropertyAddresses, getPropertyValueTypeTranslationKey, userHasPermission } from 'utils'
import { TribunalGroupPageContextBar } from './tribunal-group-page-context-bar'
import { TribunalGroupState } from './tribunal-group-state.enum'
import { getTribunalGroupNameTranslation } from 'utils'
import { useUserId, useUser } from 'auth/auth-selectors'
import { TribunalGroupPageToolbar } from './tribunal-group-page-toolbar'
import { Permission } from 'common/enums/permission'
import { IntermediateTribunalGroupState } from './intermediate-tribunal-group-state.enum'
import { actionGetTaxSettings } from 'settings-page/settings-actions'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { Clear } from '@material-ui/icons'
import { getFullMatrikkelAddress } from 'utils/tribunal/tribunal-helper'

const useStyles = makeStyles((theme: Theme) => ({
    groupDescription: {
        padding: theme.spacing(1, 2, 1, 2),
        marginBottom: theme.spacing(2),
        boxShadow: 'none',
        fontSize: theme.typography.subtitle1.fontSize,
    },
    tableDescription: {
        padding: theme.spacing(2),
        fontSize: theme.typography.h6.fontSize,
    },
    tableDescriptionCount: {
        fontWeight: 'bold',
    },
    taxationFilter: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    tableContainer: {
        minWidth: 1000
    }
}))

export const TribunalGroupPage: React.FC<
    RouteComponentProps<{ id: string; groupState: TribunalGroupState; location: any }>
> = props => {
    const { id = '0', groupState = TribunalGroupState.Initial } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)

    useEffect(() => {
        if (!settings.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [dispatch, settings])

    const [filterText, setFilterText] = useState('')

    const currentUser = useUser()
    const currentUserId = useUserId()
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    const loading =  useSelector((state: AppState) => state.tribunal.loading)
    const loaded =  useSelector((state: AppState) => state.tribunal.loaded)
    const groups: TribunalGroup[] = useSelector((state: AppState) => state.tribunal.groups)

    useEffect(() => {
        if (groups.length < 2 && !loaded) {
            // when reaching group directly from url, get all groups
            dispatch(actionTribunalGroupsGetFull())
        }
    }, [dispatch, groups, loaded])

    const group: TribunalGroup = useSelector(
        (state: AppState) => find(state.tribunal.groups, g => g.id === id) || ({} as TribunalGroup),
    )

    const hasTribunalReadyPermission = userHasPermission(currentUser, Permission.ViewTribunalReady)
    const groupConflictsReviewed = (group && includes(group.conflictsReviewedBy, currentUserId)) || false

    const intermediateGroupState = getIntermediateGroupState(
        groupState,
        hasTribunalReadyPermission,
        groupConflictsReviewed,
    )

    const taxations: Taxation[] = useSelector((state: AppState) => state.tribunal.taxations[id]) || []

    const taxationsWithFilterText = useMemo(
        () =>
            taxations.map(t => {
                const filterValues =
                    intermediateGroupState === IntermediateTribunalGroupState.PlannedConflictsReview
                        ? [
                            t.current.owners
                                .map(
                                    o =>
                                        `${o.firstName};${o.lastName};${(o.postalAddress &&
                                            o.postalAddress.addressText) ||
                                        ''};${(o.postalAddress && o.postalAddress.postalCode) ||
                                        ''};${(o.postalAddress && o.postalAddress.foreignCity) || ''}`,
                                )
                                .join(';'),
                        ]
                        : [
                            getFullMatrikkelAddress(t.current.property),
                            getPropertyAddresses(t.current.housingUnits),
                            tr(getPropertyValueTypeTranslationKey(t.current.property.valueType)),
                        ]

                const filterString = filterValues.join(';').toLocaleLowerCase()

                return { ...t, filterString }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language, taxations],
    )

    const filteredTaxations = filterText
        ? filter(taxationsWithFilterText, t => {
            return includes(t.filterString, filterText.toLowerCase())
        })
        : taxations

    const taxationsWithExcludedInterestConflicts =
        intermediateGroupState !== IntermediateTribunalGroupState.PlannedConflictsReview && hasTribunalReadyPermission
            ? filter(filteredTaxations, t => !includes(t.conflictedUsers, currentUserId))
            : filteredTaxations

    if (loading) {
        return (
            <LayoutDefault navigation={<Navigation />}>
                <CircularProgress />
            </LayoutDefault>
        )
    }

    return (
        <LayoutDefault
            toolbar={
                <TribunalGroupPageToolbar
                    group={group}
                    groupState={groupState}
                    intermediateGroupState={intermediateGroupState}
                />
            }
            contextbar={
                <TribunalGroupPageContextBar
                    groupId={group.id}
                    groupState={groupState}
                    intermediateGroupState={intermediateGroupState}
                />
            }
            navigation={<Navigation />}
        >
            {group.description && <Card className={styles.groupDescription}>{group.description}</Card>}

            <TextField
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                label={tr(TEXT_FILTER)}
                margin="normal"
                variant="outlined"
                className={styles.taxationFilter}
                InputProps={{
                    endAdornment: filterText && (
                        <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => setFilterText('')}>
                                <Clear fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <Card className={styles.tableContainer}>
                <Grid container className={styles.tableDescription} spacing={2}>
                    <Grid item>{getTribunalGroupNameTranslation(group)}</Grid>
                    <Grid item className={styles.tableDescriptionCount}>
                        {taxationsWithExcludedInterestConflicts.length}
                    </Grid>
                </Grid>
                <TribunalGroupTaxationsTable
                    results={taxationsWithExcludedInterestConflicts}
                    groupState={groupState}
                    groupId={id}
                    tableName={TribunalGroupTablePrefix + id}
                    intermediateGroupState={intermediateGroupState}
                />
            </Card>
        </LayoutDefault>
    )
}

const getIntermediateGroupState = (
    groupState: TribunalGroupState,
    hasTribunalReadyPermission: boolean,
    groupConflictsReviewed: boolean,
): IntermediateTribunalGroupState | null => {
    if (groupState === TribunalGroupState.Planned) {
        if (hasTribunalReadyPermission && groupConflictsReviewed)
            return IntermediateTribunalGroupState.PlannedConflictsReviewFinished
        else if (hasTribunalReadyPermission && !groupConflictsReviewed)
            return IntermediateTribunalGroupState.PlannedConflictsReview
        else return IntermediateTribunalGroupState.PlannedDefault
    } else {
        return null
    }
}
