import React, { useMemo } from 'react'
import { Taxation } from 'models/taxation'
import {
    Grid,
    Card,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Theme,
    makeStyles,
} from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_MAP } from 'utils/translations/keys'
import { IframeMap } from 'property-page/property-details/iframe-map'
import { Property } from 'models/property'
import { coordsFromZone32to33 } from 'utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { TribunalTaxationDetailsSummary } from './tribunal-taxation-details-summary'
import { TribunalTaxationDetailsPlotInfo } from './tribunal-taxation-details-plot-info'
import { InjectedFormProps, reduxForm, FieldArray } from 'redux-form'
import { TribunalTaxationBuildingsFieldArray } from './tribunal-taxation-buildings-field-array'
import { TribunalTaxationComments } from 'tribunal-taxation-details-page/tribunal-taxation-comments/tribunal-taxation-comments'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panel: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(2),
            boxShadow: theme.shadows[2],
            borderRadius: 0,
        },

        panelContent: {
            fontSize: theme.spacing(2),
        },

        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },
        divider: {
            margin: theme.spacing(2),
        },
        bolded: {
            fontSize: '1.125rem',
            fontWeight: 500,
        },
        detailsContainer: {
            marginBottom: theme.spacing(4),
        },
        declineReasonContainer: {
            padding: theme.spacing(2, 3),
            marginBottom: theme.spacing(4),
        },
        declineReason: {
            fontWeight: 500,
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        tableContainer: {
            minWidth: 800
        }
    }
})

type TribunalTaxationDetailsProps = {}

const Form: React.FC<InjectedFormProps<Taxation, TribunalTaxationDetailsProps> &
    TribunalTaxationDetailsProps> = props => {
    const { initialValues, form } = props
    const styles = useStyles()
    const taxation = initialValues as Taxation
    const property = (taxation && taxation.current && taxation.current.property) || ({} as Property)
    const propertyCoords = property.plots && property.plots.length
        ? coordsFromZone32to33(property.plots[0].coordinates.x, property.plots[0].coordinates.y)
        : null

    const lat = propertyCoords && propertyCoords.lat
    const long = propertyCoords && propertyCoords.long

    const Map = useMemo(() => (lat && long ? <IframeMap coords={{ lat, long }} /> : <></>), [lat, long])

    return (
        <form>
            <Grid container className={styles.tableContainer}>
                <Grid item xs={12}>
                    {taxation && (
                        <>
                            <Grid container>
                                <Grid item lg={9} md={12} xs={12}>
                                    <Card className={styles.panel}>
                                        <TribunalTaxationDetailsSummary taxation={taxation} />
                                    </Card>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TribunalTaxationComments
                                        formName={form}
                                        fieldName="current.property"
                                        commentsFieldName="tribunalSummaryComments"
                                    ></TribunalTaxationComments>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item lg={9} md={12} xs={12}>
                                    <Card className={styles.panel}>
                                        <TribunalTaxationDetailsPlotInfo formName={form} />
                                    </Card>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TribunalTaxationComments
                                        formName={form}
                                        fieldName="current.property"
                                        commentsFieldName="tribunalPlotComments"
                                    ></TribunalTaxationComments>
                                </Grid>
                            </Grid>
                            <FieldArray
                                name="current.buildings"
                                component={TribunalTaxationBuildingsFieldArray}
                                form={form}
                            />
                            <Grid container>
                                <Grid item lg={9} md={12} xs={12}>
                                    <Box>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                className={styles.panelSummary}
                                            >
                                                {tr(TEXT_MAP)}
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.panelContent}>
                                                {Map}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>
                                <Grid item md={3} xs={12}></Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </form>
    )
}

export const TribunalTaxationDetails = reduxForm<Taxation, TribunalTaxationDetailsProps>({
    enableReinitialize: true,
    destroyOnUnmount: false,
})(Form)
