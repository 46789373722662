import React from 'react'
import { CubitTableColumn } from 'shared-components/src/cubit-table/cubit-table.types'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'
import { tr } from 'utils/translations/translate'
import { TEXT_VALUE, TEXT_M2_PRICE, TEXT_FLOOR_FACTOR, TEXT_USE, TEXT_FLOOR } from 'utils/translations/keys'
import { Floor } from 'models/floor'
import { formatCurrency } from 'utils'
import { formatArea } from 'shared-components/src/utils'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { change, Field, formValueSelector } from 'redux-form'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { Grid, InputAdornment, makeStyles, Theme } from '@material-ui/core'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { getFactor } from 'property-page/taxations-history/functions'
import { getFloorOptions, getSettingsFloorFactor, getSettingsUsageFactor, getUseOptions } from 'property-page/property-helper'

const useStyles = makeStyles((theme: Theme) => {
    return {
        select: {
            maxWidth: 200
        },
        textInput: {
            '& input': {
                textAlign: 'right'
            }
        }
    }
})

type BuildingFloorsTableProps = {
    floors: Floor[]
    editing: boolean
    formName: string
}
export const BuildingFloorsTable: React.FC<BuildingFloorsTableProps> = (props) => {
    const { floors, editing = false, formName } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const selector = formValueSelector(formName)
    const stateRef = useSelector((state: AppState) => state)
    const getValue = (field: string) => selector(stateRef, field)
    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)

    const getFloorType = (floor: Floor) => {
        if (!editing) {
            return <span>{floor.floorNumber}</span>
        } else {
            return (
                <Grid item className={styles.select}>
                    <Field
                        component={CubitSelectAdapter}
                        valueIsObject={false}
                        options={getFloorOptions(settings)}
                        name={`floors[${floors.indexOf(floor)}].${TaxFormFieldNames.floorNumber}`}
                        onChange={(event: any) =>
                            dispatch(
                                change(
                                    formName,
                                    `floors[${floors.indexOf(floor)}].${TaxFormFieldNames.usageFactor}`,
                                    getSettingsFloorFactor(event.target.value, settings),
                                ),
                            )
                        }
                    />
                </Grid>
            )
        }
    }

    const getFloorUse = (floor: Floor) => {
        if (!editing) {
            return <span>{floor.usage}</span>
        } else {
            return (
                <Grid item className={styles.select}>
                    <Field
                        component={CubitSelectAdapter}
                        valueIsObject={false}
                        options={getUseOptions(settings)}
                        name={`floors[${floors.indexOf(floor)}].${TaxFormFieldNames.usage}`}
                        onChange={(event: any) =>
                            dispatch(
                                change(
                                    formName,
                                    `floors[${floors.indexOf(floor)}].${TaxFormFieldNames.pricePerM2}`,
                                    getSettingsUsageFactor(event.target.value, settings),
                                ),
                            )
                        }
                    />
                </Grid>
            )
        }
    }

    const getUsageFactor = (floor: Floor) => {
        const field = `floors[${floors.indexOf(floor)}].${TaxFormFieldNames.usageFactor}`
        const factor = getValue(field)
        return factor ? getFactor(factor).toFixed(1) : factor
    }

    const getFloorArea = (floor: Floor) => {
        if (!editing) {
            return <span>{formatArea(floor.area)}</span>
        } else {
            return (
                <Field
                    component={CubitTextFieldAdapter}
                    name={`floors[${floors.indexOf(floor)}].${TaxFormFieldNames.area}`}
                    className={styles.textInput}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m²</InputAdornment>
                    }}
                />
            )
        }
    }

    const propertyColumns: CubitTableColumn[] = [
        {
            headerLabel: tr(TEXT_FLOOR),
            key: TaxFormFieldNames.floorNumber,
            getDisplayableElement: getFloorType,
            width: '200px'
        },
        {
            headerLabel: tr(TEXT_USE),
            key: TaxFormFieldNames.usage,
            getDisplayableElement: getFloorUse,
            width: '200px'
        },
        {
            headerLabel: tr(TEXT_FLOOR_FACTOR),
            key: TaxFormFieldNames.usageFactor,
            getDisplayableElement: getUsageFactor,
        },
        {
            headerLabel: tr(TEXT_M2_PRICE),
            key: 'pricePerM2',
            getDisplayableElement: (floor: Floor) => <span>{formatCurrency(floor.pricePerM2)}</span>,
            align: 'right'
        },

        {
            headerLabel: 'BRA',
            key: 'area',
            getDisplayableElement: getFloorArea,
            align: 'right',
            width: '136px'
        },

        {
            headerLabel: tr(TEXT_VALUE),
            key: 'value',
            getDisplayableElement: (floor: Floor) => <span>{formatCurrency(floor.value)}</span>,
            align: 'right'
        },
    ]

    return <CubitTable name="buildingFloors" columns={propertyColumns} data={floors} flat sidePadding="24" />
}
