export const dummyData = {
    taxation: {
        start: '2020-12-03T08:17:30.144+0000',
        end: '2020-12-03T08:18:30.144+0000',
        current: {
            property: {
                otherLocationFactors: [],
                parentId: null,
                name: 'BJERKEMOEN SØNDRE',
                type: 'main',
                subType: null,
                municipalityNumber: 0,
                holdingNumber: 1,
                subHoldingNumber: 23,
                leaseNumber: 0,
                sectionNumber: 0,
                plots: [
                    {
                        area: 140741.1,
                        comment: 'Hjelpelinje vegkant, Noen fiktive grenser',
                        coordinates: {
                            type: 'EUREF89 UTM Sone 32',
                            x: 566711.74,
                            y: 6666843.08,
                        },
                    },
                ],
                area: null,
                areaSource: null,
                ownerShips: [
                    {
                        ownerId: '0',
                        registered: true,
                        numerator: 1,
                        denominator: 1,
                        isDocumentRecipient: false,
                        isInvoiceRecipient: false,
                        comment: null,
                        ownerType: null,
                    },
                    {
                        ownerId: '1',
                        registered: true,
                        numerator: 1,
                        denominator: 1,
                        isDocumentRecipient: true,
                        isInvoiceRecipient: true,
                        comment: null,
                        ownerType: null,
                    },
                ],
                otherOwnerShips: [],
                culturalHeritage: [],
                propertyTaxBasis: [],
                zoneFactor: '0.9',
                locationFactor: '1.00',
                plotValue: 0,
                tariffCompany: '',
                comment: '',
                exempt: false,
                exemptReason: '§5h Gårdsbruk og skogbruk',
                zone: 'SONE 2',
                regulated: false,
                valueType: 'BO',
                state: null,
                files: [],
                otherPropertyFlags: {
                    governmentBeneficial: false,
                    historicValue: false,
                    hasParking: false,
                    parkingSpaces: 0,
                    powerProduction: false,
                    nationalValuation: 123456,
                    manualValue: 567891
                },
            },
            housingUnits: [
                {
                    matrikkelId: 0,
                    type: 'Bolig',
                    noOfRooms: 0,
                    noOfToilets: 0,
                    noOfBathrooms: 0,
                    usableArea: 0.0,
                    lastControl: null,
                    nextControl: null,
                    riskAssessment: 0.0,
                    riskAssessmentDoneAt: null,
                    consequenceRiskAssessmentFactors: [],
                    probabilityRiskAssessmentFactors: [],
                    oldestUser: null,
                    inspectionRate: 0,
                    riskAssessmentInstance: null,
                    inspectionRateSetBy: null,
                    address: {
                        addressText: 'Ultvetveien 8',
                        postalCode: '3512',
                        floor: null,
                        pppartmentNumber: null,
                        holdingNumber: null,
                        subHoldingNumber: null,
                        leaseNumber: null,
                        sectionNumber: null,
                        residentialNumber: 'H0101',
                        municipalityNumber: null,
                        district: null,
                        coordinates: {
                            Type: 'EUREF89 UTM Sone 32',
                            X: 572895.0,
                            Y: 6667021.0,
                        },
                    },
                    cooperativeOwnerShips: [],
                },
            ],
            buildings: [
                {
                    otherStandardFactors: [],
                    buildingNumber: 159328945,
                    name: null,
                    serialNumber: null,
                    buildingCode: 181,
                    status: 'Tatt i bruk',
                    statusDate: null,
                    builtDate: null,
                    industrialType: '',
                    floors: [
                        {
                            floorNumber: 'H01',
                            area: 40.0,
                            usage: 'Garasje',
                            usageFactor: 1.0,
                            floorFactor: 0.0,
                            pricePerM2: 3000,
                        },
                    ],
                    lastControl: null,
                    nextControl: null,
                    riskAssessmentScore: 0.0,
                    inspectionRate: 0,
                    coordinates: {
                        type: 'EUREF89 UTM Sone 32',
                        x: 572921.0,
                        y: 6667016.0,
                    },
                    standardFactor: '1.00',
                    comment: '',
                    exempt: false,
                    exemptReason: '',
                    connectedCompanies: [],
                    is1890Building: false,
                    inDenseArea: false,
                    denseWoodenArea: null,
                },
            ],
            owners: [
                {
                    t: 'PrivateOwner',
                    id: '0',
                    number: '03077747787',
                    email: null,
                    phone: 123456789,
                    status: null,
                    acceptElectronicCommunication: false,
                    firstName: 'JOHAN FRØHAUG',
                    lastName: 'FEGRI',
                    residentialAddress: {
                        addressText: 'Trulsrudskogen 98',
                        postalCode: '1350',
                        foreignCity: null,
                        country: null,
                    },
                    postalAddress: {
                        addressText: 'TRULSRUDSKOGEN 98',
                        postalCode: '1350',
                        foreignCity: null,
                        country: null,
                    },
                    birthDate: '1977-07-02T22:00:00.000+0000',
                },
                {
                    t: 'PrivateOwner',
                    id: '1',
                    number: '58887749888',
                    email: null,
                    phone: 123456789,
                    status: null,
                    acceptElectronicCommunication: false,
                    firstName: 'JANE SMITH',
                    lastName: 'FEGRI',
                    residentialAddress: {
                        addressText: 'Trulsrudskogen 98',
                        postalCode: '1350',
                        foreignCity: null,
                        country: null,
                    },
                    postalAddress: {
                        addressText: 'TRULSRUDSKOGEN 98',
                        postalCode: '1350',
                        foreignCity: null,
                        country: null,
                    },
                    birthDate: '1966-06-06T22:00:00.000+0000',
                },
            ],
            events: null,
            taxations: null,
            history: null,
        },
        state: 'TAXATION_READY_FOR_PLANNING',
        issue: null,
        groupId: null,
        declinedReason: null,
        participant: null,
        calculationDetails: {
            livingAreaPercentage: 1.0,
            dwellingTaxRate: 0.3,
            taxValue: 1356.23796,
            plotPricePerM2: 57.73624288425047,
            plotSize: 5270.0,
            dwellingBuildingsValue: 651571.2,
            otherBuildingsValue: 0.0,
            dwellingLandValue: 170391.2,
            otherLandValue: 0.0,
            dwellingTax: 1356.23796,
            otherTax: 0.0,
            exemptions: ['Reduction factor applied'],
        },
        staticSettings: {
            "id": "629ef6f4ca7a921eb749b543",
            "created": "2020-08-25T09:51:14.086Z",
            "updated": "2022-06-07T06:57:56.581Z",
            "updatedBy": "62667d221a2a5ab0c5f5f337",
            "zoneFactors": {
                "zone1": 1,
                "zone2": 0.8,
                "zone3": 0.65,
                "zone4": 0.5,
                "zone5": 1,
                "zone6": 0.75,
                "zone7": 0.45
            },
            "zoneLabels": {
                "zone1": "Sone 1",
                "zone2": "Sone 2",
                "zone3": "Sone 3",
                "zone4": "Sone 4",
                "zone5": "Fritidsone 1",
                "zone6": "Fritidsone 2",
                "zone7": "Fritidsone 3"
            },
            "locationFactors": [
                {
                    "name": "Beliggenhet",
                    "factor": 0.8,
                    "from": 0,
                    "to": 3
                }
            ],
            "locationFactorsFromTo": true,
            "locationModifiers": [],
            "otherFactors": [
                {
                    "name": "Beliggenhet",
                    "choice1": "God beliggenhet",
                    "choice2": "Normal",
                    "choice3": "Dårlig beliggenhet",
                    "choice4": "",
                    "choice5": null
                },
                {
                    "name": "Sol",
                    "choice1": "Gode",
                    "choice2": "Normal",
                    "choice3": "Dårlig",
                    "choice4": null,
                    "choice5": null
                },
                {
                    "name": "Støy",
                    "choice1": "Fra veg",
                    "choice2": "Fra industri",
                    "choice3": "Fra jernbane",
                    "choice4": null,
                    "choice5": null
                },
                {
                    "name": "Adkomst",
                    "choice1": "Ikke veg",
                    "choice2": "Lang privat veg",
                    "choice3": "Enkelt adkomst",
                    "choice4": "",
                    "choice5": null
                }
            ],
            "standardFactors": [
                {
                    "name": "Standardfaktor",
                    "factor": 0,
                    "from": 0,
                    "to": 3
                }
            ],
            "standardFactorsFromTo": true,
            "otherFactors2": [
                {
                    "name": "Materialvalg",
                    "choice1": "Enkelt",
                    "choice2": "Normal",
                    "choice3": "Gode",
                    "choice4": "Svært gode",
                    "choice5": ""
                },
                {
                    "name": "Vedlikehold",
                    "choice1": "Normal",
                    "choice2": "Dårlig",
                    "choice3": "Meget dårlig",
                    "choice4": "Rivningsobjekt",
                    "choice5": "Modernisert"
                },
                {
                    "name": "Uteområde",
                    "choice1": "Høy opparbeidelse",
                    "choice2": "Normal",
                    "choice3": "Lite opparbeidelse ",
                    "choice4": "",
                    "choice5": ""
                },
                {
                    "name": "Arealer",
                    "choice1": "Svært små",
                    "choice2": "Små",
                    "choice3": "Normal",
                    "choice4": "Store ",
                    "choice5": "Svært store"
                }
            ],
            "propertyModifiers": [],
            "buildingUsages": [
                {
                    "name": "Boligformål",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 26000,
                    "ignoreFloorFactor": false
                },
                {
                    "name": "Takoverbygg",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 0,
                    "ignoreFloorFactor": true
                },
                {
                    "name": "Fritidsformål",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 30000,
                    "ignoreFloorFactor": false
                },
                {
                    "name": "Industri, p-hus",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 0,
                    "ignoreFloorFactor": true
                },
                {
                    "name": "Leiligheter",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 26000,
                    "ignoreFloorFactor": false
                },
                {
                    "name": "Næringsformål",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 0,
                    "ignoreFloorFactor": true
                },
                {
                    "name": "Plasthall",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 0,
                    "ignoreFloorFactor": true
                },
                {
                    "name": "Garasje",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 4000,
                    "ignoreFloorFactor": false
                },
                {
                    "name": "Takoverbygg næring",
                    "buildingCodes": null,
                    "settingsId": null,
                    "price": 0,
                    "ignoreFloorFactor": true
                }
            ],
            "buildingUsageByCode": false,
            "floorFactors": [
                {
                    "name": "H",
                    "factor": 1
                },
                {
                    "name": "K",
                    "factor": 0.3
                },
                {
                    "name": "U",
                    "factor": 0.8
                },
                {
                    "name": "Loft",
                    "factor": 0.7
                }
            ],
            "useAreaFactors": false,
            "useAreaFactorsPrice": false,
            "areaFactors": [
                {
                    "name": null,
                    "from": 0,
                    "to": 99,
                    "factor": 1,
                    "price": 0
                },
                {
                    "name": null,
                    "from": 100,
                    "to": 199,
                    "factor": 0.9,
                    "price": 0
                },
                {
                    "name": null,
                    "from": 200,
                    "to": 299,
                    "factor": 0.8,
                    "price": 0
                },
                {
                    "name": null,
                    "from": 300,
                    "to": 0,
                    "factor": 0.4,
                    "price": 0
                }
            ],
            "settingsEffectDate": "2022-03-01T07:57:00Z",
            "settingsChangeComment": "Eiendomsskatteprosjekt 2022-23",
            "minBuildingArea": 15,
            "livingSpacePlot": 1000,
            "maxHousingSize": 5000,
            "attachmentPlotValue": 50,
            "pointlessSize": 1000,
            "unknownPlotSize": 1000,
            "valuePerParkingSpace": 50000,
            "tenantId": "5e33ee7b6b92105480de385d",
            "currentUser": "John Smith",
            "databaseZones": []
        },
        yearSettings: {
            "year": 2021,
            "municipalityAdjustment": "YES",
            "municipalityAdjustmentValue": 10,
            "nationalValuation": "NO",
            "minimumTax": 50,
            "propertyType": "a",
            "propertyTypeZones": [],
            "exemptZones": [],
            "zoneRates": {},
            "differentRateForDwelling": true,
            "differentRateWithoutBuildings": false,
            "differentRateForLand": false,
            "differentRateForZones": null,
            "defaultTaxRate": 0.5,
            "dwellingTaxRate": 0.3,
            "rateWithBuildings": 0.2,
            "rateWithoutBuildings": 0.1,
            "buildingsRate": null,
            "landRate": null,
            "reductionFactor": 45,
            "useFlatReduction": "NO",
            "flatReductionValue": 1100000,
            "exemptionGovernmentBeneficial": false,
            "exemptionHistoricValue": false,
            "exemptionNewBuildings": true,
            "exemptionNewBuildingsValue": 6,
            "exemptionZones": false,
            "exemptionSummerHouses": null,
            "minBuildingArea": 15,
            "livingSpacePlot": 1000,
            "maxHousingSize": 200,
            "attachmentPlotValue": 50,
            "pointlessSize": 1000,
            "unknownPlotSize": 1000,
            "valuePerParkingSpace": 50000,
            "numberOfTerms": 3,
            "closed": true
        }
    },
    postalAddress: {
        addressText: 'Trulsrudskogen 98',
        postalInfo: '1350 OSLO',
        name: 'Ola Normann',
    },
    company: {
        name: 'De Sakkyndige',
        phone: '88888888',
        webpage: 'desakkyndige.no',
        postalAddress: {
            addressText: 'Sakkyndigveien 32',
            postalCode: '1470',
        },
    },
    loggedinuser: {
        name: 'Bruker Navn',
        type: 'Company',
        title: 'Brukerens tittel',
    },
    loggedinusercompany: {
        name: 'De Sakkyndige',
        phone: '88888888',
        webpage: 'desakkyndige.no',
        postalAddress: {
            addressText: 'Sakkyndigveien 32',
            postalCode: '1470',
        },
        visitingAddress: {
            addressText: 'Sakkyndigveien 30',
            postalCode: '1470',
        },
    },
    loggedinusercompanypostalcode: {
        code: '1470',
        city: 'LØRENSKOG',
    },
}

export const initialTemplateDocumentValues = {
    height: 82,
    documentType: 'DOCUMENT_TEMPLATE',
    elements: [
        {
            name: 'Logo',
            type: 'gridcompanylogo',
            id: '000000000000000000000000',
            top: 8.596875,
            left: 17.332291666666666,
            width: 40,
            height: 15,
        },
        {
            name: 'Postadresse',
            type: 'postaladdress',
            id: '000000000000000000000000',
            top: 45.83177083333334,
            left: 23.1328125,
            width: 80,
            height: 25,
        },
        {
            name: 'Overskrift',
            type: 'header',
            id: '000000000000000000000000',
            top: 67.63020833333333,
            left: 129,
            width: 100,
            height: 20,
        },
        {
            name: 'Sammendrag',
            type: 'summary',
            id: '000000000000000000000000',
            top: 25.965625,
            left: 129,
            width: 70,
            height: 50,
        },
        {
            name: 'Returpostadresse',
            type: 'returnaddress',
            id: '000000000000000000000000',
            top: 8.596875,
            left: 62.3328125,
            width: 40,
            height: 15,
        },
        {
            name: 'Brødtekst',
            type: 'maincontents',
            id: '000000000000000000000000',
            top: 81.43333333333334,
            left: -0.4666666666666667,
            width: 210,
            height: 10,
        },
    ],
    footerHeight: 23,
    footerElements: [
        {
            name: 'Footer #1',
            type: 'text',
            id: '5c3476b10fa05c1648ebeb04',
            top: 5,
            left: 14,
            width: 40,
            height: 16,
        },
        {
            name: 'Footer #2',
            type: 'text',
            id: '5c35dc7210600e460c1d7c77',
            top: 5,
            left: 69,
            width: 40,
            height: 16,
        },
        {
            name: 'Bunntekst - Sidetall',
            type: 'text',
            id: '5c3701fc10600e460c1d7ff6',
            top: 9.431770833333333,
            left: 178.99947916666667,
            width: 25,
            height: 10,
        },
    ],
}
