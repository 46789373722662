import React from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import { InjectedFormProps, reduxForm, Field } from 'redux-form'
import { TribunalGroup } from 'tribunal-page/models/tribunal-group'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { TEXT_GROUP_NAME, TEXT_GROUP_DESCRIPTION } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

const useStyles = makeStyles((theme: Theme) => {
    return {
        detailsContainer: {
            marginBottom: theme.spacing(4),
        },
    }
})

const Form: React.FC<InjectedFormProps<TribunalGroup>> = props => {
    const { handleSubmit } = props
    const styles = useStyles()

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Box className={styles.detailsContainer}>
                <Field name="name" component={CubitTextFieldAdapter} label={tr(TEXT_GROUP_NAME)} />
            </Box>
            <Box className={styles.detailsContainer}>
                <Field
                    name="description"
                    component={CubitTextFieldAdapter}
                    multiline
                    label={tr(TEXT_GROUP_DESCRIPTION)}
                />
            </Box>
        </form>
    )
}

export const TribunalGroupFormName = 'tribunalGroupForm'

export const TribunalGroupForm = reduxForm<TribunalGroup>({
    form: TribunalGroupFormName,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
