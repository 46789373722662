import { useSelector } from 'react-redux'
import { AppState } from './app-store'

export const useUsers = () => useSelector((state: AppState) => state.appData.users)

export const useCompanies = () => useSelector((state: AppState) => state.appData.companies)

export const useRoles = () => useSelector((state: AppState) => state.appData.roles)

export const useWelcomeUser = () => useSelector((state: AppState) => state.appData.welcomeUser)
