import React from 'react'
import { IconButton, makeStyles, createStyles, Theme, List, ListItem, ListItemText, Collapse } from "@material-ui/core"
import { useState } from "react"
import { ArrowDropDown } from "@material-ui/icons"
//import { RaceOperator } from 'rxjs/internal/observable/race'

type ImageTransferButtonsProps = {
    imageId: number,
    fieldId: number,
    fields: any,
    handleImageTransfer: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
      top: '100%',
      right: 0,
    },
    item: {
        padding: '3px 16px',
    },
    list: {
        padding: 0
    }
  }),
);

export const ImageTransferButtons = (props: ImageTransferButtonsProps) => {
    const { imageId, fieldId, fields, handleImageTransfer } = props
    const [listOpen, setListOpen] = useState(false)
    const classes = useStyles();

    return (
        <IconButton
            size="small"
            onClick={(e) => {
                e.stopPropagation()
            }}
            onMouseEnter={() => setListOpen(true)}
            onMouseLeave={() => setListOpen(false)}
            style={{ backgroundColor: 'rgba(255,255,255,0.26)' }}
        >
            <ArrowDropDown />
                <div className={classes.root}>
                    <Collapse in={listOpen}>
                            <List className={classes.list}>
                                {fields.getAll().map((field: any, index: number) => (
                                    <ListItem
                                        className={classes.item}
                                        key={index}
                                        button
                                        onClick={() => {
                                            handleImageTransfer(fieldId, index, imageId, fields)
                                            setListOpen(false)
                                        }}    
                                    >
                                        <ListItemText secondary={field.buildingNumber} />
                                    </ListItem>
                                ))}
                            </List>
                    </Collapse>
                </div>
            
        </IconButton>
    )
}