import { VerifiableProperty } from 'models/property'
import getVerifiedValuesCount from './get-verified-values-count'
import { PROPERTY_VERIFIED_VALUES_TOTAL } from 'common/constants/constants'

export default (property: VerifiableProperty): VerifiableProperty => {
    const verifiedPropsCount = getVerifiedValuesCount(property.verifiedValues)

    return {
        ...property,
        verifiedInfo: {
            count: verifiedPropsCount,
            total: PROPERTY_VERIFIED_VALUES_TOTAL,
        },
    }
}
