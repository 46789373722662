import React from 'react'
import { Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { AttachFile, InsertDriveFile, LocalPhone, MailOutlined, PhoneAndroid } from '@material-ui/icons'
import classnames from 'classnames'
import { getDate } from '../helper-functions'
import { senderAsString } from './helper'
import { tr } from 'utils/translations/translate'
import {
    TEXT_COPY,
    TEXT_NEW_EMAIL,
    TEXT_NEW_LETTER,
    TEXT_NEW_SMS,
    TEXT_REGISTER_PHONECALL,
} from 'utils/translations/keys'

export const Controls = ({ classes, onCreate }: any) => {
    return (
        <div className={classes.controls}>
            <Button className={classes.button} variant="contained" onClick={() => onCreate('email')}>
                <MailOutlined className={classes.buttonIcon} />
                {tr(TEXT_NEW_EMAIL)}
            </Button>
            <Button className={classes.button} variant="contained" onClick={() => onCreate('letter')}>
                <InsertDriveFile className={classes.buttonIcon} />
                {tr(TEXT_NEW_LETTER)}
            </Button>
            <Button className={classes.button} variant="contained" onClick={() => onCreate('sms')}>
                <PhoneAndroid className={classes.buttonIcon} />
                {tr(TEXT_NEW_SMS)}
            </Button>
            <Button className={classes.button} variant="contained" onClick={(_) => onCreate('phone')}>
                <LocalPhone className={classes.buttonIcon} />
                {tr(TEXT_REGISTER_PHONECALL)}
            </Button>
        </div>
    )
}

export const EntryIcon = ({ className, type }: any) => {
    switch (type) {
        case 'email':
            return <MailOutlined className={className} />
        case 'sms':
            return <PhoneAndroid className={className} />
        case 'letter':
            return <InsertDriveFile className={className} />
        case 'phone':
            return <LocalPhone className={className} />
        default:
            return <></>
    }
}

export const CommunicationEntry = ({ classes, entry, onClick }: any) => {
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    return (
        <div
            className={classnames(classes.commEntry, entry.notLinked && classes.notLinked)}
            onClick={() => onClick(entry)}
        >
            <div
                className={classnames(classes.commEntryIndicator, entry.incoming && classes.commEntryIndicatorIncoming)}
            >
                <EntryIcon
                    className={classnames(classes.commEntryIcon, entry.incoming && classes.commEntryIconIncoming)}
                    type={entry.type}
                />
            </div>
            <div className={classes.commEntrySender}>{senderAsString(entry)}</div>
            {entry.isCopy && <div className={classes.copy}>{tr(TEXT_COPY)}</div>}
            {entry.notHandled && <div className={classes.redBall}></div>}
            {entry.subject && <div className={classes.commEntrySubject}>{entry.subject}</div>}
            <div className={classes.commEntryBody}>{entry.textBody}</div>
            {entry.attachments && entry.attachments.length > 0 && (
                <div className={classes.commEntryAttachmentIcon}>
                    <AttachFile />
                </div>
            )}
            <div className={classes.commEntryTimestamp}>{getDate(entry.timeStamp, language)}</div>
        </div>
    )
}
