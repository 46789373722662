import React from 'react'
import { makeStyles, Theme, Grid, Button } from '@material-ui/core'
import { InjectedFormProps, reduxForm, getFormValues } from 'redux-form'
import { isTaxationFinished, Taxation, TaxationState } from 'models/taxation'
import { useDispatch, useSelector } from 'react-redux'
// @ts-ignore
import 'react-lightbox-component/build/css/index.css'
import {
    TEXT_COMPLAINT_FOLLOWED,
    TEXT_OPEN_COMPLAINT,
    TEXT_SEND_TO_APPRAISER,
} from 'utils/translations/keys'
import { ComplaintContent } from './property-taxation-complaint-content'
import { Complaint } from 'tribunal-page/models/complaint.type'
import { useUsers } from 'app/app-selectors'
import { UserSelection } from 'common/user-selection/user-selection'
import { getUsersArray } from 'models/user'
import { actionPlanningEventsCreate } from 'planning-page/planning-actions'
import { CalendarEventType } from 'shared-components/src/models/calendar-event'
import { actionComplaintUpdate } from 'complaint-page/complaint-actions'
import { ComplaintStatus } from 'tribunal-page/enums/complaint-status.enum'
import { forEach } from 'lodash'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { tr } from 'utils/translations/translate'
import { actionTaxationUpdate } from './taxation-actions'
import { getSessionKey } from 'utils'
import { AppState } from 'app/app-store'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            fontSize: theme.spacing(2),
        },
        buttonsList: {
            padding: theme.spacing(0, 0, 3, 0),
        },
        complaintButton: {
            padding: theme.spacing(1, 2),
            marginLeft: theme.spacing(2),
            minWidth: 200,
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 1.25,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginLeft: 0,
                marginTop: theme.spacing(2)
            },
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            transition: 'border-color 0.2s ease-in',
            height: 44,
            '&:hover': {
                borderWidth: 2,
                borderColor: theme.palette.primary.light,
            },
        },
        appraiserSelect: {
            '& .MuiSelect-select': {
                color: theme.palette.primary.main,
                padding: '13px 37px 13px 13px',
                minWidth: 200,
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: 1.25,
                textTransform: 'uppercase',
                textAlign: 'center',
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                },
            },
            '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
                transition: 'border-color 0.2s ease-in'
            },
            '& .MuiSelect-icon': {
                color: theme.palette.primary.main,
                marginRight: 10
            },
            '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.light,
            },
        },
        complaintContainer: {
            fontSize: 25,
            textAlign: 'center',
            paddingTop: '15vh'
        }
    }
})

type CompaintsArrayObject = {
    [key: string]: Complaint[]
}
type PropertyTaxationComplaintsProps = {
    form: string
    taxation: Taxation
}

const Form: React.FC<InjectedFormProps<CompaintsArrayObject, PropertyTaxationComplaintsProps> & PropertyTaxationComplaintsProps> = (
    props,
) => {
    const { form, taxation } = props
    const styles = useStyles()
    const users = useUsers()
    const dispatch = useDispatch()
    const complaintsArray = useSelector(getFormValues(form)) as CompaintsArrayObject
    const complaints = complaintsArray.complaints || []

    const createComplaintTaxation = (userId: string) => {
        dispatch(actionPlanningEventsCreate([taxation.current.property], userId, CalendarEventType.ComplaintTribunal))
        forEach(complaints, (complaint: Complaint) =>  
            dispatch(actionComplaintUpdate({...complaint, status: ComplaintStatus.Processed}))
        )
    }
    const handleCauseApproval = () => {
        forEach(complaints, (complaint: Complaint) => {
            dispatch(actionComplaintUpdate({...complaint, status: ComplaintStatus.Accepted}))
        })
        //open the taxation for editing
        taxation.state = TaxationState.TaxationStarted
        dispatch(actionTaxationUpdate(taxation))
    }
    //every complaint causes are reviewed
    const readyForTribunal = complaints.every((complaint: Complaint) => complaint.status === ComplaintStatus.ReadyForTribunal)
    const auth: any = useSelector((state: AppState) => state.auth)
    const tenantId = auth.isInitialized && auth.user.tenantId
    return (
        <form>
            {readyForTribunal && 
                <Protected p={Permission.TribunalApproveDecline}>
                    <Grid container spacing={0} className={styles.buttonsList}>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <UserSelection
                                usersArray={getUsersArray(users)} 
                                onChange={(userId: string) => createComplaintTaxation(userId)}
                                placeholderTranslationKey={TEXT_SEND_TO_APPRAISER}
                                customClass={styles.appraiserSelect}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleCauseApproval()}
                            className={styles.complaintButton}
                        >
                            {tr(TEXT_COMPLAINT_FOLLOWED)}
                        </Button>
                        </Grid>
                    </Grid>
                </Protected>
            }
            <Grid container className={styles.container}>
                {complaints.map((complaint: Complaint, index: number) => 
                    <ComplaintContent complaint={complaint} taxation={taxation} key={index} complaintIndex={index}/>
                )}
            </Grid>
            {isTaxationFinished(taxation) && 
                <Grid container className={styles.complaintContainer}>
                    <Grid item xs={12}>
                        <Button 
                            variant="outlined"
                            href={`https://skjema-dev.cubit.no/login/${getSessionKey()}/tax/${taxation.id}/${tenantId}`}
                            color="primary"
                            target="_blank"
                        >
                            {tr(TEXT_OPEN_COMPLAINT)}
                        </Button>
                    </Grid>
                </Grid>
            }
        </form>
    )
}

export const PropertyTaxationComplaints = reduxForm<CompaintsArrayObject, PropertyTaxationComplaintsProps>({
    enableReinitialize: true,
    destroyOnUnmount: false,
})(Form)
