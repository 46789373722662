/* eslint-disable */
import React from 'react'
import { EditorState, convertFromRaw, CompositeDecorator } from 'draft-js'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { suggestionOptions } from 'common/editor/editor-helper'
import { DateTime } from 'luxon'
import { getPropertyAddresses, getPropertyValueTypeTranslationKey } from 'utils'
import { simpleFormatNumber } from 'shared-components/src/utils/format/format-number'
import { getBillingReceiver } from 'models/taxation'

export const mergeFieldStrategy = (contentBlock: any, callback: any, contentState: any) => {
    const mergeFields = Object.values(suggestionOptions)
    let content = contentBlock.getText()
    mergeFields.forEach((option) => {
        const optionText = `@${option}`
        const index = content.indexOf(optionText)
        if (index !== -1) {
            //send it to the replace component
            callback(index, index + optionText.length)
        }
    })
}

export const replaceMergeFields = (text: string, data: any) => {
    const cleanText = text.substring(1)
    const owner = data && data.taxation ? data.taxation.current.owners[0] : null
    const owners = data.taxation.current.owners ?? []
    const calculation = data && data.taxation ? data.taxation.calculationDetails : null
    const property = data && data.taxation ? data.taxation.current.property : null
    const housingUnits = data && data.taxation ? data.taxation.current.housingUnits : null
    const taxation = data && data.taxation ? data.taxation : null
    const yearSettings = data?.taxation?.yearSettings ?? {}
    const taxSettings = data?.taxation?.staticSettings ?? {}
    switch (cleanText) {
        case suggestionOptions.PAGE_NUMBER:
            return data.pageNumber ? data.pageNumber : '1'
        case suggestionOptions.TOTAL_PAGES:
            return data.noOfPages ? data.noOfPages : '1'
        case suggestionOptions.TAXATION_DATE:
            return taxation && taxation.startDate
                ? DateTime.fromISO(data.taxation.startDate).toFormat('dd.MM.yyyy HH:mm')
                : DateTime.local().toFormat('dd.MM.yyyy HH:mm')
        case suggestionOptions.PROPERTY_ADDRESS:
        case suggestionOptions.ADDRESS:
            return housingUnits ? getPropertyAddresses(housingUnits) : '-'
        case suggestionOptions.PROPERTY_MATRIKKEL_ADDRESS:
        case suggestionOptions.MATRIKKEL:
            return property
                ? property.holdingNumber +
                '/' +
                property.subHoldingNumber +
                '/' +
                property.leaseNumber +
                '/' +
                property.sectionNumber
                : '-'
        case suggestionOptions.OWNER_ADDRESS:
            return owner ? owner.postalAddress.addressText + ', ' + owner.postalAddress.postalCode : '-'
        case suggestionOptions.CONTACT_PHONE:
            return owner ? owner.phone : '-'
        case suggestionOptions.PROPERTY_VALUE:
        case suggestionOptions.VALUE:
        case suggestionOptions.VALUE_AFTER_FACTORS:
            return calculation
                ? simpleFormatNumber(
                    calculation.dwellingBuildingsValue +
                    calculation.otherBuildingsValue +
                    calculation.dwellingLandValue +
                    calculation.otherLandValue
                )
                : '-'
        case suggestionOptions.PROPERTY_TAX:
            return calculation ? simpleFormatNumber(calculation.taxValue) : '-'
        case suggestionOptions.NOTICE_DATE:
            return DateTime.local().toFormat('dd.MM.yyyy')
        case suggestionOptions.USER_NAME:
            return taxation?.staticSettings?.currentUser || '-'
        case suggestionOptions.OWNERS:
            return owners.map((x: any) => x.name ?? `${x.firstName} ${x.lastName}`).join(', ')
        case suggestionOptions.INVOICE_RECEIVER:
            return getBillingReceiver(taxation)
        case suggestionOptions.EXEMPT:
            return property.exempt || calculation.exemptions?.includes('Exempt') ? 'Ja' : 'Nei'
        case suggestionOptions.VALUE_TYPE:
            return tr(getPropertyValueTypeTranslationKey(property.valueType))
        case suggestionOptions.UNITS_COUNT:
            return housingUnits?.length ?? 0
        case suggestionOptions.TAX_DEPARTMENT_VALUE:
            return simpleFormatNumber(property.otherPropertyFlags?.nationalValuation ?? 0)
        case suggestionOptions.REDUCTION_FACTOR:
            return yearSettings.reductionFactor ? yearSettings.reductionFactor / 100 : 0
        case suggestionOptions.REDUCTION:
            return simpleFormatNumber(yearSettings.flatReductionValue ?? 0)
        case suggestionOptions.OTHER_TAX_RATE:
            return yearSettings.defaultTaxRate ?? 0
        case suggestionOptions.RESIDENTIAL_TAX_RATE:
            return yearSettings.dwellingTaxRate ?? 0
        case suggestionOptions.YEARLY_TAX_VALUE:
            return simpleFormatNumber(calculation?.taxValue ?? 0)
        case suggestionOptions.ZONE_FACTOR:
            return property.zoneFactor
        case suggestionOptions.LOCATION_FACTOR:
            return property.locationFactor
        case suggestionOptions.MANUAL_TAX_VALUE:
            return simpleFormatNumber(property.otherPropertyFlags?.manualValue ?? 0)

        default:
            return cleanText
    }
}

export const mergeFieldReplaceSpan = (data: any) => {
    return (props: any) => {
        const initialtext = props.decoratedText
        const text = replaceMergeFields(initialtext, data)
        return <span className="decorator">{text}</span>
    }
}

export const compositeReplaceDecorator = (data: any) => {
    return new CompositeDecorator([
        {
            strategy: mergeFieldStrategy,
            component: mergeFieldReplaceSpan(data),
        },
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ])
}

export const mergeFieldSpan = (props: any) => {
    return (
        <a href="" className="rdw-mention-link">
            {props.children}
        </a>
    )
}

export const compositeDecorator = new CompositeDecorator([
    {
        strategy: mergeFieldStrategy,
        component: mergeFieldSpan,
    },
])

const FULL_OBJECT_COLUMNS = ['displayName', 'zone', 'reason', 'status', 'address', 'customer', 'type']
export const getValueFromObject = (obj: any, prop: any, func: any, valueForNull: any) => {
    if (func) {
        return FULL_OBJECT_COLUMNS.indexOf(prop) !== -1 || prop.indexOf('full_') !== -1 ? func(obj) : func(obj[prop])
    }
    let splitprop = prop.split('.')
    let traverser = obj
    for (let p of splitprop) {
        traverser = traverser[p]
        if (traverser === undefined) break
        if (traverser === null) break
    }
    return traverser || valueForNull || 'XXXXX'
}

export const textFromTextTemplates = (texttemplates: any, data: any, audit: any) => {
    let texts: any = []
    texttemplates.forEach((tt: any) => {
        if (tt.type === 'contactdefined') {
            if (audit.auditManualContactName && audit.auditManualContactName.length > 0) {
                let contentState = convertFromRaw(tt.state)
                let state = EditorState.createWithContent(contentState, compositeReplaceDecorator(data))
                texts.push({ ...tt, state })
            }
        } else if (tt.type === 'contactnotdefined') {
            if (!audit.auditManualContactName || audit.auditManualContactName.length === 0) {
                let contentState = convertFromRaw(tt.state)
                let state = EditorState.createWithContent(contentState, compositeReplaceDecorator(data))
                texts.push({ ...tt, state })
            }
        } else if (tt.type === 'generalremark') {
            if (audit.comment && audit.comment.length > 0) {
                let contentState = convertFromRaw(tt.state)
                let state = EditorState.createWithContent(contentState, compositeReplaceDecorator(data))
                texts.push({ ...tt, state })
            }
        } else if (tt.type === 'copyrecipients') {
            if (audit.contacts && audit.contacts.filter((c: any) => c.isCopyRecipient).length > 0) {
                let contentState = convertFromRaw(tt.state)
                let state = EditorState.createWithContent(contentState, compositeReplaceDecorator(data))
                texts.push({ ...tt, state })
            }
        } else {
            let contentState = convertFromRaw(tt.state)
            let state = EditorState.createWithContent(contentState, compositeReplaceDecorator(data))
            texts.push({ ...tt, state })
        }
    })
    return texts
}

export const byProperty = (prop: any, asc = true, func?: any, valueForNull?: any) => {
    if (asc) {
        return (a: any, b: any) => {
            if (getValueFromObject(a, prop, func, valueForNull) < getValueFromObject(b, prop, func, valueForNull))
                return -1
            if (getValueFromObject(a, prop, func, valueForNull) > getValueFromObject(b, prop, func, valueForNull))
                return 1
            return 0
        }
    }
    return (a: any, b: any) => {
        if (getValueFromObject(a, prop, func, valueForNull) > getValueFromObject(b, prop, func, valueForNull)) return -1
        if (getValueFromObject(a, prop, func, valueForNull) < getValueFromObject(b, prop, func, valueForNull)) return 1
        return 0
    }
}

export const dummywkparams = {
    page: 'X',
    toPage: 'Y',
}

export function getBgUrl(el: any) {
    if (!el) return null
    var bg = ''
    if (el.currentStyle) {
        // IE
        bg = el.currentStyle.backgroundImage
    } else if (document.defaultView && document.defaultView.getComputedStyle) {
        // Firefox
        bg = document.defaultView.getComputedStyle(el, '').backgroundImage
    } else {
        // try and get inline style
        bg = el.style.backgroundImage
    }
    return bg.replace(/url\(['"]?(.*?)['"]?\)/i, '$1')
}
export const formatOrgNumber = (organizationNumber: string) => {
    if (!organizationNumber) return organizationNumber
    return (
        organizationNumber.toString().slice(0, 3) +
        ' ' +
        organizationNumber.toString().slice(3, 6) +
        ' ' +
        organizationNumber.toString().slice(6)
    )
}
export const padLeft = (str: string, length: number) => {
    if (!str) return str
    if (!length) length = 4
    if (str.toString().length > length) return str.toString()
    let padstring = '0000' + str
    return padstring.substring(padstring.length - length)
}
export const editorStyleMap = {
    BOLD: { fontWeight: 600 },
    ITALIC: { fontStyle: 'italic' },
    UNDERLINE: { textDecoration: 'underline' },
    SIZE8: { fontSize: '8pt' },
    SIZE9: { fontSize: '9pt' },
    SIZE10: { fontSize: '10pt' },
    SIZE11: { fontSize: '11pt' },
    SIZE12: { fontSize: '12pt' },
    SIZE13: { fontSize: '13pt' },
    SIZE14: { fontSize: '14pt' },
    SIZE15: { fontSize: '15pt' },
    SIZE16: { fontSize: '16pt' },
    'COLOR#000000': { color: '#000000' },
    //'COLOR#333333': { color: '#333333'},
    'COLOR#808080': { color: '#808080' },
    'COLOR#D0021B': { color: '#D0021B' },
    'COLOR#1163CC': { color: '#1163CC' },
    'COLOR#417505': { color: '#417505' },
    LINK: { textDecoration: 'underline', color: '#2b7bb9' },
}
export const imageloadchecker = (element: any) => {
    let image = document.createElement('img')
    let imageurl = null
    if (typeof element === 'string') {
        imageurl = element
    } else {
        imageurl = getBgUrl(element)
    }
    if (imageurl) {
        image.src = imageurl
        image.onload = function () {
            window.status = 'imageloaded'
        }
    }
}

export const staticTemplateElements = [
    { label: tr(t.TEXT_GRID_OWNER_LOGO), type: 'gridcompanylogo', width: 50, height: 18 },
    { label: tr(t.TEXT_POST_ADDRESS), type: 'postaladdress', width: 60, height: 20 },
    { label: tr(t.TEXT_HEADLINE), type: 'header', width: 100, height: 10 },
    { label: tr(t.TEXT_SUMMARY), type: 'summary', width: 80, height: 50 },
    { label: tr(t.TEXT_RETURN_POST_ADDRESS), type: 'returnaddress', width: 40, height: 15 },
    { label: tr(t.TEXT_HORIZONTAL_LINE), type: 'divider', width: 210, height: 1 },
]

const Link = ({ entityKey, contentState, children }: any) => {
    const { url, targetOption = '_self' } = contentState.getEntity(entityKey).getData()
    return <a href={url} target={targetOption} >{children}</a>
}

const findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity()

        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
}
