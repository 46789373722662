import { tr } from 'utils/translations/translate'
import {
    TEXT_OBJECT_TYPES,
    TEXT_STATUS,
    TEXT_ASSIGNED,
    TEXT_ZONE,
    TEXT_FROM,
    TEXT_TO,
    TEXT_NO_STATUS,
    TEXT_PICKED,
    TEXT_AWARDED,
    TEXT_ACTIVE_CASE,
    TEXT_PROPERTY_VALUE,
    TEXT_EXEMPTION,
    TEXT_OWNER_CATEGORY,
    TEXT_PRIVATE,
    TEXT_STANDARD,
    TEXT_LOW,
    TEXT_NORMAL,
    TEXT_HIGH,
    TEXT_LOCATION,
    TEXT_ALL,
    TEXT_ME,
    TEXT_PARTICULARLY_FAVORABLE,
    TEXT_PARTICULARLY_UNFAVORABLE,
    TEXT_TAX_SUM,
    TEXT_DEMOLITION_OBJECT,
    TEXT_ADVANCED_DECAY_RESTORATION,
    TEXT_BUSINESS,
    TEXT_TAXATION_DATE,
    TEXT_COMPANIES,
    TEXT_USERS,
    TEXT_NO_EXEMPTION,
    TEXT_EXEMPTION_BY_REASON,
    TEXT_NO_TAXATION,
    TEXT_IGANGSETTELSESTILATELSE,
    TEXT_YEARS_SINCE_STATUS_WAS_SET,
    TEXT_MATRIKKEL_STATUS,
    TEXT_ROUTE,
    TEXT_NOTIFICATION_SENT,
    TEXT_MUNICIPALITY_OWNER,
    TEXT_USES_NATIONAL_SETTINGS,
    TEXT_DOES_NOT_USE_NATIONAL_SETTINGS,
    TEXT_ATTRIBUTES,
    TEXT_FINISHED_TAXATION,
    TEXT_YEAR_TAX_SENT,
    TEXT_YEAR_TAX_NOT_SENT,
    TEXT_TAXATION,
    TEXT_MANUAL_VALUE,
} from 'utils/translations/keys'
import { CubitCheckboxGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import CubitDatePickerAdapter from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-date-picker-adapter'
import { InputType } from 'shared-components/src/cubit-inputs/input-type.enum'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import {
    CubitSliderRangeAdapter,
    lastMarkStyle,
    firstMarkStyle,
} from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-slider-range-adapter'
import { FormField, SelectOptionType } from 'shared-components/src/cubit-inputs/cubit-form-field.types'
import { User } from 'models/user'
import { Company } from 'models/company'
import { CubitSelectGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-group-adapter'
import { getSelectYearOptions } from 'utils/form/search-form'
import {
    getExemptReasonOptions,
    getPropertyRoutesOptions,
    getPropertyValueTypeOptions,
} from 'utils/property/property-helper'
import { PropertyStatus } from 'models/property'
import { getZoneOptions } from 'settings-page/helper'
import { getAttributesFiltersOptions } from 'common/attributes/attributes-helper'

const PRIVATE = 'PRIVATE'
const COMPANY = 'COMPANY'
const USER = 'USER'

export const searchFiltersFormFields =
    (
        companies: Company[],
        currentUserId: string,
        users: { [key: string]: User },
        usersWithoutCompanyArray: User[],
        settings: any,
        attributes: string[]
    ) =>
    () => {
        return {
            valueType: (): FormField<InputType.Select> => ({
                type: InputType.Select,
                props: {
                    name: 'valueType',
                    label: tr(TEXT_OBJECT_TYPES),
                    component: CubitSelectAdapter,
                    options: getPropertyValueTypeOptions(),
                },
            }),

            zones: (): FormField<InputType.Checkbox> => ({
                type: InputType.Checkbox,
                props: {
                    name: 'zones',
                    label: tr(TEXT_ZONE),
                    component: CubitCheckboxGroupAdapter,
                    //TODO get dynamic options from settings
                    options: getZoneOptions(settings),
                },
            }),

            assigned: (): FormField<InputType.Select> => ({
                type: InputType.Select,
                props: {
                    name: 'assigned',
                    label: tr(TEXT_ASSIGNED),
                    component: CubitSelectAdapter,
                    options: [
                        { label: tr(TEXT_ALL), value: null },
                        { label: tr(TEXT_ME), value: { type: USER, value: currentUserId } },
                        { type: SelectOptionType.Divider },
                        { label: tr(TEXT_COMPANIES), type: SelectOptionType.Title },
                        { type: SelectOptionType.Divider },

                        ...companies.reduce((acc: any, company: Company) => {
                            return [
                                ...acc,
                                {
                                    label: company.name,
                                    value: { type: COMPANY, value: company.id },
                                },
                                ...company.users.map((userId) => ({
                                    label: users[userId].name,
                                    value: { type: USER, value: users[userId].id },
                                    style: { paddingLeft: '32px' },
                                })),
                                { type: SelectOptionType.Divider },
                            ]
                        }, []),
                        { label: tr(TEXT_USERS), type: SelectOptionType.Title },
                        { type: SelectOptionType.Divider },
                        ...usersWithoutCompanyArray.map((u) => ({
                            label: u.name,
                            value: { type: USER, value: u.id },
                        })),
                    ],
                },
            }),

            status: (): FormField<InputType.Select> => ({
                type: InputType.Select,
                props: {
                    name: 'status',
                    label: tr(TEXT_STATUS),
                    component: CubitSelectAdapter,
                    options: [
                        { label: tr(TEXT_NO_STATUS), value: PropertyStatus.NORMAL },
                        { label: tr(TEXT_PICKED), value: PropertyStatus.PICKED },
                        { label: tr(TEXT_AWARDED), value: 'ASSIGNED' },
                        { label: tr(TEXT_ACTIVE_CASE), value: PropertyStatus.ACTIVE },
                        { label: tr(TEXT_NOTIFICATION_SENT), value: PropertyStatus.NOTIFIED },
                        { label: tr(TEXT_FINISHED_TAXATION), value: PropertyStatus.TAXATION_FINISHED },
                        { label: tr(TEXT_YEAR_TAX_SENT), value: PropertyStatus.YEAR_LETTER_SENT },
                        { label: tr(TEXT_YEAR_TAX_NOT_SENT), value: PropertyStatus.YEAR_LETTER_NOT_SENT },
                        { label: tr(TEXT_TAXATION), value: PropertyStatus.TAXATED },
                        { label: tr(TEXT_MANUAL_VALUE), value: PropertyStatus.MANUAL_VALUE },
                    ],
                },
            }),

            propertyValue: (): FormField<InputType.NumberRange> => ({
                type: InputType.NumberRange,
                name: 'propertyValue',
                label: tr(TEXT_PROPERTY_VALUE),
                from: {
                    props: {
                        name: 'propertyValue.from',
                        label: tr(TEXT_FROM),
                        type: 'number',
                        component: CubitTextFieldAdapter,
                    },
                },
                to: {
                    props: {
                        name: 'propertyValue.to',
                        label: tr(TEXT_TO),
                        type: 'number',
                        component: CubitTextFieldAdapter,
                    },
                },
            }),

            tax: (): FormField<InputType.NumberRange> => ({
                type: InputType.NumberRange,
                name: 'tax.from',
                label: tr(TEXT_TAX_SUM),

                from: {
                    props: {
                        name: 'tax.from',
                        label: tr(TEXT_FROM),
                        type: 'number',
                        component: CubitTextFieldAdapter,
                    },
                },
                to: {
                    props: {
                        name: 'tax.to',
                        label: tr(TEXT_TO),
                        type: 'number',
                        component: CubitTextFieldAdapter,
                    },
                },
            }),

            exemption: (): FormField<InputType.Checkbox> => ({
                type: InputType.Checkbox,
                props: {
                    name: 'exemption',
                    label: tr(TEXT_EXEMPTION),
                    component: CubitCheckboxGroupAdapter,
                    options: [
                        { label: tr(TEXT_NO_EXEMPTION), value: 'false' },
                        { label: tr(TEXT_EXEMPTION), value: 'true' },
                        //{ label: tr(TEXT_EXEMPTION_BY_REASON), value: 'reason' },
                    ],
                },
            }),

            exemptionByReason: (): FormField<InputType.Select> => ({
                type: InputType.Select,
                props: {
                    name: 'exemptionByReason',
                    label: tr(TEXT_EXEMPTION_BY_REASON),
                    component: CubitSelectAdapter,
                    options: getExemptReasonOptions(),
                },
            }),

            noTaxation: (): FormField<InputType.Checkbox> => ({
                type: InputType.Checkbox,
                props: {
                    name: 'noTaxation',
                    label: tr(TEXT_NO_TAXATION),
                    component: CubitCheckboxGroupAdapter,
                    options: [{ label: tr(TEXT_NO_TAXATION), value: 'true' }],
                },
            }),

            nationalValuation: (): FormField<InputType.Checkbox> => ({
                type: InputType.Checkbox,
                props: {
                    name: 'nationalValuation',
                    label: tr(TEXT_USES_NATIONAL_SETTINGS),
                    component: CubitCheckboxGroupAdapter,
                    options: [
                        { label: tr(TEXT_USES_NATIONAL_SETTINGS), value: 'true' },
                        { label: tr(TEXT_DOES_NOT_USE_NATIONAL_SETTINGS), value: 'false' },
                    ],
                },
            }),

            municipalityOwner: (): FormField<InputType.Checkbox> => ({
                type: InputType.Checkbox,
                props: {
                    name: 'municipalityOwner',
                    label: tr(TEXT_MUNICIPALITY_OWNER),
                    component: CubitCheckboxGroupAdapter,
                    options: [{ label: tr(TEXT_MUNICIPALITY_OWNER), value: 'true' }],
                },
            }),

            matrikkelStatus: (): FormField<InputType.SelectGroup> => ({
                type: InputType.SelectGroup,
                props: {
                    name: 'matrikkelStatus',
                    label: tr(TEXT_MATRIKKEL_STATUS),
                    component: CubitSelectGroupAdapter,
                    instances: [
                        {
                            label: tr(TEXT_MATRIKKEL_STATUS),
                            name: 'matrikkelStatus',
                            options: [{ label: tr(TEXT_IGANGSETTELSESTILATELSE), value: 'igangsettingstillatelse' }],
                        },
                        {
                            label: tr(TEXT_YEARS_SINCE_STATUS_WAS_SET),
                            name: 'yearsSinceStatus',
                            options: getSelectYearOptions(99),
                        },
                    ],
                },
            }),

            ownerCategory: (): FormField<InputType.Select> => ({
                type: InputType.Select,
                props: {
                    name: 'ownerCategory',
                    label: tr(TEXT_OWNER_CATEGORY),
                    component: CubitSelectAdapter,
                    options: [
                        { label: tr(TEXT_BUSINESS), value: COMPANY },
                        { label: tr(TEXT_PRIVATE), value: PRIVATE },
                    ],
                },
            }),

            standard: (): FormField<InputType.SliderRange> => ({
                type: InputType.SliderRange,
                props: {
                    name: 'standard',
                    label: tr(TEXT_STANDARD),
                    component: CubitSliderRangeAdapter,
                    marks: {
                        '0': { style: firstMarkStyle, label: tr(TEXT_DEMOLITION_OBJECT) },
                        '0.4': { style: firstMarkStyle, label: tr(TEXT_ADVANCED_DECAY_RESTORATION) },
                        '0.8': { style: firstMarkStyle, label: tr(TEXT_LOW) },
                        1: tr(TEXT_NORMAL),
                        '1.2': { style: lastMarkStyle, label: tr(TEXT_HIGH) },
                    },
                    step: null as unknown as undefined,
                    allowCross: false,
                    min: 0,
                    max: 1.2,
                },
            }),

            location: (): FormField<InputType.SliderRange> => ({
                type: InputType.SliderRange,
                props: {
                    name: 'location',
                    label: tr(TEXT_LOCATION),
                    component: CubitSliderRangeAdapter,
                    marks: {
                        '0.8': {
                            style: firstMarkStyle,
                            label: tr(TEXT_PARTICULARLY_UNFAVORABLE),
                        },
                        1: tr(TEXT_NORMAL),
                        '1.2': {
                            style: lastMarkStyle,
                            label: tr(TEXT_PARTICULARLY_FAVORABLE),
                        },
                    },
                    step: 0.2,
                    allowCross: false,
                    min: 0.8,
                    max: 1.2,
                },
            }),

            evaluationDate: (): FormField<InputType.DateRange> => ({
                type: InputType.DateRange,
                name: 'evaluationDate',
                label: tr(TEXT_TAXATION_DATE),
                from: {
                    props: {
                        name: 'evaluationDate.from',
                        label: tr(TEXT_FROM),
                        component: CubitDatePickerAdapter,
                    },
                },
                to: {
                    props: {
                        name: 'evaluationDate.to',
                        label: tr(TEXT_TO),
                        component: CubitDatePickerAdapter,
                    },
                },
            }),
            routes: (): FormField<InputType.Checkbox> => ({
                type: InputType.Checkbox,
                props: {
                    name: 'routes',
                    label: tr(TEXT_ROUTE),
                    component: CubitCheckboxGroupAdapter,
                    options: getPropertyRoutesOptions(),
                },
            }),
            attributes: (): FormField<InputType.Checkbox> => ({
                type: InputType.Checkbox,
                props: {
                    name: 'attributes',
                    label: tr(TEXT_ATTRIBUTES),
                    component: CubitCheckboxGroupAdapter,
                    options: getAttributesFiltersOptions(attributes),
                },
            }),
        }
    }
