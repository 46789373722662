import React from 'react'
import { get } from 'lodash'
import { Tooltip } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import classes from './diff.module.css'
import { Factor } from 'models/property'

type DiffProps = {
    /** Property key to compare*/
    prop: string
    /** Previous object */
    prev: any
    /** Current object */
    curr: any
    /** Map of property translation keys */
    translationKeysFn?: any
    /** Function which formats output */
    formatFn?: any
    /** Wether to show or hide value  */
    hideValue?: boolean
    /** Factors object from settings */
    factors?: Factor[] | null
}

export const Diff: React.FC<DiffProps> = props => {
    const { prop, prev, curr, translationKeysFn, formatFn, hideValue, factors = null } = props

    const previousValue = get(prev, prop) || null
    const currentValue = get(curr, prop) || null

    const previousValueText = getValueText(previousValue, translationKeysFn, formatFn, hideValue, factors)
    const currentValueText = getValueText(currentValue, translationKeysFn, formatFn, hideValue, factors)

    return previousValue !== currentValue ? (
        <Tooltip title={previousValueText}>
            <div className={classes.diff}>
                <span className={classes.diffText}>{currentValueText}</span>
            </div>
        </Tooltip>
    ) : (
        <>{currentValueText}</>
    )
}

const getValueText = (value: any, translationKeysFn: any, formatFn: any, hideValue: boolean | undefined, factors: Factor[] | null): string => {
    let values = []
    const val = formatFn ? formatFn(value) : value

    if (!hideValue) {
        values.push(val)
    }

    if (translationKeysFn && factors) {
        values.push(translationKeysFn(val, factors))
    } else if (translationKeysFn) {
        values.push(tr(translationKeysFn(val)))
    }

    return values.join(' ')
}
