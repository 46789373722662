import { ActivityLog } from '../../models/activity-log'
import { AnyAction } from 'redux'

export const ACTION_ACTIVITY_LOG_LOAD_SUCCESS = 'ACTION_ACTIVITY_LOG_LOAD_SUCCESS'

export const actionActivityLogLoadSuccess = (activityLog: ActivityLog, objectId: string): AnyAction => ({
    type: ACTION_ACTIVITY_LOG_LOAD_SUCCESS,
    activityLog,
    objectId,
})
