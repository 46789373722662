import { from } from 'rxjs'
import superagent, { Response, SuperAgentRequest } from 'superagent'
import config from '../config'
import { getSessionKey } from 'utils'

const setSessionKey = (agent: SuperAgentRequest) => {
    const sessionKey = getSessionKey()
    if (sessionKey === undefined) {
        return agent
    } else {
        return agent.set('Authorization', `Bearer ${sessionKey}`).set('Authentication', `session ${sessionKey}`)
    }
}

const selectBody = (response: Response) => response.body

export const httpGet = (url: string) => from(setSessionKey(superagent.get(url)).then(selectBody))

export const httpPost = (url: string, data: any) =>
    from(
        setSessionKey(superagent.post(url))
            .send(data)
            .then(selectBody),
    )

export const httpUpload = (url: string, files: any, data?: any) => {
    const agent = superagent.post(url)

    files.map((file: any) => agent.attach('documents', file))

    if (data) {
        return from(setSessionKey(agent).send(data).then(selectBody))
    }

    return from(setSessionKey(agent).then(selectBody))
}

export const httpPut = (url: string, data: any) =>
    from(
        setSessionKey(superagent.put(url))
            .send(data)
            .then(selectBody),
    )

export const httpDelete = (url: string, data: any) =>
    from(
        setSessionKey(superagent.delete(url))
            .send(data)
            .then(selectBody),
    )

export const lanternUrl = (url: string): string => config.lantersUrl + url

export const plantsUrl = (url: string): string => config.plantsUrl + url

export const auditFrontUrl = (url: string): string => config.auditFrontUrl + url
