import { makeReducer } from 'utils'
import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import {
    ACTION_GET_REPORTS_SETTINGS,
    ACTION_GET_REPORTS_SETTINGS_SUCCESS,
    ACTION_UPDATE_REPORTS_SETTINGS,
    ACTION_UPDATE_REPORTS_SETTINGS_SUCCESS,
    ACTION_GET_TAX_SETTINGS,
    ACTION_GET_TAX_SETTINGS_SUCCESS,
    ACTION_UPDATE_TAX_SETTINGS,
    ACTION_UPDATE_TAX_SETTINGS_SUCCESS,
    ACTION_TAX_SETTINGS_FORM_RESET,
    ACTION_CLOSE_YEAR_SETTINGS,
    ACTION_GET_TAX_SETTINGS_HISTORY,
    ACTION_GET_TAX_SETTINGS_HISTORY_SUCCESS,
    ACTION_GET_TEMPLATES,
    ACTION_GET_TEMPLATE,
    ACTION_CREATE_TEMPLATE,
    ACTION_UPDATE_TEMPLATE,
    ACTION_CREATE_TEMPLATE_SUCCESS,
    ACTION_GET_TEMPLATE_SUCCESS, 
    ACTION_GET_TEMPLATES_SUCCESS, 
    ACTION_GET_TEMPLATES_BY_TYPE,
    ACTION_GET_TEMPLATES_BY_TYPE_SUCCESS,
    ACTION_NAVIGATE_TO_TEMPLATE,
    ACTION_UPDATE_TEMPLATE_SUCCESS,
    ACTION_UPLOAD_TEMPLATE_IMAGE,
    ACTION_DELETE_TEMPLATE,
    ACTION_DELETE_TEMPLATE_SUCCESS,
    ACTION_UPDATE_CASE_FLOW_ENTRIES,
    ACTION_UPDATE_CASE_FLOW_ENTRIES_SUCCESS,
    ACTION_GET_CASE_FLOW_ENTRIES,
    ACTION_GET_CASE_FLOW_ENTRIES_SUCCESS,
    ACTION_UPLOAD_CSV_FILE,
    ACTION_UPLOAD_CSV_FILE_SUCCESS,
    ACTION_SET_ACTIVE_PANEL,
    ACTION_UPLOAD_CSV_FILE_FAILED,
    ACTION_CALCULATE_YEAR_TAX_SUCCESS
} from './settings-actions'
import { DocumentTypes } from 'common/enums/document-types'
import { navigate } from '@reach/router'
import { RouteNames } from 'common/enums/routeNames'

interface TaxSettingsInterface {
    readonly yearSettings: string
    readonly taxSettings: string
    readonly history: string
    readonly taxFormReset: string
    readonly templates: string
    readonly [DocumentTypes.SMS]: string
    readonly [DocumentTypes.TEXT]: string
    readonly [DocumentTypes.EMAIL]: string
    readonly [DocumentTypes.CASE_DOCUMENT]: string
    readonly [DocumentTypes.LETTER]: string
    readonly [DocumentTypes.IMAGE]: string
    readonly caseFlow: string
}

export type TaxSettingsState = {
    yearSettings: any
    taxSettings: any
    history: any
    taxFormReset: boolean
    templates: any
    [DocumentTypes.SMS]: any
    [DocumentTypes.TEXT]: any
    [DocumentTypes.EMAIL]: any
    [DocumentTypes.CASE_DOCUMENT]: any
    [DocumentTypes.LETTER]: any
    [DocumentTypes.IMAGE]: any
    caseFlow: any
    activePanel: any
    uploading: boolean,
    error: boolean
}

type SettingsReducer = ActionReducer<TaxSettingsState>

const getyearSettings: SettingsReducer = (state) => state

const getReportsSuccess: SettingsReducer = (state, action) => ({
    ...state,
    yearSettings: action.settings,
})

const updateyearSettings: SettingsReducer = (state) => ({
    ...state,
    uploading: true,
})

const updateyearSettingsSuccess: SettingsReducer = (state, action) => ({
    ...state,
    yearSettings: action.settings,
})

const calculateYearTaxSuccess: SettingsReducer = (state) => ({
    ...state,
    uploading: false,
})

const closeYearSettings: SettingsReducer = (state) => {
    const lastYearSettings = { ...state.yearSettings.settings[0] }
    lastYearSettings.year = lastYearSettings.year + 1
    state.yearSettings.settings[0].closed = true
    state.yearSettings.settings.unshift(lastYearSettings)
    return state
}

const getTaxSettings: SettingsReducer = (state) => ({
    ...state,
    taxSettings: {
        ...state.taxSettings,
        loaded: true,
    },
})

const getTaxSettingsSuccess: SettingsReducer = (state, action) => ({
    ...state,
    taxSettings: {
        loaded: true,
        ...action.settings,
    },
})

const getTaxSettingsHistory: SettingsReducer = (state) => state

const getTaxSettingsHistorySuccess: SettingsReducer = (state, action) => ({
    ...state,
    history: action.settings,
})

const updateTaxSettings: SettingsReducer = (state) => ({
    ...state,
    taxFormReset: true,
})

const updateTaxSettingsSuccess: SettingsReducer = (state, action) => ({
    ...state,
    history: [...state.history, action.settings],
    taxFormReset: false,
})

const taxSettingsFormReset: SettingsReducer = (state, action) => ({
    ...state,
    taxFormReset: action.reset,
})

const getTemplate: SettingsReducer = (state) => state

const getTemplateSuccess: SettingsReducer = (state, action) => {
    const docType: keyof TaxSettingsInterface = action.template.documentType
    return {
        ...state,
        [docType]: {
            ...state[docType],
            templates: {
                ...state[docType].templates,
                [action.template.id]: action.template,
            }
        }
    }
}

const getTemplates: SettingsReducer = (state) => {
    state[DocumentTypes.CASE_DOCUMENT] = {
        loading: true,
        loaded: false
    }
    state[DocumentTypes.EMAIL] = {
        loading: true,
        loaded: false
    }
    state[DocumentTypes.SMS] = {
        loading: true,
        loaded: false
    }
    state[DocumentTypes.LETTER] = {
        loading: true,
        loaded: false
    }
    return state
}

const getTemplatesSuccess: SettingsReducer = (state, action) => {
    const templates = action.templates
    templates.forEach((template: any) => {
        const docType: keyof TaxSettingsInterface = template.documentType
        state[docType] = {
            ...state[docType],
                loading: false,
                loaded: true,
            templates: {
                ...state[docType].templates,
                [template.id]: template,
            }
        }
    })
    return state
}

const getTemplatesByType: SettingsReducer = (state, action) => {
    const docType: keyof TaxSettingsInterface = action.documentType
    return {
        ...state,
        [action.documentType]: {
            ...state[docType],
            loading: true,
            loaded: false
        },
    }
}

const getTemplatesByTypeSuccess: SettingsReducer = (state, action) => {
    const newTemplates: any = {}
    Object.values(action.templates).forEach((t: any) => newTemplates[t.id] = t)
    return {
        ...state,
        [action.documentType]: {
            templates: newTemplates,
            loaded: true,
            loading: false
        },
    }
}

const createTemplate: SettingsReducer = (state) => state

const createTemplateSuccess: SettingsReducer = (state, action) => {
    const docType: keyof TaxSettingsInterface = action.template.documentType
    return {
        ...state,
        [docType]: {
            ...state[docType],
            templates: {
                ...state[docType].templates,
                [action.template.id]: action.template,
            }
        },
    }
}

const uploadTemplateImage: SettingsReducer = (state) => state
const uploadCsvFile: SettingsReducer = (state) => ({
    ...state,
    uploading: true,
    error: false
})
const uploadCsvFileSuccess: SettingsReducer = (state) => ({
    ...state,
    uploading: false
})
const uploadCsvFileFailed: SettingsReducer = (state) => ({
    ...state,
    uploading: false,
    error: true,
})
const deleteTemplate: SettingsReducer = (state) => state

const deleteTemplateSuccess: SettingsReducer = (state, action) => {
    const docType: keyof TaxSettingsInterface = action.template.documentType
    const templates = state[docType].templates
    delete templates[action.template.id]
    return state
}

const updateTemplate: SettingsReducer = (state) => state

const navigateToTemplate: SettingsReducer = (state, action) => {
    const docType: keyof typeof RouteNames = action.template.documentType
    navigate(`/${RouteNames.settings}/${RouteNames[docType]}/${action.template.id}`)
    return state
}

const getCaseFlowEntries: SettingsReducer = (state) => state

const getCaseFlowEntriesSuccess: SettingsReducer = (state, action) => {
    action.entries.forEach((entry: any) => {
        state.caseFlow[entry.id] = entry
    })
    return state
}

const updateCaseFlowEntries: SettingsReducer = (state) => state

const updateCaseFlowEntriesSuccess: SettingsReducer = (state, action) => (
    {
        ...state,
        caseFlow: {
            ...state.caseFlow,
            [action.entry.id]: action.entry
        }
    }
)

const setActivePanel: SettingsReducer = (state, action) => ({
    ...state,
    [action.taxation.id]: action.panel,
})

const reducers: Reducers<TaxSettingsState> = {
    [ACTION_GET_REPORTS_SETTINGS]: getyearSettings,
    [ACTION_GET_REPORTS_SETTINGS_SUCCESS]: getReportsSuccess,
    [ACTION_UPDATE_REPORTS_SETTINGS]: updateyearSettings,
    [ACTION_UPDATE_REPORTS_SETTINGS_SUCCESS]: updateyearSettingsSuccess,
    [ACTION_CLOSE_YEAR_SETTINGS]: closeYearSettings,
    [ACTION_GET_TAX_SETTINGS]: getTaxSettings,
    [ACTION_GET_TAX_SETTINGS_SUCCESS]: getTaxSettingsSuccess,
    [ACTION_UPDATE_TAX_SETTINGS]: updateTaxSettings,
    [ACTION_UPDATE_TAX_SETTINGS_SUCCESS]: updateTaxSettingsSuccess,
    [ACTION_TAX_SETTINGS_FORM_RESET]: taxSettingsFormReset,
    [ACTION_GET_TAX_SETTINGS_HISTORY]: getTaxSettingsHistory,
    [ACTION_GET_TAX_SETTINGS_HISTORY_SUCCESS]: getTaxSettingsHistorySuccess,
    [ACTION_GET_TEMPLATES]: getTemplates,
    [ACTION_GET_TEMPLATE]: getTemplate,
    [ACTION_CREATE_TEMPLATE]: createTemplate,
    [ACTION_UPDATE_TEMPLATE]: updateTemplate,
    [ACTION_CREATE_TEMPLATE_SUCCESS]: createTemplateSuccess,
    [ACTION_UPDATE_TEMPLATE_SUCCESS]: createTemplateSuccess,
    [ACTION_GET_TEMPLATE_SUCCESS]: getTemplateSuccess,
    [ACTION_GET_TEMPLATES_SUCCESS]: getTemplatesSuccess,
    [ACTION_GET_TEMPLATES_BY_TYPE]: getTemplatesByType,
    [ACTION_GET_TEMPLATES_BY_TYPE_SUCCESS]: getTemplatesByTypeSuccess,
    [ACTION_NAVIGATE_TO_TEMPLATE]: navigateToTemplate,
    [ACTION_UPLOAD_TEMPLATE_IMAGE]: uploadTemplateImage,
    [ACTION_DELETE_TEMPLATE]: deleteTemplate,
    [ACTION_DELETE_TEMPLATE_SUCCESS]: deleteTemplateSuccess,
    [ACTION_GET_CASE_FLOW_ENTRIES]: getCaseFlowEntries,
    [ACTION_GET_CASE_FLOW_ENTRIES_SUCCESS]: getCaseFlowEntriesSuccess,
    [ACTION_UPDATE_CASE_FLOW_ENTRIES]: updateCaseFlowEntries,
    [ACTION_UPDATE_CASE_FLOW_ENTRIES_SUCCESS]: updateCaseFlowEntriesSuccess,
    [ACTION_UPLOAD_CSV_FILE]: uploadCsvFile,
    [ACTION_UPLOAD_CSV_FILE_SUCCESS]: uploadCsvFileSuccess,
    [ACTION_UPLOAD_CSV_FILE_FAILED]: uploadCsvFileFailed,
    [ACTION_SET_ACTIVE_PANEL]: setActivePanel,
    [ACTION_CALCULATE_YEAR_TAX_SUCCESS]: calculateYearTaxSuccess,
}

export const taxSettingsReducer = makeReducer<TaxSettingsState>(reducers, {
    activePanel: null,
    yearSettings: null,
    taxSettings: { loaded: false },
    history: null,
    taxFormReset: false,
    templates: {},
    caseFlow: {},
    [DocumentTypes.SMS]: {
        templates: {},
        loaded: false,
        loading: false
    },
    [DocumentTypes.TEXT]: {
        templates: {},
        loaded: false,
        loading: false
    },
    [DocumentTypes.EMAIL]: {
        templates: {},
        loaded: false,
        loading: false
    },
    [DocumentTypes.CASE_DOCUMENT]: {
        templates: {},
        loaded: false,
        loading: false
    },
    [DocumentTypes.LETTER]: {
        templates: {},
        loaded: false,
        loading: false
    },
    [DocumentTypes.IMAGE]: {
        templates: {},
        loaded: false,
        loading: false
    },
    uploading: false,
    error: false
})
