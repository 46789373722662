import { useLocation } from '@reach/router'

export const useQuery = (queryParam: string) => {
    const search = new URLSearchParams(useLocation().search)

    return search.get(queryParam) || ''
}

export const useWindowQuery = (queryParam: string) => {
    const search = new URLSearchParams(window.location.search)

    return search.get(queryParam)
}
