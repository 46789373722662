import { combineEpics } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { filter, mergeMap, map, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { httpPost, plantsUrl, httpGet, httpPut, httpDelete } from 'services/httpService'
import {
    ACTION_PLANNING_EVENTS_CREATE,
    actionPlanningEventsCreateSuccess,
    ACTION_PLANNING_GET_USER_EVENTS,
    actionPlanningGetUserEventsSuccess,
    ACTION_PLANNING_GET_UNPLANNED_EVENTS,
    actionPlanningGetUnplannedEventsSuccess,
} from './planning-actions'
import { Property } from 'models/property'
import {
    ACTION_CUBIT_CALENDAR_EVENTS_SAVE,
    actionCubitCalendarSaveSuccess,
    ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN,
    actionCubitCalendarEventUnassignSuccess,
    actionCubitCalendarEventsRemoveSuccess,
    ACTION_CUBIT_CALENDAR_EVENTS_REMOVE,
} from 'shared-components/src/cubit-calendar/calendar-actions'
import { CalendarEventInfo, CubitCalendarEvent } from 'shared-components/src/models/calendar-event'
import { actionCubitSnackbarShow } from 'common/cubit-snackbar/cubit-snackbar-actions'
import { tr } from 'utils/translations/translate'
import { TEXT_SCHEDULED_TAXATION_FOR } from 'utils/translations/keys'
import { AppState } from 'app/app-store'
import { CubitCalendarExtendedProps } from 'models/calendar-event'

export const createEvents = (action$: Observable<AnyAction>, state$: Observable<AppState>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_PLANNING_EVENTS_CREATE),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpPost(
                    plantsUrl('/tax/events/'),
                    action.items.map((property: Property) => ({
                        relatedEntityId: property.id,
                        userId: action.userId,
                        type: action.eventType,
                    })),
                ).pipe(
                    mergeMap((items: any[]) =>
                        of(
                            actionPlanningEventsCreateSuccess(items),
                            actionCubitSnackbarShow(
                                `${tr(TEXT_SCHEDULED_TAXATION_FOR)} ${state.appData.users[action.userId].name}`,
                            ),
                        ),
                    ),
                ),
        ),
    )

export const getUserEvents = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_PLANNING_GET_USER_EVENTS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/events/${action.userId}/${action.year}/${action.month}`)).pipe(
                    map((events: CalendarEventInfo<CubitCalendarExtendedProps>[]) => {
                        return actionPlanningGetUserEventsSuccess(events)
                    }),
                ),
        ),
    )

export const saveModifiedEvents = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_CUBIT_CALENDAR_EVENTS_SAVE),
        mergeMap(
            (action): Observable<AnyAction> => {
                return httpPut(plantsUrl('/tax/events/'), action.events).pipe(
                    map((events: CubitCalendarEvent<CubitCalendarExtendedProps>[]) => actionCubitCalendarSaveSuccess(events)),
                )
            },
        ),
    )

export const saveUnassignedEvent = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN),
        mergeMap(
            (action): Observable<AnyAction> => {
                const event: CubitCalendarEvent<CubitCalendarExtendedProps> = action.event
                return httpPut(plantsUrl('/tax/events/'), [{ ...event.extendedProps, start: null, end: null }]).pipe(
                    map((events: CubitCalendarEvent<CubitCalendarExtendedProps>[]) => actionCubitCalendarEventUnassignSuccess(events)),
                )
            },
        ),
    )

export const deleteEvent = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_CUBIT_CALENDAR_EVENTS_REMOVE),
        mergeMap(
            (action): Observable<AnyAction> => {
                const events: CubitCalendarEvent<CubitCalendarExtendedProps>[] = action.events
                return httpDelete(plantsUrl('/tax/events/'), events.map(event => event.extendedProps.id)).pipe(
                    map((deletedEventIds: number[]) => actionCubitCalendarEventsRemoveSuccess(deletedEventIds)),
                )
            },
        ),
    )

export const getUserUnplannedEvents = (action$: Observable<AnyAction>, state$: Observable<AppState>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_PLANNING_GET_UNPLANNED_EVENTS),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> => {
                return httpGet(plantsUrl(`/tax/unplanned/${action.userId}`)).pipe(
                    map((events: CalendarEventInfo<CubitCalendarExtendedProps>[]) => {
                        return actionPlanningGetUnplannedEventsSuccess(events)
                    }),
                )
            }
        ),
    )

export const planningEpics = combineEpics(
    createEvents,
    getUserEvents,
    saveModifiedEvents,
    saveUnassignedEvent,
    deleteEvent,
    getUserUnplannedEvents,
)
