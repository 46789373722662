import React from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import { InjectedFormProps, reduxForm, Field } from 'redux-form'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { TEXT_NOTE } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

const useStyles = makeStyles((theme: Theme) => {
    return {
        detailsContainer: {
            marginBottom: theme.spacing(4),
        },
    }
})

export type TribunalTaxationDeclineFormProps = {
    reason: string
}

const Form: React.FC<InjectedFormProps<TribunalTaxationDeclineFormProps>> = props => {
    const { handleSubmit } = props
    const styles = useStyles()

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Box className={styles.detailsContainer}>
                <Field name="reason" component={CubitTextFieldAdapter} multiline label={tr(TEXT_NOTE)} />
            </Box>
        </form>
    )
}

export const TribunalTaxationDeclineFormName = 'tribunalTaxationDeclineForm'

export const TribunalTaxationDeclineForm = reduxForm<TribunalTaxationDeclineFormProps>({
    form: TribunalTaxationDeclineFormName,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
