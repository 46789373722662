import React from 'react'
import { navigate } from '@reach/router'
import { Property } from 'models/property'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'
import { CubitTableColumn } from 'shared-components/src/cubit-table/cubit-table.types'
import { arrayDistinct, getPropertyAddresses, isPropertyTaxable } from 'utils'
import {
    TEXT_ADDRESS,
    TEXT_EXEMPTION,
    TEXT_LOCATION,
    TEXT_STATUS,
    TEXT_TARIFF,
    TEXT_TAX,
    TEXT_USE_OF_LAND,
    TEXT_ZONE,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { Truncate } from 'common/truncate/truncate'
import { formatCurrency, formatNumber } from 'shared-components/src/utils'
import { getPropertyBuildingsValue } from 'utils/property/get-property-buildings-value'
import { getExemptText, getMatrikkelAddress } from 'utils/property/property-helper'
import { Tooltip } from '@material-ui/core'
import { getStatusDate, getStatusText } from 'utils/property/property-helper'
import { SearchResult } from 'search-page/search-reducer'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'

export const PropertiesTableName = 'properties'

const propertiesColumns = (companies: any, users: any, settings: any): CubitTableColumn[] => [
    {
        headerLabel: 'Grd/br/',
        key: 'holdingNumber',
        getDisplayableElement: (row) => <span>{getMatrikkelAddress(row)}</span>,
    },
    {
        headerLabel: tr(TEXT_ADDRESS),
        key: 'housingUnits.address',
        getDisplayableElement: (row) => <Truncate>{getPropertyAddresses(row.housingUnits)}</Truncate>,
        //width: '450px',
    },
    {
        headerLabel: tr(TEXT_USE_OF_LAND),
        key: 'housingUnits.type',
        getDisplayableElement: (row) => (
            <span>{`${arrayDistinct(row.housingUnits.map((x: any) => x.type))
                .filter((y: any) => !!y && y !== 'Unummerert bruksenhet')
                .sort()
                .join(', ')}`}</span>
        ),
    },
    {
        headerLabel: tr(TEXT_TARIFF),
        key: 'ratedValue',
        align: 'right',
        getDisplayableElement: (row) => (
            <span>
                {formatNumber(getPropertyBuildingsValue(row, settings), 0)}
            </span>
        ),
    },
    {
        headerLabel: tr(TEXT_TAX),
        key: 'tax',
        getDisplayableElement: (row) => <span>{formatCurrency(row.taxInfo.tax || 0)}</span>,
    },
    { headerLabel: tr(TEXT_LOCATION), key: 'locationFactor' },
    //{ headerLabel: tr(TEXT_STANDARD), key: 'standardFactor' },
    { headerLabel: tr(TEXT_ZONE), key: 'zoneFactor' },
    {
        headerLabel: tr(TEXT_EXEMPTION),
        key: 'exemptReason',
        getDisplayableElement: (row) => (
            <span>{row.exemptReason && row.exempt ? getExemptText(row.exemptReason) : ''}</span>
        ),
    },
    {
        headerLabel: tr(TEXT_STATUS),
        key: 'status',
        getDisplayableElement: (row) => (
            <span>{row.status ? row.status : getStatusTooltip(row, companies, users)}</span>
        ),
    },
]

const getStatusTooltip = (property: Property, companies: any, users: any) => {
    const assignedCompanyId = property.state?.companyId
    const assignedUserId = property.state?.userId

    const assignedCompanyName =
        assignedCompanyId && companies[assignedCompanyId] ? companies[assignedCompanyId].name : ''
    const assignedUserName = assignedUserId && users[assignedUserId] ? users[assignedUserId].name : ''

    const tooltipText = assignedUserName || assignedCompanyName

    return (
        <Tooltip title={tooltipText}>
            <span>{`${getStatusText(property)} ${getStatusDate(property, 'nb')}`}</span>
        </Tooltip>
    )
}

interface PropertiesTable {
    searchResult: SearchResult<any>
    companies: any
    users: any
}

const PropertiesTable: React.FC<PropertiesTable> = ({ searchResult, companies, users }) => {
    const handleRowClick = (row: Property) => {
        navigate(`/property/${row.id}`)
    }
    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)

    return (
        <CubitTable
            selectable={isPropertyTaxable}
            sorting
            columns={propertiesColumns(companies, users, settings)}
            data={searchResult.items}
            name={PropertiesTableName}
            onRowClick={handleRowClick}
            style={{ tableLayout: 'fixed' }}
        />
    )
}

export default PropertiesTable
