import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { useDispatch, useSelector } from 'react-redux'
import { tr } from 'utils/translations/translate'
import {
    TEXT_PROPERTY,
    TEXT_VALUE_TYPE,
    TEXT_TARIFF,
    TEXT_LAST_TARIFF,
    TEXT_ZONE_FACTOR,
    TEXT_LOCATION_FACTOR,
    TEXT_STANDARD_FACTOR,
    TEXT_FACTOR_CHANGE,
    TEXT_METRIC_CHANGE,
    TEXT_NOTE,
    TEXT_INCOMPETENT,
    TEXT_YES,
    TEXT_FILTER_TAXATIONS,
    TEXT_SEND_NEW_TAX,
} from 'utils/translations/keys'
import { makeStyles, Theme, CircularProgress, Paper, Typography, Grid, TextField, Button, IconButton } from '@material-ui/core'
import { AppState } from 'app/app-store'
import { navigate, RouteComponentProps } from '@reach/router'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { actionGetGroupDetails, actionSendGroupLetters } from 'tribunal-page/tribunal-actions'
import { CubitTableColumn } from 'shared-components/src/cubit-table/cubit-table.types'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'
import { useUsers } from 'app/app-selectors'
import { formatFactor } from 'property-page/taxations-history/functions'
import { formatCurrency, getPropertyValueTypeTranslationKey, getSessionKey } from 'utils'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { TribunalApprovedContextBar } from './tribunal-approved-context-bar'
import { GroupStatus } from 'tribunal-page/enums/group-status'
import { CloudDownload } from '@material-ui/icons'
import { plantsUrl } from 'services/httpService'

const useStyles = makeStyles((theme: Theme) => ({
    block: {
        display: 'block'
    },
    header: {
        fontWeight: 'bold',
        fontSize: 16,
        padding: '8px 13px'
    }
}))

export const TribunalApprovedGroupPage: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
    const { id } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const users = useUsers()
    const [filterText, setFilterText] = React.useState('')
    const [downloading, setDownloading] = React.useState(false)

    const groupDetails = useSelector((state: AppState) => state.tribunal.approvedDetails)
    const sendingLetters = useSelector((state: AppState) => state.tribunal.sendingLetters)

    const data = groupDetails?.taxationDetails ?? []

    const loading = groupDetails?.loading

    useEffect(() => {
        if (!groupDetails || groupDetails?.group?.id !== id) {
            dispatch(actionGetGroupDetails(id as string))
        }
        setDownloading(false)
    }, [dispatch, id])

    const groupColumns = (): CubitTableColumn[] => [
        {
            headerLabel: tr(TEXT_PROPERTY),
            key: 'matrikkelAddress',
            getDisplayableElement: (item) => (
                <span>{item.matrikkelAddress}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_PROPERTY),
            key: 'address',
            getDisplayableElement: (item) => (
                <span>{item.address}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_VALUE_TYPE),
            key: 'valueType',
            getDisplayableElement: (item) => (
                <span>{tr(getPropertyValueTypeTranslationKey(item.valueType))}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_TARIFF),
            key: 'tax',
            getDisplayableElement: (item) => (
                <span>{formatCurrency(item.tax)}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_ZONE_FACTOR),
            key: 'zoneFactor',
            getDisplayableElement: (item) => (
                <span>{formatFactor(item.zoneFactor)}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_LOCATION_FACTOR),
            key: 'locationFactor',
            getDisplayableElement: (item) => (
                <span>{formatFactor(item.locationFactor)}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_STANDARD_FACTOR),
            key: 'standardFactors',
            getDisplayableElement: (item) => (
                <span>{item.standardFactors.map((factor: number, index: number) => <span key={index} className={styles.block}>{formatFactor(factor)}</span>)}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_INCOMPETENT),
            key: 'conflictedUsers',
            getDisplayableElement: (item) => (
                <span>{item.conflictedUsers.map((id: string, index: number) => <span key={index} className={styles.block}>{getUserName(id)}</span>)}</span>
            ),
        },
    ]

    const getUserName = (id: string) => {
        const user = users[id]
        return user?.name
    }

    const handleClick = (rowData: any) => {
        navigate(`/tribunal/group/approved/${id}/taxation/${rowData.id}`)
    }

    const handleFilter = (details: any) => {
        if (filterText !== '' && details) {
            return details.matrikkelAddress?.toLowerCase().includes(filterText.toLowerCase()) ||
                details.address?.toLowerCase().includes(filterText.toLowerCase()) ||
                tr(getPropertyValueTypeTranslationKey(details.valueType))?.toLowerCase().includes(filterText.toLowerCase()) ||
                details.tax?.toString()?.toLowerCase().includes(filterText.toLowerCase()) ||
                details.previousTax?.toString()?.toLowerCase().includes(filterText.toLowerCase()) ||
                details.conflictedUsers?.some((id: string) => getUserName(id)?.toLowerCase()?.includes(filterText.toLowerCase()))
        } else {
            return true
        }
    }

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={
                <TitleToolbar title={groupDetails?.group?.name}>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="inherit"
                                type="button"
                                disabled={sendingLetters || groupDetails?.group?.status === GroupStatus.NOTIFIED || loading}
                                onClick={() => dispatch(actionSendGroupLetters([id as string]))}
                            >
                                {!sendingLetters ? tr(TEXT_SEND_NEW_TAX) : <CircularProgress color="inherit" />}
                            </Button>
                        </Grid>
                        <Grid item>
                            <IconButton
                                disabled={downloading || loading}
                                color="inherit"
                                href={plantsUrl(`/groups/exportGroupDetails/${id}/${getSessionKey()}`)}
                            >
                                <CloudDownload />
                            </IconButton>
                        </Grid>
                    </Grid>
                </TitleToolbar>
            }
            contextbar={<TribunalApprovedContextBar />}
        >
            <Protected p={Permission.ViewTribunalAll}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            value={filterText}
                            color="primary"
                            onChange={e => setFilterText(e.target.value)}
                            placeholder={tr(TEXT_FILTER_TAXATIONS)}
                            margin="normal"
                            variant="outlined"
                            style={{ margin: '0px 0px 8px 0px' }}
                        />
                    </Grid>
                </Grid>
                <Paper elevation={0}>
                    {!loading && <>
                        <Typography className={styles.header}>{groupDetails?.group?.name}</Typography>
                        <CubitTable
                            sorting
                            flat
                            columns={groupColumns()}
                            data={data.filter(handleFilter)}
                            name={'approvedGroupDetails'}
                            onRowClick={handleClick}
                        />
                    </>}
                    {loading && <CircularProgress />}
                </Paper>
            </Protected>
        </LayoutDefault>
    )
}
