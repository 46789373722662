import React from 'react'
import { IconButton, makeStyles, Modal, Theme } from '@material-ui/core'
import { StaticTemplateType, Taxation } from 'models/taxation'
import { FileCopy } from '@material-ui/icons'
import { getSessionKey } from 'utils'
import { PropertyValueType } from 'models/property'
import { tr } from 'utils/translations/translate'
import { TEXT_TAX_LETTER_PREVIEW } from 'utils/translations/keys'

const useStyles = makeStyles((theme: Theme) => {
    return {
        doc: {
            maxWidth: 1000,
            width: '100%',
            height: '98%',
            margin: '0 auto',
            position: 'absolute',
            right: 0,
            left: 0,
            top: '1%',
            overflowX: 'hidden'
        }
    }
})

type TaxPreviewProps = {
    taxation?: Taxation
}

export const TaxPreview: React.FC<TaxPreviewProps> = (props) => {
    const {
        taxation
    } = props

    const styles = useStyles()

    const [open, setOpen] = React.useState(false)
    const path = window.location.origin

    const session = getSessionKey()

    if (!taxation) {
        return <></>
    }

    const owner = taxation.current.property.ownerShips.find((x: any) => x.isInvoiceRecipient)
    const doc = taxation.current.property.ownerShips.find((x: any) => x.isDocumentRecipient)
    let template = StaticTemplateType.BO2
    if (taxation.current.property.otherPropertyFlags?.nationalValuation) {
        template = StaticTemplateType.BO1
    }
    if (taxation.current.property.valueType === PropertyValueType.KO) {
        template = StaticTemplateType.KO
    }
    if (taxation.current.property.valueType === PropertyValueType.NE || taxation.current.property.valueType === PropertyValueType.UN) {
        template = StaticTemplateType.NE
    }
    const url = `${path}/preview?sessionKey=${session}&taxId=${taxation.id}&letterId=5fd229c3d16aae33c8bfb066&usersign=false&recipientId=${owner?.ownerId ?? doc?.ownerId}&staticTemplate=${template}`
    const openSesame = (e: any) => {
        e.stopPropagation()
        setOpen(true)
    }
    const close = (e: any) => {
        e.stopPropagation()
        setOpen(false)
    }
    return (
        <>
            <IconButton
                onClick={openSesame}
            >
                <FileCopy />
            </IconButton>
            <Modal open={open} onClose={close}>
                <iframe title={tr(TEXT_TAX_LETTER_PREVIEW)} src={url} className={styles.doc} />
            </Modal>
        </>
    )
}