import React, { ChangeEvent, useState, useEffect } from 'react'

import styles from './cubit-table.module.css'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import { CubitTableHeaderProps, CubitTableProps, CubitTableColumn, OrderDirection } from './cubit-table.types'
import { CubitTableHeader } from './cubit-table-header'
import TableBody from '@material-ui/core/TableBody'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import find from 'lodash/find'
import TablePagination from '@material-ui/core/TablePagination'
import { useDispatch, useSelector } from 'react-redux'
import { actionCubitTableOrder, actionCubitTableSelect } from './cubit-table-actions'
import { arraySection, arrayDistinct, arrayRemove, arrayRemoveAtIndex } from '../utils'
import { CubitCheckbox } from '../cubit-inputs/cubit-checkbox/cubit-checkbox'
import classNames from 'classnames'
import { filter, includes } from 'lodash'

const DEFAULT_ROWS_PER_PAGE = [10, 15, 20]

const CubitTable: React.FC<CubitTableProps> = props => {
    const { columns, data, selectable, sorting, paging, name: tableName, onRowClick, flat, sidePadding, style, paperClassName } = props

    let initialSortingDirection: 'asc' | 'desc' = 'asc'

    const firstSortableColumn = find(columns, column => column.sortable !== false)
    let initialSortingColumn = firstSortableColumn ? firstSortableColumn.key : columns[0].key

    if (sorting && typeof sorting === 'object') {
        initialSortingDirection = sorting.direction
        initialSortingColumn = sorting.by
    }

    const dispatch = useDispatch()
    const onOrderChange = (tableName: string, orderBy: string, orderDirection: OrderDirection) =>
        dispatch(actionCubitTableOrder(tableName, orderBy, orderDirection))

    const order =
        useSelector((state: any) => state.table[tableName] && state.table[tableName].orderDirection) ||
        initialSortingDirection
    const orderBy =
        useSelector((state: any) => state.table[tableName] && state.table[tableName].orderBy) || initialSortingColumn

    const initialRowsPerPage = paging && typeof paging === 'object' ? paging.rowsPerPage[0] : DEFAULT_ROWS_PER_PAGE[0]

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)

    const selected = useSelector((state: any) =>
        state.table[tableName] && state.table[tableName].selection ? state.table[tableName].selection : [],
    )

    const setSelected = (tableName: string, selection: any[]) => dispatch(actionCubitTableSelect(tableName, selection))
    const [previouslyToggledCheckboxIndex, setPreviouslyToggledCheckboxIndex] = useState(0)

    let tableHeaderProps: CubitTableHeaderProps = {
        columns: columns,
    }

    let transformedData = data

    const rowClickHandler = (row: any, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (typeof onRowClick === 'function') {
            onRowClick(row, event)
        }
    }

    //#region SELECTING

    let renderRowCheckbox: (index: number, id: string, selected: boolean) => JSX.Element | null = (
        index,
        id,
        selected,
    ) => null

    if (selectable) {
        const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
            const isSelectAllCurrentlyChecked = event.currentTarget.checked
            let selected = []

            if (isSelectAllCurrentlyChecked) {
                if (typeof selectable === 'function') {
                    // each row conditionally can have checkbox, so filter ones that has (is selectable)
                    selected = filter(data, selectable).map(n => n.id)
                } else {
                    selected = data.map(n => n.id)
                }
            }

            setSelected(tableName, selected)
        }

        const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, id: string) => {
            const nativeEvent = (event.nativeEvent as unknown) as React.MouseEvent
            const isShiftKeyPressed = nativeEvent.shiftKey
            const itemAlreadySelected = selected.indexOf(id) !== -1

            let newSelected: any[] = []

            if (isShiftKeyPressed) {
                const selection = arraySection(data, previouslyToggledCheckboxIndex, index, true)
                const selectableItemsInSelection =
                    typeof selectable === 'function' ? filter(selection, selectable) : selection
                const selectableItemsIds = selectableItemsInSelection.map((i: any) => i.id)

                if (itemAlreadySelected) {
                    newSelected = arrayRemove(selected, selectableItemsIds)
                } else {
                    newSelected = arrayDistinct(newSelected.concat(selected, selectableItemsIds))
                }
            } else {
                if (itemAlreadySelected) {
                    newSelected = arrayRemoveAtIndex(selected, selected.indexOf(id))
                } else {
                    newSelected = newSelected.concat(selected, id)
                }
            }

            setPreviouslyToggledCheckboxIndex(index)

            setSelected(tableName, newSelected)
        }

        renderRowCheckbox = (index: number, rowData: any, selected: boolean) => {
            const isRowSelectable = typeof selectable === 'function' ? selectable(rowData) : selectable

            return isRowSelectable ? (
                <td className={styles.checkboxCell}>
                    <CubitCheckbox
                        checked={selected}
                        onChange={event => handleCheckboxChange(event, index, rowData.id)}
                        onClick={e => e.stopPropagation()}
                    />
                </td>
            ) : (
                <td />
            )
        }

        tableHeaderProps = {
            ...tableHeaderProps,
            select: {
                selectedCount: selected.length,
                totalSelectableCount:
                    typeof selectable === 'function' ? filter(data, selectable).map(n => n.id).length : data.length,
                onSelectAll: handleSelectAllChange,
            },
        }
    }
    //#endregion

    //#region SORTING
    if (sorting) {
        const handleSort = (event: React.MouseEvent<HTMLButtonElement>, property: string) => {
            const isDesc = orderBy === property && order === 'desc'
            onOrderChange(tableName, property, isDesc ? 'asc' : 'desc')
        }

        tableHeaderProps = {
            ...tableHeaderProps,
            sorting: { direction: order, by: orderBy, onSort: handleSort },
        }

        transformedData = sortBy(transformedData, [orderBy]) || []
        transformedData = order === 'asc' ? transformedData : transformedData.reverse()
    }
    //#endregion

    //#region PAGING
    let pagingElement: JSX.Element | null = null
    let whiteSpaceElement: JSX.Element | null = null

    if (paging) {
        const handleChangePage = (event: any, newPage: any) => {
            setPage(newPage)
        }

        const handleChangeRowsPerPage = (event: any) => {
            setRowsPerPage(event.target.value)
            setPage(0)
        }

        const rowsPerPageOptions = typeof paging === 'object' ? paging.rowsPerPage : DEFAULT_ROWS_PER_PAGE

        pagingElement = (
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        )

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

        // fill remaining space in table when there are less results in page than page size
        whiteSpaceElement =
            emptyRows > 0 ? (
                <tr style={{ height: 49 * emptyRows }}>
                    <td colSpan={selectable ? columns.length + 1 : columns.length} />
                </tr>
            ) : null

        transformedData = transformedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
    //#endregion

    const renderTableRow = (index: number, row: any, columns: CubitTableColumn[]) => {
        const isItemSelected = selected.indexOf(row.id) !== -1

        return (
            <tr
                key={row.id || index}
                className={classNames(styles.tableRow, {
                    [styles.tableRowSelected]: isItemSelected,
                    [styles.tableRowClickable]: typeof onRowClick === 'function',
                })}
                role="checkbox"
                tabIndex={-1}
                onClick={event => rowClickHandler(row, event)}
            >
                {renderRowCheckbox(index, row, isItemSelected)}

                {columns.map((column, index) => (
                    <td
                        key={index}
                        className={classNames(styles.tableCell, column.bodyClassName, column.paddingClassName ?? styles.defaultPadding)}
                        style={{ textAlign: column.align, width: column.width }}
                    >
                        {column.getDisplayableElement ? column.getDisplayableElement(row) : get(row, column.key)}
                    </td>
                ))}
            </tr>
        )
    }

    const renderTable = () => {
        return (
            <>
                <Table className={classNames({ [styles['tablePadding' + sidePadding]]: sidePadding })} style={style}>
                    <CubitTableHeader {...tableHeaderProps} />
                    <TableBody>
                        {transformedData.map((row: any, index: number) => renderTableRow(index, row, columns))}

                        {whiteSpaceElement}
                    </TableBody>
                </Table>
                {pagingElement}
            </>
        )
    }

    return flat ? renderTable() : <Paper className={paperClassName}>{renderTable()}</Paper>
}

export default CubitTable
