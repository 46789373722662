import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { makeStyles, Theme, Paper, Typography, Grid } from '@material-ui/core'
import Navigation from 'common/navigation/navigation'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { ReportsForm } from './settings-form'
import { ReportsToolbar } from './settings-toolbar'
import { useDispatch, useSelector } from 'react-redux'
import { actionGetTaxSettings, actionGetYearSettings, actionUpdateYearSettings } from 'settings-page/settings-actions'
import { AppState } from '../app/app-store'
import { YES } from 'common/constants/constants'
import { formatToPermille, getOfficeAdjustmentText } from './helper-functions'
import { navigate, useParams } from '@reach/router'
import { RouteNames } from 'common/enums/routeNames'

type YearSettingsProps = {}

const useStyles = makeStyles((theme: Theme) => {
    return {
        propertyDataContainer: {
            marginBottom: theme.spacing(4),
        },
        title: {
            fontWeight: 'bold',
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        text: {
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        tableHead: {
            padding: theme.spacing(2, 0, 1, 0),
        },
        tableRow: {
            borderTop: '1px solid #f0f0f0',
            borderBottom: '1px solid #f0f0f0',
            cursor: 'pointer',
            padding: theme.spacing(1, 0, 1, 0),
        },
        tableContainer: {
            minWidth: 700,
        },
        upperCase: {
            textTransform: 'uppercase',
        },
    }
})

export const YearSettings: React.FC<YearSettingsProps> = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { id } = useParams()
    const allSettings = useSelector((state: AppState) => state.taxSettings.yearSettings)
    const taxSettings = useSelector((state: AppState) => state.taxSettings.taxSettings)

    const settingsIndex = id ? parseInt(id) : -1
    const currentSettings = allSettings ? allSettings.settings[settingsIndex] : null
    const working = useSelector((state: AppState) => state.taxSettings.uploading)
    const readOnly: boolean = currentSettings ? (currentSettings.closed && !working) : true

    const handleSubmit = (values: any) => {
        dispatch(actionUpdateYearSettings(values))
    }
    useEffect(() => {
        if (!allSettings) {
            dispatch(actionGetYearSettings())
        }
    }, [dispatch, allSettings])

    useEffect(() => {
        if (!taxSettings?.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [taxSettings, dispatch])

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={
                <ReportsToolbar
                    title={`${tr(t.TEXT_REPORTS)} ${currentSettings ? currentSettings.year : ''}`}
                    hideActions={readOnly}
                />
            }
        >
            <Paper elevation={0}>
                {settingsIndex !== -1 && (
                    <ReportsForm onSubmit={handleSubmit} initialValues={allSettings} settingsIndex={settingsIndex} />
                )}
                {settingsIndex === -1 && (
                    <div className={classes.tableContainer}>
                        <Grid container className={classes.tableHead}>
                            <Grid item xs={true}>
                                <Typography variant="body1" className={classes.title}>
                                    {tr(t.TEXT_YEAR)}
                                </Typography>
                            </Grid>
                            <Grid item xs={true}>
                                <Typography variant="body1" className={classes.title}>
                                    {tr(t.TEXT_MUNICIPALITY_ADJUSTED)}
                                </Typography>
                            </Grid>
                            <Grid item xs={true}>
                                <Typography variant="body1" className={classes.title}>
                                    {tr(t.TEXT_PROPERTY_TYPE)}
                                </Typography>
                            </Grid>
                            <Grid item xs={true}>
                                <Typography variant="body1" className={classes.title}>
                                    {tr(t.TEXT_GENERAL_RATE)}
                                </Typography>
                            </Grid>
                            <Grid item xs={true}>
                                <Typography variant="body1" className={classes.title}>
                                    {tr(t.TEXT_REDUCTION_FACTOR)}
                                </Typography>
                            </Grid>
                            <Grid item xs={true}>
                                <Typography variant="body1" className={classes.title}>
                                    {tr(t.TEXT_FLAT_REDUCTION)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {allSettings &&
                            allSettings.settings.map((settings: any, index: number) => (
                                <Grid
                                    container
                                    key={index}
                                    className={classes.tableRow}
                                    onClick={() =>
                                        navigate(`/${RouteNames.settings}/${RouteNames.yearSettings}/${index}`)
                                    }
                                >
                                    <Grid item sm={true}>
                                        <Typography variant="body1" className={classes.text}>
                                            {settings.year}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={true}>
                                        <Typography variant="body1" className={classes.text}>
                                            {getOfficeAdjustmentText(settings)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={true}>
                                        <Typography variant="body1" className={classes.text}>
                                            <span className={classes.upperCase}>{settings.propertyType}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={true}>
                                        <Typography variant="body1" className={classes.text}>
                                            {`${formatToPermille(settings.defaultTaxRate)} ‰`}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={true}>
                                        <Typography variant="body1" className={classes.text}>
                                            {`${settings.reductionFactor} %`}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={true}>
                                        <Typography variant="body1" className={classes.text}>
                                            {settings.useFlatReduction === YES ? tr(t.TEXT_YES) : tr(t.TEXT_NO)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                    </div>
                )}
            </Paper>
        </LayoutDefault>
    )
}
