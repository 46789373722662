import { Box, Button, Grid } from '@material-ui/core'
import React from 'react'
import Dropzone from 'react-dropzone'


type CubitDropZoneProps = { handleFileDrop: any, label: string, accept?: string, icon?: any }

export const CubitDropZone: React.FC<CubitDropZoneProps> = props => {
    const { handleFileDrop, label, accept = 'image/*', icon = <></> } = props
    return (
        <Dropzone
            accept={accept}
            onDrop={(files) => {
                handleFileDrop(files)
            }}
        >
            {({ getRootProps, getInputProps }) => (
                <Grid container>
                    <Grid item>
                        <Box {...getRootProps()}>
                            <Button variant="outlined" color="primary">
                                <input {...getInputProps()} />
                                {icon}
                                {label}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Dropzone>
    )
}