import { Divider, Grid } from '@material-ui/core'
import { Taxation } from 'models/taxation'
import { getPropertyCadastreDetails } from 'property-taxation-edit-page/helper-functions'
import React from 'react'
import { getPropertyAddresses } from 'utils'
import { TEXT_CADASTRE, TEXT_ADDRESS } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

type ComplaintHeaderProps = {
    styles: any
    taxation: Taxation
}

export const ComplaintHeader: React.FC<ComplaintHeaderProps> = (props) => {
    const { styles, taxation } = props
    return (
        <>
            <Divider className={styles.divider} />
            <Grid container spacing={2} className={styles.panelBox}>
                <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                    {tr(TEXT_CADASTRE)}
                </Grid>
                <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                    {getPropertyCadastreDetails(taxation.current.property)}
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                    {tr(TEXT_ADDRESS)}
                </Grid>
                <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                    {getPropertyAddresses(taxation.current.housingUnits)}
                </Grid>
            </Grid>

            <Divider className={styles.divider} />
        </>
    )
}
