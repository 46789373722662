export const isOverElement = (ref: HTMLElement, jsEvent: { clientX: number; clientY: number }) => {
    if (!ref) {
        return false
    }

    const rect = ref.getBoundingClientRect()
    const x = rect.left
    const y = rect.top
    return (
        x < jsEvent.clientX &&
        x + rect.width > jsEvent.clientX &&
        y < jsEvent.clientY &&
        y + rect.height > jsEvent.clientY
    )
}
