import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import { InputType } from '../input-type.enum'

export type CubitDatePickerAdapterProps = { type: InputType.DateRange | InputType.Date }

const CubitDatePickerAdapter: React.FC<CubitDatePickerAdapterProps> = (props: any) => {
    const {
        input,
        field,
        label,
        style,
        meta: { touched, invalid, error },
        ...rest
    } = props

    const inputFormat = 'dd/MM/yyyy'

    const value = input.value || null

    return (
        <FormControl component="fieldset" style={style}>
            <KeyboardDatePicker
                margin="none"
                label={label}
                autoOk
                format={inputFormat}
                value={value}
                onChange={(date: any) => input.onChange(date)}
                {...rest}
            />
        </FormControl>
    )
}

export default CubitDatePickerAdapter
