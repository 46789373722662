import React from 'react'
import {
    makeStyles,
    Theme,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { FieldArray, reduxForm } from 'redux-form'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { renderPanel } from './settings-panel'

const useStyles = makeStyles((theme: Theme) => {
    return {
        button: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        innerDivider: {
            marginRight: -theme.spacing(3),
            marginLeft: -theme.spacing(3),
            marginBottom: theme.spacing(1),
        },
        settingsLabel: {
            fontWeight: 600,
            fontSize: 16,
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
        },
        settingsRow: {},
        shortTextInput: {
            width: '100px !important',
            '& input': {
                paddingLeft: theme.spacing(1),
                color: theme.palette.primary.main,
                '-moz-appearance': 'textfield',
                '&::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
            },
        },
        percentageAfter: {
            position: 'relative',
            '&::after': {
                content: "'%'",
                position: 'absolute',
                right: 10,
                top: 0,
                bottom: 0,
                margin: 'auto 0',
                height: 18,
                color: theme.palette.primary.main,
            },
        },
        kronerAfter: {
            '&::after': {
                content: "'kr'",
            },
        },
        fullWidthCheckbox: {
            width: '100%',
        },
        helperText: {
            opacity: 0.6,
            paddingLeft: 30,
            display: 'flex',
            alignItems: 'flex-end',
        },
        smallSelect: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(3),
            minWidth: '100px !important',
            paddingLeft: theme.spacing(1),
        },
        formContainer: {
            padding: theme.spacing(1, 3, 0, 3)
        }
    }
})

const Form = (props: any) => {
    const { handleSubmit, change, settingsIndex } = props
    const stateRef = useSelector((state: AppState) => state)
    const classes = useStyles()

    return (
        <form onSubmit={handleSubmit}>
            <FieldArray
                name={TaxFormFieldNames.yearSettings}
                component={renderPanel}
                change={change}
                stateRef={stateRef}
                classes={classes}
                settingsIndex={settingsIndex}
            />
        </form>
    )
}

interface FormProps {
    settingsIndex: number;
}
export const ReportsForm = reduxForm<{}, FormProps>({
    form: TaxFormName.YearSettings,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
