import React from 'react'

type LoadCheckerProps = {
    setContentReady: any
    contentReady: boolean
}

export const LoadChecker: React.FC<LoadCheckerProps> = (props: LoadCheckerProps) => {
    const { setContentReady, contentReady } = props

    const logo = 'gridcompanylogo-demo.png'
    const logoSrc = `/images/${logo}`
    const onImageLoad = () => {
        if (contentReady) {
            return true
        }
        setContentReady(true)
    }

    return (
        <img src={logoSrc} alt="" onLoad={onImageLoad} style={{ width: 1, height: 1, visibility:'hidden' }} />
    )
}
