import React from 'react'
import { Calendar, usePickerState } from '@material-ui/pickers'
import { makeStyles, Theme, IconButton, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { CalendarView } from 'shared-components/src/cubit-calendar/calendar'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles((theme: Theme) => ({
    calendarWrapper: {
        overflow: 'hidden',
        '& .MuiPickersCalendarHeader-transitionContainer': {
            textTransform: 'capitalize',
        },
    },
    dayWrapper: {
        position: 'relative',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0 2px',
        color: 'inherit',
        fontWeight: theme.typography.fontWeightMedium,
        padding: 0,
    },
    currentlySelectedDay: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
        },
        [theme.breakpoints.down('sm')]: {
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            },
        },
    },
    currentDay: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
    currentDayInSelectedWeek: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
        border: '2px solid' + theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.contrastText,
        },
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    },
    endHighlight: {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    },
}))

type InlineDatePickerProps = {
    date: string
    onChange: (date: string) => void
    view: CalendarView
}

export const InlineDatePicker: React.FC<InlineDatePickerProps> = props => {
    const { date, onChange, view } = props

    const styles = useStyles()

    const { pickerProps } = usePickerState(
        {
            value: date,
            onChange: (a: any) => {
                changeHandler(a)
            },
            autoOk: true,
        },
        {
            getDefaultFormat: () => 'MM/dd/yyyy',
        },
    )

    const changeHandler = (date: DateTime) => {
        const dateString = date.toString()

        if (onChange) {
            onChange(dateString)
        }
    }

    const renderWrappedWeekDay = (
        dayOfTheMonth: MaterialUiPickersDate,
        currentlySelectedDay: MaterialUiPickersDate,
        dayInCurrentMonth: any,
    ) => {
        dayOfTheMonth = dayOfTheMonth || DateTime.fromJSDate(new Date())
        currentlySelectedDay = currentlySelectedDay || DateTime.fromJSDate(new Date())

        const isCurrentDay = dayOfTheMonth.hasSame(DateTime.fromJSDate(new Date()), 'day')

        const isCurrentlySelectedDay = dayOfTheMonth.valueOf() === currentlySelectedDay.valueOf()

        const start = currentlySelectedDay.startOf('week')
        const end = currentlySelectedDay.endOf('week')

        const dayIsBetween = end.diff(dayOfTheMonth).milliseconds > 0 && dayOfTheMonth.diff(start).milliseconds > 0
        const isFirstDay = dayOfTheMonth.diff(start).milliseconds === 0
        const isLastDay = end.diff(dayOfTheMonth.endOf('day')).milliseconds === 0

        const weekHighlightClassNames = classnames({
            [styles.highlight]: dayIsBetween,
            [styles.firstHighlight]: isFirstDay,
            [styles.endHighlight]: isLastDay,
        })

        const defaultDayClassNames = classnames(styles.day, {
            [styles.currentDay]: isCurrentDay,
            [styles.currentlySelectedDay]: isCurrentlySelectedDay,
        })

        const dayClassNamesForWeek = classnames(styles.day, {
            [styles.currentDay]: isCurrentDay,
            [styles.currentDayInSelectedWeek]: isCurrentDay && (dayIsBetween || isFirstDay || isLastDay),
        })

        const wrapperClassName =
            view === CalendarView.Week || view === CalendarView.List ? weekHighlightClassNames : undefined
        const dayClassName =
            view === CalendarView.Week || view === CalendarView.List ? dayClassNamesForWeek : defaultDayClassNames

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName} disabled={!dayInCurrentMonth}>
                    <Typography variant="body2" color="inherit">
                        {dayOfTheMonth.toFormat('d')}
                    </Typography>
                </IconButton>
            </div>
        )
    }

    return (
        <>
            <div className={styles.calendarWrapper}>
                <Calendar {...pickerProps} renderDay={renderWrappedWeekDay} />
            </div>
        </>
    )
}
