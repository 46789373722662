import React from 'react'
import { FormControl, Select, OutlinedInput, MenuItem } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_MYSELF, TEXT_SCHEDULE_TAXATION_FOR } from 'utils/translations/keys'
import { User } from 'models/user'
import { useUserId } from 'auth/auth-selectors'

type UserSelectionProps = { 
    usersArray: User[]; 
    onChange: (userId: string) => void; 
    placeholderTranslationKey?: string;
    customClass?: any
}

export const UserSelection: React.FC<UserSelectionProps> = props => {
    const { 
        usersArray, 
        onChange, 
        placeholderTranslationKey = TEXT_SCHEDULE_TAXATION_FOR,
        customClass = "whiteControl"
    } = props

    const currentUserId = useUserId()

    const handleUserSelectionChange = (userId: string) => {
        if (!userId) {
            return
        }
        onChange(userId)
    }

    return (
        <FormControl className={customClass}>
            <Select
                value={0}
                onChange={(e: any) => handleUserSelectionChange(e.target.value)}
                input={<OutlinedInput margin="dense" labelWidth={0} />}
            >
                <MenuItem value={0}>{tr(placeholderTranslationKey)}</MenuItem>
                {usersArray.map((user: any, index: number) => (
                    <MenuItem key={index} value={user.id}>
                        {user.id !== currentUserId ? user.name : tr(TEXT_MYSELF)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
