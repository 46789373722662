import React from 'react'
import { Box, makeStyles, Theme, Grid, Card } from '@material-ui/core'
import { FieldArray, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Taxation } from 'models/taxation'
import { TaxationPropertyBuildingsFieldArray } from './property-taxation-building-edit/property-taxation-building-edit-field-array'
import { TaxationSummary } from './property-taxation-edit-summary'
import { tr } from 'utils/translations/translate'
import { TEXT_REASON_FOR_FAILED_TAXATION } from 'utils/translations/keys'
import { TaxationPropertyDetails } from './property-taxation-edit/property-taxation-edit'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => {
    return {
        detailsContainer: {
            marginBottom: theme.spacing(4),
        },
        declineReasonContainer: {
            padding: theme.spacing(2, 3),
            marginBottom: theme.spacing(4),
        },
        declineReason: {
            fontWeight: 500,
        },
    }
})

type PropertyTaxationEditPageFormProps = {
    form: string
}

const Form: React.FC<
    InjectedFormProps<Taxation, PropertyTaxationEditPageFormProps> & PropertyTaxationEditPageFormProps
> = (props) => {
    const { form } = props

    const styles = useStyles()
    const [expandedBuildingIndex, setExpandedBuildingIndex] = React.useState<number | false>(false)

    const taxation = useSelector(getFormValues(form)) as Taxation
    return taxation ? (
        <form>
            {taxation.declinedReason && (
                <Card className={styles.declineReasonContainer}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>{tr(TEXT_REASON_FOR_FAILED_TAXATION)}:</Grid>
                        <Grid item className={styles.declineReason}>
                            {taxation.declinedReason}
                        </Grid>
                    </Grid>
                </Card>
            )}

            <Box className={styles.detailsContainer}>
                <TaxationPropertyDetails form={form} taxation={taxation} expandedBuildingIndex={expandedBuildingIndex} />
            </Box>

            <Box className={styles.detailsContainer}>
                <FieldArray
                    name="current.buildings"
                    component={TaxationPropertyBuildingsFieldArray}
                    form={form}
                    taxation={taxation}
                    setExpandedBuildingIndex={setExpandedBuildingIndex}
                    expandedBuildingIndex={expandedBuildingIndex}
                />
            </Box>

            <Box className={styles.detailsContainer}>
                <TaxationSummary taxation={taxation} form={form} />
            </Box>
        </form>
    ) : (
        <></>
    )
}

export const PropertyTaxationEditPageForm = reduxForm<Taxation, PropertyTaxationEditPageFormProps>({
    enableReinitialize: true,
    destroyOnUnmount: false,
})(Form)
