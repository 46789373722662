export enum RouteNames {
    access = 'access',
    companies = 'companies',
    users = 'users',
    settings = 'settings',
    yearSettings = 'year-settings',
    taxSettings = 'tax-settings',
    templates = 'templates',
    LETTER = 'letter-templates',
    TEXT = 'text-templates',
    CASE_DOCUMENT = 'case-documents',
    DOCUMENT_TEMPLATES = 'document-templates',
    EMAIL = 'email-templates',
    SMS = 'sms-templates',
    taxation = 'taxation',
    edit = 'edit',
    CASE_FLOW = 'case-flow',
    UPLOAD_DOCUMENT = 'upload-document',
    DASHBOARD = 'dashboard',
    FAILED_CORRESPONDENCE = 'failed-correspondence',
}
