import React from 'react'
import { Grid } from '@material-ui/core'
import { Factor } from 'models/property'

type TaxationOtherFactorsProps = {
    factors: Factor[]
}

export const TaxationOtherFactorsList: React.FC<TaxationOtherFactorsProps> = (props) => {
    const { factors } = props

    return (
        <>
            {factors.filter(f => f.factor).map((factor: Factor, index: number) => (
                <Grid container spacing={2} alignItems="center" key={index}>
                    <Grid item xs={3}>
                        {factor.name}
                    </Grid>
                    <Grid item xs>
                        {factor.factor}
                    </Grid>
                </Grid>
            ))}
        </>
    )
}
