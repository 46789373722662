import React from 'react'
import { Divider, Grid } from '@material-ui/core'
import { Factor, Property } from 'models/property'

type LocationFactorsProps = {
    styles: any
    property: Property
}

export const LocationFactors: React.FC<LocationFactorsProps> = (props) => {
    const { styles, property } = props
    const factors = property.otherLocationFactors.filter((factor: Factor) => factor.factor !== null)
    return factors.length ? (
        <>
            {factors.map((factor: Factor, index: number) => (
                <Grid container spacing={1} className={styles.innerContainer} key={`1-${index}`}>
                    <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                        {factor.name}:
                    </Grid>
                    <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                        {factor.factor}
                    </Grid>
                </Grid>
            ))}
            <Divider className={styles.innerDivider} />
        </>
    ) : (
        <></>
    )
}
