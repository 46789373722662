import { ActionReducer, Reducers, makeReducer } from 'shared-components/src/utils/make-reducer'
import { AnyAction } from 'redux'
import { ACTION_CUBIT_SNACKBAR_SHOW } from './cubit-snackbar-actions'

export type CubitSnackbarState = {
    message?: string
    timestamp?: number
    variant?: 'success' | 'warning' | 'error' | 'info'
}

type CubitSnackbarReducer = ActionReducer<CubitSnackbarState, AnyAction>

const showCubitSnackbar: CubitSnackbarReducer = (state, action) => {
    state.message = action.message
    state.variant = action.variant
    state.timestamp = Date.now()
    return state
}

const reducers: Reducers<CubitSnackbarState> = {
    [ACTION_CUBIT_SNACKBAR_SHOW]: showCubitSnackbar,
}

export const cubitSnackbarReducer = makeReducer<CubitSnackbarState>(reducers, {})
