import React from 'react'

import { CalendarView } from 'shared-components/src/cubit-calendar/calendar'
import {
    getTimeFromJsDate,
    getTaxationStatusTranslationKey,
    getPropertyValueTypeColor,
} from 'utils'
import { CubitCalendarEvent } from 'shared-components/src/models/calendar-event'
import { TaxationState } from 'models/taxation'
import { Warning } from '@material-ui/icons'
import { TEXT_COULD_NOT_TAXATE } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { Grid } from '@material-ui/core'
import classes from './planning-calendar-event.module.css'
import classnames from 'classnames'
import { CubitCalendarExtendedProps } from 'models/calendar-event'

export const renderPlanningCalendarEvent = (calendarView: CalendarView) => (info: any) => {
    //const content: HTMLElement = calendarView === CalendarView.List ? info.el : info.el.querySelector('.fc-content')

    // if (content) {
    //     content.innerHTML = getEventInnerHTML(info.event, calendarView)
    // }
    return getEventHTML(info.event, calendarView)
}

const getEventHTML = (event: CubitCalendarEvent<CubitCalendarExtendedProps>, calendarView: CalendarView) => {
    const startingTime = getTimeFromJsDate(event.start)
    const endingTime = getTimeFromJsDate(event.end)
    const time = `${startingTime} ${endingTime ? `- ${endingTime}` : ''}`

    const status = tr(getTaxationStatusTranslationKey(event.extendedProps.relatedEntity.state))

    return calendarView === CalendarView.List
            ? PropertyEventInfoListView(event, time, status)
            : PropertyEventInfoDefaultView(event, time, status)
}

const PropertyEventInfoDefaultView = (data: CubitCalendarEvent<CubitCalendarExtendedProps>, time: string, status: string) => {
    const eventInProgress =
        data.extendedProps.relatedEntity.state === TaxationState.TaxationReadyForPlanning ||
        data.extendedProps.relatedEntity.state === TaxationState.TaxationPlanned ||
        data.extendedProps.relatedEntity.state === TaxationState.TaxationStarted

    const eventHasIssue = data.extendedProps.relatedEntity.issue
    const eventShouldBeFaded = eventHasIssue || !eventInProgress

    const eventTitle = data.title && data.title.trim()

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
            className={classnames(classes.calendarEventInfo, {
                [classes.calendarEventInfoFaded]: eventShouldBeFaded,
            })}
            style={{
                backgroundColor: getPropertyValueTypeColor(data.extendedProps.relatedEntity),
            }}
        >
            <Grid item className={classes.calendarEventInfoTop}>
                {time}
                <br />
                {eventTitle && eventTitle}
                <br />
                <span className={classes.bolder}>
                    {data.extendedProps.relatedEntity.propertyAddresses}
                </span>
                <br />
                {data.extendedProps.relatedEntity.postalAddress}
                {data.extendedProps.relatedEntity.issue && (
                    <span
                        title={`${tr(TEXT_COULD_NOT_TAXATE)}: ${data.extendedProps.relatedEntity.issue.comment}`}
                        style={{ position: 'absolute', top: '2px', right: '2px' }}
                    >
                        <Warning className={classes.warningIcon}></Warning>
                    </span>
                )}
            </Grid>
            <Grid item className={classes.calendarEventInfoBottom}>
                {status}
            </Grid>
        </Grid>
    )
}

const PropertyEventInfoListView = (data: CubitCalendarEvent<CubitCalendarExtendedProps>, time: string, status: string) => (
    <>
        <td className="fc-list-item-time">{time}</td>
        <td>
            <Grid container>
                <Grid item xs={4}>
                    {data.title}
                </Grid>
                <Grid item xs={4}>
                    {status}
                </Grid>
                <Grid item xs={4}>
                    {data.extendedProps.relatedEntity.issue && (
                        <>
                            <span title={tr(TEXT_COULD_NOT_TAXATE)} style={{ verticalAlign: 'middle' }}>
                                <Warning className={classes.warningIcon}></Warning>
                            </span>
                            {data.extendedProps.relatedEntity.issue.comment}
                        </>
                    )}
                </Grid>
            </Grid>
        </td>
        <td>{data.extendedProps.relatedEntity.propertyAddresses}</td>
    </>
)
