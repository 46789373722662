import React from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { makeStyles, Theme, Paper, Typography, Grid } from '@material-ui/core'
import Navigation from 'common/navigation/navigation'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { RouteComponentProps } from '@reach/router'
import { TemplatesToolbar } from 'settings-templates/templates-toolbar'
import { DataUploadForm } from 'settings-page/data-upload-form/dataUploadForm'

type UploadDocumentProps = {}

const useStyles = makeStyles((theme: Theme) => {
    return {
        title: {
            fontWeight: 'bold',
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        tableHead: {
            padding: theme.spacing(2, 0, 1, 0),
        },
        tableRow: {
            borderTop: '1px solid #f0f0f0',
            borderBottom: '1px solid #f0f0f0',
            cursor: 'pointer',
            padding: theme.spacing(1, 0, 1, 0),
        },
    }
})

export const UploadDocument: React.FC<RouteComponentProps<UploadDocumentProps>> = () => {
    const classes = useStyles()

    return (
        <LayoutDefault navigation={<Navigation />} toolbar={<TemplatesToolbar title={tr(t.TEXT_DOCUMENTS)} />}>
            <Paper elevation={0}>
                <Grid container className={classes.tableHead}>
                    <Grid item xs={5}>
                        <Typography variant="body1" className={classes.title}>
                            {tr(t.TEXT_UPLOAD_DOCUMENT)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.tableRow}>
                    <DataUploadForm />
                </Grid>
            </Paper>
        </LayoutDefault>
    )
}
