import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    actionCubitCalendarSave,
    actionCubitCalendarReset,
} from 'shared-components/src/cubit-calendar/calendar-actions'
import ContextBar from 'common/context-bar/context-bar'
import { Grid, IconButton, Typography, FormControl, Select, MenuItem, Button, OutlinedInput } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { tr } from 'utils/translations/translate'
import { TEXT_CONFIRM_CHANGES, TEXT_SEND_NOTICE } from 'utils/translations/keys'
import { CubitCalendarEvent } from 'shared-components/src/models/calendar-event'
import { CubitCalendarExtendedProps } from 'models/calendar-event'
import { actionGetCaseFlowEntries } from 'settings-page/settings-actions'
import { AppState } from 'app/app-store'
import { CaseFlowTitles } from 'common/enums/caseFlow'

type PlanningContextBarProps = {
    events: CubitCalendarEvent<CubitCalendarExtendedProps>[]
}

export const PlanningContextBar: React.FC<PlanningContextBarProps> = ({ events }) => {
    const dispatch = useDispatch()

    const modifiedEvents = events.filter(x => x.start !== x.extendedProps.start)
    const caseFlow = useSelector((state: AppState) => state.taxSettings.caseFlow)
    // @ts-ignore
    const days: any = Object.values(caseFlow).length ? Object.values(caseFlow).find((c: any) => c.name === CaseFlowTitles.taxationNotice).noOfDays : 21
    const save = () =>
        dispatch(
            actionCubitCalendarSave(
                modifiedEvents.map(event => ({
                    ...event.extendedProps,
                    start: event.start || undefined,
                    end: event.end || undefined,
                })),
            ),
        )
    const reset = () => dispatch(actionCubitCalendarReset())
    
    useEffect(() => {
        return () => {
            if(modifiedEvents){
                dispatch(actionCubitCalendarReset())
            }
            
        }
    }, [])

    useEffect(() => {
        if (!Object.values(caseFlow).length) {
            dispatch(actionGetCaseFlowEntries())
        }
    }, [dispatch, caseFlow])

    return modifiedEvents.length === 0 ? null : (
        <ContextBar>
            <Grid style={{ flexGrow: 1 }} container alignItems="center">
                <Grid item>
                    <IconButton onClick={reset} color="inherit" edge="start" aria-label="Clear">
                        <Clear />
                    </IconButton>
                </Grid>
                <Grid style={{ paddingLeft: '36px' }} item xs>
                    <Typography variant="h6">{`${modifiedEvents.length} items`}</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <FormControl className="whiteControl">
                                <Select
                                    value={days}
                                    onChange={e => console.log(e.target.value)}
                                    input={<OutlinedInput margin="dense" labelWidth={0} />}
                                >
                                    <MenuItem value={days}>{tr(TEXT_SEND_NOTICE, [days])}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" color="inherit" type="button" onClick={save}>
                                {tr(TEXT_CONFIRM_CHANGES)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContextBar>
    )
}
