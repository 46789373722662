import { Property } from 'models/property'
import { Building } from 'models/building'
import { getPropertyAddresses } from 'utils'
import { getPropertyBuildingsValue } from './get-property-buildings-value'

export default <P extends Property, B extends Building>(property: P, settings: any, buildings: B[]): P => {
    let prop = { ...property }
    prop.address = getPropertyAddresses(buildings)

    prop.plotSize = Math.round(prop.plots.reduce((plotSize, plot) => plotSize + plot.area, 0))
    prop.plotValue = 0
    prop.buildingsValue = getPropertyBuildingsValue(prop, settings, buildings)
    prop.valueBeforeFactors = prop.plotValue + prop.buildingsValue

    prop.totalValue = prop.valueBeforeFactors * prop.locationFactor * prop.zoneFactor
    prop.plotSquareMeterValue = prop.plotSize ? prop.plotValue / prop.plotSize : 0

    return prop
}
