import React, { useContext, useEffect, useRef } from 'react'
import MapContext from '../map/map-context'
import { Overlay } from 'ol'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Coordinate } from 'ol/coordinate'

const useStyles = makeStyles((theme) => ({
    marker: {
        width: 40,
        height: 40,
        backgroundColor: theme.palette.primary.main,
        border: `5px solid #fff`,
        borderRadius: 20,
        animationName: '$pulse',
        animationDuration: '2s',
        animationIterationCount: 'infinite',
    },
    '@keyframes pulse': {
        from: { transform: 'scale(1)' },
        '50%': { transform: 'scale(0.85)' },
        to: { transform: 'scale(1)' },
    },
}))

interface PositionOverlay {
    position: Coordinate | undefined
}

const PositionOverlay: React.FC<PositionOverlay> = ({ position }) => {
    const classes = useStyles()
    const { map } = useContext(MapContext)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!map || !position) return

        const marker = new Overlay({
            position: position,
            positioning: 'center-center',
            stopEvent: false,
        })

        if (ref && ref.current) {
            marker.setElement(ref.current)
            map.addOverlay(marker)
        }

        return () => {
            if (map) {
                map.removeOverlay(marker)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, position])

    return position ? <div id="marker" ref={ref} className={classes.marker} /> : null
}
export default PositionOverlay
