import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { tr } from 'utils/translations/translate'
import { TEXT_RIGHTS } from 'utils/translations/keys'
import { useDispatch } from 'react-redux'
import { actionSettingsGroupsGet } from 'shared-components/src/settings/groups/groups-actions'
import { RouteComponentProps } from '@reach/router'
import TitleToolbar from 'common/title-toolbar/title-toolbar'

export const SettingsRightsPage: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actionSettingsGroupsGet())
    }, [dispatch])

    return (
        <LayoutDefault navigation={<Navigation />} toolbar={<TitleToolbar title={tr(TEXT_RIGHTS)} />}></LayoutDefault>
    )
}
