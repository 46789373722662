import React from 'react'
import { Grid, makeStyles, Paper, Theme } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_TAXATION_DATE,
    TEXT_STATUS,
    TEXT_TAXATION_METHOD,
    TEXT_TAXATION_CASE_WORKER,
    TEXT_TARIFF,
} from 'utils/translations/keys'
import { Taxation } from 'models/taxation'
import { useDispatch, useSelector } from 'react-redux'
import { getTaxationMethodText, getTaxDate } from 'property-page/taxations-history/functions'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { PictureAsPdf } from '@material-ui/icons'
import { actionSetPreviewPdf } from 'app/app-actions'
import { actionLoadCorrespondance } from './taxation-actions'
import { Correspondance, getSentByText } from 'models/correspondance'
import { getDate } from './helper-functions'
import { DateTime } from 'luxon'
import { useUsers } from 'app/app-selectors'
import { getTaxationStatusTranslationKey } from 'utils'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            padding: '20px 10px 40px 10px',
        },
        headline: {
            fontSize: '18px',
            paddingBottom: theme.spacing(1),
            fontWeight: 600,
        },
        document: {
            cursor: 'pointer',
            marginTop: 10,
            fontSize: 16,
        },
        docContainer: {
            minHeight: 50,
            padding: 10
        },
        label: {
            fontSize: 14,
            fontWeight: 600,
        },
        title: {
            fontSize: 17,
            margin: '0 10px'
        },
        bold: {
            fontWeight: 600,
        },
        flex: {
            display: 'flex'
        }
    }
})

export const CaseDocuments: React.FC<{
    taxation: Taxation
}> = (props) => {
    const { taxation } = props
    const styles = useStyles()
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)
    const dispatch = useDispatch()
    const correspondance = taxation.correspondance
    const users = useUsers()

    React.useEffect(() => {
        if (!correspondance) {
            dispatch(actionLoadCorrespondance(taxation.id))
        }
    }, [taxation.id, correspondance, dispatch])

    return (
        <div>
            <Paper square={true} className={styles.container} elevation={0}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={6} md={4} className={styles.label}>
                        {tr(TEXT_STATUS)}
                    </Grid>

                    <Grid item xs={6}>
                        {tr(getTaxationStatusTranslationKey(taxation.state))}
                    </Grid>
                </Grid>

                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={6} md={4} className={styles.label}>
                        {tr(TEXT_TAXATION_METHOD)}
                    </Grid>

                    <Grid item xs={6}>
                        {getTaxationMethodText(taxation)}
                    </Grid>
                </Grid>

                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={6} md={4} className={styles.label}>
                        {tr(TEXT_TAXATION_CASE_WORKER)}
                    </Grid>

                    <Grid item xs={6}>
                        {users[taxation.userId]?.name}
                    </Grid>
                </Grid>

                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={6} md={4} className={styles.label}>
                        {tr(TEXT_TAXATION_DATE)}
                    </Grid>

                    <Grid item xs={6}>
                        {getTaxDate(taxation, language)}
                    </Grid>
                </Grid>
            </Paper>

            {correspondance &&
                correspondance.map((c: Correspondance, index: number) => (
                    <Paper className={styles.document} square={true} key={index} elevation={0}>
                        <Grid
                            className={styles.docContainer}
                            container
                            spacing={0}
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => dispatch(actionSetPreviewPdf(c.attachmentId, c.title))}
                        >
                            <Grid item className={styles.flex}>
                                <PictureAsPdf />
                                <Grid item className={styles.title}>{c.title || tr(TEXT_TARIFF)}</Grid>
                            </Grid>
                            <Grid item>
                                <span className={styles.bold}>{getSentByText(c)} </span>
                                {getDate(c.timeStamp, language, DateTime.DATE_SHORT)}
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
        </div>
    )
}
