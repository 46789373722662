import { Building } from 'models/building'
import { HousingUnit } from 'models/housing-unit'
import { find } from 'lodash'
import { HousingUnitAddress } from 'models/housing-unit-address'
import { Property } from 'models/property'
import { floorBelongsToProperty } from 'models/floor'
import { getFactor } from 'property-page/taxations-history/functions'

export default <B extends Building>(buildings: B[], housingUnits: HousingUnit[], property: Property): B[] => {
    return buildings.map(building => {
        const standardFactor = building.standardFactor
        const buildingHousingUnit = find(housingUnits, { buildingId: building.id })

        building.floors = building.floors.filter(f => floorBelongsToProperty(f, property.id)).map((floor, index) => {
            floor.id = index
            const usageFactor = getFactor(floor.usageFactor)
            floor.value = usageFactor * Math.round(floor.area) * floor.pricePerM2
            return floor
        })
        building.valueBeforeFactors = building.floors.reduce((acc: number, floor) => acc + floor.value, 0)
        building.value = standardFactor * building.valueBeforeFactors
        building.address = buildingHousingUnit ? buildingHousingUnit.address : ({} as HousingUnitAddress)

        return building
    })
}
