import { Icon } from 'ol/style'
import IconAnchorUnits from 'ol/style/IconAnchorUnits'
import Style from 'ol/style/Style'

export const Marker = {
    icon: new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.PIXELS,
            src: 'images/mapmarker-red.png',
        }),
    }),
    selectedIcon: new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.PIXELS,
            src: 'images/mapmarker-blue.png',
        }),
    }),
    passiveIcon: new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.PIXELS,
            src: 'images/mapmarker-disabled.png',
        }),
    }),
}
