import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_ADD_USER, TEXT_CANCEL } from 'utils/translations/keys'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { SettingsUserForm, SettingsUserFormName } from './settings-user-form'
import { actionCreateUser } from 'app/app-actions'

export const SettingsUserCreate: React.FC = () => {
    const dispatch = useDispatch()
    const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false)

    const handleClose = () => {
        setAddNewUserDialogOpen(false)
    }

    const handleOk = () => {
        dispatch(submit(SettingsUserFormName))
    }

    const handleSubmit = (val: any) => {
        const newUser = {
            ...val,
            type: 'TAX',
        }
        dispatch(actionCreateUser(newUser))
        setAddNewUserDialogOpen(false)
    }

    return (
        <>
            <Button
                variant="outlined"
                color="inherit"
                type="button"
                onClick={() => {
                    setAddNewUserDialogOpen(true)
                }}
            >
                {tr(TEXT_ADD_USER)}
            </Button>

            <Dialog onClose={handleClose} open={addNewUserDialogOpen} disableBackdropClick>
                <DialogTitle>{tr(TEXT_ADD_USER)}</DialogTitle>
                <DialogContent style={{ width: '480px' }}>
                    <SettingsUserForm initialValues={{}} onSubmit={handleSubmit} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit">
                        {tr(TEXT_CANCEL)}
                    </Button>
                    <Button onClick={handleOk} color="primary" autoFocus>
                        {tr(TEXT_ADD_USER)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
