import { HousingUnit } from './housing-unit'
import { Plot } from './plot'
import { Verifiable } from './verifiable'
import { PropertyTaxInfo } from './taxation'
import { UploadedFile } from './uploaded-file'
import { Building } from './building'

export enum PropertyStatus {
    NORMAL = 'NORMAL',
    PICKED = 'PICKED',
    ASSIGNED_USER = 'ASSIGNED_USER',
    ASSIGNED_COMPANY = 'ASSIGNED_COMPANY',
    ACTIVE = 'ACTIVE',
    PLANNED = 'PLANNED',
    STARTED = 'STARTED',
    TAXATED = 'TAXATED',
    NOTIFIED = 'NOTIFIED',
    TAXATION_FINISHED = 'TAXATION_FINISHED',
    TAXATION_COMPLAINED = 'TAXATION_COMPLAINED',
    TRIBUNAL_PLANNED = 'TRIBUNAL_PLANNED',
    TRIBUNAL_FINISHED = 'TRIBUNAL_FINISHED',
    TRIBUNAL_DECLINED = 'TRIBUNAL_DECLINED',
    METRIC_CHANGE = 'METRIC_CHANGE',
    YEAR_LETTER_SENT = 'YEAR_LETTER_SENT',
    YEAR_LETTER_NOT_SENT = 'YEAR_LETTER_NOT_SENT',
    MANUAL_VALUE = 'MANUAL_VALUE',
}

export type StateModel = {
    value?: PropertyStatus
    propertyId: string
    companyId?: string
    userId?: string
    taxationId?: string
    updated?: Date
    updatedBy?: string
    taxatedAt?: Date
    buildingsSnapshot?: Building[]
    tax?: number
}

export enum PropertyType {
    Main = 'main',
    Lease = 'lease',
}

export enum PropertyValueType {
    /** Residential property */
    BO = 'BO',
    /** Leisure Property */
    FR = 'FR',
    /** Farming / forestry with housing */
    GB = 'GB',
    /** Farm use / forestry with leisure. */
    GF = 'GF',
    /** Farms or forestry */
    GU = 'GU',
    /** Not selected */
    IV = 'IV',
    /** Combined built plot */
    KO = 'KO',
    /** Commercial real estate */
    NE = 'NE',
    /** Undeveloped plot, housing */
    UB = 'UB',
    /** Undeveloped plot, leisure */
    UF = 'UF',
    /** Undeveloped plot, industry */
    UN = 'UN',
    /** Undeveloped plot */
    UT = 'UT',
}

export enum LocationFactor {
    ParticularlyUnfavorable = 0.8,
    Normal = 1,
    ParticularlyFavorable = 1.2,
}

export enum ZoneFactor {
    Zone0 = 0.5,
    Zone1 = 0.7,
    Zone2 = 0.9,
    Zone3 = 1.2,
}

export type Factor = {
    name: string
    factor: number
}

type OtherPropertyFlags = {
    childrenSections: string[]
    governmentBeneficial: boolean
    powerProduction: boolean
    hasParking: boolean
    parkingSpaces: number
    correspondanceDetails: CorrespondanceSendingOutcome[]
    nationalValuation: number
    taxNoticeSent: boolean
    yearLetterSent: boolean
    manualValue: number
    manualKoValue: number
    manualValueComment: string
    manualValueDocument: UploadedFile
    deductibleHousingUnitsCount: number
    matrikkelChanged: boolean,
    detailedLetterSent: boolean
}

type CorrespondanceSendingOutcome = {
    issue: string
    receiver: string
    errorMessage: string
    errorStack: string
    timestamp: Date
}

export type ContactInfo = {
    phone: string
    email: string
}

export type Property = {
    areaSource: any // null
    comment: string
    exempt: boolean
    exemptReason: string
    files: UploadedFile[]
    municipalityNumber: number
    holdingNumber: number
    housingUnits: HousingUnit[]
    id: string
    key: string
    leaseNumber: number
    locationFactor: LocationFactor
    name: string
    otherLocationFactors: Factor[]
    otherStandardFactos: Factor[]
    ownerShips: any // [{…}]
    otherOwnerShips: any // [{…}]
    owners: any // [{…}]
    parentId: any // null
    plots: Plot[]
    propertyTaxBasis: any // (3) [{…}, {…}, {…}]
    regulated: boolean
    sectionNumber: number
    state: StateModel
    subHoldingNumber: number
    subType: any // null
    tariffCompany: string
    taxInfo: PropertyTaxInfo
    type: PropertyType
    valueType: PropertyValueType
    zone: string
    zoneFactor: number
    zones: any // []
    otherPropertyFlags: OtherPropertyFlags
    buildings: Building[]
    communication: any
    // mapped props
    address: string
    buildingsValue: number
    plotSize: number
    plotSquareMeterValue: number
    plotValue: number
    totalValue: number
    valueBeforeFactors: number
}

export type VerifiableProperty = Verifiable & Property
