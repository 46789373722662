import { combineEpics } from 'redux-observable'
import { from, Observable, zip, of } from 'rxjs'
import { filter, mergeMap, map, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { httpGet, httpPost, httpPut, httpUpload, plantsUrl } from 'services/httpService'
import {
    ACTION_TAXATION_CREATE,
    ACTION_TAXATION_FINISH,
    ACTION_TAXATION_GET_WITH_HISTORY,
    ACTION_TAXATION_UPDATE,
    ACTION_TAXATION_UPLOAD,
    actionTaxationCreateSuccess,
    actionTaxationFinishSuccess,
    actionTaxationGetWithHistorySuccess,
    actionTaxationUpdateSuccess,
    actionTaxationUploadFilesSuccess,
    actionTaxationReopenSuccess,
    ACTION_TAXATION_REOPEN,
    actionTaxationGetSuccess,
    ACTION_TAXATION_GET,
    ACTION_TAXATION_UPDATE_AFTER_COMPLAINT,
    ACTION_LOAD_CORRESPONDANCE,
    actionLoadCorrespondanceSuccess,
    ACTION_LOAD_COMMUNICATION,
    actionLoadCommunicationSuccess,
    ACTION_UPLOAD_ATTACHMENT,
    actionUploadAttachmentSuccess,
    ACTION_SEND_COMMUNICATION,
    actionSendCommunicationSuccess,
    actionDocumentSaveSuccess,
    ACTION_DOCUMENT_SAVE,
    ACTION_MANUAL_VALUE_SAVE,
    actionManualCloseSuccess,
    ACTION_RECALCULATE,
} from './taxation-actions'
import { Taxation } from 'models/taxation'
import { navigate } from '@reach/router'
import { mapBuildings, mapTaxation } from 'utils'
import { UploadedFile } from 'models/uploaded-file'
import { arrayPush, submit } from 'redux-form'
import { AppState } from 'app/app-store'
import { defaultCatchError } from 'app/app-epics'
import { ActivityLog } from 'models/activity-log'
import { actionActivityLogLoadSuccess } from 'property-page/activity-log/activity-log-actions'
import { actionCubitSnackbarShow } from 'common/cubit-snackbar/cubit-snackbar-actions'
import { tr } from 'utils/translations/translate'
import { TEXT_FINISHED_TAXATION } from 'utils/translations/keys'
import { Correspondance } from 'models/correspondance'
import { actionPropertyTaxationUpdateSuccess } from 'property-page/property-actions'

export const createTaxation = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_CREATE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/taxations/'), {
                    propertyId: action.propertyId,
                    taxationId: action.taxationId,
                }).pipe(
                    map((taxation: Taxation) => {
                        const buildings = mapBuildings(taxation.current.buildings, taxation.current.housingUnits, taxation.current.property)

                        navigate(`/taxation/${taxation.id}/edit`)

                        return actionTaxationCreateSuccess({ ...taxation, current: { ...taxation.current, buildings } })
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const getTaxation = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_GET),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/taxations/${action.taxationId}`)).pipe(
                    map((taxation: Taxation) => actionTaxationGetSuccess(mapTaxation(taxation))),
                    defaultCatchError(),
                ),
        ),
    )

export const getTaxationWithHistory = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_GET_WITH_HISTORY),
        mergeMap(
            (action): Observable<AnyAction> =>
                zip(
                    httpGet(plantsUrl(`/tax/taxations/${action.taxationId}`)),
                    httpGet(plantsUrl(`/tax/${action.taxationId}/history/`))
                ).pipe(
                    mergeMap(([taxation, activityLog]: [Taxation, ActivityLog]) => {
                        return from([
                            actionTaxationGetWithHistorySuccess(mapTaxation(taxation)),
                            actionActivityLogLoadSuccess(activityLog, action.taxationId)
                        ])
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const updateTaxation = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_UPDATE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(plantsUrl(`/tax/taxations/${action.taxation.id}`), action.taxation).pipe(
                    map((taxation: Taxation) => actionTaxationUpdateSuccess(mapTaxation(taxation))),
                    defaultCatchError(),
                ),
        ),
    )

export const updateTaxationAfterComplaint = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_UPDATE_AFTER_COMPLAINT),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(plantsUrl(`/tax/complaintTaxations/${action.taxation.id}`), action.taxation).pipe(
                    map((taxation: Taxation) => actionTaxationUpdateSuccess(mapTaxation(taxation))),
                    defaultCatchError(),
                ),
        ),
    )

export const finishTaxation = (action$: Observable<AnyAction>, state$: Observable<AppState>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_FINISH),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> => {
                const taxation = state.form[`taxation-${action.taxationId}`].values

                return httpPost(plantsUrl(`/tax/taxations/finish/${action.taxationId}`), taxation).pipe(
                    mergeMap((taxation: Taxation) => {
                        navigate(`/calendar`)

                        return of(
                            actionTaxationFinishSuccess(mapTaxation(taxation)),
                            actionCubitSnackbarShow(tr(TEXT_FINISHED_TAXATION)),
                        )
                    }),
                    defaultCatchError(),
                )
            },
        ),
    )

export const reopenTaxation = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_REOPEN),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(plantsUrl(`/tax/taxations/${action.taxation.id}`), action.taxation).pipe(
                    map((taxation: Taxation) => {
                        navigate(`/taxation/${taxation.id}/edit`)
                        return actionTaxationReopenSuccess(mapTaxation(taxation))
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const uploadTaxationFiles = (action$: Observable<any>): Observable<any> =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_UPLOAD),
        mergeMap(
            (action): Observable<any> => {
                const request = httpUpload(plantsUrl('/tax/upload'), action.files)
                const formName = action.formName
                const fieldName = action.fieldName

                return request.pipe(
                    mergeMap((uploadedFiles: UploadedFile[]) => {
                        return from([
                            ...uploadedFiles.map(f => arrayPush(formName, fieldName, f)),
                            actionTaxationUploadFilesSuccess(uploadedFiles, action.taxationId, action.buildingId),
                            submit(formName),
                        ])
                    }),
                    defaultCatchError(),
                )
            },
        ),
    )

export const loadTaxationCorrespondance = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_LOAD_CORRESPONDANCE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/correspondance/get/${action.taxationId}`)).pipe(
                    map((correspondance: Correspondance[]) => {
                        return actionLoadCorrespondanceSuccess(action.taxationId, correspondance)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const loadTaxationCommunication = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_LOAD_COMMUNICATION),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/communication/get/${action.taxationId}`)).pipe(
                    map((communication: any) => {
                        return actionLoadCommunicationSuccess(action.taxationId, communication)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const uploadAttachment = (action$: Observable<any>): Observable<any> =>
    action$.pipe(
        filter(x => x.type === ACTION_UPLOAD_ATTACHMENT),
        mergeMap(
            (action): Observable<any> => {
                const request = httpUpload(plantsUrl('/communication/attach'), action.files)

                return request.pipe(
                    mergeMap((uploadedFiles: any) => {
                        return from([
                            ...uploadedFiles.map((f: any) => arrayPush(action.formName, action.fieldName, f)),
                            actionUploadAttachmentSuccess(uploadedFiles, action.taxationId),
                        ])
                    }),
                    defaultCatchError(),
                )
            },
        ),
    )

export const sendCommunication = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_SEND_COMMUNICATION),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/communication/sendCommunication'), action.entry).pipe(
                    map((entries: any) => (
                        actionSendCommunicationSuccess(entries)
                    )),
                    defaultCatchError(),
                ),
        ),
    )

export const uploadManualValueDoc = (action$: Observable<any>): Observable<any> =>
    action$.pipe(
        filter(x => x.type === ACTION_DOCUMENT_SAVE),
        mergeMap(
            (action): Observable<any> => {
                const formData = new FormData()
                formData.append("id", action.id)
                formData.append("file", action.file)

                return httpPost(plantsUrl('/tax/manualValueDocument'), formData).pipe(
                    mergeMap((uploadedFile: UploadedFile) => {
                        return from([
                            actionDocumentSaveSuccess(action.id, uploadedFile),
                        ])
                    }),
                    defaultCatchError(),
                )
            },
        ),
    )

export const uploadManualValue = (action$: Observable<any>): Observable<any> =>
    action$.pipe(
        filter(x => x.type === ACTION_MANUAL_VALUE_SAVE),
        mergeMap(
            (action): Observable<any> => {
                const data = {
                    id: action.id,
                    value: action.value,
                    comment: action.comment,
                    manualKoValue: action.manualKoValue
                }

                return httpPost(plantsUrl('/tax/manualValue'), data).pipe(
                    mergeMap(() => {
                        return from([
                            actionManualCloseSuccess(action.id, action.value, action.comment, action.manualKoValue),
                        ])
                    }),
                    defaultCatchError(),
                )
            },
        ),

        )
export const recalculateEpic = (action$: Observable<any>): Observable<any> =>
    action$.pipe(
        filter(x => x.type === ACTION_RECALCULATE),
        mergeMap(
            (action): Observable<any> => {
                return httpGet(plantsUrl(`/tax/recalculate/${action.propertyId}`)).pipe(
                    mergeMap((taxation) => {
                        return from([
                            actionPropertyTaxationUpdateSuccess(action.propertyId, taxation),
                        ])
                    }),
                    defaultCatchError(),
                )
            },
        ),
    )


export const taxationEpics = combineEpics(
    createTaxation,
    getTaxation,
    getTaxationWithHistory,
    updateTaxation,
    finishTaxation,
    reopenTaxation,
    uploadTaxationFiles,
    updateTaxationAfterComplaint,
    loadTaxationCorrespondance,
    loadTaxationCommunication,
    uploadAttachment,
    sendCommunication,
    uploadManualValueDoc,
    uploadManualValue,
    recalculateEpic
)
