import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Grid, IconButton, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getYesNoBoolOptions, tr } from 'utils/translations/translate'
import {
    TEXT_LAND_VALUE,
    TEXT_NO,
    TEXT_USED_FOR_PARKING,
    TEXT_PARKING_SPACES,
    TEXT_YES,
    TEXT_REGULATED,
    TEXT_PLOT_SIZE,
    TEXT_HAS_PARKING,
    TEXT_USE,
    TEXT_VALUE_OVER,
    TEXT_VALUE_UNDER,
    TEXT_ATTACHMENT_PLOT,
    TEXT_MUNICIPALITY_ADJUSTMENT,
    TEXT_TOTAL_PLOT_VALUE,
    TEXT_CANCEL,
} from 'utils/translations/keys'
import { formatArea, formatCurrency } from 'utils'
import { Property } from 'models/property'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import { actionPropertySave } from 'property-page/property-actions'
import { submit, Field, formValueSelector, reset } from 'redux-form'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { PropertyDetailsPanelForm } from './property-details-form'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { Edit, Save } from '@material-ui/icons'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { formatPercentage } from 'year-settings-page/helper-functions'
import { getOfficeAdjustment } from 'models/year-settings'
import {
    getFinalPlotValue,
    getLeaseCalculation,
    getLeasePlotValue,
    getOver1000Area,
    getOver1000Multiplier,
    getPropertyValueTypeDescription,
    getUnder1000Area,
    getUnder1000Multiplier,
    getValueOver1000m,
    getValueUnder1000m,
} from 'utils/property/property-helper'

type PanelProps = {
    styles: any
    property: Property
}

export const PropertyValuePanel: React.FC<PanelProps> = (props) => {
    const { styles, property } = props
    const [editing, setEditing] = useState(false)
    const [propertyPanelExpanded, setPropertyPanelExpanded] = useState(false)
    const dispatch = useDispatch()
    const selector = formValueSelector(TaxFormName.PropertyValuePanel)
    const stateRef = useSelector((state: AppState) => state)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)
    const yearSettings = useSelector((state: AppState) => state.taxSettings.yearSettings)

    const startEditing = (e: React.MouseEvent) => {
        e.stopPropagation()
        setPropertyPanelExpanded(true)
        setEditing(true)
    }
    const handleFormSubmit = (values: any) => {
        setEditing(false)
        dispatch(actionPropertySave(property, values))
    }
    const handleSaveClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch(submit(TaxFormName.PropertyValuePanel))
    }
    const handleCancelEdit = () => {
        setEditing(false)
        dispatch(reset(TaxFormName.PropertyValuePanel))
    }
    return (
        <Accordion expanded={propertyPanelExpanded} className={styles.lessGrouped} elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={styles.panelSummary}
                onClick={() => setPropertyPanelExpanded(!propertyPanelExpanded)}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>{tr(TEXT_LAND_VALUE)}</Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>{formatCurrency(getFinalPlotValue(property, settings, yearSettings))}</Grid>
                            <Protected p={Permission.Complaints}>
                                {!editing && (
                                    <Grid item>
                                        <IconButton size="small" onClick={startEditing} color="inherit">
                                            <Edit />
                                        </IconButton>
                                    </Grid>
                                )}
                                {editing && (
                                    <Grid item>
                                        <Button
                                            className={styles.cancelButton}
                                            onClick={handleCancelEdit}
                                            color="inherit"
                                        >
                                            {tr(TEXT_CANCEL)}
                                        </Button>
                                        <IconButton size="small" onClick={handleSaveClick} color="inherit">
                                            <Save />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Protected>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={styles.panelContent}>
                <Grid container spacing={2} className={styles.alignRight}>
                    <Grid item md={3} sm={6} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_REGULATED)}:
                    </Grid>
                    <Grid item md={3} className={styles.gap} />
                    <Grid item md={3} sm={6} xs={6}>
                        {property.regulated ? tr(TEXT_YES) : tr(TEXT_NO)}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} />

                    <Grid item md={3} sm={6} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_USE)}:
                    </Grid>
                    <Grid item md={3} className={styles.gap} />
                    <Grid item md={3} sm={6} xs={6}>
                        {getPropertyValueTypeDescription(property.valueType)}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} />

                    <Grid item md={3} sm={6} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_PLOT_SIZE)}:
                    </Grid>
                    <Grid item md={3} className={styles.gap} />
                    <Grid item md={3} sm={6} xs={6}>
                        {formatArea(property.plotSize)}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} />

                    <Grid item md={3} sm={12} xs={12} className={styles.panelContent__label}>
                        {tr(TEXT_VALUE_UNDER)} 1000 m²:
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>{`${getUnder1000Area(property)}m² x ${getUnder1000Multiplier(
                        property,
                    )}kr/m²`}</Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        {formatCurrency(getValueUnder1000m(property))}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} />

                    <Grid item md={3} sm={12} xs={12} className={styles.panelContent__label}>
                        {tr(TEXT_VALUE_OVER)} 1000 m²:
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>{`${getOver1000Area(property)}m² x ${getOver1000Multiplier(
                        property,
                    )}kr/m²`}</Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        {formatCurrency(getValueOver1000m(property))}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} />

                    <Grid item md={3} sm={6} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_TOTAL_PLOT_VALUE)}:
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} className={styles.spacer} />
                    <Grid item md={3} sm={6} xs={6} className={styles.bold}>
                        {formatCurrency(property.plotValue)}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} />

                    <Grid item md={3} sm={12} xs={12} className={styles.panelContent__label}>
                        {tr(TEXT_ATTACHMENT_PLOT)}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        {getLeaseCalculation(property, settings)}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} className={styles.bold}>
                        {getLeasePlotValue(property, settings)}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} />

                    <Grid item md={3} sm={12} xs={12} className={styles.panelContent__label}>
                        {tr(TEXT_MUNICIPALITY_ADJUSTMENT)}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        {formatPercentage(getOfficeAdjustment(yearSettings))}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} className={styles.bold}>
                        {formatCurrency(getFinalPlotValue(property, settings, yearSettings))}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} />

                    {!editing && property.otherPropertyFlags && property.otherPropertyFlags.hasParking && (
                        <Grid item sm={5} xs={12} className={styles.panelContent__label}>
                            {tr(TEXT_HAS_PARKING)}
                        </Grid>
                    )}
                    {editing && property.otherPropertyFlags && (
                        <Grid container>
                            <PropertyDetailsPanelForm
                                form={TaxFormName.PropertyValuePanel}
                                onSubmit={handleFormSubmit}
                                initialValues={{
                                    [TaxFormFieldNames.otherPropertyFlags]:
                                        property[TaxFormFieldNames.otherPropertyFlags],
                                }}
                            >
                                <Grid
                                    container
                                    className={styles.container}
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item sm={5} xs={12} className={styles.panelContent__label}>
                                        {tr(TEXT_USED_FOR_PARKING)}
                                    </Grid>
                                    <Grid item sm={5} xs={12}>
                                        <Field
                                            name={`${[TaxFormFieldNames.otherPropertyFlags]}.${
                                                TaxFormFieldNames.hasParking
                                            }`}
                                            component={CubitRadioGroupAdapter}
                                            options={getYesNoBoolOptions()}
                                            direction="row"
                                            justifyContent="flex-end"
                                        />
                                    </Grid>
                                </Grid>
                                {getFieldValue(
                                    `${[TaxFormFieldNames.otherPropertyFlags]}.${TaxFormFieldNames.hasParking}`,
                                ) ? (
                                    <Grid container className={styles.container} spacing={2}>
                                        <Grid item sm={3} xs={12} className={styles.panelContent__label}>
                                            {tr(TEXT_PARKING_SPACES)}:
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                            <Field
                                                name={`${[TaxFormFieldNames.otherPropertyFlags]}.${
                                                    TaxFormFieldNames.parkingSpaces
                                                }`}
                                                className={styles.textInput}
                                                component={CubitTextFieldAdapter}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </PropertyDetailsPanelForm>
                        </Grid>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}
