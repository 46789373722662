import { Field, reduxForm, isValid } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import * as React from 'react'
import { Button, Grid, makeStyles, Theme, Paper, Divider, InputAdornment, IconButton } from '@material-ui/core'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { tr } from 'utils/translations/translate'
import {
    TEXT_REGISTER,
    TEXT_PASSWORD,
    TEXT_WELCOME_TO,
    TEXT_CUBIT_TAX,
    TEXT_REPEAT_PASSWORD,
    TEXT_PASSWORD_DOESNT_MATCH,
    TEXT_REQUIRED,
    TEXT_MINIMUM_LENGTH,
} from 'utils/translations/keys'
import { grey } from '@material-ui/core/colors'
import { RouteComponentProps } from '@reach/router'
import { useState, useEffect } from 'react'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { actionGetWelcomeUser, actionWelcomeUserRegister } from 'app/app-actions'
import { useWelcomeUser } from 'app/app-selectors'
import { WelcomeUser } from 'models/welcome-user'

type FormProps = {
    handleSubmit: any
    welcomeUser: WelcomeUser | null
}
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: `radial-gradient(circle at top, ${theme.palette.primary.main} 35%, #294903 150%)`,
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '100%',
        minHeight: '100%',
        overflow: 'auto',
    },
    mainGrid: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        padding: theme.spacing(2),
    },

    userName: {
        color: theme.palette.primary.contrastText,
        fontSize: theme.typography.h4.fontSize,
        textTransform: 'uppercase',
        letterSpacing: '1.5px',
        textAlign: 'center',
        lineHeight: '48px',
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h5.fontSize,
            lineHeight: '32px',
        },
    },

    welcomeTo: {
        color: theme.palette.primary.contrastText,
        fontSize: theme.typography.h4.fontSize,
        fontWeight: 300,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h5.fontSize,
        },
    },
    appName: {
        color: theme.palette.primary.contrastText,
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'uppercase',
        letterSpacing: '2px',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h4.fontSize,
        },
    },

    loginContainer: {
        width: '100%',
        maxWidth: '444px',
        padding: theme.spacing(0, 4, 4, 4),
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2, 2, 2),
        },
    },

    loginTitle: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.h3.fontSize,
        fontWeight: 300,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h4.fontSize,
        },
    },

    loginDivider: {
        margin: theme.spacing(0, -4),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0, -2),
        },
    },

    filledInputRoot: {
        '& .MuiInputBase-root': {
            borderRadius: 0,
            border: `1px solid ${grey[400]}`,
        },
    },

    loginButtonRoot: {
        width: '100%',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: 300,
    },
    centerAlignFlexItem: {
        margin: 'auto',
    },
}))

const matchPasswords = (value: string, allValues: any) =>
    value !== allValues.password ? tr(TEXT_PASSWORD_DOESNT_MATCH) : undefined

const required = (value: string) => (!value ? tr(TEXT_REQUIRED) : undefined)

const minimumLength = (value: string) => (value && value.length < 6 ? `${tr(TEXT_MINIMUM_LENGTH)} 6` : undefined)

const Form = (props: FormProps) => {
    const { handleSubmit, welcomeUser } = props
    const styles = useStyles()
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)

    const handleMouseDown = (event: React.MouseEvent) => {
        event.preventDefault()
    }

    const isFormValid = useSelector(isValid(RegisterFormName))

    return (
        <div className={styles.container}>
            <Grid container className={styles.mainGrid}>
                <Grid item className={styles.centerAlignFlexItem}>
                    <Grid container justifyContent="center" alignContent="center">
                        <Grid item xs={12} className={styles.userName}>
                            {welcomeUser && `${welcomeUser.name},`}
                        </Grid>
                        <Grid item xs={12} className={styles.welcomeTo}>
                            {tr(TEXT_WELCOME_TO)}
                        </Grid>
                        <Grid item xs={12} className={styles.appName}>
                            {tr(TEXT_CUBIT_TAX)}
                        </Grid>

                        {welcomeUser && (
                            <Grid item>
                                <Paper className={styles.loginContainer} elevation={20}>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2} justifyContent="center" alignContent="center">
                                            <Grid item className={styles.loginTitle}>
                                                {tr(TEXT_REGISTER)}
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={4} justifyContent="center" alignContent="center">
                                            <Grid item xs={12}>
                                                <Divider className={styles.loginDivider}></Divider>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field
                                                    name="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    label={tr(TEXT_PASSWORD)}
                                                    variant="filled"
                                                    classes={{ root: styles.filledInputRoot }}
                                                    component={CubitTextFieldAdapter}
                                                    autoFocus={true}
                                                    validate={[required, minimumLength]}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    onMouseDown={handleMouseDown}
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field
                                                    name="passwordRepeat"
                                                    type={showPasswordRepeat ? 'text' : 'password'}
                                                    label={tr(TEXT_REPEAT_PASSWORD)}
                                                    variant="filled"
                                                    classes={{ root: styles.filledInputRoot }}
                                                    component={CubitTextFieldAdapter}
                                                    validate={[matchPasswords, required, minimumLength]}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        setShowPasswordRepeat(!showPasswordRepeat)
                                                                    }
                                                                    onMouseDown={handleMouseDown}
                                                                >
                                                                    {showPasswordRepeat ? (
                                                                        <Visibility />
                                                                    ) : (
                                                                        <VisibilityOff />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    classes={{ root: styles.loginButtonRoot }}
                                                    disabled={!isFormValid}
                                                >
                                                    {tr(TEXT_REGISTER)}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const RegisterFormName = 'RegisterForm'
const RegisterForm = reduxForm<{}, { welcomeUser: WelcomeUser | null }>({
    form: RegisterFormName,
})(Form)

export const RegisterPage: React.FC<RouteComponentProps<{ newUserKey: string }>> = props => {
    const dispatch = useDispatch()
    const { newUserKey = '' } = props

    const welcomeUser = useWelcomeUser()

    useEffect(() => {
        dispatch(actionGetWelcomeUser(newUserKey))
    }, [dispatch, newUserKey])

    const handleSubmit = (data: any) =>
        welcomeUser && dispatch(actionWelcomeUserRegister(welcomeUser.email, data.password, newUserKey))

    return <RegisterForm onSubmit={handleSubmit} welcomeUser={welcomeUser} />
}
