import { GroupedComplaints } from 'tribunal-page/models/grouped-complaints.type'
import { Complaint } from 'tribunal-page/models/complaint.type'
import { ComplaintGroupType } from 'tribunal-page/enums/complaint-group-type.enum'

const groupNameByComplaintGroupType: {
    [key: string]: 'political' | 'cadastralError' | 'valuationError' | 'judgmentalError' | 'complaintBoard' | 'combined'
} = {
    [ComplaintGroupType.Political]: 'political',
    [ComplaintGroupType.CadastralError]: 'cadastralError',
    [ComplaintGroupType.ValuationError]: 'valuationError',
    [ComplaintGroupType.JudgmentalError]: 'judgmentalError',
    [ComplaintGroupType.ComplaintBoard]: 'complaintBoard',
    [ComplaintGroupType.Combined]: 'combined',
}

export default (complaints: Complaint[]): GroupedComplaints => {
    const groupedComplaints: GroupedComplaints = {
        combined: [],
        political: [],
        cadastralError: [],
        valuationError: [],
        judgmentalError: [],
        complaintBoard: [],
    }

    for (let index = 0; index < complaints.length; index++) {
        const complaint = complaints[index]
        if (complaint.complaintGroupType !== undefined) {
            groupedComplaints[groupNameByComplaintGroupType[complaint.complaintGroupType]].push(complaint)
        }
    }

    return groupedComplaints
}
