import config from '../../config'
import { en } from './languages/en'
import { nb } from './languages/nb'
import { Language } from '../../settings/language/language.enum'

export const strings: any = {}

strings[Language.English] = en
strings[Language.Norwegian] = nb

const getLang = (): string => {
    const lang = localStorage.getItem(config.localStorageLangKey)
    return lang !== null ? lang : config.language
}

export const tr = (key: string, params?: Array<any>): string => {
    const lang = getLang()
    let translated = ''
    try {
        const translatedString = strings[lang][key] || ''
        translated = eval('`' + translatedString + '`')
    } catch (e) {
        console.log('translation error', { key, params })
    }

    return translated
}
