import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCubitTableClearSelection } from 'shared-components/src/cubit-table/cubit-table-actions'
import ContextBar from 'common/context-bar/context-bar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {
    TEXT_OWNERS,
    TEXT_PROPERTIES,
    TEXT_SCHEDULE_TAXATION,
    TEXT_SELECTED,
    TEXT_SELECT_FOR_TAXATION,
    TEXT_ASSIGN,
    TEXT_COMPANIES,
    TEXT_USERS,
    TEXT_START_TAXATION,
    TEXT_UNASSIGN,
    TEXT_SEND_BUSINESS_TAXATION_NOTICE,
    TEXT_SEND_BUSINESS_TAXATION_NOTICE2,
    TEXT_SEND_YEAR_TAX,
    TEXT_DETAILED_TAX_LETTER,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import { actionPlanningEventsCreate } from '../../planning-page/planning-actions'
import { SearchResults } from '../search-reducer'
import { AppState } from 'app/app-store'
import { Property, StateModel } from '../../models/property'
import { CalendarEventType } from 'shared-components/src/models/calendar-event'
import {
    FormControl,
    Select,
    OutlinedInput,
    MenuItem,
    Divider,
    CircularProgress,
    makeStyles,
    Theme,
} from '@material-ui/core'
import { sortBy } from 'lodash'
import {
    actionAssignProperties,
    actionSendRikoNotices,
    actionSendYearLetters,
    actionUnassignProperties,
} from 'search-page/search-actions'
import { User } from 'models/user'
import { Company } from 'models/company'
import { PropertiesTableName } from 'search-page/properties-results/properties-table'
import { UserSelection } from 'common/user-selection/user-selection'
import { useUserId } from 'auth/auth-selectors'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { allAssigned, anyNotified, anyUnsentYearLetters, GetUnsentYearLettersIds, isRingerike } from 'search-page/search-toolbar/helper'
import { actionCreateTaxation } from 'property-page/property-actions'

const useStyles = makeStyles((theme: Theme) => ({
    spinnerButton: {
        '&.Mui-disabled': {
            color: '#fff',
            borderColor: '#fff',
        },
    },
    spinnerIcon: {
        height: '24px !important',
        width: '24px !important',
        marginLeft: 10,
    },
    contextBarItem: {
        paddingLeft: 36,
        minWidth: 150
    },
    contextBarContainer: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 5
        },
    }
}))

type SearchSelectionContextBarProps = {
    users: { [key: string]: User }
    companies: Company[]
}

const SearchSelectionContextBar: React.FC<SearchSelectionContextBarProps> = (props) => {
    const { users, companies } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const activeTabIndex: number = useSelector((state: AppState) => state.search.activeTabIndex)
    const showMap: boolean = useSelector((state: AppState) => state.search.showMap)
    const results: SearchResults = useSelector((state: AppState) => state.search.results)
    const currentUserId = useUserId()

    const usersArray = sortBy(
        sortBy(
            Object.keys(users).map((key) => users[key]),
            (u) => u.name,
        ),
        (u) => u.id !== currentUserId,
    )

    const usersWithoutCompanyArray = usersArray.filter((u) => !u.taxCompanyId)

    const properties = results.properties.items as unknown as Property[]
    const selectedPropertiesIds: string[] = useSelector((state: AppState) =>
        state.table.properties && state.table.properties.selection ? state.table.properties.selection : [],
    )
    const selectedProperties = properties.filter((p) => selectedPropertiesIds.some((id: string) => p.id === id))

    const selectedOwners = useSelector((state: AppState) =>
        state.table.owners && state.table.owners.selection ? state.table.owners.selection : [],
    )

    const [sending, setSending] = useState(false)
    useEffect(() => {
        setSending(false)
    }, [selectedPropertiesIds])

    const clearSelectedItemsHandler = (tableName: string) => {
        dispatch(actionCubitTableClearSelection(tableName))
    }

    const handleSelectForTaxation = () => {
        const stateModels = selectedProperties.map((property) => ({
            propertyId: property.id,
        }))

        dispatch(actionAssignProperties(stateModels))
    }

    const handleAssignPropertyForCompany = (company: any) => {
        if (!company) {
            return
        }

        const stateModels = selectedProperties.map(
            (property) =>
            ({
                companyId: company.id,
                propertyId: property.id,
            } as StateModel),
        )

        assignProperties(stateModels)
    }
    const handleAssignPropertyForUser = (user: any) => {
        if (!user) {
            return
        }

        const stateModels = selectedProperties.map(
            (property) =>
            ({
                companyId: user.taxCompanyId,
                userId: user.id,
                propertyId: property.id,
            } as StateModel),
        )

        assignProperties(stateModels)
    }

    const assignProperties = (stateModels: StateModel[]) => {
        dispatch(actionAssignProperties(stateModels))
    }

    const unassignProperties = () => {
        dispatch(actionUnassignProperties(selectedPropertiesIds))
    }

    const handleScheduleTaxationChange = (userId: string) => {
        if (!userId) {
            return
        }

        dispatch(actionPlanningEventsCreate(selectedProperties, userId, CalendarEventType.ManualTaxation))
        dispatch(actionCubitTableClearSelection(PropertiesTableName))
    }

    const tenant = useSelector((state: AppState) => state.auth.tenant)
    const tenantId: string = tenant?.id ?? ''
    const isProd = !!tenant?.isProduction

    const handleSendyearTax = (isStatic: boolean = false) => {
        setSending(true)
        const data = {
            ids: GetUnsentYearLettersIds(selectedProperties),
            useStaticTemplate: isStatic
        }
        dispatch(actionSendYearLetters(data))
    }

    const handleSendRikoNotices = () => {
        setSending(true)
        dispatch(actionSendRikoNotices(selectedPropertiesIds))
    }

    const noticeSent = selectedProperties.length === 1 && selectedProperties[0].otherPropertyFlags?.taxNoticeSent
    const canStartTaxation = selectedPropertiesIds.length === 1 && showMap && noticeSent
    const handleCreateTaxation = () => {
        dispatch(actionCreateTaxation(selectedPropertiesIds[0]))
    }

    if (activeTabIndex === 0 && selectedProperties.length) {
        return (
            <ContextBar>
                <Grid className={styles.contextBarContainer} container alignItems="center" spacing={2}>
                    <Grid item>
                        <IconButton
                            onClick={() => clearSelectedItemsHandler(PropertiesTableName)}
                            color="inherit"
                            edge="start"
                            aria-label="Clear"
                            disabled={sending}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid className={styles.contextBarItem} item xs>
                        <Typography variant="h6">
                            {`${selectedProperties.length} ${tr(TEXT_PROPERTIES)} ${tr(TEXT_SELECTED)}`.toLowerCase()}
                        </Typography>
                    </Grid>

                    {anyUnsentYearLetters(selectedProperties) && (
                        <>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    type="button"
                                    onClick={() => handleSendyearTax(true)}
                                    disabled={sending}
                                    className={styles.spinnerButton}
                                >
                                    {`${tr(TEXT_SEND_YEAR_TAX)} ${GetUnsentYearLettersIds(selectedProperties).length}`} {sending && <CircularProgress className={styles.spinnerIcon} />}
                                </Button>
                            </Grid>
                        </>
                    )}

                    {allAssigned(selectedProperties) && (
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="inherit"
                                type="button"
                                onClick={unassignProperties}
                                disabled={sending}
                                className={styles.spinnerButton}
                            >
                                {tr(TEXT_UNASSIGN)} {sending && <CircularProgress className={styles.spinnerIcon} />}
                            </Button>
                        </Grid>
                    )}

                    {/* {isRingerike(tenantId) && !anyNotified(selectedProperties) && (
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="inherit"
                                type="button"
                                onClick={handleSendRikoNotices}
                                disabled={sending}
                                className={styles.spinnerButton}
                            >
                                {isProd ? tr(TEXT_SEND_BUSINESS_TAXATION_NOTICE) : tr(TEXT_SEND_BUSINESS_TAXATION_NOTICE2)}{' '}
                                {sending && <CircularProgress className={styles.spinnerIcon} />}
                            </Button>
                        </Grid>
                    )} */}
                    <Protected p={[Permission.TaxateAllTaxations, Permission.TaxateOwnCompanyTaxations]}>
                        {canStartTaxation && (
                            <Grid item>
                                <Button variant="outlined" color="inherit" type="button" onClick={handleCreateTaxation}>
                                    {tr(TEXT_START_TAXATION)}
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button variant="outlined" color="inherit" type="button" onClick={handleSelectForTaxation}>
                                {tr(TEXT_SELECT_FOR_TAXATION)}
                            </Button>
                        </Grid>

                        <Grid item>
                            <FormControl className="whiteControl">
                                <Select
                                    value={0}
                                    onChange={(e) => handleAssignPropertyForUser(e.target.value)}
                                    input={<OutlinedInput margin="dense" labelWidth={0} />}
                                >
                                    <MenuItem value={0}> {tr(TEXT_ASSIGN)}</MenuItem>
                                    <li style={{ padding: '8px', cursor: 'default' }}>{tr(TEXT_COMPANIES)}</li>

                                    <Divider></Divider>

                                    {companies.map((company: any, index: number) => (
                                        <span key={index}>
                                            <MenuItem onClick={() => handleAssignPropertyForCompany(company)}>
                                                {company.name}
                                            </MenuItem>
                                            {company.users.map((userId: string, index: number) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={() => handleAssignPropertyForUser(users[userId])}
                                                    style={{ paddingLeft: '32px' }}
                                                >
                                                    {users[userId].name}
                                                </MenuItem>
                                            ))}
                                            <Divider></Divider>
                                        </span>
                                    ))}

                                    <Protected p={Permission.TaxateAllTaxations}>
                                        <li style={{ padding: '8px', cursor: 'default' }}>{tr(TEXT_USERS)}</li>

                                        <Divider></Divider>

                                        {usersWithoutCompanyArray.map((user: any, index: number) => (
                                            <MenuItem key={index} onClick={() => handleAssignPropertyForUser(user)}>
                                                {user.name}
                                            </MenuItem>
                                        ))}
                                    </Protected>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Protected>
                    <Grid item>
                        <UserSelection usersArray={usersArray} onChange={handleScheduleTaxationChange}></UserSelection>
                    </Grid>
                </Grid>
            </ContextBar>
        )
    }

    if (activeTabIndex === 1 && selectedOwners.length) {
        return (
            <ContextBar>
                <Grid style={{ flexGrow: 1 }} container alignItems="center">
                    <Grid item>
                        <IconButton
                            onClick={() => clearSelectedItemsHandler('owners')}
                            color="inherit"
                            edge="start"
                            aria-label="Clear"
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid style={{ paddingLeft: '36px' }} item xs>
                        <Typography variant="h6">
                            {`${selectedOwners.length} ${tr(TEXT_OWNERS)} ${tr(TEXT_SELECTED)}`.toLowerCase()}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="inherit" type="button" onClick={() => { }}>
                            {tr(TEXT_SCHEDULE_TAXATION)}
                        </Button>
                    </Grid>
                </Grid>
            </ContextBar>
        )
    }

    return null
}

export default SearchSelectionContextBar
