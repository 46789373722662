import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { useDispatch, useSelector } from 'react-redux'
import { tr } from 'utils/translations/translate'
import {
    TEXT_APPROVED_TRIBUNAL,
    TEXT_GROUP_NAME,
    TEXT_GROUP_DESCRIPTION,
    TEXT_APPROVAL_DATE,
    TEXT_NUMBER_OF_PROPERTIES,
} from 'utils/translations/keys'
import { Paper } from '@material-ui/core'
import { AppState } from 'app/app-store'
import { navigate, RouteComponentProps } from '@reach/router'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { actionTribunalGetApproved } from 'tribunal-page/tribunal-actions'
import { CubitTableColumn } from 'shared-components/src/cubit-table/cubit-table.types'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'
import { getDateString } from 'settings-tax-page/helper-functions'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { GroupStatus } from 'tribunal-page/enums/group-status'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { TribunalApprovedContextBar } from './tribunal-approved-context-bar'

export const TribunalApprovedPage: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch()

    const approved = useSelector((state: AppState) => state.tribunal.approved)
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    const count = approved.length
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        if (!loading && !count) {
            setLoading(true)
            dispatch(actionTribunalGetApproved())
        }
    }, [loading, setLoading, dispatch, count])

    const groupColumns = (): CubitTableColumn[] => [
        {
            headerLabel: tr(TEXT_GROUP_NAME),
            key: 'group.name',
            getDisplayableElement: (item) => (
                <span>{item.group.name}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_GROUP_DESCRIPTION),
            key: 'group.description',
            getDisplayableElement: (item) => (
                <span>{item.group.description}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_APPROVAL_DATE),
            key: 'group.statusDate',
            getDisplayableElement: (item) => (
                <span>{getDateString(item.group.statusDate, language)}</span>
            ),
        },
        {
            headerLabel: tr(TEXT_NUMBER_OF_PROPERTIES),
            key: 'count',
            getDisplayableElement: (item) => (
                <span>{item.count}</span>
            ),
        },
    ]

    const handleClick = (rowData: any) => {
        navigate(`/tribunal/group/historic/${rowData.group.id}`)
    }

    const isGroupSelectable = (groupData: any) => {
        return groupData.group.status !== GroupStatus.NOTIFIED
    }

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={<TitleToolbar title={tr(TEXT_APPROVED_TRIBUNAL)} />}
            contextbar={<TribunalApprovedContextBar />}
        >
            <Protected p={Permission.ViewTribunalAll}>
                <Paper elevation={0}>
                    <CubitTable
                        selectable={isGroupSelectable}
                        sorting
                        flat
                        columns={groupColumns()}
                        data={approved}
                        name={'approvedTribunal'}
                        onRowClick={handleClick}
                    />
                </Paper>
            </Protected>
        </LayoutDefault>
    )
}
