import { makeReducer } from '../utils'
import {
    ACTION_CUBIT_TABLE_ORDER,
    ACTION_CUBIT_TABLE_SELECT,
    ACTION_CUBIT_TABLE_CLEAR_SELECTION,
} from './cubit-table-actions'
import { AnyAction } from 'redux'
import { OrderDirection } from './cubit-table.types'
import { Reducers } from '../utils/make-reducer'

export type CubitTableData = {
    orderBy: string
    orderDirection: OrderDirection
    selection?: any[]
}

export type CubitTableState = {
    [key: string]: CubitTableData
}

type CubitTableReducer = (state: CubitTableState, action: AnyAction) => CubitTableState

const initialState: CubitTableState = {
    properties: {
        orderBy: 'id',
        orderDirection: 'asc',
    },
    owners: {
        orderBy: 'id',
        orderDirection: 'asc',
    },
}

const changeOrder: CubitTableReducer = (state, action) => {
    state[action.tableName] = {
        orderBy: action.orderBy,
        orderDirection: action.orderDirection,
    }
    return state
}

const select: CubitTableReducer = (state, action) => {
    let tableState = state[action.tableName]

    state[action.tableName] = { ...tableState, selection: action.selection }

    return state
}

const clearSelection: CubitTableReducer = (state, action) => {
    let tableState = state[action.tableName]

    state[action.tableName] = { ...tableState, selection: [] }

    return state
}

const reducers: Reducers<CubitTableState> = {
    [ACTION_CUBIT_TABLE_ORDER]: changeOrder,
    [ACTION_CUBIT_TABLE_SELECT]: select,
    [ACTION_CUBIT_TABLE_CLEAR_SELECTION]: clearSelection,
}

export const cubitTableReducer = makeReducer<CubitTableState>(reducers, initialState)
