import React from 'react'

import { useDispatch } from 'react-redux'

import { change } from 'redux-form'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import { filter } from 'lodash'
import Grid from '@material-ui/core/Grid'
import { InputType } from '../cubit-inputs/input-type.enum'
import { AvailableFormFields } from '../cubit-inputs/cubit-form-field.types'
import { getChipGroup } from './search-filters-chips/search-filters-chip-group'

type ChipType = {
    key: string
    label: string
    value: string | number
    type: InputType
}
export type ChipGroupType = { name: string; label: string; chips: ChipType[] }

type SearchFiltersChipsProps = {
    formName: string
    formState: any
    formFields: AvailableFormFields
}

const useStyles = makeStyles((theme: Theme) => ({
    chipGroup: {
        padding: theme.spacing(0, 2),
    },
    chip: {
        margin: theme.spacing(1),
    },
}))

const SearchFiltersChips: React.FC<SearchFiltersChipsProps> = props => {
    const { formName, formState, formFields } = props
    const stateCopy = { ...formState }
    const classes = useStyles()
    let filterChipGroups: ChipGroupType[] = []

    const dispatch = useDispatch()

    const handleChipDelete = (chip: ChipType) => {
        const previousFormStateByType = formState[chip.key]

        let currentFilterState

        if (chip.type === InputType.SliderRange) {
            currentFilterState = []
        } else if (Array.isArray(previousFormStateByType)) {
            currentFilterState = filter(previousFormStateByType, entry => {
                return entry.label !== chip.label || entry.value !== chip.value
            })
        } else if (typeof previousFormStateByType === 'boolean') {
            currentFilterState = false
        } else {
            currentFilterState = ''
        }

        dispatch(change(formName, chip.key, currentFilterState))
    }

    if (stateCopy) {
        // shallow remove empty arrays from state copy
        Object.keys(stateCopy).forEach(
            key =>
                Array.isArray(stateCopy[key]) &&
                stateCopy[key].length === 0 &&
                delete stateCopy[key],
        )

        filterChipGroups = Object.keys(stateCopy).map((formFieldName: string) =>
            getChipGroup(formState[formFieldName], formFields[formFieldName]()),
        )
    }

    return (
        <>
            {filterChipGroups.map((chipGroup, groupIndex) =>
                chipGroup ? (
                    <Grid item key={groupIndex} className={classes.chipGroup}>
                        <span>{chipGroup.label}</span>
                        {chipGroup.chips.map((chip, chipIndex) => (
                            <Chip
                                key={chipIndex}
                                label={chip.label}
                                className={classes.chip}
                                onDelete={() => handleChipDelete(chip)}
                            />
                        ))}
                    </Grid>
                ) : null,
            )}
        </>
    )
}

export default SearchFiltersChips
