export enum DocumentTypes {
    TEXT = 'TEXT',
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    CASE_DOCUMENT = 'CASE_DOCUMENT',
    DOCUMENT_TEMPLATE = 'DOCUMENT_TEMPLATE',
    IMAGE = 'IMAGE',
    LETTER = 'LETTER'
}

export enum TextTemplateTypes {
    NORMAL = 'normal',
    ERROR_HEADER = 'errorheader',
    ERROR_DESCRIPTION = 'errordescription',
    REMARK_HEADER = 'remarkheader',
    REMARK_DESCRIPTION = 'remarkdescription',
    GENERAL_REMARK = 'generalremark',
    TEFT_DESCRIPTION = 'teftdescription',
    COPY_RECIPIENTS = 'copyrecipients',
    CONTACT_DEFINED = 'contactdefined',
    CONTACT_NOT_DEFINED = 'contactnotdefined',
    TEMPLATE_TEXT = 'templatetext',
    ALWAYS_AFTER = 'alwaysafter',
    ALWAYS_BEFORE = 'alwaysbefore',
    NOT_INSIDE = 'notinside',
}