import { YES } from "common/constants/constants"

export type YearSettings = {
    year: number
    municipalityAdjustment: string
    municipalityAdjustmentValue: number
    nationalValuation: string
    minimumTax: number
    propertyType: string
    propertyTypeZones: string[]
    exemptZones: string[]
    zoneRates: any //{[key: string]: [value: number]}
    differentRateForDwelling: boolean
    differentRateWithoutBuildings: boolean
    differentRateForLand: boolean
    differentRateForZones: boolean
    defaultTaxRate: number
    dwellingTaxRate: number
    rateWithBuildings: number
    rateWithoutBuildings: number
    buildingsRate: number
    landRate: number
    reductionFactor: number
    useFlatReduction: string
    flatReductionValue: number
    exemptionGovernmentBeneficial: boolean
    exemptionHistoricValue: boolean
    exemptionNewBuildings: boolean
    exemptionNewBuildingsValue: number
    exemptionZones: boolean
    exemptionSummerHouses: boolean
    minBuildingArea: number
    livingSpacePlot: number
    maxHousingSize: number
    attachmentPlotValue: number
    pointlessSize: number
    unknownPlotSize: number
    valuePerParkingSpace: number
    numberOfTerms: number
    closed: boolean
}

export const getOfficeAdjustment = (allSettings: any) => {
    const settings = allSettings && allSettings.settings ? allSettings.settings[0] : null
    return settings && settings.municipalityAdjustmentValue ? settings.municipalityAdjustmentValue : 0
}

export const getReductionFactor = (allSettings: any) => {
    const settings: YearSettings | undefined = allSettings && allSettings.settings ? allSettings.settings[0] : undefined
    return settings && settings.reductionFactor ? settings.reductionFactor : 0
}

export const getUseNationalValuation = (allSettings: any) => {
    const settings = allSettings?.settings ? allSettings.settings[0] : undefined
    return settings?.nationalValuation === YES
}
