import { Grid, makeStyles, Theme } from '@material-ui/core'
import { Building } from 'models/building'
import { Floor } from 'models/floor'
import React from 'react'
import { AppState } from 'app/app-store'
import { useSelector } from 'react-redux'
import { getBuildingTableHeader, getFloorRowContent } from './functions'
import { TableRow } from './table-row'
import { Taxation } from 'models/taxation'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
            flexDirection: 'column',
        },
        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },

        panelContent__label: {
            fontWeight: 500,
        },

        tableWrapper: {
            margin: '0 -16px',
            width: 'calc(100% + 48px)',
            maxWidth: 'calc(100% + 48px)',
            flexBasis: 'auto',
        },

        button: {
            color: theme.palette.grey[400],
        },
        cancelButton: {
            marginRight: theme.spacing(2),
        },

        cameraIcon: {
            marginRight: theme.spacing(1),
        },

        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        boldeded: {
            fontSize: 18,
            fontWeight: 600,
        },
        right: {
            textAlign: 'right'
        },
        center: {
            textAlign: 'center'  
        },
        tableRow: {
            padding: theme.spacing(1),
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            '& .MuiGrid-item': {
                padding: theme.spacing(1, 2)
            }
        },
        tableHeader: {
            padding: theme.spacing(1),
            fontWeight: 600,
            color: '#4a4a4a',
            fontSize: 14,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            '& .MuiGrid-item': {
                padding: theme.spacing(1, 2)
            }
        }
    }
})

type BuildingsTablesProps = {
    taxation: Taxation
}

export const BuildingsTables: React.FC<BuildingsTablesProps> = (props) => {
    const { taxation } = props
    const buildings = taxation.current.buildings
    const zoneFactor = taxation.current.property.zoneFactor
    const styles = useStyles()
    const buildingCodesMap = useSelector((state: AppState) => state.appData.buildingCodesMap)

    const floorBelongsToProp = (floor: Floor) => {
        if (!floor.matrikkelUnitId) {
            return true
        }
        const sections = taxation.current.property.otherPropertyFlags?.childrenSections ?? []
        const ids = [...sections, taxation.propertyId]
        return ids.includes(floor.matrikkelUnitId)
    }

    return (
        <Grid container>
            {buildings.map((building: Building, index: number) => (
                <Grid container spacing={2} key={index}>
                    <Grid item xs={12} className={styles.boldeded}>
                        {building.buildingNumber} {building.buildingCode} {buildingCodesMap[building.buildingCode]}
                    </Grid>
                    <Grid item xs={12} className={styles.tableWrapper}>
                        <TableRow 
                            content={getBuildingTableHeader(styles)}
                            containerClass={styles.tableHeader}
                        />
                        {building.floors &&
                            building.floors.filter(floorBelongsToProp).map((floor: Floor, index: number) => (
                                <TableRow
                                    key={index}
                                    content={getFloorRowContent(styles, floor, building, zoneFactor, {settings: [taxation.yearSettings]})}
                                    containerClass={styles.tableRow}
                                />
                            ))}
                    </Grid>
                    <Grid item xs={12} />
                </Grid>
            ))}
        </Grid>
    )
}
