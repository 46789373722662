import React from 'react'
import { AvailableFormFields, FormFields, FormField } from '../cubit-inputs/cubit-form-field.types'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import { Field, InjectedFormProps } from 'redux-form'
import { InputType } from '../cubit-inputs/input-type.enum'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Language } from 'settings/language/language.enum'

const useStyles = makeStyles((theme: Theme) => ({
    filterGroupLabel: {
        flex: '0 0 200px',
        minWidth: 0,
    },

    filterWrapper: {
        '& > .MuiGrid-item:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
    },

    filterMarginRight: {
        marginRight: theme.spacing(4),
    },

    dateWrapper: {
        maxWidth: '200px',
    },
}))

export type CubitFormProps = {
    fields: () => AvailableFormFields
    handleSubmit?: () => void
}

export const CubitForm: React.FC<InjectedFormProps & CubitFormProps> = props => {
    const { fields, handleSubmit } = props

    const formFields = fields()

    const classes = useStyles()

    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    const getFormElement = (field: FormFields, classes: any) => {
        const elements = {
            [InputType.Checkbox]: () => {
                const f = field() as FormField<InputType.Checkbox>
                return (
                    <>
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{f.props.label}:</FormLabel>
                        </Grid>
                        <Grid item>
                            <Field {...f.props} />
                        </Grid>
                    </>
                )
            },
            [InputType.DateRange]: () => {
                const f = field() as FormField<InputType.DateRange>
                return (
                    <>
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{f.label}:</FormLabel>
                        </Grid>
                        <Grid item className={classNames(classes.filterMarginRight, classes.dateWrapper)}>
                            <Field {...f.from.props} />
                        </Grid>

                        <Grid item className={classes.dateWrapper}>
                            <Field {...f.to.props} />
                        </Grid>
                    </>
                )
            },
            [InputType.Date]: () => {
                const f = field() as FormField<InputType.Date>
                return (
                    <>
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{f.props.label}:</FormLabel>
                        </Grid>
                        <Grid item className={classes.dateWrapper}>
                            <Field {...f.props} />
                        </Grid>
                    </>
                )
            },
            [InputType.NumberRange]: () => {
                const f = field() as FormField<InputType.NumberRange>
                return (
                    <>
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{f.label}:</FormLabel>
                        </Grid>
                        <Grid item className={classes.filterMarginRight}>
                            <Field {...f.from.props} />
                        </Grid>

                        <Grid item>
                            <Field {...f.to.props} />
                        </Grid>
                    </>
                )
            },
            [InputType.Switch]: () => {
                const f = field() as FormField<InputType.Switch>
                return (
                    <>
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{f.props.label}:</FormLabel>
                        </Grid>
                        <Grid item>
                            <Field {...f.props} />
                        </Grid>
                    </>
                )
            },
            [InputType.SliderRange]: () => {
                const f = field() as FormField<InputType.SliderRange>
                return (
                    <>
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{f.props.label}:</FormLabel>
                        </Grid>
                        <Grid item>
                            <Field {...f.props} />
                        </Grid>
                    </>
                )
            },
            [InputType.Select]: () => {
                const f = field() as FormField<InputType.Select>
                return (
                    <>
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{f.props.label}:</FormLabel>
                        </Grid>
                        <Grid item>
                            <Field {...f.props} />
                        </Grid>
                    </>
                )
            },
            [InputType.SelectGroup]: () => {
                const f = field() as FormField<InputType.SelectGroup>
                return (
                    <>
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{f.props.label}:</FormLabel>
                        </Grid>
                        
                        <Grid item className={classes.filterMarginRight}>
                            <Field {...f.props} />
                        </Grid>
                    </>
                )
            },
        }

        return elements[field().type]()
    }

    const renderField = (field: FormFields, index: number, classes: any): React.ReactElement => {
        return (
            <Grid item xs={12} key={index}>
                <Grid container alignItems="center">
                    {getFormElement(field, classes)}
                </Grid>
            </Grid>
        )
    }

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%' }} data-lang={language}>
            <Grid container spacing={2} className={classes.filterWrapper}>
                {Object.keys(formFields).map((fieldName, index) => renderField(formFields[fieldName], index, classes))}
                {/* 





                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{tr(TEXT_OWNER_CATEGORY)}:</FormLabel>
                        </Grid>
                        <Grid item>
                            <Field {...fields.ownerCategory().props} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{tr(TEXT_STANDARD)}</FormLabel>
                        </Grid>
                        <Grid item>
                            <Field {...fields.standard().props} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{tr(TEXT_LOCATION)}</FormLabel>
                        </Grid>
                        <Grid item>
                            <Field {...fields.location().props} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}> 
                    <Grid container alignItems="center">
                        <Grid item className={classes.filterGroupLabel}>
                            <FormLabel>{tr(TEXT_EVALUATION_DATE)}:</FormLabel>
                        </Grid>
                        <Grid item className={classNames(classes.filterMarginRight, classes.dateWrapper)}>
                            <Field {...fields.evaluationDate().from.props} />
                        </Grid>

                        <Grid item className={classes.dateWrapper}>
                            <Field {...fields.evaluationDate().to.props} />
                        </Grid>
                    </Grid>
                </Grid>
                */}
            </Grid>
        </form>
    )
}
