import React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

import { InputType } from '../input-type.enum'
import { RadioGroup, Grid, FormLabel, Divider } from '@material-ui/core'
import { SelectOptionType } from '../cubit-form-field.types'

export type CubitRadioGroupAdapterProps = { type: InputType.Radio }

export const CubitRadioGroupAdapter: React.FC<any> = (props: any) => {
    const { 
        name, 
        options, 
        direction = 'column', 
        justify = 'flex-start', 
        label, 
        input, 
        meta, 
        labelPlacement, 
        ...custom 
    } = props

    const radioButtons = options.map((option: any, index: number) => {
        return option.type === SelectOptionType.Title ? (
            <Grid key={index} style={{ padding: '8px', cursor: 'default' }}>
                {option.label}
            </Grid>
        ) : option.type === SelectOptionType.Divider ? (
            <Divider key={index}></Divider>
        ) : (
            <Grid key={index} item>
                <FormControlLabel
                    label={option.label}
                    labelPlacement={labelPlacement ? labelPlacement : 'end'}
                    value={option.value}
                    style={option.style}
                    control={<Radio color="primary" {...custom} />}
                />
            </Grid>
        )
    })

    const val = input.value === true ? 'true' : input.value === false ? 'false' : input.value

    const handleOnChange = (event: any) => {
        const v = event.target.value
        const changedValue = v === 'true' ? true : v === 'false' ? false : v

        input.onChange(changedValue)
    }

    return (
        <FormControl component="fieldset">
            {label ? <FormLabel component="legend">{label}</FormLabel> : null}
            <RadioGroup name={name} value={val} onChange={handleOnChange}>
                <Grid container direction={direction} justifyContent={justify}>
                    {radioButtons}
                </Grid>
            </RadioGroup>
        </FormControl>
    )
}
