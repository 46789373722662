import { TileWMS } from 'ol/source'

const wms = (
    url: string,
    params: {
        [x: string]: any
    },
) => new TileWMS({ url: url, params: params })

export default wms
