import { tr } from 'utils/translations/translate'
import {
    TEXT_RESIDENTIAL_PROPERTY,
    TEXT_LEISURE_PROPERTY,
    TEXT_FARMING_FORESTRY_WITH_HOUSING,
    TEXT_FARM_USE_FORESTRY_WITH_LEISURE,
    TEXT_FARMS_OR_FORESTRY,
    TEXT_NOT_SELECTED,
    TEXT_COMBINED_BUILT_PLOT,
    TEXT_COMMERCIAL,
    TEXT_UNDEVELOPED_PLOT_HOUSING,
    TEXT_UNDEVELOPED_PLOT_LEISURE,
    TEXT_UNDEVELOPED_PLOT_INDUSTRY,
    TEXT_UNDEVELOPED_PLOT,
    TEXT_FARM_AND_FORESTRY,
    TEXT_BUILDING_7C,
    TEXT_HISTORIC_VALUE,
    TEXT_MUNICIPAL_PROPERTY,
    TEXT_STATE_PROPERTY,
    TEXT_INSTITUTIONS,
    TEXT_HEALTH_INSTITUTIONS,
    TEXT_FIVE_H,
    TEXT_CHURCHES,
    TEXT_BUILDING_PERMIT,
    TEXT_RAILWAY_PROPERTIES,
    TEXT_SEVEN_D,
    TEXT_SECTION_28,
    TEXT_FIVE_J,
    TEXT_FIVE_K,
    TEXT_APPLIED,
    TEXT_COMPLETION,
    TEXT_TEMPORARY_USE_PERMIT,
    TEXT_PERMISSION_TO_START,
    TEXT_BUILDING_NUMBER_EXPIRED,
    TEXT_NOTIFICATION_ACTION_COMPLETED,
    TEXT_BUILDING_DEMOLISHED,
    TEXT_NOTIFICATION_CASE_MEASURES,
    TEXT_BUILDING_CANCELED,
    TEXT_BUILDING_APPROVED_FOR_DEMOLITION,
    TEXT_OUTLINE_PLANNING_PERMISSION,
    TEXT_EXEMPT_TO_APPLY,
    TEXT_BUILDING_MOVED,
    TEXT_MEASURES_EXEMPT,
    TEXT_ACTIVE_CASE,
    TEXT_ASSIGNED,
    TEXT_PICKED,
    TEXT_NO,
    TEXT_DECISION,
    TEXT_NOTIFICATION_SENT,
    TEXT_PLANNED,
    TEXT_STARTED,
    TEXT_FINISHED_TAXATION,
    TEXT_READY_FOR_TRIBUNAL,
    TEXT_APPROVED_BY_TRIBUNAL,
    TEXT_REJECTED_BY_THE_TRIBUNAL,
    TEXT_COMPLAINT_RECEIVED,
    TEXT_METRIC_CHANGE,
    TEXT_MATRIKKEL_CHANGED,
} from 'utils/translations/keys'
import { ExemptionByReason, BuildingStatus } from 'common/enums/filter-values'
import { DateTime } from 'luxon'
import { find } from 'lodash'
import { formatCurrency } from 'utils'
import { Property, PropertyStatus, PropertyType, PropertyValueType } from 'models/property'
import { getOfficeAdjustment, getReductionFactor, getUseNationalValuation } from 'models/year-settings'

export const regulatedPlotMultipliersByType = {
    [PropertyValueType.BO]: [300, 50],
    [PropertyValueType.FR]: [150, 50],
    [PropertyValueType.GB]: [300, 0],
    [PropertyValueType.GF]: [150, 0],
    [PropertyValueType.GU]: [0, 0],
    [PropertyValueType.IV]: [0, 0],
    [PropertyValueType.KO]: [300, 50],
    [PropertyValueType.NE]: [300, 50],
    [PropertyValueType.UB]: [50, 50],
    [PropertyValueType.UF]: [50, 50],
    [PropertyValueType.UN]: [50, 50],
    [PropertyValueType.UT]: [1, 1],
    null: [0, 0],
}

export const nonRegulatedPlotMultipliersByType = {
    [PropertyValueType.BO]: [300, 1],
    [PropertyValueType.FR]: [150, 1],
    [PropertyValueType.GB]: [300, 0],
    [PropertyValueType.GF]: [150, 0],
    [PropertyValueType.GU]: [0, 0],
    [PropertyValueType.IV]: [0, 0],
    [PropertyValueType.KO]: [300, 1],
    [PropertyValueType.NE]: [300, 1],
    [PropertyValueType.UB]: [1, 1],
    [PropertyValueType.UF]: [1, 1],
    [PropertyValueType.UN]: [1, 1],
    [PropertyValueType.UT]: [1, 1],
    null: [0, 0],
}

export const getPlotSize = (property: Property) => {
    return property.plots ? property.plots.reduce((plotSize, plot) => plotSize + Math.round(plot.area), 0) : 0
}

export const getUnder1000Multiplier = (property: Property) => {
    const plotMultiplier = property.regulated ? regulatedPlotMultipliersByType : nonRegulatedPlotMultipliersByType
    const valueType: PropertyValueType = property.valueType
    if (!valueType) {
        return 0
    }
    return plotMultiplier[valueType][0]
}

export const getOver1000Multiplier = (property: Property) => {
    const plotMultiplier = property.regulated ? regulatedPlotMultipliersByType : nonRegulatedPlotMultipliersByType
    const valueType: PropertyValueType = property.valueType
    if (!valueType) {
        return 0
    }
    return plotMultiplier[valueType][1]
}

export const getValueUnder1000m = (property: Property) => {
    const plotMultiplier = getUnder1000Multiplier(property)
    const plotSize = getPlotSize(property)
    return plotSize > 1000 ? 1000 * plotMultiplier : plotSize * plotMultiplier
}

export const getValueOver1000m = (property: Property) => {
    const plotMultiplier = getOver1000Multiplier(property)
    const plotSize = getPlotSize(property)
    return plotSize > 1000 ? (plotSize - 1000) * plotMultiplier : 0
}

export const getUnder1000Area = (property: Property) => {
    const plotSize = getPlotSize(property)
    return plotSize > 1000 ? 1000 : plotSize
}

export const getOver1000Area = (property: Property) => {
    const plotSize = getPlotSize(property)
    return plotSize > 1000 ? plotSize - 1000 : 0
}

export const getPropertyValueBeforeFactors = (property: Property, taxSettings: any, allYearSettings: any) => {
    const propValue = getFinalPlotValue(property, taxSettings, allYearSettings)
    return propValue + property.buildingsValue
}

export const getPropertyValueAfterFactors = (property: Property, taxSettings: any, allYearSettings: any) => {
    const nationalValuation = getNationalValuation(property)
    if (nationalValuation !== null && getUseNationalValuation(allYearSettings)) {
        return nationalValuation
    }
    const zoneFactor = property.zoneFactor || 1
    const locationFactor = property.locationFactor || 1
    const reductionFactor = getReductionFactor(allYearSettings)
    const reductionMultiplier = reductionFactor ? 1 - reductionFactor / 100 : 1
    const propValue = getPropertyValueBeforeFactors(property, taxSettings, allYearSettings)
    return propValue * reductionMultiplier * locationFactor * zoneFactor
}

export const getPropertyValueTypeDescription = (type: string) => {
    const types: { [key: string]: string } = {
        [PropertyValueType.BO]: tr(TEXT_RESIDENTIAL_PROPERTY),
        [PropertyValueType.FR]: tr(TEXT_LEISURE_PROPERTY),
        [PropertyValueType.GB]: tr(TEXT_FARMING_FORESTRY_WITH_HOUSING),
        [PropertyValueType.GF]: tr(TEXT_FARM_USE_FORESTRY_WITH_LEISURE),
        [PropertyValueType.GU]: tr(TEXT_FARMS_OR_FORESTRY),
        [PropertyValueType.IV]: tr(TEXT_NOT_SELECTED),
        [PropertyValueType.KO]: tr(TEXT_COMBINED_BUILT_PLOT),
        [PropertyValueType.NE]: tr(TEXT_COMMERCIAL),
        [PropertyValueType.UB]: tr(TEXT_UNDEVELOPED_PLOT_HOUSING),
        [PropertyValueType.UF]: tr(TEXT_UNDEVELOPED_PLOT_LEISURE),
        [PropertyValueType.UN]: tr(TEXT_UNDEVELOPED_PLOT_INDUSTRY),
        [PropertyValueType.UT]: tr(TEXT_UNDEVELOPED_PLOT),
    }
    return types[type]
}

export const getPropertyValueTypeOptions = () => [
    { label: tr(TEXT_RESIDENTIAL_PROPERTY), value: PropertyValueType.BO },
    { label: tr(TEXT_LEISURE_PROPERTY), value: PropertyValueType.FR },
    { label: tr(TEXT_FARMING_FORESTRY_WITH_HOUSING), value: PropertyValueType.GB },
    { label: tr(TEXT_FARM_USE_FORESTRY_WITH_LEISURE), value: PropertyValueType.GF },
    { label: tr(TEXT_FARMS_OR_FORESTRY), value: PropertyValueType.GU },
    { label: tr(TEXT_NOT_SELECTED), value: PropertyValueType.IV },
    { label: tr(TEXT_COMBINED_BUILT_PLOT), value: PropertyValueType.KO },
    { label: tr(TEXT_COMMERCIAL), value: PropertyValueType.NE },
    { label: tr(TEXT_UNDEVELOPED_PLOT_HOUSING), value: PropertyValueType.UB },
    { label: tr(TEXT_UNDEVELOPED_PLOT_LEISURE), value: PropertyValueType.UF },
    { label: tr(TEXT_UNDEVELOPED_PLOT_INDUSTRY), value: PropertyValueType.UN },
    { label: tr(TEXT_UNDEVELOPED_PLOT), value: PropertyValueType.UT },
]

export const getPropertyRoutesOptions = () => {
    const counter = Array.from({ length: 18 }, (_, i) => i + 1)
    return counter.map((index: number) => {
        return {
            label: index.toString(),
            value: index,
        }
    })
}

export const getExemptReasonOptions = () =>
    [
        { label: tr(TEXT_DECISION), value: ExemptionByReason.decision },
        { label: tr(TEXT_FARM_AND_FORESTRY), value: ExemptionByReason.farmAndForestry },
        { label: tr(TEXT_BUILDING_7C), value: ExemptionByReason.building },
        { label: tr(TEXT_HISTORIC_VALUE), value: ExemptionByReason.historicValue },
        { label: tr(TEXT_MUNICIPAL_PROPERTY), value: ExemptionByReason.municipalProperty },
        { label: tr(TEXT_STATE_PROPERTY), value: ExemptionByReason.stateProperty },
        { label: tr(TEXT_INSTITUTIONS), value: ExemptionByReason.institutions },
        { label: tr(TEXT_HEALTH_INSTITUTIONS), value: ExemptionByReason.healthInstitutions },
        { label: tr(TEXT_FIVE_H), value: ExemptionByReason.fiveH },
        { label: tr(TEXT_CHURCHES), value: ExemptionByReason.churches },
        { label: tr(TEXT_BUILDING_PERMIT), value: ExemptionByReason.buildingPermit },
        { label: tr(TEXT_RAILWAY_PROPERTIES), value: ExemptionByReason.railwayProperties },
        { label: tr(TEXT_SEVEN_D), value: ExemptionByReason.sevenD },
        { label: tr(TEXT_SECTION_28), value: ExemptionByReason.section28 },
        { label: tr(TEXT_FIVE_J), value: ExemptionByReason.fiveJ },
        { label: tr(TEXT_FIVE_K), value: ExemptionByReason.fiveK },
    ].sort((a, b) => a.label.localeCompare(b.label, 'nb'))

export const getBuildingStatusOptions = () => [
    { label: tr(TEXT_APPLIED), value: BuildingStatus.applied },
    { label: tr(TEXT_COMPLETION), value: BuildingStatus.completion },
    { label: tr(TEXT_TEMPORARY_USE_PERMIT), value: BuildingStatus.temporaryUsePermit },
    { label: tr(TEXT_PERMISSION_TO_START), value: BuildingStatus.permissionToStart },
    { label: tr(TEXT_BUILDING_NUMBER_EXPIRED), value: BuildingStatus.buildingNumberExpired },
    { label: tr(TEXT_NOTIFICATION_ACTION_COMPLETED), value: BuildingStatus.notificationActionCompleted },
    { label: tr(TEXT_BUILDING_DEMOLISHED), value: BuildingStatus.buildingDemolished },
    { label: tr(TEXT_NOTIFICATION_CASE_MEASURES), value: BuildingStatus.notificationCaseMeasures },
    { label: tr(TEXT_BUILDING_CANCELED), value: BuildingStatus.buildingCanceled },
    { label: tr(TEXT_BUILDING_APPROVED_FOR_DEMOLITION), value: BuildingStatus.buildingApprovedForDemolition },
    { label: tr(TEXT_OUTLINE_PLANNING_PERMISSION), value: BuildingStatus.outlinePlanningPermission },
    { label: tr(TEXT_EXEMPT_TO_APPLY), value: BuildingStatus.exemptToApply },
    { label: tr(TEXT_BUILDING_MOVED), value: BuildingStatus.buildingMoved },
    { label: tr(TEXT_MEASURES_EXEMPT), value: BuildingStatus.measuresExempt },
]

export const getStatusText = (property: Property): string => {
    const status = property.state?.value ?? ''
    let statusTexts = [getStatusTextFromValue(status)]
    
    if (property.otherPropertyFlags?.detailedLetterSent && !statusTexts.includes(tr(TEXT_NOTIFICATION_SENT))) {
        statusTexts.push(tr(TEXT_NOTIFICATION_SENT))
    }

    if (property.otherPropertyFlags?.matrikkelChanged) {
        statusTexts.push(tr(TEXT_MATRIKKEL_CHANGED))
    }
    if (statusTexts.length === 1) {
        return statusTexts[0]
    }
    return statusTexts.filter(s => !!s).join(', ')
}

const getStatusTextFromValue = (stateValue: string) => {
    switch (stateValue) {
        case PropertyStatus.ACTIVE:
            return tr(TEXT_ACTIVE_CASE)
        case PropertyStatus.PICKED:
            return tr(TEXT_PICKED)
        case PropertyStatus.ASSIGNED_COMPANY:
        case PropertyStatus.ASSIGNED_USER:
            return tr(TEXT_ASSIGNED)
        case PropertyStatus.PLANNED:
            return tr(TEXT_PLANNED)
        case PropertyStatus.STARTED:
            return tr(TEXT_STARTED)
        case PropertyStatus.TAXATION_FINISHED:
            return tr(TEXT_FINISHED_TAXATION)
        case PropertyStatus.TRIBUNAL_PLANNED:
            return tr(TEXT_READY_FOR_TRIBUNAL)
        case PropertyStatus.TRIBUNAL_FINISHED:
            return tr(TEXT_APPROVED_BY_TRIBUNAL)
        case PropertyStatus.TRIBUNAL_DECLINED:
            return tr(TEXT_REJECTED_BY_THE_TRIBUNAL)
        case PropertyStatus.TAXATION_COMPLAINED:
            return tr(TEXT_COMPLAINT_RECEIVED)
        case PropertyStatus.METRIC_CHANGE:
            return tr(TEXT_METRIC_CHANGE)
        case PropertyStatus.NORMAL:
            return ''
        default:
            return ''
    }
}

export const getStatusDate = (property: Property, language: any) => {
    if (!property.state) {
        return ''
    }
    let statusDate = property.state && property.state.updated
    if (statusDate) {
        if (new Date(statusDate).getFullYear() !== new Date().getFullYear()) {
            return ''
        }
    }
    return statusDate
        ? DateTime.fromJSDate(new Date(statusDate)).setLocale(language).toLocaleString(DateTime.DATE_SHORT)
        : ''
}

export const getLeaseMultiplier = (property: Property, settings: any) => {
    const isLease = property.type === PropertyType.Lease
    return settings && settings.attachmentPlotValue && isLease ? Math.round(settings.attachmentPlotValue / 10) / 10 : 1
}

export const getLeaseCalculation = (property: Property, settings: any) => {
    return getLeaseMultiplier(property, settings) !== 1
        ? `${formatCurrency(property.plotValue)} x ${getLeaseMultiplier(property, settings)}`
        : ''
}

export const getLeasePlotValue = (property: Property, settings: any) => {
    const multiplier = getLeaseMultiplier(property, settings)
    return multiplier === 1 ? tr(TEXT_NO) : formatCurrency(property.plotValue * multiplier)
}

export const getFinalPlotValue = (property: Property, settings: any, yearSettings: any) => {
    const adjustment = getOfficeAdjustment(yearSettings)
    const multiplier = 1 + adjustment / 100
    return property.plotValue * getLeaseMultiplier(property, settings) * multiplier
}

export const isDocumentReceiver = (property: Property, ownerId: string) => {
    const ownership = find(property.ownerShips, (ownership: any) => ownership.ownerId === ownerId)
    return ownership && ownership.isDocumentRecipient
}

export const getExemptText = (str: string): string => (str.startsWith('§') ? str.substring(0, 4) : str)

export const getMatrikkelAddress = (property: Property) => {
    let address = `${property.holdingNumber}/${property.subHoldingNumber}`
    if (property.leaseNumber || property.sectionNumber) {
        address += `/${property.leaseNumber}`
    }
    if (property.sectionNumber) {
        address += `/${property.sectionNumber}`
    }
    return address
}

export const getNationalValuation = (property: Property) => {
    const value = property.otherPropertyFlags?.nationalValuation
    // eslint-disable-next-line
    if (value && value != 0) {
        return value
    }
    return null
}
