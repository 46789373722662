import React from 'react'
import { makeStyles, Theme, Grid } from '@material-ui/core'
import 'react-lightbox-component/build/css/index.css'
import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import { ComplaintFiles } from './partials/complaint-files'
import { getBuildingTableHeadItem } from './helper-functions'
import { CommentDescription } from './partials/comment-description'
import { CauseType } from './partials/cause-type'
import { PlotAreaComplaint } from './partials/complaint-plot-area'
import { BuildingDetails } from './partials/building-details'
import { PlotTypes } from './partials/plot-types'
import { BuildingAreaComplaint } from './partials/complaint-building-area'
import { BuildingPhotosComplaint } from './partials/complaint-building-photos'
import { ComplaintPanel } from './partials/complaint-panel'
import { LocationFactors } from './partials/property-location-details'
import { Taxation } from 'models/taxation'
import { Styles } from '@material-ui/core/styles/withStyles'

const makeTableCellsStyles: Styles<Theme, never> = (theme: any) => {
    let cells: any = {};
    for (let index = 0; index < 7; index++) {
        cells[`tableCell${index}`] =
            {
                [theme.breakpoints.down('sm')]: {
                    '&:before': {
                        content: `"${getBuildingTableHeadItem(index)}"`,
                    },
                },
            }
    }
    return cells
}
const useStyles = makeStyles((theme: Theme) => {
    return {
        divider: {
            width: '100%',
        },
        innerDivider: {
            width: '100%',
            margin: theme.spacing(1, -2)
        },
        panelBox: {
            padding: theme.spacing(3),
        },
        panelContent__label: {
            fontWeight: 500,
            padding: theme.spacing(1),
        },
        panelContent_value: {
            padding: theme.spacing(1),
        },
        boxedText: {
            backgroundColor: '#ffffff',
            border: '2px solid rgba(0, 0, 0, 0.12)',
            padding: '17px 5px 17px 24px !important',
            minHeight: theme.spacing(10),
            '& .MuiInputBase-multiline:before': {
                display: 'none'
            },
            '& .MuiInputBase-multiline:after': {
                display: 'none'
            }
        },
        nonPanelContent__label: {
            width: '100%',
            fontWeight: 500,
            padding: theme.spacing(2, 3),
        },
        spacingBottomNone: {
            paddingBottom: '0 !important',
        },
        innerContainer: {
            padding: theme.spacing(1),
        },
        fileBox: {
            color: 'inherit',
            padding: theme.spacing(1),
            display: 'block',
            height: '100%',
            lineHeight: '24px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        fileName: {
            marginLeft: theme.spacing(2),
        },
        icon: {
            color: 'inherit',
            fontSize: theme.spacing(4),
        },
        buildingFloorsTable: {
            padding: theme.spacing(2, 0),
        },
        tableHead: {
            fontWeight: 500,
            padding: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        tableRow: {
            padding: theme.spacing(1),
            alignItems: 'center',
        },
        tableCell: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                alignItems: 'center',
                '&:before': {
                    width: '50%',
                    content: '""',
                    display: 'inline-block',
                },
            },
        },
        ...makeTableCellsStyles(theme),
        bold: {
            fontWeight: 500,
        },
        textInput: {
            border: 'none',
            borderBottom: '1px dashed rgba(0, 0, 0, 0.12)',
            width: '100%',
            outline: 'none',
        },
        differenceCalculations: {
            padding: theme.spacing(1),
        },
        dashedInput: {
            maxWidth: '150px',
        },
        textAlignRight: {
            textAlign: 'right',
            color: 'red',
        },
        underline: {
            '&:before': {
                borderBottomStyle: 'dashed',
                marginLeft: '-24px',
            },
            '&:after': {
                marginLeft: '-24px',
            },
            [theme.breakpoints.down('sm')]: {
                '&:before': {
                    marginLeft: 0,
                },
                '&:after': {
                    marginLeft: 0,
                },
            },
        },
        Accordion: {
            marginBottom: 10,
            borderRadius: 4,
            width: '100%',
            '&:before': {
                display: 'none',
            },
        },
        AccordionContent: {
            flexWrap: 'wrap',
            padding: 0,
        },
        AccordionSummary: {
            fontSize: theme.spacing(2),
            fontWeight: 500,
            paddingRight: 8,
            '& .MuiIconButton-edgeEnd': {
                marginRight: 0,
            },
            '&.Mui-expanded': {
                minHeight: 50,
            },
            //for the inner element with the same class
            '& .Mui-expanded': {
                margin: 0,
            },
        },
    }
})

type ComplaintContentPropsType = {
    complaint: any
    taxation: Taxation
    complaintIndex: number
}

export const ComplaintContent = ({ complaint, taxation, complaintIndex }: ComplaintContentPropsType) => {
    const styles = useStyles()
    return (
        <>
            {complaint.causes &&
                complaint.causes.map((cause: any, index: number) => {
                    switch (cause.type) {
                        case ComplaintCauseType.PlotArea:
                            return <PlotAreaComplaint styles={styles} cause={cause} taxation={taxation} key={index} complaint={complaint} complaintIndex={complaintIndex} index={index}/>
                        case ComplaintCauseType.PlotType:
                            return (
                                <ComplaintPanel styles={styles} cause={cause} taxation={taxation} key={index} complaint={complaint} complaintIndex={complaintIndex} index={index}>
                                    <Grid container spacing={2} className={styles.panelBox}>
                                        <CauseType causeType={cause.type} styles={styles} />
                                        <PlotTypes
                                            typeCode={cause.correctValueType}
                                            styles={styles}
                                            taxation={taxation}
                                        />
                                        <CommentDescription comment={cause.comment} styles={styles} />
                                        <ComplaintFiles cause={cause} styles={styles} />
                                    </Grid>
                                </ComplaintPanel>
                            )
                        case ComplaintCauseType.PlotLocation:
                            return (
                                <ComplaintPanel styles={styles} cause={cause} taxation={taxation} key={index} complaint={complaint} complaintIndex={complaintIndex} index={index}>
                                    <Grid container spacing={2} className={styles.panelBox}>
                                        <CauseType causeType={cause.type} styles={styles} />
                                        <LocationFactors styles={styles} property={taxation.current.property} />
                                        <CommentDescription comment={cause.comment} styles={styles} />
                                        <ComplaintFiles cause={cause} styles={styles} />
                                    </Grid>
                                </ComplaintPanel>
                            )
                        case ComplaintCauseType.BuildingStandard:
                            return (
                                <ComplaintPanel styles={styles} cause={cause} taxation={taxation} key={index} complaint={complaint} complaintIndex={complaintIndex} index={index}>
                                    <Grid container spacing={2} className={styles.panelBox}>
                                        <CauseType causeType={cause.type} styles={styles} />
                                        <CommentDescription comment={cause.comment} styles={styles} />
                                        <BuildingDetails
                                            buildings={cause.buildings}
                                            styles={styles}
                                            taxation={taxation}
                                        />
                                        <ComplaintFiles cause={cause} styles={styles} />
                                    </Grid>
                                </ComplaintPanel>
                            )
                        case ComplaintCauseType.BuildingArea:
                            return (
                                <BuildingAreaComplaint styles={styles} cause={cause} taxation={taxation} key={index} complaint={complaint} complaintIndex={complaintIndex} index={index}/>
                            )
                        case ComplaintCauseType.BuildingPhotos:
                            return (
                                <BuildingPhotosComplaint styles={styles} cause={cause} taxation={taxation} key={index} complaint={complaint} complaintIndex={complaintIndex} index={index}/>
                            )
                        case ComplaintCauseType.Other:
                            return (
                                <ComplaintPanel styles={styles} cause={cause} taxation={taxation} key={index} complaint={complaint} complaintIndex={complaintIndex} index={index}>
                                    <Grid container spacing={2} className={styles.panelBox}>
                                        <CauseType causeType={cause.type} styles={styles} />
                                        <CommentDescription comment={cause.comment} styles={styles} />
                                        <ComplaintFiles cause={cause} styles={styles} />
                                    </Grid>
                                </ComplaintPanel>
                            )
                        default:
                            console.log('Unhandled case occured')
                            return false
                    }
                })}
        </>
    )
}
