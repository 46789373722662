import React from 'react'

import { Field, formValueSelector } from 'redux-form'
import { Grid } from '@material-ui/core'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { CubitCheckboxAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import { ValueContainer } from 'common/value-container/value-container'
import { isTaxationEditingFinished, Taxation } from 'models/taxation'
import { useSelector } from 'react-redux'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { orderBy } from 'lodash'
import { AppState } from 'app/app-store'

type Factor = {
    name: string
    factor: string
}

const defaultFactors: any[] = [
    {
        label: '-',
        value: 1,
    },
]
const defaultNameFactors: any[] = [
    {
        label: '-',
        value: '',
    },
]

const getLocationFactorClassicOptions = (settings: any): any => {
    const locationFactors =
        settings && settings.locationFactors ? orderBy(settings.locationFactors, 'factor', 'desc') : []

    return defaultFactors.concat(
        locationFactors.map((factor: Factor) => ({
            label: `${factor.factor} ${factor.name}`,
            value: factor.factor,
        })),
    )
}

const getLocationFactorNameOptions = (settings: any): any => {
    const locationFactors =
        settings && settings.locationFactors ? orderBy(settings.locationFactors, 'from', 'desc') : []

    return defaultNameFactors.concat(
        locationFactors.map((factor: Factor) => ({
            label: factor.name,
            value: factor.name,
        })),
    )
}

const getLocationFactorFromToOptions = (settings: any, factorName: string): any => {
    const locationFactor = settings?.locationFactors?.find((f: any) => f.name === factorName) || {}
    let options: any = []
    for (let index = locationFactor.from; index <= locationFactor.to; index += 0.05) {
        options.push(index)
    }
    return defaultFactors.concat(
        options.map((factor: number) => ({
            label: factor.toFixed(2),
            value: factor,
        })),
    )
}

type TaxationPropertyLocationFactorsProps = {
    taxation: Taxation
    editable: boolean
    formName: string
    verifyable?: boolean
}

export const TaxationPropertyLocationFactors: React.FC<TaxationPropertyLocationFactorsProps> = (props) => {
    const { taxation, editable, verifyable = false } = props
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
    const locationFactorsFromTo = settings?.locationFactorsFromTo
    const selector = formValueSelector(`taxation-${taxation.id}`)
    const stateRef = useSelector((state: AppState) => state)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                {!locationFactorsFromTo && (
                    <Grid item lg={3} sm={6} xs={9}>
                        <Field
                            options={getLocationFactorClassicOptions(settings)}
                            defaultValue=""
                            label={tr(t.TEXT_LOCATION_FACTOR)}
                            name="current.property.locationFactor"
                            component={CubitSelectAdapter}
                            valueIsObject={false}
                            disabled={!editable}
                        />
                    </Grid>
                )}
                {locationFactorsFromTo && (
                    <>
                        <Grid item lg={3} sm={6} xs={9}>
                            <Field
                                options={getLocationFactorNameOptions(settings)}
                                defaultValue=""
                                label={tr(t.TEXT_LOCATION_FACTOR)}
                                name="current.property.locationFactorName"
                                component={CubitSelectAdapter}
                                valueIsObject={false}
                                disabled={!editable}
                            />
                        </Grid>

                        <Grid item lg={3} sm={6} xs={9}>
                            <Field
                                options={getLocationFactorFromToOptions(
                                    settings,
                                    getFieldValue('current.property.locationFactorName'),
                                )}
                                label={tr(t.TEXT_FACTOR)}
                                name="current.property.locationFactor"
                                component={CubitSelectAdapter}
                                valueIsObject={false}
                                disabled={!editable}
                            />
                        </Grid>
                    </>
                )}
                {verifyable && (
                    <Grid item xs>
                        <ValueContainer
                            label={tr(t.TEXT_VERIFIED)}
                            value={
                                <Field
                                    name="current.property.verifiedValues.locationFactor"
                                    component={CubitCheckboxAdapter}
                                    disabled={isTaxationEditingFinished(taxation)}
                                />
                            }
                        ></ValueContainer>
                    </Grid>
                )}
            </Grid>
        </>
    )
}
