import React from 'react'
import { Divider, Grid, IconButton, Typography } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_TAXATION_INFORMATION,
    TEXT_LAND_VALUE,
    TEXT_LAST_TAXATION,
    TEXT_NEW_TAXATION,
    TEXT_PROPERTY_VALUE,
    TEXT_ZONE_FACTOR,
    TEXT_LOCATION_FACTOR,
    TEXT_MANUAL_VALUE,
    TEXT_COMBINED,
    TEXT_USES_NATIONAL_SETTINGS,
} from 'utils/translations/keys'
import { Taxation } from 'models/taxation'
import { Building } from 'models/building'
import { uniqBy, filter } from 'lodash'
import { formatCurrency, getPropertyLocationFactorTranslationKey } from 'utils'
import './tribunal-taxation-details.css'
import { useDispatch, useSelector } from 'react-redux'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { actionSetPreviewPdf } from 'app/app-actions'
import { Info } from '@material-ui/icons'
import { simpleFormatNumber } from 'shared-components/src/utils/format/format-number'
import { getAdjustedFactorsMultiplier, isBuildingExempt } from 'property-page/property-helper'

export const TribunalTaxationDetailsSummary: React.FC<{
    taxation: Taxation
}> = props => {
    const { taxation } = props
    const taxationSettings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
    const dispatch = useDispatch()
    const allBuildings = uniqBy([...taxation.current.buildings, ...taxation.old.buildings], 'id')

    const getBuildingType = (buildingId: string): string => {
        const housingUnit = filter(taxation.old.housingUnits, h => h.buildingId === buildingId)[0]
        return housingUnit ? housingUnit.type : ''
    }

    const getOldBuildingValue = (buildingId: string): number => {
        const building = filter(taxation.old.buildings, b => b.id === buildingId)[0]
        return building ? building.value : 0
    }

    const getNewBuildingValue = (buildingId: string): number => {
        const building = filter(taxation.current.buildings, (b) => b.id === buildingId)[0]
        if (isBuildingExempt(building, taxation.staticSettings)) {
            return 0
        }
        const buildingValue = building?.value * getAdjustedFactorsMultiplier(taxation)
        return building && building.id in taxation.deletedBuildings ? 0 : buildingValue
    }

    const prop = taxation.current.property
    const totalValue =
        taxation.calculationDetails.dwellingLandValue +
        taxation.calculationDetails.dwellingBuildingsValue +
        taxation.calculationDetails.otherLandValue +
        taxation.calculationDetails.otherBuildingsValue

    return (
        <div className="tribunalTaxationDetailsContent">
            <Grid container alignItems="center" style={{ marginBottom: '8px' }}>
                <Grid item xs={8}>
                    <Typography variant="h6">{tr(TEXT_TAXATION_INFORMATION)}</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {tr(TEXT_LAST_TAXATION)}
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {tr(TEXT_NEW_TAXATION)}
                </Grid>
            </Grid>

            <Grid container alignItems="center">
                <Grid item xs={8}>
                    {tr(TEXT_LAND_VALUE)}
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {formatCurrency(taxation.old.property.plotValue)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {formatCurrency(taxation.current.property.plotValue)}
                    </Typography>
                </Grid>
            </Grid>

            {allBuildings.map((building: Building, index: number) => (
                <Grid container alignItems="center" key={index}>
                    <Grid item xs={2}>
                        {building.buildingNumber}
                    </Grid>
                    <Grid item xs={6}>
                        {building.buildingCode}, {getBuildingType(building.id)}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" align="right">
                            {formatCurrency(getOldBuildingValue(building.id))}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" align="right">
                            {formatCurrency(getNewBuildingValue(building.id))}
                        </Typography>
                    </Grid>
                </Grid>
            ))}

            <Divider className="divider-2"></Divider>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                    <Typography variant="h6">{tr(TEXT_ZONE_FACTOR)}</Typography>
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {simpleFormatNumber(taxation.old.property.zoneFactor)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {simpleFormatNumber(taxation.current.property.zoneFactor)}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container alignItems="center">
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                    <Typography variant="h6"> {tr(TEXT_LOCATION_FACTOR)}</Typography>
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {simpleFormatNumber(taxation.old.property.locationFactor)}{' '}
                        {getPropertyLocationFactorTranslationKey(taxation.old.property.locationFactor, taxationSettings.locationFactors)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {simpleFormatNumber(taxation.current.property.locationFactor)}{' '}
                        {getPropertyLocationFactorTranslationKey(taxation.current.property.locationFactor, taxationSettings.locationFactors)}
                    </Typography>
                </Grid>
            </Grid>

            <Divider className="divider-2"></Divider>

            <Grid container alignItems="center">
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                    <Typography variant="h6">{tr(TEXT_PROPERTY_VALUE)}</Typography>
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {formatCurrency(taxation.old.property.totalValue)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {formatCurrency(totalValue)}
                    </Typography>
                </Grid>
            </Grid>

            {prop.otherPropertyFlags?.manualKoValue && <Grid container alignItems="center">
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                    <Typography variant="h6">{tr(TEXT_USES_NATIONAL_SETTINGS)}</Typography>
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {formatCurrency(taxation.old.property.otherPropertyFlags?.nationalValuation ?? 0)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Grid item>{formatCurrency(prop.otherPropertyFlags?.nationalValuation ?? 0)}</Grid>
                        </Grid>
                    </Typography>
                </Grid>
            </Grid>}

            {(prop.otherPropertyFlags?.manualValue || prop.otherPropertyFlags?.manualKoValue) && <Grid container alignItems="center">
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                    <Typography variant="h6">{tr(TEXT_MANUAL_VALUE)}</Typography>
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        {formatCurrency(taxation.old.property.otherPropertyFlags?.manualValue ?? 0)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" align="right">
                        <Grid container justifyContent="flex-end" alignItems="center">
                            {prop.otherPropertyFlags?.manualValueDocument?.url &&
                                <Grid item>
                                    <IconButton onClick={() => dispatch(actionSetPreviewPdf(undefined, undefined, prop.otherPropertyFlags.manualValueDocument.url))}><Info /></IconButton>
                                </Grid>}
                            <Grid item>{formatCurrency(prop.otherPropertyFlags?.manualValue ?? 0)}</Grid>
                        </Grid>
                    </Typography>
                </Grid>
                {prop.otherPropertyFlags?.manualKoValue && <>
                    <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                        <Typography variant="h6">{tr(TEXT_COMBINED)}</Typography>
                    </Grid>

                    <Grid item xs={6}></Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" align="right">
                            {formatCurrency(taxation.old.property.otherPropertyFlags?.manualKoValue ?? 0)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" align="right">
                            <Grid container justifyContent="flex-end" alignItems="center">
                                {prop.otherPropertyFlags?.manualValueDocument?.url &&
                                    <Grid item>
                                        <IconButton onClick={() => dispatch(actionSetPreviewPdf(undefined, undefined, prop.otherPropertyFlags.manualValueDocument.url))}><Info /></IconButton>
                                    </Grid>}
                                <Grid item>{formatCurrency(prop.otherPropertyFlags?.manualKoValue ?? 0)}</Grid>
                            </Grid>
                        </Typography>
                    </Grid>
                </>}
                <Grid item xs={12}>
                    <Typography variant="h6" align="right">
                        {prop.otherPropertyFlags.manualValueComment ?? ''}
                    </Typography>
                </Grid>
            </Grid>}

        </div>
    )
}
