import { AnyAction } from 'redux'
import { AttributesKey } from './attribute'

export const ACTION_GET_ATTRIBUTES = 'ACTION_GET_ATTRIBUTES'
export const ACTION_GET_ATTRIBUTES_SUCCESS = 'ACTION_GET_ATTRIBUTES_SUCCESS'
export const ACTION_CREATE_ATTRIBUTE = 'ACTION_CREATE_ATTRIBUTE'
export const ACTION_CREATE_ATTRIBUTE_SUCCESS = 'ACTION_CREATE_ATTRIBUTE_SUCCESS'
export const ACTION_DELETE_ATTRIBUTE = 'ACTION_DELETE_ATTRIBUTE'
export const ACTION_DELETE_ATTRIBUTE_SUCCESS = 'ACTION_DELETE_ATTRIBUTE_SUCCESS'
export const ACTION_ATTACH_ATTRIBUTES = 'ACTION_ATTACH_ATTRIBUTES'
export const ACTION_ATTACH_ATTRIBUTES_SUCCESS = 'ACTION_ATTACH_ATTRIBUTES_SUCCESS'
export const ACTION_GET_ENTITY_ATTRIBUTES = 'ACTION_GET_ENTITY_ATTRIBUTES'
export const ACTION_GET_ENTITY_ATTRIBUTES_SUCCESS = 'ACTION_GET_ENTITY_ATTRIBUTES_SUCCESS'

export const actionGetAttributes = (attType: AttributesKey): AnyAction => {
    return {
        type: ACTION_GET_ATTRIBUTES,
        attType,
    }
}

export const actionGetAttributesSuccess = (attType: AttributesKey, attributes: string[]): AnyAction => {
    return {
        type: ACTION_GET_ATTRIBUTES_SUCCESS,
        attType,
        attributes,
    }
}

export const actionCreateAttribute = (attType: AttributesKey, attribute: string): AnyAction => {
    return {
        type: ACTION_CREATE_ATTRIBUTE,
        attType,
        attribute,
    }
}

export const actionCreateAttributeSuccess = (attType: AttributesKey, attribute: string): AnyAction => {
    return {
        type: ACTION_CREATE_ATTRIBUTE_SUCCESS,
        attribute,
        attType,
    }
}

export const actionDeleteAttribute = (attType: AttributesKey, attribute: string): AnyAction => {
    return {
        type: ACTION_DELETE_ATTRIBUTE,
        attType,
        attribute,
    }
}

export const actionDeleteAttributeSuccess = (attType: AttributesKey, attribute: string): AnyAction => {
    return {
        type: ACTION_DELETE_ATTRIBUTE_SUCCESS,
        attType,
        attribute,
    }
}

export const actionAttachAttributes = (attributes: string[], oldAttributes: string[], attType: AttributesKey, entityId: string, propertyId: string, afterEdit: any): AnyAction => {
    return {
        type: ACTION_ATTACH_ATTRIBUTES,
        attributes,
        oldAttributes,
        attType,
        entityId,
        propertyId,
        afterEdit
    }
}

export const actionAttachAttributesSuccess = (
    attributes: string[],
    attType: string,
    entityId: string,
    propertyId: string
): AnyAction => {
    return {
        type: ACTION_ATTACH_ATTRIBUTES_SUCCESS,
        attributes,
        attType,
        entityId,
        propertyId
    }
}

export const actionGetEntityAttributes = (entityId: string, onDone: any): AnyAction => {
    return {
        type: ACTION_GET_ENTITY_ATTRIBUTES,
        entityId,
        onDone
    }
}

export const actionGetEntityAttributesSuccess = (entityId: string, attributes: string[]): AnyAction => {
    return {
        type: ACTION_GET_ENTITY_ATTRIBUTES_SUCCESS,
        entityId,
        attributes,
    }
}
