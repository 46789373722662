import React, { useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Grid,
    Button,
    makeStyles,
    Theme,
    IconButton,
    AccordionActions,
    TextField,
} from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_TAXATION_INFORMATION,
    TEXT_FINISH_TAXATION,
    TEXT_LAND_VALUE,
    TEXT_LAST_TAXATION,
    TEXT_NEW_TAXATION,
    TEXT_PROPERTY_VALUE,
    TEXT_ZONE_FACTOR,
    TEXT_LOCATION_FACTOR,
    TEXT_UPLOAD_DOCUMENT,
    TEXT_MANUAL_VALUE,
    TEXT_SAVE,
    TEXT_CANCEL,
    TEXT_VALUE,
    TEXT_COMMENT,
    TEXT_COMBINED,
    TEXT_USES_NATIONAL_SETTINGS,
} from 'utils/translations/keys'
import { Edit, ExpandMore, Info } from '@material-ui/icons'
import { VerifiedCount } from 'common/verified-count/verified-count'
import { useDispatch } from 'react-redux'
import { isCombinedProperty, isTaxationEditingFinished, Taxation } from 'models/taxation'
import { actionDocumentSave, actionManualClose, actionTaxationFinish } from 'property-taxation-edit-page/taxation-actions'
import { Building } from 'models/building'
import { uniqBy, filter } from 'lodash'
import { mapCurrentTaxationVerifiedValues, formatCurrency } from 'utils'
import { getAdjustedFactorsMultiplier, isBuildingExempt, untaxableBuildingsFilter } from 'property-page/property-helper'
import { useQuery } from 'utils/hooks/hooks'
import { simpleFormatNumber } from 'shared-components/src/utils/format/format-number'
import { CubitDropZone } from 'common/upload/CubitDropzone'
import { FORM_FILE_TYPES } from 'common/constants/constants'
import { actionSetPreviewPdf } from 'app/app-actions'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'

const useStyles = makeStyles((theme: Theme) => {
    return {
        headerItem: {
            textAlign: 'right',
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'left',
            },
        },
        spacer: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        mobileHeaderItem: {
            textAlign: 'left',
            display: 'none',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        contentItem: {
            textAlign: 'right',
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'left',
            },
        },
        cancelButton: {
            marginRight: theme.spacing(2),
        },
    }
})

type TaxationSummaryProps = {
    taxation: Taxation
    form: string
}

export const TaxationSummary: React.FC<TaxationSummaryProps> = (props) => {
    const { taxation } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const printing = useQuery('printing')
    const prop = taxation.current.property

    const [taxationSummaryPanelExpanded, setTaxationSummaryPanelExpanded] = useState(!!printing)
    const [editing, setEditing] = useState(false)
    const [manualValue, setManualValue] = useState(prop.otherPropertyFlags?.manualValue ?? 0)
    const [manualKoValue, setManualKoValue] = useState(prop.otherPropertyFlags?.manualKoValue ?? 0)
    const [manualValueComment, setManualValueComment] = useState(prop.otherPropertyFlags?.manualValueComment ?? '')

    const manualReady = manualValue !== 0 && (!!prop.otherPropertyFlags.manualValueDocument || manualValueComment)

    const taxationWithVerifiedValues = taxation ? mapCurrentTaxationVerifiedValues(taxation) : null

    const verifiedValuesCount = taxationWithVerifiedValues ? taxationWithVerifiedValues.current.verifiedInfo.count : 0
    const verifiedValuesTotal = taxationWithVerifiedValues ? taxationWithVerifiedValues.current.verifiedInfo.total : 0

    const isFinishDisabled = verifiedValuesTotal === 0 || verifiedValuesCount !== verifiedValuesTotal

    const handleTaxationFinish = (taxationId: string) => {
        dispatch(actionTaxationFinish(taxationId))
    }

    const allBuildings = uniqBy([...taxation.current.buildings, ...taxation.old.buildings], 'id')

    const getBuildingType = (buildingId: string): string => {
        const housingUnit = filter(taxation.old.housingUnits, (h) => h.buildingId === buildingId)[0]
        return housingUnit ? housingUnit.type : ''
    }

    const getOldBuildingValue = (buildingId: string): number => {
        const building = filter(taxation.old.buildings, (b) => b.id === buildingId)[0]
        return building ? building.value : 0
    }

    const getNewBuildingValue = (buildingId: string): number => {
        const building = filter(taxation.current.buildings, (b) => b.id === buildingId)[0]
        if (isBuildingExempt(building, taxation.staticSettings)) {
            return 0
        }
        const buildingValue = building?.value * getAdjustedFactorsMultiplier(taxation)
        return building && building.id in taxation.deletedBuildings ? 0 : buildingValue
    }

    const handleStartEditing = (e: React.MouseEvent) => {
        e.stopPropagation()
        setTaxationSummaryPanelExpanded(true)
        setEditing(true)
    }

    const handleCancelEditing = () => {
        setTaxationSummaryPanelExpanded(false)
        setEditing(false)
    }

    const handleManualDocUpload = (files: any) => {
        dispatch(actionDocumentSave(taxation.id, files[0]))
    }

    const handleManualValueSet = () => {
        dispatch(actionManualClose(taxation.id, manualValue, manualValueComment, manualKoValue))
        setTaxationSummaryPanelExpanded(false)
        setEditing(false)
    }

    const totalValue =
        taxation.calculationDetails.dwellingLandValue +
        taxation.calculationDetails.dwellingBuildingsValue +
        taxation.calculationDetails.otherLandValue +
        taxation.calculationDetails.otherBuildingsValue


    const taxationClosed = isTaxationEditingFinished(taxation)

    return (
        <Accordion expanded={taxationSummaryPanelExpanded}>
            <AccordionSummary
                onClick={() => setTaxationSummaryPanelExpanded(!taxationSummaryPanelExpanded)}
                expandIcon={<ExpandMore />}
                style={{ fontSize: '1.25rem', fontWeight: 500 }}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>{tr(TEXT_TAXATION_INFORMATION)}
                    </Grid>
                    <Grid item>
                        {!taxationClosed && !editing && <IconButton onClick={handleStartEditing} color="inherit">
                            <Edit />
                        </IconButton>}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ flexGrow: 1, fontSize: '16px' }}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item sm={8} xs={12} className={styles.spacer}></Grid>
                        <Grid item sm={2} xs={6} className={styles.headerItem}>
                            {tr(TEXT_LAST_TAXATION)}
                        </Grid>
                        <Grid item sm={2} xs={6} className={styles.headerItem}>
                            {tr(TEXT_NEW_TAXATION)}
                        </Grid>

                        <Grid item sm={8} xs={12}>
                            {tr(TEXT_LAND_VALUE)}
                        </Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {formatCurrency(taxation.old.property.plotValue)}
                        </Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {formatCurrency(taxation.calculationDetails.dwellingLandValue)}
                        </Grid>
                    </Grid>

                    {allBuildings
                        .filter(
                            (b: Building) =>
                                untaxableBuildingsFilter(b, allBuildings, taxation.staticSettings),
                        )
                        .map((building: Building, index: number) => (
                            <Grid container spacing={4} alignItems="center" key={index}>
                                <Grid item sm={2} xs>
                                    {building.buildingNumber}
                                </Grid>
                                <Grid item sm={6} xs={9}>
                                    {building.buildingCode}, {getBuildingType(building.id)}
                                </Grid>
                                <Grid item sm={2} xs={6} className={styles.contentItem}>
                                    {formatCurrency(getOldBuildingValue(building.id))}
                                </Grid>
                                <Grid item sm={2} xs={6} className={styles.contentItem}>
                                    {formatCurrency(getNewBuildingValue(building.id))}
                                </Grid>
                            </Grid>
                        ))}

                    <Divider style={{ margin: '16px -16px' }}></Divider>

                    <Grid container spacing={4} alignItems="center">
                        <Grid item sm={2} xs={12}>
                            {tr(TEXT_ZONE_FACTOR)}
                        </Grid>

                        <Grid item sm={6} className={styles.spacer}></Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {simpleFormatNumber(taxation.old.property.zoneFactor)}
                        </Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {simpleFormatNumber(taxation.current.property.zoneFactor)}
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} alignItems="center">
                        <Grid item sm={2} xs={12}>
                            {tr(TEXT_LOCATION_FACTOR)}
                        </Grid>

                        <Grid item sm={6} className={styles.spacer}></Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {simpleFormatNumber(taxation.old.property.locationFactor)}
                        </Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {simpleFormatNumber(taxation.current.property.locationFactor)}
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} alignItems="center">
                        <Grid item sm={2} xs={12} style={{ fontWeight: 'bold' }}>
                            {tr(TEXT_PROPERTY_VALUE)}
                        </Grid>

                        <Grid item sm={6} className={styles.spacer}></Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {formatCurrency(taxation.old.property.totalValue)}
                        </Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {formatCurrency(totalValue)}
                        </Grid>
                    </Grid>

                    {prop.otherPropertyFlags?.nationalValuation && < Grid container spacing={4} alignItems="center">
                        <Grid item sm={2} xs={12} style={{ fontWeight: 'bold' }}>
                            {tr(TEXT_USES_NATIONAL_SETTINGS)}
                        </Grid>

                        <Grid item sm={6} className={styles.spacer}></Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {formatCurrency(taxation.old.property.otherPropertyFlags?.nationalValuation ?? 0)}
                        </Grid>
                        {!editing && <Grid item sm={2} xs={6} className={styles.contentItem}>
                            <Grid container justifyContent="flex-end" alignItems="center">
                                <Grid item>{formatCurrency(prop.otherPropertyFlags?.nationalValuation ?? 0)}</Grid>
                            </Grid>
                        </Grid>}
                    </Grid>}

                    {prop.otherPropertyFlags?.manualValue || editing ? < Grid container spacing={4} alignItems="center">
                        <Grid item sm={2} xs={12} style={{ fontWeight: 'bold' }}>
                            {tr(TEXT_MANUAL_VALUE)}
                        </Grid>

                        <Grid item sm={6} className={styles.spacer}></Grid>
                        <Grid item sm={2} xs={6} className={styles.contentItem}>
                            {formatCurrency(taxation.old.property.otherPropertyFlags?.manualValue ?? 0)}
                        </Grid>
                        {!editing && <Grid item sm={2} xs={6} className={styles.contentItem}>
                            <Grid container justifyContent="flex-end" alignItems="center">
                                {prop.otherPropertyFlags?.manualValueDocument?.url &&
                                    <Grid item>
                                        <IconButton onClick={() => dispatch(actionSetPreviewPdf(undefined, undefined, prop.otherPropertyFlags.manualValueDocument.url))}><Info /></IconButton>
                                    </Grid>}
                                <Grid item>{formatCurrency(prop.otherPropertyFlags?.manualValue ?? 0)}</Grid>
                            </Grid>
                        </Grid>
                        }
                        {editing && <Grid item sm={2} xs={6} className={styles.contentItem}>
                            <TextField
                                name={`${TaxFormFieldNames.otherPropertyFlags}.${TaxFormFieldNames.manualValue}`}
                                type="text"
                                label={tr(TEXT_VALUE)}
                                value={manualValue}
                                onChange={(e: any) => setManualValue(e.target.value)}
                            />
                        </Grid>}

                        {/* Combined property (KO) specific extra manual value field */}
                        {isCombinedProperty(taxation.current.property.valueType) && <>
                            <Grid item sm={2} xs={12} style={{ fontWeight: 'bold' }}>
                                {tr(TEXT_COMBINED)}
                            </Grid>

                            <Grid item sm={6} className={styles.spacer}></Grid>
                            <Grid item sm={2} xs={6} className={styles.contentItem}>
                                {formatCurrency(taxation.old.property.otherPropertyFlags?.manualKoValue ?? 0)}
                            </Grid>
                            {!editing && <Grid item sm={2} xs={6} className={styles.contentItem}>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    {prop.otherPropertyFlags?.manualValueDocument?.url &&
                                        <Grid item>
                                            <IconButton onClick={() => dispatch(actionSetPreviewPdf(undefined, undefined, prop.otherPropertyFlags.manualValueDocument.url))}><Info /></IconButton>
                                        </Grid>}
                                    <Grid item>{formatCurrency(prop.otherPropertyFlags?.manualKoValue ?? 0)}</Grid>
                                </Grid>
                            </Grid>
                            }
                            {editing && <Grid item sm={2} xs={6} className={styles.contentItem}>
                                <TextField
                                    name={`${TaxFormFieldNames.otherPropertyFlags}.${TaxFormFieldNames.manualKoValue}`}
                                    type="text"
                                    label={tr(TEXT_VALUE)}
                                    value={manualKoValue}
                                    onChange={(e: any) => setManualKoValue(e.target.value)}
                                />
                            </Grid>}
                        </>}

                        {/* One more row for upload button */}
                        {editing && <>
                            <Grid item sm={8} className={styles.spacer}></Grid>
                            <Grid item sm={4} xs={12} className={styles.contentItem}>
                                <Grid container alignItems="center" justifyContent="flex-end">
                                    <Grid item>
                                        <CubitDropZone handleFileDrop={handleManualDocUpload} accept={FORM_FILE_TYPES.PDF} label={tr(TEXT_UPLOAD_DOCUMENT)} />
                                    </Grid>
                                    {prop.otherPropertyFlags?.manualValueDocument?.url &&
                                        <Grid item>
                                            <IconButton onClick={() => dispatch(actionSetPreviewPdf(undefined, undefined, prop.otherPropertyFlags.manualValueDocument.url))}><Info /></IconButton>
                                        </Grid>}
                                </Grid>
                            </Grid>
                        </>}
                        {/* Manual value comment */}
                        {(editing || prop.otherPropertyFlags.manualValueComment) && <>
                            <Grid item sm={8} className={styles.spacer}></Grid>
                            <Grid item sm={4} xs={12} className={styles.contentItem}>
                                <TextField
                                    name={`${TaxFormFieldNames.otherPropertyFlags}.${TaxFormFieldNames.manualValueComment}`}
                                    type="text"
                                    label={tr(TEXT_COMMENT)}
                                    value={manualValueComment}
                                    multiline
                                    onChange={(e: any) => setManualValueComment(e.target.value)}
                                    disabled={isTaxationEditingFinished(taxation)}
                                />
                            </Grid>
                        </>}
                    </Grid>
                        : <></>}

                    <Divider style={{ margin: '16px -16px' }}></Divider>

                    <Grid container spacing={2} alignItems="center" justifyContent="center" direction="column">
                        {!isTaxationEditingFinished(taxation) && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => handleTaxationFinish(taxation.id)}
                                    disabled={isFinishDisabled}
                                >
                                    <VerifiedCount
                                        count={verifiedValuesCount}
                                        total={verifiedValuesTotal}
                                        style={{ paddingRight: '3px', marginRight: '3px' }}
                                    />

                                    {tr(TEXT_FINISH_TAXATION)}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {editing && (
                        <>
                            <Divider style={{ margin: '16px -16px' }} />
                            <AccordionActions>
                                <Button onClick={handleCancelEditing} className={styles.cancelButton}>
                                    {tr(TEXT_CANCEL)}
                                </Button>
                                <Button
                                    onClick={handleManualValueSet}
                                    disabled={!manualReady}
                                    variant="contained"
                                    color="primary"
                                >
                                    {tr(TEXT_SAVE)}
                                </Button>
                            </AccordionActions>
                        </>
                    )}
                </div>
            </AccordionDetails>
        </Accordion >
    )
}
