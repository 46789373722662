/* eslint-disable */
import * as keys from '../keys'

export const nb: { [index: string]: string } = {}
nb[keys.TEXT_SAVE] = 'Lagre'
nb[keys.TEXT_CHANGES_SAVED] = 'Changes saved'
nb[keys.TEXT_DASHBOARD] = 'Dashbord'
nb[keys.TEXT_FILTERS] = 'Filtre'
nb[keys.TEXT_SEARCH] = 'Søk'
nb[keys.TEXT_LANGUAGE] = 'Språk'
nb[keys.TEXT_LOGOUT] = 'Logg ut'
nb[keys.TEXT_OBJECT_TYPES] = 'Objekttyper'
nb[keys.TEXT_PLOT_TYPE] = 'Tomtetype'
nb[keys.TEXT_HOUSE] = 'Boliger'
nb[keys.TEXT_HOLIDAY_HOME] = 'Fritidsboliger'
nb[keys.TEXT_LOGIN] = 'Logg inn'

nb[keys.TEXT_ZONE] = 'Sone'
nb[keys.TEXT_ZONE0] = 'Sone 0'
nb[keys.TEXT_ZONE1] = 'Sone 1'
nb[keys.TEXT_ZONE2] = 'Sone 2'
nb[keys.TEXT_ZONE3] = 'Sone 3'
nb[keys.TEXT_ZONE4] = 'Sone 4'
nb[keys.TEXT_ZONE5] = 'Sone 5'
nb[keys.TEXT_ZONE6] = 'Sone 6'

nb[keys.TEXT_ALL] = 'Alle'
nb[keys.TEXT_ME] = 'Meg'

nb[keys.TEXT_YES] = 'Ja'
nb[keys.TEXT_NO] = 'Nei'

nb[keys.TEXT_STATUS] = 'Status'
nb[keys.TEXT_NO_STATUS] = 'Ingen status'
nb[keys.TEXT_PICKED] = 'Plukket'
nb[keys.TEXT_AWARDED] = 'Tildelt'
nb[keys.TEXT_ACTIVE_CASE] = 'Aktiv sak'
nb[keys.TEXT_APPROVED_BY_TRIBUNAL] = 'Vedtatt NEMND'
nb[keys.TEXT_COMPLAINT_RECEIVED] = 'Klage mottatt'

nb[keys.TEXT_TAXATION] = 'Taksering'
nb[keys.TEXT_NO_TAXATION] = 'Mangler taksering'
nb[keys.TEXT_TAXATION_SOURCE] = 'Takseringskilde'
nb[keys.TEXT_TAX_ADMINISTRATION] = 'Skatteetaten'

nb[keys.TEXT_PROPERTY_VALUE] = 'Eiendomsverdi'

nb[keys.TEXT_MASS_REGISTRATION] = 'Masseregistrering'

nb[keys.TEXT_EXEMPTION] = 'Fritak'
nb[keys.TEXT_NO_EXEMPTION] = 'Ikke fritak'
nb[keys.TEXT_EXEMPTION_BY_REASON] = 'Årsak til fritak'

nb[keys.TEXT_IGANGSETTELSESTILATELSE] = 'Igangsettingstillatelse'
nb[keys.TEXT_YEARS_SINCE_STATUS_WAS_SET] = 'Sist endring av matrikkelstatus'
nb[keys.TEXT_MATRIKKEL_STATUS] = 'Matrikkelstatus'
nb[keys.TEXT_STATUS_DATE] = 'Sist endring av matrikkelstatus'

nb[keys.TEXT_OWNER_CATEGORY] = 'Eierkattegori'
nb[keys.TEXT_MUNICIPALITY] = 'Kommune'
nb[keys.TEXT_PUBLIC] = 'Offentlige'
nb[keys.TEXT_PRIVATE] = 'Private'
nb[keys.TEXT_DEAD_OWNERS] = 'Døde hjemmelshavere'

nb[keys.TEXT_STANDARD] = 'Standard'
nb[keys.TEXT_LOW] = 'Lav'
nb[keys.TEXT_NORMAL] = 'Normal'
nb[keys.TEXT_HIGH] = 'Høy'

nb[keys.TEXT_LOCATION] = 'Beliggenhet'

nb[keys.TEXT_PROPERTY] = 'Eiendom'
nb[keys.TEXT_PROPERTIES] = 'Eiendomer'

nb[keys.TEXT_OWNERS] = 'Eiere'

nb[keys.TEXT_ENABLE] = 'Aktiver'

nb[keys.TEXT_TAX_SUM] = 'Skattesum'

nb[keys.TEXT_FROM] = 'Fra'
nb[keys.TEXT_TO] = 'Til'
nb[keys.TEXT_ASSIGN] = 'Tildel'
nb[keys.TEXT_ASSIGNED] = 'Tildelt'
nb[keys.TEXT_TAXATION_DATE] = 'Takseringsdato'

nb[keys.TEXT_DATE_FROM] = 'Dato fra'
nb[keys.TEXT_DATE_TO] = 'Dato til'
nb[keys.TEXT_SETTINGS] = 'Innstillinger'
nb[keys.TEXT_CLEAR_ALL_FILTERS] = '[Clear all filters]'

nb[keys.TEXT_NAME] = 'Navn'
nb[keys.TEXT_FIRST_NAME] = 'Fornavn'
nb[keys.TEXT_LAST_NAME] = 'Etternavn'
nb[keys.TEXT_ADDRESS] = 'Adresse'
nb[keys.TEXT_ADDRESSES] = 'Adresser'
nb[keys.TEXT_NUMBER] = 'Nummer'
nb[keys.TEXT_PHONE_NUMBER] = 'Telefonnummer'
nb[keys.TEXT_E_MAIL] = 'E-post'
nb[keys.TEXT_TAX] = 'Skatt'
nb[keys.TEXT_TARIFF] = 'Takst'
nb[keys.TEXT_LAST_TARIFF] = 'Sist takst'
nb[keys.TEXT_APPRAISERS] = 'Takstmenn'
nb[keys.TEXT_USE_OF_LAND] = 'Bruk av grunn'
nb[keys.TEXT_USE] = 'Bruk'

nb[keys.TEXT_REGISTER_NUMBERS] = 'Matrikkelnummer'
nb[keys.TEXT_SELECTED] = 'Valgt'
nb[keys.TEXT_SCHEDULE_TAXATION] = 'Planlegg taksering'
nb[keys.TEXT_PARTICULARLY_FAVORABLE] = 'Spesielt gunstig'
nb[keys.TEXT_PARTICULARLY_UNFAVORABLE] = 'Spesielt ugunstig'

nb[keys.TEXT_0457] = 'Næringskoder'
nb[keys.TEXT_0777] = 'Postaddresser'
nb[keys.TEXT_0778] = 'Anlegg-/utstyrstyper'
nb[keys.TEXT_0779] = 'Resultatområder'
nb[keys.TEXT_0780] = 'Anleggs-/virksomhetstyper'
nb[keys.TEXT_0781] = 'Bygningskoder'
nb[keys.TEXT_0782] = 'El-virksomhetsstatus'
nb[keys.TEXT_0783] = 'El-virksomhetsoppgaver'

nb[keys.TEXT_PROPERTY_INFORMATION] = 'Eiendomsinformasjon'
nb[keys.TEXT_PLOT_INFORMATION] = 'Tomteinformasjon'
nb[keys.TEXT_CADASTRE] = 'Matrikkel'
nb[keys.TEXT_VALUE_TYPE] = 'Verditype'
nb[keys.TEXT_VALUE] = 'Verdi'
nb[keys.TEXT_M2_PRICE] = 'm² pris'
nb[keys.TEXT_FLOOR_FACTOR] = 'Etasjefaktor'
nb[keys.TEXT_FLOOR] = 'Etasje'
nb[keys.TEXT_LAST_REVIEWED] = 'Sist taksert'
nb[keys.TEXT_MAP] = 'Kart'
nb[keys.TEXT_LAND_VALUE] = 'Tomteverdi'
nb[keys.TEXT_BUILDING_VALUE] = 'Bygningsverdi'
nb[keys.TEXT_TOTAL_BUILDING_VALUE] = 'Total bygningsverdi'
nb[keys.TEXT_TOTAL_BEFORE_FACTOR] = 'Total før faktor'
nb[keys.TEXT_ZONE_FACTOR] = 'Sonefaktor'
nb[keys.TEXT_LOCATION_FACTOR] = 'Beliggenhetsfaktor'
nb[keys.TEXT_TOTAL_PROPERTY_VALUE] = 'Total eiendomsverdi'
nb[keys.TEXT_EXCEPTION] = 'Unntak'
nb[keys.TEXT_VALUE_PER_M2] = 'Verdi per m²'
nb[keys.TEXT_PLOT_SIZE] = 'Tomtestørrelse'
nb[keys.TEXT_TOTAL_LAND_VALUE] = 'Total tomteverdi'
nb[keys.TEXT_VALUE_BASED_LAYERS] = 'Verdigrunnlag'
nb[keys.TEXT_ADD_NEW_COMMENT] = 'Legg til ny kommentar'
nb[keys.TEXT_VIEW_ALL_COMMENTS_AND_NOTES] = 'Vis alle kommentarer og notater'
nb[keys.TEXT_STATE] = 'Tilstand'
nb[keys.TEXT_STANDARD_FACTOR] = 'Standardfaktor'
nb[keys.TEXT_ADD_TABLE] = 'Legg til etasje'
nb[keys.TEXT_NOTE] = 'Notat'
nb[keys.TEXT_CALENDAR] = 'Kalender'
nb[keys.TEXT_CALENDARS] = 'Kalendre'
nb[keys.TEXT_LIST] = 'Liste'
nb[keys.TEXT_DAY] = 'Dag'
nb[keys.TEXT_WEEK] = 'Uke'
nb[keys.TEXT_MONTH] = 'Måned'
nb[keys.TEXT_REMOVE_EVENT] = 'Fjern eiendom'
nb[keys.TEXT_REMOVE_EVENTS] = 'Fjern eiendommer'
nb[keys.TEXT_CONFIRM_CHANGES] = 'Bekreft endringer'
nb[keys.TEXT_SEND_NOTICE] = 'Send varsel ${params[0]} dager før taksering'
nb[keys.TEXT_TODAY] = 'I dag'

nb[keys.TEXT_RESIDENTIAL_PROPERTY] = 'Boligeiendom'
nb[keys.TEXT_LEISURE_PROPERTY] = 'Fritidseiendom'
nb[keys.TEXT_FARMING_FORESTRY_WITH_HOUSING] = 'Gårdsbruk/skogbruk med bolig'
nb[keys.TEXT_FARM_USE_FORESTRY_WITH_LEISURE] = 'Gårdsbruk/skogbruk med fritidsb'
nb[keys.TEXT_FARMS_OR_FORESTRY] = 'Gårdsbruk eller skogbruk'
nb[keys.TEXT_NOT_SELECTED] = 'Ikke valgt'
nb[keys.TEXT_COMBINED_BUILT_PLOT] = 'Kombinert bebygd tomt'
nb[keys.TEXT_COMMERCIAL] = 'Næringseiendom'
nb[keys.TEXT_UNDEVELOPED_PLOT_HOUSING] = 'Ubebygd tomt, bolig'
nb[keys.TEXT_UNDEVELOPED_PLOT_LEISURE] = 'Ubebygd tomt, fritid'
nb[keys.TEXT_UNDEVELOPED_PLOT_INDUSTRY] = 'Ubebygd tomt, næring'
nb[keys.TEXT_UNDEVELOPED_PLOT] = 'Ubebygd tomt'

nb[keys.TEXT_DIRECTIONS] = 'Veibeskrivelse'
nb[keys.TEXT_START_TAXATION] = 'Start taksering'
nb[keys.TEXT_BILLING_ADDRESS] = 'Fakturaadresse'
nb[keys.TEXT_BILLING] = 'Faktura'
nb[keys.TEXT_ZIP_CODE] = 'Postnummer'
nb[keys.TEXT_CANCEL] = 'Avbryt'
nb[keys.TEXT_ROLLBACK] = 'Tilbakefør'
nb[keys.TEXT_VERIFIED] = 'Verifisert'
nb[keys.TEXT_UNITS] = 'Boenheter'
nb[keys.TEXT_ADVANCED_DECAY_RESTORATION] = 'Fremskredet forfall/restaureringsobjekt'
nb[keys.TEXT_DEMOLITION_OBJECT] = 'Rivningsobjekt'
nb[keys.TEXT_ADD_FLOOR] = 'Legg til etasje'
nb[keys.TEXT_ADD_BUILDING] = 'Legg til bygning'
nb[keys.TEXT_ADD_PICTURE] = 'Legg til bilde'
nb[keys.TEXT_OF] = 'av'
nb[keys.TEXT_2_IMAGES_REQUIRED] = 'Minimum 2 bilder er påkrevd'
nb[keys.TEXT_FINISH_TAXATION] = 'Ferdigstill taksering'
nb[keys.TEXT_SHOW_WEEKENDS] = 'Vis helg'
nb[keys.TEXT_BUSINESS] = 'Virksomhet'

nb[keys.TEXT_SELECT_FOR_TAXATION] = 'Plukk for taksering'
nb[keys.TEXT_SCHEDULE_TAXATION_FOR] = 'Planlegg taksering for'
nb[keys.TEXT_SCHEDULED_TAXATION_FOR] = 'Planlagt taksering for'
nb[keys.TEXT_COMPANIES] = 'Selskaper'
nb[keys.TEXT_USERS] = 'Brukere'
nb[keys.TEXT_USER] = 'Bruker'

nb[keys.TEXT_AREA] = 'Område'
nb[keys.TEXT_SUN] = 'Sol'
nb[keys.TEXT_NOISE] = 'Støy'
nb[keys.TEXT_VIEW] = 'Utsikt'
nb[keys.TEXT_LIGHT_POLLUTION] = 'Lysforurensning'
nb[keys.TEXT_AIR_POLLUTION] = 'Luftforurensning'
nb[keys.TEXT_POLLUTION_REASON] = 'Forurensning grunn'
nb[keys.TEXT_FLOOD_AND_EXPOSED_AREA] = 'Flom og rasutsatt område'
nb[keys.TEXT_DIFFICULT_ACCESS_ROAD] = 'Vanskelig atkomstvei'
nb[keys.TEXT_ARCHITECTURE] = 'Arkitektur'
nb[keys.TEXT_MAINTENANCE] = 'Vedlikehold'
nb[keys.TEXT_OUTDOOR_AREA] = 'Uteområde'

nb[keys.TEXT_POSITIVE] = 'Positiv'
nb[keys.TEXT_NEGATIVE] = 'Negativ'

nb[keys.TEXT_METRICS_STATUS] = 'Matrikkelstatus'
nb[keys.TEXT_BAD] = 'Dårlig'
nb[keys.TEXT_VERY_BAD] = 'Meget dårlig'

nb[keys.TEXT_PRESENT_AT_TAXATION] = 'Tilstede ved taksering'
nb[keys.TEXT_NOBODY] = 'Ingen'
nb[keys.TEXT_LEGAL_OWNER] = 'Hjemmelshaver'
nb[keys.TEXT_OTHERS] = 'Andre'
nb[keys.TEXT_TAXATION_INFORMATION] = 'Takst informasjon'

nb[keys.TEXT_LAST_TAXATION] = 'Sist taksering'
nb[keys.TEXT_NEW_TAXATION] = 'Ny taksering'
nb[keys.TEXT_ERASE_BUILDING] = 'Slett bygning'

nb[keys.TEXT_WELCOME_TO] = 'Velkommen til'
nb[keys.TEXT_CUBIT_TAX] = 'Cubit skatt'
nb[keys.TEXT_PASSWORD] = 'Passord'

nb[keys.TEXT_IMAGES] = 'Bilder'

nb[keys.TEXT_COULD_NOT_TAXATE] = 'Kunne ikke taksert'
nb[keys.TEXT_DID_NOT_GET_TAXATED] = 'Fikk ikke taksert'

nb[keys.TEXT_TRIBUNAL] = 'Nemnd'
nb[keys.TEXT_EDIT_GROUP] = 'Rediger gruppe'
nb[keys.TEXT_GROUP_NAME] = 'Gruppe navn'
nb[keys.TEXT_GROUP_DESCRIPTION] = 'Gruppe beskrivelse'
nb[keys.TEXT_APPROVED_TRIBUNAL] = 'Godkjent av nemnd'
nb[keys.TEXT_APPROVAL_DATE] = 'Dato godkjent'
nb[keys.TEXT_NUMBER_OF_PROPERTIES] = 'Antall eiendommer'

nb[keys.TEXT_FACTOR_CHANGE] = 'Faktor endring'
nb[keys.TEXT_METRIC_CHANGE] = 'Matrikkel endring'
nb[keys.TEXT_METRIC_CHANGES] = 'Matrikkel endringer'
nb[keys.TEXT_NOTE_CHANGE] = 'Notat endring'

nb[keys.TEXT_FILTER] = 'Filtrer'
nb[keys.TEXT_ADD_TO_LIST] = 'Legg til liste'
nb[keys.TEXT_CREATE_NEW_GROUP] = 'Lag ny gruppe'

nb[keys.TEXT_ADDED_TO] = 'Lagt til'
nb[keys.TEXT_FINISHED_TAXATION] = 'Ferdig taksering'
nb[keys.TEXT_READY_FOR_TRIBUNAL] = 'Klar for nemnd'
nb[keys.TEXT_COMPLAINTS] = 'Klager'

nb[keys.TEXT_INCOMPETENT] = 'Inhabil'
nb[keys.TEXT_SET_AS_INCOMPETENT] = 'Sett som inhabil'
nb[keys.TEXT_REMOVE_AS_INCOMPETENT] = 'Fjerne inhabilitet'
nb[keys.TEXT_SET_AS_INCOMPETENT_FOR_OBJECT] = 'Sett som inhabil for objektet'
nb[keys.TEXT_APPROVE_TRIBUNAL] = 'Godkjenn nemnd'
nb[keys.TEXT_APPROVE_TAXATION] = 'Godkjenn taksering'
nb[keys.TEXT_DECLINE_TAXATION] = 'Avslå taksering'
nb[keys.TEXT_DECLINED] = 'Aavvist'
nb[keys.TEXT_START_TRIBUNAL] = 'Start nemnd'
nb[keys.TEXT_APPROVED] = 'Godkjent'

nb[keys.TEXT_HAS_COMMENT] = 'Har kommentar'
nb[keys.TEXT_HAS_CHANGE] = 'Har endring'
nb[keys.TEXT_HAS_METRICS_CHANGE] = 'Har matrikkel endring'
nb[keys.TEXT_UNASSIGNED] = 'Ufordelt'
nb[keys.TEXT_REJECTED_BY_THE_TRIBUNAL] = 'Avvist av nemnd'
nb[keys.TEXT_REASON_FOR_FAILED_TAXATION] = 'Årsak til underkjent taksering'

nb[keys.TEXT_PLANNED] = 'Planlagt'
nb[keys.TEXT_STARTED] = 'Startet'
nb[keys.TEXT_COMPLETED] = 'Gjennomført'

nb[keys.TEXT_REOPEN] = 'Gjenåpne'
nb[keys.TEXT_FILTER_EVENTS] = 'Filtrer eiendommer'
nb[keys.TEXT_FILTER_TAXATIONS] = 'Filtrer etter eiendom, verditype…'
nb[keys.TEXT_REJECTED] = 'Avvist'

nb[keys.TEXT_COMMENT_NOUN] = 'Kommentar'
nb[keys.TEXT_COMMENTS] = 'Kommentarer'

nb[keys.TEXT_ACCESS] = 'Tilgang'
nb[keys.TEXT_ACCESSES] = 'Tilganger'
nb[keys.TEXT_COMPANY] = 'Selskap'
nb[keys.TEXT_ZONES] = 'Soner'
nb[keys.TEXT_RIGHTS] = 'Rettigheter'
nb[keys.TEXT_ACTIVE] = 'Aktiv'
nb[keys.TEXT_INACTIVE] = 'Inaktiv'
nb[keys.TEXT_SHOW_ACTIVE_USERS_ONLY] = 'Bare vis aktive brukere'

nb[keys.TEXT_ADD_USER] = 'Legg til brukere'
nb[keys.TEXT_REGISTER] = 'Registrere'
nb[keys.TEXT_REPEAT_PASSWORD] = 'Gjenta passord'
nb[keys.TEXT_PASSWORD_DOESNT_MATCH] = 'Passordet stemmer ikke'
nb[keys.TEXT_REQUIRED] = 'Påkrevd'
nb[keys.TEXT_MINIMUM_LENGTH] = 'Minimum lengde'
nb[keys.TEXT_ADD_COMPANY] = 'Legg til selskap'
nb[keys.TEXT_ADD_COMPANY] = 'Rediger selskap'

nb[keys.TEXT_ADMINISTRATOR] = 'Administrator'
nb[keys.TEXT_TAXATIONS_ADMINISTRATOR] = 'Taksering administrator'
nb[keys.TEXT_TRIBUNAL_ADMINISTRATOR] = 'Nemnd administrator'
nb[keys.TEXT_TAXATIONS_USER] = 'Taksering bruker'

nb[keys.TEXT_UNAUTHORIZED] = 'Uautorisert'
nb[keys.TEXT_FINISH_REVIEW] = 'Ferdig med gjennomgang'

nb[keys.TEXT_PREVIOUS] = 'Forrige'
nb[keys.TEXT_NEXT] = 'Neste'

nb[keys.TEXT_REGULATED] = 'Regulert'

nb[keys.TEXT_USER_GROUPS] = 'Brukergrupper'
nb[keys.TEXT_USER_GROUP] = 'Brukergruppe'
nb[keys.TEXT_NEW_USER_GROUP] = 'Ny brukergruppe'

nb[keys.TEXT_TYPE] = 'Type'
nb[keys.TEXT_EVALUATION_COMPANY] = 'Takseringsselskap'

nb[keys.TEXT_SHOWING] = 'Viser'
nb[keys.TEXT_LOAD_MORE] = 'Last mer'

nb[keys.TEXT_POLITICAL] = 'Andre'
nb[keys.TEXT_CADASTRAL_ERROR] = 'Matrikkelfeil'
nb[keys.TEXT_VALUATION_ERROR] = 'Takseringsfeil'
nb[keys.TEXT_JUDGMENTAL_ERRORS] = 'Skjønnsmessige feil'
nb[keys.TEXT_COMPLAINT_BOARD] = 'Klagenemnd'
nb[keys.TEXT_COMBINED] = 'Kombinerte'
nb[keys.TEXT_ALL_COMPLAINTS] = 'Alle klager'

nb[keys.TEXT_CAUSE] = 'Årsak'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_1] = 'Tomtearealet er ikke korrekt'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_2] = 'Tomtetype er ikke korrekt'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_3] = 'Tomten og bygningene har fått urimelig god beliggenhet'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_4] = 'Bygning har fått urimelig høy standard'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_5] = 'Feil areal av bygning'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_6] = 'Bilder er fra feil eiendom'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_7] = 'Andre rettelser/klager'

nb[keys.TEXT_APPLIED] = 'Tatt i bruk'
nb[keys.TEXT_COMPLETION] = 'Ferdigattest'
nb[keys.TEXT_TEMPORARY_USE_PERMIT] = 'Midlertidig brukstillatelse'
nb[keys.TEXT_PERMISSION_TO_START] = 'Igangsettingstillatelse'
nb[keys.TEXT_BUILDING_NUMBER_EXPIRED] = 'Bygningsnummer utgått'
nb[keys.TEXT_NOTIFICATION_ACTION_COMPLETED] = 'Meldingssak tiltak fullført'
nb[keys.TEXT_BUILDING_DEMOLISHED] = 'Bygning revet/brent'
nb[keys.TEXT_NOTIFICATION_CASE_MEASURES] = 'Meldingssak registrer tiltak'
nb[keys.TEXT_BUILDING_CANCELED] = 'Bygging avlyst'
nb[keys.TEXT_BUILDING_APPROVED_FOR_DEMOLITION] = 'Bygning godkjent for riving/brenning'
nb[keys.TEXT_OUTLINE_PLANNING_PERMISSION] = 'Rammetillatelse'
nb[keys.TEXT_EXEMPT_TO_APPLY] = 'Fritatt for søknadsplikt'
nb[keys.TEXT_BUILDING_MOVED] = 'Bygning flyttet'
nb[keys.TEXT_MEASURES_EXEMPT] = 'Tiltak unntatt fra byggesaksbehandling'

nb[keys.TEXT_FARM_AND_FORESTRY] = '§5h Gårdsbruk og skogbruk'
nb[keys.TEXT_BUILDING_7C] = '§7c bygg'
nb[keys.TEXT_HISTORIC_VALUE] = '§7b Bygn. med hist. verdi'
nb[keys.TEXT_MUNICIPAL_PROPERTY] = '§5d Kommunal eiend.'
nb[keys.TEXT_STATE_PROPERTY] = '§5a Statlig eiend.'
nb[keys.TEXT_INSTITUTIONS] = '§7a Stiftelser og institusjoner'
nb[keys.TEXT_HEALTH_INSTITUTIONS] = '§5g Helseforetak'
nb[keys.TEXT_FIVE_H] = '§5h'
nb[keys.TEXT_CHURCHES] = '§5c Kirker'
nb[keys.TEXT_BUILDING_PERMIT] = 'Byggetillatelse?'
nb[keys.TEXT_RAILWAY_PROPERTIES] = '§5b Jernbaneeiendommer'
nb[keys.TEXT_SEVEN_D] = '§7d'
nb[keys.TEXT_SECTION_28] = '§ 28-fritak'
nb[keys.TEXT_FIVE_J] = '§5j'
nb[keys.TEXT_FIVE_K] = '§5k'
nb[keys.TEXT_COMPLAINT] = 'Klage'
nb[keys.TEXT_BUILDING_PHOTOS] = 'Bilder fra feil eiendom'
nb[keys.TEXT_BUILDING_PHOTOS_LABEL] = 'Følgende bilder er fra feil eiendom'

nb[keys.TEXT_SEND_TO_APPRAISER] = 'Send til takserer'
nb[keys.TEXT_COMPLAINT_FOLLOWED] = 'Klage tatt til følge'
nb[keys.TEXT_COMPLAINT_NOT_FOLLOWED] = 'Klage ikke tatt til følge'
nb[keys.TEXT_COMPLAINT_PARTIALLY_FOLLOWED] = 'Klage delvis tatt til følge'
nb[keys.TEXT_COMMENT_DESCRIPTION] = 'Kommentar / beskrivelse'
nb[keys.TEXT_GROUNDS] = 'Begrunnelse'
nb[keys.TEXT_DOCUMENTATION] = 'Documentasjon'
nb[keys.TEXT_BUILDING] = 'Bygning'
nb[keys.TEXT_TAXATION_PLOT_TYPE] = 'Taksering tomtetype'
nb[keys.TEXT_CORRECT_PLOT_TYPE] = 'Korrekt tomtereal'
nb[keys.TEXT_ADJUSTED] = 'Rettet'
nb[keys.TEXT_READJUSTED] = 'Justert'
nb[keys.TEXT_DIFFERENCE] = 'Differanse'
nb[keys.TEXT_ORIGINAL_TAXATION] = 'Original taksering'
nb[keys.TEXT_UPDATED_TAXATION] = 'Takst med justert areal'
nb[keys.TEXT_TAXATION_AREA] = 'Taksering areal'
nb[keys.TEXT_CORRECTED_AREA] = 'Korrekt areal'
nb[keys.TEXT_ADJUSTED_AREA] = 'Justert areal'

nb[keys.TEXT_REPORTS] = 'Rapport'
nb[keys.TEXT_TAX_YEAR] = 'Skatteår'
nb[keys.TEXT_EXPORT_TO_EXCEL] = 'Last ned til excel'
nb[keys.TEXT_SIMULATION] = 'Simulering'
nb[keys.TEXT_RUN_SIMULATION] = 'Kjøre simulering'
nb[keys.TEXT_MUNICIPALITY_ADJUSTMENT] = 'Kontorjustering'
nb[keys.TEXT_ADJUSTMENT_PERCENTAGE] = 'Justering i prosent'
nb[keys.TEXT_MUNICIPALITY_OWNER] = ' Kommune eier'
nb[keys.TEXT_USE_NATIONAL_SETTINGS] = 'Bruk formuegrunnlag'
nb[keys.TEXT_USES_NATIONAL_SETTINGS] = 'Bruker formuegrunnlag'
nb[keys.TEXT_DOES_NOT_USE_NATIONAL_SETTINGS] = 'Bruker ikke formuegrunnlag'
nb[keys.TEXT_MINIMUM_TAX] = 'Minimumsskatt'
nb[keys.TEXT_PROPERTY_TYPE] = 'Utskrivningsalternativ'
nb[keys.TEXT_PROPERTY_TYPE_OPTION_A] = 'a) Faste eiendommer i hele kommune'
nb[keys.TEXT_PROPERTY_TYPE_OPTION_B] = 'b) Faste eiendommer i klart avgrensa områder som helt eller delvis er utbygd på byvis eller der slik utbygning er i gang'
nb[keys.TEXT_PROPERTY_TYPE_OPTION_C] = 'c) Bare på kraftanlegg, vindkraftanlegg, kraftnettet og anlegg omfattet av særskatteregler for petroleum'
nb[keys.TEXT_PROPERTY_TYPE_OPTION_D] = 'd) Bare på næringseiendom, kraftanlegg , vidgraftverk, kraftnett og anlegg omfattet av særskatteregler for petroleum'
nb[keys.TEXT_PROPERTY_TYPE_OPTION_E] = 'e) b og c'
nb[keys.TEXT_PROPERTY_TYPE_OPTION_F] = 'f) b og d'
nb[keys.TEXT_PROPERTY_TYPE_OPTION_G] = 'g) Faste eiendommer i hele kommunen, unntatt næringseiendommer, kraftanlegg, vindkraftanlegg, kraftnettet og anlegg omfattet av særskattereglene for petroleum'
nb[keys.TEXT_RATES] = 'Satser'
nb[keys.TEXT_RATES_OPTION_1] = 'Egen sats eiendommer med selvstendig boenhet  (boliger og fritidseiendommer samt boligdel i kombinasjonsbygninger)'
nb[keys.TEXT_RATES_OPTION_2] = 'Forskjellig sats for bebygd og ubebygde tomter'
nb[keys.TEXT_RATES_OPTION_3] = 'Forskjellig sats for tomt og bygninger'
nb[keys.TEXT_RATES_OPTION_4] = 'Egen sats for områder nevnt i utskrivingsalternativ'
nb[keys.TEXT_DEFAULT_TAX_RATE] = 'Almindelig skattesats'
nb[keys.TEXT_TAX_PER_ZONE] = 'Egen sats for områder'
nb[keys.TEXT_DWELLING_TAX_RATE] = 'Selvstendig boenhet'
nb[keys.TEXT_PROPERTIES_WITH_BUILDINGS] = 'Bebygget grunneiendom'
nb[keys.TEXT_PROPERTIES_WITHOUT_BUILDINGS] = 'Uebygget grunneiendom'
nb[keys.TEXT_BUILDINGS_TAX_RATE] = 'Buildings tax rate'
nb[keys.TEXT_LAND_TAX_RATE] = 'Land tax rate'
// eslint-disable-next-line
nb[keys.TEXT_BETWEEN_SOMETHING_AND] = 'Mellom ${params[0]} og ${params[1]}'
nb[keys.TEXT_REDUCTION_FACTOR] = 'Reduksjonsfaktor'
nb[keys.TEXT_FLAT_REDUCTION] = 'Bunnfradrag'
nb[keys.TEXT_FLAT_REDUCTION_VALUE] = 'Bunnfradrag størrelse'
// eslint-disable-next-line
nb[keys.TEXT_MINIMUM_SOMETHING] = 'Minimum ${params[0]}'
// eslint-disable-next-line
nb[keys.TEXT_SOMETHING_EXEMPTION] = '${params[0]} fritak'
nb[keys.TEXT_EXEMPTION_OPTION_A] = 'a) Eiendommer eid av stiftelser eller institusjoner som gagner kommune, fylke eller stat'
nb[keys.TEXT_EXEMPTION_OPTION_B] = 'b) Bygninger av historisk verdi'
nb[keys.TEXT_EXEMPTION_OPTION_C] = 'c) Bygninger som helt eller delvis benyttes til husvær (opptil 20 år etter de er bygget)'
nb[keys.TEXT_EXEMPTION_OPTION_D] = 'd) Bygning og grunn i visse områder av kommunen'
nb[keys.TEXT_EXEMPTION_OPTION_E] = 'e) fritidsboliger'
nb[keys.TEXT_YEARS] = 'år'
nb[keys.TEXT_REMOVE] = 'Fjern'
nb[keys.TEXT_TAX_SETTINGS] = 'Takseringinnstillinger'
nb[keys.TEXT_ADD_USAGE_TYPE] = 'LEGG TIL BRUKSTYPE'
nb[keys.TEXT_FACTOR] = 'Faktor'
nb[keys.TEXT_ADD_FACTOR] = 'LEGG TIL FAKTOR'
nb[keys.TEXT_CHOICE] = 'Valg'
nb[keys.TEXT_FACTORS] = 'Faktorer'
nb[keys.TEXT_DONT_USE_FLOOR_FACTOR] = 'Bruker ikke etasjefaktor'
nb[keys.TEXT_BUILDING_USE_TYPE] = 'Brukstyper'
nb[keys.TEXT_ERROR_OCCURRED] = 'En uventet feil oppstod'
nb[keys.TEXT_MIN_BUILDING_AREA] = 'Minimums bygningsareal'
nb[keys.TEXT_FARM] = 'Gårdsbruk'
nb[keys.TEXT_PLOT_AS_DWELLING] = 'Tomt regnet som boligareal'
nb[keys.TEXT_MAX_HOUSING_SIZE] = 'Maks boligstørrelse'
nb[keys.TEXT_ATTACHMENT_PLOT_VALUE] = 'Festetomtverdi'
nb[keys.TEXT_POINTLESS_SIZE] = 'Punkttomt størrelse'
nb[keys.TEXT_UNKNOWN_PLOT_SIZE] = 'Ukjent tomtestørrelse'
nb[keys.TEXT_VALUE_PER_PARKING] = 'Verdi per parkeringsplass'
nb[keys.TEXT_NUMBER_OF_TERMS] = 'Antall terminer'
nb[keys.TEXT_USE_FLOOR_FACTOR] = 'Bruk etasjefaktor'
nb[keys.TEXT_USE_AREA_FACTOR] = 'Bruk arealfaktor'
nb[keys.TEXT_FROM_AREA] = 'Fra areal'
nb[keys.TEXT_TO_AREA] = 'Til areal'
nb[keys.TEXT_SAVE_CHANGES] = 'LAGRE ENDRINGER'
nb[keys.TEXT_COMPLETE_TAX_YEAR] = 'FERDIGSTILL SKATTEÅR'
nb[keys.TEXT_SETTINGS_CHANGE_COMMENT] = 'Årsak til endring'
nb[keys.TEXT_SETTINGS_TAKE_EFFECT] = 'Når gjelder innstillingene fra?'
nb[keys.TEXT_SAVE_TAXATION_SETTINGS] = 'Lagre takseringsinnstillinger'
nb[keys.TEXT_VALID_FROM] = 'Gjelder fra'
nb[keys.TEXT_POWERPLANTS_AND_PETROLIUM] = 'Kraftanlegg, vindkraftanlegg, kraftnettet og anlegg omfattet av særskatteregler for petroleum'
nb[keys.TEXT_BENEFICIAL_TO_MUNICIPALITY] = 'Eiendommer eid av stiftelser eller institusjoner som gagner kommune, fylke eller stat'
nb[keys.TEXT_USED_FOR_PARKING] = 'Er tomten benyttet for parkeringsvirksomhet'
nb[keys.TEXT_PARKING_SPACES] = 'Antall parkeringsplasser'
nb[keys.TEXT_YEAR] = 'År'
nb[keys.TEXT_MUNICIPALITY_ADJUSTED] = 'Kontorjustert'
nb[keys.TEXT_GENERAL_RATE] = 'Generell sats'
nb[keys.TEXT_OTHER_RATES] = 'Andre satser'
nb[keys.TEXT_FLOOR_USE] = 'Etasjebruk'
nb[keys.TEXT_HOUSING_TAX] = 'boligskatt'
nb[keys.TEXT_PERCENTAGE_SHARE] = 'Andel'
nb[keys.TEXT_PLOT] = 'Tomt'
nb[keys.TEXT_LIVING] = 'Bolig'
nb[keys.TEXT_ORDINARY] = 'Ordinær'
nb[keys.TEXT_ORDINARY_NORMAL] = 'Ordinær / normal'
nb[keys.TEXT_TAX_RATE] = 'Skatterate'
nb[keys.TEXT_BUILDING_DELETED_UNDO] = 'Bygning er slettet. Angre?'
nb[keys.TEXT_LETTER_TEMPLATES] = 'Brevmal'
nb[keys.TEXT_TEXTS] = 'Tekster'
nb[keys.TEXT_CASE_DOCUMENTS] = 'Saksdokumenter'
nb[keys.TEXT_EMAILS] = 'Eposter'
nb[keys.TEXT_SMS] = 'SMS'
nb[keys.TEXT_TEMPLATES] = 'Utsendinger'
nb[keys.TEXT_TITLE] = 'Tittel'
nb[keys.TEXT_PERSONAL_NR] = 'Personnummer/org nummer'
nb[keys.TEXT_ADD_SMS] = 'OPPRETT SMS'
nb[keys.TEXT_ADD_EMAIL] = 'OPPRETT DOKUMNET'
nb[keys.TEXT_ADD_TEXT] = 'OPPRETT TEKST'
nb[keys.TEXT_ADD_CASE_DOCUMENT] = 'OPPRETT SAKSDOKUMENT'
nb[keys.TEXT_ADD_DOCUMENT_TEMPLATE] = 'OPPRETT MAL'
nb[keys.TEXT_DOCUMENT_TEMPLATES] = 'Maler'
nb[keys.TEXT_FLOW] = 'Sideskift'
nb[keys.TEXT_COMPANY2] = 'Selskaper'
nb[keys.TEXT_TEMPLATE] = 'Mal'
nb[keys.TEXT_ERROR_HEADER] = 'Avviksoverskrift'
nb[keys.TEXT_ERROR_DESCRIPTION] = 'Avviksbeskrivelse'
nb[keys.TEXT_REMARK_HEADER] = 'Anmerkningsoverskrift'
nb[keys.TEXT_REMARK_DESCRIPTION] = 'Anmerkningsbeskrivelse'
nb[keys.TEXT_GENERAL_REMARK] = 'Generelle kommentarer'
nb[keys.TEXT_TEFT_DESCRIPTION] = 'Temabeskrivelse'
nb[keys.TEXT_COPY_RECIPIENTS] = 'Kopimottakere'
nb[keys.TEXT_CONTACT_DEFINED] = 'Kontaktperson definert'
nb[keys.TEXT_CONTACT_NOT_DEFINED] = 'Kontaktperson ikke definert'
nb[keys.TEXT_TEMPLATE_TEXT] = 'Maltekst'
nb[keys.TEXT_ALWAYS_AFTER] = 'Alltid sideskift etter denne teksten'
nb[keys.TEXT_ALWAYS_BEFORE] = 'Alltid sideskift før denne teksten'
nb[keys.TEXT_NOT_INSIDE] = 'Aldri linjeskift inne i denne teksten'

nb[keys.TEXT_GRID_OWNER_LOGO] = 'Logo nettselskap'
nb[keys.TEXT_POST_ADDRESS] = 'Postadresse'
nb[keys.TEXT_HEADLINE] = 'Overskrift'
nb[keys.TEXT_SUMMARY] = 'Sammendrag'
nb[keys.TEXT_RETURN_POST_ADDRESS] = 'Returpostadresse'
nb[keys.TEXT_HORIZONTAL_LINE] = 'Horisontal linje'
nb[keys.TEXT_DELETE_ELEMENT] = 'SLETT ELEMENT'
nb[keys.TEXT_DESIGN_ELEMENT] = 'Designelementer'
nb[keys.TEXT_HEIGHT_IN_MM] = 'Høyde i mm'
nb[keys.TEXT_FOOTER_HEIGHT_IN_MM] = 'Høyde bunntekst i mm'
nb[keys.TEXT_OFFSET_TOP_IN_MM] = 'Avstand fra topp i mm'
nb[keys.TEXT_OFFSET_LEFT_IN_MM] = 'Avstand fra venstre i mm'
nb[keys.TEXT_WIDTH_IN_MM] = 'Bredde i mm'
nb[keys.TEXT_LETTER_CONTENT] = 'Brevets innhold'
nb[keys.TEXT_PREVIEW] = 'Forhåndsvisning'
nb[keys.TEXT_TEXT_ELEMENTS] = 'Tekstelementer'

nb[keys.TEXT_BUILDING_BUILT] = 'Bygning bygget'
nb[keys.TEXT_HAS_PARKING] = 'Tomten er benyttet for parkeringsvirksomhet'
nb[keys.TEXT_VALUE_UNDER] = 'Verdi under'
nb[keys.TEXT_VALUE_OVER] = 'Verdi over'
nb[keys.TEXT_TAXATIONS] = 'Taksering'
nb[keys.TEXT_FINISHED] = 'Avsluttet'
nb[keys.TEXT_SENT_OUT] = 'Sendt ut'
nb[keys.TEXT_OPEN_COMPLAINT] = 'OPPRETT KLAGE'
nb[keys.TEXT_NO_COMPLAINTS_HEADLINE] = 'Ingen klager mottatt for denne takseringen'
nb[keys.TEXT_DOCUMENT_RECEIVER] = 'Dokumentmottaker'
nb[keys.TEXT_INVOICE_RECEIVER] = 'Fakturamottaker'
nb[keys.TEXT_ADD_DOCUMENT_RECEIVER] = 'LEGG TIL DOKUMENTMOTTAGER'
nb[keys.TEXT_SEND_LAST_ASSESSMENT] = 'SEND SISTE TAKSERING'
nb[keys.TEXT_COMMENT] = 'Kommentar'
nb[keys.TEXT_SEND_TO_NEMND] = 'SEND TIL KLAGENEMND'
nb[keys.TEXT_CREATED] = 'Opprettet'
nb[keys.TEXT_COMPLAINTS_TRIBUNAL] = 'Klagenemnd'
nb[keys.TEXT_DESCRIPTION] = 'Til stede'
nb[keys.TEXT_OFFICE_ADJUSTMENT_TAX] = 'Skriveborsjustering'
nb[keys.TEXT_TAXATION_TYPE] = 'Takst type'
nb[keys.TEXT_CHANGE] = 'Endring'
nb[keys.TEXT_UPLOAD_IMAGE] = 'Legg til bilde'
nb[keys.TEXT_UPLOAD_DOCUMENT] = 'Last opp fra datamaskin'
nb[keys.TEXT_UPLOAD] = 'Last opp'
nb[keys.TEXT_DOCUMENTS] = 'Dokumenter'

nb[keys.TEXT_VIEW_ONLY] = 'Innsyn uten personopplysninger'
nb[keys.TEXT_REPORTS_USER] = 'Rapport og simulering'
nb[keys.TEXT_TAXATION_PLANNER] = 'Takseringsplanlegger'
nb[keys.TEXT_TAXATION_PEOPLE] = 'Takseringspersonale'
nb[keys.TEXT_NEMND_TRIBUNAL] = 'Nemnd tribunal'
nb[keys.TEXT_CASE_WORKER] = 'Saksbehandler'

nb[keys.TEXT_MYSELF] = 'Meg selv'
nb[keys.TEXT_WHO] = 'Hvem'

nb[keys.TEXT_TAXATION_METHOD] = 'Takseringsmetode'
nb[keys.TEXT_TAXATION_CASE_WORKER] = 'Takst mann'
nb[keys.TEXT_INSECTION_DATE] = 'Besiktigelse dato'
nb[keys.TEXT_SENT_BY] = 'Sendt på'
nb[keys.TEXT_EMAIL] = 'epost'
nb[keys.TEXT_LETTER] = 'brev'

nb[keys.TEXT_CASE_FLOW] = 'Saksgang'

nb[keys.TEXT_NOTICE] = 'Utsending'
nb[keys.TEXT_CHANNEL] = 'Kanal'
nb[keys.TEXT_PAPER_LETTER] = 'Svar ut'
nb[keys.TEXT_TRIGGER] = 'Tidspunkt'
nb[keys.TEXT_CASE_DOCUMENT] = 'Saksdokument'
nb[keys.TEXT_DAYS] = 'dager'
nb[keys.TEXT_UNTIL_TAXATION] = 'før taksering'
nb[keys.TEXT_AFTER_APPROVED_BY_TRIBUNAL] = 'etter godkjent av nemd'
nb[keys.TEXT_AFTER_COMPLAINT_RECEIVED] = 'etter klage mottatt'
nb[keys.TEXT_AFTER_COMPLAINT_APPROVED] = 'etter klage godkjetnt'
nb[keys.TEXT_AFTER_COMPLAINT_REJECTED] = 'etter klage avvist'
nb[keys.TEXT_AFTER_NEW_TAX] = 'etter ny takst'
nb[keys.TEXT_AFTER_OWNERSHIP_CHANGE] = 'etter eierskifte'
nb[keys.TEXT_AFTER_APPLICATION_RECEIVED] = 'etter mottatt søknad'

nb[keys.TEXT_COMMUNICATION] = 'Kommunikasjon'
nb[keys.TEXT_RECIPIENT] = 'Motpart'
nb[keys.TEXT_COPY_RECIPIENT] = 'Kopimottakere'
nb[keys.TEXT_SEND_EMAIL] = 'SEND E-POST'
nb[keys.TEXT_SEND_SMS] = 'SEND SMS'
nb[keys.TEXT_SEND_LETTER] = 'SEND BREV'
nb[keys.TEXT_ADD_RECIPIENT_EMAIL] = 'Legg til epost for mottaker'
nb[keys.TEXT_ADD_RECIPIENT_PHONENUMBER] = 'Legg til telefonnummer'
nb[keys.TEXT_SENT] = 'Sendt'
nb[keys.TEXT_SENDER] = 'Avsender'
nb[keys.TEXT_SUBJECT] = 'Vedrørende'
nb[keys.TEXT_FORWARDED] = 'Videresendt'
nb[keys.TEXT_WROTE] = 'skrev'
nb[keys.TEXT_REPLY] = 'Svar'
nb[keys.TEXT_ATTACH_FILE_FROM_COMPUTER] = 'Legg ved fil fra datamaskin'
nb[keys.TEXT_ATTACH_CASE_DOCUMENT] = 'Legg ved taksdokument'
nb[keys.TEXT_SEARCH_FOR_LETTERS] = 'Søk etter brev, e-post, sms eller telefonsamtaler'
nb[keys.TEXT_INBOUND] = 'Innkommende'
nb[keys.TEXT_OUTBOUND] = 'Utgående'

nb[keys.TEXT_NEW_EMAIL] = 'Ny e-post'
nb[keys.TEXT_NEW_SMS] = 'Ny SMS'
nb[keys.TEXT_NEW_LETTER] = 'Nytt brev'
nb[keys.TEXT_REGISTER_PHONECALL] = 'Registrer telefonsamtale'
nb[keys.TEXT_NEW_COMMUNICATION] = 'Generisk tittel'
nb[keys.TEXT_FORWARD] = 'Videresend'
nb[keys.TEXT_REPLY_ALL] = 'Svar alle'
nb[keys.TEXT_COPY] = 'KOPI'

nb[keys.TEXT_ATTACHMENT_PLOT] = 'Festetomt'
nb[keys.TEXT_TOTAL_PLOT_VALUE] = 'Samlet tomteverdi'
nb[keys.TEXT_PROPERTY_VALUE_BEFORE_FACTORS] = 'Eiendomsverdi før faktorer'
nb[keys.TEXT_OFFICE_ADJUSTMENT] = 'Kontor justering'
nb[keys.TEXT_NOTIFICATION_SENT] = 'Varsel sendt'
nb[keys.TEXT_ROUTE] = 'Rute'
nb[keys.TEXT_BUILDING_CODE] = 'Bygningskoder'
nb[keys.TEXT_SEND_TAXATION_NOTICE] = 'Send varsel om synfaring'
nb[keys.TEXT_MODIFYING_FACTORS] = 'Modifiserende faktorer'

nb[keys.TEXT_UNASSIGN] = 'Fjern tildeling'
nb[keys.TEXT_MANUAL_VALUE] = 'Manuell verdi'
nb[keys.TEXT_SEND_BUSINESS_TAXATION_NOTICE] = 'Send varsel om taksering næring'
nb[keys.TEXT_SEND_BUSINESS_TAXATION_NOTICE2] = 'send varsel om besiktigelse'
nb[keys.TEXT_ATTRIBUTES] = 'Attributter'
nb[keys.TEXT_NO_ATTRIBUTES] = 'Ingen attributter'
nb[keys.TEXT_NEW_ATTRIBUTE] = 'Ny attributt'
nb[keys.TEXT_SAVE_NEW_ATTRIBUTE] = 'Lagre ny attributt'
nb[keys.TEXT_EDIT_ATTRIBUTE] = 'Rediger attributter'
nb[keys.TEXT_EXIT_EDIT_ATTRIBUTE] = 'Avslutt redigering'

nb[keys.TEXT_SEND_NEW_TAX] = 'Send ut ny takst'
nb[keys.TEXT_GROUPS] = 'grupper'
nb[keys.TEXT_SELECT_TENANT] = 'Velg tenant'

nb[keys.TEXT_DELETED] = 'Slettet'
nb[keys.TEXT_SEND_YEAR_TAX] = 'Send skatteseddel'
nb[keys.TEXT_YEAR_TAX_SENT] = 'Skattemelding sendt'
nb[keys.TEXT_YEAR_TAX_NOT_SENT] = 'Skattemelding ikke sendt'
nb[keys.TEXT_TAX_LETTER_PREVIEW] = 'Forhåndsvisning av skatteseddel'
nb[keys.TEXT_DETAILED_TAX_LETTER] = 'Send detaljert skatteseddel'
nb[keys.TEXT_RESIDENTIAL_UNITS] = 'Antall boenheter'
nb[keys.TEXT_FAILED_CORRESPONDENCE] = 'Mislykket korrespondanse'
nb[keys.TEXT_ISSUE] = 'Utgave'
nb[keys.TEXT_CALCULATE] = 'Bare re-kalkuler inkluderte eiendommer'
nb[keys.TEXT_MATRIKKEL_CHANGED] = 'Matrikkel endring'
