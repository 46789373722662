import React from 'react'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'
import { CubitTableColumn, CubitTableWrapperProps } from 'shared-components/src/cubit-table/cubit-table.types'
import { TEXT_NAME, TEXT_ADDRESS, TEXT_NUMBER } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { getOwnerDisplayName, getOwnerAddress } from 'models/owner'
import { SearchResult } from 'search-page/search-reducer'
import { useDispatch } from 'react-redux'
import { actionSearchLoadMore } from 'search-page/search-actions'
import LoadMore from 'common/load-more/load-more'

const ownersColumns: CubitTableColumn[] = [
    {
        headerLabel: tr(TEXT_NAME),
        key: 'name',
        getDisplayableElement: row => <span>{getOwnerDisplayName(row)}</span>,
    },
    {
        headerLabel: tr(TEXT_ADDRESS),
        key: 'address',
        getDisplayableElement: row => <span>{getOwnerAddress(row).addressText}</span>,
    },
    {
        headerLabel: tr(TEXT_NUMBER),
        key: 'number',
    },
]
const OwnersTableName = 'owners'

const OwnersTable: React.FC<CubitTableWrapperProps<SearchResult<any>>> = props => {
    const { results } = props
    const dispatch = useDispatch()
    const loadMore = () => dispatch(actionSearchLoadMore(OwnersTableName))

    return (
        <>
            <CubitTable sorting columns={ownersColumns} data={results.items} name={OwnersTableName} />
            <LoadMore count={results.items.length} totalCount={results.total} onLoadMore={() => loadMore()} />
        </>
    )
}

export { OwnersTable, OwnersTableName }
