import React from 'react'
import { change, Field } from 'redux-form'
import { Grid } from '@material-ui/core'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { Taxation } from 'models/taxation'
import { useDispatch, useSelector } from 'react-redux'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { get } from 'lodash'

type FactorOption = {
    name: string
    choice1?: string
    choice2?: string
    choice3?: string
    choice4?: string
    choice5?: string
}

const defaultFactors: any[] = [
    {
        label: '-',
        value: null,
    },
]

const getOtherStandardFactorOptions = (factor: any) =>
    defaultFactors.concat(
        Object.values(factor)
            .filter((value: any, index: number) => value && index !== 0)
            .map((value: any) => ({
                label: value,
                value: value,
            })),
    )

type OtherFactorsProps = {
    factorOptions: FactorOption[]
    editable: boolean
    formName: string
    buildingFieldName: string
    taxation?: Taxation
}
const OtherFactorsFields: React.FC<OtherFactorsProps> = (props) => {
    const { factorOptions, editable, formName, buildingFieldName, taxation } = props
    const dispatch = useDispatch()
    const factors = get(taxation, `${buildingFieldName}.otherStandardFactors`)

    return (
        <>
            {factorOptions.map((option: FactorOption, index: number) => {
                return (
                    (editable || !!factors[index]?.factor) && (
                        <Grid container spacing={2} alignItems="center" key={index}>
                            <Grid item xs>
                                <Field
                                    name={`${buildingFieldName}.otherStandardFactors[${index}].name`}
                                    type="hidden"
                                    format={(value: string, name: string) => {
                                        if (!value) {
                                            dispatch(change(formName, name, option.name))
                                        }
                                    }}
                                    component="input"
                                />
                                <Field
                                    name={`${buildingFieldName}.otherStandardFactors[${index}].factor`}
                                    label={option.name}
                                    options={getOtherStandardFactorOptions(option)}
                                    component={CubitSelectAdapter}
                                    valueIsObject={false}
                                    direction="row"
                                    disabled={!editable}
                                />
                            </Grid>
                        </Grid>
                    )
                )
            })}
        </>
    )
}

type TaxationOtherFactorsProps = {
    taxation: Taxation
    editable: boolean
    formName: string
    buildingFieldName: string
}

export const TaxationStandardOtherFactors: React.FC<TaxationOtherFactorsProps> = (props) => {
    const { editable, formName, buildingFieldName, taxation } = props
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)

    const otherFactors = settings && settings.otherFactors2 ? settings.otherFactors2 : []
    return (
        <>
            {otherFactors && (
                <OtherFactorsFields
                    factorOptions={otherFactors}
                    editable={editable}
                    formName={formName}
                    buildingFieldName={buildingFieldName}
                    taxation={taxation}
                />
            )}
        </>
    )
}
