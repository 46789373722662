import { ActionReducer, makeReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import { ACTION_ACTIVITY_LOG_LOAD_SUCCESS } from './activity-log-actions'
import { ActivityLog } from '../../models/activity-log'

export type ActivityLogState = { [key: string]: ActivityLog }

type ActivityLogReducer = ActionReducer<ActivityLogState>

const activityLogLoadSuccess: ActivityLogReducer = (state, action) => {
    state[action.objectId] = action.activityLog
    return state
}

const reducers: Reducers<ActivityLogState> = {
    [ACTION_ACTIVITY_LOG_LOAD_SUCCESS]: activityLogLoadSuccess,
}

export const activityLogReducer = makeReducer<ActivityLogState>(reducers, {})
