// NOTE! This is not template.
import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import { CancelOutlined, ShowChart } from '@material-ui/icons'
import { MapContext } from 'map'
import * as ol from 'ol'
import Draw from 'ol/interaction/Draw'
import * as Layer from 'ol/layer'
import OLVectorLayer from 'ol/layer/Vector'
import { Cluster, Vector } from 'ol/source'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { actionCubitTableSelect } from 'shared-components/src/cubit-table/cubit-table-actions'
import { PropertiesTableName } from 'search-page/properties-results/properties-table'

const createPolygonDraw = (
    source: Vector<any>,
    setActive: React.Dispatch<React.SetStateAction<boolean>>,
    map: ol.Map,
    dispatch: Dispatch<any>,
    selected: React.MutableRefObject<string[]>,
) => {
    const polygonDraw = new Draw({
        source: source,
        type: 'Polygon',
        condition: function (this: any, e) {
            if (e.originalEvent.buttons === 2) {
                this.removeLastPoint()
                return false
            } else {
                return true
            }
        },
    })
    polygonDraw.setActive(false)

    polygonDraw.on('drawend', (event) => {
        const polygon = event.feature.getGeometry()

        const featureLayer = polygonDraw
            .getMap()
            ?.getLayers()
            .getArray()
            .find((l) => l.get('source').features) as OLVectorLayer<Cluster>

        const features = featureLayer ? featureLayer.getSource()?.getFeatures() : undefined

        if (features && polygon) {
            const result = features
                .filter((f) => {
                    const geometry = f.getGeometry()
                    return geometry && polygon.intersectsExtent(geometry.getExtent())
                })
                .flatMap((f) => f.get('features'))
                .filter((f) => f.get('selectable'))
                .map((unit) => {
                    unit.set('selected', !unit.get('selected'))
                    return unit.get('unitId')
                })

            if (result.length > 0) {
                dispatch(actionCubitTableSelect('properties', [...selected.current, ...result]))
            }
        }

        setActive(false)
    })

    polygonDraw.on('change:active', (event) => {
        source.clear()
        event.oldValue ? map.removeInteraction(polygonDraw) : map.addInteraction(polygonDraw)
    })

    return polygonDraw
}

const PolygonSelectControl: React.FC = () => {
    const dispatch = useDispatch()
    const { map } = useContext(MapContext)
    const [active, setActive] = useState(false)
    const [polygonDraw, setPolygonDraw] = useState<Draw | undefined>(undefined)
    const selected = useSelector((state: any) =>
        state.table[PropertiesTableName] && state.table[PropertiesTableName].selection
            ? state.table[PropertiesTableName].selection
            : [],
    )
    const selectedRef = useRef<string[]>(selected)

    useEffect(() => {
        selectedRef.current = selected
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    useEffect(() => {
        if (!map) return

        const source = new Vector({ wrapX: false })
        const layer = new Layer.Vector({
            source,
        })

        map.addLayer(layer)

        let p = createPolygonDraw(source, setActive, map, dispatch, selectedRef)
        setPolygonDraw(p)

        return () => {
            map.removeLayer(layer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map])

    useEffect(() => {
        if (polygonDraw) {
            polygonDraw.setActive(active)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active])

    return <Button onClick={() => setActive(!active)}>{active ? <CancelOutlined /> : <ShowChart />}</Button>
}

export default PolygonSelectControl
