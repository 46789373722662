import React from 'react'
import { CubitTableHeaderProps } from 'shared-components/src/cubit-table/cubit-table.types'
import { tr } from 'utils/translations/translate'
import { TEXT_USE, TEXT_FLOOR, TEXT_ADD_FLOOR } from 'utils/translations/keys'
import { Floor } from 'models/floor'
import { Field, WrappedFieldArrayProps, change } from 'redux-form'
import { FormField } from 'shared-components/src/cubit-inputs/cubit-form-field.types'
import { InputType } from 'shared-components/src/cubit-inputs/input-type.enum'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { Table, TableBody, Grid, Button, makeStyles, Theme } from '@material-ui/core'
import { CubitTableHeader } from 'shared-components/src/cubit-table/cubit-table-header'
import tableStyles from 'shared-components/src/cubit-table/cubit-table.module.css'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { getFloorOptions, getSettingsFloorFactor, getSettingsUsageFactor, getUseOptions } from 'property-page/property-helper'

// H01    1.00    Hovedetasje 1
// H02    1.00    Hovedetasje 2
// H03    1.00    Hovedetasje 3
// H04    1.00    Hovedetasje 4
// H05    1.00    Hovedetasje 5
// H06    1.00    Hovedetasje 6
// K01    0.20    Kjeller
// K02    0.20    Kjeller 2
// L01    0.40    Loft
// L02    0.40    Loft 2
// U01    0.50    Underetasje, sokkel
// U02    0.50    Underetasje, sokkel 2

const floorNumber = (options: any): FormField<InputType.Select> => ({
    type: InputType.Select,
    props: {
        name: '',
        label: '',
        component: CubitSelectAdapter,
        valueIsObject: false,
        options: options,
    },
})

// 3000     Garasje    GA
// 4000     Industri, p-hus    IN
// 7000     Næringsformål    NE
// 9000     Boligformål    BO
// 12000    Leiligheter    LE
// 9000     Fritidsformål    FR
// 750      Plasthall    PH
// 0        Takoverbygg    TB

const usage = (options: any): FormField<InputType.Select> => ({
    type: InputType.Select,
    props: {
        name: '',
        label: '',
        component: CubitSelectAdapter,
        valueIsObject: false,
        options: options,
    },
})

const area = (): FormField<InputType.TextField> => ({
    type: InputType.TextField,
    props: {
        name: '',
        label: '',
        component: CubitTextFieldAdapter,
        type: 'number',
    },
})

const useStyles = makeStyles((theme: Theme) => {
    return {
        tableWrapper: {
            margin: '0 -16px',
            width: 'calc(100% + 48px)',
            maxWidth: 'calc(100% + 48px)',
            flexBasis: 'auto',
        },
    }
})

type TaxationPropertyBuildingFloorsProps = {
    form: string
}

export const TribunalTaxationBuildingFloors: React.FC<
    WrappedFieldArrayProps<Floor> & TaxationPropertyBuildingFloorsProps
> = props => {
    const { fields, form } = props
    const dispatch = useDispatch()
    const styles = useStyles()
    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)

    const tableHeaderProps: CubitTableHeaderProps = {
        columns: [
            {
                headerLabel: tr(TEXT_FLOOR),
                key: 'floorNumber',
            },
            {
                headerLabel: tr(TEXT_USE),
                key: 'usage',
            },
            {
                headerLabel: 'BRA',
                key: 'area',
            },
        ],
    }

    const handleFloorNumberChange = (e: any, formName: string, floorIdentifier: string) => {
        const floorNumber: string = e.target.value
        dispatch(change(formName, `${floorIdentifier}.usageFactor`, getSettingsFloorFactor(floorNumber, settings)))
    }

    const handleFloorUsageChange = (e: any, formName: string, floorIdentifier: string) => {
        const usage: string = e.target.value
        dispatch(change(formName, `${floorIdentifier}.pricePerM2`, getSettingsUsageFactor(usage, settings)))
    }

    const handleAddFloor = () => {
        const floor = {} as Floor
        fields.push(floor)
    }

    return fields.length > 0 ? (
        <Grid container>
            <Grid item xs={12} className={styles.tableWrapper}>
                <Table className="tablePadding24">
                    <CubitTableHeader {...tableHeaderProps} />
                    <TableBody>
                        {fields.map((field: any, index: number) => {
                            return (
                                <tr key={index} className={tableStyles.tableRow}>
                                    <td className={tableStyles.tableCell}>
                                        <Field
                                            {...floorNumber(getFloorOptions(settings)).props}
                                            name={`${field}.floorNumber`}
                                            onChange={(e: any) => handleFloorNumberChange(e, form, field)}
                                        />
                                    </td>
                                    <td className={tableStyles.tableCell}>
                                        <Field
                                            {...usage(getUseOptions(settings)).props}
                                            name={`${field}.usage`}
                                            onChange={(e: any) => handleFloorUsageChange(e, form, field)}
                                        />
                                    </td>
                                    <td className={tableStyles.tableCell}>
                                        <Field {...area().props} name={`${field}.area`} />
                                    </td>
                                </tr>
                            )
                        })}
                    </TableBody>
                </Table>

                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <Button variant="outlined" style={{ margin: '16px' }} onClick={handleAddFloor}>
                                {tr(TEXT_ADD_FLOOR)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <></>
    )
}
