import React from 'react'

import { reduxForm, InjectedFormProps } from 'redux-form'


type DetailsPanelFormProps = {
    onSubmit: any,
    initialValues: any,
    form: string
}


const Form: React.FC<InjectedFormProps<any, DetailsPanelFormProps> &
DetailsPanelFormProps> = props => {
    const { 
        handleSubmit,
    } = props  

    return (
        <form onSubmit={handleSubmit} style={{width: '100%'}}>
            {props.children}
        </form>
    )
}

export const PropertyDetailsPanelForm = reduxForm<{}, DetailsPanelFormProps>({
    destroyOnUnmount: true,
    enableReinitialize: true,
})(Form)


