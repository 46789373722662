import React from 'react'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { Grid, Button } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { useSelector, useDispatch } from 'react-redux'
import { isDirty, isSubmitting, reset, submit } from 'redux-form'
import { TaxFormName } from 'common/enums/form-name.enum'
import { AppState } from 'app/app-store'

type TemplatesToolbarProps = {
    title: string
    edit?: boolean
    addButton?: React.ReactElement,
    formName?: string
}
export const TemplatesToolbar: React.FC<TemplatesToolbarProps> = (props) => {
    const { 
        title,
        edit = false,
        addButton,
        formName = TaxFormName.TemplateForm
    } = props

    const dirty: boolean = useSelector((state: AppState) => isDirty(formName)(state))
    const submitting: boolean = useSelector((state: AppState) => isSubmitting(formName)(state))
    const dispatch = useDispatch()

    return (
        <TitleToolbar title={title}>
            {edit &&
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button
                            color="inherit"
                            type="button"
                            onClick={() => {
                                dispatch(reset(formName))
                            }}
                            disabled={!dirty}
                        >
                            {tr(t.TEXT_CANCEL)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="inherit"
                            type="button"
                            onClick={() => {
                                dispatch(submit(formName))
                            }}
                            disabled={!dirty || submitting}
                        >
                            {tr(t.TEXT_SAVE_CHANGES)}
                        </Button>
                    </Grid>
                </Grid>
            }
            {addButton &&
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        {addButton && addButton}
                    </Grid>
                </Grid>
            }
        </TitleToolbar>
    )
}
