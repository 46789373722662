import React, { useState, useMemo } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    makeStyles,
    Theme,
    IconButton,
    Divider,
    AccordionActions,
    Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { tr } from 'utils/translations/translate'
import {
    TEXT_MAP,
    TEXT_PLOT_INFORMATION,
    TEXT_CANCEL,
    TEXT_SAVE,
    TEXT_PRESENT_AT_TAXATION,
    TEXT_WHO,
    TEXT_NOBODY,
    TEXT_LEGAL_OWNER,
    TEXT_OTHERS,
} from 'utils/translations/keys'
import { mapPropertyVerifiedValues } from 'utils'
import { Edit } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { isInvalid, submit, reset, Field, change } from 'redux-form'
import { VerifiedCount } from 'common/verified-count/verified-count'
import { isTaxationEditingFinished, Taxation, TaxationState } from 'models/taxation'
import { IframeMap } from 'property-page/property-details/iframe-map'
import { TaxationPropertyLandDetails } from './property-taxation-land-edit'
import { useQuery } from 'utils/hooks/hooks'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { LEGAL_OWNER, NOBODY, OTHERS } from 'common/constants/constants'
import { CubitCheckboxGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { getPropertyMapCoords } from 'property-taxation-edit-page/helper-functions'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                maxWidth: '100%',
            },
        },

        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
            [theme.breakpoints.down('xs')]: {
                height: 'auto',
            },
        },
        summaryContainer: {
            [theme.breakpoints.down('xs')]: {
                flexWrap: 'nowrap',
            },
        },
        summaryContent: {
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                justifyContent: 'flex-start',
            },
        },
        panelContent__label: {
            fontWeight: 500,
        },

        cancelButton: {
            marginRight: theme.spacing(2),
        },
    }
})

type TaxationPropertyDetailsProps = {
    form: string
    taxation: Taxation
    expandedBuildingIndex: number | false
}

export const TaxationPropertyDetails: React.FC<TaxationPropertyDetailsProps> = (props) => {
    const { form, taxation, expandedBuildingIndex } = props

    const styles = useStyles()
    const dispatch = useDispatch()
    const printing = useQuery('printing')

    const isFormInvalid = useSelector(isInvalid(form))

    const [plotInfoPanelExpanded, setPlotInfoPanelExpanded] = useState(!!printing)
    const [mapPanelExpanded, setMapPanelExpanded] = useState(false)
    const [presentEvaluationPanelExpanded, setPresentEvaluationPanelExpanded] = useState(false)
    const [editingPlotInfo, setEditingPlotInfo] = useState(false)
    const [editingPresentEvaluation, setEditingPresentEvaluation] = useState(false)
    const property = mapPropertyVerifiedValues(taxation.current?.property)

    const mapCoords = getPropertyMapCoords(property.plots, taxation.current.buildings, expandedBuildingIndex)

    const lat = mapCoords?.lat
    const long = mapCoords?.long

    const Map = useMemo(() => (lat && long ? <IframeMap coords={{ lat, long }} /> : <></>), [lat, long])

    const handleEditPlotInformation = (e: React.MouseEvent) => {
        e.stopPropagation()
        setPlotInfoPanelExpanded(true)
        setEditingPlotInfo(true)
    }
    const handleEditPresentEvaluation = (e: React.MouseEvent) => {
        e.stopPropagation()
        setPresentEvaluationPanelExpanded(true)
        setEditingPresentEvaluation(true)
    }

    const handleCancelEditPlotInformation = () => {
        dispatch(reset(form))
        setPlotInfoPanelExpanded(false)
        setEditingPlotInfo(false)
    }
    const handleCancelEditPresentEvaluation = () => {
        dispatch(reset(form))
        setPresentEvaluationPanelExpanded(false)
        setEditingPresentEvaluation(false)
    }
    const handleSavePresentEvaluation = () => {
        dispatch(submit(form))
        setPresentEvaluationPanelExpanded(false)
        setEditingPresentEvaluation(false)
    }
    const handleSavePlotInformation = () => {
        dispatch(submit(form))
        setPlotInfoPanelExpanded(false)
        setEditingPlotInfo(false)
    }

    const handleParticipantSelectChange = () => {
        dispatch(change(form, 'participant.ownersIds', null))
        dispatch(change(form, 'participant.description', null))
    }
    const getParticipantTypeOptions = () => [
        { label: tr(TEXT_NOBODY), value: NOBODY },
        { label: tr(TEXT_LEGAL_OWNER), value: LEGAL_OWNER },
        { label: tr(TEXT_OTHERS), value: OTHERS },
    ]

    const verifiedValuesCount = property.verifiedInfo.count
    const verifiedValuesTotal = property.verifiedInfo.total
    return property ? (
        <>
            <Accordion expanded={mapPanelExpanded}>
                <AccordionSummary onClick={() => setMapPanelExpanded(!mapPanelExpanded)} expandIcon={<ExpandMoreIcon />} className={styles.panelSummary}>
                    {tr(TEXT_MAP)}
                </AccordionSummary>
                <AccordionDetails className={styles.panelContent}>{Map}</AccordionDetails>
            </Accordion>

            <Accordion expanded={presentEvaluationPanelExpanded}>
                <AccordionSummary
                    onClick={() => setPresentEvaluationPanelExpanded(!presentEvaluationPanelExpanded)}
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.panelSummary}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={styles.summaryContainer}
                    >
                        {tr(TEXT_PRESENT_AT_TAXATION)}

                        {editingPresentEvaluation || taxation.state === TaxationState.TribunalFinished || taxation.state === TaxationState.TaxationFinished ? (
                            <></>
                        ) : (
                            <Grid item>
                                <IconButton onClick={handleEditPresentEvaluation} color="inherit">
                                    <Edit />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={styles.panelContent}>
                    <Grid item sm={9} xs={12}>
                        <Field
                            name="participant.type"
                            options={getParticipantTypeOptions()}
                            component={CubitRadioGroupAdapter}
                            direction="row"
                            onChange={handleParticipantSelectChange}
                            disabled={!editingPresentEvaluation || taxation.state === TaxationState.TribunalFinished}
                        />
                    </Grid>

                    {taxation.participant && taxation.participant.type === LEGAL_OWNER && (
                        <>
                            <Grid item xs={3}>
                                {' '}
                            </Grid>

                            <Grid item xs={9}>
                                <Field
                                    name="participant.ownersIds"
                                    component={CubitCheckboxGroupAdapter}
                                    valueIsObject={false}
                                    options={taxation.current.owners.map((o) => ({
                                        label: o.name || `${o.firstName} ${o.lastName}` || o.email || o.number,
                                        value: o.id,
                                    }))}
                                    disabled={
                                        !editingPresentEvaluation || taxation.state === TaxationState.TribunalFinished
                                    }
                                />
                            </Grid>
                        </>
                    )}

                    {taxation.participant && taxation.participant.type === OTHERS && (
                        <>
                            <Grid item xs={3}>
                                {' '}
                            </Grid>

                            <Grid item xs={6}>
                                <Field
                                    name="participant.description"
                                    label={tr(TEXT_WHO)}
                                    component={CubitTextFieldAdapter}
                                    disabled={
                                        !editingPresentEvaluation || taxation.state === TaxationState.TribunalFinished
                                    }
                                />
                            </Grid>
                        </>
                    )}
                </AccordionDetails>
                {editingPresentEvaluation ? (
                    <>
                        <Divider />
                        <AccordionActions>
                            <Button onClick={handleCancelEditPresentEvaluation} className={styles.cancelButton}>
                                {tr(TEXT_CANCEL)}
                            </Button>
                            <Button
                                onClick={handleSavePresentEvaluation}
                                variant="contained"
                                color="primary"
                                disabled={isFormInvalid}
                            >
                                {tr(TEXT_SAVE)}
                            </Button>
                        </AccordionActions>
                    </>
                ) : null}
            </Accordion>

            <Accordion expanded={plotInfoPanelExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.panelSummary}
                    onClick={() => setPlotInfoPanelExpanded(!plotInfoPanelExpanded)}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={styles.summaryContainer}
                    >
                        <Grid item>
                            <Grid container alignItems="center" spacing={2} className={styles.summaryContent}>
                                <Grid item>{tr(TEXT_PLOT_INFORMATION)}</Grid>
                                <Grid item>
                                    <VerifiedCount
                                        style={{ marginLeft: '8px', fontSize: '14px' }}
                                        count={verifiedValuesCount}
                                        total={verifiedValuesTotal}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {editingPlotInfo || isTaxationEditingFinished(taxation) ? (
                            <></>
                        ) : (
                            <Grid item>
                                <IconButton onClick={handleEditPlotInformation} color="inherit">
                                    <Edit />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </AccordionSummary>

                <AccordionDetails className={styles.panelContent}>
                    {isEmpty(property) && taxation ? (
                        <></>
                    ) : (
                        <TaxationPropertyLandDetails
                            taxation={taxation}
                            plotSize={property.plotSize}
                            editable={editingPlotInfo}
                            formName={form}
                        />
                    )}
                </AccordionDetails>

                {editingPlotInfo ? (
                    <>
                        <Divider />
                        <AccordionActions>
                            <Button onClick={handleCancelEditPlotInformation} className={styles.cancelButton}>
                                {tr(TEXT_CANCEL)}
                            </Button>
                            <Button
                                onClick={handleSavePlotInformation}
                                variant="contained"
                                color="primary"
                                disabled={isFormInvalid}
                            >
                                {tr(TEXT_SAVE)}
                            </Button>
                        </AccordionActions>
                    </>
                ) : null}
            </Accordion>
        </>
    ) : null
}
