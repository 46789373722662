export enum InputType {
    TextField = 'textField',
    Checkbox = 'checkbox',
    DateRange = 'dateRange',
    Date = 'date',
    NumberRange = 'numberRange',
    Switch = 'switch',
    SliderRange = 'slider',
    Select = 'select',
    Radio = 'radio',
    SelectGroup = 'selectGroup',
}
