import React from 'react'

import { Field, arrayRemove, submit } from 'redux-form'
import { Grid, makeStyles, Theme, Divider, Box, Button, IconButton } from '@material-ui/core'
import {
    TEXT_PLOT_SIZE,
    TEXT_VERIFIED,
    TEXT_PLOT_TYPE,
    TEXT_YES,
    TEXT_NO,
    TEXT_NOTE,
    TEXT_ADD_PICTURE,
} from 'utils/translations/keys'
import { InputType } from 'shared-components/src/cubit-inputs/input-type.enum'
import { FormField } from 'shared-components/src/cubit-inputs/cubit-form-field.types'
import { tr } from 'utils/translations/translate'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { CubitCheckboxAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import { ValueContainer } from 'common/value-container/value-container'
import { formatArea } from 'shared-components/src/utils'
import { isTaxationEditingFinished, Taxation } from 'models/taxation'
import { YES, NO } from 'common/constants/constants'
import Dropzone from 'react-dropzone'
import { actionTaxationUploadFiles } from 'property-taxation-edit-page/taxation-actions'
import { useDispatch } from 'react-redux'
// @ts-ignore
import Lightbox from 'react-lightbox-component'
import { LightboxImage } from 'models/lightbox-image'
import { TaxationPropertyLocationFactors } from './property-taxation-locationFactor-fields'
import { TaxationLocationOtherFactors } from './property-taxation-locationFactor-otherFactors'
import { getPropertyValueTypeOptions } from 'utils/property/property-helper'
import { Clear, PhotoCamera } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
        },

        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },
        divider: {
            margin: '16px -16px',
        },

        cameraIcon: {
            marginRight: theme.spacing(1),
        },

        marginBottom: {
            marginBottom: theme.spacing(2),
        },
    }
})

const valueType = (): FormField<InputType.Select> => ({
    type: InputType.Select,
    props: {
        name: 'current.property.valueType',
        label: '',
        component: CubitSelectAdapter,
        valueIsObject: false,
        options: getPropertyValueTypeOptions(),
    },
})

export const getYesNoOptions = () => [
    { label: tr(TEXT_YES), value: YES },
    { label: tr(TEXT_NO), value: NO },
]

type TaxationPropertyLandDetailsProps = { taxation: Taxation; editable: boolean; plotSize: number; formName: string }

export const TaxationPropertyLandDetails: React.FC<TaxationPropertyLandDetailsProps> = props => {
    const { taxation, editable, plotSize, formName } = props

    const styles = useStyles()
    const dispatch = useDispatch()

    const currentLocationFactor = taxation.current.property.locationFactor
    const locationFactorHasBeenChanged = currentLocationFactor !== taxation.old.property.locationFactor

    const propertyImages: LightboxImage[] = taxation
        ? taxation.current.property.files.map((file: any) => ({
              src: file.url,
              title: ' ',
              description: ' ',
          }))
        : []

    const handleFileDrop = (acceptedFiles: any) => {
        dispatch(actionTaxationUploadFiles(formName, `current.property.files`, acceptedFiles))
    }

    const renderDropzoneInput = (field: any) => {
        return (
            <Grid container justifyContent="center">
                <Grid item>
                    <Dropzone
                        accept="image/*"
                        onDrop={(files: any) => {
                            handleFileDrop(files)
                            field.input.onChange(files)
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Box {...getRootProps()}>
                                        <Button 
                                            variant="outlined" 
                                            color="primary"
                                            disabled={isTaxationEditingFinished(taxation)}
                                        >
                                            <input {...getInputProps()} />
                                            <PhotoCamera className={styles.cameraIcon} />
                                            {tr(TEXT_ADD_PICTURE)}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Dropzone>
                </Grid>
                <Grid item xs={12} />
                <Grid item>{field.meta.error && <span className="error">{field.meta.error}</span>}</Grid>
            </Grid>
        )
    }

    const handleImageDelete = (e: React.MouseEvent, fileIndex: number) => {
        e.stopPropagation()
        dispatch(arrayRemove(formName, `current.property.files`, fileIndex))
        setTimeout(() => dispatch(submit(formName)), 0)
    }

    return (
        <div style={{ flexGrow: 1 }}>
            <Grid container spacing={2} alignItems="center">

                <Grid item lg={3} sm={6} xs={9}>
                    <Field
                        {...valueType().props}
                        valueIsObject={false}
                        disabled={!editable}
                        label={tr(TEXT_PLOT_TYPE)}
                    />
                </Grid>
                <Grid item xs>
                    <ValueContainer
                        label={tr(TEXT_VERIFIED)}
                        value={
                            <Field
                                name="current.property.verifiedValues.valueType"
                                component={CubitCheckboxAdapter}
                                disabled={isTaxationEditingFinished(taxation)}
                            />
                        }
                    ></ValueContainer>
                </Grid>
            </Grid>

            <Divider className={styles.divider}></Divider>

            <TaxationPropertyLocationFactors taxation={taxation} editable={editable} formName={formName} verifyable={true} />
            <TaxationLocationOtherFactors taxation={taxation} editable={editable} formName={formName} locationFactorHasBeenChanged={locationFactorHasBeenChanged} />

            <Divider className={styles.divider}></Divider>

            <Grid container spacing={2} alignItems="center">
                <Grid item sm={3} xs={6}>
                    {tr(TEXT_PLOT_SIZE)}
                </Grid>
                <Grid item sm xs={6}>
                    {formatArea(plotSize)}:
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="current.property.taxInfo.lotSizeComment"
                        component={CubitTextFieldAdapter}
                        type="text"
                        label={tr(TEXT_NOTE)}
                        multiline
                        disabled={!editable}
                    />
                </Grid>
            </Grid>

            <Divider className={styles.divider}></Divider>

            <Grid container spacing={2} className={styles.marginBottom}>
                <Grid item xs={12}>
                    <Lightbox
                        images={propertyImages}
                        thumbnailWidth="384px"
                        thumbnailHeight="216px"
                        renderImageFunc={(
                            idx: number,
                            image: LightboxImage,
                            toggleLightbox: any,
                            width: string,
                            height: string,
                        ) => {
                            return (
                                <div
                                    key={idx}
                                    className="lightbox-img-thumbnail  lightbox-img-thumbnail--cubit"
                                    style={{
                                        backgroundImage: `url(${image.src})`,
                                        width: width,
                                        height: height,
                                    }}
                                    onClick={toggleLightbox.bind(null, idx)}
                                >
                                    {!isTaxationEditingFinished(taxation) && (
                                        <div style={{ position: 'absolute', right: '6px', top: '6px' }}>
                                            <IconButton
                                                size="small"
                                                onClick={e => handleImageDelete(e, idx)}
                                                style={{ backgroundColor: 'rgba(255,255,255,0.26)' }}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            )
                        }}
                    />
                </Grid>

                <Grid container justifyContent="center">
                    <Grid item>
                        <Field name="files" component={renderDropzoneInput} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
