import React from 'react'
import { Grid, Typography, Divider } from '@material-ui/core'

type SettingsRowProps = {
    label?: string
    children?: any
    classes: any
    innerSection?: boolean
}
export const SettingsRow: React.FC<SettingsRowProps> = (props) => {
    const { label, children, classes, innerSection = false } = props
    return (
        <Grid container className={classes.settingsRow}>
            <Grid item xs={12}>
                {!innerSection && <Divider className={classes.innerDivider} />}
            </Grid>
            <Grid item sm={2} xs={12}>
                <Typography className={classes.settingsLabel}>{label}</Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
                {children}
            </Grid>
        </Grid>
    )
}
