import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { TEXT_ENABLE, TEXT_FILTERS } from '../utils/translations/keys'
import SearchFiltersChips from './search-filters-chips'
import AccordionActions from '@material-ui/core/AccordionActions'
import { Button, Grid } from '@material-ui/core'
import { getFormValues, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { actionSearchAll } from './search-actions'
import { AvailableFormFields } from '../cubit-inputs/cubit-form-field.types'
import { tr } from '../utils/translations/translate'
import { CubitForm, CubitFormProps } from '../cubit-form/cubit-form'

const useStyles = makeStyles((theme: Theme) => ({
    panel: {
        zIndex:1,
        marginBottom: theme.spacing(2),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        '&.Mui-expanded': {
            marginTop: 0,
        },
    },
    summaryExpanded: {
        '&.Mui-expanded': {
            margin: theme.spacing(1.5, 0),
        },
    },
    filterPanelTitle: {
        fontSize: theme.typography.h6.fontSize,
        marginRight: theme.spacing(2),
        display: 'inline-block',
        //center align finesse :
        lineHeight: `${theme.spacing(6)}px`,
        position: 'relative',
        top: '-2px',
    },
    form: { width: '100%' },
}))

const SearchFiltersPanelFormName = 'searchFiltersPanelFrom'

const FiltersPanelForm = reduxForm<{}, CubitFormProps>({
    form: SearchFiltersPanelFormName,
    destroyOnUnmount: false,
})(CubitForm)

type SearchFiltersPanelProps = {
    formFields: () => AvailableFormFields
}

const SearchFiltersPanel: React.FC<SearchFiltersPanelProps> = props => {
    const { formFields } = props

    const classes = useStyles()

    const searching = useSelector((state: any) => state.search.loading)
    const [panelExpanded, setPanelExpanded] = useState(false)

    const dispatch = useDispatch()
    const formState = useSelector(getFormValues(SearchFiltersPanelFormName))

    const handleSearch = () => {
        setPanelExpanded(false)
        dispatch(actionSearchAll())
    }
    
    useEffect(() => {
        if (searching) {
            setPanelExpanded(false)
        }
    }, [searching])

    return (
        <Accordion className={classes.panel} expanded={panelExpanded}>
            <AccordionSummary
                classes={{ content: classes.summaryExpanded }}
                expandIcon={<ExpandMoreIcon />}
                onClick={() => setPanelExpanded(!panelExpanded)}
            >
                <Grid container alignItems="center">
                    <Grid item>
                        <span className={classes.filterPanelTitle}>{tr(TEXT_FILTERS)}</span>
                    </Grid>
                    <SearchFiltersChips
                        formState={formState}
                        formFields={formFields()}
                        formName={SearchFiltersPanelFormName}
                    />
                </Grid>
            </AccordionSummary>

            <Divider />

            <AccordionDetails>
                <FiltersPanelForm fields={formFields} />
            </AccordionDetails>

            <Divider />

            <AccordionActions>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Button variant="outlined" color="primary" type="button" onClick={handleSearch}>
                            {tr(TEXT_ENABLE)}
                        </Button>
                    </Grid>
                </Grid>
            </AccordionActions>
        </Accordion>
    )
}

export { SearchFiltersPanel, SearchFiltersPanelFormName }
