import { Building } from 'models/building'
import { Property } from 'models/property'
import { isBuildingExempt } from 'property-page/property-helper'

export const getPropertyBuildingsValue = (property: Property, settings: any, buildings?: Building[]): number => {
    const propertyBuildings = buildings ? buildings : property.buildings

    if (!propertyBuildings || !propertyBuildings.length) {
        return 0
    }

    return propertyBuildings.reduce((acc: number, building) => {
        if (isBuildingExempt(building, settings)) {
            return 0
        }
        const standardFactor = building.standardFactor

        const valueBeforeFactors = building.floors.reduce((floorAcc: number, floor) => {
            if (floor.pricePerM2 === 0) {
                return floorAcc
            }
            const usageFactor = isNaN(floor.usageFactor) ? 1 : floor.usageFactor
            return floorAcc + usageFactor * Math.round(floor.area) * floor.pricePerM2
        }, 0)
        return acc + valueBeforeFactors * standardFactor // * property.zoneFactor * property.locationFactor
    }, 0)
}
