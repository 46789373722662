import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { useDispatch, useSelector } from 'react-redux'
import {
    actionCreateTemplate,
    actionUpdateTemplate,
    actionGetTemplate,
    actionGetTemplatesByType,
} from 'settings-page/settings-actions'
import { AppState } from '../app/app-store'
import { TemplatesToolbar } from './templates-toolbar'
import { RouteComponentProps } from '@reach/router'
import { DocumentTypes } from 'common/enums/document-types'
import { DocumentTemplate } from 'models/document-template'
import { CaseDocumentForm } from './case-document-edit-form'
import { TaxFormName } from 'common/enums/form-name.enum'

type DocumentTemplatesProps = {
    id: string
}

export const DocumentTemplateEdit: React.FC<RouteComponentProps<DocumentTemplatesProps>> = (props) => {
    const { id = '' } = props
    const dispatch = useDispatch()

    const documentTemplates: any = useSelector((state: AppState) => state.taxSettings[DocumentTypes.CASE_DOCUMENT])
    const textTemplates: any = useSelector((state: AppState) => state.taxSettings[DocumentTypes.TEXT])
    const pdfTemplates: any = useSelector((state: AppState) => state.taxSettings[DocumentTypes.LETTER])
    const template: any = Object.values(documentTemplates.templates).find((t: any) => t.id === id)

    const selectedTextTemplates = template && template.textIds && template.textIds.length && textTemplates.loaded ? Object.values(textTemplates.templates).filter((t: any) => template.textIds.indexOf(t.id) !== -1) : []

    const handleSubmit = (values: any) => {
        const saveObject: any = {
            ...values,
            textIds: values.textIds ? values.textIds.map((template: DocumentTemplate) => template.id) : [],
            documentType: DocumentTypes.CASE_DOCUMENT,
            body: null,
        }
        if (!template) {
            dispatch(actionCreateTemplate(saveObject))
        } else {
            dispatch(actionUpdateTemplate(saveObject))
        }
    }

    useEffect(() => {
        if (id !== 'new' && !template) {
            dispatch(actionGetTemplate(id))
        }
        if (!textTemplates.loaded && !textTemplates.loading) {
            dispatch(actionGetTemplatesByType(DocumentTypes.TEXT))
        }
        if (!pdfTemplates.loaded && !pdfTemplates.loading) {
            dispatch(actionGetTemplatesByType(DocumentTypes.LETTER))
        }
    }, [dispatch, id, template, textTemplates, pdfTemplates])

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={
                <TemplatesToolbar title={template?.name ? template.name : tr(t.TEXT_CASE_DOCUMENTS)} edit={true} formName={TaxFormName.CaseTemplateForm} />
            }
        >
            <CaseDocumentForm 
                onSubmit={handleSubmit} 
                initialValues={{...template, textIds: selectedTextTemplates}} 
                selectedTextTemplates={selectedTextTemplates} 
                allTextTemplates={Object.values(textTemplates.templates)}
                pdfTemplates={Object.values(pdfTemplates.templates)}
            />
        </LayoutDefault>
    )
}
