import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'

import Navigation from 'common/navigation/navigation'
import { useDispatch, useSelector } from 'react-redux'

import { tr } from 'utils/translations/translate'
import {
    TEXT_E_MAIL,
    TEXT_FINISHED_TAXATION,
    TEXT_READY_FOR_TRIBUNAL,
    TEXT_COMPLAINTS,
    TEXT_REJECTED_BY_THE_TRIBUNAL,
    TEXT_CASE_FLOW,
} from 'utils/translations/keys'
import { actionTribunalGroupsGetFull } from './tribunal-actions'
import { Grid, makeStyles, Theme, Card, CircularProgress } from '@material-ui/core'
import { AppState } from 'app/app-store'
import { filter } from 'lodash'
import { grey } from '@material-ui/core/colors'
import { navigate, RouteComponentProps } from '@reach/router'
import { TaxationState } from 'models/taxation'
import { TribunalGroupState } from './tribunal-group-page/tribunal-group-state.enum'
import { getTribunalGroupNameTranslation } from 'utils'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { ComplaintGroupLinks } from './complaints/complaint-group-links'
import { TribunalApprovedChip } from './tribunal-approved/tribunal-approved-chip'

export const useTribunalPageStyles = makeStyles((theme: Theme) => ({
    taxationCount: {
        color: theme.palette.primary.main,
    },

    category: {
        padding: theme.spacing(1, 2, 1, 3),
        marginBottom: theme.spacing(1),
        boxShadow: 'none',
    },

    categoryName: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 'bold',
        maxWidth: '220px',
        flexBasis: '220px',
    },

    group: {
        cursor: 'pointer',
        color: grey[700],

        border: '1px solid' + grey[400],
        padding: theme.spacing(0.5, 1.5),
        margin: theme.spacing(0.5, 1),

        '&:hover': {
            color: grey[900],
            border: '1px solid' + grey[600],
        },
    },
    groupName: {
        paddingTop: '1px',
        fontSize: theme.typography.subtitle1.fontSize,
    },
    groupItemCount: {
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.primary.main,
        fontWeight: 500,
        paddingLeft: theme.spacing(2),
    },
}))

export const TribunalPage: React.FC<RouteComponentProps> = () => {
    const styles = useTribunalPageStyles()
    const dispatch = useDispatch()



    const groups = useSelector((state: AppState) => state.tribunal.groups)
    const taxationsMap = useSelector((state: AppState) => state.tribunal.taxations)
    const loading = useSelector((state: AppState) => state.tribunal.loading)
    const loaded = useSelector((state: AppState) => state.tribunal.loaded)

    useEffect(() => {
        if (!loaded && !loading) {
            dispatch(actionTribunalGroupsGetFull())
        }
    }, [dispatch, loaded, loading])

    const groupsWithTaxations = filter(groups, g => !!(taxationsMap[g.id] && taxationsMap[g.id][0]))

    const tribunalInitialGroups = filter(
        groupsWithTaxations,
        g => taxationsMap[g.id][0].state === TaxationState.TaxationFinished,
    )

    const tribunalPlannedGroups = filter(
        groupsWithTaxations,
        g => taxationsMap[g.id].some(t => t.state === TaxationState.TribunalPlanned),
    )

    const tribunalStartedGroups = filter(
        groupsWithTaxations,
        g => taxationsMap[g.id].some(t => t.state === TaxationState.TribunalStarted),
    )

    const tribunalReadyForFinishGroups = filter(
        groupsWithTaxations,
        g => taxationsMap[g.id].some(t => t.state === TaxationState.TribunalReadyForFinishing),
    )

    const tribunalDeclinedGroup = filter(
        groupsWithTaxations,
        g => taxationsMap[g.id][0].state === TaxationState.TribunalDeclined,
    )[0]

    const handleGroupClick = (groupState: TribunalGroupState, groupId: string) => {
        navigate(`/tribunal/group/${groupState}/${groupId}`)
    }

    const getGroupTaxationCount = (groupId: string): number =>
        taxationsMap[groupId] ? taxationsMap[groupId].length : 0

    return (
        <LayoutDefault navigation={<Navigation />} pageTitle={tr(TEXT_CASE_FLOW)}>
            <Protected p={Permission.ViewTribunalAll}>
                <Card className={styles.category}>
                    <Grid container alignItems="center">
                        <Grid item xs className={styles.categoryName}>
                            {tr(TEXT_E_MAIL)}
                        </Grid>
                    </Grid>
                </Card>
            </Protected>
            {loading && <CircularProgress />}
            {!loading && <>
                <Protected p={Permission.ViewTribunalAll}>
                    <Card className={styles.category}>
                        <Grid container alignItems="center">
                            <Grid item xs className={styles.categoryName}>
                                {tr(TEXT_FINISHED_TAXATION)}
                            </Grid>
                            {tribunalInitialGroups.map((group, index) => (
                                <Grid
                                    key={index}
                                    item
                                    className={styles.group}
                                    onClick={() => handleGroupClick(TribunalGroupState.Initial, group.id)}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item className={styles.groupName}>
                                            {getTribunalGroupNameTranslation(group)}
                                        </Grid>
                                        <Grid item className={styles.groupItemCount}>
                                            {getGroupTaxationCount(group.id)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Card>
                </Protected>

                <Protected p={[Permission.ViewTribunalReady, Permission.ViewTribunalAll]}>
                    <Card className={styles.category}>
                        <Grid container alignItems="center">
                            <Grid item xs className={styles.categoryName}>
                                {tr(TEXT_READY_FOR_TRIBUNAL)}
                            </Grid>

                            <Protected p={Permission.ViewTribunalReady}>
                                {tribunalReadyForFinishGroups.map((group, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        className={styles.group}
                                        onClick={() => handleGroupClick(TribunalGroupState.ReadyForFinish, group.id)}
                                    >
                                        <Grid container alignItems="center">
                                            <Grid item className={styles.groupName}>
                                                {getTribunalGroupNameTranslation(group)}
                                            </Grid>
                                            <Grid item className={styles.groupItemCount}>
                                                {getGroupTaxationCount(group.id)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Protected>

                            {tribunalStartedGroups.map((group, index) => (
                                <Grid
                                    key={index}
                                    item
                                    className={styles.group}
                                    onClick={() => handleGroupClick(TribunalGroupState.Started, group.id)}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item className={styles.groupName}>
                                            {getTribunalGroupNameTranslation(group)}
                                        </Grid>
                                        <Grid item className={styles.groupItemCount}>
                                            {getGroupTaxationCount(group.id)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}

                            {tribunalPlannedGroups.map((group, index) => (
                                <Grid
                                    key={index}
                                    item
                                    className={styles.group}
                                    onClick={() => handleGroupClick(TribunalGroupState.Planned, group.id)}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item className={styles.groupName}>
                                            {getTribunalGroupNameTranslation(group)}
                                        </Grid>
                                        <Grid item className={styles.groupItemCount}>
                                            {getGroupTaxationCount(group.id)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Card>
                </Protected>

                <Protected p={Permission.ViewTribunalAll}>
                    <Card className={styles.category}>
                        <Grid container alignItems="center">
                            <Grid item xs className={styles.categoryName}>
                                {tr(TEXT_REJECTED_BY_THE_TRIBUNAL)}
                            </Grid>
                            {tribunalDeclinedGroup && (
                                <Grid
                                    item
                                    className={styles.group}
                                    onClick={() => handleGroupClick(TribunalGroupState.Declined, tribunalDeclinedGroup.id)}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item className={styles.groupName}>
                                            {getTribunalGroupNameTranslation(tribunalDeclinedGroup)}
                                        </Grid>
                                        <Grid item className={styles.groupItemCount}>
                                            {getGroupTaxationCount(tribunalDeclinedGroup.id)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Card>
                </Protected>
            </>}
            <TribunalApprovedChip />

            <Protected p={Permission.ViewTribunalAll}>
                <Card className={styles.category}>
                    <Grid container alignItems="center">
                        <Grid item xs className={styles.categoryName}>
                            {tr(TEXT_COMPLAINTS)}
                        </Grid>

                        <ComplaintGroupLinks />
                    </Grid>
                </Card>
            </Protected>
        </LayoutDefault>
    )
}
