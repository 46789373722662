import React from 'react'
import LoadMore from 'common/load-more/load-more'
import { SearchResult } from 'search-page/search-reducer'
import { CubitTableWrapperProps } from 'shared-components/src/cubit-table/cubit-table.types'
import { AppState } from 'app/app-store'
import { Property } from 'models/property'
import { useDispatch, useSelector } from 'react-redux'
import { actionSearchLoadMore } from 'search-page/search-actions'
import PropertiesTable, { PropertiesTableName } from './properties-table'

const PropertiesResults: React.FC<
    CubitTableWrapperProps<SearchResult<Property>> & {
        companies: any
        users: any
    }
> = (props) => {
    const { results, companies, users } = props
    const dispatch = useDispatch()
    const loading = useSelector((state: AppState) => state.search.loading)

    const loadMore = () => {
        if (!loading) {
            dispatch(actionSearchLoadMore(PropertiesTableName))
        }
    }

    return (
        <div style={{minWidth: "1000px"}}>
            <PropertiesTable searchResult={results} companies={companies} users={users} />
            <LoadMore count={results.items.length} totalCount={results.total} onLoadMore={() => loadMore()} />
        </div>
    )
}

export default PropertiesResults
