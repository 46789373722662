import { Taxation } from 'models/taxation'
import mapBuildingsVerifiedValues from './map-buildings-verified-values'
import mapPropertyVerifiedValues from './map-property-verified-values'
import produce from 'immer'
import { PROPERTY_VERIFIED_VALUES_TOTAL } from 'common/constants/constants'

export default (taxation: Taxation): Taxation => {
    const buildings = mapBuildingsVerifiedValues(taxation.current.buildings, taxation.deletedBuildings, taxation)
    const property = mapPropertyVerifiedValues(taxation.current.property)

    const verifiedBuildingsPropsCount = buildings.reduce((prev, curr) => prev + curr.verifiedInfo.count, 0)
    const totalVerifiableBuildingsProps = buildings.reduce((prev, curr) => prev + curr.verifiedInfo.total, 0)

    return produce(taxation, newTaxation => {
        newTaxation.current.buildings = buildings
        newTaxation.current.property = property

        newTaxation.current.verifiedInfo = {
            count: property.verifiedInfo.count + verifiedBuildingsPropsCount,
            total: PROPERTY_VERIFIED_VALUES_TOTAL + totalVerifiableBuildingsProps,
        }
    })
}
