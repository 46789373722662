import config from 'config'
import { en } from './languages/en'
import { nb } from './languages/nb'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { TEXT_NO, TEXT_YES } from './keys'

export const strings: any = {}

strings[Language.English] = en
strings[Language.Norwegian] = nb

const getLang = (): string => {
    const lang = localStorage.getItem(config.localStorageLangKey)
    return lang !== null ? lang : config.language
}

export const tr = (key: string, params?: Array<any>): string => {
    const lang = getLang()

    let translated = ''
    try {
        const translatedString = strings[lang][key] || ''
        // eslint-disable-next-line
        translated = eval('`' + translatedString + '`')
    } catch (e) {
        console.log('translation error', { key, params })
    }

    return translated
}

export const getYesNoBoolOptions = () => [
    { label: tr(TEXT_YES), value: 'true' },
    { label: tr(TEXT_NO), value: 'false' },
]
