import React from 'react'
import { Grid, FormControlLabel, Typography, InputAdornment } from '@material-ui/core'
import { Field, formValueSelector } from 'redux-form'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { getYesNoOptions } from 'property-taxation-edit-page/property-taxation-edit/property-taxation-land-edit'
import { SettingsRow } from './SettingsRow'
import { YES, PROPERTY_TYPE_OPTION_VALUES, REPORTS_SETTINGS_RESTRICTIONS } from 'common/constants/constants'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { CubitCheckboxGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { CubitCheckboxAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import {
    getYearValue,
    getPropertyTypeOptions1,
    getPropertyTypeOptions2,
    parsePermille,
    formatToPermille,
    getSelectNumberOptions,
    maxValue,
    customMinValue,
} from './helper-functions'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { getSelectYearOptions } from 'utils/form/search-form'
import { getZoneOptions } from 'settings-page/helper'

export const renderPanel = ({
    fields,
    meta: { touched, error, submitFailed },
    change,
    stateRef,
    classes,
    settingsIndex,
}: any) => {
    const selector = formValueSelector(TaxFormName.YearSettings)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const taxSettings = stateRef.taxSettings.taxSettings

    return (
        <>
            {fields.map((fieldName: string, index: number) => {
                const disabled: boolean = fields.get(index).closed
                if (settingsIndex !== index) {
                    return <span key={index}></span>
                }
                return (
                    <Grid container key={index} className={classes.formContainer}>
                        <Grid item xs={12}>
                            <Field
                                type="hidden"
                                name={`${fieldName}.${TaxFormFieldNames.year}`}
                                format={(value: string, name: string) => {
                                    if (!value) {
                                        change(name, getYearValue(fields, index))
                                    }
                                }}
                                component="input"
                            />
                            <SettingsRow
                                label={tr(t.TEXT_MUNICIPALITY_ADJUSTMENT)}
                                classes={classes}
                                innerSection={true}
                            >
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.municipalityAdjustment}`}
                                    component={CubitRadioGroupAdapter}
                                    options={getYesNoOptions()}
                                    direction="row"
                                    disabled={disabled}
                                />
                            </SettingsRow>
                            {getFieldValue(`${fieldName}.${TaxFormFieldNames.municipalityAdjustment}`) === YES && (
                                <SettingsRow
                                    label={tr(t.TEXT_ADJUSTMENT_PERCENTAGE)}
                                    classes={classes}
                                    innerSection={true}
                                >
                                    <Field
                                        name={`${fieldName}.${TaxFormFieldNames.municipalityAdjustmentValue}`}
                                        component={CubitTextFieldAdapter}
                                        className={classes.shortTextInput}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        disabled={disabled}
                                    />
                                </SettingsRow>
                            )}
                            <SettingsRow label={tr(t.TEXT_USE_NATIONAL_SETTINGS)} classes={classes}>
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.nationalValuation}`}
                                    component={CubitRadioGroupAdapter}
                                    options={getYesNoOptions()}
                                    direction="row"
                                    disabled={disabled}
                                />
                            </SettingsRow>
                            <SettingsRow label={tr(t.TEXT_MINIMUM_TAX)} classes={classes}>
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.minimumTax}`}
                                    component={CubitTextFieldAdapter}
                                    className={classes.shortTextInput}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                                    }}
                                    disabled={disabled}
                                />
                            </SettingsRow>
                            <SettingsRow label={tr(t.TEXT_PROPERTY_TYPE)} classes={classes}>
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.propertyType}`}
                                    component={CubitRadioGroupAdapter}
                                    options={getPropertyTypeOptions1()}
                                    direction="column"
                                    disabled={disabled}
                                />
                                {[
                                    PROPERTY_TYPE_OPTION_VALUES.B,
                                    PROPERTY_TYPE_OPTION_VALUES.E,
                                    PROPERTY_TYPE_OPTION_VALUES.F,
                                ].indexOf(getFieldValue(`${fieldName}.${TaxFormFieldNames.propertyType}`)) !== -1 && (
                                    <Field
                                        name={`${fieldName}.${TaxFormFieldNames.propertyTypeZones}`}
                                        component={CubitCheckboxGroupAdapter}
                                        valueIsObject={false}
                                        options={getZoneOptions(taxSettings)}
                                        disabled={disabled}
                                    />
                                )}
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.propertyType}`}
                                    component={CubitRadioGroupAdapter}
                                    options={getPropertyTypeOptions2()}
                                    direction="column"
                                    disabled={disabled}
                                />
                            </SettingsRow>
                            <SettingsRow label={tr(t.TEXT_RATES)} classes={classes}>
                                <FormControlLabel
                                    className={classes.fullWidthCheckbox}
                                    label={tr(t.TEXT_RATES_OPTION_1)}
                                    control={
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.differentRateForDwelling}`}
                                            component={CubitCheckboxAdapter}
                                            disabled={disabled}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    className={classes.fullWidthCheckbox}
                                    label={tr(t.TEXT_RATES_OPTION_2)}
                                    control={
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.differentRateWithoutBuildings}`}
                                            component={CubitCheckboxAdapter}
                                            disabled={disabled}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    className={classes.fullWidthCheckbox}
                                    label={tr(t.TEXT_RATES_OPTION_3)}
                                    control={
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.differentRateForLand}`}
                                            component={CubitCheckboxAdapter}
                                            disabled={disabled}
                                        />
                                    }
                                />
                                {getFieldValue(`${fieldName}.${TaxFormFieldNames.propertyType}`) ===
                                    PROPERTY_TYPE_OPTION_VALUES.B && (
                                    <FormControlLabel
                                        className={classes.fullWidthCheckbox}
                                        label={tr(t.TEXT_RATES_OPTION_4)}
                                        control={
                                            <Field
                                                name={`${fieldName}.${TaxFormFieldNames.differentRateForZones}`}
                                                component={CubitCheckboxAdapter}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                )}
                            </SettingsRow>
                            {getFieldValue(`${fieldName}.${TaxFormFieldNames.differentRateForZones}`) === true &&
                                [
                                    PROPERTY_TYPE_OPTION_VALUES.B,
                                    PROPERTY_TYPE_OPTION_VALUES.E,
                                    PROPERTY_TYPE_OPTION_VALUES.F,
                                ].indexOf(getFieldValue(`${fieldName}.${TaxFormFieldNames.propertyType}`)) !== -1 && (
                                    <SettingsRow label={tr(t.TEXT_TAX_PER_ZONE)} classes={classes} innerSection={true}>
                                        {getFieldValue(`${fieldName}.${TaxFormFieldNames.propertyTypeZones}`).map(
                                            (zone: any, i: number) => (
                                                <div key={i}>
                                                    <Field
                                                        label={`${zone}`}
                                                        name={`${fieldName}.${TaxFormFieldNames.zoneRates}[${zone}]`}
                                                        component={CubitTextFieldAdapter}
                                                        className={classes.shortTextInput}
                                                        format={formatToPermille}
                                                        parse={parsePermille}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">‰</InputAdornment>
                                                            ),
                                                        }}
                                                        disabled={disabled}
                                                    />
                                                </div>
                                            ),
                                        )}
                                    </SettingsRow>
                                )}
                            <SettingsRow label={tr(t.TEXT_DEFAULT_TAX_RATE)} classes={classes} innerSection={true}>
                                <Grid container>
                                    <Grid item>
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.defaultTaxRate}`}
                                            component={CubitTextFieldAdapter}
                                            className={classes.shortTextInput}
                                            format={formatToPermille}
                                            parse={parsePermille}
                                            normalize={maxValue(0.7)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">‰</InputAdornment>,
                                            }}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item className={classes.helperText}>
                                        <Typography>
                                            {tr(t.TEXT_BETWEEN_SOMETHING_AND, [
                                                `${REPORTS_SETTINGS_RESTRICTIONS.DEFAULT_TAX_RATE[0]}`,
                                                `${REPORTS_SETTINGS_RESTRICTIONS.DEFAULT_TAX_RATE[1]}‰`,
                                            ])}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </SettingsRow>
                            {getFieldValue(`${fieldName}.${TaxFormFieldNames.differentRateForDwelling}`) === true && (
                                <SettingsRow label={tr(t.TEXT_DWELLING_TAX_RATE)} classes={classes} innerSection={true}>
                                    <Grid container alignContent="flex-end">
                                        <Grid item>
                                            <Field
                                                name={`${fieldName}.${TaxFormFieldNames.dwellingTaxRate}`}
                                                component={CubitTextFieldAdapter}
                                                className={classes.shortTextInput}
                                                format={formatToPermille}
                                                parse={parsePermille}
                                                normalize={maxValue(0.5)}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">‰</InputAdornment>,
                                                }}
                                                disabled={disabled}
                                            />
                                        </Grid>
                                        <Grid item className={classes.helperText}>
                                            <Typography>
                                                {tr(t.TEXT_BETWEEN_SOMETHING_AND, [
                                                    `${REPORTS_SETTINGS_RESTRICTIONS.DWELLING_TAX_RATE[0]}`,
                                                    `${REPORTS_SETTINGS_RESTRICTIONS.DWELLING_TAX_RATE[1]}‰`,
                                                ])}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </SettingsRow>
                            )}
                            {getFieldValue(`${fieldName}.${TaxFormFieldNames.differentRateWithoutBuildings}`) ===
                                true && (
                                <>
                                    <SettingsRow
                                        label={tr(t.TEXT_PROPERTIES_WITHOUT_BUILDINGS)}
                                        classes={classes}
                                        innerSection={true}
                                    >
                                        <Grid container alignContent="flex-end">
                                            <Grid item>
                                                <Field
                                                    name={`${fieldName}.${TaxFormFieldNames.rateWithoutBuildings}`}
                                                    component={CubitTextFieldAdapter}
                                                    className={classes.shortTextInput}
                                                    format={formatToPermille}
                                                    parse={parsePermille}
                                                    normalize={maxValue(0.7)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">‰</InputAdornment>,
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                            <Grid item className={classes.helperText}>
                                                <Typography>
                                                    {tr(t.TEXT_BETWEEN_SOMETHING_AND, [
                                                        `${REPORTS_SETTINGS_RESTRICTIONS.PROPERTIES_WITHOUT_BUILDINGS_TAX_RATE[0]}`,
                                                        `${REPORTS_SETTINGS_RESTRICTIONS.PROPERTIES_WITHOUT_BUILDINGS_TAX_RATE[1]}‰`,
                                                    ])}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </SettingsRow>
                                    <SettingsRow
                                        label={tr(t.TEXT_PROPERTIES_WITH_BUILDINGS)}
                                        classes={classes}
                                        innerSection={true}
                                    >
                                        <Grid container>
                                            <Grid item>
                                                <Field
                                                    name={`${fieldName}.${TaxFormFieldNames.rateWithBuildings}`}
                                                    component={CubitTextFieldAdapter}
                                                    className={classes.shortTextInput}
                                                    format={formatToPermille}
                                                    parse={parsePermille}
                                                    normalize={maxValue(0.7)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">‰</InputAdornment>,
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                            <Grid item className={classes.helperText}>
                                                <Typography>
                                                    {tr(t.TEXT_BETWEEN_SOMETHING_AND, [
                                                        `${REPORTS_SETTINGS_RESTRICTIONS.PROPERTIES_WITH_BUILDINGS_TAX_RATE[0]}`,
                                                        `${REPORTS_SETTINGS_RESTRICTIONS.PROPERTIES_WITH_BUILDINGS_TAX_RATE[1]}‰`,
                                                    ])}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </SettingsRow>
                                </>
                            )}
                            {getFieldValue(`${fieldName}.${TaxFormFieldNames.differentRateForLand}`) === true && (
                                <>
                                    <SettingsRow label={tr(t.TEXT_LAND_TAX_RATE)} classes={classes} innerSection={true}>
                                        <Grid container>
                                            <Grid item>
                                                <Field
                                                    name={`${fieldName}.${TaxFormFieldNames.landRate}`}
                                                    component={CubitTextFieldAdapter}
                                                    className={classes.shortTextInput}
                                                    format={formatToPermille}
                                                    parse={parsePermille}
                                                    normalize={maxValue(0.7)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">‰</InputAdornment>,
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                            <Grid item className={classes.helperText}>
                                                <Typography>
                                                    {tr(t.TEXT_BETWEEN_SOMETHING_AND, [
                                                        `${REPORTS_SETTINGS_RESTRICTIONS.LAND_TAX_RATE[0]}`,
                                                        `${REPORTS_SETTINGS_RESTRICTIONS.LAND_TAX_RATE[1]}‰`,
                                                    ])}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </SettingsRow>
                                </>
                            )}
                            <SettingsRow label={tr(t.TEXT_REDUCTION_FACTOR)} classes={classes}>
                                <Grid container>
                                    <Grid item>
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.reductionFactor}`}
                                            component={CubitTextFieldAdapter}
                                            className={classes.shortTextInput}
                                            onBlur={(e: any) =>
                                                customMinValue(
                                                    30,
                                                    e,
                                                    change,
                                                    `${fieldName}.${TaxFormFieldNames.reductionFactor}`,
                                                )
                                            }
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item className={classes.helperText}>
                                        <Typography>
                                            {tr(t.TEXT_MINIMUM_SOMETHING, [
                                                `${REPORTS_SETTINGS_RESTRICTIONS.REDUCTION_FACTOR}%`,
                                            ])}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </SettingsRow>
                            <SettingsRow label={tr(t.TEXT_FLAT_REDUCTION)} classes={classes}>
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.useFlatReduction}`}
                                    component={CubitRadioGroupAdapter}
                                    options={getYesNoOptions()}
                                    direction="row"
                                    disabled={disabled}
                                />
                            </SettingsRow>
                            {getFieldValue(`${fieldName}.${TaxFormFieldNames.useFlatReduction}`) === YES && (
                                <SettingsRow
                                    label={tr(t.TEXT_FLAT_REDUCTION_VALUE)}
                                    classes={classes}
                                    innerSection={true}
                                >
                                    <Field
                                        name={`${fieldName}.${TaxFormFieldNames.flatReductionValue}`}
                                        component={CubitTextFieldAdapter}
                                        className={classes.shortTextInput}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                                        }}
                                        disabled={disabled}
                                    />
                                </SettingsRow>
                            )}
                            <SettingsRow label={tr(t.TEXT_SOMETHING_EXEMPTION, ['§7'])} classes={classes}>
                                <FormControlLabel
                                    className={classes.fullWidthCheckbox}
                                    label={tr(t.TEXT_EXEMPTION_OPTION_A)}
                                    control={
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.exemptionGovernmentBeneficial}`}
                                            component={CubitCheckboxAdapter}
                                            disabled={disabled}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    className={classes.fullWidthCheckbox}
                                    label={tr(t.TEXT_EXEMPTION_OPTION_B)}
                                    control={
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.exemptionHistoricValue}`}
                                            component={CubitCheckboxAdapter}
                                            disabled={disabled}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    className={classes.fullWidthCheckbox}
                                    label={tr(t.TEXT_EXEMPTION_OPTION_C)}
                                    control={
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.exemptionNewBuildings}`}
                                            component={CubitCheckboxAdapter}
                                            disabled={disabled}
                                        />
                                    }
                                />
                                {getFieldValue(`${fieldName}.${TaxFormFieldNames.exemptionNewBuildings}`) === true && (
                                    <FormControlLabel
                                        label={tr(t.TEXT_EXEMPTION)}
                                        control={
                                            <Field
                                                className={classes.smallSelect}
                                                name={`${fieldName}.${TaxFormFieldNames.exemptionNewBuildingsValue}`}
                                                component={CubitSelectAdapter}
                                                options={getSelectYearOptions(20)}
                                                valueIsObject={false}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                )}
                                <FormControlLabel
                                    className={classes.fullWidthCheckbox}
                                    label={tr(t.TEXT_EXEMPTION_OPTION_D)}
                                    control={
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.exemptionZones}`}
                                            component={CubitCheckboxAdapter}
                                            disabled={disabled}
                                        />
                                    }
                                />
                                {getFieldValue(`${fieldName}.${TaxFormFieldNames.exemptionZones}`) === true && (
                                    <div>
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.exemptZones}`}
                                            component={CubitCheckboxGroupAdapter}
                                            valueIsObject={false}
                                            options={getZoneOptions(taxSettings)}
                                            disabled={disabled}
                                        />
                                    </div>
                                )}
                                <FormControlLabel
                                    className={classes.fullWidthCheckbox}
                                    label={tr(t.TEXT_EXEMPTION_OPTION_E)}
                                    control={
                                        <Field
                                            name={`${fieldName}.${TaxFormFieldNames.exemptionSummerHouses}`}
                                            component={CubitCheckboxAdapter}
                                            disabled={disabled}
                                        />
                                    }
                                />
                            </SettingsRow>
                            <SettingsRow label={tr(t.TEXT_NUMBER_OF_TERMS)} classes={classes}>
                                <FormControlLabel
                                    label=""
                                    control={
                                        <Field
                                            className={classes.smallSelect}
                                            name={`${fieldName}.${TaxFormFieldNames.numberOfTerms}`}
                                            component={CubitSelectAdapter}
                                            options={getSelectNumberOptions(20)}
                                            valueIsObject={false}
                                            disabled={disabled}
                                        />
                                    }
                                />
                            </SettingsRow>
                        </Grid>
                    </Grid>
                )
            })}
        </>
    )
}
