import React from 'react'
import { SearchToolbarFrom } from 'search-page/search-toolbar/search-toolbar-form'
import { TaxSearchInput } from './tax-search-input'
import { actionSearchAll, setShowMap } from 'search-page/search-actions'
import { Grid } from '@material-ui/core'
import MapIcon from '@material-ui/icons/Map'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import { Suggestions } from 'shared-components/src/search-input/search-input-types'
import { AppState } from 'app/app-store'
import IconButton from '@material-ui/core/IconButton'

const SearchToolbar: React.FC = () => {
    const dispatch = useDispatch()
    const search = () => dispatch(actionSearchAll())
    const showMap: boolean = useSelector((state: AppState) => state.search.showMap)
    const map = (
        <IconButton color="inherit" onClick={() => dispatch(setShowMap(!showMap))}>
            {showMap ? <CloseIcon /> : <MapIcon />}
        </IconButton>
    )

    const suggestions: Suggestions = useSelector((state: AppState) => state.search.suggestions)
    const autocomplete: Suggestions = useSelector((state: AppState) => state.search.autocomplete)

    return (
        <SearchToolbarFrom onSubmit={search}>
            <Grid container>
                <Grid item xs={12}>
                    <TaxSearchInput suggestions={suggestions} autocomplete={autocomplete} map={map} search={search} />
                </Grid>
            </Grid>
        </SearchToolbarFrom>
    )
}

export default SearchToolbar
