import { Form, Field } from 'react-final-form'
import React, { useEffect, useRef, useState } from 'react'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import {
    TEXT_ADDRESS,
    TEXT_CANCEL,
    TEXT_DOCUMENT_RECEIVER,
    TEXT_EMAIL,
    TEXT_INVOICE_RECEIVER,
    TEXT_NAME,
    TEXT_PHONE_NUMBER,
    TEXT_REMOVE,
    TEXT_SAVE,
    TEXT_TITLE,
    TEXT_ZIP_CODE,
} from 'utils/translations/keys'
import { getYesNoBoolOptions, tr } from 'utils/translations/translate'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { CubitSwitchAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-switch-adapter'
import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles, Theme } from '@material-ui/core'
import { useDispatch} from 'react-redux'
import { actionPropertyDeleteOwnership, actionPropertyUpdateOwnership } from 'property-page/property-actions'
import { Owner } from 'models/owner'
const useStyles = makeStyles((theme: Theme) => ({
    field: {
        marginBottom: '5px',
        marginTop: '5px',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        maxWidth: '100%',
    },
}))
export const UpdateOwner: React.FC<{
    owners: any
    ownership: any
    handleClose: any
    open: boolean
    id: string
    propertyId: string
}> = ({ owners, ownership, handleClose, open, id, propertyId }) => {
    const dispatch = useDispatch()
    const formRef = useRef<any>(null)
    const owner = owners.find((o: any) => o.id === ownership.ownerId) || ({} as Owner)
    const [updateOwnerDialogOpen, setUpdateOwnerDialogOpen] = useState(false)
    const [isOwnerDelete, setIsOwnerDelete] = useState(false)
    const onSubmit = (values: any) => {
        if (isOwnerDelete) {
            dispatch(actionPropertyDeleteOwnership(values, propertyId))
        } else {
            dispatch(actionPropertyUpdateOwnership(values, propertyId))
        }
        handleClose()
    }

    const handleOwnerDelete = () => {
        setIsOwnerDelete(true)
    }
    useEffect(() => {
        if (open) {
            setUpdateOwnerDialogOpen(true)
        } else {
            setUpdateOwnerDialogOpen(false)
        }
    }, [open])

    const styles = useStyles()
    return (
        <Dialog open={updateOwnerDialogOpen}>
            <DialogTitle>
                <Grid container justifyContent="space-between">
                    <Grid item>{tr(TEXT_DOCUMENT_RECEIVER)}</Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ width: '480px' }}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        ownerId: owner.id,
                        title: owner.title,
                        name: owner.name,
                        addressText: owner?.postalAddress?.addressText,
                        postalCode: owner?.postalAddress?.postalCode,
                        email: owner.email,
                        phone: owner.phone,
                        isInvoiceRecipient: ownership.isInvoiceRecipient,
                        isDocumentRecipient: ownership.isDocumentRecipient,
                    }}
                    render={({ submitting, pristine, form, handleSubmit, values }) => {
                        formRef.current = form
                        return (
                            <form id={id} onSubmit={handleSubmit}>
                                <Field
                                    name={'title'}
                                    component={CubitTextFieldAdapter}
                                    type="text"
                                    label={tr(TEXT_TITLE)}
                                    multiline
                                    className={styles.field}
                                />
                                <Field
                                    name={'name'}
                                    component={CubitTextFieldAdapter}
                                    type="text"
                                    label={tr(TEXT_NAME)}
                                    multiline
                                    className={styles.field}
                                />
                                <Field
                                    name={'addressText'}
                                    component={CubitTextFieldAdapter}
                                    type="text"
                                    label={tr(TEXT_ADDRESS)}
                                    multiline
                                    className={styles.field}
                                />
                                <Field
                                    name={'postalCode'}
                                    component={CubitTextFieldAdapter}
                                    type="text"
                                    label={tr(TEXT_ZIP_CODE)}
                                    multiline
                                    className={styles.field}
                                />
                                <Field
                                    name={'email'}
                                    component={CubitTextFieldAdapter}
                                    type="text"
                                    label={tr(TEXT_EMAIL)}
                                    multiline
                                    className={styles.field}
                                />
                                <Field
                                    name={'phone'}
                                    component={CubitTextFieldAdapter}
                                    type="text"
                                    label={tr(TEXT_PHONE_NUMBER)}
                                    multiline
                                    className={styles.field}
                                />
                                <Grid item xs={6} className={styles.field}>
                                    {tr(TEXT_DOCUMENT_RECEIVER)}
                                </Grid>
                                <Field
                                    name={`${TaxFormFieldNames.isDocumentRecipient}`}
                                    component={CubitSwitchAdapter}
                                    options={getYesNoBoolOptions()}
                                    direction="row"
                                    className={styles.field}
                                />

                                <Grid item xs={6} className={styles.field}>
                                    {tr(TEXT_INVOICE_RECEIVER)}
                                </Grid>

                                <Field
                                    name={`${TaxFormFieldNames.isInvoiceRecipient}`}
                                    component={CubitSwitchAdapter}
                                    options={getYesNoBoolOptions()}
                                    direction="row"
                                />
                                <Grid container justifyContent="flex-end" item xs={6} className={styles.buttons}>
                                    <Button onClick={handleOwnerDelete} type="submit" color="inherit">
                                        {tr(TEXT_REMOVE)}
                                    </Button>
                                    <Button onClick={handleClose} color="inherit">
                                        {tr(TEXT_CANCEL)}
                                    </Button>
                                    <Button color="primary" type="submit" disabled={submitting || pristine} autoFocus>
                                        {tr(TEXT_SAVE)}
                                    </Button>
                                </Grid>
                            </form>
                        )
                    }}
                />
            </DialogContent>
        </Dialog>
    )
}
