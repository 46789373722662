import React from 'react'
import { useSelector } from 'react-redux'

import { DateTime } from 'luxon'
import { Language } from 'shared-components/src/settings/language/language.enum'

import { ChevronLeft } from '@material-ui/icons'
import { ChevronRight } from '@material-ui/icons'
import { Grid, IconButton } from '@material-ui/core'
import { CalendarView } from 'shared-components/src/cubit-calendar/calendar'

type CalendarDateSwitcherProps = {
    date: string
    calendarView: CalendarView
    onChange: (date: string) => void
}

export const CalendarDateSwitcher: React.FC<CalendarDateSwitcherProps> = props => {
    const { date, calendarView, onChange } = props
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    const dateObject = DateTime.fromJSDate(new Date(date)).setLocale(language)

    const handleDateChange = (direction: 'prev' | 'next', calendarVIew: CalendarView) => {
        const offsetByDirection = {
            prev: -1,
            next: 1,
        }

        const dateByView: { [key: string]: () => DateTime } = {
            [CalendarView.Day]: () => dateObject.plus({ day: offsetByDirection[direction] }),
            [CalendarView.Week]: () => dateObject.plus({ week: offsetByDirection[direction] }),
            [CalendarView.Month]: () => dateObject.plus({ month: offsetByDirection[direction] }).set({ day: 1 }),
            [CalendarView.List]: () => dateObject.plus({ week: offsetByDirection[direction] }),
        }
        const changedDate = dateByView[calendarVIew]()

        onChange(changedDate.toJSDate().toISOString())
    }

    return (
        <Grid container>
            <Grid item>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <IconButton size="small" onClick={() => handleDateChange('prev', calendarView)} color="inherit">
                            <ChevronLeft />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDateChange('next', calendarView)} color="inherit">
                            <ChevronRight />
                        </IconButton>
                    </Grid>
                    <Grid item style={{ textTransform: 'capitalize', fontSize: '22px' }}>
                        {dateObject.toFormat('MMMM yyyy')}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
