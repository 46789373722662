import { ComplaintGroupType } from 'tribunal-page/enums/complaint-group-type.enum'
import { Complaint } from 'tribunal-page/models/complaint.type'
import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import { ComplaintStatus } from 'tribunal-page/enums/complaint-status.enum'

const complaintGroupTypesByCauseType = {
    [ComplaintCauseType.PlotArea]: ComplaintGroupType.CadastralError,
    [ComplaintCauseType.PlotType]: ComplaintGroupType.CadastralError,
    [ComplaintCauseType.PlotLocation]: ComplaintGroupType.JudgmentalError,
    [ComplaintCauseType.BuildingStandard]: ComplaintGroupType.JudgmentalError,
    [ComplaintCauseType.BuildingArea]: ComplaintGroupType.CadastralError,
    [ComplaintCauseType.BuildingPhotos]: ComplaintGroupType.ValuationError,
    [ComplaintCauseType.Other]: ComplaintGroupType.Political,
}

export default (complaint: Complaint): ComplaintGroupType | undefined => {
    if (!complaint.causes || complaint.causes.length === 0) {
        return undefined
    }

    const complaintDeclined = complaint.status === ComplaintStatus.Declined
    const readyForTribunal = complaint.status === ComplaintStatus.ReadyForTribunal

    if (complaintDeclined || readyForTribunal) {
        return ComplaintGroupType.ComplaintBoard
    }

    return complaint.causes.length > 1
        ? ComplaintGroupType.Combined
        : complaintGroupTypesByCauseType[complaint.causes[0].type]
}
