export const TEXT_SAVE = 'TEXT_SAVE'
export const TEXT_CHANGES_SAVED = 'TEXT_CHANGES_SAVED'
export const TEXT_DASHBOARD = 'TEXT_DASHBOARD'
export const TEXT_FILTERS = 'TEXT_FILTERS'
export const TEXT_SEARCH = 'TEXT_SEARCH'
export const TEXT_LANGUAGE = 'TEXT_LANGUAGE'
export const TEXT_LOGOUT = 'TEXT_LOGOUT'
export const TEXT_OBJECT_TYPES = 'TEXT_OBJECT_TYPES'
export const TEXT_PLOT_TYPE = 'TEXT_PLOT_TYPE'
export const TEXT_HOUSE = 'TEXT_HOUSE'
export const TEXT_HOLIDAY_HOME = 'TEXT_HOLIDAY_HOME'
export const TEXT_LOGIN = 'TEXT_LOGIN'

export const TEXT_FROM = 'TEXT_FROM'
export const TEXT_TO = 'TEXT_TO'

export const TEXT_YES = 'TEXT_YES'
export const TEXT_NO = 'TEXT_NO'

export const TEXT_ZONE = 'TEXT_ZONE'
export const TEXT_ZONE0 = 'TEXT_ZONE0'
export const TEXT_ZONE1 = 'TEXT_ZONE1'
export const TEXT_ZONE2 = 'TEXT_ZONE2'
export const TEXT_ZONE3 = 'TEXT_ZONE3'
export const TEXT_ZONE4 = 'TEXT_ZONE4'
export const TEXT_ZONE5 = 'TEXT_ZONE5'
export const TEXT_ZONE6 = 'TEXT_ZONE6'

export const TEXT_ALL = 'TEXT_ALL'
export const TEXT_ME = 'TEXT_ME'

export const TEXT_STATUS = 'TEXT_STATUS'
export const TEXT_NO_STATUS = 'TEXT_NO_STATUS'
export const TEXT_PICKED = 'TEXT_PICKED'
export const TEXT_AWARDED = 'TEXT_AWARDED'
export const TEXT_ACTIVE_CASE = 'TEXT_ACTIVE_CASE'
export const TEXT_APPROVED_BY_TRIBUNAL = 'TEXT_APPROVED_BY_TRIBUNAL'
export const TEXT_COMPLAINT_RECEIVED = 'TEXT_COMPLAINT_RECEIVED'

export const TEXT_TAXATION = 'TEXT_TAXATION'
export const TEXT_NO_TAXATION = 'TEXT_NO_TAXATION'
export const TEXT_TAXATIONS = 'TEXT_TAXATIONS'
export const TEXT_TAXATION_SOURCE = 'TEXT_TAXATION_SOURCE'
export const TEXT_TAX_ADMINISTRATION = 'TEXT_TAX_ADMINISTRATION'

export const TEXT_PROPERTY_VALUE = 'TEXT_PROPERTY_VALUE'

export const TEXT_TAX_SUM = 'TEXT_TAX_SUM'

export const TEXT_MASS_REGISTRATION = 'TEXT_MASS_REGISTRATION'

export const TEXT_EXEMPTION = 'TEXT_EXEMPTION'
export const TEXT_NO_EXEMPTION = 'TEXT_NO_EXEMPTION'
export const TEXT_EXEMPTION_BY_REASON = 'TEXT_EXEMPTION_BY_REASON'

export const TEXT_IGANGSETTELSESTILATELSE = 'IGANGSETTELSESTILATELSE'
export const TEXT_YEARS_SINCE_STATUS_WAS_SET = 'TEXT_YEAR_SINCE_STATUS_WAS_SET'
export const TEXT_MATRIKKEL_STATUS = 'TEXT_MATRIKKEL_STATUS'
export const TEXT_STATUS_DATE = 'TEXT_STATUS_DATE'

export const TEXT_OWNER_CATEGORY = 'TEXT_OWNER_CATEGORY'

export const TEXT_MUNICIPALITY = 'TEXT_MUNICIPALITY'
export const TEXT_PUBLIC = 'TEXT_PUBLIC'
export const TEXT_PRIVATE = 'TEXT_PRIVATE'
export const TEXT_DEAD_OWNERS = 'TEXT_DEAD_OWNERS'

export const TEXT_STANDARD = 'TEXT_STANDARD'
export const TEXT_LOW = 'TEXT_LOW'
export const TEXT_NORMAL = 'TEXT_NORMAL'
export const TEXT_HIGH = 'TEXT_HIGH'

export const TEXT_LOCATION = 'TEXT_LOCATION'

export const TEXT_PROPERTY = 'TEXT_PROPERTY'
export const TEXT_PROPERTIES = 'TEXT_PROPERTIES'

export const TEXT_OWNERS = 'TEXT_OWNERS'
export const TEXT_ENABLE = 'TEXT_ENABLE'

export const TEXT_ASSIGN = 'TEXT_ASSIGN'
export const TEXT_ASSIGNED = 'TEXT_ASSIGNED'
export const TEXT_TAXATION_DATE = 'TEXT_TAXATION_DATE'
export const TEXT_DATE_FROM = 'TEXT_DATE_FROM'
export const TEXT_DATE_TO = 'TEXT_DATE_TO'
export const TEXT_SETTINGS = 'TEXT_SETTINGS'
export const TEXT_CLEAR_ALL_FILTERS = 'TEXT_CLEAR_ALL_FILTERS'

export const TEXT_NAME = 'TEXT_NAME'
export const TEXT_FIRST_NAME = 'TEXT_LAST_NAME'
export const TEXT_LAST_NAME = 'TEXT_LAST_NAME'
export const TEXT_ADDRESS = 'TEXT_ADDRESS'
export const TEXT_ADDRESSES = 'TEXT_ADDRESSES'
export const TEXT_NUMBER = 'TEXT_NUMBER'
export const TEXT_PHONE_NUMBER = 'TEXT_PHONE_NUMBER'
export const TEXT_E_MAIL = 'TEXT_E_MAIL'
export const TEXT_TAX = 'TEXT_TAX'
export const TEXT_TARIFF = 'TEXT_TARIFF'
export const TEXT_LAST_TARIFF = 'TEXT_LAST_TARIFF'
export const TEXT_APPRAISERS = 'TEXT_APPRAISERS'
export const TEXT_USE_OF_LAND = 'TEXT_USE_OF_LAND'
export const TEXT_USE = 'TEXT_USE'
export const TEXT_PERSONAL_NR = 'TEXT_PERSONAL_NR'

export const TEXT_REGISTER_NUMBERS = 'TEXT_REGISTER_NUMBERS'
export const TEXT_SELECTED = 'TEXT_SELECTED'
export const TEXT_SCHEDULE_TAXATION = 'TEXT_SCHEDULE_TAXATION'
export const TEXT_PARTICULARLY_FAVORABLE = 'TEXT_PARTICULARLY_FAVORABLE'
export const TEXT_PARTICULARLY_UNFAVORABLE = 'TEXT_PARTICULARLY_UNFAVORABLE'

export const TEXT_0457 = 'TEXT_0457'
export const TEXT_0777 = 'TEXT_0777'
export const TEXT_0778 = 'TEXT_0778'
export const TEXT_0779 = 'TEXT_0779'
export const TEXT_0780 = 'TEXT_0780'
export const TEXT_0781 = 'TEXT_0781'
export const TEXT_0782 = 'TEXT_0782'
export const TEXT_0783 = 'TEXT_0783'

export const TEXT_PROPERTY_INFORMATION = 'TEXT_PROPERTY_INFORMATION'
export const TEXT_PLOT_INFORMATION = 'TEXT_PLOT_INFORMATION'
export const TEXT_CADASTRE = 'TEXT_CADASTRE'
export const TEXT_VALUE_TYPE = 'TEXT_VALUE_TYPE'
export const TEXT_VALUE = 'TEXT_VALUE'
export const TEXT_M2_PRICE = 'TEXT_M2_PRICE'
export const TEXT_FLOOR_FACTOR = 'TEXT_FLOOR_FACTOR'
export const TEXT_FLOOR = 'TEXT_FLOOR'
export const TEXT_LAST_REVIEWED = 'TEXT_LAST_REVIEWED'
export const TEXT_MAP = 'TEXT_MAP'
export const TEXT_LAND_VALUE = 'TEXT_LAND_VALUE'
export const TEXT_BUILDING_VALUE = 'TEXT_BUILDING_VALUE'
export const TEXT_TOTAL_BUILDING_VALUE = 'TEXT_TOTAL_BUILDING_VALUE'
export const TEXT_TOTAL_BEFORE_FACTOR = 'TEXT_TOTAL_BEFORE_FACTOR'
export const TEXT_ZONE_FACTOR = 'TEXT_ZONE_FACTOR'
export const TEXT_LOCATION_FACTOR = 'TEXT_LOCATION_FACTOR'
export const TEXT_TOTAL_PROPERTY_VALUE = 'TEXT_TOTAL_PROPERTY_VALUE'
export const TEXT_EXCEPTION = 'TEXT_EXCEPTION'
export const TEXT_VALUE_PER_M2 = 'TEXT_VALUE_PER_M2'
export const TEXT_PLOT_SIZE = 'TEXT_PLOT_SIZE'
export const TEXT_TOTAL_LAND_VALUE = 'TEXT_TOTAL_LAND_VALUE'
export const TEXT_VALUE_BASED_LAYERS = 'TEXT_VALUE_BASED_LAYERS'
export const TEXT_ADD_NEW_COMMENT = 'TEXT_ADD_NEW_COMMENT'
export const TEXT_VIEW_ALL_COMMENTS_AND_NOTES = 'TEXT_VIEW_ALL_COMMENTS_AND_NOTES'
export const TEXT_STATE = 'TEXT_STATE'
export const TEXT_STANDARD_FACTOR = 'TEXT_STANDARD_FACTOR'
export const TEXT_ADD_TABLE = 'TEXT_ADD_TABLE'
export const TEXT_NOTE = 'TEXT_NOTE'
export const TEXT_CALENDAR = 'TEXT_CALENDAR'
export const TEXT_CALENDARS = 'TEXT_CALENDARS'
export const TEXT_LIST = 'TEXT_LIST'
export const TEXT_DAY = 'TEXT_DAY'
export const TEXT_WEEK = 'TEXT_WEEK'
export const TEXT_MONTH = 'TEXT_MONTH'
export const TEXT_REMOVE_EVENT = 'TEXT_REMOVE_EVENT'
export const TEXT_REMOVE_EVENTS = 'TEXT_REMOVE_EVENTS'
export const TEXT_CONFIRM_CHANGES = 'TEXT_CONFIRM_CHANGES'
export const TEXT_SEND_NOTICE = 'TEXT_SEND_NOTICE'
export const TEXT_TODAY = 'TEXT_TODAY'

export const TEXT_RESIDENTIAL_PROPERTY = 'TEXT_RESIDENTIAL_PROPERTY'
export const TEXT_LEISURE_PROPERTY = 'TEXT_LEISURE_PROPERTY'
export const TEXT_FARMING_FORESTRY_WITH_HOUSING = 'TEXT_FARMING_FORESTRY_WITH_HOUSING'
export const TEXT_FARM_USE_FORESTRY_WITH_LEISURE = 'TEXT_FARM_USE_FORESTRY_WITH_LEISURE'
export const TEXT_FARMS_OR_FORESTRY = 'TEXT_FARMS_OR_FORESTRY'
export const TEXT_NOT_SELECTED = 'TEXT_NOT_SELECTED'
export const TEXT_COMBINED_BUILT_PLOT = 'TEXT_COMBINED_BUILT_PLOT'
export const TEXT_COMMERCIAL = 'TEXT_COMMERCIAL'
export const TEXT_UNDEVELOPED_PLOT_HOUSING = 'TEXT_UNDEVELOPED_PLOT_HOUSING'
export const TEXT_UNDEVELOPED_PLOT_LEISURE = 'TEXT_UNDEVELOPED_PLOT_LEISURE'
export const TEXT_UNDEVELOPED_PLOT_INDUSTRY = 'TEXT_UNDEVELOPED_PLOT_INDUSTRY'
export const TEXT_UNDEVELOPED_PLOT = 'TEXT_UNDEVELOPED_PLOT'

export const TEXT_DIRECTIONS = 'TEXT_DIRECTIONS'
export const TEXT_START_TAXATION = 'TEXT_START_TAXATION'
export const TEXT_BILLING_ADDRESS = 'TEXT_BILLING_ADDRESS'
export const TEXT_ZIP_CODE = 'TEXT_ZIP_CODE'
export const TEXT_CANCEL = 'TEXT_CANCEL'
export const TEXT_ROLLBACK = 'TEXT_ROLLBACK'
export const TEXT_VERIFIED = 'TEXT_VERIFIED'
export const TEXT_UNITS = 'TEXT_UNITS'
export const TEXT_ADVANCED_DECAY_RESTORATION = 'TEXT_ADVANCED_DECAY_RESTORATION'
export const TEXT_DEMOLITION_OBJECT = 'TEXT_DEMOLITION_OBJECT'
export const TEXT_ADD_FLOOR = 'TEXT_ADD_FLOOR'
export const TEXT_ADD_BUILDING = 'TEXT_ADD_BUILDING'
export const TEXT_ADD_PICTURE = 'TEXT_ADD_PICTURE'
export const TEXT_OF = 'TEXT_OF'
export const TEXT_2_IMAGES_REQUIRED = 'TEXT_2_IMAGES_REQUIRED'
export const TEXT_FINISH_TAXATION = 'TEXT_FINISH_TAXATION'
export const TEXT_SHOW_WEEKENDS = 'TEXT_SHOW_WEEKENDS'
export const TEXT_BUSINESS = 'TEXT_BUSINESS'

export const TEXT_SCHEDULE_TAXATION_FOR = 'TEXT_SCHEDULE_TAXATION_FOR'
export const TEXT_SCHEDULED_TAXATION_FOR = 'TEXT_SCHEDULED_TAXATION_FOR'
export const TEXT_SELECT_FOR_TAXATION = 'TEXT_SELECT_FOR_TAXATION'
export const TEXT_COMPANIES = 'TEXT_COMPANIES'
export const TEXT_USERS = 'TEXT_USERS'
export const TEXT_USER = 'TEXT_USER'

export const TEXT_AREA = 'TEXT_AREA'
export const TEXT_SUN = 'TEXT_SUN'
export const TEXT_NOISE = 'TEXT_NOISE'
export const TEXT_VIEW = 'TEXT_VIEW'
export const TEXT_LIGHT_POLLUTION = 'TEXT_LIGHT_POLLUTION'
export const TEXT_AIR_POLLUTION = 'TEXT_AIR_POLLUTION'
export const TEXT_POLLUTION_REASON = 'TEXT_POLLUTION_REASON'
export const TEXT_FLOOD_AND_EXPOSED_AREA = 'TEXT_FLOOD_AND_EXPOSED_AREA'
export const TEXT_DIFFICULT_ACCESS_ROAD = 'TEXT_DIFFICULT_ACCESS_ROAD'
export const TEXT_ARCHITECTURE = 'TEXT_ARCHITECTURE'
export const TEXT_MAINTENANCE = 'TEXT_MAINTENANCE'
export const TEXT_OUTDOOR_AREA = 'TEXT_OUTDOOR_AREA'

export const TEXT_POSITIVE = 'TEXT_POSITIVE'
export const TEXT_NEGATIVE = 'TEXT_NEGATIVE'

export const TEXT_METRICS_STATUS = 'TEXT_METRICS_STATUS'
export const TEXT_BAD = 'TEXT_BAD'
export const TEXT_VERY_BAD = 'TEXT_VERY_BAD'

export const TEXT_PRESENT_AT_TAXATION = 'TEXT_PRESENT_AT_TAXATION'
export const TEXT_NOBODY = 'TEXT_NOBODY'
export const TEXT_LEGAL_OWNER = 'TEXT_LEGAL_OWNER'
export const TEXT_OTHERS = 'TEXT_OTHERS'
export const TEXT_TAXATION_INFORMATION = 'TEXT_TAXATION_INFORMATION'

export const TEXT_LAST_TAXATION = 'TEXT_LAST_TAXATION'
export const TEXT_NEW_TAXATION = 'TEXT_NEW_TAXATION'
export const TEXT_ERASE_BUILDING = 'TEXT_ERASE_BUILDING'

export const TEXT_WELCOME_TO = 'TEXT_WELCOME_TO'
export const TEXT_CUBIT_TAX = 'TEXT_CUBIT_TAX'
export const TEXT_PASSWORD = 'TEXT_PASSWORD'

export const TEXT_IMAGES = 'TEXT_IMAGES'

export const TEXT_COULD_NOT_TAXATE = 'TEXT_COULD_NOT_TAXATE'
export const TEXT_DID_NOT_GET_TAXATED = 'TEXT_DID_NOT_GET_TAXATED'

export const TEXT_TRIBUNAL = 'TEXT_TRIBUNAL'
export const TEXT_EDIT_GROUP = 'TEXT_EDIT_GROUP'
export const TEXT_GROUP_NAME = 'TEXT_GROUP_NAME'
export const TEXT_GROUP_DESCRIPTION = 'TEXT_GROUP_DESCRIPTION'
export const TEXT_APPROVED_TRIBUNAL = 'TEXT_APPROVED_TRIBUNAL'
export const TEXT_APPROVAL_DATE = 'TEXT_APPROVAL_DATE'
export const TEXT_NUMBER_OF_PROPERTIES = 'TEXT_NUMBER_OF_PROPERTIES'

export const TEXT_FACTOR_CHANGE = 'TEXT_FACTOR_CHANGE'
export const TEXT_METRIC_CHANGE = 'TEXT_METRIC_CHANGE'
export const TEXT_METRIC_CHANGES = 'TEXT_METRIC_CHANGES'
export const TEXT_NOTE_CHANGE = 'TEXT_NOTE_CHANGE'

export const TEXT_FILTER = 'TEXT_FILTER'
export const TEXT_ADD_TO_LIST = 'TEXT_ADD_TO_LIST'
export const TEXT_CREATE_NEW_GROUP = 'TEXT_CREATE_NEW_GROUP'

export const TEXT_ADDED_TO = 'TEXT_ADDED_TO'
export const TEXT_FINISHED_TAXATION = 'TEXT_FINISHED_TAXATION'
export const TEXT_READY_FOR_TRIBUNAL = 'TEXT_READY_FOR_TRIBUNAL'
export const TEXT_COMPLAINTS = 'TEXT_COMPLAINTS'

export const TEXT_INCOMPETENT = 'TEXT_INCOMPETENT'
export const TEXT_SET_AS_INCOMPETENT = 'TEXT_SET_AS_INCOMPETENT'
export const TEXT_REMOVE_AS_INCOMPETENT = 'TEXT_REMOVE_AS_INCOMPETENT'
export const TEXT_SET_AS_INCOMPETENT_FOR_OBJECT = 'TEXT_SET_AS_INCOMPETENT_FOR_OBJECT'
export const TEXT_START_TRIBUNAL = 'TEXT_START_TRIBUNAL'
export const TEXT_APPROVE_TRIBUNAL = 'TEXT_APPROVE_TRIBUNAL'
export const TEXT_APPROVE_TAXATION = 'TEXT_APPROVE_TAXATION'
export const TEXT_DECLINE_TAXATION = 'TEXT_DECLINE_TAXATION'
export const TEXT_DECLINED = 'TEXT_DECLINED'
export const TEXT_APPROVED = 'TEXT_APPROVED'

export const TEXT_HAS_COMMENT = 'TEXT_HAS_COMMENT'
export const TEXT_HAS_CHANGE = 'TEXT_HAS_CHANGE'
export const TEXT_HAS_METRICS_CHANGE = 'TEXT_HAS_METRICS_CHANGE'
export const TEXT_UNASSIGNED = 'TEXT_UNASSIGNED'
export const TEXT_REJECTED_BY_THE_TRIBUNAL = 'TEXT_REJECTED_BY_THE_TRIBUNAL'
export const TEXT_REASON_FOR_FAILED_TAXATION = 'TEXT_REASON_FOR_FAILED_TAXATION'

export const TEXT_PLANNED = 'TEXT_PLANNED'
export const TEXT_STARTED = 'TEXT_STARTED'
export const TEXT_COMPLETED = 'TEXT_COMPLETED'

export const TEXT_REOPEN = 'TEXT_REOPEN'
export const TEXT_FILTER_EVENTS = 'TEXT_FILTER_EVENTS'
export const TEXT_FILTER_TAXATIONS = 'TEXT_FILTER_TAXATIONS'
export const TEXT_REJECTED = 'TEXT_REJECTED'

export const TEXT_COMMENT_NOUN = 'TEXT_COMMENT_NOUN'
export const TEXT_COMMENTS = 'TEXT_COMMENTS'

export const TEXT_ACCESS = 'TEXT_ACCESS'
export const TEXT_ACCESSES = 'TEXT_ACCESSES'
export const TEXT_COMPANY = 'TEXT_COMPANY'
export const TEXT_ZONES = 'TEXT_ZONES'
export const TEXT_RIGHTS = 'TEXT_RIGHTS'
export const TEXT_ACTIVE = 'TEXT_ACTIVE'
export const TEXT_INACTIVE = 'TEXT_INACTIVE'
export const TEXT_SHOW_ACTIVE_USERS_ONLY = 'TEXT_SHOW_ACTIVE_USERS_ONLY'

export const TEXT_ADD_USER = 'TEXT_ADD_USER'
export const TEXT_REGISTER = 'TEXT_REGISTER'
export const TEXT_REPEAT_PASSWORD = 'TEXT_REPEAT_PASSWORD'
export const TEXT_PASSWORD_DOESNT_MATCH = 'TEXT_PASSWORD_DOESNT_MATCH'
export const TEXT_REQUIRED = 'TEXT_REQUIRED'
export const TEXT_MINIMUM_LENGTH = 'TEXT_MINIMUM_LENGTH'
export const TEXT_ADD_COMPANY = 'TEXT_ADD_COMPANY'
export const TEXT_EDIT_COMPANY = 'TEXT_EDIT_COMPANY'

export const TEXT_ADMINISTRATOR = 'TEXT_ADMINISTRATOR'
export const TEXT_TAXATIONS_ADMINISTRATOR = 'TEXT_TAXATIONS_ADMINISTRATOR'
export const TEXT_TRIBUNAL_ADMINISTRATOR = 'TEXT_TRIBUNAL_ADMINISTRATOR'
export const TEXT_TAXATIONS_USER = 'TEXT_TAXATIONS_USER'

export const TEXT_UNAUTHORIZED = 'TEXT_UNAUTHORIZED'
export const TEXT_FINISH_REVIEW = 'TEXT_FINISH_REVIEW'

export const TEXT_PREVIOUS = 'TEXT_PREVIOUS'
export const TEXT_NEXT = 'TEXT_NEXT'

export const TEXT_REGULATED = 'TEXT_REGULATED'

export const TEXT_USER_GROUPS = 'TEXT_USER_GROUPS'
export const TEXT_USER_GROUP = 'TEXT_USER_GROUP'
export const TEXT_NEW_USER_GROUP = 'TEXT_NEW_USER_GROUP'

export const TEXT_TYPE = 'TEXT_TYPE'
export const TEXT_EVALUATION_COMPANY = 'TEXT_EVALUATION_COMPANY'

export const TEXT_SHOWING = 'TEXT_SHOWING'
export const TEXT_LOAD_MORE = 'TEXT_LOAD_MORE'

export const TEXT_POLITICAL = 'TEXT_POLITICAL'
export const TEXT_CADASTRAL_ERROR = 'TEXT_CADASTRAL_ERROR'
export const TEXT_VALUATION_ERROR = 'TEXT_VALUATION_ERROR'
export const TEXT_JUDGMENTAL_ERRORS = 'TEXT_JUDGMENTAL_ERRORS'
export const TEXT_COMPLAINT_BOARD = 'TEXT_COMPLAINT_BOARD'
export const TEXT_COMBINED = 'TEXT_COMBINED'
export const TEXT_ALL_COMPLAINTS = 'TEXT_ALL_COMPLAINTS'

export const TEXT_CAUSE = 'TEXT_CAUSE'
export const TEXT_TAX_COMPLAINT_CAUSE_1 = 'TEXT_TAX_COMPLAINT_CAUSE_1'
export const TEXT_TAX_COMPLAINT_CAUSE_2 = 'TEXT_TAX_COMPLAINT_CAUSE_2'
export const TEXT_TAX_COMPLAINT_CAUSE_3 = 'TEXT_TAX_COMPLAINT_CAUSE_3'
export const TEXT_TAX_COMPLAINT_CAUSE_4 = 'TEXT_TAX_COMPLAINT_CAUSE_4'
export const TEXT_TAX_COMPLAINT_CAUSE_5 = 'TEXT_TAX_COMPLAINT_CAUSE_5'
export const TEXT_TAX_COMPLAINT_CAUSE_6 = 'TEXT_TAX_COMPLAINT_CAUSE_6'
export const TEXT_TAX_COMPLAINT_CAUSE_7 = 'TEXT_TAX_COMPLAINT_CAUSE_7'

export const TEXT_APPLIED = 'TEXT_APPLIED'
export const TEXT_COMPLETION = 'TEXT_COMPLETION'
export const TEXT_TEMPORARY_USE_PERMIT = 'TEXT_TEMPORARY_USE_PERMIT'
export const TEXT_PERMISSION_TO_START = 'TEXT_PERMISSION_TO_START'
export const TEXT_BUILDING_NUMBER_EXPIRED = 'TEXT_BUILDING_NUMBER_EXPIRED'
export const TEXT_NOTIFICATION_ACTION_COMPLETED = 'TEXT_NOTIFICATION_ACTION_COMPLETED'
export const TEXT_BUILDING_DEMOLISHED = 'TEXT_BUILDING_DEMOLISHED'
export const TEXT_NOTIFICATION_CASE_MEASURES = 'TEXT_NOTIFICATION_CASE_MEASURES'
export const TEXT_BUILDING_CANCELED = 'TEXT_BUILDING_CANCELED'
export const TEXT_BUILDING_APPROVED_FOR_DEMOLITION = 'TEXT_BUILDING_APPROVED_FOR_DEMOLITION'
export const TEXT_OUTLINE_PLANNING_PERMISSION = 'TEXT_OUTLINE_PLANNING_PERMISSION'
export const TEXT_EXEMPT_TO_APPLY = 'TEXT_EXEMPT_TO_APPLY'
export const TEXT_BUILDING_MOVED = 'TEXT_BUILDING_MOVED'
export const TEXT_MEASURES_EXEMPT = 'TEXT_MEASURES_EXEMPT'

export const TEXT_FARM_AND_FORESTRY = 'TEXT_FARM_AND_FORESTRY'
export const TEXT_BUILDING_7C = 'TEXT_BUILDING'
export const TEXT_HISTORIC_VALUE = 'TEXT_HISTORIC_VALUE'
export const TEXT_MUNICIPAL_PROPERTY = 'TEXT_MUNICIPAL_PROPERTY'
export const TEXT_STATE_PROPERTY = 'TEXT_STATE_PROPERTY'
export const TEXT_INSTITUTIONS = 'TEXT_INSTITUTIONS'
export const TEXT_HEALTH_INSTITUTIONS = 'TEXT_HEALTH_INSTITUTIONS'
export const TEXT_FIVE_H = 'TEXT_FIVE_H'
export const TEXT_CHURCHES = 'TEXT_CHURCHES'
export const TEXT_BUILDING_PERMIT = 'TEXT_BUILDING_PERMIT'
export const TEXT_RAILWAY_PROPERTIES = 'TEXT_RAILWAY_PROPERTIES'
export const TEXT_SEVEN_D = 'TEXT_SEVEN_D'
export const TEXT_SECTION_28 = 'TEXT_SECTION_28'
export const TEXT_FIVE_J = 'TEXT_FIVE_J'
export const TEXT_FIVE_K = 'TEXT_FIVE_K'
export const TEXT_COMPLAINT = 'TEXT_COMPLAINT'
export const TEXT_BUILDING_PHOTOS = 'TEXT_BUILDING_PHOTOS'
export const TEXT_BUILDING_PHOTOS_LABEL = 'TEXT_BUILDING_PHOTOS_LABEL'
export const TEXT_DECISION = 'TEXT_DECISION'

export const TEXT_SEND_TO_APPRAISER = 'TEXT_SEND_TO_APPRAISER'
export const TEXT_COMPLAINT_FOLLOWED = 'TEXT_COMPLAINT_FOLLOWED'
export const TEXT_COMPLAINT_NOT_FOLLOWED = 'TEXT_COMPLAINT_NOT_FOLLOWED'
export const TEXT_COMPLAINT_PARTIALLY_FOLLOWED = 'TEXT_COMPLAINT_PARTIALLY_FOLLOWED'
export const TEXT_COMMENT_DESCRIPTION = 'TEXT_COMMENT_DESCRIPTION'
export const TEXT_GROUNDS = 'TEXT_GROUNDS'
export const TEXT_DOCUMENTATION = 'TEXT_DOCUMENTATION'
export const TEXT_BUILDING = 'TEXT_BUILDING'
export const TEXT_TAXATION_PLOT_TYPE = 'TEXT_TAXATION_PLOT_TYPE'
export const TEXT_CORRECT_PLOT_TYPE = 'TEXT_CORRECT_PLOT_TYPE'
export const TEXT_ADJUSTED = 'TEXT_ADJUSTED'
export const TEXT_READJUSTED = 'TEXT_READJUSTED'
export const TEXT_DIFFERENCE = 'TEXT_DIFFERENCE'
export const TEXT_ORIGINAL_TAXATION = 'TEXT_ORIGINAL_TAXATION'
export const TEXT_UPDATED_TAXATION = 'TEXT_UPDATED_TAXATION'
export const TEXT_TAXATION_AREA = 'TEXT_TAXATION_AREA'
export const TEXT_CORRECTED_AREA = 'TEXT_CORRECTED_AREA'
export const TEXT_ADJUSTED_AREA = 'TEXT_ADJUSTED_AREA'

export const TEXT_REPORTS = 'TEXT_REPORTS'
export const TEXT_TAX_YEAR = 'TEXT_TAX_YEAR'
export const TEXT_EXPORT_TO_EXCEL = 'TEXT_EXPORT_TO_EXCEL'
export const TEXT_SIMULATION = 'TEXT_SIMULATION'
export const TEXT_RUN_SIMULATION = 'TEXT_RUN_SIMULATION'
export const TEXT_MUNICIPALITY_ADJUSTMENT = 'TEXT_MUNICIPALITY_ADJUSTMENT'
export const TEXT_ADJUSTMENT_PERCENTAGE = 'TEXT_ADJUSTMENT_PERCENTAGE'
export const TEXT_USE_NATIONAL_SETTINGS = 'TEXT_USE_NATIONAL_SETTINGS'
export const TEXT_MUNICIPALITY_OWNER = 'TEXT_MUNICIPALITY_OWNER'
export const TEXT_MINIMUM_TAX = 'TEXT_MINIMUM_TAX'
export const TEXT_PROPERTY_TYPE = 'TEXT_PROPERTY_TYPE'
export const TEXT_PROPERTY_TYPE_OPTION_A = 'TEXT_PROPERTY_TYPE_OPTION_A'
export const TEXT_PROPERTY_TYPE_OPTION_B = 'TEXT_PROPERTY_TYPE_OPTION_B'
export const TEXT_PROPERTY_TYPE_OPTION_C = 'TEXT_PROPERTY_TYPE_OPTION_C'
export const TEXT_PROPERTY_TYPE_OPTION_D = 'TEXT_PROPERTY_TYPE_OPTION_D'
export const TEXT_PROPERTY_TYPE_OPTION_E = 'TEXT_PROPERTY_TYPE_OPTION_E'
export const TEXT_PROPERTY_TYPE_OPTION_F = 'TEXT_PROPERTY_TYPE_OPTION_F'
export const TEXT_PROPERTY_TYPE_OPTION_G = 'TEXT_PROPERTY_TYPE_OPTION_G'
export const TEXT_RATES = 'TEXT_RATES'
export const TEXT_RATES_OPTION_1 = 'TEXT_RATES_OPTION_1'
export const TEXT_RATES_OPTION_2 = 'TEXT_RATES_OPTION_2'
export const TEXT_RATES_OPTION_3 = 'TEXT_RATES_OPTION_3'
export const TEXT_RATES_OPTION_4 = 'TEXT_RATES_OPTION_4'
export const TEXT_DEFAULT_TAX_RATE = 'TEXT_DEFAULT_TAX_RATE'
export const TEXT_TAX_PER_ZONE = 'TEXT_TAX_PER_ZONE'
export const TEXT_DWELLING_TAX_RATE = 'TEXT_DWELLING_TAX_RATE'
export const TEXT_PROPERTIES_WITH_BUILDINGS = 'TEXT_PROPERTIES_WITH_BUILDINGS'
export const TEXT_PROPERTIES_WITHOUT_BUILDINGS = 'TEXT_PROPERTIES_WITHOUT_BUILDINGS'
export const TEXT_BUILDINGS_TAX_RATE = 'TEXT_BUILDINGS_TAX_RATE'
export const TEXT_LAND_TAX_RATE = 'TEXT_LAND_TAX_RATE'
export const TEXT_BETWEEN_SOMETHING_AND = 'TEXT_BETWEEN_SOMETHING_AND'
export const TEXT_REDUCTION_FACTOR = 'TEXT_REDUCTION_FACTOR'
export const TEXT_FLAT_REDUCTION = 'TEXT_FLAT_REDUCTION'
export const TEXT_FLAT_REDUCTION_VALUE = 'TEXT_FLAT_REDUCTION_VALUE'
export const TEXT_MINIMUM_SOMETHING = 'TEXT_MINIMUM_SOMETHING'
export const TEXT_SOMETHING_EXEMPTION = 'TEXT_SOMETHING_EXEMPTION'
export const TEXT_EXEMPTION_OPTION_A = 'TEXT_EXEMPTION_OPTION_A'
export const TEXT_EXEMPTION_OPTION_B = 'TEXT_EXEMPTION_OPTION_B'
export const TEXT_EXEMPTION_OPTION_C = 'TEXT_EXEMPTION_OPTION_C'
export const TEXT_EXEMPTION_OPTION_D = 'TEXT_EXEMPTION_OPTION_D'
export const TEXT_EXEMPTION_OPTION_E = 'TEXT_EXEMPTION_OPTION_E'
export const TEXT_YEARS = 'TEXT_YEARS'
export const TEXT_REMOVE = 'TEXT_REMOVE'
export const TEXT_TAX_SETTINGS = 'TEXT_TAX_SETTINGS'
export const TEXT_ADD_USAGE_TYPE = 'TEXT_ADD_USAGE_TYPE'
export const TEXT_ADD_FACTOR = 'TEXT_ADD_FACTOR'
export const TEXT_FACTOR = 'TEXT_FACTOR'
export const TEXT_CHOICE = 'TEXT_CHOICE'
export const TEXT_FACTORS = 'TEXT_FACTORS'
export const TEXT_DONT_USE_FLOOR_FACTOR = 'TEXT_DONT_USE_FLOOR_FACTOR'
export const TEXT_BUILDING_USE_TYPE = 'TEXT_BUILDING_USE_TYPE'
export const TEXT_ERROR_OCCURRED = 'TEXT_ERROR_OCCURRED'
export const TEXT_MIN_BUILDING_AREA = 'TEXT_MIN_BUILDING_AREA'
export const TEXT_FARM = 'TEXT_FARM'
export const TEXT_PLOT_AS_DWELLING = 'TEXT_PLOT_AS_DWELLING'
export const TEXT_MAX_HOUSING_SIZE = 'TEXT_MAX_HOUSING_SIZE'
export const TEXT_ATTACHMENT_PLOT_VALUE = 'TEXT_ATTACHMENT_PLOT_VALUE'
export const TEXT_POINTLESS_SIZE = 'TEXT_POINTLESS_SIZE'
export const TEXT_UNKNOWN_PLOT_SIZE = 'TEXT_UNKNOWN_PLOT_SIZE'
export const TEXT_VALUE_PER_PARKING = 'TEXT_VALUE_PER_PARKING'
export const TEXT_NUMBER_OF_TERMS = 'TEXT_NUMBER_OF_TERMS'
export const TEXT_USE_FLOOR_FACTOR = 'TEXT_USE_FLOOR_FACTOR'
export const TEXT_USE_AREA_FACTOR = 'TEXT_USE_AREA_FACTOR'
export const TEXT_FROM_AREA = 'TEXT_FROM_AREA'
export const TEXT_TO_AREA = 'TEXT_TO_AREA'
export const TEXT_SAVE_CHANGES = 'TEXT_SAVE_CHANGES'
export const TEXT_COMPLETE_TAX_YEAR = 'TEXT_COMPLETE_TAX_YEAR'
export const TEXT_SETTINGS_CHANGE_COMMENT = 'TEXT_SETTINGS_CHANGE_COMMENT'
export const TEXT_SETTINGS_TAKE_EFFECT = 'TEXT_SETTINGS_TAKE_EFFECT'
export const TEXT_SAVE_TAXATION_SETTINGS = 'TEXT_SAVE_TAXATION_SETTINGS'
export const TEXT_VALID_FROM = 'TEXT_VALID_FROM'
export const TEXT_POWERPLANTS_AND_PETROLIUM = 'TEXT_POWERPLANTS_AND_PETROLIUM'
export const TEXT_BENEFICIAL_TO_MUNICIPALITY = 'TEXT_BENEFICIAL_TO_MUNICIPALITY'
export const TEXT_USED_FOR_PARKING = 'TEXT_USED_FOR_PARKING'
export const TEXT_PARKING_SPACES = 'TEXT_PARKING_SPACES'
export const TEXT_YEAR = 'TEXT_YEAR'
export const TEXT_MUNICIPALITY_ADJUSTED = 'TEXT_MUNICIPALITY_ADJUSTED'
export const TEXT_GENERAL_RATE = 'TEXT_GENERAL_RATE'
export const TEXT_OTHER_RATES = 'TEXT_OTHER_RATES'
export const TEXT_FLOOR_USE = 'TEXT_FLOOR_USE'
export const TEXT_HOUSING_TAX = 'TEXT_HOUSING_TAX'
export const TEXT_PERCENTAGE_SHARE = 'TEXT_PERCENTAGE_SHARE'
export const TEXT_PLOT = 'TEXT_PLOT'
export const TEXT_LIVING = 'TEXT_LIVING'
export const TEXT_ORDINARY = 'TEXT_ORDINARY'
export const TEXT_ORDINARY_NORMAL = 'TEXT_ORDINARY_NORMAL'
export const TEXT_TAX_RATE = 'TEXT_TAX_RATE'
export const TEXT_BUILDING_DELETED_UNDO = 'TEXT_BUILDING_DELETED_UNDO'
export const TEXT_LETTER_TEMPLATES = 'TEXT_LETTER_TEMPLATES'
export const TEXT_TEXTS = 'TEXT_TEXTS'
export const TEXT_CASE_DOCUMENTS = 'TEXT_CASE_DOCUMENTS'
export const TEXT_EMAILS = 'TEXT_EMAILS'
export const TEXT_SMS = 'TEXT_SMS'
export const TEXT_TEMPLATES = 'TEXT_TEMPLATES'
export const TEXT_TITLE = 'TEXT_TITLE'
export const TEXT_ADD_SMS = 'TEXT_ADD_SMS'
export const TEXT_ADD_EMAIL = 'TEXT_ADD_EMAIL'
export const TEXT_ADD_TEXT = 'TEXT_ADD_TEXT'
export const TEXT_ADD_CASE_DOCUMENT = 'TEXT_ADD_CASE_DOCUMENT'
export const TEXT_ADD_DOCUMENT_TEMPLATE = 'TEXT_ADD_DOCUMENT_TEMPLATE'
export const TEXT_FLOW = 'TEXT_FLOW'
export const TEXT_COMPANY2 = 'TEXT_COMPANY2'
export const TEXT_TEMPLATE = 'TEXT_TEMPLATE'
export const TEXT_DOCUMENT_TEMPLATES = 'TEXT_DOCUMENT_TEMPLATES'
export const TEXT_ERROR_HEADER = 'TEXT_ERROR_HEADER'
export const TEXT_ERROR_DESCRIPTION = 'TEXT_ERROR_DESCRIPTION'
export const TEXT_REMARK_HEADER = 'TEXT_REMARK_HEADER'
export const TEXT_REMARK_DESCRIPTION = 'TEXT_REMARK_DESCRIPTION'
export const TEXT_GENERAL_REMARK = 'TEXT_GENERAL_REMARK'
export const TEXT_TEFT_DESCRIPTION = 'TEXT_TEFT_DESCRIPTION'
export const TEXT_COPY_RECIPIENTS = 'TEXT_COPY_RECIPIENTS'
export const TEXT_CONTACT_DEFINED = 'TEXT_CONTACT_DEFINED'
export const TEXT_CONTACT_NOT_DEFINED = 'TEXT_CONTACT_NOT_DEFINED'
export const TEXT_TEMPLATE_TEXT = 'TEXT_TEMPLATE_TEXT'
export const TEXT_ALWAYS_AFTER = 'TEXT_ALWAYS_AFTER'
export const TEXT_ALWAYS_BEFORE = 'TEXT_ALWAYS_BEFORE'
export const TEXT_NOT_INSIDE = 'TEXT_NOT_INSIDE'

export const TEXT_GRID_OWNER_LOGO = 'TEXT_GRID_OWNER_LOGO'
export const TEXT_POST_ADDRESS = 'TEXT_POST_ADDRESS'
export const TEXT_HEADLINE = 'TEXT_HEADLINE'
export const TEXT_SUMMARY = 'TEXT_SUMMARY'
export const TEXT_RETURN_POST_ADDRESS = 'TEXT_RETURN_POST_ADDRESS'
export const TEXT_HORIZONTAL_LINE = 'TEXT_HORIZONTAL_LINE'
export const TEXT_DELETE_ELEMENT = 'TEXT_DELETE_ELEMENT'
export const TEXT_DESIGN_ELEMENT = 'TEXT_DESIGN_ELEMENT'
export const TEXT_HEIGHT_IN_MM = 'TEXT_HEIGHT_IN_MM'
export const TEXT_FOOTER_HEIGHT_IN_MM = 'TEXT_FOOTER_HEIGHT_IN_MM'
export const TEXT_OFFSET_TOP_IN_MM = 'TEXT_OFFSET_TOP_IN_MM'
export const TEXT_OFFSET_LEFT_IN_MM = 'TEXT_OFFSET_LEFT_IN_MM'
export const TEXT_WIDTH_IN_MM = 'TEXT_WIDTH_IN_MM'
export const TEXT_LETTER_CONTENT = 'TEXT_LETTER_CONTENT'
export const TEXT_PREVIEW = 'TEXT_PREVIEW'
export const TEXT_TEXT_ELEMENTS = 'TEXT_TEXT_ELEMENTS'

export const TEXT_BUILDING_BUILT = 'TEXT_BUILDING_BUILT'
export const TEXT_HAS_PARKING = 'TEXT_HAS_PARKING'
export const TEXT_VALUE_UNDER = 'TEXT_VALUE_UNDER'
export const TEXT_VALUE_OVER = 'TEXT_VALUE_OVER'
export const TEXT_FINISHED = 'TEXT_FINISHED'
export const TEXT_SENT_OUT = 'TEXT_SENT_OUT'
export const TEXT_OPEN_COMPLAINT = 'TEXT_OPEN_COMPLAINT'
export const TEXT_NO_COMPLAINTS_HEADLINE = 'TEXT_NO_COMPLAINTS_HEADLINE'
export const TEXT_DOCUMENT_RECEIVER = 'TEXT_DOCUMENT_RECEIVER'
export const TEXT_INVOICE_RECEIVER = 'TEXT_INVOICE_RECEIVER'
export const TEXT_ADD_DOCUMENT_RECEIVER = 'TEXT_ADD_DOCUMENT_RECEIVER'
export const TEXT_SEND_LAST_ASSESSMENT = 'TEXT_SEND_LAST_ASSESSMENT'
export const TEXT_COMMENT = 'TEXT_COMMENT'
export const TEXT_SEND_TO_NEMND = 'TEXT_SEND_TO_NEMND'
export const TEXT_CREATED = 'TEXT_CREATED'
export const TEXT_COMPLAINTS_TRIBUNAL = 'TEXT_COMPLAINTS_TRIBUNAL'
export const TEXT_DESCRIPTION = 'TEXT_DESCRIPTION'
export const TEXT_OFFICE_ADJUSTMENT_TAX = 'TEXT_OFFICE_ADJUSTMENT_TAX'
export const TEXT_TAXATION_TYPE = 'TEXT_TAXATION_TYPE'
export const TEXT_CHANGE = 'TEXT_CHANGE'
export const TEXT_UPLOAD_IMAGE = 'TEXT_UPLOAD_IMAGE'
export const TEXT_UPLOAD_DOCUMENT = 'TEXT_UPLOAD_DOCUMENT'
export const TEXT_UPLOAD = 'TEXT_UPLOAD'
export const TEXT_DOCUMENTS = 'TEXT_DOCUMENTS'

export const TEXT_VIEW_ONLY = 'TEXT_VIEW_ONLY'
export const TEXT_REPORTS_USER = 'TEXT_REPORTS_USER'
export const TEXT_TAXATION_PLANNER = 'TEXT_TAXATION_PLANNER'
export const TEXT_TAXATION_PEOPLE = 'TEXT_TAXATION_PEOPLE'
export const TEXT_NEMND_TRIBUNAL = 'TEXT_NEMND_TRIBUNAL'
export const TEXT_CASE_WORKER = 'TEXT_CASE_WORKER'
export const TEXT_MYSELF = 'TEXT_MYSELF'
export const TEXT_WHO = 'TEXT_WHO'
export const TEXT_TAXATION_METHOD = 'TEXT_TAXATION_METHOD'
export const TEXT_TAXATION_CASE_WORKER = 'TEXT_TAXATION_CASE_WORKER'
export const TEXT_INSECTION_DATE = 'TEXT_INSECTION_DATE'
export const TEXT_SENT_BY = 'TEXT_SENT_BY'
export const TEXT_EMAIL = 'TEXT_EMAIL'
export const TEXT_LETTER = 'TEXT_LETTER'
export const TEXT_CASE_FLOW = 'TEXT_CASE_FLOW'
export const TEXT_NOTICE = 'TEXT_NOTICE'
export const TEXT_CHANNEL = 'TEXT_CHANNEL'
export const TEXT_PAPER_LETTER = 'TEXT_PAPER_LETTER'
export const TEXT_TRIGGER = 'TEXT_TRIGGER'
export const TEXT_CASE_DOCUMENT = 'TEXT_CASE_DOCUMENT'
export const TEXT_DAYS = 'TEXT_DAYS'

export const TEXT_UNTIL_TAXATION = 'TEXT_UNTIL_TAXATION'
export const TEXT_AFTER_APPROVED_BY_TRIBUNAL = 'TEXT_AFTER_APPROVED_BY_TRIBUNAL'
export const TEXT_AFTER_COMPLAINT_RECEIVED = 'TEXT_AFTER_COMPLAINT_RECEIVED'
export const TEXT_AFTER_COMPLAINT_APPROVED = 'TEXT_AFTER_COMPLAINT_APPROVED'
export const TEXT_AFTER_COMPLAINT_REJECTED = 'TEXT_AFTER_COMPLAINT_REJECTED'
export const TEXT_AFTER_NEW_TAX = 'TEXT_AFTER_NEW_TAX'
export const TEXT_AFTER_OWNERSHIP_CHANGE = 'TEXT_AFTER_OWNERSHIP_CHANGE'
export const TEXT_AFTER_APPLICATION_RECEIVED = 'TEXT_AFTER_APPLICATION_RECEIVED'

export const TEXT_COMMUNICATION = 'TEXT_COMMUNICATION'
export const TEXT_RECIPIENT = 'TEXT_RECIPIENT'
export const TEXT_SEND_EMAIL = 'TEXT_SEND_EMAIL'
export const TEXT_SEND_SMS = 'TEXT_SEND_SMS'
export const TEXT_SEND_LETTER = 'TEXT_SEND_LETTER'
export const TEXT_ADD_RECIPIENT_EMAIL = 'TEXT_ADD_RECIPIENT_EMAIL'
export const TEXT_ADD_RECIPIENT_PHONENUMBER = 'TEXT_ADD_RECIPIENT_PHONENUMBER'

export const TEXT_COPY_RECIPIENT = 'TEXT_COPY_RECIPIENT'
export const TEXT_SENT = 'TEXT_SENT'
export const TEXT_SENDER = 'TEXT_SENDER'
export const TEXT_SUBJECT = 'TEXT_SUBJECT'
export const TEXT_FORWARDED = 'TEXT_FORWARDED'
export const TEXT_WROTE = 'TEXT_WROTE'
export const TEXT_REPLY = 'TEXT_REPLY'
export const TEXT_ATTACH_FILE_FROM_COMPUTER = 'TEXT_ATTACH_FILE_FROM_COMPUTER'
export const TEXT_ATTACH_CASE_DOCUMENT = 'TEXT_ATTACH_CASE_DOCUMENT'
export const TEXT_SEARCH_FOR_LETTERS = 'TEXT_SEARCH_FOR_LETTERS'
export const TEXT_INBOUND = 'TEXT_INBOUND'
export const TEXT_OUTBOUND = 'TEXT_OUTBOUND'

export const TEXT_NEW_EMAIL = 'TEXT_NEW_EMAIL'
export const TEXT_NEW_SMS = 'TEXT_NEW_SMS'
export const TEXT_NEW_LETTER = 'TEXT_NEW_LETTER'
export const TEXT_REGISTER_PHONECALL = 'TEXT_REGISTER_PHONECALL'
export const TEXT_NEW_COMMUNICATION = 'TEXT_NEW_COMMUNICATION'
export const TEXT_FORWARD = 'TEXT_FORWARD'
export const TEXT_REPLY_ALL = 'TEXT_REPLY_ALL'
export const TEXT_COPY = 'TEXT_COPY'

export const TEXT_ATTACHMENT_PLOT = 'TEXT_ATTACHMENT_PLOT'
export const TEXT_TOTAL_PLOT_VALUE = 'TEXT_TOTAL_PLOT_VALUE'
export const TEXT_PROPERTY_VALUE_BEFORE_FACTORS = 'TEXT_PROPERTY_VALUE_BEFORE_FACTORS'
export const TEXT_OFFICE_ADJUSTMENT = 'TEXT_OFFICE_ADJUSTMENT'
export const TEXT_NOTIFICATION_SENT = 'TEXT_NOTIFICATION_SENT'
export const TEXT_ROUTE = 'TEXT_ROUTE'
export const TEXT_BUILDING_CODE = 'TEXT_BUILDING_CODE'
export const TEXT_SEND_TAXATION_NOTICE = 'TEXT_SEND_TAXATION_NOTICE'
export const TEXT_SEND_BUSINESS_TAXATION_NOTICE = 'TEXT_SEND_BUSINESS_TAXATION_NOTICE'
export const TEXT_SEND_BUSINESS_TAXATION_NOTICE2 = 'TEXT_SEND_BUSINESS_TAXATION_NOTICE2'
export const TEXT_MODIFYING_FACTORS = 'TEXT_MODIFYING_FACTORS'
export const TEXT_BILLING = 'TEXT_BILLING'

export const TEXT_USES_NATIONAL_SETTINGS = 'TEXT_USES_NATIONAL_SETTINGS'
export const TEXT_DOES_NOT_USE_NATIONAL_SETTINGS = 'TEXT_DOES_NOT_USE_NATIONAL_SETTINGS'

export const TEXT_UNASSIGN = 'TEXT_UNASSIGN'
export const TEXT_MANUAL_VALUE = 'TEXT_MANUAL_VALUE'
export const TEXT_ATTRIBUTES = 'TEXT_ATTRIBUTES'
export const TEXT_NO_ATTRIBUTES = 'TEXT_NO_ATTRIBUTES'
export const TEXT_NEW_ATTRIBUTE = 'TEXT_NEW_ATTRIBUTE'
export const TEXT_SAVE_NEW_ATTRIBUTE = 'TEXT_SAVE_NEW_ATTRIBUTE'
export const TEXT_EDIT_ATTRIBUTE = 'TEXT_EDIT_ATTRIBUTE'
export const TEXT_EXIT_EDIT_ATTRIBUTE = 'TEXT_EXIT_EDIT_ATTRIBUTE'

export const TEXT_SEND_NEW_TAX = 'TEXT_SEND_NEW_TAX'
export const TEXT_GROUPS = 'TEXT_GROUPS'
export const TEXT_SELECT_TENANT = 'TEXT_SELECT_TENANT'

export const TEXT_DELETED = 'TEXT_DELETED'
export const TEXT_SEND_YEAR_TAX = 'TEXT_SEND_YEAR_TAX'
export const TEXT_YEAR_TAX_SENT = 'TEXT_YEAR_TAX_SENT'
export const TEXT_YEAR_TAX_NOT_SENT = 'TEXT_YEAR_TAX_NOT_SENT'
export const TEXT_TAX_LETTER_PREVIEW = 'TEXT_TAX_LETTER_PREVIEW'
export const TEXT_DETAILED_TAX_LETTER = 'TEXT_DETAILED_TAX_LETTER'
export const TEXT_RESIDENTIAL_UNITS = 'TEXT_RESIDENTIAL_UNITS'
export const TEXT_FAILED_CORRESPONDENCE = 'TEXT_FAILED_CORRESPONDENCE'
export const TEXT_ISSUE = 'TEXT_ISSUE'
export const TEXT_CALCULATE = 'TEXT_CALCULATE'
export const TEXT_MATRIKKEL_CHANGED = 'TEXT_MATRIKKEL_CHANGED'
