import React, { useEffect, useState } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { makeStyles, Theme, Paper, Grid } from '@material-ui/core'
import Navigation from 'common/navigation/navigation'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../app/app-store'
import { CaseFlowEditModal } from './case-flow-edit-modal'
import { actionGetCaseFlowEntries, actionGetTemplates } from 'settings-page/settings-actions'
import {
    getEntryCaseDocuments,
    getEntryChannelText,
    getEntryTemplateText,
    getEntryTriggerText,
    getEntryTypeText,
} from './helper'
import { DocumentTypes } from 'common/enums/document-types'

type DocumentTemplatesProps = {}

const useStyles = makeStyles((theme: Theme) => {
    return {
        title: {
            fontWeight: 'bold',
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        text: {
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        capitalize: {
            textTransform: 'capitalize',
        },
        tableHead: {
            padding: theme.spacing(2),
        },
        tableRow: {
            borderTop: '1px solid #f0f0f0',
            borderBottom: '1px solid #f0f0f0',
            cursor: 'pointer',
            marginTop: 0,
            marginBottom: 0,
            padding: theme.spacing(2),
        },
    }
})

export const CaseFlow: React.FC<DocumentTemplatesProps> = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const entries = useSelector((state: AppState) => state.taxSettings.caseFlow)
    const entriesArray: any = Object.values(entries)

    const caseDocuments = useSelector((state: AppState) => state.taxSettings[DocumentTypes.CASE_DOCUMENT])
    const smsTemplates = useSelector((state: AppState) => state.taxSettings[DocumentTypes.SMS])
    const emailTemplates = useSelector((state: AppState) => state.taxSettings[DocumentTypes.EMAIL])
    const letterTemplates = useSelector((state: AppState) => state.taxSettings[DocumentTypes.LETTER])

    const templatesArray = Object.values(
        Object.assign(
            {},
            caseDocuments.templates,
            smsTemplates.templates,
            emailTemplates.templates,
            letterTemplates.templates,
        ),
    )

    const [currentEntry, setCurrentEntry] = useState(null)

    useEffect(() => {
        if (!Object.values(entries).length) {
            dispatch(actionGetCaseFlowEntries())
        }
    }, [dispatch, entries])

    useEffect(() => {
        if (
            (!caseDocuments.loaded || !smsTemplates.loaded || !emailTemplates.loaded || !letterTemplates.loaded) &&
            !caseDocuments.loading &&
            !smsTemplates.loading &&
            !emailTemplates.loading &&
            !letterTemplates.loading
        ) {
            dispatch(actionGetTemplates())
        }
    }, [dispatch, caseDocuments, smsTemplates, emailTemplates, letterTemplates])

    return (
        <LayoutDefault navigation={<Navigation />} pageTitle={tr(t.TEXT_CASE_FLOW)}>
            <Paper elevation={0}>
                <Grid container className={classes.tableHead} alignItems="center" spacing={2}>
                    <Grid item sm={2}>
                        {tr(t.TEXT_TITLE)}
                    </Grid>
                    <Grid item sm={1}>
                        {tr(t.TEXT_TYPE)}
                    </Grid>
                    <Grid item sm={1}>
                        {tr(t.TEXT_CHANNEL)}
                    </Grid>
                    <Grid item sm={3}>
                        {tr(t.TEXT_TRIGGER)}
                    </Grid>
                    <Grid item sm={3}>
                        {tr(t.TEXT_NOTICE)}
                    </Grid>
                    <Grid item sm={2}>
                        {tr(t.TEXT_CASE_DOCUMENT)}
                    </Grid>
                </Grid>
                {entriesArray.map((entry: any, index: number) => (
                    <Grid
                        container
                        key={index}
                        className={classes.tableRow}
                        alignItems="center"
                        spacing={2}
                        onClick={() => setCurrentEntry(entry)}
                    >
                        <Grid item sm={2}>
                            {entry.name}
                        </Grid>
                        <Grid item sm={1}>
                            {getEntryTypeText(entry.type)}
                        </Grid>
                        <Grid item sm={1} className={classes.capitalize}>
                            {getEntryChannelText(entry.channels[0]?.type)}
                        </Grid>
                        <Grid item sm={3}>
                            {getEntryTriggerText(entry)}
                        </Grid>
                        <Grid item sm={3} className={classes.capitalize}>
                            {getEntryTemplateText(entry, templatesArray)}
                        </Grid>
                        <Grid item sm={2}>
                            {getEntryCaseDocuments(entry, caseDocuments.templates)}
                        </Grid>
                    </Grid>
                ))}
                {currentEntry && <CaseFlowEditModal entry={currentEntry} close={() => setCurrentEntry(null)} />}
            </Paper>
        </LayoutDefault>
    )
}
