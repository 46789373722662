import { TEXT_APPROVED, TEXT_CREATED, TEXT_COMPLAINTS_TRIBUNAL, TEXT_READY_FOR_TRIBUNAL, TEXT_FINISHED } from "utils/translations/keys";
import { tr } from "utils/translations/translate";

export enum ComplaintStatus {
    Created = 'CREATED',
    Accepted = 'ACCEPTED',
    PartiallyAccepted = 'PARTIALLY_ACCEPTED',
    Declined = 'DECLINED',
    ReadyForTribunal = 'READY_FOR_TRIBUNAL',
    Processed = 'PROCESSED'
}

type ComplaintStatusTexts = {
    [key: string]: string
}
export const complaintStatusTexts: ComplaintStatusTexts = {
    [ComplaintStatus.Accepted]: tr(TEXT_APPROVED),
    [ComplaintStatus.Created]: tr(TEXT_CREATED),
    [ComplaintStatus.Declined]: tr(TEXT_COMPLAINTS_TRIBUNAL),
    [ComplaintStatus.PartiallyAccepted]: tr(TEXT_COMPLAINTS_TRIBUNAL),
    [ComplaintStatus.ReadyForTribunal]: tr(TEXT_READY_FOR_TRIBUNAL),
    [ComplaintStatus.Processed]: tr(TEXT_FINISHED),
}

export const getComplaintStatusText = (status: string) => {
    const text = complaintStatusTexts[status]
    return text || tr(TEXT_CREATED)
}