import React from 'react'
import * as ol from 'ol'

interface MapContext {
    map: ol.Map | null
    loading: Boolean
}
const MapContext = React.createContext<MapContext>({} as MapContext)

export default MapContext
