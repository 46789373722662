import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, mergeMap, map } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { httpGet, httpPut, plantsUrl } from 'services/httpService'
import {
    ACTION_GET_COMPLAINTS,
    actionGetComplaintsSuccess,
    actionComplaintUpdateSuccess,
    ACTION_UPDATE_COMPLAINT,
} from './complaint-actions'
import { defaultCatchError } from 'app/app-epics'
import { Complaint } from 'tribunal-page/models/complaint.type'

export const getComplaints = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_GET_COMPLAINTS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/taxations/${action.taxationId}/complaints`)).pipe(
                    map((complaints: Complaint[]) => actionGetComplaintsSuccess(complaints, action.taxationId)),
                    defaultCatchError(),
                ),
        ),
    )

export const updateComplaint = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_UPDATE_COMPLAINT),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(plantsUrl(`/tax/complaints/${action.complaint.id}`), action.complaint).pipe(
                    map((complaint: Complaint) => actionComplaintUpdateSuccess(complaint)),
                    defaultCatchError(),
                ),
        ),
    )

export const complaintEpics = combineEpics(
    getComplaints,
    updateComplaint,
)
