import React from 'react'
import { Grid } from '@material-ui/core'

export type TableRowContent = {
    value: string | number
    classes?: string
}

type TableRowProps = {
    content: TableRowContent[]
    containerClass?: string
}

export const TableRow: React.FC<TableRowProps> = (props) => {
    const {
        containerClass = '',
        content = [],
    } = props

    return (
        <Grid container className={containerClass}>
            {content.map((item, index: number) =>
                <Grid item xs className={item.classes ?? ''} key={index}>{item.value}</Grid>
            )}
        </Grid>
    )
}