import { Taxation } from 'models/taxation'
import { Building } from 'models/building'
import { get } from 'lodash'

export default (taxation: Taxation, buildingIndex: number): number => {
    const prev = taxation.old.buildings[buildingIndex]
    const curr = taxation.current.buildings[buildingIndex]

    const prevBuildingFloorsMetrics = getBuildingFloorsMetrics(prev)
    const currBuildingFloorsMetrics = getBuildingFloorsMetrics(curr)

    return currBuildingFloorsMetrics.reduce((accumulator, floorMetrics, index) => {
        const floorMetricChangesCount = floorMetrics.reduce(
            (acc, curr, i) => (curr !== get(prevBuildingFloorsMetrics, `[${index}][${i}]`) ? acc + 1 : acc),
            0,
        )
        return accumulator + floorMetricChangesCount
    }, 0)
}

const getBuildingFloorsMetrics = (building: Building): string[][] => {
    const floorMetricsKeys = ['floorNumber', 'usage', 'area']

    return (
        (building &&
            building.floors.map(floor =>
                floorMetricsKeys.map(propWithCommentKey => get(floor, propWithCommentKey) || null),
            )) ||
        null
    )
}
