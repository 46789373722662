import React from 'react'
import { WrappedFieldArrayProps } from 'redux-form'
import { Building } from 'models/building'
import { TribunalTaxationDetailsBuilding } from './tribunal-taxation-details-building'

type TaxationPropertyBuildingsFieldArrayProps = {
    form: string
}

export const TribunalTaxationBuildingsFieldArray: React.FC<WrappedFieldArrayProps<Building> &
    TaxationPropertyBuildingsFieldArrayProps> = props => {
    const { form, fields } = props

    return (
        <>
            {fields.map((buildingFieldName: string, index: number) => (
                <TribunalTaxationDetailsBuilding
                    key={index}
                    buildingIndex={index}
                    buildingFieldName={buildingFieldName}
                    form={form}
                />
            ))}
        </>
    )
}
