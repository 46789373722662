import React, { useEffect, useState } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { tr } from 'utils/translations/translate'
import {
    TEXT_USERS,
    TEXT_NAME,
    TEXT_E_MAIL,
    TEXT_RIGHTS,
    TEXT_STATUS,
    TEXT_ACTIVE,
    TEXT_SHOW_ACTIVE_USERS_ONLY,
    TEXT_FILTER,
    TEXT_CANCEL,
    TEXT_SAVE,
    TEXT_USER_GROUP,
} from 'utils/translations/keys'
import { useDispatch } from 'react-redux'
import { actionSettingsGroupsGet } from 'shared-components/src/settings/groups/groups-actions'
import { RouteComponentProps } from '@reach/router'
import {
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { useUsers, useRoles, useCompanies } from 'app/app-selectors'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'
import { CubitTableColumn } from 'shared-components/src/cubit-table/cubit-table.types'
import { User } from 'models/user'
import { CubitCheckbox } from 'shared-components/src/cubit-inputs/cubit-checkbox/cubit-checkbox'
import { Clear } from '@material-ui/icons'
import { filter, includes, isEmpty, find } from 'lodash'
import { SettingsUserCreate } from './settings-user-create'
import { submit } from 'redux-form'
import { SettingsUserFormName, SettingsUserForm } from './settings-user-form'
import { actionUpdateUser } from 'app/app-actions'
import { getUserRoleTranslationKey } from 'utils'
import { Role } from 'models/role'

export const SettingsUsersPage: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actionSettingsGroupsGet())
    }, [dispatch])

    const [filterText, setFilterText] = useState('')
    const [displayActiveUsers, setDisplayActiveUsers] = useState(false)
    const [selectedUser, setSelectedUser] = useState<User | null>(null)

    const companies = useCompanies()

    const users = useUsers()
    const mappedUsers = Object.keys(users).map((userId: string) => {
        const userCompany = find(companies, { id: users[userId].taxCompanyId })
        return { ...users[userId], taxCompanyName: userCompany ? userCompany.name : '' }
    })
    const filteredUsers = filter(
        mappedUsers,
        u =>
            includes([u.name, u.email, u.taxCompanyName].join(';').toLowerCase(), filterText.toLowerCase()) &&
            (displayActiveUsers ? u.isActive : true),
    )

    const roles = useRoles()

    const rolesMap = isEmpty(roles)
        ? null
        : roles.reduce((acc, curr) => {
              acc[curr.id] = curr
              return acc
          }, {} as { [key: string]: Role })

    const handleRowClick = (user: User) => {
        setSelectedUser(user)
        setUserDialogOpen(true)
    }

    /* User dialog */
    const [userDialogOpen, setUserDialogOpen] = useState(false)

    const handleClose = () => {
        setUserDialogOpen(false)
    }

    const handleOk = () => {
        dispatch(submit(SettingsUserFormName))
    }

    const handleSubmit = (user: User) => {
        dispatch(actionUpdateUser(user))
        setUserDialogOpen(false)
    }

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={
                <TitleToolbar title={tr(TEXT_USERS)}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <SettingsUserCreate></SettingsUserCreate>
                        </Grid>
                    </Grid>
                </TitleToolbar>
            }
        >
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                        placeholder={tr(TEXT_FILTER)}
                        margin="normal"
                        variant="outlined"
                        style={{ margin: '0px 0px 8px 0px' }}
                        InputProps={{
                            endAdornment: filterText && (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setFilterText('')}>
                                        <Clear fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Grid item>
                    <CubitCheckbox
                        checked={displayActiveUsers}
                        onChange={(a: React.ChangeEvent<HTMLInputElement>) => setDisplayActiveUsers(a.target.checked)}
                    />
                </Grid>
                <Grid item>{tr(TEXT_SHOW_ACTIVE_USERS_ONLY)}</Grid>
            </Grid>
            <CubitTable
                sorting
                columns={usersTableColumns(rolesMap)}
                data={filteredUsers}
                name={SettingsUsersTableName}
                onRowClick={handleRowClick}
            />

            <Dialog onClose={handleClose} open={userDialogOpen} disableBackdropClick>
                <DialogTitle>{selectedUser && selectedUser.name}</DialogTitle>
                <DialogContent style={{ width: '480px' }}>
                    {selectedUser && <SettingsUserForm initialValues={selectedUser} onSubmit={handleSubmit} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit">
                        {tr(TEXT_CANCEL)}
                    </Button>
                    <Button onClick={handleOk} color="primary" autoFocus>
                        {tr(TEXT_SAVE)}
                    </Button>
                </DialogActions>
            </Dialog>
        </LayoutDefault>
    )
}

const SettingsUsersTableName = 'SETTINGS_USERS'

const usersTableColumns = (rolesMap: { [key: string]: Role } | null): CubitTableColumn[] => [
    {
        headerLabel: tr(TEXT_NAME),
        key: 'name',
    },
    {
        headerLabel: tr(TEXT_E_MAIL),
        key: 'email',
    },
    {
        headerLabel: tr(TEXT_USER_GROUP),
        key: 'taxCompanyName',
    },
    {
        headerLabel: tr(TEXT_RIGHTS),
        key: 'roles',
        getDisplayableElement: (user: User) => (
            <span>
                {user.roles
                    .map(roleId => rolesMap && tr(getUserRoleTranslationKey(rolesMap[roleId].nameKey)))
                    .join(' · ') || ''}
            </span>
        ),
    },
    {
        headerLabel: tr(TEXT_STATUS),
        key: 'isActive',
        getDisplayableElement: (user: User) => <span>{user.isActive ? tr(TEXT_ACTIVE) : '-'}</span>,
    },
]
