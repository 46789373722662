export enum Permission {
    ViewAllCompanies = 'VIEW_ALL_COMPANIES',
    ViewOwnCompany = 'VIEW_OWN_COMPANY',
    ViewTribunalAll = 'VIEW_TRIBUNAL_ALL',
    ViewTribunalReady = 'VIEW_TRIBUNAL_READY',
    ViewTaxation = 'VIEW_TAXATION',
    TaxateAllTaxations = 'TAXATE_ALL_TAXATIONS',
    TaxateOwnCompanyTaxations = 'TAXATE_OWN_COMPANY_TAXATIONS',
    TaxateOwnTaxations = 'TAXATE_OWN_TAXATIONS',
    PageDashboard = 'PAGE_DASHBOARD',
    PageSearch = 'PAGE_SEARCH',
    PagePlanning = 'PAGE_PLANNING',
    PageTribunal = 'PAGE_TRIBUNAL',
    PageSettings = 'PAGE_SETTINGS',
    AdministrateUsers = 'ADMINISTRATE_USERS',
    Reports = "REPORTS", 
    Complaints = "COMPLAINTS", 
    PropertyEdit = "EDIT_PROPERTY",
    Billing = "BILLING",

    // Imaginary permissions that doesn't exist yet
    TribunalApproveDecline = 'VIEW_TRIBUNAL_ALL',
}
