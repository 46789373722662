import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { PROPERTY_TYPE_OPTION_VALUES, YES } from 'common/constants/constants'
import { SelectOptions } from 'shared-components/src/cubit-inputs/cubit-form-field.types'
import { YearSettings } from 'models/year-settings'

export const shouldExpand = (fields: any, index: number) => {
    const expanded = fields.get(index).expanded
    return expanded === true ? true : false
}

export const getYearValue = (fields: any, index: number) => {
    // check if there are earlier years set and use it as starting point or use current year
    const initialYear: number | undefined = fields.get(0)?.year
    const valueYear: number = initialYear ? initialYear : new Date().getFullYear() + 1

    return valueYear + index
}

export const normalizeNumbers = (value: any) => {
    if (!value) {
        return value
    }
    return value.toString().replace(/[^\d]/g, '')
}

export const formatPercentage = (value: any) => {
    return `${normalizeNumbers(value)} %`
}

export const formatPermille = (value: any) => {
    if (!value) {
        return value
    }
    return `${normalizeNumbers(value)} ‰`
}

export const parsePermille = (value: any) => {
    if (!value) return ''
    return value / 10
}
export const formatToPermille = (value: any) => {
    if (!value) return ''
    return (value * 10).toFixed(2)
}
export const parsePercent = (value: any) => {
    if (!value) return ''
    return value / 100
}
export const formatToPercent = (value: any) => {
    if (!value) return ''
    return value * 100
}

export const formatKroner = (value: any) => {
    if (!value) {
        return value
    }
    return `${normalizeNumbers(value)} kr`
}

export const getCleanValue = (value: any) => {
    if (!value) {
        return value
    }
    return value.toString().replace(/ %+$/, '').replace(/ kr/, '').replace(/ ‰/, '')
}

export const maxValue = (max: number) => (value: number) => {
    if (value < 0) {
        return 0
    } else if (value > max) {
        return max
    } else {
        return value
    }
}

export const customMinValue = (min: number, e: any, change: any, name: string) => {
    if (e.target.value < min) {
        setTimeout(() => change(name, min), 0)
    }
}

export const minValue = (min: number) => (value: number) => {
    if (!value) {
        return 0
    } else if (value < min) {
        return min
    } else {
        return value
    }
}

export const getPropertyTypeOptions1 = () => [
    { label: tr(t.TEXT_PROPERTY_TYPE_OPTION_A), value: PROPERTY_TYPE_OPTION_VALUES.A },
    { label: tr(t.TEXT_PROPERTY_TYPE_OPTION_B), value: PROPERTY_TYPE_OPTION_VALUES.B },
]

export const getPropertyTypeOptions2 = () => [
    { label: tr(t.TEXT_PROPERTY_TYPE_OPTION_C), value: PROPERTY_TYPE_OPTION_VALUES.C },
    { label: tr(t.TEXT_PROPERTY_TYPE_OPTION_D), value: PROPERTY_TYPE_OPTION_VALUES.D },
    { label: tr(t.TEXT_PROPERTY_TYPE_OPTION_E), value: PROPERTY_TYPE_OPTION_VALUES.E },
    { label: tr(t.TEXT_PROPERTY_TYPE_OPTION_F), value: PROPERTY_TYPE_OPTION_VALUES.F },
    { label: tr(t.TEXT_PROPERTY_TYPE_OPTION_G), value: PROPERTY_TYPE_OPTION_VALUES.G },
]

export const getSelectNumberOptions = (max: Number): SelectOptions[] => {
    let options = []

    for (let i = 1; i <= max; i++) {
        options.push({ label: i.toString(), value: i })
    }

    return options
}

export const getOfficeAdjustmentText = (settings: YearSettings) => {
    return settings.municipalityAdjustment === YES ? `${settings.municipalityAdjustmentValue} %` : tr(t.TEXT_NO)
}
