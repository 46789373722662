import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'

const Form: React.FC<InjectedFormProps<{ chips: never[] }>> = props => {
    const { handleSubmit, children } = props

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%', height: '48px' }}>
            {children}
        </form>
    )
}

const SearchToolbarFormName = 'searchToolbarForm'

const SearchToolbarFrom = reduxForm({
    form: SearchToolbarFormName,
    destroyOnUnmount: false,
    initialValues: { chips: [] },
})(Form)

export { SearchToolbarFrom, SearchToolbarFormName }
