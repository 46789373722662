import React from 'react'
import { Grid, IconButton, Tooltip } from '@material-ui/core'
import {
    TEXT_EXEMPTION_BY_REASON,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import {MoneyOff } from '@material-ui/icons'

type PropertyExemptionProps = {
    isExempt: boolean
    exemptReason: string
}

export const GetExemption: React.FC<PropertyExemptionProps> = (props) => {
    const { isExempt, exemptReason } = props
    return (
        <>
            {isExempt && (
                <Grid item>
                    <IconButton size="small" color="inherit">
                        <Tooltip title={`${tr(TEXT_EXEMPTION_BY_REASON)}: ${exemptReason}`}>{<MoneyOff />}</Tooltip>
                    </IconButton>
                </Grid>
            )}
        </>
    )
}
