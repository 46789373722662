export const foo = 0

export const TEXT_ENABLE = 'TEXT_ENABLE'
export const TEXT_FILTERS = 'TEXT_FILTERS'
export const TEXT_SEARCH = 'TEXT_SEARCH'
export const TEXT_NO_RESULTS = 'TEXT_NO_RESULTS'

// export const TEXT_SAVE = 'TEXT_SAVE'
// export const TEXT_DASHBOARD = 'TEXT_DASHBOARD'
// export const TEXT_LANGUAGE = 'TEXT_LANGUAGE'
// export const TEXT_OBJECT_TYPE = 'TEXT_OBJECT_TYPE'
// export const TEXT_HOUSE = 'TEXT_HOUSE'
// export const TEXT_HOLIDAY_HOME = 'TEXT_HOLIDAY_HOME'
// export const TEXT_COMMERCIAL = 'TEXT_COMMERCIAL'

// export const TEXT_FROM = 'TEXT_FROM'
// export const TEXT_TO = 'TEXT_TO'

// export const TEXT_ZONE = 'TEXT_ZONE'
// export const TEXT_ZONE1 = 'TEXT_ZONE1'
// export const TEXT_ZONE2 = 'TEXT_ZONE2'
// export const TEXT_ZONE3 = 'TEXT_ZONE3'
// export const TEXT_ZONE4 = 'TEXT_ZONE4'
// export const TEXT_ZONE5 = 'TEXT_ZONE5'

// export const TEXT_ALL = 'TEXT_ALL'
// export const TEXT_MINE = 'TEXT_MINE'

// export const TEXT_STATUS = 'TEXT_STATUS'
// export const TEXT_NO_STATUS = 'TEXT_NO_STATUS'
// export const TEXT_PICKED = 'TEXT_PICKED'
// export const TEXT_AWARDED = 'TEXT_AWARDED'
// export const TEXT_ACTIVE_CASE = 'TEXT_ACTIVE_CASE'

// export const TEXT_EVALUATION = 'TEXT_EVALUATION'
// export const TEXT_EVALUATION_SOURCE = 'TEXT_EVALUATION_SOURCE'
// export const TEXT_TAX_ADMINISTRATION = 'TEXT_TAX_ADMINISTRATION'

// export const TEXT_PROPERTY_VALUE = 'TEXT_PROPERTY_VALUE'

// export const TEXT_TAX_SUM = 'TEXT_TAX_SUM'

// export const TEXT_MASS_REGISTRATION = 'TEXT_MASS_REGISTRATION'

// export const TEXT_EXEMPTION = 'TEXT_EXEMPTION'

// export const TEXT_OWNER_CATEGORY = 'TEXT_OWNER_CATEGORY'

// export const TEXT_MUNICIPALITY = 'TEXT_MUNICIPALITY'
// export const TEXT_PUBLIC = 'TEXT_PUBLIC'
// export const TEXT_PRIVATE = 'TEXT_PRIVATE'
// export const TEXT_DEAD_OWNERS = 'TEXT_DEAD_OWNERS'

// export const TEXT_STANDARD = 'TEXT_STANDARD'
// export const TEXT_LOW = 'TEXT_LOW'
// export const TEXT_NORMAL = 'TEXT_NORMAL'
// export const TEXT_HIGH = 'TEXT_HIGH'

// export const TEXT_LOCATION = 'TEXT_LOCATION'

// export const TEXT_PROPERTY = 'TEXT_PROPERTY'
// export const TEXT_PROPERTIES = 'TEXT_PROPERTIES'

// export const TEXT_OWNERS = 'TEXT_OWNERS'

// export const TEXT_ASSIGNED = 'TEXT_ASSIGNED'
// export const TEXT_EVALUATION_DATE = 'TEXT_EVALUATION_DATE'
// export const TEXT_DATE_FROM = 'TEXT_DATE_FROM'
// export const TEXT_DATE_TO = 'TEXT_DATE_TO'
// export const TEXT_SETTINGS = 'TEXT_SETTINGS'
// export const TEXT_CLEAR_ALL_FILTERS = 'TEXT_CLEAR_ALL_FILTERS'

// export const TEXT_NAME = 'TEXT_NAME'
// export const TEXT_E_POST = 'TEXT_E_POST'
// export const TEXT_ADDRESS = 'TEXT_ADDRESS'
// export const TEXT_NUMBER = 'TEXT_NUMBER'
// export const TEXT_PHONE_NUMBER = 'TEXT_PHONE_NUMBER'
// export const TEXT_TAX = 'TEXT_TAX'
// export const TEXT_APPRAISERS = 'TEXT_APPRAISERS'
// export const TEXT_USE_OF_LAND = 'TEXT_USE_OF_LAND'

// export const TEXT_REGISTER_NUMBERS = 'TEXT_REGISTER_NUMBERS'
// export const TEXT_SELECTED = 'TEXT_SELECTED'
// export const TEXT_SCHEDULE_ASSESSMENT = 'TEXT_SCHEDULE_ASSESSMENT'

// export const TEXT_0457 = 'TEXT_0457'
// export const TEXT_0777 = 'TEXT_0777'
// export const TEXT_0778 = 'TEXT_0778'
// export const TEXT_0779 = 'TEXT_0779'
// export const TEXT_0780 = 'TEXT_0780'
// export const TEXT_0781 = 'TEXT_0781'
// export const TEXT_0782 = 'TEXT_0782'
// export const TEXT_0783 = 'TEXT_0783'
