import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    actionTribunalGroupFinish,
    actionTribunalGroupStart,
    actionTribunalConflictsReviewFinish,
    actionTribunalGroupUpdate,
    actionTribunalGroupPlan,
    actionTribunalGroupReadyForFinish,
} from 'tribunal-page/tribunal-actions'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { getTribunalGroupNameTranslation } from 'utils'
import { Grid, IconButton, Button } from '@material-ui/core'
import { TribunalGroupState } from './tribunal-group-state.enum'
import { TribunalGroupType, TribunalGroup } from 'tribunal-page/models/tribunal-group'
import { tr } from 'utils/translations/translate'
import {
    TEXT_READY_FOR_TRIBUNAL,
    TEXT_START_TRIBUNAL,
    TEXT_FINISH_REVIEW,
    TEXT_APPROVE_TRIBUNAL,
    TEXT_EDIT_GROUP,
    TEXT_SAVE,
} from 'utils/translations/keys'
import EditIcon from '@material-ui/icons/Edit'
import { submit } from 'redux-form'
import { TribunalGroupFormName, TribunalGroupForm } from './tribunal-group-form'
import { TribunalGroupDialog } from './tribunal-group-dialog'
import { IntermediateTribunalGroupState } from './intermediate-tribunal-group-state.enum'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'

export const TribunalGroupPageToolbar: React.FC<{
    group: TribunalGroup
    groupState: TribunalGroupState
    intermediateGroupState: IntermediateTribunalGroupState | null
}> = props => {
    const { group, groupState, intermediateGroupState } = props
    const dispatch = useDispatch()

    const [groupDialogOpen, setGroupDialogOpen] = useState(false)
    const [readyForTribunalDialogOpen, setReadyForTribunalDialogOpen] = useState(false)

    const handleReadyForTribunal = () => {
        setReadyForTribunalDialogOpen(true)
    }

    const handleStartTribunal = () => {
        dispatch(actionTribunalGroupStart(group.id))
    }

    const handleReadyForFinishTribunal = () => {
        dispatch(actionTribunalGroupReadyForFinish(group.id))
    }

    const handleFinishTribunal = () => {
        dispatch(actionTribunalGroupFinish(group.id))
    }

    const handleFinishConflictsReview = () => {
        dispatch(actionTribunalConflictsReviewFinish(group.id))
    }

    const handleClose = () => {
        setGroupDialogOpen(false)
    }

    const handleOk = () => {
        dispatch(submit(TribunalGroupFormName))
    }

    const handleSubmit = (group: TribunalGroup) => {
        setGroupDialogOpen(false)
        dispatch(actionTribunalGroupUpdate(group))
    }

    const handleReadyForTribunalClose = () => {
        setReadyForTribunalDialogOpen(false)
    }

    const handleReadyForTribunalOk = () => {
        dispatch(submit(TribunalGroupFormName))
    }

    const handleReadyForTribunalSubmit = (newGroup: TribunalGroup) => {
        setReadyForTribunalDialogOpen(false)
        dispatch(actionTribunalGroupPlan(group.id, newGroup))
    }

    const renderConflictsReviewFinishButton = () => {
        return intermediateGroupState === IntermediateTribunalGroupState.PlannedConflictsReview ? (
            <Button variant="outlined" color="inherit" type="button" onClick={handleFinishConflictsReview}>
                {tr(TEXT_FINISH_REVIEW)}
            </Button>
        ) : (
            <></>
        )
    }

    return (
        <TitleToolbar title={getTribunalGroupNameTranslation(group)}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    {groupState === TribunalGroupState.Initial && group.type === TribunalGroupType.UserCreated && (
                        <IconButton onClick={() => setGroupDialogOpen(true)} color="inherit">
                            <EditIcon />
                        </IconButton>
                    )}
                </Grid>
                <Grid item>
                    {groupState === TribunalGroupState.Initial && (
                        <Button variant="outlined" color="inherit" type="button" onClick={handleReadyForTribunal}>
                            {tr(TEXT_READY_FOR_TRIBUNAL)}
                        </Button>
                    )}

                    {groupState === TribunalGroupState.Planned && (
                        <Protected p={Permission.TribunalApproveDecline}>
                            <Button variant="outlined" color="inherit" type="button" onClick={handleStartTribunal}>
                                {tr(TEXT_START_TRIBUNAL)}
                            </Button>
                        </Protected>
                    )}

                    {renderConflictsReviewFinishButton()}

                    {groupState === TribunalGroupState.Started && (
                        // first app admin "marks" group as finished
                        <Protected p={Permission.TribunalApproveDecline}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                type="button"
                                onClick={handleReadyForFinishTribunal}
                            >
                                {tr(TEXT_APPROVE_TRIBUNAL)}
                            </Button>
                        </Protected>
                    )}

                    {groupState === TribunalGroupState.ReadyForFinish && (
                        // then tribunal admin finalizes with his finish confirmation
                        <Protected p={Permission.ViewTribunalReady}>
                            <Button variant="outlined" color="inherit" type="button" onClick={handleFinishTribunal}>
                                {tr(TEXT_APPROVE_TRIBUNAL)}
                            </Button>
                        </Protected>
                    )}
                </Grid>
            </Grid>

            <TribunalGroupDialog
                title={tr(TEXT_EDIT_GROUP)}
                open={groupDialogOpen}
                handleClose={handleClose}
                handleOk={handleOk}
                okText={tr(TEXT_SAVE)}
            >
                <TribunalGroupForm initialValues={group} onSubmit={handleSubmit} />
            </TribunalGroupDialog>

            <TribunalGroupDialog
                title={tr(TEXT_READY_FOR_TRIBUNAL)}
                open={readyForTribunalDialogOpen}
                handleClose={handleReadyForTribunalClose}
                handleOk={handleReadyForTribunalOk}
                okText={tr(TEXT_READY_FOR_TRIBUNAL)}
            >
                <TribunalGroupForm initialValues={group} onSubmit={handleReadyForTribunalSubmit} />
            </TribunalGroupDialog>
        </TitleToolbar>
    )
}
