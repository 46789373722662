import { AnyAction } from 'redux'
import { SuggestionGroup } from 'shared-components/src/search-input/search-input-types'
import { SearchResultKey } from './search-reducer'
import { Property, StateModel } from 'models/property'
import { AppDataState } from 'app/app-reducer'

export const ACTION_SEARCH = 'ACTION_SEARCH'
export const ACTION_SEARCH_ALL = 'ACTION_SEARCH_ALL'
export const ACTION_SET_SHOW_MAP = 'ACTION_SET_SHOW_MAP'
export const ACTION_SEARCH_LOAD_MORE = 'ACTION_SEARCH_LOAD_MORE'
export const ACTION_SEARCH_SUCCESS = 'ACTION_SEARCH_SUCCESS'
export const ACTION_SEARCH_ERROR = 'ACTION_SEARCH_ERROR'
export const ACTION_SEARCH_SET_SUGGESTIONS = 'ACTION_SEARCH_SET_SUGGESTIONS'
export const ACTION_SEARCH_SELECT_TAB = 'ACTION_SEARCH_SELECT_TAB'
export const ACTION_SEARCH_AUTOCOMPLETE_LOADED = 'ACTION_SEARCH_AUTOCOMPLETE_LOADED'
export const ACTION_ASSIGN_PROPERTIES = 'ACTION_ASSIGN_PROPERTIES'
export const ACTION_UNASSIGN_PROPERTIES = 'ACTION_UNASSIGN_PROPERTIES'
export const ACTION_UNASSIGN_PROPERTIES_SUCCESS = 'ACTION_UNASSIGN_PROPERTIES_SUCCESS'
export const ACTION_ASSIGN_PROPERTIES_SUCCESS = 'ACTION_ASSIGN_PROPERTIES_SUCCESS'
export const ACTION_SEND_NOTICES = 'ACTION_SEND_NOTICES'
export const ACTION_SEND_NOTICES_SUCCESS = 'ACTION_SEND_NOTICES_SUCCESS'
export const ACTION_SEND_YEAR_LETTERS = 'ACTION_SEND_YEAR_LETTERS'
export const ACTION_SEND_YEAR_LETTERS_SUCCESS = 'ACTION_SEND_YEAR_LETTERS_SUCCESS'
export const ACTION_SEND_RIKO_NOTICES = 'ACTION_SEND_RIKO_NOTICES'

export const actionSearchAutocompleteLoaded = (autocomplete: SuggestionGroup[]): AnyAction => ({
    type: ACTION_SEARCH_AUTOCOMPLETE_LOADED,
    autocomplete,
})

export const actionSearchSetSuggestions = (data: AppDataState[]): AnyAction => {
    return {
        type: ACTION_SEARCH_SET_SUGGESTIONS,
        data,
    }
}

export const actionSearch = (tableName: SearchResultKey): AnyAction => ({
    type: ACTION_SEARCH,
    tableName,
})

export const actionSearchLoadMore = (tableName: SearchResultKey): AnyAction => ({
    type: ACTION_SEARCH_LOAD_MORE,
    tableName,
})

export const actionSearchAll = (): AnyAction => ({
    type: ACTION_SEARCH_ALL,
})
export const setShowMap = (payload:boolean): AnyAction => ({
    type: ACTION_SET_SHOW_MAP,
    payload
})
export const actionSearchSuccess = (tableName: SearchResultKey, results: any): AnyAction => ({
    type: ACTION_SEARCH_SUCCESS,
    tableName,
    results,
})

export const actionAssignProperties = (stateModels: StateModel[]): AnyAction => ({
    type: ACTION_ASSIGN_PROPERTIES,
    stateModels,
})

export const actionAssignPropertiesSuccess = (properties: Property[]): AnyAction => ({
    type: ACTION_ASSIGN_PROPERTIES_SUCCESS,
    properties,
})

export const actionUnassignProperties = (ids: string[]): AnyAction => ({
    type: ACTION_UNASSIGN_PROPERTIES,
    ids,
})
export const actionUnassignPropertiesSuccess = (ids: string[]): AnyAction => ({
    type: ACTION_UNASSIGN_PROPERTIES_SUCCESS,
    ids,
})

export const actionSearchError = (tableName: SearchResultKey): AnyAction => ({
    type: ACTION_SEARCH_ERROR,
    tableName,
})

export const actionSearchOrder = (
    tableName: SearchResultKey,
    orderBy: string,
    orderDirection: 'asc' | 'desc',
): AnyAction => ({
    type: ACTION_SEARCH,
    tableName,
    orderBy,
    orderDirection,
})

export const actionSearchSelectTab = (tabIndex: number): AnyAction => ({
    type: ACTION_SEARCH_SELECT_TAB,
    tabIndex,
})

export const actionSendNotices = (ids: any): AnyAction => ({
    type: ACTION_SEND_NOTICES,
    ids,
})

export const actionSendNoticesSuccsess = (ids: any): AnyAction => ({
    type: ACTION_SEND_NOTICES_SUCCESS,
    ids,
})

export const actionSendYearLetters = (data: any, tableName?: string): AnyAction => ({
    type: ACTION_SEND_YEAR_LETTERS,
    data,
    tableName
})

export const actionSendYearLettersSuccsess = (ids: any): AnyAction => ({
    type: ACTION_SEND_YEAR_LETTERS_SUCCESS,
    ids,
})

export const actionSendRikoNotices = (ids: any): AnyAction => ({
    type: ACTION_SEND_RIKO_NOTICES,
    ids,
})
