import { combineEpics } from 'redux-observable'
import { EMPTY, Observable, ObservedValueOf, of, OperatorFunction } from 'rxjs'
import { catchError, mergeMap, tap, withLatestFrom, filter, delay, map } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { searchEpics } from '../search-page/search-epics'
import {
    actionShowError,
    ACTION_LOAD_APP_DATA,
    actionLoadAppDataSuccess,
    ACTION_LOAD_COMPANIES_AND_USERS,
    actionLoadCompaniesAndUsersSuccess,
    ACTION_GO_BACK,
    ACTION_LOAD_ROLES,
    actionLoadRolesSuccess,
    ACTION_CREATE_USER,
    actionCreateUserSuccess,
    actionGetWelcomeUserSuccess,
    ACTION_GET_WELCOME_USER,
    ACTION_WELCOME_USER_REGISTER,
    actionWelcomeUserRegisterSuccess,
    actionUpdateUserSuccess,
    ACTION_UPDATE_USER,
    ACTION_UPDATE_COMPANY,
    actionUpdateCompanySuccess,
    ACTION_CREATE_COMPANY,
    actionCreateCompanySuccess,
    actionLoadTenantsSuccess,
    ACTION_LOAD_TENANTS,
    ACTION_SWITCH_TENANT,
} from './app-actions'
import { authEpics } from '../auth/auth-epics'
import { propertyEpics } from 'property-page/property-epics'
import { planningEpics } from 'planning-page/planning-epic'
import { taxationEpics } from 'property-taxation-edit-page/taxation-epics'
import { complaintEpics } from 'complaint-page/complaint-epics'
import { ReduxFormActions } from 'common/enums/redux-form-actions'
import { httpGet, plantsUrl, lanternUrl, httpPost, httpPut, auditFrontUrl } from 'services/httpService'
import { actionSearchSetSuggestions } from 'search-page/search-actions'
import { tribunalEpics } from 'tribunal-page/tribunal-epics'
import { settingsEpics } from 'settings-page/settings-epics'
import { actionAuthLogin } from 'auth/auth-actions'
import { submit } from 'redux-form'
import { includes } from 'lodash'
import { attributeEpics } from 'common/attributes/attribute-epics'
import { TENANT_TYPES } from 'common/enums/tenant'
import { dashboardEpics } from 'dashboard-page/dashboard-epics'

export const defaultCatchError = (
    message?: string,
): OperatorFunction<AnyAction, ObservedValueOf<AnyAction> | AnyAction> =>
    catchError((error): Observable<AnyAction> => {
        console.error(error)
        return of(actionShowError(message))
    })

export const logEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        withLatestFrom(state$),
        tap(([action, state]) => console.log(action.type, { action, state })),
        mergeMap(() => EMPTY),
    )

export const goBackEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((x) => x.type === ACTION_GO_BACK),
        mergeMap(() => {
            window.history.back()

            return EMPTY
        }),
    )

export const reduxFormAutoSubmitOnChangeEpic = (action$: Observable<any>) =>
    action$.pipe(
        filter((x) => x.type === ReduxFormActions.Change),
        delay(0),
        mergeMap((action): Observable<any> => {
            const { form, field } = action.meta

            if (includes(field, 'verifiedValues')) {
                return of(submit(form))
            }
            return EMPTY
        }),
    )

export const loadAppDataEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter((x) => x.type === ACTION_LOAD_APP_DATA),
        withLatestFrom(state$),
        mergeMap(([action, state]): Observable<any> => {
            return httpGet(plantsUrl(`/data/${state.auth.sessionKey}`)).pipe(
                mergeMap((data: any) => of(actionLoadAppDataSuccess(data), actionSearchSetSuggestions(data))),
                defaultCatchError(),
            )
        }),
    )

export const loadCompaniesAndUsersEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_LOAD_COMPANIES_AND_USERS),
        mergeMap(
            (): Observable<AnyAction> =>
                httpGet(lanternUrl('/taxationcompanies')).pipe(
                    map((companiesAndUsers) => {
                        const { companies, users } = companiesAndUsers
                        return actionLoadCompaniesAndUsersSuccess({ companies, users })
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const loadRolesEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_LOAD_ROLES),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpGet(lanternUrl(`/roles/${state.auth.sessionKey}`)).pipe(
                    map((roles) => actionLoadRolesSuccess(roles)),
                    defaultCatchError(),
                ),
        ),
    )

export const createUserEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_CREATE_USER),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpPost(lanternUrl('/user/createUsers'), [action.user]).pipe(
                    map((user) => actionCreateUserSuccess(user)),
                    defaultCatchError(),
                ),
        ),
    )

export const getWelcomeUserEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_GET_WELCOME_USER),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(lanternUrl(`/user/newuser/${action.newUserKey}`)).pipe(
                    map((welcomeUser) => actionGetWelcomeUserSuccess(welcomeUser)),
                    defaultCatchError(),
                ),
        ),
    )

export const welcomeUserRegisterEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_WELCOME_USER_REGISTER),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(lanternUrl('/resetPassword'), {
                    email: action.email,
                    password: action.password,
                    newUserKey: action.newUserKey,
                }).pipe(
                    mergeMap(() => {
                        return of(
                            actionWelcomeUserRegisterSuccess(),
                            actionAuthLogin({ email: action.email, password: action.password }),
                        )
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const updateUserEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_UPDATE_USER),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(lanternUrl('/user/update'), action.user).pipe(
                    map((user) => actionUpdateUserSuccess(user)),
                    defaultCatchError(),
                ),
        ),
    )

export const createCompanyEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_CREATE_COMPANY),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(lanternUrl('/taxationcompanies'), action.company).pipe(
                    map((company) => actionCreateCompanySuccess(company)),
                    defaultCatchError(),
                ),
        ),
    )

export const updateCompanyEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_UPDATE_COMPANY),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(lanternUrl(`/taxationcompanies/${action.company.id}`), action.company).pipe(
                    map((company) => actionUpdateCompanySuccess(company)),
                    defaultCatchError(),
                ),
        ),
    )

export const loadTenantsEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_LOAD_TENANTS),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpGet(lanternUrl(`/tenant/${state.auth.sessionKey}`)).pipe(
                    map((tenants) => actionLoadTenantsSuccess(tenants)),
                    defaultCatchError(),
                ),
        ),
    )

export const switchTenantEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_SWITCH_TENANT),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpPut(lanternUrl(`/user/changetenant/${state.auth.sessionKey}/${action.tenant.id}`), {}).pipe(
                    mergeMap(() => {
                        if (action.tenant.type === TENANT_TYPES.TAX) {
                            window.location.href = '/'
                        } else {
                            window.location.href = auditFrontUrl('')
                        }
                        return EMPTY
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const appEpics = combineEpics(
    logEpic,
    goBackEpic,
    reduxFormAutoSubmitOnChangeEpic,
    loadAppDataEpic,
    loadCompaniesAndUsersEpic,
    loadRolesEpic,
    createUserEpic,
    updateUserEpic,
    createCompanyEpic,
    updateCompanyEpic,
    getWelcomeUserEpic,
    welcomeUserRegisterEpic,
    searchEpics,
    authEpics,
    propertyEpics,
    planningEpics,
    taxationEpics,
    complaintEpics,
    tribunalEpics,
    settingsEpics,
    attributeEpics,
    loadTenantsEpic,
    switchTenantEpic,
    dashboardEpics,
)
