import * as keys from '../keys'

export const nb: { [index: string]: string } = {}

nb[keys.TEXT_FILTERS] = 'Filtre'
nb[keys.TEXT_ENABLE] = 'Aktiver'
nb[keys.TEXT_SEARCH] = 'Søk'
nb[keys.TEXT_NO_RESULTS] = 'Ingen resultater'

// nb[keys.TEXT_SAVE] = 'Lagre'
// nb[keys.TEXT_DASHBOARD] = 'Dashbord'

// nb[keys.TEXT_LANGUAGE] = 'Språk'
// nb[keys.TEXT_OBJECT_TYPE] = 'Objekttyper'
// nb[keys.TEXT_HOUSE] = 'Boliger'
// nb[keys.TEXT_HOLIDAY_HOME] = 'Fritidsboliger'
// nb[keys.TEXT_COMMERCIAL] = 'Næringsbygg'

// nb[keys.TEXT_ZONE] = 'Sone'
// nb[keys.TEXT_ZONE1] = 'Sone 1'
// nb[keys.TEXT_ZONE2] = 'Sone 2'
// nb[keys.TEXT_ZONE3] = 'Sone 3'
// nb[keys.TEXT_ZONE4] = 'Sone 4'
// nb[keys.TEXT_ZONE5] = 'Sone 5'

// nb[keys.TEXT_ALL] = 'Alle'
// nb[keys.TEXT_MINE] = 'Meg'

// nb[keys.TEXT_STATUS] = 'Status'
// nb[keys.TEXT_NO_STATUS] = 'Ingen status'
// nb[keys.TEXT_PICKED] = 'Plukket'
// nb[keys.TEXT_AWARDED] = 'Tildelt'
// nb[keys.TEXT_ACTIVE_CASE] = 'Aktiv sak'

// nb[keys.TEXT_EVALUATION] = 'Taksering'
// nb[keys.TEXT_EVALUATION_SOURCE] = 'Takseringskilde'
// nb[keys.TEXT_TAX_ADMINISTRATION] = 'Skatteetaten'

// nb[keys.TEXT_PROPERTY_VALUE] = 'Eiendomsverdi'

// nb[keys.TEXT_MASS_REGISTRATION] = 'Skattesum'

// nb[keys.TEXT_EXEMPTION] = 'Fritak'

// nb[keys.TEXT_OWNER_CATEGORY] = 'Eierkattegori'
// nb[keys.TEXT_MUNICIPALITY] = 'Kommune'
// nb[keys.TEXT_PUBLIC] = 'Offentlige'
// nb[keys.TEXT_PRIVATE] = 'Private'
// nb[keys.TEXT_DEAD_OWNERS] = 'Døde hjemmelshavere'

// nb[keys.TEXT_STANDARD] = 'Standard'
// nb[keys.TEXT_LOW] = 'Lav'
// nb[keys.TEXT_NORMAL] = 'Normal'
// nb[keys.TEXT_HIGH] = 'Høy'

// nb[keys.TEXT_LOCATION] = 'Beliggenhet'

// nb[keys.TEXT_PROPERTY] = 'Eiendom'
// nb[keys.TEXT_PROPERTIES] = 'Eiendomer'

// nb[keys.TEXT_OWNERS] = 'Eiere'

// nb[keys.TEXT_TAX_SUM] = 'Masseregistrering'

// nb[keys.TEXT_FROM] = 'Fra'
// nb[keys.TEXT_TO] = 'Til'
// nb[keys.TEXT_ASSIGNED] = 'Tildelt'
// nb[keys.TEXT_EVALUATION_DATE] = 'Takseringsdato'

// nb[keys.TEXT_DATE_FROM] = 'Dato fra'
// nb[keys.TEXT_DATE_TO] = 'Dato til'
// nb[keys.TEXT_SETTINGS] = 'Innstillinger'
// nb[keys.TEXT_CLEAR_ALL_FILTERS] = '[Clear all filters]'

// nb[keys.TEXT_NAME] = 'Navn'
// nb[keys.TEXT_E_POST] = 'E-post'
// nb[keys.TEXT_ADDRESS] = 'Adresse'
// nb[keys.TEXT_NUMBER] = 'Nummer'
// nb[keys.TEXT_PHONE_NUMBER] = 'Telefonnummer'
// nb[keys.TEXT_TAX] = 'Skatt'
// nb[keys.TEXT_APPRAISERS] = 'Takst'
// nb[keys.TEXT_USE_OF_LAND] = 'Bruk av grunn'

// nb[keys.TEXT_REGISTER_NUMBERS] = 'Matrikkelnummer'
// nb[keys.TEXT_SELECTED] = 'Valgt'
// nb[keys.TEXT_SCHEDULE_ASSESSMENT] = 'Planlegg taksering'

// nb[keys.TEXT_0457] = 'Næringskoder'
// nb[keys.TEXT_0777] = 'Postaddresser'
// nb[keys.TEXT_0778] = 'Anlegg-/utstyrstyper'
// nb[keys.TEXT_0779] = 'Resultatområder'
// nb[keys.TEXT_0780] = 'Anleggs-/virksomhetstyper'
// nb[keys.TEXT_0781] = 'Bygningskoder'
// nb[keys.TEXT_0782] = 'El-virksomhetsstatus'
// nb[keys.TEXT_0783] = 'El-virksomhetsoppgaver'
