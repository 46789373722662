import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, mergeMap, map } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { httpDelete, httpGet, httpPost, httpPut, plantsUrl } from 'services/httpService'
import * as actions from './attribute-actions'
import { defaultCatchError } from 'app/app-epics'

export const getAttributes = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === actions.ACTION_GET_ATTRIBUTES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/attribute/${action.attType}`)).pipe(
                    map((attributes: string[]) => actions.actionGetAttributesSuccess(action.attType, attributes)),
                    defaultCatchError(),
                ),
        ),
    )

export const createAttribute = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === actions.ACTION_CREATE_ATTRIBUTE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(plantsUrl('/attribute'), {
                    text: action.attribute,
                    type: action.attType,
                }).pipe(
                    map(() => actions.actionCreateAttributeSuccess(action.attType, action.attribute)),
                    defaultCatchError(),
                ),
        ),
    )

export const deleteAttribute = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === actions.ACTION_DELETE_ATTRIBUTE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpDelete(plantsUrl('/attribute'), {
                    text: action.attribute,
                    type: action.attType,
                }).pipe(
                    map(() => actions.actionDeleteAttributeSuccess(action.attType, action.attribute)),
                    defaultCatchError(),
                ),
        ),
    )

export const attachAttributes = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === actions.ACTION_ATTACH_ATTRIBUTES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/attribute'), {
                    attributes: action.attributes,
                    oldAttributes: action.oldAttributes,
                    entityId: action.entityId,
                    propertyId: action.propertyId
                }).pipe(
                    map(() => {
                        if (action.afterEdit) {
                            action.afterEdit(action.attributes)
                        }
                        return actions.actionAttachAttributesSuccess(action.attributes, action.attType, action.entityId, action.propertyId)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const getEntityAttributes = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === actions.ACTION_GET_ENTITY_ATTRIBUTES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/attribute/entityAttributes'), {
                    entityId: action.entityId,
                }).pipe(
                    map((attributes: string[]) => {
                        action.onDone(attributes)
                        return actions.actionGetEntityAttributesSuccess(action.entityId, attributes)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const attributeEpics = combineEpics(
    getAttributes,
    createAttribute,
    deleteAttribute,
    attachAttributes,
    getEntityAttributes
)
