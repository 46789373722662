import React from 'react'
import { change, Field } from 'redux-form'
import { Grid } from '@material-ui/core'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { Taxation } from 'models/taxation'
import { useDispatch, useSelector } from 'react-redux'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { Property } from 'models/property'

type FactorOption = {
    name: string
    choice1?: string
    choice2?: string
    choice3?: string
    choice4?: string
    choice5?: string
}

const defaultFactors: any[] = [
    {
        label: '-',
        value: null,
    },
]

const getOtherLocationFactorOptions = (factor: any) =>
    defaultFactors.concat(
        Object.values(factor)
            .filter((value: any, index: number) => value && index !== 0)
            .map((value: any) => ({
                label: value,
                value: value,
            })),
    )

type OtherFactorsProps = {
    factorOptions: FactorOption[]
    editable: boolean
    formName: string
    property: Property
}
const OtherFactorsFields: React.FC<OtherFactorsProps> = (props) => {
    const { factorOptions, editable, formName, property } = props
    const dispatch = useDispatch()
    return (
        <>
            {factorOptions.map((option: FactorOption, index: number) =>
                editable ||
                (property.otherLocationFactors[index] && property.otherLocationFactors[index].factor !== null) ? (
                    <Grid container spacing={2} alignItems="center" key={index}>
                        <Grid item xs>
                            <Field
                                name={`current.property.otherLocationFactors[${index}].name`}
                                type="hidden"
                                format={(value: string, name: string) => {
                                    if (!value) {
                                        dispatch(change(formName, name, option.name))
                                    }
                                }}
                                component="input"
                            />
                            <Field
                                name={`current.property.otherLocationFactors[${index}].factor`}
                                label={option.name}
                                options={getOtherLocationFactorOptions(option)}
                                component={CubitSelectAdapter}
                                valueIsObject={false}
                                direction="row"
                                disabled={!editable}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <div key={index}></div>
                ),
            )}
        </>
    )
}

type TaxationOtherFactorsProps = {
    taxation: Taxation
    editable: boolean
    formName: string
    locationFactorHasBeenChanged: boolean
}

export const TaxationLocationOtherFactors: React.FC<TaxationOtherFactorsProps> = (props) => {
    const { editable, locationFactorHasBeenChanged = false, formName, taxation } = props
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)

    const otherFactors = settings && settings.otherFactors ? settings.otherFactors : []
    return (
        <>
            {locationFactorHasBeenChanged && otherFactors && (
                <OtherFactorsFields
                    factorOptions={otherFactors}
                    editable={editable}
                    formName={formName}
                    property={taxation.current.property}
                />
            )}
        </>
    )
}
