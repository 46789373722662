import React, { useEffect } from 'react'
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, makeStyles } from '@material-ui/core'
import { Form } from 'react-final-form'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import classNames from 'classnames'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionUploadCsvFile } from 'settings-page/settings-actions'
import { FinalFormFileField } from './FinalFormFileField'
import { AppState } from 'app/app-store'
const useStyles = makeStyles((theme) => ({
    fileLabel: {
        display: 'flex',
        width: '100%',
    },
    hiddenInput: {
        visibility: 'hidden',
        width: 0,
        height: 0,
        overflow: 'hidden',
    },
    fakeInput: {
        width: '100%',
        minWidth: '260px',
        maxWidth: '260px',
        padding: '4px 12px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: 3,
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        margin: '0px',
        marginRight: 25,
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            maxWidth: 'none',
            margin: '0px',
            flexWrap: 'wrap',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: 0,
        },
    },
    ready: {
        borderColor: '#04cc00',
        margin: '0px',
        minWidth: '260px',
        maxWidth: '260px',
        marginRight: 25,
        [theme.breakpoints.down('md')]: {
            margin: 0,
            marginRight: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    error: {
        border: '1px solid rgba(255, 53, 10)',
    },
    form: {
        maxWidth: '100%',
        flexBasis: '100%',
        padding: '0',
    },
    formWrapper: {
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        display: 'block',
    },
    button: {
        margin: '4px 4px',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            margin: '4px 4px',
        },
    },
    contentWrapper: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
    },
}))
export type Values = {
    valuesString: any[]
}

export const DataUploadForm: React.FC = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [csvFile, setCsvFile] = useState<string | null>(null)
    const [submittingManual, setSubmitting] = useState(true)
    const [recalc, setRecalc] = useState(false)
    const uploading = useSelector((state: AppState) => state.taxSettings.uploading)
    const error = useSelector((state: AppState) => state.taxSettings.error)

    useEffect(() => {
        if (csvFile) {
            const data = {
                valuesString: csvFile,
                recalculate: recalc,
            }
            setSubmitting(false)
            dispatch(actionUploadCsvFile(data))
        }
    }, [csvFile, dispatch])

    const onSubmit = (values: Values) => {
        setSubmitting(true)
        const csvReader = new FileReader()
        csvReader.onload = function (e) {
            const text: any = e.target?.result
            setCsvFile(text)
            setSubmitting(false)
        }

        csvReader.readAsText(values.valuesString[0])
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{}}
            render={({ handleSubmit, values }) => (
                <form id={'FILE_UPLOAD_FORM'} className={classes.formWrapper} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item className={classes.form} xs={12} lg={8}>
                            <div className={classes.contentWrapper}>
                                <label className={classes.fileLabel}>
                                    <Grid container style={{ display: 'flex' }} alignItems="center">
                                        <Grid item xs={12} lg={7} xl={7}>
                                            <div
                                                className={classNames(
                                                    classes.fakeInput,
                                                    values.valuesString?.length > 0 ? classes.ready : '',
                                                    error ? classes.error : '',
                                                )}
                                            >
                                                {(values.valuesString && values.valuesString[0]?.name) ||
                                                    tr(t.TEXT_UPLOAD_DOCUMENT)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <FinalFormFileField
                                        className={classes.hiddenInput}
                                        name={'valuesString'}
                                        accept={'.csv'}
                                        id={'valuesString'}
                                    />
                                </label>

                                <Grid container className={classes.button}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={!values.valuesString?.length || !submittingManual || uploading}
                                    >
                                        {uploading ? <CircularProgress /> : tr(t.TEXT_UPLOAD)}
                                    </Button>
                                </Grid>
                            </div>
                            <FormControlLabel
                                control={<Checkbox checked={recalc} onChange={() => setRecalc(!recalc)} />}
                                label={tr(t.TEXT_CALCULATE)}
                            />
                        </Grid>
                    </Grid>
                </form>
            )}
        />
    )
}
