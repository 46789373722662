import React, { useEffect, useRef, memo } from 'react'
import FullCalendar from '@fullcalendar/react'
import DayGrid from '@fullcalendar/daygrid'
import TimeGrid from '@fullcalendar/timegrid'
import Interaction from '@fullcalendar/interaction'
import LuxonPlugin from '@fullcalendar/luxon'
import ListPlugin from '@fullcalendar/list'

//@ts-ignore
import nbLocale from '@fullcalendar/core/locales/nb'
//@ts-ignore
import enLocale from '@fullcalendar/core/locales/en-gb'

import './style/core.css'

import { useSelector } from 'react-redux'
import { Language } from './../settings/language/language.enum'

import { DateTime } from 'luxon'

export enum CalendarView {
    List = 'listWeek',
    Month = 'dayGridMonth',
    Week = 'timeGridWeek',
    Day = 'timeGridDay',
}

type Props = {
    defaultView: string
    view: CalendarView
    events: any[]
    date: string
    scrollTime: string
    showWeekends: boolean
    onDrop: (args: any) => void
    onDropExternal: (args: any) => void
    eventDragStart: (args: any) => void
    eventDragStop: (args: any) => void
    onResize: (args: any) => void
    onRender: (args: any) => void
    onDateClick: (args: any) => void
    onEventClick: (args: any) => void
}

const getDayHeaderHTML = (date: DateTime, view: CalendarView) => {
    const longView = <>
            <span className="fc-headerDayName">{date.weekdayLong}</span>
            <span className="fc-headerDayNumber">{date.day}</span>
        </>

    const htmlOutputsByCalendarView = {
        [CalendarView.List]: longView,
        [CalendarView.Day]: longView,
        [CalendarView.Week]: longView,
        [CalendarView.Month]: <span className="fc-headerDayName">{date.weekdayLong}</span>,
    }

    return htmlOutputsByCalendarView[view]
}

const getListViewFormat = (date: DateTime): string => {
    return `${date.weekdayLong}, ${date.monthShort}`
}

export const Calendar: React.FC<Props> = props => {
    const {
        defaultView,
        view,
        events,
        date,
        onDateClick,
        onEventClick,
        onDrop,
        onDropExternal,
        eventDragStart,
        eventDragStop,
        onResize,
        onRender,
        showWeekends,
        scrollTime,
    } = props

    const ref = useRef(null)

    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    useEffect(() => {
        if (ref) {
            // @ts-ignore
            ref.current.getApi().changeView(view, date)
        }
    }, [view, date])

    useEffect(() => {
        if (ref) {
            // @ts-ignore
            const calendar = ref.current.getApi()
            calendar.batchRendering(() => {
                calendar.getEvents().forEach((x: any) => x.remove())
                calendar.addEventSource(events)                
            })
        }
    }, [events])

    return (
        <FullCalendar
            ref={ref}
            headerToolbar={false}
            height="100%"
            dayHeaderContent={(date: any) => getDayHeaderHTML(DateTime.fromJSDate(new Date(date.date.toString())).setLocale(language), view)}
            listDayFormat={(a: any) => DateTime.fromJSDate(new Date(a.date.marker.toString())).setLocale(language).day.toString()}
            listDaySideFormat={(a: any) =>
                getListViewFormat(DateTime.fromJSDate(new Date(a.date.marker.toString())).setLocale(language))
            }
            locales={[enLocale, nbLocale]}
            locale={language}
            selectMirror={false}
            weekends={showWeekends}
            // @ts-ignore
            allDaySlot={false}
            defaultAllDay={false}
            dragRevertDuration={0}
            initialView={defaultView}
            plugins={[DayGrid, TimeGrid, ListPlugin, Interaction, LuxonPlugin]}
            editable={true}
            droppable={true}
            dateClick={onDateClick}
            eventClick={onEventClick}
            eventReceive={onDropExternal}
            eventDrop={onDrop}
            eventResize={onResize}
            eventDragStop={eventDragStop}
            eventDragStart={eventDragStart}
            eventContent={onRender}
            eventDisplay={'block'}
            scrollTime={scrollTime}
            nowIndicator={true}
            firstDay={1}
            defaultTimedEventDuration="00:30:00"
            slotDuration="00:30:00"
            slotLabelInterval='00:30'
            slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short'
            }}
            scrollTimeReset={false}
        />
    )
}

export const MemoCalendar = memo(Calendar)
