import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { makeStyles, Theme, Paper} from '@material-ui/core'
import Navigation from 'common/navigation/navigation'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { useDispatch, useSelector } from 'react-redux'
import {
    actionCreateTemplate, 
    actionUpdateTemplate, 
    actionGetTemplate
} from 'settings-page/settings-actions'
import { AppState } from '../app/app-store'
import { Field } from 'redux-form'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { TemplatesToolbar } from './templates-toolbar'
import { TemplateForm } from './templates-form'
import { RouteComponentProps } from '@reach/router'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { stripHtml } from 'common/editor/editor-helper'
import { DocumentTypes } from 'common/enums/document-types'
import { convertToRaw } from 'draft-js'
import { getEditorState } from 'common/editor/editor'
import { stateToHTML } from 'draft-js-export-html'

type SmsTemplatesProps = {
    id: string
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        titleContainer: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(3),
        },
    }
})

export const SmsTemplateEdit: React.FC<RouteComponentProps<SmsTemplatesProps>> = (props) => {
    const { id = '' } = props
    const classes = useStyles()
    const dispatch = useDispatch()
    
    const smsTemplates: any = useSelector((state: AppState) => state.taxSettings[DocumentTypes.SMS])
    const template: any = Object.values(smsTemplates.templates).find((t: any) => t.id === id)
    const initialFormContent = template
        ? {
            ...template,
            body: getEditorState(template.state)
        }
        : {
            body: getEditorState(null)
        }

    const handleSubmit = (values: any) => {
        const htmlBody: any = stateToHTML(values.body.getCurrentContent())
        const textBody: string = stripHtml(htmlBody)
        const content = convertToRaw(values.body.getCurrentContent())
        
        const saveObject: any = {
            ...values,
            documentType: DocumentTypes.SMS,
            state: content,
            bodyText: textBody,
            body: null,
        }
        if (!template) {
            dispatch(actionCreateTemplate(saveObject))
        } else {
            dispatch(actionUpdateTemplate(saveObject))
        }
    }

    const titleBar = () => (
        <Paper elevation={0} className={classes.titleContainer}>
            <Field
                name={TaxFormFieldNames.name}
                component={CubitTextFieldAdapter}
                label={tr(t.TEXT_TITLE)}
            />
        </Paper>
    )

    useEffect(() => {
        if (id !== 'new' && !template) {
            dispatch(actionGetTemplate(id))
        }
    }, [dispatch, id, template])

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={<TemplatesToolbar title={template?.name ? template.name : tr(t.TEXT_SMS)} edit={true} />}
        >
                <TemplateForm 
                    onSubmit={handleSubmit} 
                    initialValues={initialFormContent} 
                    toolbarHidden={true}
                    TitleBar={titleBar}
                />
        </LayoutDefault>
    )
}
