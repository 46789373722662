import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import { InputType } from '../input-type.enum'
import { FormControlLabel } from '@material-ui/core'
import { CubitSelectAdapter } from './cubit-select-adapter'
import { find } from 'lodash'

export type CubitSelectGroupAdapterProps = { type: InputType.SelectGroup }

const CubitSelectGroupAdapter: React.FC<CubitSelectGroupAdapterProps> = (props: any) => {
    const {
        instances,
        options,
        input,
        ...rest
    } = props
    return (
      <FormGroup row>
        {instances.map((instance: any, index: any) =>
            <FormControlLabel
              label={null}
              style={{marginLeft: 0}}
              key={index}
              control={
                <CubitSelectAdapter
                  {...rest}
                  label={instance.label}
                  name={instance.name}
                  options={instance.options}
                  disabled={index > 0 && !input.value}
                  singleValue={find(input.value, {name: instance.name})}
                  input={{
                    ...input,
                    onChange:(e: any) => {
                      let newValue = input.value ? [...input.value] : []
                      const instanceOptions = instance.options
                      const valueIndex = instanceOptions.indexOf(find(instanceOptions, { value: e.target.value.value }))
                      const newItem = {
                        label: instanceOptions[valueIndex].label,
                        value: instanceOptions[valueIndex].value,
                        name: instance.name
                      }
                      if (index === 0 && newValue.length) {
                        newValue = [newItem]
                      } else {
                        newValue[index] = newItem
                      }
                      
                      return input.onChange(newValue)
                    },
                  }}
                />}
            >
            </FormControlLabel>
          )}
      </FormGroup>
    )
}

export { CubitSelectGroupAdapter }