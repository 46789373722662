import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CubitCheckbox } from 'shared-components/src/cubit-inputs/cubit-checkbox/cubit-checkbox'

import styles from './planning-draggable-event.module.css'
import classnames from 'classnames'
import { CubitCalendarEvent } from 'shared-components/src/models/calendar-event'
import { getPropertyValueTypeTranslationKey, getPropertyValueTypeColor } from 'utils'
import { tr } from 'utils/translations/translate'
import { Tooltip } from '@material-ui/core'
import { CubitCalendarExtendedProps } from 'models/calendar-event'

type PlanningDraggableEventProps = {
    data: CubitCalendarEvent<CubitCalendarExtendedProps>
    onSelect: (item: any, event: React.ChangeEvent<HTMLInputElement>) => void
}

const PropertyEventInfo = (data: CubitCalendarEvent<CubitCalendarExtendedProps>) => (
    <>
        <div className={styles.eventTitle}>{data.title}</div>
        <div>{`${data.extendedProps.relatedEntity.propertyAddresses}, ${data.extendedProps.relatedEntity.postalAddress}`}</div>
    </>
)

export const PlanningDraggableEvent: React.FC<PlanningDraggableEventProps> = props => {
    const { data, onSelect } = props

    const valueTypeTranslated = tr(getPropertyValueTypeTranslationKey(data.extendedProps.relatedEntity.valueType))

    return (
        <Grid container className={classnames(styles.event, 'draggableEvent')} alignItems="stretch">
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                className={styles.eventLetter}
                style={{
                    backgroundColor: getPropertyValueTypeColor(
                        data.extendedProps.relatedEntity,
                    ),
                }}
            >
                <Grid item>
                    <Tooltip title={valueTypeTranslated}>
                        <span>{valueTypeTranslated.substr(0, 1)}</span>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs className={styles.eventContent}>
                {PropertyEventInfo(data)}
            </Grid>
            <Grid item className={classnames(styles.eventCheckboxWrapper, 'draggableEventCheckbox')}>
                <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
                    <Grid item>
                        <CubitCheckbox
                            checked={data.isSelected === undefined ? false : data.isSelected}
                            onChange={(a: React.ChangeEvent<HTMLInputElement>) => onSelect(data, a)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
