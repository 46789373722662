import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChipInput from 'material-ui-chip-input'
import {
    Typography,
    Divider,
    TextField,
    IconButton,
    Button,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Theme,
    makeStyles,
    Box,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import { actionSetPreviewPdf } from 'app/app-actions'
import { tr } from 'utils/translations/translate'
import {
    TEXT_RECIPIENT,
    TEXT_COPY_RECIPIENT,
    TEXT_SAVE,
    TEXT_SEND_EMAIL,
    TEXT_SEND_LETTER,
    TEXT_SEND_SMS,
    TEXT_SENDER,
    TEXT_SENT,
    TEXT_TITLE,
    TEXT_SUBJECT,
    TEXT_WROTE,
    TEXT_CANCEL,
    TEXT_ATTACH_FILE_FROM_COMPUTER,
    TEXT_ATTACH_CASE_DOCUMENT,
    TEXT_INBOUND,
    TEXT_OUTBOUND,
    TEXT_NEW_EMAIL,
    TEXT_NEW_SMS,
    TEXT_NEW_LETTER,
    TEXT_REGISTER_PHONECALL,
    TEXT_NEW_COMMUNICATION,
    TEXT_FORWARD,
    TEXT_REPLY_ALL,
    TEXT_REPLY,
} from 'utils/translations/keys'
import { getDate } from '../helper-functions'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { RichEditorField } from 'common/editor/editor'
import { AttachmentOutlined } from '@material-ui/icons'
import { DateTime } from 'luxon'
import { arrayPush, arrayRemove, arraySplice, Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import Dropzone from 'react-dropzone'
import { actionUploadAttachment } from 'property-taxation-edit-page/taxation-actions'
import { plantsUrl } from 'services/httpService'
import { getSessionKey } from 'utils'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
            width: 'calc(100% - 64px)',
            maxWidth: 1280,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        form: {
            height: 'calc(100% - 64px)',
            backgroundColor: '#ffffff',
            width: '100%',
        },
        contents: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
        },
        scrollableArea: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            padding: '0 20px',
        },
        titleSubject: {
            flexGrow: 1,
        },
        titleAction: {
            color: 'white',
        },
        senderInformation: {
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        senderTextField: {
            flexGrow: 1,
        },
        recipientInformation: {
            padding: '10px 20px',
        },
        titleInformation: {
            padding: '10px 20px',
        },
        smsBody: {
            whiteSpace: 'pre-wrap',
            fontFamily: 'inherit',
            padding: '20px',
        },
        actions: {
            padding: 10,
            display: 'flex',
            justifyContent: 'space-between',
        },
        action: {
            margin: '0 10px',
        },
        comTrail: {
            backgroundColor: 'lightGray',
            padding: '10px 25px',
        },
        attachmentControls: {
            display: 'flex',
            padding: 10,
        },
        attachmentControl: {
            marginRight: 20,
        },
        attachmentList: {
            padding: theme.spacing(1),
        },
        attachment: {
            backgroundColor: '#f0f0f0',
            marginBottom: 5,
        },
        hidden: {
            display: 'none',
        },
        removePreview: {
            padding: 4,
            marginLeft: 10,
        }
    }
})

const getTitleForNew = (type: string) => {
    switch (type) {
        case 'email':
            return tr(TEXT_NEW_EMAIL)
        case 'sms':
            return tr(TEXT_NEW_SMS)
        case 'letter':
            return tr(TEXT_NEW_LETTER)
        case 'phone':
            return tr(TEXT_REGISTER_PHONECALL)
    }
    return tr(TEXT_NEW_COMMUNICATION)
}

const Title = ({ classes, entry, responseMode, closeComm }: any) => {
    const subject = responseMode ? getTitleForNew(entry.type) : entry.subject

    return (
        <div className={classes.title}>
            <Typography className={classes.titleSubject}>{subject}</Typography>

            <IconButton className={classes.titleAction} onClick={closeComm}>
                <CloseIcon />
            </IconButton>
        </div>
    )
}

const SenderInformation = ({ classes, entry, responseMode }: any) => {
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)
    let sender = entry.sender
    if (entry.type === 'phone') {
        return <></>
    }
    return (
        <div className={classes.senderInformation}>
            <TextField
                className={classes.senderTextField}
                label={tr(TEXT_SENDER)}
                value={`${sender.name || ''} ${entry.type !== 'sms' ? sender.address : ''}`.trim()}
                InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                }}
            />
            {!responseMode && entry.timeStamp && (
                <TextField
                    label={tr(TEXT_SENT)}
                    value={getDate(entry.timeStamp, language, DateTime.DATETIME_MED)}
                    InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                    }}
                />
            )}
        </div>
    )
}

const RecipientsInformation = (props: any) => {
    const { classes, entry, responseMode, recipients, setRecipients, dispatch, isCopy = false } = props

    const recipientValue = recipients.map((r: any) => `${r.name || ''} ${r.address || ''}`.trim())

    if (!responseMode && recipients.length === 0) {
        return <span></span>
    }
    if (isCopy && (entry.type === 'letter' || entry.type === 'phone')) {
        return <span></span>
    }

    const label = isCopy ? tr(TEXT_COPY_RECIPIENT) : tr(TEXT_RECIPIENT)

    const removeRecipient = (i: number) => {
        const newRecipients = recipients.filter((r: any) => r.address !== recipients[i].address)
        setRecipients(newRecipients)
        const recipientsField = isCopy ? TaxFormFieldNames.copyRecipients : TaxFormFieldNames.recipients
        dispatch(arrayRemove(TaxFormName.CommunicationForm, recipientsField, i))
    }
    const addRecipient = (address: string) => {
        const newRecipient = { name: '', address: address.trim() }
        const newRecipients = [...recipients, newRecipient]
        setRecipients(newRecipients)
        const recipientsField = isCopy ? TaxFormFieldNames.copyRecipients : TaxFormFieldNames.recipients
        dispatch(arrayPush(TaxFormName.CommunicationForm, recipientsField, newRecipient))
    }
    const changePhoneRecipient = (name: string) => {
        const newRecipient = { name: name }
        setRecipients([newRecipient])
        dispatch(arraySplice(TaxFormName.CommunicationForm, TaxFormFieldNames.recipients, 0, 1, newRecipient))
    }

    if (entry.type === 'phone') {
        return (
            <div className={classes.senderInformation}>
                <TextField
                    className={classes.senderTextField}
                    label={tr(TEXT_RECIPIENT)}
                    value={recipients[0].name}
                    onChange={(e: any) => changePhoneRecipient(e.target.value)}
                    InputProps={{
                        disableUnderline: !responseMode,
                        readOnly: !responseMode,
                    }}
                />
            </div>
        )
    }

    return (
        <>
            <div className={classes.recipientInformation}>
                <ChipInput
                    label={label}
                    value={recipientValue}
                    blurBehavior="add"
                    disabled={!responseMode || entry.type === 'letter'}
                    onAdd={(text: string) => addRecipient(text)}
                    onDelete={(t: string, i: number) => removeRecipient(i)}
                    fullWidth
                    disableUnderline={!responseMode}
                    InputProps={{
                        readOnly: !responseMode || entry.type === 'letter',
                    }}
                />
            </div>
            <Divider />
        </>
    )
}

const TitleInformation = ({ classes, entry, responseMode }: any) => {
    if (!responseMode || entry.type === 'sms' || entry.type === 'letter') {
        return <span></span>
    }
    const label = entry.type === TaxFormFieldNames.email ? tr(TEXT_TITLE) : tr(TEXT_SUBJECT)
    return (
        <>
            <div className={classes.titleInformation}>
                <Field
                    name={TaxFormFieldNames.subject}
                    component={CubitTextFieldAdapter}
                    label={label}
                    className={classes.senderTextField}
                />
            </div>
            <Divider />
        </>
    )
}

const CommunicationContents = ({ classes, entry }: any) => {
    if (!entry) return <span></span>
    if (entry.type === 'sms') {
        return <pre className={classes.smsBody}>{entry.body}</pre>
    }
    return <div className={classes.smsBody} dangerouslySetInnerHTML={{ __html: entry.body }} />
}

const CommunicationTrail = ({ classes, entry }: any) => {
    if (!entry.body || entry.type === 'phone') return <span></span>

    return (
        <div className={classes.comTrail}>
            <div>
                <b>
                    {DateTime.fromISO(entry.timeStamp).toFormat('dd. LLL yyyy kl. HH:mm')} {tr(TEXT_WROTE)} {entry.sender.name}:
                </b>
            </div>
            <div className="body">
                {entry.type === 'sms' ? (
                    <pre className={classes.smsBody}>{entry.body}</pre>
                ) : (
                    <div className={classes.smsBody} dangerouslySetInnerHTML={{ __html: entry.body }} />
                )}
            </div>
        </div>
    )
}

const ResponseContents = ({ classes, response }: any) => {
    return (
        <div className="newcommunication">
            <Field
                name={TaxFormFieldNames.editorState}
                component={RichEditorField}
                classes={classes}
                toolbarHidden={response.type === 'sms'}
            />
        </div>
    )
}

const IncomingOutgoing = ({ classes, entry, responseMode }: any) => {
    if (entry.type !== 'phone') return <span></span>

    const options = [
        { label: tr(TEXT_INBOUND), value: 'true' },
        { label: tr(TEXT_OUTBOUND), value: 'false' },
    ]
    return (
        <>
            <Divider />
            <div className={classes.senderInformation}>
                <Field
                    name={TaxFormFieldNames.incoming}
                    component={CubitRadioGroupAdapter}
                    direction="row"
                    disabled={!responseMode}
                    options={options}
                />
            </div>
            <Divider />
        </>
    )
}

const Attachments = ({
    classes,
    entity,
    entry,
    responseMode,
    previewAttachment,
    handleFileDrop,
    selectDocumentAttachment,
    removeAttachment,
}: any) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const showMenu = (e: any) => setAnchorEl(e.currentTarget)
    const hideMenu = () => setAnchorEl(null)
    const attachableCorrespondance: any = entity.correspondance?.filter(
        (c: any) => c.type === 'document' && c.attachmentId,
    ) || []

    if (!responseMode && !entry.attachments.length) {
        return <span></span>
    }
    if (responseMode && entry.type !== 'email') {
        return <span></span>
    }

    const controls = responseMode ? (
        <div className={classes.attachmentControls}>
            {attachableCorrespondance.length ? (
                <Button className={classes.attachmentControl} color="primary" onClick={showMenu}>
                    {tr(TEXT_ATTACH_CASE_DOCUMENT)}
                </Button>
            ) : null}
            <Dropzone
                accept="application/pdf,image/*"
                onDrop={(files) => {
                    handleFileDrop(files)
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()}>
                        <Button variant="outlined" color="primary">
                            <input {...getInputProps()} />
                            {tr(TEXT_ATTACH_FILE_FROM_COMPUTER)}
                        </Button>
                    </Box>
                )}
            </Dropzone>
        </div>
    ) : (
        <></>
    )

    const AttachmentsList = ({ fields }: any) => (
        <List className={classes.attachmentList}>
            {fields.map((fieldName: string, index: number) => {
                const attachment = fields.get(index)
                return (
                    <ListItem
                        key={index}
                        className={classes.attachment}
                        button={!responseMode || attachment.type === 'document' ? undefined : false}
                        onClick={
                            !responseMode || attachment.type === 'document'
                                ? () => previewAttachment(attachment)
                                : undefined
                        }
                    >
                        <ListItemText>{attachment.description}</ListItemText>
                        <AttachmentOutlined />
                        {responseMode && (
                            <IconButton className={classes.removePreview} onClick={(e) => removeAttachment(index, e)}>
                                <CloseIcon />
                            </IconButton>
                        )}
                    </ListItem>
                )
            })}
        </List>
    )

    return (
        <>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={hideMenu}>
                {attachableCorrespondance.map((d: any, i: number) => (
                    <MenuItem
                        key={i}
                        onClick={() => {
                            selectDocumentAttachment(d)
                            hideMenu()
                        }}
                    >
                        {d.title}
                    </MenuItem>
                ))}
            </Menu>
            {controls}
            <FieldArray name={TaxFormFieldNames.attachments} component={AttachmentsList} />
        </>
    )
}

const buttonText = (type: string) => {
    switch (type) {
        case 'email':
            return tr(TEXT_SEND_EMAIL)
        case 'sms':
            return tr(TEXT_SEND_SMS)
        case 'letter':
            return tr(TEXT_SEND_LETTER)
        default:
            return tr(TEXT_SAVE)
    }
}

const Actions = ({
    classes,
    loading,
    entry,
    responseMode,
    sendResponse,
    addResponse,
    cancelResponse,
}: any) => {
    let actions = []

    //actions
    if (entry && responseMode) {
        actions.push(
            <Button className={classes.action} onClick={cancelResponse} key="cancel" color="primary" disabled={loading}>
                {tr(TEXT_CANCEL)}
            </Button>,
        )
    }
    if (responseMode) {
        actions.push(
            <Button
                className={classes.action}
                onClick={sendResponse}
                key="send"
                color="primary"
                variant="contained"
                disabled={loading}
            >
                {buttonText(entry.type)}
            </Button>,
        )
    } else if (entry.type === 'email') {
        actions.push(
            <Button className={classes.action} onClick={addResponse} key="response-forward" color="primary">
                {tr(TEXT_FORWARD)}
            </Button>,
        )
        if (entry.incoming) {
            actions.push(
                <Button
                    className={classes.action}
                    onClick={() => addResponse('all')}
                    key="response-all"
                    color="primary"
                >
                    {tr(TEXT_REPLY_ALL)}
                </Button>,
            )
            actions.push(
                <Button className={classes.action} onClick={() => addResponse('sender')} key="response" color="primary">
                    {tr(TEXT_REPLY)}
                </Button>,
            )
        }
    }

    return (
        <>
            <Divider />
            <div className={classes.actions}>
                <div>{actions}</div>
            </div>
        </>
    )
}

type FormProps = {
    entry: any
    entity: any
    close: any
    cancel: any
    responseMode: boolean
    respond: any
}

const Form: React.FC<InjectedFormProps<any, FormProps> & FormProps> = (props) => {
    const { entry, entity, close, cancel, respond, handleSubmit, submitting, responseMode = false } = props

    //const response = responseMode ? entry : null

    const [recipients, setRecipients] = React.useState(entry.recipients ?? [])
    const [copyRecipients, setCopyRecipients] = React.useState(entry.copyRecipients ?? [])

    const classes = useStyles()

    const dispatch = useDispatch()

    const previewAttachment = (attachment: any) => {
        if (attachment.type === 'document' || attachment.contentType === 'application/pdf') {
            dispatch(actionSetPreviewPdf(attachment.attachmentId, attachment.description))
        } else {
            const contentType = attachment.contentType
                ? attachment.contentType.replace(/\//g, '_')
                : 'application_octet-stream'
            const sessionKey = getSessionKey()
            window.open(
                plantsUrl(
                    `/communication/attachment/${sessionKey}/${attachment.attachmentId}/${contentType}/${attachment.description}`,
                ),
            )
        }
    }

    const selectDocumentAttachment = (document: any) => {
        const attachment = {
            type: 'document',
            description: document.title,
            attachmentId: document.attachmentId,
        }
        dispatch(arrayPush(TaxFormName.CommunicationForm, TaxFormFieldNames.attachments, attachment))
    }

    const removeAttachment = (index: number, e: any) => {
        e.stopPropagation()
        dispatch(arrayRemove(TaxFormName.CommunicationForm, TaxFormFieldNames.attachments, index))
    }

    const handleFileDrop = (acceptedFiles: any) => {
        dispatch(actionUploadAttachment(acceptedFiles, TaxFormName.CommunicationForm, TaxFormFieldNames.attachments))
    }

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.contents}>
                    <Title classes={classes} entry={entry} responseMode={responseMode} closeComm={close} />
                    <div className={classes.scrollableArea}>
                        <SenderInformation
                            classes={classes}
                            entry={entry}
                            responseMode={responseMode}
                        />
                        <Divider />
                        <RecipientsInformation
                            classes={classes}
                            entry={entry}
                            responseMode={responseMode}
                            recipients={recipients}
                            setRecipients={setRecipients}
                            dispatch={dispatch}
                        />
                        {/* Copy recipient */}
                        <RecipientsInformation
                            classes={classes}
                            entry={entry}
                            responseMode={responseMode}
                            recipients={copyRecipients}
                            setRecipients={setCopyRecipients}
                            isCopy={true}
                            dispatch={dispatch}
                        />
                        <IncomingOutgoing classes={classes} entry={entry} responseMode={responseMode} />
                        <TitleInformation classes={classes} entry={entry} responseMode={responseMode} />
                        {!responseMode && <CommunicationContents classes={classes} entry={entry} />}
                        {responseMode && <ResponseContents classes={classes} response={entry} />}
                        <Attachments
                            classes={classes}
                            entity={entity}
                            entry={entry}
                            responseMode={responseMode}
                            previewAttachment={previewAttachment}
                            handleFileDrop={handleFileDrop}
                            selectDocumentAttachment={selectDocumentAttachment}
                            removeAttachment={removeAttachment}
                        />
                        {responseMode && <CommunicationTrail classes={classes} entry={entry} />}
                    </div>
                    <Actions
                        classes={classes}
                        loading={submitting}
                        entry={entry}
                        responseMode={responseMode}
                        sendResponse={handleSubmit}
                        addResponse={respond}
                        cancelResponse={cancel}
                    />
                </div>
            </form>
        </div>
    )
}

export const SelectedCommunication = reduxForm<{}, FormProps>({
    form: TaxFormName.CommunicationForm,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
