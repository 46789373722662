import React from 'react'
import { Card, CardContent, CardHeader, IconButton, makeStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles(() => ({
    information: {
        position: 'absolute',
        zIndex: 1,
        left: '20px',
        bottom: '0px',
        minWidth: 1000
    },
    content: {
        paddingBottom: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
        '& div': {
            padding: 0,
            marginTop: 2,
            marginBottom: 2,
            fontSize: '0.75rem',
            '& span': {
                fontSize: '0.75rem',
            },
        },
        '& td': {
            fontSize: '0.75rem',
        },
    },
}))

interface MapCard {
    onClose: any
    title: any
    content: any
    fullWidth: any
}

const MapCard: React.FC<MapCard> = ({ onClose, title, content, fullWidth = false }) => {
    const classes = useStyles()

    return (
        <Card
            className={classes.information}
            variant={'outlined'}
            style={{ width: fullWidth ? 'calc(100% - 140px)' : undefined }}
        >
            <CardHeader
                title={title}
                titleTypographyProps={{ variant: 'subtitle1', style: { fontWeight: 600 } }}
                action={<IconButton onClick={onClose} children={<Close fontSize="inherit" />} size={'small'} />}
                style={{ paddingBottom: 0 }}
            />
            <CardContent className={classes.content}>{content}</CardContent>
        </Card>
    )
}

export default MapCard
