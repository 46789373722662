import React from 'react'

import { LoginPage } from '../auth/login-page'
import { ApplicationRoute, getRouteComponent } from './app-routes'
import { RegisterPage } from 'auth/register-page'
import { PrieviewPage } from 'document-preview-page/preview-page'

const ApplicationRoutesPublic: ApplicationRoute[] = [
    props => <LoginPage default key={props.key} path="/login" />,

    props =>
        getRouteComponent({
            props,
            path: '/welcome/:newUserKey',
            Component: RegisterPage,
        }),

    props =>
        getRouteComponent({
            props,
            path: '/preview',
            Component: PrieviewPage,
        }),


]

export { ApplicationRoutesPublic }
