import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_CANCEL, TEXT_NEW_USER_GROUP, TEXT_SAVE, TEXT_USER_GROUP } from 'utils/translations/keys'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { actionCreateCompany } from 'app/app-actions'
import { SettingsCompanyFormName, SettingsCompanyForm } from './settings-company-form'
import { Company } from 'models/company'

export const SettingsCompanyAdd: React.FC = () => {
    const dispatch = useDispatch()
    const [addNewCompanyDialogOpen, setAddNewCompanyDialogOpen] = useState(false)

    const handleClose = () => {
        setAddNewCompanyDialogOpen(false)
    }

    const handleOk = () => {
        dispatch(submit(SettingsCompanyFormName))
    }

    const handleSubmit = (company: Company) => {
        dispatch(actionCreateCompany(company))
        setAddNewCompanyDialogOpen(false)
    }

    return (
        <>
            <Button
                variant="outlined"
                color="inherit"
                type="button"
                onClick={() => {
                    setAddNewCompanyDialogOpen(true)
                }}
            >
                {tr(TEXT_NEW_USER_GROUP)}
            </Button>

            <Dialog onClose={handleClose} open={addNewCompanyDialogOpen} disableBackdropClick>
                <DialogTitle>{tr(TEXT_USER_GROUP)}</DialogTitle>
                <DialogContent style={{ width: '480px' }}>
                    <SettingsCompanyForm initialValues={{}} onSubmit={handleSubmit} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit">
                        {tr(TEXT_CANCEL)}
                    </Button>
                    <Button onClick={handleOk} color="primary" autoFocus>
                        {tr(TEXT_SAVE)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
