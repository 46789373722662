import {
    TEXT_ADMINISTRATOR,
    TEXT_TRIBUNAL_ADMINISTRATOR,
    TEXT_TAXATIONS_ADMINISTRATOR,
    TEXT_TAXATIONS_USER,
    TEXT_VIEW_ONLY,
    TEXT_REPORTS_USER,
    TEXT_TAXATION_PLANNER,
    TEXT_TAXATION_PEOPLE,
    TEXT_NEMND_TRIBUNAL,
    TEXT_CASE_WORKER,
} from 'utils/translations/keys'
import { RoleNameKey } from 'common/enums/role-name-key'

const rolesTranslationKeys = {
    [RoleNameKey.Administrator]: TEXT_ADMINISTRATOR,
    [RoleNameKey.TaxationsAdministrator]: TEXT_TAXATIONS_ADMINISTRATOR,
    [RoleNameKey.TribunalAdministrator]: TEXT_TRIBUNAL_ADMINISTRATOR,
    [RoleNameKey.TaxationsUser]: TEXT_TAXATIONS_USER,
    [RoleNameKey.ViewOnly]: TEXT_VIEW_ONLY,
    [RoleNameKey.ReportsUser]: TEXT_REPORTS_USER,
    [RoleNameKey.TaxationPlanner]: TEXT_TAXATION_PLANNER,
    [RoleNameKey.TaxationPeople]: TEXT_TAXATION_PEOPLE,
    [RoleNameKey.NemndTribunal]: TEXT_NEMND_TRIBUNAL,
    [RoleNameKey.CaseWorker]: TEXT_CASE_WORKER,
    [RoleNameKey.BillingUser]: '',
}

export default (role: RoleNameKey): string => rolesTranslationKeys[role] || ''
