import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { FieldArray, reduxForm, Field } from 'redux-form'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import {
    RenderZoneFactorsRow,
    RenderOtherFactorsRow,
    RenderBuildingUseTypeRow,
    RenderStaticFields,
} from './helper-functions'
import { SettingsCommentModal } from './tax-settings-comment-modal'
import { RenderLocationFactors } from './partials/render-location-factors'
import { FromToRow } from './partials/from-to-row'
import { AreaFloorFactors } from './partials/area-floor-factors'
import { StandardFactors } from './partials/standard-factors'

const useStyles = makeStyles((theme: Theme) => {
    return {
        button: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        editButton: {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 3,
        },
        header: {
            fontWeight: 600,
        },
        innerDivider: {
            marginRight: -theme.spacing(3),
            marginLeft: -theme.spacing(3),
            marginTop: theme.spacing(2),
        },
        settingsLabel: {
            fontWeight: 600,
            fontSize: 17,
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
        },
        zonesContent: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        settingsRow: {
            padding: theme.spacing(1, 3, 1, 3),
            fontSize: 16,
            fontWeight: 400,
            position: 'relative',
        },
        shortTextInput: {
            width: '100px !important',
            '& input': {
                paddingLeft: 10,
            },
        },
        fromToTextInput: {
            width: '150px !important',
            maxWidth: '150px',
        },
        columnPadding: {
            paddingRight: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                paddingRight: theme.spacing(2),
            },
        },
        itemPaddding: {
            paddingTop: theme.spacing(0.25),
            paddingBottom: theme.spacing(0.25),
        },
        extraPadding: {
            paddingRight: theme.spacing(8),
            [theme.breakpoints.down('xs')]: {
                paddingRight: theme.spacing(2),
            },
        },
    }
})

const Form = (props: any) => {
    const { handleSubmit, readOnly, canCancel } = props
    const stateRef = useSelector((state: AppState) => state)
    const classes = useStyles()

    return (
        <form onSubmit={handleSubmit}>
            <Field
                name={TaxFormFieldNames.zoneFactors}
                component={RenderZoneFactorsRow}
                classes={classes}
                stateRef={stateRef}
                rowLabels={{
                    label: tr(t.TEXT_ZONES),
                    subLabel: tr(t.TEXT_FACTOR),
                }}
                removable={false}
                readOnly={readOnly}
                canCancel={canCancel}
            />
            <FieldArray
                name={TaxFormFieldNames.locationFactors}
                component={RenderLocationFactors}
                classes={classes}
                stateRef={stateRef}
                rowLabels={{
                    label: tr(t.TEXT_LOCATION),
                    subLabel: tr(t.TEXT_FACTOR),
                    from: tr(t.TEXT_FROM),
                    to: tr(t.TEXT_TO),
                }}
                readOnly={readOnly}
                canCancel={canCancel}
            />
            <FieldArray
                name={TaxFormFieldNames.otherFactors}
                component={RenderOtherFactorsRow}
                classes={classes}
                stateRef={stateRef}
                rowLabels={{
                    label: tr(t.TEXT_GROUNDS),
                }}
                readOnly={readOnly}
                canCancel={canCancel}
            />
            <FieldArray
                name={TaxFormFieldNames.locationModifiers}
                component={FromToRow}
                classes={classes}
                stateRef={stateRef}
                rowLabels={{
                    label: tr(t.TEXT_MODIFYING_FACTORS),
                }}
                readOnly={readOnly}
                canCancel={canCancel}
            />
            <FieldArray
                name={TaxFormFieldNames.standardFactors}
                component={StandardFactors}
                classes={classes}
                stateRef={stateRef}
                rowLabels={{
                    label: tr(t.TEXT_STANDARD),
                    subLabel: tr(t.TEXT_FACTOR),
                    from: tr(t.TEXT_FROM),
                    to: tr(t.TEXT_TO),
                }}
                readOnly={readOnly}
                canCancel={canCancel}
            />
            <FieldArray
                name={TaxFormFieldNames.otherFactors2}
                component={RenderOtherFactorsRow}
                classes={classes}
                stateRef={stateRef}
                rowLabels={{
                    label: tr(t.TEXT_GROUNDS),
                }}
                readOnly={readOnly}
                canCancel={canCancel}
            />
            <FieldArray
                name={TaxFormFieldNames.propertyModifiers}
                component={FromToRow}
                classes={classes}
                stateRef={stateRef}
                rowLabels={{
                    label: tr(t.TEXT_MODIFYING_FACTORS),
                }}
                readOnly={readOnly}
                canCancel={canCancel}
            />
            <FieldArray
                name={TaxFormFieldNames.buildingUse}
                component={RenderBuildingUseTypeRow}
                classes={classes}
                stateRef={stateRef}
                rowLabels={{
                    label: tr(t.TEXT_BUILDING_USE_TYPE),
                    valueLabels: [tr(t.TEXT_M2_PRICE), tr(t.TEXT_DONT_USE_FLOOR_FACTOR), tr(t.TEXT_BUILDING_CODE)],
                }}
                readOnly={readOnly}
                canCancel={canCancel}
            />
            <AreaFloorFactors classes={classes} stateRef={stateRef} readOnly={readOnly} canCancel={canCancel} />
            <Field
                stateRef={stateRef}
                readOnly={readOnly}
                canCancel={canCancel}
                classes={classes}
                name="static"
                fields={[
                    {
                        label: tr(t.TEXT_MIN_BUILDING_AREA),
                        name: TaxFormFieldNames.minBuildingArea,
                        endAdornment: 'm²',
                    },
                ]}
                component={RenderStaticFields}
            />
            <Field
                stateRef={stateRef}
                readOnly={readOnly}
                canCancel={canCancel}
                settingLabel={tr(t.TEXT_FARM)}
                classes={classes}
                name="static"
                fields={[
                    {
                        label: tr(t.TEXT_PLOT_AS_DWELLING),
                        name: TaxFormFieldNames.livingSpacePlot,
                        endAdornment: 'm²',
                    },
                    {
                        label: tr(t.TEXT_MAX_HOUSING_SIZE),
                        name: TaxFormFieldNames.maxHousingSize,
                        endAdornment: 'm²',
                    },
                ]}
                component={RenderStaticFields}
            />
            <Field
                stateRef={stateRef}
                readOnly={readOnly}
                canCancel={canCancel}
                component={RenderStaticFields}
                classes={classes}
                name="static"
                lastItem={true}
                fields={[
                    {
                        label: tr(t.TEXT_ATTACHMENT_PLOT_VALUE),
                        name: TaxFormFieldNames.attachmentPlotValue,
                        endAdornment: '%',
                    },
                    {
                        label: tr(t.TEXT_POINTLESS_SIZE),
                        name: TaxFormFieldNames.pointlessSize,
                        endAdornment: 'm²',
                    },
                    {
                        label: tr(t.TEXT_UNKNOWN_PLOT_SIZE),
                        name: TaxFormFieldNames.unknownPlotSize,
                        endAdornment: 'm²',
                    },
                    {
                        label: tr(t.TEXT_VALUE_PER_PARKING),
                        name: TaxFormFieldNames.valuePerParkingSpace,
                        endAdornment: 'kr',
                    },
                ]}
            />
            <SettingsCommentModal />
        </form>
    )
}

interface FormProps {
    readOnly: boolean
    canCancel: any
}
export const TaxSettingsForm = reduxForm<{}, FormProps>({
    form: TaxFormName.TaxationSettings,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
