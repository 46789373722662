import { YES } from "common/constants/constants";
import { Floor } from "models/floor";
import { CalculationDetails, isPropertyUsingFactors, Taxation, TaxationType } from "models/taxation";
import { getOfficeAdjustment, YearSettings } from "models/year-settings";
import { formatPercentage, formatToPermille } from "year-settings-page/helper-functions";
import { formatArea, formatCurrency } from "shared-components/src/utils";
import { tr } from "utils/translations/translate";
import * as t from 'utils/translations/keys';
import { TableRowContent } from "./table-row";
import { Building } from "models/building";
import { Property } from "models/property";
import { DateTime } from "luxon";
import { getOver1000Multiplier, getPropertyValueTypeDescription, getUnder1000Multiplier } from "utils/property/property-helper";

export const oneIfNull = (item: number | undefined): number => item !== null && item !== undefined ? item : 1

export const getFloorValue = (floor: Floor, standardFactor: number, adjustment: number = 0): number =>
    oneIfNull(floor.usageFactor) * oneIfNull(floor.area) * oneIfNull(floor.pricePerM2) * standardFactor * (1 + (adjustment / 100))

export const formatToPercent = (value: any): string => value !== undefined ? `${Math.round(value)} %` : '-'

export const getDwellingPercentage = (rate: number): string => formatToPercent(rate * 100)

export const getOtherPercentage = (rate: number): string => formatToPercent(100 - rate * 100)

export const getPrice = (price: number): string => formatCurrency(price)

export const formatFactor = (factor: number): number | string => (getFactor(factor)).toFixed(2)

export const getPermile = (value: number): string => `${formatToPermille(value)} ‰`

export const roundPercents = (value: number): number => Math.round(value * 100) / 100;

export const getDwellingPlotSize = (details: CalculationDetails): string => formatArea(details.plotSize * roundPercents(details.livingAreaPercentage))

export const getOtherPlotSize = (details: CalculationDetails): string => formatArea(details.plotSize * roundPercents(1 - details.livingAreaPercentage))

export const getDwellingPlotPrice = (details: CalculationDetails, property: Property): string => getPrice(details.dwellingLandValue / property.locationFactor / property.zoneFactor)

export const getOtherPlotPrice = (details: CalculationDetails, property: Property): string => getPrice(details.otherLandValue / property.locationFactor / property.zoneFactor)

export const getDwellingValue = (details: CalculationDetails, property: Property): string => {
    if (!isPropertyUsingFactors(property)) {
        return getPrice((details.dwellingLandValue + details.dwellingBuildingsValue))
    }
    return getPrice((details.dwellingLandValue + details.dwellingBuildingsValue) / property.zoneFactor / property.locationFactor)
}

export const getOtherValue = (details: CalculationDetails, property: Property): string => {
    if (!isPropertyUsingFactors(property)) {
        return getPrice((details.otherLandValue + details.otherBuildingsValue))
    }
    return getPrice((details.otherLandValue + details.otherBuildingsValue) / property.zoneFactor / property.locationFactor)
}

export const getFlatReduction = (settings: YearSettings): string => {
    if (!settings || settings.useFlatReduction !== YES || settings.flatReductionValue === null) {
        return ''
    }
    return getPrice(settings.flatReductionValue)
}

export const getBuildingTableHeader = (styles: any): TableRowContent[] => [
    {
        value: tr(t.TEXT_FLOOR),
    },
    {
        value: tr(t.TEXT_USE),
    },
    {
        value: tr(t.TEXT_FLOOR_FACTOR),
    },
    {
        value: tr(t.TEXT_STANDARD),
    },
    {
        value: tr(t.TEXT_M2_PRICE),
    },
    {
        value: 'BRA',
        classes: styles.center,
    },
    {
        value: tr(t.TEXT_OFFICE_ADJUSTMENT),
    },
    {
        value: tr(t.TEXT_VALUE),
        classes: styles.right,
    },
]

export const getFloorRowContent = (styles: any, floor: Floor, building: Building, zoneFactor: number, yearSettings: any): TableRowContent[] => [
    {
        value: floor.floorNumber,
    },
    {
        value: floor.usage,
    },
    {
        value: formatFactor(floor.usageFactor),
    },
    {
        value: formatFactor(building.standardFactor),
    },
    {
        value: formatCurrency(floor.pricePerM2),
    },
    {
        value: formatArea(floor.area),
        classes: styles.center,
    },
    {
        value: formatPercentage(getOfficeAdjustment(yearSettings)),
    },
    {
        value: formatCurrency(getFloorValue(floor, building.standardFactor, getOfficeAdjustment(yearSettings))),
        classes: styles.right,
    },
]

export const getPlotTableHeaderContent = (styles: any): TableRowContent[] => [
    {
        value: tr(t.TEXT_USE),
    },
    {
        value: `${tr(t.TEXT_VALUE_UNDER)} 1000 m²`,
    },
    {
        value: `${tr(t.TEXT_VALUE_OVER)} 1000 m²`,
    },
    {
        value: tr(t.TEXT_OFFICE_ADJUSTMENT),
    },
    {
        value: tr(t.TEXT_VALUE),
        classes: styles.right,
    },
]

export const getPlotTableSubHeaderContent = (styles: any): TableRowContent[] => [
    {
        value: tr(t.TEXT_TAX_RATE),
    },
    {
        value: tr(t.TEXT_PERCENTAGE_SHARE),
    },
    {
        value: '',
        classes: styles.tableItem
    },
    {
        value: '',
        classes: styles.tableItem
    },
    {
        value: tr(t.TEXT_VALUE),
        classes: styles.right,
    },
]

export const getPlotOtherContent = (styles: any, taxation: Taxation): TableRowContent[] => [
    {
        value: tr(t.TEXT_ORDINARY),
    },
    {
        value: getOtherPercentage(taxation.calculationDetails.livingAreaPercentage),
    },
    {
        value: '',
        classes: styles.tableItem
    },
    {
        value: '',
        classes: styles.tableItem
    },
    {
        value: getOtherPlotPrice(taxation.calculationDetails, taxation.current.property),
        classes: styles.right,
    },
]

export const getPlotDwellingContent = (styles: any, taxation: Taxation): TableRowContent[] => [
    {
        value: tr(t.TEXT_LIVING),
    },
    {
        value: getDwellingPercentage(taxation.calculationDetails.livingAreaPercentage),
    },
    {
        value: '',
    },
    {
        value: '',
    },
    {
        value: getDwellingPlotPrice(taxation.calculationDetails, taxation.current.property),
        classes: styles.right,
    },
]

export const getPlotGeneralContent = (styles: any, taxation: Taxation): TableRowContent[] => {
    const calc = taxation.calculationDetails;
    const under = calc.plotSize > 1000 ? 1000 : calc.plotSize
    const over = calc.plotSize > 1000 ? (calc.plotSize - 1000) : 0
    return [
        {
            value: getPropertyValueTypeDescription(taxation.current.property.valueType),
        },
        {
            value: `${under}m² x ${getUnder1000Multiplier(taxation.current.property)}kr/m²`,
        },
        {
            value: `${over}m² x ${getOver1000Multiplier(taxation.current.property)}kr/m²`,
        },
        {
            value: formatPercentage(getOfficeAdjustment({settings: [taxation.yearSettings]})),
        },
        {
            value: formatCurrency(getFinalPlotValue(taxation)),
            classes: styles.right,
        },
    ]
}

export const getTaxDate = (taxation: Taxation, language: any) => {
    const date = taxation.start
    return date ? 
        DateTime.fromJSDate(new Date(date))
            .setLocale(language)
            .toLocaleString(DateTime.DATE_SHORT)
        : ''
}

export const getTaxYear = (taxation: Taxation) => {
    const date = taxation.start
    return date ? 
        DateTime.fromJSDate(new Date(date)).year
        : ''
}

export const getTaxationTypeText = (taxation: Taxation) => {
    const type = taxation.type
    switch (type) {
        case TaxationType.OfficeAdjustment:
            return tr(t.TEXT_OFFICE_ADJUSTMENT)
    
        default:
            return tr(t.TEXT_TAXATIONS)
    }
}

export const getTaxationMethodText = (taxation: Taxation) => {
    const type = taxation.type
    switch (type) {
        case TaxationType.OfficeAdjustment:
            return tr(t.TEXT_OFFICE_ADJUSTMENT)
    
        default:
            return tr(t.TEXT_TAXATIONS)
    }
}

export const getFinalPlotValue = (taxation: Taxation) => {
    const otherPrice = taxation.calculationDetails.otherLandValue / taxation.current.property.locationFactor / taxation.current.property.zoneFactor
    const residential = taxation.calculationDetails.dwellingLandValue / taxation.current.property.locationFactor / taxation.current.property.zoneFactor
    return (otherPrice + residential)
}

export const getFactor = (factor: any) => {
    if (!factor || isNaN(factor) || factor === 'Infinity') {
        return 1
    }
    return factor
}