import React from 'react'
import { Modal } from '@material-ui/core'
import { SelectedCommunication } from './selected-communication'
import { DateTime } from 'luxon'
import { convertToRaw, EditorState } from 'draft-js'
import { draftJsToHtml, replaceMentions } from 'common/editor/editor-helper'
import { SubmissionError } from 'redux-form'
import { useDispatch } from 'react-redux'

export const CommunicationModal = (props: any) => {
    const { entry, taxation, close, cancel, respond, responseMode, sendAction } = props
    const dispatch = useDispatch()

    const handleSend = (values: any) => {
        if (!values.recipients.length) {
            const error = 'Recipient is missing.'
            alert(error)
            throw new SubmissionError({
                _error: error
            })
        }
        const editorContentState = values.editorState ? values.editorState : EditorState.createEmpty()
        const contentState = editorContentState.getCurrentContent()
        const body = replaceMentions(draftJsToHtml(contentState), {taxation})
        if (values.type === 'sms') {
            //only the new content goes to sms
            values.body = replaceMentions(contentState.getPlainText(), {taxation})
            
        } else {
            //add the previous mail to the body as a quote
            if (values.body) {
                const previousContent = values.body
                //move the new content to the start if this is a reply
                values.body = body
                values.body += `\n<div style='margin: 30px 0 15px 0'><b>${DateTime.fromISO(values.timeStamp).toFormat('dd. LLL yyyy kl. HH:mm')} skrev ${values.sender.name}:</b></div>\n`
                values.body += previousContent
            } else {
                values.body = body
            }
        }
        //add id
        values.forId = taxation.id
        //remove editor state object to optimize bandwidth usage if not sending a letter
        if (values.type !== 'letter') {
            values.editorState = null
        } else {
            //send state for letter generating
            values.state = convertToRaw(values.editorState.getCurrentContent())
        }
        dispatch(sendAction(values))
        close()
    }

    return (
        <Modal open={true} onClose={cancel} aria-labelledby="communication-modal" aria-describedby="communication-modal">
            <>
                <SelectedCommunication
                    entry={entry}
                    entity={taxation}
                    close={close}
                    cancel={cancel}
                    onSubmit={handleSend}
                    initialValues={entry}
                    respond={respond}
                    responseMode={responseMode}
                />
            </>
        </Modal>
    )
}
