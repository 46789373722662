import { stateToHTML } from 'draft-js-export-html'
import React from 'react'

import { Editor } from 'react-draft-wysiwyg'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classnames from 'classnames'
import { convertFromRaw, DefaultDraftBlockRenderMap, EditorState } from 'draft-js'
import { mergeFields } from './editor-helper'
import { mergeFieldSpan, mergeFieldStrategy } from 'settings-templates/template-document-helper'

const useStyles = makeStyles(() => ({
    editor: {
        position: 'relative',
        '& .DraftEditor-root, & .public-DraftEditor-content': {
            minHeight: 200,
        },
        '& .public-DraftStyleDefault-block': {
            margin: '2px 0',
        },
        '& .rdw-editor-toolbar': {
            borderRadius: 0,
            alignItems: 'center',
            padding: '6px 5px 6px 20px',
            border: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        '& .rdw-editor-main': {
            padding: '10px 24px',
        },
        '& .rdw-editor-toolbar > div': {
            marginBottom: 0,
        },
        '& .rdw-suggestion-option': {
            cursor: 'pointer',
            padding: 10,
            backgroundColor: '#ffffff',
            transition: 'background-color 0.2s ease-in',
            '&:hover': {
                backgroundColor: 'rgba(0, 118, 255, 0.26)',
            }
        },
        '& .rdw-suggestion-dropdown': {
            '&::-webkit-scrollbar': {
                width: 0,
                background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#FF0000',
            }
        }
    },
    label: {
        padding: '2px 4px',
        fontSize: 12,
        position: 'absolute',
        background: '#ffffff',
        top: -14,
        left: 10,
    },
}))

const toolbar = {
    options: ['blockType', 'fontSize', 'inline', 'list', 'textAlign', 'link', 'colorPicker', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    blockType: {
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30],
    },
    list: {
        options: ['unordered', 'ordered'],
    },
    textAlign: {
        inDropdown: true,
        options: ['left', 'center', 'right', 'justify'],
    },
}

export const RichEditorField = ({ input, toolbarCustomButtons, toolbarHidden } : any) => {
    const classes = useStyles()
    //const [editorRef, setRef] = React.useState(undefined)
    const value = input.value ? input.value : EditorState.createEmpty()
    // React.useEffect(() => {
    //     if (editorRef) {
    //         (editorRef as any).focus()
    //     }
    // }, [editorRef])
    // const setEditorReference = (ref: any) => {
    //     if (ref) {
    //         setRef(ref)
    //     }
    // }
    return (
        <div className={classnames(classes.editor, 'editor')}>
            <Editor
                //editorRef={setEditorReference}
                stripPastedStyles={true}
                toolbar={toolbar}
                toolbarHidden={toolbarHidden}
                customBlockRenderFunc={(DefaultDraftBlockRenderMap as any)}
                editorState={value}
                toolbarCustomButtons={toolbarCustomButtons}
                onEditorStateChange={input.onChange}
                mention={mergeFields}
                customDecorators={[
                    {
                        strategy: mergeFieldStrategy,
                        component: mergeFieldSpan,
                    },
                ]}
            />
        </div>
    )
}
export const EditorPreview = ({ value }: any) => {
    const markup = stateToHTML(value)
    return <div dangerouslySetInnerHTML={{ __html: markup }} />
}

export const getEditorState = (rawState: any) => {    
    if (rawState) {
        const contentState = convertFromRaw(rawState)
        return EditorState.createWithContent(contentState);
    } else {
        return EditorState.createEmpty()
    }
}