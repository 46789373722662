import { Building } from 'models/building'
import { Property, PropertyStatus } from 'models/property'

export default (property: Property, buildings?: Building[]): boolean => {
    return true
    // if (!property.state) {
    //     return true
    // }

    // const taxableStates = [PropertyStatus.TAXATED, PropertyStatus.NOTIFIED, PropertyStatus.NORMAL, PropertyStatus.PICKED, PropertyStatus.ASSIGNED_COMPANY, PropertyStatus.ASSIGNED_USER]
    // const taxNoticeSent = property.otherPropertyFlags?.taxNoticeSent
    // return (!!property.state?.value && taxableStates.includes(property.state.value)) || taxNoticeSent
}
