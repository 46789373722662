import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { tr } from 'utils/translations/translate'
import { TEXT_USER_GROUPS } from 'utils/translations/keys'
import { useDispatch, useSelector } from 'react-redux'
import { actionSettingsGroupsGet } from 'shared-components/src/settings/groups/groups-actions'
import { RouteComponentProps } from '@reach/router'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { useCompanies } from 'app/app-selectors'
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Theme, Grid } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { SettingsCompanyDetails } from './settings-company-details'
import { SettingsCompanyAdd } from './settings-company-add'
import { SettingsCompanyEdit } from './settings-company-edit'
import { AppState } from 'app/app-store'
import { actionGetTaxSettings } from 'settings-page/settings-actions'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
        },

        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },
    }
})

export const SettingsCompaniesPage: React.FC<RouteComponentProps> = () => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)

    useEffect(() => {
        if (!settings?.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [settings, dispatch])

    useEffect(() => {
        dispatch(actionSettingsGroupsGet())
    }, [dispatch])

    const companies = useCompanies()

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={
                <TitleToolbar title={tr(TEXT_USER_GROUPS)}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <SettingsCompanyAdd></SettingsCompanyAdd>
                        </Grid>
                    </Grid>
                </TitleToolbar>
            }
        >
            {companies.map((c, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.panelSummary}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>{c.name}</Grid>

                            <Grid item onClick={(e) => e.stopPropagation()}>
                                <SettingsCompanyEdit company={c}></SettingsCompanyEdit>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={styles.panelContent}>
                        <SettingsCompanyDetails company={c}></SettingsCompanyDetails>
                    </AccordionDetails>
                </Accordion>
            ))}
        </LayoutDefault>
    )
}
