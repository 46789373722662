import { Divider, Grid } from '@material-ui/core'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { ComplaintFloor } from 'models/floor'
import { Taxation } from 'models/taxation'
import { getBuildingsValue, getBuildingTableHeadItem, getDifference } from 'property-taxation-edit-page/helper-functions'
import React from 'react'
import { Field } from 'redux-form'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { formatArea } from 'shared-components/src/utils'
import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import { ComplaintCause } from 'tribunal-page/models/complaint-cause.type'
import { Complaint } from 'tribunal-page/models/complaint.type'
import { TEXT_DIFFERENCE, TEXT_ORIGINAL_TAXATION, TEXT_UPDATED_TAXATION } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { BuildingDetails } from './building-details'
import { CauseType } from './cause-type'
import { ComplaintFiles } from './complaint-files'
import { ComplaintPanel } from './complaint-panel'

type BuildingAreaComplaintProps = {
    styles: any
    cause: ComplaintCause<ComplaintCauseType.BuildingArea>
    taxation: Taxation
    complaint: Complaint
    index: number
    complaintIndex: number
}

export const BuildingAreaComplaint: React.FC<BuildingAreaComplaintProps> = (props) => {
    const { styles, cause, taxation, complaint, index, complaintIndex } = props
    const causeProcessed = !!cause.state
    const getUseOptions = (settings: any) => {
        if (!settings.buildingUsages) {
            return [
                {
                    label: '',
                    value: '',
                },
            ]
        }
        return settings.buildingUsages.map((use: any) => {
            return {
                label: use.name,
                value: use.name,
            }
        })
    }
    const handleAreaChange = (value: number) => {
        cause.originalBuildingsValue = taxation.current.property.plotSize
    }
    const displayTableHeadRow = () => {
        const items: any = []
        for (let i = 0; i < 7; i++)
            items.push(
                <Grid item xs key={i}>
                    {getBuildingTableHeadItem(i)}
                </Grid>,
            )
        return items
    }
    return (
        <ComplaintPanel
            styles={styles}
            cause={cause}
            taxation={taxation}
            complaint={complaint}
            index={index}
            complaintIndex={complaintIndex}
        >
            <Grid container spacing={2} className={styles.panelBox}>
                <CauseType causeType={cause.type} styles={styles} />
                <BuildingDetails buildings={cause.buildings} styles={styles} taxation={taxation} />
                {cause.buildings && (
                    <Grid container className={styles.buildingFloorsTable}>
                        <Grid container spacing={1} className={styles.tableHead}>
                            {displayTableHeadRow()}
                        </Grid>
                        <Divider className={styles.divider} />
                        {cause.buildings.map(
                            (building: any, bIndex: number) =>
                                building.floors &&
                                building.floors.filter((f: any) => !f.matrikkelUnitId || f.matrikkelUnitId === taxation.propertyId).map((floor: ComplaintFloor, fIndex: number) => (
                                    <Grid container key={`${bIndex}-${fIndex}`}>
                                        <Grid container spacing={1} className={styles.tableRow}>
                                            <Grid
                                                item
                                                md
                                                xs={12}
                                                className={`${styles.tableCell} ${styles.tableCell0}`}
                                            >
                                                {floor.floorNumber}
                                            </Grid>
                                            <Grid
                                                item
                                                md
                                                xs={12}
                                                className={`${styles.tableCell} ${styles.tableCell1}`}
                                            >
                                                {floor.usage}
                                            </Grid>
                                            <Grid
                                                item
                                                md
                                                xs={12}
                                                className={`${styles.tableCell} ${styles.tableCell2}`}
                                            >
                                                {floor.correctUsage}
                                            </Grid>
                                            <Grid
                                                item
                                                md
                                                xs={12}
                                                className={`${styles.tableCell} ${styles.tableCell3}`}
                                            >
                                                <Grid item xs={6}>
                                                    <Field
                                                        name={`complaints.[${complaintIndex}].causes[${index}].buildings[${bIndex}].floors[${fIndex}].${TaxFormFieldNames.correctUsageApproved}`}
                                                        format={(value: string) => value || floor.correctUsage}
                                                        options={getUseOptions(taxation.staticSettings)}
                                                        component={CubitSelectAdapter}
                                                        valueIsObject={false}
                                                        disabled={!floor.correctUsage || causeProcessed}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                md
                                                xs={12}
                                                className={`${styles.tableCell} ${styles.tableCell4}`}
                                            >
                                                {formatArea(floor.area)}
                                            </Grid>
                                            <Grid
                                                item
                                                md
                                                xs={12}
                                                className={`${styles.tableCell} ${styles.tableCell5}`}
                                            >
                                                {formatArea(floor.correctArea)}
                                            </Grid>
                                            <Grid
                                                item
                                                md
                                                xs={12}
                                                className={`${styles.tableCell} ${styles.tableCell6}`}
                                            >
                                                <Grid item xs={6}>
                                                    <Field
                                                        name={`complaints.[${complaintIndex}].causes[${index}].buildings[${bIndex}].floors[${fIndex}].${TaxFormFieldNames.adjustedArea}`}
                                                        component={CubitTextFieldAdapter}
                                                        className={styles.dashedInput}
                                                        InputProps={{ classes: { underline: styles.underline } }}
                                                        parse={(value: any) => (value ? parseFloat(value) : value)}
                                                        onChange={handleAreaChange as any}
                                                        variant="standard"
                                                        type="number"
                                                        disabled={causeProcessed}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider className={styles.divider} />
                                    </Grid>
                                )),
                        )}
                    </Grid>
                )}
                <Grid container spacing={2} className={styles.tableRow}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_ORIGINAL_TAXATION)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {cause.originalBuildingsValue || taxation.current.property.buildingsValue} kr
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_UPDATED_TAXATION)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {getBuildingsValue(cause.buildings, taxation)} kr
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_DIFFERENCE)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {getDifference(cause.originalBuildingsValue || taxation.current.property.buildingsValue, getBuildingsValue(cause.buildings, taxation))} kr
                        </Grid>
                    </Grid>
                </Grid>
                <ComplaintFiles cause={cause} styles={styles} />
            </Grid>
        </ComplaintPanel>
    )
}
