import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { tr } from 'utils/translations/translate'
import {
    TEXT_APPROVED,
    TEXT_APPROVED_TRIBUNAL,
} from 'utils/translations/keys'
import { Grid, makeStyles, Theme, Card } from '@material-ui/core'
import { AppState } from 'app/app-store'
import { grey } from '@material-ui/core/colors'
import { navigate, RouteComponentProps } from '@reach/router'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { actionTribunalGetApproved } from 'tribunal-page/tribunal-actions'

const useTribunalPageStyles = makeStyles((theme: Theme) => ({
    category: {
        padding: theme.spacing(1, 2, 1, 3),
        marginBottom: theme.spacing(1),
        boxShadow: 'none',
    },

    categoryName: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 'bold',
        maxWidth: '220px',
        flexBasis: '220px',
    },

    group: {
        cursor: 'pointer',
        color: grey[700],

        border: '1px solid' + grey[400],
        padding: theme.spacing(0.5, 1.5),
        margin: theme.spacing(0.5, 1),

        '&:hover': {
            color: grey[900],
            border: '1px solid' + grey[600],
        },
    },
    groupName: {
        paddingTop: '1px',
        fontSize: theme.typography.subtitle1.fontSize,
    },
    groupItemCount: {
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.primary.main,
        fontWeight: 500,
        paddingLeft: theme.spacing(2),
    },
}))

export const TribunalApprovedChip: React.FC<RouteComponentProps> = () => {
    const styles = useTribunalPageStyles()
    const dispatch = useDispatch()

    const approved = useSelector((state: AppState) => state.tribunal.approved)
    const count = approved.length
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        if (!loading && !count)
        {
            setLoading(true)
            dispatch(actionTribunalGetApproved())
        }
    }, [loading, setLoading, dispatch, count])



    const handleClick = () => {
        navigate('/tribunal/group/historic')
    }

    return (

        <Protected p={Permission.ViewTribunalAll}>
            <Card className={styles.category}>
                <Grid container alignItems="center">
                    <Grid item xs className={styles.categoryName}>
                        {tr(TEXT_APPROVED)}
                    </Grid>
                    <Grid
                        item
                        className={styles.group}
                        onClick={() => handleClick()}
                    >
                        <Grid container alignItems="center">
                            <Grid item className={styles.groupName}>
                                {tr(TEXT_APPROVED_TRIBUNAL)}
                            </Grid>
                            <Grid item className={styles.groupItemCount}>
                                {count}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Protected>
    )
}
