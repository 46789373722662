import React, { useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { tr } from 'utils/translations/translate'
import { TEXT_NAME, TEXT_SEARCH, TEXT_SELECT_TENANT, TEXT_USERS } from 'utils/translations/keys'
import Divider from '@material-ui/core/Divider'
import { useDispatch, useSelector } from 'react-redux'
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
} from '@material-ui/core'
import { AppState } from 'app/app-store'
import { actionLoadTenants, actionSwitchTenant } from 'app/app-actions'
import { TENANT_TYPES } from 'common/enums/tenant'
import { sortBy } from 'lodash'

const useStyles = makeStyles((theme: Theme) => ({
    languageLabel: {
        paddingRight: theme.spacing(1),
    },
    navigationItem: {},
    activeLink: {},
    center: {
        textAlign: 'center',
    },
    tenantSelector: {
        marginTop: 10,
        cursor: 'pointer',
    },
    dialogTitle: {
        textAlign: 'center',
    },
    dialogContent: {
        minHeight: 800,
    },
    tableRow: {
        cursor: 'pointer',
    },
    loader: {
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tenantName: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        marginRight: 10,
    },
    header: {
        display: 'flex',
        'align-items': 'baseline',
        'justify-content': 'center',
    },
    limitedDialogMenuContainer: {
        margin: 50,
    },
    searchField: {
        padding: '20px 0',
    },
}))

export const TenantSwitch: React.FC = () => {
    const [tab, setTab] = React.useState(0)
    const [search, setSearch] = React.useState('')
    const [open, setOpen] = React.useState(false)

    const onTabChanged = (event: any, newValue: number) => {
        setTab(newValue)
    }

    const styles = useStyles()

    const dispatch = useDispatch()
    const tenants = useSelector((state: AppState) => state.appData.tenants)

    useEffect(() => {
        if (!tenants) {
            dispatch(actionLoadTenants())
        }
    })

    const TenantTab = ({ index, value, tenants }: any) => {
        const classes = useStyles()
        const dispatch = useDispatch()
        return (
            <div role="tabpanel" hidden={value !== index}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{tr(TEXT_NAME)}</TableCell>
                                <TableCell>{'DB'}</TableCell>
                                <TableCell>{tr(TEXT_USERS)}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tenants &&
                                sortBy(tenants, 'name').map((tenant) => (
                                    <TableRow
                                        onClick={() => dispatch(actionSwitchTenant(tenant))}
                                        className={classes.tableRow}
                                        hover
                                        key={tenant.id}
                                    >
                                        <TableCell>
                                            <div className={classes.tenantName}>
                                                <Avatar className={classes.avatar} alt={tenant.name}>
                                                    {tenant.name[0]}
                                                </Avatar>
                                                {tenant.name}
                                            </div>
                                        </TableCell>
                                        <TableCell> {tenant.databaseSuffix}</TableCell>
                                        <TableCell>{'N/A'}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const searchFilter = (tenant: any, search: any) => {
        if (search) {
            const words = search.split(' ')
            return words.every(
                (word: string) =>
                    tenant.name?.toLowerCase()?.includes(word.toLowerCase()) ||
                    tenant.databaseSuffix?.toLowerCase()?.includes(word.toLowerCase()),
            )
        } else {
            return true
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Button fullWidth={true} onClick={() => setOpen(true)}>
                        {tr(TEXT_SELECT_TENANT)}
                    </Button>
                </Grid>
            </Grid>
            <Dialog maxWidth={'lg'} fullWidth open={open} onClose={() => setOpen(false)}>
                <div className={styles.header}>
                    <div>
                        <DialogTitle className={styles.dialogTitle}>{tr(TEXT_SELECT_TENANT)}</DialogTitle>
                    </div>
                </div>
                <DialogContent className={styles.dialogContent}>
                    <Divider />
                    <Tabs value={tab} indicatorColor="primary" textColor="primary" centered onChange={onTabChanged}>
                        <Tab label="EL PRODUKSJON" />
                        <Tab label="EL TEST/DEMO" />
                        <Tab label="BRANN PRODUKSJON" />
                        <Tab label="BRANN TEST/DEMO" />
                        <Tab label="MHV" />
                        <Tab label="SKATT" />
                    </Tabs>
                    <Divider />
                    <div className={styles.searchField}>
                        <TextField
                            fullWidth
                            label={tr(TEXT_SEARCH)}
                            variant="outlined"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    {tenants && (
                        <>
                            <TenantTab
                                value={tab}
                                index={0}
                                tenants={tenants
                                    .filter((t) => searchFilter(t, search))
                                    .filter((t) => t.type === TENANT_TYPES.EL && t.isProduction)}
                            />
                            <TenantTab
                                value={tab}
                                index={1}
                                tenants={tenants
                                    .filter((t) => searchFilter(t, search))
                                    .filter((t) => t.type === TENANT_TYPES.EL && !t.isProduction)}
                            />
                            <TenantTab
                                value={tab}
                                index={2}
                                tenants={tenants
                                    .filter((t) => searchFilter(t, search))
                                    .filter((t) => t.type === TENANT_TYPES.FIRE && t.isProduction)}
                            />
                            <TenantTab
                                value={tab}
                                index={3}
                                tenants={tenants
                                    .filter((t) => searchFilter(t, search))
                                    .filter((t) => t.type === TENANT_TYPES.FIRE && !t.isProduction)}
                            />
                            <TenantTab
                                value={tab}
                                index={4}
                                tenants={tenants
                                    .filter((t) => searchFilter(t, search))
                                    .filter((t) => t.type === TENANT_TYPES.HEALTHCARE)}
                            />
                            <TenantTab
                                value={tab}
                                index={5}
                                tenants={tenants
                                    .filter((t) => searchFilter(t, search))
                                    .filter((t) => t.type === TENANT_TYPES.TAX)}
                            />
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}
