import { Grid } from '@material-ui/core'
import { Taxation } from 'models/taxation'
import React from 'react'
import { getPropertyValueTypeDescription } from 'utils/property/property-helper'
import { TEXT_CORRECT_PLOT_TYPE, TEXT_TAXATION_PLOT_TYPE } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

type PlotTypesProps = {
    styles: any
    taxation: Taxation
    typeCode: string
}

export const PlotTypes: React.FC<PlotTypesProps> = (props) => {
    const { typeCode, styles, taxation } = props
    return (
        <>
            <Grid container spacing={1} className={styles.innerContainer}>
                <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                    {tr(TEXT_TAXATION_PLOT_TYPE)}:
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                    {getPropertyValueTypeDescription(typeCode)}
                </Grid>
            </Grid>
            <Grid container spacing={1} className={styles.innerContainer}>
                <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                    {tr(TEXT_CORRECT_PLOT_TYPE)}:
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                    {getPropertyValueTypeDescription(taxation.current.property.valueType)}
                </Grid>
            </Grid>
        </>
    )
}
