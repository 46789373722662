import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { tr } from 'utils/translations/translate'
import { TEXT_MAP } from 'utils/translations/keys'
import { IframeMap } from 'property-page/property-details/iframe-map'
import { coordsFromZone32to33 } from 'utils'
import { Property } from 'models/property'
import { PrivateOwner } from 'models/owner'

type PropertyDetailsProps = {
    property: Property
    owners: PrivateOwner[]
    styles: any
    openedBuilding: number | undefined
    buildings: any
}

export const PropertyDetails: React.FC<PropertyDetailsProps> = (props) => {
    const { property, styles, openedBuilding, buildings } = props
    const [currentBuilding, setCurrentBuilding] = useState<any>(null)
    const prop = property || {}

    useEffect(() => {
        if (openedBuilding !== undefined) {
            setCurrentBuilding(buildings[openedBuilding])
        }
    }, [openedBuilding, buildings])

    const buildingCoords = currentBuilding?.coordinates ? coordsFromZone32to33(currentBuilding.coordinates.x, currentBuilding.coordinates.y) : undefined

    const mapCoords = buildingCoords
        ? buildingCoords
        : prop.plots && prop.plots[0]
            ? coordsFromZone32to33(prop.plots[0].coordinates.x, prop.plots[0].coordinates.y)
            : null

    const lat = mapCoords?.lat
    const long = mapCoords?.long

    const Map = useMemo(() => (lat && long ? <IframeMap coords={{ lat, long }} /> : <></>), [lat, long])

    return (
        <Accordion className={styles.semiGrouped} elevation={0} square={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.panelSummary}>
                {tr(TEXT_MAP)}
            </AccordionSummary>
            <AccordionDetails className={styles.panelContent}>{Map}</AccordionDetails>
        </Accordion>
    )
}
