import React, { useMemo } from 'react'
import { Taxation, TaxationState } from 'models/taxation'
import {
    Grid,
    Card,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Theme,
    makeStyles,
} from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_REASON_FOR_FAILED_TAXATION,
    TEXT_MAP,
    TEXT_PLOT_INFORMATION,
    TEXT_PLOT_TYPE,
    TEXT_LOCATION_FACTOR,
    TEXT_AREA,
    TEXT_SUN,
    TEXT_NOISE,
    TEXT_VIEW,
    TEXT_LIGHT_POLLUTION,
    TEXT_AIR_POLLUTION,
    TEXT_POLLUTION_REASON,
    TEXT_FLOOD_AND_EXPOSED_AREA,
    TEXT_DIFFICULT_ACCESS_ROAD,
    TEXT_PLOT_SIZE,
    TEXT_COMMENT_NOUN,
} from 'utils/translations/keys'
import { IframeMap } from 'property-page/property-details/iframe-map'
import { Property } from 'models/property'
import {
    getTaxInfoTranslationKeyByValue,
    formatArea,
    coordsFromZone32to33,
    getPropertyValueTypeTranslationKey,
    getPropertyLocationFactorTranslationKey,
    getTaxationPlotNewCommentsCount,
} from 'utils'
import { TaxationDetailsBuilding } from 'property-taxation-details-page/taxation-details/taxation-details-building'
import { TaxationDetailsSummary } from 'property-taxation-details-page/taxation-details/taxation-details-summary'
import { ActivityLog } from 'property-page/activity-log/activity-log'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Diff } from 'common/diff/diff'
import { LightboxImage } from 'models/lightbox-image'
// @ts-ignore
import Lightbox from 'react-lightbox-component'
import { useSelector } from 'react-redux'
import { TaxSettingsState } from 'settings-page/settings-reducer'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
        },

        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },
        divider: {
            margin: '16px -16px',
        },
        bolded: {
            fontSize: '1.125rem',
            fontWeight: 500,
        },
        detailsContainer: {
            marginBottom: theme.spacing(4),
        },
        declineReasonContainer: {
            padding: theme.spacing(2, 3),
            marginBottom: theme.spacing(4),
        },
        declineReason: {
            fontWeight: 500,
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
    }
})

export const TaxationDetails: React.FC<{
    taxation: Taxation
}> = props => {
    const { taxation } = props
    const taxationSettings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)

    const styles = useStyles()
    const property = taxation ? taxation.current.property : ({} as Property)
    const propertyCoords = property.plots
        ? coordsFromZone32to33(property.plots[0].coordinates.x, property.plots[0].coordinates.y)
        : null

    const lat = propertyCoords && propertyCoords.lat
    const long = propertyCoords && propertyCoords.long

    const Map = useMemo(() => (lat && long ? <IframeMap coords={{ lat, long }} /> : <></>), [lat, long])

    const prev = taxation.old
    const curr = taxation.current

    const propertyImages: LightboxImage[] = taxation
        ? taxation.current.property.files.map((file: any) => ({
              src: file.url,
              title: ' ',
              description: ' ',
          }))
        : []

    return (
        <>
            {taxation && (
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item md={12} lg={9}>
                                {taxation && (
                                    <>
                                        {taxation.declinedReason && taxation.state === TaxationState.TribunalDeclined && (
                                            <Card className={styles.declineReasonContainer}>
                                                <Grid container alignItems="center" spacing={2}>
                                                    <Grid item>{tr(TEXT_REASON_FOR_FAILED_TAXATION)}:</Grid>
                                                    <Grid item className={styles.declineReason}>
                                                        {taxation.declinedReason}
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        )}

                                        <Box className={styles.detailsContainer}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    className={styles.panelSummary}
                                                >
                                                    {tr(TEXT_MAP)}
                                                </AccordionSummary>
                                                <AccordionDetails className={styles.panelContent}>
                                                    {Map}
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    className={styles.panelSummary}
                                                >
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={2}>
                                                                <Grid item>{tr(TEXT_PLOT_INFORMATION)}</Grid>
                                                            </Grid>
                                                        </Grid>

                                                        {renderNewPlotCommentsCount(taxation)}
                                                    </Grid>
                                                </AccordionSummary>

                                                <AccordionDetails className={styles.panelContent}>
                                                    <div style={{ flexGrow: 1 }}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={3} className={styles.bolded}>
                                                                {tr(TEXT_PLOT_TYPE)}
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Diff
                                                                    prop="property.valueType"
                                                                    prev={prev}
                                                                    curr={curr}
                                                                    translationKeysFn={
                                                                        getPropertyValueTypeTranslationKey
                                                                    }
                                                                    hideValue
                                                                ></Diff>
                                                            </Grid>
                                                        </Grid>

                                                        <Divider className={styles.divider}></Divider>

                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={3} className={styles.bolded}>
                                                                {tr(TEXT_LOCATION_FACTOR)}
                                                            </Grid>
                                                            <Grid item xs className={styles.bolded}>
                                                                <Diff
                                                                    prop="property.locationFactor"
                                                                    prev={prev}
                                                                    curr={curr}
                                                                    translationKeysFn={
                                                                        getPropertyLocationFactorTranslationKey
                                                                    }
                                                                    factors={taxationSettings.locationFactors}
                                                                ></Diff>
                                                            </Grid>
                                                        </Grid>

                                                        {property.taxInfo && (
                                                            <>
                                                                {property.taxInfo.area && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_AREA)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo.area,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {property.taxInfo.sun && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_SUN)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo.sun,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {property.taxInfo.noise && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_NOISE)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo.noise,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {property.taxInfo.view && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_VIEW)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo.view,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {property.taxInfo.lightPollution && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_LIGHT_POLLUTION)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo.lightPollution,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {property.taxInfo.airPollution && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_AIR_POLLUTION)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo.airPollution,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {property.taxInfo.pollutionReason && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_POLLUTION_REASON)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo.pollutionReason,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {property.taxInfo.floodAndExposedArea && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_FLOOD_AND_EXPOSED_AREA)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo
                                                                                        .floodAndExposedArea,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {property.taxInfo.difficultAccessRoad && (
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={3}>
                                                                            {tr(TEXT_DIFFICULT_ACCESS_ROAD)}
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {tr(
                                                                                getTaxInfoTranslationKeyByValue(
                                                                                    property.taxInfo
                                                                                        .difficultAccessRoad,
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}

                                                        <Divider className={styles.divider}></Divider>

                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={3} className={styles.bolded}>
                                                                {tr(TEXT_PLOT_SIZE)}
                                                            </Grid>
                                                            <Grid item>{formatArea(property.plotSize)}</Grid>
                                                            {property.taxInfo && property.taxInfo.lotSizeComment && (
                                                                <Grid item>
                                                                    <Diff
                                                                        prop="property.taxInfo.lotSizeComment"
                                                                        prev={prev}
                                                                        curr={curr}
                                                                    ></Diff>
                                                                </Grid>
                                                            )}
                                                        </Grid>

                                                        {propertyImages.length > 0 && (
                                                            <Divider style={{ margin: '24px -24px' }}></Divider>
                                                        )}

                                                        <Grid container spacing={2} className={styles.marginBottom}>
                                                            <Grid item xs={12}>
                                                                <Lightbox
                                                                    images={propertyImages}
                                                                    thumbnailWidth="384px"
                                                                    thumbnailHeight="216px"
                                                                    renderImageFunc={(
                                                                        idx: number,
                                                                        image: LightboxImage,
                                                                        toggleLightbox: any,
                                                                        width: string,
                                                                        height: string,
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={idx}
                                                                                className="lightbox-img-thumbnail  lightbox-img-thumbnail--cubit"
                                                                                style={{
                                                                                    backgroundImage: `url(${image.src})`,
                                                                                    width: width,
                                                                                    height: height,
                                                                                }}
                                                                                onClick={toggleLightbox.bind(null, idx)}
                                                                            ></div>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>

                                        <Box className={styles.detailsContainer}>
                                            {taxation.current.buildings.map((b, index) => (
                                                <TaxationDetailsBuilding
                                                    key={index}
                                                    index={index}
                                                    building={b}
                                                    taxation={taxation}
                                                ></TaxationDetailsBuilding>
                                            ))}
                                        </Box>

                                        <Box>
                                            <TaxationDetailsSummary taxation={taxation} />
                                        </Box>
                                    </>
                                )}
                            </Grid>

                            <Grid item md={12} lg={3}>
                                {taxation && <ActivityLog objectId={taxation.id} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

const renderNewPlotCommentsCount = (taxation: Taxation): JSX.Element | null => {
    const newPlotComments = getTaxationPlotNewCommentsCount(taxation)
    return (
        (newPlotComments > 0 && (
            <Grid item style={{ fontSize: '16px' }}>
                {`${newPlotComments} ${tr(TEXT_COMMENT_NOUN).toLowerCase()}`}
            </Grid>
        )) ||
        null
    )
}
