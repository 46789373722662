import React, { FunctionComponent } from 'react'
import { DashboardPage } from 'dashboard-page/dashboard-page'
import { SearchPage } from 'search-page/search-page'
import { LoginPage } from '../auth/login-page'
import { PropertyPage } from 'property-page/property-page'
import { PlanningPage } from '../planning-page/planning-page'
import { TaxationPropertyPage } from 'property-taxation-edit-page/property-taxation-edit-page'
import { TribunalPage } from 'tribunal-page/tribunal-page'
import { TribunalTaxationDetailsPage } from 'tribunal-taxation-details-page/tribunal-taxation-details-page'
import { TribunalGroupPage } from 'tribunal-page/tribunal-group-page/tribunal-group-page'
import { PropertyTaxationDetailsPage } from 'property-taxation-details-page/property-taxation-details-page'
import { SettingsPage } from 'settings-page/settings-page'
import { NotFoundPage } from 'not-found-page/not-found-page'
import { RouteComponentProps } from '@reach/router'
import { includes } from 'lodash'
import { SettingsCompaniesPage } from 'settings-companies-page/settings-companies-page'
import { SettingsUsersPage } from 'settings-users-page/settings-users-page'
import { SettingsAccessPage } from 'settings-access-page/settings-access-page'
import { SettingsRightsPage } from 'settings-rights-page/settings-rights-page'
import { SettingsCompanyPage } from 'settings-companies-page/settings-company-page'
import { Permission } from 'common/enums/permission'
import { RedirectPage } from './redirect-page/redirect-page'
import { UnauthorizedPage } from 'unauthorized-page/unauthorized-page'
import { ComplaintsPage } from 'tribunal-page/complaints/complaints-page'
import { TaxationTabs } from 'common/enums/taxation'
import { YearSettings } from 'year-settings-page/year-settings'
import { RouteNames } from 'common/enums/routeNames'
import { TaxSettings } from 'settings-tax-page/tax-settings-page'
import { SmsTemplates } from 'settings-templates/templates-page-sms'
import { SmsTemplateEdit } from 'settings-templates/templates-page-sms-edit'
import { TextTemplates } from 'settings-templates/templates-page-text'
import { TextTemplateEdit } from 'settings-templates/templates-page-text-edit'
import { EmailTemplateEdit } from 'settings-templates/templates-page-email-edit'
import { EmailTemplates } from 'settings-templates/templates-page-email'
import { DocumentTemplates } from 'settings-templates/templates-page-case-document'
import { DocumentTemplateEdit } from 'settings-templates/templates-page-case-document-edit'
import { DocumentTemplatesList } from 'settings-templates/templates-page-template-document'
import { PdfTemplateEdit } from 'settings-templates/templates-page-template-document-edit'
import { CaseFlow } from 'settings-case-flow-page/case-flow-page'
import { PrieviewPage } from 'document-preview-page/preview-page'
import { UploadDocument } from 'settings-documents/upload-document'
import { TribunalApprovedPage } from 'tribunal-page/tribunal-approved/tribunal-approved-page'
import { TribunalApprovedGroupPage } from 'tribunal-page/tribunal-approved/tribunal-approved-group-page'
import { FailedCorrespondence } from 'dashboard-page/dashboard-failed-correspondence'

type ApplicationRouteProps = {
    key: number
    userPermissions?: string[]
}

export type ApplicationRoute = (props: ApplicationRouteProps) => JSX.Element

type ProtectedRouteProps = {
    props: ApplicationRouteProps
    routePermission: Permission
    path: string
    Component: FunctionComponent<RouteComponentProps>
}

export type UnprotectedRouteProps = {
    props: ApplicationRouteProps
    path: string
    Component: FunctionComponent<RouteComponentProps>
}

const ApplicationRoutes: ApplicationRoute[] = [
    (props) =>
        get404RouteComponent({
            props,
            path: '/404',
            Component: NotFoundPage,
        }),

    (props) =>
        getRouteComponent({
            props,
            path: '/unauthorized',
            Component: UnauthorizedPage,
        }),

    (props) => <RedirectPage key={props.key} path="/welcome/:newUserKey" to="/" />,

    (props) =>
        getRouteComponent({
            props,
            path: '/login',
            Component: LoginPage,
        }),

    (props) =>
        getRouteComponent({
            props,
            path: '/preview',
            Component: PrieviewPage,
        }),

    (props) =>
        getDashboardRouteComponent({
            props,
            path: '/',
            routePermission: Permission.PageDashboard,
            Component: DashboardPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/search',
            routePermission: Permission.PageSearch,
            Component: SearchPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/property/:id',
            routePermission: Permission.PageSearch,
            Component: PropertyPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/taxation/:id',
            routePermission: Permission.ViewTaxation,
            Component: PropertyTaxationDetailsPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/taxation/:id/edit',
            routePermission: Permission.ViewTaxation,
            Component: TaxationPropertyPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/taxation/:id/${TaxationTabs.complaints}`,
            routePermission: Permission.ViewTaxation,
            Component: TaxationPropertyPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/taxation/:id/${TaxationTabs.caseDocuments}`,
            routePermission: Permission.ViewTaxation,
            Component: TaxationPropertyPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/taxation/:id/${TaxationTabs.communication}`,
            routePermission: Permission.ViewTaxation,
            Component: TaxationPropertyPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/calendar',
            routePermission: Permission.PagePlanning,
            Component: PlanningPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/tribunal',
            routePermission: Permission.PageTribunal,
            Component: TribunalPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/tribunal/group/:groupState/:id',
            routePermission: Permission.PageTribunal,
            Component: TribunalGroupPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/tribunal/group/:groupState/:groupId/taxation/:taxationId',
            routePermission: Permission.PageTribunal,
            Component: TribunalTaxationDetailsPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/tribunal/complaints/:complaintGroupType',
            routePermission: Permission.ViewTribunalAll,
            Component: ComplaintsPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/tribunal/group/historic',
            routePermission: Permission.PageTribunal,
            Component: TribunalApprovedPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/tribunal/group/historic/:id',
            routePermission: Permission.PageTribunal,
            Component: TribunalApprovedGroupPage,
        }),

    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/settings',
            routePermission: Permission.PageSettings,
            Component: SettingsPage,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/settings/access',
            routePermission: Permission.PageSettings,
            Component: SettingsAccessPage,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/settings/companies',
            routePermission: Permission.PageSettings,
            Component: SettingsCompaniesPage,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/settings/companies/:id',
            routePermission: Permission.PageSettings,
            Component: SettingsCompanyPage,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/settings/users',
            routePermission: Permission.AdministrateUsers,
            Component: SettingsUsersPage,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: '/settings/rights',
            routePermission: Permission.PageSettings,
            Component: SettingsRightsPage,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.yearSettings}`,
            routePermission: Permission.PageSettings,
            Component: YearSettings,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.yearSettings}/:id`,
            routePermission: Permission.PageSettings,
            Component: YearSettings,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.taxSettings}`,
            routePermission: Permission.PageSettings,
            Component: TaxSettings,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.SMS}`,
            routePermission: Permission.PageSettings,
            Component: SmsTemplates,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.SMS}/:id`,
            routePermission: Permission.PageSettings,
            Component: SmsTemplateEdit,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.TEXT}`,
            routePermission: Permission.PageSettings,
            Component: TextTemplates,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.TEXT}/:id`,
            routePermission: Permission.PageSettings,
            Component: TextTemplateEdit,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.EMAIL}`,
            routePermission: Permission.PageSettings,
            Component: EmailTemplates,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.EMAIL}/:id`,
            routePermission: Permission.PageSettings,
            Component: EmailTemplateEdit,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.UPLOAD_DOCUMENT}`,
            routePermission: Permission.PageSettings,
            Component: UploadDocument,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.CASE_DOCUMENT}`,
            routePermission: Permission.PageSettings,
            Component: DocumentTemplates,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.CASE_DOCUMENT}/:id`,
            routePermission: Permission.PageSettings,
            Component: DocumentTemplateEdit,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.DOCUMENT_TEMPLATES}`,
            routePermission: Permission.PageSettings,
            Component: DocumentTemplatesList,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.DOCUMENT_TEMPLATES}/:id`,
            routePermission: Permission.PageSettings,
            Component: PdfTemplateEdit,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.settings}/${RouteNames.CASE_FLOW}`,
            routePermission: Permission.PageSettings,
            Component: CaseFlow,
        }),
    (props) =>
        getProtectedRouteComponent({
            props,
            path: `/${RouteNames.DASHBOARD}/${RouteNames.FAILED_CORRESPONDENCE}`,
            routePermission: Permission.PageDashboard,
            Component: FailedCorrespondence,
        }),
]

export const getProtectedRouteComponent = (props: ProtectedRouteProps): JSX.Element => {
    const {
        props: { key, userPermissions },
        routePermission: routeAccessPoint,
        Component,
        path,
    } = props
    return includes(userPermissions, routeAccessPoint) ? (
        <Component key={key} path={path} />
    ) : (
        <RedirectPage key={key} path={path} to="/unauthorized" />
    )
}

export const getRouteComponent = (props: UnprotectedRouteProps): JSX.Element => {
    const {
        props: { key },
        path,
        Component,
    } = props
    return <Component key={key} path={path} />
}

const get404RouteComponent = (props: UnprotectedRouteProps): JSX.Element => {
    const {
        props: { key },
        path,
        Component,
    } = props
    return <Component default key={key} path={path} />
}

export const getDashboardRouteComponent = (props: ProtectedRouteProps): JSX.Element => {
    const {
        props: { key, userPermissions },
        routePermission: routeAccessPoint,
        Component,
        path,
    } = props
    return includes(userPermissions, routeAccessPoint) ? (
        <Component key={key} path={path} />
    ) : includes(userPermissions, Permission.PageTribunal) ? (
        <RedirectPage key={key} path={path} to="/tribunal" />
    ) : (
        <RedirectPage key={key} path={path} to="/unauthorized" />
    )
}

export { ApplicationRoutes }
