import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    AccordionActions,
    IconButton,
    makeStyles,
    Theme,
    Tooltip,
} from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_ADD_PICTURE,
    TEXT_BAD,
    TEXT_CANCEL,
    TEXT_DEMOLITION_OBJECT,
    TEXT_ERASE_BUILDING,
    TEXT_EXEMPTION,
    TEXT_METRICS_STATUS,
    TEXT_NEGATIVE,
    TEXT_NO,
    TEXT_NORMAL,
    TEXT_NOTE,
    TEXT_POSITIVE,
    TEXT_SAVE,
    TEXT_UNITS,
    TEXT_VERY_BAD,
    TEXT_YES,
    TEXT_EXEMPTION_BY_REASON,
    TEXT_BUILDING_DELETED_UNDO,
} from 'utils/translations/keys'
import { Building } from 'models/building'
import { useDispatch, useSelector } from 'react-redux'
import {
    arrayRemove,
    arrayPush,
    Field,
    FieldArray,
    FieldArrayFieldsProps,
    getFormValues,
    reset,
    submit,
    change,
    clearFields
} from 'redux-form'
import { Clear, Edit, PhotoCamera, UndoRounded } from '@material-ui/icons'
import { VerifiedCount } from 'common/verified-count/verified-count'
import { TaxationPropertyBuildingFloors } from './property-taxation-building-edit-floors'
import Dropzone from 'react-dropzone'
import { FormField } from 'shared-components/src/cubit-inputs/cubit-form-field.types'
import { InputType } from 'shared-components/src/cubit-inputs/input-type.enum'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { mapBuildingVerifiedValues } from 'utils'
import { get } from 'lodash'
import { actionTaxationUploadFiles } from 'property-taxation-edit-page/taxation-actions'
import { isTaxationEditingFinished, Taxation } from 'models/taxation'
// @ts-ignore
import Lightbox from 'react-lightbox-component'
import 'react-lightbox-component/build/css/index.css'
import { ValueContainer } from 'common/value-container/value-container'
import { AppState } from 'app/app-store'
import { POSITIVE, NORMAL, NEGATIVE, BAD, VERY_BAD, DEMOLITION_OBJECT } from 'common/constants/constants'
import { LightboxImage } from 'models/lightbox-image'
import { ImageTransferButtons } from './property-taxation-building-image-transfer-buttons'
import { BuildingStatus, ExemptionByReason } from 'common/enums/filter-values'
import { TaxationPropertyStandardFactors } from './property-taxation-standardFactor-fields'
import { TaxationStandardOtherFactors } from './property-taxation-standardFactor-otherFactors'
import { getBuildingStatusOptions, getExemptReasonOptions } from 'utils/property/property-helper'
import { untaxableBuildingsFilter } from 'property-page/property-helper'
import { useQuery } from 'utils/hooks/hooks'
import { Attributes } from 'common/attributes/attributes'
import { attributeKeys } from 'common/attributes/attribute'
import { actionGetEntityAttributes } from 'common/attributes/attribute-actions'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
            flexDirection: 'column',
        },
        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
            [theme.breakpoints.down('xs')]: {
                height: 'auto'
            }
        },
        summaryContainer: {
            [theme.breakpoints.down('xs')]: {
                flexWrap: 'nowrap'
            }
        },
        summaryContent: {
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
            }
        },
        panelContent__label: {
            fontWeight: 500,
        },

        tableWrapper: {
            margin: '0 -16px',
            width: 'calc(100% + 48px)',
            maxWidth: 'calc(100% + 48px)',
            flexBasis: 'auto',
            [theme.breakpoints.down('xs')]: {
                overflowX: 'scroll',
                maxWidth: '100%',
                margin: 0
              }
        },

        button: {
            color: theme.palette.grey[400],
        },
        cancelButton: {
            marginRight: theme.spacing(2),
        },

        cameraIcon: {
            marginRight: theme.spacing(1),
        },

        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        buildingDescription: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            [theme.breakpoints.down('xs')]: {
                display: 'block'
            }
        }
    }
})

const comment: FormField<InputType.TextField> = {
    type: InputType.TextField,
    props: {
        name: '',
        label: '',
        component: CubitTextFieldAdapter,
        type: 'text',
    },
}

export const getPositiveNormalOptions = () => [
    { label: tr(TEXT_POSITIVE), value: POSITIVE },
    { label: tr(TEXT_NORMAL), value: NORMAL },
]

export const getPositiveNegativeNormalOptions = () => [
    { label: tr(TEXT_POSITIVE), value: POSITIVE },
    { label: tr(TEXT_NEGATIVE), value: NEGATIVE },
    { label: tr(TEXT_NORMAL), value: NORMAL },
]

export const getMaintenanceOptions = () => [
    { label: tr(TEXT_NORMAL), value: NORMAL },
    { label: tr(TEXT_BAD), value: BAD },
    { label: tr(TEXT_VERY_BAD), value: VERY_BAD },
    { label: tr(TEXT_DEMOLITION_OBJECT), value: DEMOLITION_OBJECT },
]

type TaxationPropertyBuildingDetailsProps = {
    fields: FieldArrayFieldsProps<Building>
    buildingFieldName: string
    form: string
    index: number
    setExpandedBuildingIndex: any
    expandedBuildingIndex: number | false
}

export const TaxationPropertyBuildingDetails: React.FC<TaxationPropertyBuildingDetailsProps> = props => {
    const { form, buildingFieldName, index, fields, setExpandedBuildingIndex, expandedBuildingIndex } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const printing = useQuery('printing')

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleCancelDelete = () => {
        setDeleteDialogOpen(false)
        dispatch(clearFields(form,false, false,`deletedBuildings.${building.id}`))
    }

    const buildingCodesMap = useSelector((state: AppState) => state.appData.buildingCodesMap)

    const taxation = useSelector(getFormValues(form)) as Taxation
    const building = mapBuildingVerifiedValues(get(taxation, `current.buildings[${index}]`), taxation.deletedBuildings, taxation)
    const isDeleted = taxation.deletedBuildings && building.id in taxation.deletedBuildings
    const oldBuilding = get(taxation, `old.buildings[${index}]`) as Building

    const standardFactorHasBeenChanged = oldBuilding && building.standardFactor !== oldBuilding.standardFactor

    const [buildingInfoPanelExpanded, setBuildingInfoPanelExpanded] = useState(!!printing)
    const [editingBuildingInfo, setEditingBuildingInfo] = useState(!!building.newlyCreated)

    const updateBuildingAttributes = (attributes: string[]) => {
        dispatch(change(form, `current.buildings[${index}].attributes`, attributes))
    }

    useEffect(() => {
        if (!building.attributes?.length) {
            dispatch(actionGetEntityAttributes(building.id, updateBuildingAttributes))
        }
    }, [])

    useEffect(() => {
        if (expandedBuildingIndex !== index) {
            setBuildingInfoPanelExpanded(false)
        }
    }, [expandedBuildingIndex, index, setBuildingInfoPanelExpanded])

    const handlePanelExpand = (expanded: boolean) => {
        setExpandedBuildingIndex(expanded ? index : false)
        setBuildingInfoPanelExpanded(expanded)
    }

    const handleEditBuildingInformation = (e: React.MouseEvent) => {
        e.stopPropagation()
        handlePanelExpand(true)
        setEditingBuildingInfo(true)
    }

    const handleCancelEditBuildingInformation = () => {
        dispatch(reset(form))
        handlePanelExpand(false)
        setEditingBuildingInfo(false)
    }

    const handleDeleteBuildingClick = (e: any) => {
        e.stopPropagation()
        setDeleteDialogOpen(true)
    }

    const handleRemoveBuilding = () => {
        setDeleteDialogOpen(false)
        fields.remove(index)
        submitForm()
    }

    const handleUndoBuildingDelete = (e: any) => {
        e.stopPropagation()
        const deletedBuildingsCopy = {...taxation.deletedBuildings}
        delete deletedBuildingsCopy[building.id]
        
        dispatch(change(form, 'deletedBuildings', deletedBuildingsCopy))        
        setTimeout(() => {
            submitForm()
        }, 0)
    }

    const submitForm = () => {
        dispatch(submit(form))
        setEditingBuildingInfo(false)
    }

    const handleFileDrop = (acceptedFiles: any) => {
        dispatch(actionTaxationUploadFiles(form, `current.buildings[${index}].files`, acceptedFiles))
    }

    const buildingImages: LightboxImage[] = building.files.map((file: any) => ({
        src: file.url,
        title: ' ',
        description: ' ',
    }))

    const verifiedValuesCount = building.verifiedInfo.count
    const verifiedValuesTotal = building.verifiedInfo.total

    const handleImageDelete = (e: React.MouseEvent, fileIndex: number) => {
        e.stopPropagation()
        dispatch(arrayRemove(form, `current.buildings[${index}].files`, fileIndex))
        setTimeout(() => dispatch(submit(form)), 0)
    }
    const handleImageTransfer = (from: number, to: number, imageIndex: number, fields: any) => {
        const imageObject = fields.get(from).files[imageIndex]
        dispatch(arrayPush(form, `current.buildings[${to}].files`, imageObject))
        dispatch(arrayRemove(form, `current.buildings[${from}].files`, imageIndex))
        setTimeout(() => dispatch(submit(form)), 0)
    }

    const renderDropzoneInput = (field: any) => {
        return (
            <Grid container justifyContent="center">
                <Grid item>
                    <Dropzone
                        accept="image/*"
                        onDrop={(files: any) => {
                            handleFileDrop(files)
                            field.input.onChange(files)
                        }}
                        disabled={isTaxationEditingFinished(taxation)}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Box {...getRootProps()}>
                                        <Button variant="outlined" color="primary">
                                            <input {...getInputProps()} />
                                            <PhotoCamera className={styles.cameraIcon} />
                                            {tr(TEXT_ADD_PICTURE)}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Dropzone>
                </Grid>
                <Grid item xs={12} />
                <Grid item>{field.meta.error && <span className="error">{field.meta.error}</span>}</Grid>
            </Grid>
        )
    }
    //skip the building fields if it's untaxable
    if (!untaxableBuildingsFilter(building, taxation.current.buildings, taxation.staticSettings)) {
        return <></>
    }
    return (
        <>
            <Accordion expanded={buildingInfoPanelExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.panelSummary}
                    onClick={() => handlePanelExpand(!buildingInfoPanelExpanded)}
                >
                    <Grid container justifyContent="space-between" alignItems="center" className={styles.summaryContainer}>
                        <Grid item>
                            <Grid container alignItems="center" spacing={2} className={styles.summaryContent}>
                                <Grid item>{building.buildingNumber}</Grid>
                                <Grid item>{building.buildingCode}</Grid>
                                <Grid item className={styles.buildingDescription}>{buildingCodesMap[building.buildingCode]}</Grid>
                                {!isDeleted &&
                                    <>
                                        <Grid item>
                                            <VerifiedCount
                                                style={{ marginLeft: '8px', fontSize: '14px' }}
                                                count={verifiedValuesCount}
                                                total={verifiedValuesTotal}
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                        {isTaxationEditingFinished(taxation) ? (
                            <></>
                        ) : editingBuildingInfo && !isDeleted ? (
                            <Grid item>
                                <Button onClick={handleDeleteBuildingClick} className={styles.cancelButton}>
                                    {tr(TEXT_ERASE_BUILDING)}
                                </Button>
                            </Grid>
                        ) : (
                            <Grid item>
                                {isDeleted ? 
                                    <IconButton color="inherit">

                                        <Tooltip title={tr(TEXT_BUILDING_DELETED_UNDO)}>
                                            <UndoRounded onClick={handleUndoBuildingDelete} />
                                        </Tooltip>
                                    </IconButton>
                                :
                                    <IconButton onClick={handleEditBuildingInformation} color="inherit">
                                        <Edit />
                                    </IconButton>
                                }
                            </Grid>
                        )}
                    </Grid>
                </AccordionSummary>

                <AccordionDetails className={styles.panelContent}>
                    {(!isDeleted || editingBuildingInfo) &&
                        <div style={{ flexGrow: 1 }}>
                            <Divider style={{ margin: '0 -16px 24px -16px' }}></Divider>

                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                    <ValueContainer
                                        label={tr(TEXT_EXEMPTION)}
                                        value={building.exempt ? tr(TEXT_YES) : tr(TEXT_NO)}
                                    ></ValueContainer>
                                    <Field
                                        type="hidden"
                                        name={`${buildingFieldName}.exempt`}
                                        component="input"
                                    />
                                </Grid>
                                {(building.exempt || editingBuildingInfo) &&
                                    <>
                                        <Grid item xs>
                                            <Field
                                                component={CubitSelectAdapter}
                                                valueIsObject={false}
                                                options={getExemptReasonOptions()}
                                                onChange={() => dispatch(change(form, `${buildingFieldName}.exempt`, true))}
                                                name={`${buildingFieldName}.exemptReason`}
                                                label={tr(TEXT_EXEMPTION_BY_REASON)}
                                                multiline
                                                disabled={true}
                                        />
                                        </Grid>
                                        <Grid item sm xs={12}>
                                            <Field
                                                {...comment.props}
                                                name={`${buildingFieldName}.taxInfo.exemptionComment`}
                                                label={tr(TEXT_NOTE)}
                                                multiline
                                                disabled={!editingBuildingInfo}
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>

                            <Divider style={{ margin: '24px -16px' }}></Divider>

                            <Grid container spacing={2} alignItems="center">
                                <Grid item sm={3} xs={6}>
                                    <Field
                                        component={CubitSelectAdapter}
                                        valueIsObject={false}
                                        options={getBuildingStatusOptions()}
                                        onChange={(e: any) => {
                                            // check for specific cases when exemption should be updated
                                            if (e.target.value === BuildingStatus.completion || e.target.value === BuildingStatus.temporaryUsePermit) {
                                                dispatch(change(form, `${buildingFieldName}.exemptReason`, ExemptionByReason.building))
                                                dispatch(change(form, `${buildingFieldName}.exempt`, true))
                                            }
                                        }}
                                        name={`${buildingFieldName}.status`}
                                        label={tr(TEXT_METRICS_STATUS)}
                                        multiline
                                        disabled={!editingBuildingInfo}
                                    />
                                </Grid>

                                <Grid item md sm={12} xs={12}>
                                    <Field
                                        name={`${buildingFieldName}.taxInfo.propertyStatusComment`}
                                        component={CubitTextFieldAdapter}
                                        type="text"
                                        label={tr(TEXT_NOTE)}
                                        multiline
                                        disabled={!editingBuildingInfo}
                                    />
                                </Grid>
                            </Grid>

                            <Divider style={{ margin: '24px -16px' }}></Divider>

                            <TaxationPropertyStandardFactors editable={editingBuildingInfo} taxation={taxation} formName={form} buildingFieldName={buildingFieldName} verifyable={true}/>
                            {standardFactorHasBeenChanged && (
                                <TaxationStandardOtherFactors taxation={taxation} editable={editingBuildingInfo} formName={form} buildingFieldName={buildingFieldName} />
                            )}

                            <Divider style={{ margin: '24px -16px' }}></Divider>

                            <Grid container spacing={2} alignItems="center">
                                <Grid item sm={3} xs={6}>
                                    {tr(TEXT_UNITS)}
                                </Grid>
                                <Grid item xs={3}>
                                    {building.noOfHousingUnits}
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        {...comment.props}
                                        name={`${buildingFieldName}.taxInfo.unitsComment`}
                                        label={tr(TEXT_NOTE)}
                                        multiline
                                        disabled={!editingBuildingInfo}
                                    />
                                </Grid>
                            </Grid>

                            <Divider style={{ margin: '24px -16px' }}></Divider>

                            <Grid container spacing={2} className={styles.marginBottom}>
                                <Grid item xs={12}>
                                    <Lightbox
                                        images={buildingImages}
                                        thumbnailWidth="384px"
                                        thumbnailHeight="216px"
                                        renderImageFunc={(
                                            idx: number,
                                            image: LightboxImage,
                                            toggleLightbox: any,
                                            width: string,
                                            height: string,
                                        ) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    className="lightbox-img-thumbnail  lightbox-img-thumbnail--cubit"
                                                    style={{
                                                        backgroundImage: `url(${image.src})`,
                                                        width: width,
                                                        height: height,
                                                    }}
                                                    onClick={toggleLightbox.bind(null, idx)}
                                                >
                                                    {!isTaxationEditingFinished(taxation) && (
                                                        <div style={{ position: 'absolute', right: '6px', top: '6px' }}>
                                                            <IconButton
                                                                size="small"
                                                                onClick={e => handleImageDelete(e, idx)}
                                                                style={{ backgroundColor: 'rgba(255,255,255,0.26)' }}
                                                            >
                                                                <Clear />
                                                            </IconButton>
                                                            <ImageTransferButtons imageId={idx} fieldId={index} fields={fields} handleImageTransfer={handleImageTransfer} />
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }}
                                    />
                                </Grid>

                                {!isTaxationEditingFinished(taxation) && (
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <Field name="files" component={renderDropzoneInput} />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            {editingBuildingInfo || buildingImages.length > 0 ? (
                                <Divider style={{ margin: '24px -16px' }}></Divider>
                            ) : null}

                            <Grid container spacing={2}>
                                <Grid item xs={12} className={styles.tableWrapper}>
                                    {/* @ts-ignore */}
                                    <FieldArray
                                        name={`${buildingFieldName}.floors`}
                                        component={TaxationPropertyBuildingFloors}
                                        form={form}
                                        editable={editingBuildingInfo}
                                        taxationFinished={isTaxationEditingFinished(taxation)}
                                    />
                                </Grid>

                                <Grid item xs={12} />
                            </Grid>

                            <Attributes
                                id={building.id}
                                attributes={building.attributes}
                                type={attributeKeys.BUILDING}
                                propertyId={taxation.propertyId}
                                afterEdit={updateBuildingAttributes}
                            />

                            <Divider style={{ margin: '24px -16px' }}></Divider>

                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                    <Field
                                        {...comment.props}
                                        name={`${buildingFieldName}.comment`}
                                        label={tr(TEXT_NOTE)}
                                        multiline
                                        disabled={!editingBuildingInfo}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    }
                </AccordionDetails>

                {editingBuildingInfo && (
                    <>
                        <Divider />
                        <AccordionActions>
                            <Button onClick={handleCancelEditBuildingInformation} className={styles.cancelButton}>
                                {tr(TEXT_CANCEL)}
                            </Button>
                            <Button
                                onClick={submitForm}
                                variant="contained"
                                color="primary"
                            >
                                {tr(TEXT_SAVE)}
                            </Button>
                        </AccordionActions>
                    </>
                )}
            </Accordion>
            <Dialog onClose={handleCancelDelete} open={deleteDialogOpen}>
                <DialogTitle>
                    {tr(TEXT_ERASE_BUILDING)} {building.buildingNumber} {building.buildingCode}?
                </DialogTitle>
                <DialogContent style={{ width: '480px' }}>
                    <Field
                        name={`deletedBuildings.${building.id}.reason`}
                        component={CubitTextFieldAdapter}
                        type="text"
                        label={tr(TEXT_NOTE)}
                        multiline
                        disabled={!editingBuildingInfo}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="inherit">
                        {tr(TEXT_CANCEL)}
                    </Button>
                    <Button onClick={handleRemoveBuilding} disabled={!isDeleted} color="primary" autoFocus>
                        {tr(TEXT_YES)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
