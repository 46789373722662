import React, { useState } from 'react'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_START_TAXATION,
    TEXT_DIRECTIONS,
    TEXT_ROLLBACK,
    TEXT_CANCEL,
    TEXT_TAXATION,
    TEXT_COULD_NOT_TAXATE,
    TEXT_NOTE,
    TEXT_SELECT_FOR_TAXATION,
    TEXT_ASSIGN,
    TEXT_COMPANIES,
    TEXT_USERS,
    TEXT_UNASSIGN,
} from 'utils/translations/keys'
import { Directions } from '@material-ui/icons'
import { coordsFromZone32toGoogle, getPropertyAddresses, isPropertyTaxable } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { find, sortBy } from 'lodash'
import { PropertyObjectWithTaxations } from 'models/property-object'
import { getPropertyTitle } from 'utils'
import { actionTaxationCreate } from 'property-taxation-edit-page/taxation-actions'
import {
    actionPropertyTaxationDelete,
    actionPropertyTaxationUpdate,
    actionAssignProperty,
    actionPropertyEventsCreate,
    actionCreateTaxation,
} from 'property-page/property-actions'
import { TaxationState } from 'models/taxation'
import { DateTime } from 'luxon'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { LatLong } from 'models/LatLong'
import { useUserId } from 'auth/auth-selectors'
import { UserSelection } from 'common/user-selection/user-selection'
import { CalendarEventType } from 'shared-components/src/models/calendar-event'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { useUsers, useCompanies } from 'app/app-selectors'
import { FormControl, Select, OutlinedInput, MenuItem, Divider } from '@material-ui/core'
import { actionUnassignProperties } from 'search-page/search-actions'
import { allAssigned } from 'search-page/search-toolbar/helper'

type PropertyToolbarProps = {
    propertyData: PropertyObjectWithTaxations
}
export const PropertyToolbar: React.FC<PropertyToolbarProps> = (props) => {
    const {
        propertyData: { property, housingUnits, taxations, buildings },
    } = props
    const dispatch = useDispatch()

    const [couldNotTaxateText, setCouldNotTaxateText] = useState('')

    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)
    const currentUserId = useUserId()
    const users = useUsers()
    const companies = useCompanies()
    const usersArray = sortBy(
        sortBy(
            Object.keys(users).map((key) => users[key]),
            (u) => u.name,
        ),
        (u) => u.id !== currentUserId,
    )
    const usersWithoutCompanyArray = usersArray.filter((u) => !u.taxCompanyId)

    const propertyAddresses = getPropertyAddresses(housingUnits)
    const title = getPropertyTitle(property, propertyAddresses)

    const coords =
        property && property.plots[0]
            ? coordsFromZone32toGoogle(property.plots[0].coordinates.x, property.plots[0].coordinates.y)
            : { lat: 0, long: 0 }

    const sortedTaxations = sortBy(taxations, 'start').reverse() // sort by start date, so latest taxation is at the start

    const pendingTaxation = find(
        sortedTaxations,
        (taxation) => taxation.state === TaxationState.TaxationPlanned && !taxation.issue,
    ) // newly scheduled taxation in calendar without issues

    const latestTaxation = find(
        sortedTaxations,
        (taxation) =>
            taxation.state === TaxationState.TaxationStarted ||
            (taxation.state === TaxationState.TaxationPlanned && !!taxation.issue),
    )

    const renderDirectionsButton = () =>
        coords ? (
            <Button
                variant="outlined"
                color="inherit"
                type="button"
                onClick={() => openGoogleMapsWithDirections(coords)}
            >
                {tr(TEXT_DIRECTIONS)}
                <Directions style={{ marginLeft: '8px' }} />
            </Button>
        ) : null

    const handleScheduleTaxationChange = (userId: string) => {
        if (!userId) {
            return
        }

        dispatch(actionPropertyEventsCreate([property], userId, CalendarEventType.ManualTaxation))
    }

    const handleSelectForTaxation = () => {
        dispatch(actionAssignProperty([{ propertyId: property.id }]))
    }

    const handleAssignPropertyForUser = (user: any) => {
        if (!user) {
            return
        }

        dispatch(
            actionAssignProperty([
                {
                    companyId: user.taxCompanyId,
                    userId: user.id,
                    propertyId: property.id,
                },
            ]),
        )
    }

    const handleAssignPropertyForCompany = (company: any) => {
        if (!company) {
            return
        }

        dispatch(
            actionAssignProperty([
                {
                    companyId: company.id,
                    propertyId: property.id,
                },
            ]),
        )
    }

    const handleCreateTaxation = () => {
        dispatch(actionCreateTaxation(property.id))
    }

    const unassignProperties = () => {
        dispatch(actionUnassignProperties([property.id]))
    }

    const noticeSent = property?.otherPropertyFlags?.taxNoticeSent

    const renderTaxCreationControls = () => (
        <Grid container justifyContent="flex-end" spacing={2}>
            {allAssigned([property]) && (
                <Grid item>
                    <Button variant="outlined" color="inherit" type="button" onClick={unassignProperties}>
                        {tr(TEXT_UNASSIGN)}
                    </Button>
                </Grid>
            )}
            <Protected p={[Permission.TaxateAllTaxations, Permission.TaxateOwnCompanyTaxations]}>
                {noticeSent && (
                    <Grid item>
                        <Button variant="outlined" color="inherit" type="button" onClick={handleCreateTaxation}>
                            {tr(TEXT_START_TAXATION)}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button variant="outlined" color="inherit" type="button" onClick={handleSelectForTaxation}>
                        {tr(TEXT_SELECT_FOR_TAXATION)}
                    </Button>
                </Grid>
                <Grid item>
                    <FormControl className="whiteControl">
                        <Select
                            value={0}
                            onChange={(e) => handleAssignPropertyForUser(e.target.value)}
                            input={<OutlinedInput margin="dense" labelWidth={0} />}
                        >
                            <MenuItem value={0}> {tr(TEXT_ASSIGN)}</MenuItem>
                            <li style={{ padding: '8px', cursor: 'default' }}>{tr(TEXT_COMPANIES)}</li>

                            <Divider></Divider>

                            {companies.map((company: any, index: number) => (
                                <span key={index}>
                                    <MenuItem onClick={() => handleAssignPropertyForCompany(company)}>
                                        {company.name}
                                    </MenuItem>
                                    {company.users.map((userId: string, index: number) => (
                                        <MenuItem
                                            key={index}
                                            onClick={() => handleAssignPropertyForUser(users[userId])}
                                            style={{ paddingLeft: '32px' }}
                                        >
                                            {users[userId].name}
                                        </MenuItem>
                                    ))}
                                    <Divider></Divider>
                                </span>
                            ))}

                            <Protected p={Permission.TaxateAllTaxations}>
                                <li style={{ padding: '8px', cursor: 'default' }}>{tr(TEXT_USERS)}</li>

                                <Divider></Divider>

                                {usersWithoutCompanyArray.map((user: any, index: number) => (
                                    <MenuItem key={index} onClick={() => handleAssignPropertyForUser(user)}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                            </Protected>
                        </Select>
                    </FormControl>
                </Grid>
            </Protected>
            <Grid item>
                <UserSelection usersArray={usersArray} onChange={handleScheduleTaxationChange}></UserSelection>
            </Grid>
        </Grid>
    )

    const renderTaxationButtons = () => (
        <Grid container justifyContent="flex-end" spacing={2}>
            {pendingTaxation ? (
                <>
                    {!pendingTaxation.issue && (
                        <Grid item>
                            <Button variant="text" color="inherit" type="button" onClick={handleCouldNotTaxateClick}>
                                {tr(TEXT_COULD_NOT_TAXATE)}
                            </Button>
                        </Grid>
                    )}
                    <Grid item>{renderDirectionsButton()}</Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="inherit"
                            type="button"
                            onClick={() => dispatch(actionTaxationCreate(property.id, pendingTaxation.id))}
                        >
                            {tr(TEXT_START_TAXATION)}
                        </Button>
                    </Grid>
                </>
            ) : latestTaxation ? (
                <Grid item>
                    <Button variant="outlined" color="inherit" type="button" onClick={handleRollbackClick}>
                        {tr(TEXT_ROLLBACK)}
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    )

    const openGoogleMapsWithDirections = (coordinates: LatLong) => {
        window.open(`https://www.google.com/maps/dir//${coordinates.lat},${coordinates.long}/`)
    }

    const [rollbackDialogOpen, setRollbackDialogOpen] = useState(false)

    const handleRollbackClick = () => {
        setRollbackDialogOpen(true)
    }

    const handleRollbackDialogClose = () => {
        setRollbackDialogOpen(false)
    }

    const handleRollbackTaxation = () => {
        setRollbackDialogOpen(false)
        latestTaxation && dispatch(actionPropertyTaxationDelete(property.id, latestTaxation.id))
    }

    const [couldNotTaxateDialogOpen, setCouldNotTaxateDialogOpen] = useState(false)

    const handleCouldNotTaxateClick = () => {
        setCouldNotTaxateDialogOpen(true)
    }

    const handleCouldNotTaxatekDialogClose = () => {
        setCouldNotTaxateDialogOpen(false)
    }

    const handleCouldNotTaxate = () => {
        setCouldNotTaxateDialogOpen(false)

        pendingTaxation &&
            dispatch(
                actionPropertyTaxationUpdate(property.id, {
                    ...pendingTaxation,
                    issue: { comment: couldNotTaxateText, userId: currentUserId },
                }),
            )
    }

    return (
        <TitleToolbar title={title}>
            {property && isPropertyTaxable(property, buildings) && renderTaxCreationControls()}
            {sortedTaxations && sortedTaxations.length > 0 && renderTaxationButtons()}

            <Dialog onClose={handleRollbackDialogClose} open={rollbackDialogOpen}>
                <DialogTitle>{`${tr(TEXT_ROLLBACK)} ${tr(TEXT_TAXATION)} ${
                    latestTaxation &&
                    DateTime.fromJSDate(new Date(latestTaxation.start))
                        .setLocale(language)
                        .toLocaleString(DateTime.DATE_SHORT)
                }`}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleRollbackDialogClose} color="inherit">
                        {tr(TEXT_CANCEL)}
                    </Button>
                    <Button onClick={handleRollbackTaxation} color="primary" autoFocus>
                        {tr(TEXT_ROLLBACK)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={handleCouldNotTaxatekDialogClose} open={couldNotTaxateDialogOpen}>
                <DialogTitle>
                    {`${tr(TEXT_COULD_NOT_TAXATE)}  ${
                        pendingTaxation &&
                        DateTime.fromJSDate(new Date(pendingTaxation.start))
                            .setLocale(language)
                            .toLocaleString(DateTime.DATE_SHORT)
                    }`}
                </DialogTitle>

                <DialogContent>
                    <TextField
                        label={tr(TEXT_NOTE)}
                        multiline
                        value={couldNotTaxateText}
                        onChange={(e) => setCouldNotTaxateText(e.target.value)}
                        margin="normal"
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCouldNotTaxatekDialogClose} color="inherit">
                        {tr(TEXT_CANCEL)}
                    </Button>
                    <Button
                        onClick={handleCouldNotTaxate}
                        color="primary"
                        disabled={couldNotTaxateText.length < 1}
                        autoFocus
                    >
                        {tr(TEXT_COULD_NOT_TAXATE)}
                    </Button>
                </DialogActions>
            </Dialog>
        </TitleToolbar>
    )
}
