export type CalendarEvent = {
    editable: boolean
    end?: Date
    id: string
    start?: Date
    type: CalendarEventType
    userId: string
}

export type CalendarEventInfo<E> = CalendarEvent & {
    relatedEntity: E
}

export type CubitCalendarEvent<E> = {
    allDay: false
    end?: Date
    extendedProps: CalendarEventInfo<E>
    id: string
    isSelected: boolean
    start?: Date
    title?: string
}

export enum CalendarEventType {
    Property = 'property',
    ComplaintTribunal = 'COMPLAINT_TRIBUNAL_TAXATION',
    ComplaintResolution = 'COMPLAINT_RESOLUTION',
    ManualTaxation = 'MANUAL_TAXATION'
}
