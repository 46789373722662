import { TribunalGroup, TribunalGroupType } from 'tribunal-page/models/tribunal-group'
import {
    TEXT_REJECTED_BY_THE_TRIBUNAL,
    TEXT_HAS_METRICS_CHANGE,
    TEXT_HAS_COMMENT,
    TEXT_UNASSIGNED,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

const tribunalGroupNameTranslationKeys: { [key: string]: string } = {
    [TribunalGroupType.Declined]: TEXT_REJECTED_BY_THE_TRIBUNAL,
    [TribunalGroupType.HasChanges]: TEXT_HAS_METRICS_CHANGE,
    [TribunalGroupType.HasComment]: TEXT_HAS_COMMENT,
    [TribunalGroupType.Unassigned]: TEXT_UNASSIGNED,
}

export default (group: TribunalGroup): string => {
    const translationKey = tribunalGroupNameTranslationKeys[group.type]

    return translationKey ? tr(translationKey) : group.name
}
