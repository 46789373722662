import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
    controls: {
        position: 'absolute',
        zIndex: 1,
        top: '160px',
        left: '28px',
        '& button': {
            minWidth: 'unset',
        },
    },
}))

interface Controls {
    children: JSX.Element
}

const Controls: React.FC<Controls> = ({ children }) => {
    const classes = useStyles()

    return <div className={`${classes.controls} ol-selectable ol-control`}>{children}</div>
}

export default Controls
