import React from 'react'
import { connect } from 'react-redux'

import { convertFromRaw, Editor, EditorState } from 'draft-js'

import './documentpreview.css'
import { DateTime } from 'luxon'
import { compositeReplaceDecorator, editorStyleMap, imageloadchecker } from './template-document-helper'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { plantsUrl } from 'services/httpService'

const ElementContents = ({ ratio, type, data, wkparams, element, texts }: any) => {
    if (type === 'postaladdress') {
        return (
            <div>
                <div>{data ? data.postalAddress?.name : ''}</div>
                <div>{data ? data.postalAddress?.addressText : ''}</div>
                <div>{data ? data.postalAddress?.postalInfo : ''}</div>
            </div>
        )
    }
    if (type === 'returnaddress') {
        let returnaddresssource
        let postalcodesource
        if (data.loggedinuser.type !== 'Automation') {
            returnaddresssource =
                data.loggedinusercompany && !data.loggedinusercompany.useDleReturnAddress
                    ? data.loggedinusercompany
                    : data.tenant
            postalcodesource =
                data.loggedinusercompany && !data.loggedinusercompany.useDleReturnAddress
                    ? data.loggedinusercompanypostalcode
                    : data.tenantpostalcode
        } else {
            returnaddresssource = data.company && !data.company.useDleReturnAddress ? data.company : data.tenant
            postalcodesource =
                data.company && !data.company.useDleReturnAddress
                    ? data.techniciancompanypostalcode
                    : data.tenantpostalcode
        }
        return (
            <div>
                <div>Retur:</div>
                <div>{returnaddresssource.name}</div>
                <div>{returnaddresssource.postalAddress.addressText}</div>
                <div>
                    {postalcodesource ? postalcodesource.code : ''} {postalcodesource ? postalcodesource.city : ''}
                </div>
            </div>
        )
    }
    if (type === 'header') {
        return <h1 style={{ fontSize: `${24 * ratio}px` }}>{data ? data.header : '???'}</h1>
    }
    let controldate

    if (data && data.taxation && data.taxation.start) {
        controldate = DateTime.fromISO(data.taxation.start).toFormat('dd.MM.yyyy')
    }
    if (type === 'summary') {
        const customerContact = data && data.taxation ? data.taxation.current.owners[0] : null
        const property = data && data.taxation ? data.taxation.current.property : null
        return (
            <div className="summary">
                {controldate ? (
                    <div>
                        <span className="label">Takseringdato</span>
                        <span className="value">{controldate}</span>
                    </div>
                ) : null}
                {property ? (
                    <div>
                        <span className="label">Eiendom matrikkel</span>
                        <span className="value">
                            {property.holdingNumber +
                                '/' +
                                property.subHoldingNumber +
                                '/' +
                                property.leaseNumber +
                                '/' +
                                property.sectionNumber}
                        </span>
                    </div>
                ) : null}
                {customerContact ? (
                    <div>
                        <span className="label">Eier</span>
                        <span className="value">{customerContact.firstName + ' ' + customerContact.lastName}</span>
                    </div>
                ) : null}
            </div>
        )
    }
    if (type === 'pagenumber' && wkparams) {
        return (
            <div>
                Side {wkparams.page} av {wkparams.toPage}
            </div>
        )
    }
    if (type === 'divider') {
        return <hr style={{ margin: '1px 0' }} />
    }
    if (type === 'text') {
        const textElement = texts.find((t: any) => t.id === element.id)
        if (textElement) {
            let state = textElement.state
            if (!state.getCurrentContent) {
                state = convertFromRaw(textElement.state)
                state = EditorState.createWithContent(state, compositeReplaceDecorator(data))
            }
            return <Editor readOnly={true} customStyleMap={editorStyleMap} editorState={state} onChange={console.log}/>
        }
        return <div>Tekst ikke funnet!</div>
    }
    if (type === 'image') {
        return (
            <div
                style={{
                    backgroundImage: `url('${plantsUrl('/documentTemplate/images/' + element.id)}')`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100%',
                }}
            ></div>
        )
    }
    return <span></span>
}

const DocumentTemplatePresentation = ({
    isFooter,
    elements,
    ratio,
    forPrint,
    noBorder,
    logoClass,
    canvasStyle,
    pixelsPerMm,
    data,
    wkparams,
    draggable,
    dragStart,
    dragEnd,
    dragOver,
    elementDropped,
    selectElement,
    selectedElement,
    texts
}: any) => {
    return (
        <div
            id={isFooter ? 'footercanvas' : 'documentcanvas'}
            className={'canvas a4' + (noBorder ? ' noborder' : '')}
            style={canvasStyle}
            onDragOver={dragOver}
            onDrop={elementDropped}
        >
            {!forPrint && !isFooter ? (
                <div
                    className="c5window"
                    style={{
                        top: 8 * pixelsPerMm,
                        left: 16 * pixelsPerMm,
                        width: 110 * pixelsPerMm,
                        height: 16 * pixelsPerMm,
                    }}
                ></div>
            ) : null}
            {!forPrint && !isFooter ? (
                <div
                    className="c5window"
                    style={{
                        top: 40 * pixelsPerMm,
                        left: 16 * pixelsPerMm,
                        width: 110 * pixelsPerMm,
                        height: 32 * pixelsPerMm,
                    }}
                ></div>
            ) : null}
            {elements.map((e: any, i: number) => {
                let extraclass = e.type === 'gridcompanylogo' ? ' ' + logoClass : ''
                let refmethod = e.type === 'gridcompanylogo' ? imageloadchecker : null
                const isSelected =
                    selectedElement &&
                    selectedElement.index !== -1 &&
                    ((isFooter &&
                        selectedElement.name === TaxFormFieldNames.footerElements &&
                        selectedElement.index === i) ||
                        (!isFooter &&
                            selectedElement.name === TaxFormFieldNames.elements &&
                            selectedElement.index === i))
                return (
                    <div
                        ref={refmethod}
                        key={i}
                        className={'element ' + (isSelected ? 'selected ' : '') + e.type + extraclass}
                        onClick={selectElement ? selectElement.bind(null, i, isFooter) : null}
                        draggable={draggable}
                        onDragStart={dragStart ? dragStart.bind(null, i) : null}
                        onDragEnd={dragEnd}
                        style={{ top: e.pixeltop, left: e.pixelleft, width: e.pixelwidth, height: e.pixelheight }}
                    >
                        <ElementContents
                            element={e}
                            texts={texts}
                            ratio={ratio}
                            type={e.type}
                            data={data}
                            wkparams={wkparams}
                        />
                    </div>
                )
            })}
        </div>
    )
}

const recalculateElementLocationAndSize = (elements: any, pixelsPerMm: number) => {
    if (!elements || !elements.length) {
        return elements
    }
    return elements.map((e: any) => {
        return {
            ...e,
            pixelwidth: e.width * pixelsPerMm,
            pixelheight: e.height * pixelsPerMm,
            pixeltop: e.top * pixelsPerMm,
            pixelleft: e.left * pixelsPerMm
        }
    })
}

const mapStateToProps = (state: any, props: any) => {
    const isFooter = props.isFooter
    const canvasWidth = props.canvasWidth || 1000
    const pixelsPerMm = canvasWidth / 210
    const canvasHeight = (isFooter ? props.template.footerHeight : props.template.height) * pixelsPerMm
    const fontSizeEm = canvasWidth / 1000
    let elements = isFooter ? props.template.footerElements : props.template.elements
    elements = recalculateElementLocationAndSize(elements, pixelsPerMm)
    const taxation = state.taxations[Object.keys(state.taxations)[0]]
    const tenantId = state.auth?.user ? state.auth.user.tenantId : taxation?.staticSettings?.tenantId ?? ''
    let logoClass = ''
    if (state.letterData) logoClass = state.letterData.logoClass
    else if (tenantId) logoClass = `logo-${tenantId}`
    return {
        isFooter,
        elements,
        ratio: 1000 / canvasWidth,
        pixelsPerMm,
        canvasStyle: { width: canvasWidth, height: canvasHeight, fontSize: fontSizeEm + 'em' },
        noBorder: props.noBorder,
        draggable: props.dragStart ? true : false,
        elementDropped: props.elementDropped ? props.elementDropped.bind(null, pixelsPerMm, isFooter) : null,
        logoClass,
    }
}

const mapDispatchToProps = (dispatch: any, props: any) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTemplatePresentation)
