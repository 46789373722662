import React from 'react'
import { Grid, makeStyles, Theme } from '@material-ui/core'
import { ActivityLogItem } from './activity-log-item'
import { ActivityLogType } from './activity-log-type.enum'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import * as texts from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

const propertyNameTranslationKey: { [key: string]: string } = {
    ValueType: texts.TEXT_VALUE_TYPE,
    LocationFactor: texts.TEXT_LOCATION_FACTOR,
    Comment: texts.TEXT_NOTE,
    StandardFactor: texts.TEXT_STANDARD_FACTOR,
    FloorNumber: texts.TEXT_FLOOR,
    Usage: texts.TEXT_USE,
    Area: texts.TEXT_AREA,
    NationalValuation: texts.TEXT_USE_NATIONAL_SETTINGS,
    Zone: texts.TEXT_ZONE,
    ZoneFactor: texts.TEXT_ZONE_FACTOR,
    "OtherPropertyFlags.DeductibleHousingUnitsCount": texts.TEXT_RESIDENTIAL_UNITS,
}

enum PropertiesOfInterest {
    OwnerShips = 'OwnerShips'
}

const propsToSkip: string[] = [
    PropertiesOfInterest.OwnerShips,
]

const useStyles = makeStyles((theme: Theme) => {
    return {
        activityLogContainer: {
            // paddingTop: theme.spacing(2),
        },
    }
})

export const ActivityLog: React.FC<{ objectId: string }> = ({ objectId }) => {
    const styles = useStyles()
    const activityLog = useSelector((state: AppState) => state.activityLog[objectId])

    const skipFilter = (change: any) => {
        const prop = change?.property ?? ''
        return !propsToSkip.some((p: string) => prop.startsWith(p))
    }

    return (
        <>
            {/* <Grid container justifyContent="center" spacing={4}>
                <Grid item>
                    <Button variant="contained" color="primary">
                        {tr(TEXT_ADD_NEW_COMMENT)}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <FormControl style={{ width: '100%' }}>
                        <Select value={10}>
                            <MenuItem value={10}>{tr(TEXT_VIEW_ALL_COMMENTS_AND_NOTES)}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid> */}

            <Grid container className={styles.activityLogContainer}>
                {activityLog &&
                    activityLog.entries.map((entry, index) => {
                        if (!entry.changes.length && entry.comment) {
                            return (
                                <Grid item xs={12} key={index}>
                                    <ActivityLogItem type={ActivityLogType.C} entry={entry}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {entry.comment}
                                            </Grid>
                                        </Grid>
                                    </ActivityLogItem>
                                </Grid>
                            )
                        }
                        return entry.changes.filter(skipFilter).map((change, index) => (
                            <Grid key={index} item xs={12}>
                                <ActivityLogItem type={ActivityLogType.A} entry={entry}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            {propertyNameTranslationKey[change.property]
                                                ? tr(propertyNameTranslationKey[change.property])
                                                : change.property}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {change.oldValue}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ArrowRightAltIcon />
                                        </Grid>
                                        <Grid item xs={3}>
                                            {change.newValue === null ? tr(texts.TEXT_DELETED) : change.newValue}
                                        </Grid>
                                    </Grid>
                                </ActivityLogItem>
                            </Grid>
                        ))
                    })}
                {/* <Grid item xs={12}>
                    <PropertyActivityLogItem type={ActivityLogType.A}>
                        En kommentar om noe som hva vært endret på kunden og hvorfor dette ble gjort
                    </PropertyActivityLogItem>
                </Grid>
                <Grid item xs={12}>
                    <PropertyActivityLogItem type={ActivityLogType.B}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box fontWeight={500}>Oppdatert telefonnummer</Box>
                            </Grid>
                            <Grid item xs={3}>
                                Tlf
                            </Grid>
                            <Grid item xs={3}>
                                4567 8910
                            </Grid>
                            <Grid item xs={3}>
                                <ArrowRightAltIcon />
                            </Grid>
                            <Grid item xs={3}>
                                4567 8911
                            </Grid>
                        </Grid>
                    </PropertyActivityLogItem>
                </Grid>
                <Grid item xs={12}>
                    <PropertyActivityLogItem type={ActivityLogType.C}>
                        <Grid container>
                            <Grid item xs={3}>
                                Planlagt
                            </Grid>
                            <Grid item xs={3}>
                                Uplanlagt
                            </Grid>
                            <Grid item xs={3}>
                                <ArrowRightAltIcon />
                            </Grid>
                            <Grid item xs={3}>
                                06.11.2018 08:30
                            </Grid>
                        </Grid>
                    </PropertyActivityLogItem>
                </Grid> */}
            </Grid>
        </>
    )
}
