import { Taxation } from 'models/taxation'
import { mapTaxation } from 'utils'

export default (tribunalGroupsTaxationsMap: { [key: string]: Taxation[] }): { [key: string]: Taxation[] } => {
    const mappedGroupTaxations: { [key: string]: Taxation[] } = {}
    Object.keys(tribunalGroupsTaxationsMap).forEach(groupId => {
        mappedGroupTaxations[groupId] = tribunalGroupsTaxationsMap[groupId].map(groupTaxation =>
            mapTaxation(groupTaxation),
        )
    })

    return mappedGroupTaxations
}
