import React, { MutableRefObject, useMemo } from 'react'
import { CalendarView, MemoCalendar } from './calendar'
import { CubitCalendarState } from './calendar-recuder'
import { useDispatch, useSelector } from 'react-redux'
import {
    actionCubitCalendarEventDrop,
    actionCubitCalendarEventDropExternal,
    actionCubitCalendarEventsRemove,
    actionCubitCalendarEventResize,
    actionCubitCalendarEventUnassign,
    actionCubitCalendarSetDragging,
} from './calendar-actions'
import { isOverElement } from '../utils/math'

export const calendarSelector = (state: { calendar: CubitCalendarState }): CubitCalendarState => state.calendar
export const calendarViewSelector = (state: { calendar: CubitCalendarState }): CalendarView => state.calendar.view
export const calendarDateSelector = (state: { calendar: CubitCalendarState }): string => state.calendar.date
export const calendarShowWeekendsSelector = (state: { calendar: CubitCalendarState }): boolean =>
    state.calendar.showWeekends

type Props = {
    events: any[]
    onRender: (calendarView: CalendarView) => (info: any) => void
    onDateClick: (info: any) => void
    onEventClick: (info: any) => void
    removeContainer: MutableRefObject<any>
    unassignContainer: MutableRefObject<any>
    removeContainerMobile: MutableRefObject<any>
    unassignContainerMobile: MutableRefObject<any>
    scrollTime: string
}

export const ReduxCalendar: React.FC<Props> = props => {
    const {
        events,
        onRender,
        onDateClick,
        removeContainer,
        unassignContainer,
        removeContainerMobile,
        unassignContainerMobile,
        onEventClick,
        scrollTime,
    } = props

    const view = useSelector(calendarViewSelector)
    const date = useSelector(calendarDateSelector)
    const showWeekends = useSelector(calendarShowWeekendsSelector)
    const dispatch = useDispatch()

    // hack to prevent rerendering calendar on drag.
    const cb = useMemo(
        () => ({
            onDrop: (args: any) => dispatch(actionCubitCalendarEventDrop(args.event)),
            onDropExternal: (args: any) => dispatch(actionCubitCalendarEventDropExternal(args.event)),
            onDragStart: () => dispatch(actionCubitCalendarSetDragging(true)),
            onDragStop: (args: any) => {
                if (
                    isOverElement(removeContainer.current, args.jsEvent) ||
                    isOverElement(removeContainerMobile.current, args.jsEvent)
                ) {
                    dispatch(actionCubitCalendarEventsRemove([args.event]))
                }
                if (
                    isOverElement(unassignContainer.current, args.jsEvent) ||
                    isOverElement(unassignContainerMobile.current, args.jsEvent)
                ) {
                    dispatch(actionCubitCalendarEventUnassign(args.event))
                }
                dispatch(actionCubitCalendarSetDragging(false))
            },
            onResize: (args: any) => dispatch(actionCubitCalendarEventResize(args.event)),
            onRender: onRender(view),
            onDateClick: (args: any) => onDateClick(args),
            onEventClick: (args: any) => onEventClick(args.event),
        }),
        [
            dispatch,
            onRender,
            removeContainer,
            unassignContainer,
            removeContainerMobile,
            unassignContainerMobile,
            onDateClick,
            onEventClick,
            view,
        ],
    )

    return (
        <MemoCalendar
            events={events}
            defaultView={view}
            showWeekends={showWeekends}
            view={view}
            date={date}
            onDateClick={cb.onDateClick}
            onEventClick={cb.onEventClick}
            onRender={cb.onRender}
            onDrop={cb.onDrop}
            onResize={cb.onResize}
            onDropExternal={cb.onDropExternal}
            eventDragStart={cb.onDragStart}
            eventDragStop={cb.onDragStop}
            scrollTime={scrollTime}
        />
    )
}
