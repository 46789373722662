import React, { useEffect } from 'react'
import { CubitExpandablePanel } from 'common/cubit-expandable-panel/cubit-expandable-panel'
import { tr } from 'utils/translations/translate'
import {
    TEXT_POLITICAL,
    TEXT_CADASTRAL_ERROR,
    TEXT_VALUATION_ERROR,
    TEXT_COMBINED,
    TEXT_JUDGMENTAL_ERRORS,
    TEXT_COMPLAINT_BOARD,
    TEXT_COMPLAINTS,
    TEXT_ADDRESS,
    TEXT_CAUSE,
    TEXT_STATUS,
} from 'utils/translations/keys'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { RouteComponentProps, navigate } from '@reach/router'
import { ComplaintGroupType } from 'tribunal-page/enums/complaint-group-type.enum'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { useComplaints } from 'tribunal-page/tribunal-selectors'
import getGroupedComplaints from 'utils/tribunal/get-grouped-complaints'
import { Complaint } from 'tribunal-page/models/complaint.type'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { actionTribunalComplaintsGetAll } from 'tribunal-page/tribunal-actions'
import { CubitTableColumn } from 'shared-components/src/cubit-table/cubit-table.types'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'
import { getComplaintCauseTranslationKey } from 'utils'
import { TaxationTabs } from 'common/enums/taxation'
import { getComplaintStatusText } from 'tribunal-page/enums/complaint-status.enum'

export const ComplaintsPage: React.FC<RouteComponentProps<{ complaintGroupType: number }>> = props => {
    const { complaintGroupType = '' } = props
    const dispatch = useDispatch()

    const panelsState = {
        [ComplaintGroupType.Political]: false,
        [ComplaintGroupType.CadastralError]: false,
        [ComplaintGroupType.ValuationError]: false,
        [ComplaintGroupType.JudgmentalError]: false,
        [ComplaintGroupType.ComplaintBoard]: false,
        [ComplaintGroupType.Combined]: false,
    }

    panelsState[complaintGroupType as ComplaintGroupType] = true

    const complaints = useComplaints()

    useEffect(() => {
        if (complaints && complaints.length > 0) {
            return
        }
        dispatch(actionTribunalComplaintsGetAll())
    }, [dispatch, complaints])

    const groupedComplaints = getGroupedComplaints(complaints)

    const complaintPanels: ComplaintPanelContent[] = [
        {
            label: tr(TEXT_POLITICAL),
            type: ComplaintGroupType.Political,
            complaints: groupedComplaints.political,
        },
        {
            label: tr(TEXT_CADASTRAL_ERROR),
            type: ComplaintGroupType.CadastralError,
            complaints: groupedComplaints.cadastralError,
        },
        {
            label: tr(TEXT_VALUATION_ERROR),
            type: ComplaintGroupType.ValuationError,
            complaints: groupedComplaints.valuationError,
        },
        {
            label: tr(TEXT_JUDGMENTAL_ERRORS),
            type: ComplaintGroupType.JudgmentalError,
            complaints: groupedComplaints.judgmentalError,
        },
        {
            label: tr(TEXT_COMPLAINT_BOARD),
            type: ComplaintGroupType.ComplaintBoard,
            complaints: groupedComplaints.complaintBoard,
        },
        {
            label: tr(TEXT_COMBINED),
            type: ComplaintGroupType.Combined,
            complaints: groupedComplaints.combined,
        },
    ]

    const handleComplaintsTableClick = (row: any, e: any) => {
        if (row && row.taxationId) {
            navigate(`/taxation/${row.taxationId}/${TaxationTabs.complaints}`)
        }
    }

    const renderPanelSummary = (count: number) => (
        <Grid container justifyContent="flex-end">
            <Grid item>
                <Typography variant="h6">{count}</Typography>
            </Grid>
        </Grid>
    )

    return (
        <LayoutDefault navigation={<Navigation />} toolbar={<TitleToolbar title={tr(TEXT_COMPLAINTS)} />}>
            {complaintPanels.map((cp, index) => (
                <CubitExpandablePanel
                    key={index}
                    label={cp.label}
                    AccordionProps={
                        { 
                            defaultExpanded: panelsState[cp.type],
                            children: <></>
                        }
                    }
                    panelSummary={renderPanelSummary(cp.complaints.length)}
                >
                    <CubitTable
                        columns={complaintsTableColumns()}
                        data={cp.complaints}
                        name={`${complaintsTableNamePrefix}${index}`}
                        onRowClick={handleComplaintsTableClick}
                        style={{ margin: '0 -24px', width: 'calc(100% + 48px' }}
                        sidePadding={'24'}
                        sorting
                        flat
                    />
                </CubitExpandablePanel>
            ))}
        </LayoutDefault>
    )
}

type ComplaintPanelContent = {
    label: string
    type: ComplaintGroupType
    complaints: Complaint[]
}

const complaintsTableNamePrefix = 'complaintsTable-'
const complaintsTableColumns = (): CubitTableColumn[] => [
    {
        headerLabel: 'Grd/brk',
        key: 'propertyNumber',
    },
    {
        headerLabel: tr(TEXT_ADDRESS),
        key: 'address',
    },
    {
        headerLabel: tr(TEXT_CAUSE),
        key: 'causes',
        getDisplayableElement: (complaint: Complaint) => (
            <span>
                {complaint.causes.map(cause => tr(getComplaintCauseTranslationKey(cause.type))).join(' · ') || ''}
            </span>
        ),
    },
    {
        headerLabel: tr(TEXT_STATUS),
        key: 'status',
        getDisplayableElement: (complaint: Complaint) => <span>{getComplaintStatusText(complaint.status)}</span>,
    },
]
