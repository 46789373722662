import { TaxationState } from 'models/taxation'
import { TEXT_PLANNED, TEXT_STARTED, TEXT_REJECTED, TEXT_COMPLETED, TEXT_FINISHED, TEXT_ADDED_TO } from 'utils/translations/keys'

const statusKeyByState: { [key: string]: string } = {
    [TaxationState.TaxationReadyForPlanning]: TEXT_ADDED_TO,
    [TaxationState.TaxationPlanned]: TEXT_PLANNED,
    [TaxationState.TaxationStarted]: TEXT_STARTED,
    [TaxationState.TribunalDeclined]: TEXT_REJECTED,
    [TaxationState.TaxationFinished]: TEXT_FINISHED,
    [TaxationState.TribunalFinished]: TEXT_FINISHED,
}

export default (taxationState: TaxationState): string =>
    statusKeyByState[taxationState] ? statusKeyByState[taxationState] : TEXT_COMPLETED
