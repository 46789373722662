import React from 'react'
import { tr } from '../utils/translations/translate'
import { TEXT_FLOOR, TEXT_FLOOR_FACTOR, TEXT_M2_PRICE, TEXT_USE, TEXT_VALUE } from '../utils/translations/keys'
import {
    getBillingReceiver,
    getDocumentReceiver,
    getFlatReduction,
    getFlatReductionValue,
    getManualValueComment,
    getOtherValue,
    getOtherValueAfterFactorsAndReduction,
    getReductionFactor,
    getReductionFromValue,
    getReductionSum,
    getResidentialHousingUnitCount,
    getResidentialValueAfterFactors,
    getResidentialValueAfterFactorsAndReduction,
    getTotalValueAfterFactors,
    getTotalValueAfterFactorsAndReduction,
    isCalculationExempt,
    isUsingManualValue,
    StaticTemplateType,
    Taxation,
} from 'models/taxation'
import { Divider, Grid, Table, TableBody, Theme, Typography } from '@material-ui/core'
import { getFullMatrikkelAddress } from 'utils/tribunal/tribunal-helper'
import { formatArea, formatCurrency } from 'utils'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getOwnerDisplayName, Owner } from 'models/owner'
import { getPlotSize } from 'utils/property/property-helper'
import { formatFactor } from 'property-page/taxations-history/functions'
import { Floor } from 'models/floor'
import { CubitTableHeader } from 'shared-components/src/cubit-table/cubit-table-header'
import { CubitTableHeaderProps } from 'shared-components/src/cubit-table/cubit-table.types'
import { getHousingUnitsAddresses } from 'models/housing-unit'

const useStyles = makeStyles((theme: Theme) => ({
    black: {
        backgroundColor: '#000',
        marginBottom: 10,
    },
    blackSpaced: {
        backgroundColor: '#000',
        margin: '20px 0',
    },
    tableContents: {
        padding: '12px 35px 30px 31px',
        margin: 0,
    },
    letterHeader: {
        fontWeight: 500,
        marginBottom: 50,
    },
    letterHeader1: {
        fontWeight: 500,
    },
    divider: {
        margin: '20px 0 5px 0',
        width: '100%',
    },
    dividerSmall: {
        marginBottom: 11,
    },
    dividerBold: {
        marginBottom: 11,
        height: 2,
        backgroundColor: 'rgb(0, 0, 0, 0.5)',
    },
    tableWrapper: {
        margin: '0 -16px',
        width: 'calc(100% + 32px)',
        maxWidth: 'calc(100% + 32px)',
        flexBasis: 'auto',
    },
    tableCell: {
        padding: '8px 40px 8px 16px',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        verticalAlign: 'inherit',
    },
    alighnRight: {
        textAlign: 'right',
    },
    tableRow: {
        padding: theme.spacing(1),
        //borderBottom: '1px solid rgba(224, 224, 224, 1)',
        '& .MuiGrid-item': {
            padding: theme.spacing(1, 2),
        },
    },
    spaced: {
        lineHeight: 2,
    },
    subHeader: {
        fontWeight: 'bold',
        marginTop: 28,
        lineHeight: 1.82,
    },
    padding: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: 40,
    },
    customizedTable: {
        ' & th': {
            padding: '8px 40px 8px 16px !important',
            fontWeight: 600,
            color: '#000',
        },
    },
    summary: {
        paddingRight: 25,
        lineHeight: 2,
    },
    spacer: {
        display: 'block',
        width: '100%',
        marginBottom: 30,
    },
}))

type StaticTemplateProps = {
    taxation: Taxation
    templateType: StaticTemplateType
}

const tableHeaderProps: CubitTableHeaderProps = {
    columns: [
        {
            headerLabel: tr(TEXT_FLOOR),
            key: 'floorNumber',
        },
        {
            headerLabel: tr(TEXT_USE),
            key: 'usage',
        },
        {
            headerLabel: tr(TEXT_FLOOR_FACTOR),
            key: 'usageFactor',
        },
        {
            headerLabel: tr(TEXT_M2_PRICE),
            key: 'pricePerM2',
        },
        {
            headerLabel: 'BRA',
            key: 'area',
        },
        {
            headerLabel: tr(TEXT_VALUE),
            key: 'value',
            align: 'right',
        },
    ],
}

export const StaticTemplate: React.FC<StaticTemplateProps> = (props) => {
    const { taxation, templateType } = props

    const styles = useStyles()
    const property = taxation.current.property
    const isExempt = isCalculationExempt(taxation.calculationDetails)
    const buildings = taxation.current.buildings
    const footnote = (
        <p className={styles.tableContents}>
            Mer informasjon om eiendomsskatt kan du finne på Ringerike kommunes nettside:
            <br></br>
            <a href="www.ringerike.kommune.no/eiendomsskatt">www.ringerike.kommune.no/eiendomsskatt</a>.
        </p>
    )

    if (templateType === StaticTemplateType.BO1) {
        return (
            <div>
                <div className={styles.tableContents}>
                    <Typography className={styles.letterHeader}>Skatteseddel {new Date().getFullYear()}</Typography>
                    <Typography>
                        Din bolig er oppført hos Skatteetaten med boligverdi som angitt i tabell under. Det gir følgende beregning av eiendomsskatt for skatteåret {new Date().getFullYear()}.
                    </Typography>
                </div>
                <div className={styles.tableContents}>
                    <Divider className={styles.black} />
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eiendom:
                                </Grid>
                                <Grid item xs={8}>{`${property.municipalityNumber}-${getFullMatrikkelAddress(
                                    property,
                                )}`}</Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Eiendommens adresse:
                                </Grid>
                                <Grid item xs={6}>
                                    {getHousingUnitsAddresses(taxation.current.housingUnits)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />

                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eier:
                                </Grid>
                                <Grid item xs={8}>
                                    {taxation.current.owners.map((owner: Owner, index) => (
                                        <div key={index}>{getOwnerDisplayName(owner)}</div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Dokumentmottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getDocumentReceiver(taxation)}
                                </Grid>
                                <Grid item xs={6}>
                                    Fakturamottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getBillingReceiver(taxation)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />

                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Fritak:
                                </Grid>
                                <Grid item xs={8}>
                                    {isExempt ? 'Ja' : 'Nei'}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Antall boenheter:
                                </Grid>
                                <Grid item xs={6}>
                                    {getResidentialHousingUnitCount(taxation.current)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />
                    </Grid>

                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattegrunnlag fra skatteetaten
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getTotalValueAfterFactors(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor*
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionSum(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Bunnfradrag**
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getFlatReductionValue(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getTotalValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>{`${
                            taxation.calculationDetails.dwellingTaxRate * 10
                        } ‰`}</Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.taxValue)}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.black} />
                    <Typography>{`*  Reduksjonsfaktor er ${getReductionFactor(taxation)} %.`}</Typography>
                    <Typography>{`** Bunnfradrag pr. boenhet er ${getFlatReduction(
                        taxation,
                    )} kroner. Det tildeles bunnfradrag basert på antall godkjente boenheter i matrikkelen.`}</Typography>
                </div>
                {footnote}
            </div>
        )
    }
    if (templateType === StaticTemplateType.BO2) {
        return (
            <div>
                <div className={styles.tableContents}>
                    <Typography className={styles.letterHeader1}>Skatteseddel - Bolig- og fritidseiendom</Typography>
                </div>
                <div className={styles.tableContents}>
                    <Divider className={styles.black} />
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eiendom:
                                </Grid>
                                <Grid item xs={8}>
                                    {getFullMatrikkelAddress(property)}
                                </Grid>
                                <Grid item xs={4}>
                                    Eier:
                                </Grid>
                                <Grid item xs={8}>
                                    {taxation.current.owners.map((owner: Owner, index) => (
                                        <div key={index}>{getOwnerDisplayName(owner)}</div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Eiendommens adresse:
                                </Grid>
                                <Grid item xs={6}>
                                    {getHousingUnitsAddresses(taxation.current.housingUnits)}
                                </Grid>

                                <Grid item xs={6}>
                                    Fritak:
                                </Grid>
                                <Grid item xs={6}>
                                    {isExempt ? 'Ja' : 'Nei'}
                                </Grid>

                                <Grid item xs={6}>
                                    Antall boenheter:
                                </Grid>
                                <Grid item xs={6}>
                                    {getResidentialHousingUnitCount(taxation.current)}
                                </Grid>

                                <Grid item xs={6}>
                                    Fakturamottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getBillingReceiver(taxation)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />

                    {!isUsingManualValue(taxation) && (
                        <>
                            <Grid container justifyContent="space-between">
                                {/* left page half */}
                                <Grid item>
                                    <div>Takst</div>
                                    <div>{formatCurrency(taxation.calculationDetails.totalValue)}</div>
                                </Grid>
                                <Grid item>
                                    <div>Sonefaktor</div>
                                    <div>{formatFactor(property.zoneFactor)}</div>
                                </Grid>
                                <Grid item>
                                    <div>Beliggenhetsfaktor</div>
                                    <div>{formatFactor(property.locationFactor)}</div>
                                </Grid>
                                <Grid item>
                                    <div>Takstgrunnlag</div>
                                    <div>{formatCurrency(getTotalValueAfterFactors(taxation))}</div>
                                </Grid>
                            </Grid>
                            <Divider className={styles.divider} />
                        </>
                    )}
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Takstgrunnlag
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getTotalValueAfterFactors(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionSum(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Bunnfradrag per boenhet
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getFlatReductionValue(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getTotalValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>{`${
                            taxation.calculationDetails.dwellingTaxRate * 10
                        } ‰`}</Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.taxValue)}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                </div>
                {footnote}
            </div>
        )
    }
    if (templateType === StaticTemplateType.NE) {
        return (
            <div>
                <div className={styles.tableContents}>
                    <Typography className={styles.letterHeader1}>Skatteseddel - Næringseiendom</Typography>
                </div>

                <div className={styles.tableContents}>
                    <Divider className={styles.black} />
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eiendom:
                                </Grid>
                                <Grid item xs={8}>
                                    {getFullMatrikkelAddress(property)}
                                </Grid>

                                <Grid item xs={4}>
                                    Eierrepresentant:
                                </Grid>
                                <Grid item xs={8}>
                                    {taxation.current.owners.map((owner: Owner, index) => (
                                        <div key={index}>{getOwnerDisplayName(owner)}</div>
                                    ))}
                                </Grid>

                                <Grid item xs={4}>
                                    Fakturamottaker:
                                </Grid>
                                <Grid item xs={8}>
                                    {getBillingReceiver(taxation)}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Eiendommens adresse:
                                </Grid>
                                <Grid item xs={6}>
                                    {getHousingUnitsAddresses(taxation.current.housingUnits)}
                                </Grid>

                                <Grid item xs={6}>
                                    Fritak:
                                </Grid>
                                <Grid item xs={6}>
                                    {isExempt ? 'Ja' : 'Nei'}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />

                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Vedlagt takst
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getTotalValueAfterFactors(taxation))}
                        </Grid>

                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionSum(taxation))}
                        </Grid>

                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getTotalValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {`${taxation.calculationDetails.otherTaxRate * 10} ‰`}
                        </Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.taxValue)}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                </div>
                {footnote}
            </div>
        )
    }
    if (templateType === StaticTemplateType.KO) {
        return (
            <div>
                <Typography className={styles.letterHeader}>Skatteseddel - Kombinert eiendom</Typography>
                <Divider className={styles.black} />
                <div className={styles.tableContents}>
                    {/* NE part */}
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eiendom:
                                </Grid>
                                <Grid item xs={8}>
                                    {getFullMatrikkelAddress(property)}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Eiendommens adresse:
                                </Grid>
                                <Grid item xs={6}>
                                    {getHousingUnitsAddresses(taxation.current.housingUnits)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Typography className={styles.subHeader}>Næring</Typography>
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eierrepresentant:
                                </Grid>
                                <Grid item xs={8}>
                                    {taxation.current.owners.map((owner: Owner, index) => (
                                        <div key={index}>{getOwnerDisplayName(owner)}</div>
                                    ))}
                                </Grid>

                                <Grid item xs={4}>
                                    Fakturamottaker:
                                </Grid>
                                <Grid item xs={8}>
                                    {getBillingReceiver(taxation)}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Fritak:
                                </Grid>
                                <Grid item xs={6}>
                                    {isExempt ? 'Ja' : 'Nei'}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />

                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Vedlagt takst
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getOtherValue(taxation))}
                        </Grid>

                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionFromValue(taxation, getOtherValue(taxation)))}
                        </Grid>

                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getOtherValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {`${taxation.calculationDetails.otherTaxRate * 10} ‰`}
                        </Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.otherTax)}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    {/* BO part */}
                    <Typography className={styles.subHeader}>Bolig</Typography>
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eierrepresentant:
                                </Grid>
                                <Grid item xs={8}>
                                    {taxation.current.owners.map((owner: Owner, index) => (
                                        <div key={index}>{getOwnerDisplayName(owner)}</div>
                                    ))}
                                </Grid>

                                <Grid item xs={4}>
                                    Fakturamottaker:
                                </Grid>
                                <Grid item xs={8}>
                                    {getBillingReceiver(taxation)}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Fritak:
                                </Grid>
                                <Grid item xs={6}>
                                    {isExempt ? 'Ja' : 'Nei'}
                                </Grid>

                                <Grid item xs={6}>
                                    Antall boenheter:
                                </Grid>
                                <Grid item xs={6}>
                                    {getResidentialHousingUnitCount(taxation.current)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />

                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Vedlagt takst
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getResidentialValueAfterFactors(taxation))}
                        </Grid>

                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionFromValue(taxation, getResidentialValueAfterFactors(taxation)))}
                        </Grid>

                        <Grid item xs={6}>
                            Bunnfradrag
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getFlatReductionValue(taxation))}
                        </Grid>

                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getResidentialValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>{`${
                            taxation.calculationDetails.dwellingTaxRate * 10
                        } ‰`}</Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.dwellingTax)}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                </div>
                {footnote}
            </div>
        )
    }
    //with comment
    if (templateType === StaticTemplateType.MA1) {
        return (
            <div>
                <div className={styles.tableContents}>
                    <Typography className={styles.letterHeader}>Skatteseddel {new Date().getFullYear()}</Typography>
                    <Typography>
                        I tabellen under vises begrunnelse for takstgrunnlaget og beregningen av eiendomsskatt for din
                        eiendom i skatteåret {new Date().getFullYear()}. Takstgrunnlaget er godkjent av sakkyndig nemnd
                        i Ringerike kommune.
                    </Typography>
                </div>
                <div className={styles.tableContents}>
                    <Divider className={styles.black} />
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eiendom:
                                </Grid>
                                <Grid item xs={8}>{`${property.municipalityNumber}-${getFullMatrikkelAddress(
                                    property,
                                )}`}</Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Eiendommens adresse:
                                </Grid>
                                <Grid item xs={6}>
                                    {getHousingUnitsAddresses(taxation.current.housingUnits)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />

                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eier:
                                </Grid>
                                <Grid item xs={8}>
                                    {taxation.current.owners.map((owner: Owner, index) => (
                                        <div key={index}>{getOwnerDisplayName(owner)}</div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Dokumentmottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getDocumentReceiver(taxation)}
                                </Grid>
                                <Grid item xs={6}>
                                    Fakturamottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getBillingReceiver(taxation)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />

                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Størrelse:
                                </Grid>
                                <Grid item xs={6}>{`${getPlotSize(property)} m2`}</Grid>
                            </Grid>
                        </Grid>

                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Antall boenheter:
                                </Grid>
                                <Grid item xs={6}>
                                    {getResidentialHousingUnitCount(taxation.current)}
                                </Grid>
                                <Grid item xs={6}>
                                    Fritak:
                                </Grid>
                                <Grid item xs={6}>
                                    {isExempt ? 'Ja' : 'Nei'}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.blackSpaced} />

                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <b>Takstbegrunnelse:</b>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            {getManualValueComment(property)}
                        </Grid>
                    </Grid>

                    <Divider className={styles.blackSpaced} />

                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattegrunnlag
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getTotalValueAfterFactors(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor*
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionSum(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Bunnfradrag**
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getFlatReductionValue(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getTotalValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>{`${
                            taxation.calculationDetails.dwellingTaxRate * 10
                        } ‰`}</Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.taxValue)}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.black} />
                    <Typography>{`*  Reduksjonsfaktor er ${getReductionFactor(taxation)} %.`}</Typography>
                    <Typography>{`** Bunnfradrag pr. boenhet er ${getFlatReduction(
                        taxation,
                    )} kroner. Det tildeles bunnfradrag basert på antall godkjente boenheter i matrikkelen.`}</Typography>
                </div>
                {footnote}
            </div>
        )
    }
    //with two values
    if (templateType === StaticTemplateType.MA2) {
        return (
            <div>
                <div className={styles.tableContents}>
                    <Typography className={styles.letterHeader}>Skatteseddel {new Date().getFullYear()}</Typography>
                    <Typography>
                        I tabellen under vises beregningen av eiendomsskatt for din eiendom i skatteåret {new Date().getFullYear()}. Takstgrunnlaget er basert på eiendomsskattetakst godkjent av sakkyndig nemnd i Ringerike kommune.
                    </Typography>
                </div>
                <div className={styles.tableContents}>
                    <Divider className={styles.black} />
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eiendom:
                                </Grid>
                                <Grid item xs={8}>{`${property.municipalityNumber}-${getFullMatrikkelAddress(
                                    property,
                                )}`}</Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Eiendommens adresse:
                                </Grid>
                                <Grid item xs={6}>
                                    {getHousingUnitsAddresses(taxation.current.housingUnits)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />

                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eier:
                                </Grid>
                                <Grid item xs={8}>
                                    {taxation.current.owners.map((owner: Owner, index) => (
                                        <div key={index}>{getOwnerDisplayName(owner)}</div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Dokumentmottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getDocumentReceiver(taxation)}
                                </Grid>
                                <Grid item xs={6}>
                                    Fakturamottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getBillingReceiver(taxation)}
                                </Grid>
                                <Grid item xs={6}>
                                    Fritak:
                                </Grid>
                                <Grid item xs={6}>
                                    {isExempt ? 'Ja' : 'Nei'}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={styles.blackSpaced} />

                    <Typography>
                        <b>NÆRING</b>
                    </Typography>

                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Takstgrunnlag
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getOtherValue(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor*
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionFromValue(taxation, getOtherValue(taxation)))}
                        </Grid>
                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getOtherValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {`${taxation.calculationDetails.otherTaxRate * 10} ‰`}
                        </Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.otherTax)}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />

                    <Typography>
                        <b>BOLIG</b>
                    </Typography>

                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Takstgrunnlag
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getResidentialValueAfterFactors(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor*
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionFromValue(taxation, getResidentialValueAfterFactors(taxation)))}
                        </Grid>
                        <Grid item xs={6}>
                            Bunnfradrag**
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getFlatReductionValue(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getResidentialValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {`${taxation.calculationDetails.dwellingTaxRate * 10} ‰`}
                        </Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.dwellingTax)}</b>
                        </Grid>
                    </Grid>

                    <Divider className={styles.black} />
                    <Typography>{`*  Reduksjonsfaktor er ${getReductionFactor(taxation)} %.`}</Typography>
                    <Typography>{`** Bunnfradrag pr. boenhet er ${getFlatReduction(
                        taxation,
                    )} kroner. Det tildeles bunnfradrag basert på antall godkjente boenheter i matrikkelen.`}</Typography>
                </div>
                {footnote}
            </div>
        )
    }
    if (templateType === StaticTemplateType.MA3) {
        return (
            <div>
                <div className={styles.tableContents}>
                    <Typography className={styles.letterHeader}>Skatteseddel {new Date().getFullYear()}</Typography>
                    <Typography>
                    I tabellen under vises begrunnelse for takstgrunnlaget og beregningen av eiendomsskatt for din eiendom i skatteåret {new Date().getFullYear()}. Takstgrunnlaget er godkjent av sakkyndig nemnd i Ringerike kommune.
                    </Typography>
                </div>
                <div className={styles.tableContents}>
                    <Divider className={styles.black} />
                    <Grid container>
                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eiendom:
                                </Grid>
                                <Grid item xs={8}>{`${property.municipalityNumber}-${getFullMatrikkelAddress(
                                    property,
                                )}`}</Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Eiendommens adresse:
                                </Grid>
                                <Grid item xs={6}>
                                    {getHousingUnitsAddresses(taxation.current.housingUnits)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />

                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Eier:
                                </Grid>
                                <Grid item xs={8}>
                                    {taxation.current.owners.map((owner: Owner, index) => (
                                        <div key={index}>{getOwnerDisplayName(owner)}</div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Dokumentmottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getDocumentReceiver(taxation)}
                                </Grid>
                                <Grid item xs={6}>
                                    Fakturamottaker:
                                </Grid>
                                <Grid item xs={6}>
                                    {getBillingReceiver(taxation)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />

                        {/* left page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    Fritak:
                                </Grid>
                                <Grid item xs={8}>
                                    {isExempt ? 'Ja' : 'Nei'}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right page half */}
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    Antall boenheter:
                                </Grid>
                                <Grid item xs={6}>
                                    {getResidentialHousingUnitCount(taxation.current)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={styles.divider} />
                    </Grid>

                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattegrunnlag fra skatteetaten
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getTotalValueAfterFactors(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Reduksjon etter reduksjonsfaktor*
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getReductionSum(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            Bunnfradrag**
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            {formatCurrency(getFlatReductionValue(taxation))}
                        </Grid>
                        <Grid item xs={6}>
                            <b>Skattegrunnlag</b>
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(getTotalValueAfterFactorsAndReduction(taxation))}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid container className={styles.spaced}>
                        <Grid item xs={6}>
                            Skattesats
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>{`${
                            taxation.calculationDetails.dwellingTaxRate * 10
                        } ‰`}</Grid>
                        <Grid item xs={6}>
                            Årlig beregnet skatt
                        </Grid>
                        <Grid item xs={6} className={styles.alighnRight}>
                            <b>{formatCurrency(taxation.calculationDetails.taxValue)}</b>
                        </Grid>
                    </Grid>
                    <Divider className={styles.black} />
                    <Typography>{`*  Reduksjonsfaktor er ${getReductionFactor(taxation)} %.`}</Typography>
                    <Typography>{`** Bunnfradrag pr. boenhet er ${getFlatReduction(
                        taxation,
                    )} kroner. Det tildeles bunnfradrag basert på antall godkjente boenheter i matrikkelen.`}</Typography>
                </div>
                {footnote}
            </div>
        )
    }
    // default detailed layout
    return (
        <div>
            <div className={styles.tableContents}>
                <Typography className={styles.letterHeader1}>Detaljert skatteseddel</Typography>
            </div>
            <div className={styles.tableContents}>
                <Divider className={styles.black} />
                <Grid container>
                    {/* left page half */}
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={4}>
                                Eiendom:
                            </Grid>
                            <Grid item xs={8}>{`${property.municipalityNumber}-${getFullMatrikkelAddress(
                                property,
                            )}`}</Grid>
                        </Grid>
                    </Grid>
                    {/* right page half */}
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                Eiendommens adresse:
                            </Grid>
                            <Grid item xs={6}>
                                {getHousingUnitsAddresses(taxation.current.housingUnits)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />

                    {/* left page half */}
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={4}>
                                Eier:
                            </Grid>
                            <Grid item xs={8}>
                                {taxation.current.owners.map((owner: Owner, index) => (
                                    <div key={index}>{getOwnerDisplayName(owner)}</div>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* right page half */}
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                Dokumentmottaker:
                            </Grid>
                            <Grid item xs={6}>
                                {getDocumentReceiver(taxation)}
                            </Grid>
                            <Grid item xs={6}>
                                Fakturamottaker:
                            </Grid>
                            <Grid item xs={6}>
                                {getBillingReceiver(taxation)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />

                    {/* left page half */}
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={4}>
                                Fritak:
                            </Grid>
                            <Grid item xs={8}>
                                {isExempt ? 'Ja' : 'Nei'}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* right page half */}
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                Antall boenheter:
                            </Grid>
                            <Grid item xs={6}>
                                {getResidentialHousingUnitCount(taxation.current)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                </Grid>

                <Grid container>
                    {/* left page half */}
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                Tomtestørrelse:
                            </Grid>
                            <Grid item xs={6}>{`${getPlotSize(property)} m2`}</Grid>
                        </Grid>
                    </Grid>
                    {/* right page half */}
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                Beliggenhetsfaktor:
                            </Grid>
                            <Grid item xs={6}>
                                {formatFactor(property.locationFactor)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={styles.divider} />

                {/* Buildings */}
                {buildings
                    .filter((b) => b.status !== 'Bygningsnummer utgått')
                    .map((building) => (
                        <Grid key={building.id} container>
                            {/* Building details */}

                            {/* left page half */}
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <b>Bygingsnummer:</b>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {building.buildingNumber}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* right page half */}
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <b>Bygningskode:</b>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {building.buildingCode} {building.buildingCodeName}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider className={styles.divider} />

                            {/* left page half */}
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        Matrikkelstatus:
                                    </Grid>
                                    <Grid item xs={6}>
                                        {building.status}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* right page half */}
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        Fritak:
                                    </Grid>
                                    <Grid item xs={6}>
                                        {building.exempt ? 'Ja' : 'Nei'}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider className={styles.divider} />

                            {/* left side */}
                            <Grid item xs={6}></Grid>
                            {/* right page half */}
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        Standardfaktor:
                                    </Grid>
                                    <Grid item xs={6}>
                                        {formatFactor(building.standardFactor)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className={styles.spacer}></div>
                            {/* Floors table */}
                            <Grid item xs={12} className={styles.tableWrapper}>
                                <Table className={styles.customizedTable}>
                                    <CubitTableHeader {...tableHeaderProps} />
                                    <TableBody>
                                        {building.floors
                                            .filter(
                                                (f) => !f.matrikkelUnitId || f.matrikkelUnitId === taxation.propertyId,
                                            )
                                            .map((floor: Floor, index) => (
                                                <tr key={index} className={styles.tableRow}>
                                                    <td className={styles.tableCell}>{floor.floorNumber}</td>
                                                    <td className={styles.tableCell}>{floor.usage}</td>
                                                    <td className={styles.tableCell}>
                                                        {formatFactor(floor.usageFactor)}
                                                    </td>
                                                    <td className={styles.tableCell}>{floor.pricePerM2}</td>
                                                    <td className={styles.tableCell}>{formatArea(floor.area)}</td>
                                                    <td className={`${styles.tableCell} textRight`}>
                                                        {formatCurrency(floor.value)}
                                                    </td>
                                                </tr>
                                            ))}
                                    </TableBody>
                                </Table>
                                <Divider className={styles.dividerSmall} />
                                <Grid item xs={12}>
                                    <Grid container className={styles.padding}>
                                        <Grid item xs={3}>
                                            <b>Verdi etter standardfaktor</b>
                                        </Grid>
                                        <Grid item xs={3}>{`${building.valueBeforeFactors} x ${formatFactor(
                                            building.standardFactor,
                                        )}`}</Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3} className={styles.alighnRight}>
                                            <b>{formatCurrency(building.value)}</b>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider className={styles.dividerBold} />
                            </Grid>
                        </Grid>
                    ))}
            </div>

            {/* Results */}
            <div className={styles.tableContents}>
                {property.otherPropertyFlags?.manualValueComment ? (
                    <p>
                        <b>Kommentar til taksering</b>
                        {': '}
                        {property.otherPropertyFlags.manualValueComment}
                    </p>
                ) : (
                    <></>
                )}
                <Grid container justifyContent="space-between" className={styles.summary}>
                    {/* left page half */}
                    <Grid item>
                        <div>
                            <b>Verdi etter standardfaktor</b>
                        </div>
                        <div>{formatCurrency(taxation.calculationDetails.totalValue)}</div>
                    </Grid>
                    <Grid item>
                        <div>
                            <b>Sonefaktor</b>
                        </div>
                        <div>{formatFactor(property.zoneFactor)}</div>
                    </Grid>
                    <Grid item>
                        <div>
                            <b>Beliggenhetsfaktor</b>
                        </div>
                        <div>{formatFactor(property.locationFactor)}</div>
                    </Grid>
                    <Grid item className={styles.alighnRight}>
                        <div>
                            <b>Takstgrunnlag</b>
                        </div>
                        <div>{formatCurrency(getTotalValueAfterFactors(taxation))}</div>
                    </Grid>
                </Grid>
            </div>
            {footnote}
        </div>
    )
}
