import { AnyAction } from 'redux'
import {
    ACTION_SEARCH,
    ACTION_SEARCH_ALL,
    ACTION_SEARCH_AUTOCOMPLETE_LOADED,
    ACTION_SEARCH_ERROR,
    ACTION_SEARCH_SELECT_TAB,
    ACTION_SEARCH_SUCCESS,
    ACTION_ASSIGN_PROPERTIES,
    ACTION_ASSIGN_PROPERTIES_SUCCESS,
    ACTION_SEARCH_SET_SUGGESTIONS,
    ACTION_SET_SHOW_MAP,
    ACTION_SEARCH_LOAD_MORE,
    ACTION_SEND_NOTICES,
    ACTION_SEND_NOTICES_SUCCESS,
    ACTION_UNASSIGN_PROPERTIES,
    ACTION_UNASSIGN_PROPERTIES_SUCCESS,
    ACTION_SEND_YEAR_LETTERS,
    ACTION_SEND_YEAR_LETTERS_SUCCESS,
} from './search-actions'
import { makeReducer } from 'utils'
import { SuggestionGroup, Suggestions } from 'shared-components/src/search-input/search-input-types'
import { ACTION_CUBIT_TABLE_ORDER } from 'shared-components/src/cubit-table/cubit-table-actions'
import { Reducers } from 'shared-components/src/utils/make-reducer'
import { Property, PropertyStatus } from 'models/property'
import { PropertiesTableName } from './properties-results/properties-table'
import { findIndex } from 'lodash'
import { tr } from 'utils/translations/translate'
import { TEXT_0457, TEXT_0777, TEXT_0781 } from 'utils/translations/keys'

export type SearchState = {
    results: SearchResults
    suggestions: SuggestionGroup[]
    autocomplete: SuggestionGroup[]
    activeTabIndex: number
    showMap: boolean
    loading: boolean
}

export type SearchResults = {
    properties: SearchResult<any>
    housingUnits: SearchResult<any>
    owners: SearchResult<any>
    buildings: SearchResult<any>
}

export type SearchResultKey = keyof SearchResults

export type SearchResult<T> = {
    items: T[]
    total: number
    isLoading: boolean
}

const searchResultOf = <T>(items: T[], total: number = 0): SearchResult<T> => ({
    items,
    total,
    isLoading: false,
})

const emptySearchResults = (): SearchResults => ({
    housingUnits: searchResultOf<any>([]),
    owners: searchResultOf<any>([]),
    properties: searchResultOf<any>([]),
    buildings: searchResultOf<any>([]),
})

type SearchReducer = (state: SearchState, action: AnyAction) => SearchState

const initialState: SearchState = {
    results: emptySearchResults(),
    suggestions: [],
    autocomplete: [],
    activeTabIndex: 0,
    showMap: false,
    loading: false,
}

const search: SearchReducer = (state, action) => {
    const tableName: SearchResultKey = action.tableName
    state.results[tableName].isLoading = true

    return state
}

const orderChanged: SearchReducer = (state, action) => {
    const tableName: SearchResultKey = action.tableName
    state.results[tableName] = searchResultOf<any>([])
    return state
}

const searchAll: SearchReducer = (state, action) => ({
    ...state,
    loading: true,
    results: emptySearchResults(),
})

const searchSuccess: SearchReducer = (state, action) => {
    const tableName: SearchResultKey = action.tableName
    state.results[tableName].items = state.results[tableName].items.concat(action.results.items)
    state.results[tableName].total = action.results.total
    state.results[tableName].isLoading = false
    state.loading = false

    return state
}

const searchError: SearchReducer = (state, action) => {
    const tableName: SearchResultKey = action.tableName
    state.results[tableName].isLoading = false
    state.loading = false
    return state
}

const setSearchSuggestions: SearchReducer = (state, action) => {
    const results: {
        buildingCodes: any[]
        consumptionCodes: any[]
        industrialCodes: any[]
        inspectionAreas: any[]
        plantTypes: any[]
        postalCodes: any[]
    } = action.data
    const industrialCodes = results.industrialCodes.map((x) => ({
        type: 'industrialCodes',
        label: `${x.code} - ${x.name}`,
        value: x.code,
    }))
    const postalCodes = results.postalCodes.map((x) => ({
        type: 'postalCodes',
        label: `${x.code} ${x.city}`,
        value: x.code,
    }))
    const buildingCodes = results.buildingCodes.map((x) => ({
        type: 'buildingCodes',
        label: `${x.code} ${x.description}`,
        value: x.code,
    }))
    const suggestions: Suggestions = [
        {
            type: 'industrialCodes',
            label: tr(TEXT_0457),
            items: industrialCodes,
        },
        {
            type: 'postalCodes',
            label: tr(TEXT_0777),
            items: postalCodes,
        },
        {
            type: 'buildingCodes',
            label: tr(TEXT_0781),
            items: buildingCodes,
        },
    ]
    return { ...state, suggestions }
}

const selectTab: SearchReducer = (state, action) => ({
    ...state,
    activeTabIndex: action.tabIndex,
})

const autocompleteLoaded: SearchReducer = (state, action) => ({
    ...state,
    autocomplete: action.autocomplete,
})
const assignProperties: SearchReducer = (state) => state

const assignPropertiesSuccess: SearchReducer = (state, action) => {
    const assignedProperties: Property[] = action.properties
    const properties: Property[] = state.results[PropertiesTableName].items

    assignedProperties.forEach((p) => {
        const index = findIndex(properties, (i) => i.id === p.id)

        properties[index] = { ...properties[index], state: p.state }
    })

    return state
}

const unassignProperties: SearchReducer = (state) => state

const unassignPropertiesSuccess: SearchReducer = (state, action) => {
    const assignedProperties: string[] = action.ids
    const properties: Property[] = state.results[PropertiesTableName].items

    if (!properties.length) {
        return state
    }

    assignedProperties.forEach((id) => {
        const index = findIndex(properties, (i) => i.id === id)
        // @ts-ignore
        properties[index] = { ...properties[index], state: null }
    })

    return state
}

const setShowMap: SearchReducer = (state, action) => ({
    ...state,
    showMap: action.payload,
})

const loadMore: SearchReducer = (state, action) => {
    state.loading = true

    return state
}

const sendNotices: SearchReducer = (state) => state

const sendNoticesSuccess: SearchReducer = (state, action) => {
    const ids: string[] = action.ids
    const properties: Property[] = state.results[PropertiesTableName].items

    ids.forEach((id) => {
        const index = findIndex(properties, (i) => i.id === id)

        properties[index] = {
            ...properties[index],
            state: {
                propertyId: id,
                value: PropertyStatus.NOTIFIED,
            },
            otherPropertyFlags: {
                ...properties[index].otherPropertyFlags,
                taxNoticeSent: true,
            },
        }
    })

    return state
}

const sendYearLetters: SearchReducer = (state) => state

const sendYearLettersSuccess: SearchReducer = (state, action) => {
    const ids: string[] = action.ids
    const properties: Property[] = state.results[PropertiesTableName].items

    ids.forEach((id) => {
        const index = findIndex(properties, (i) => i.id === id)
        if (!properties[index]) {
            return
        }
        properties[index] = {
            ...properties[index],
            otherPropertyFlags: {
                ...properties[index].otherPropertyFlags,
                yearLetterSent: true,
            },
        }
    })

    return state
}

const reducers: Reducers<SearchState> = {
    [ACTION_SEARCH]: search,
    [ACTION_SET_SHOW_MAP]: setShowMap,
    [ACTION_SEARCH_ALL]: searchAll,
    [ACTION_SEARCH_SUCCESS]: searchSuccess,
    [ACTION_SEARCH_ERROR]: searchError,
    [ACTION_SEARCH_SET_SUGGESTIONS]: setSearchSuggestions,
    [ACTION_CUBIT_TABLE_ORDER]: orderChanged,
    [ACTION_SEARCH_SELECT_TAB]: selectTab,
    [ACTION_SEARCH_AUTOCOMPLETE_LOADED]: autocompleteLoaded,
    [ACTION_ASSIGN_PROPERTIES]: assignProperties,
    [ACTION_ASSIGN_PROPERTIES_SUCCESS]: assignPropertiesSuccess,
    [ACTION_UNASSIGN_PROPERTIES]: unassignProperties,
    [ACTION_UNASSIGN_PROPERTIES_SUCCESS]: unassignPropertiesSuccess,
    [ACTION_SEARCH_LOAD_MORE]: loadMore,
    [ACTION_SEND_NOTICES]: sendNotices,
    [ACTION_SEND_NOTICES_SUCCESS]: sendNoticesSuccess,
    [ACTION_SEND_YEAR_LETTERS]: sendYearLetters,
    [ACTION_SEND_YEAR_LETTERS_SUCCESS]: sendYearLettersSuccess,
}

export const searchReducer = makeReducer(reducers, initialState)
