import React, { useEffect, useState } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { makeStyles, Theme, Paper, Typography, Grid } from '@material-ui/core'
import Navigation from 'common/navigation/navigation'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { TaxSettingsForm } from './tax-settings-form'
import { TaxToolbar } from './tax-settings-toolbar'
import { useDispatch, useSelector } from 'react-redux'
import {
    actionUpdateTaxSettings,
    actionGetTaxSettingsHistory,
    actionTaxSettingsFormReset
} from 'settings-page/settings-actions'
import { AppState } from '../app/app-store'
import { startSubmit, isSubmitting } from 'redux-form'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { getDateString, orderByProperty } from './helper-functions'

type TaxSettingsProps = {}

const useStyles = makeStyles((theme: Theme) => {
    return {
        propertyDataContainer: {
            marginBottom: theme.spacing(4),
        },
        title: {
            fontWeight: 'bold',
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        text: {
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        tableHead: {
            padding: theme.spacing(2, 0, 1, 0),
        },
        tableRow: {
            borderTop: '1px solid #f0f0f0',
            borderBottom: '1px solid #f0f0f0',
            cursor: 'pointer',
            padding: theme.spacing(1, 0, 1, 0),
        },
    }
})

export const TaxSettings: React.FC<TaxSettingsProps> = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [settingsIndex, setSettingsIndex] = useState(-1)
    const [canCancel, setCanCancel] = useState(false)
    const settingsState = useSelector((state: AppState) => state.taxSettings.history)
    const readOnly: boolean = settingsIndex === 0 ? false : true
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)
    const settingsHistory = settingsState ? [...settingsState].sort(orderByProperty(TaxFormFieldNames.settingsEffectDate)) : []
    const settingsDate: string =
        settingsIndex !== -1
            ? getDateString(settingsHistory[settingsIndex][TaxFormFieldNames.settingsEffectDate], language)
            : ''
    const submitting: boolean = useSelector((state: AppState) => isSubmitting(TaxFormName.TaxationSettings)(state))
    const taxFormReset: boolean = useSelector((state: AppState) => state.taxSettings.taxFormReset)

    const handleSubmit = (values: any) => {
        if (submitting) {
            dispatch(actionUpdateTaxSettings(values))
        } else {
            dispatch(startSubmit(TaxFormName.TaxationSettings))
        }
    }

    useEffect(() => {
        if (!settingsState) {
            dispatch(actionGetTaxSettingsHistory())
        }
    }, [dispatch, settingsState])

    useEffect(() => {
        if (taxFormReset) {
            setCanCancel(false)
            dispatch(actionTaxSettingsFormReset(false))
        }
    }, [dispatch, taxFormReset])

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={
                <TaxToolbar 
                    title={`${tr(t.TEXT_TAX_SETTINGS)} ${settingsDate}`} 
                    hideActions={settingsIndex !== 0}
                    canCancel={canCancel}
                />
            }
        >
            <Paper elevation={0}>
                {settingsIndex !== -1 && (
                    <TaxSettingsForm
                        onSubmit={handleSubmit}
                        initialValues={{
                            ...settingsHistory[settingsIndex],
                            settingsChangeComment: '',
                            settingsEffectDate: null
                        }}
                        readOnly={readOnly}
                        canCancel={setCanCancel}
                    />
                )}
                {settingsIndex === -1 && (
                    <>
                        <Grid container className={classes.tableHead}>
                            <Grid item xs={2}>
                                <Typography variant="body1" className={classes.title}>
                                    {tr(t.TEXT_VALID_FROM)}
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body1" className={classes.title}>
                                    {tr(t.TEXT_SETTINGS_CHANGE_COMMENT)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {settingsHistory &&
                            settingsHistory.map((settings: any, index: number) => (
                                <Grid
                                    container
                                    key={index}
                                    className={classes.tableRow}
                                    onClick={() => setSettingsIndex(index)}
                                >
                                    <Grid item sm={2}>
                                        <Typography variant="body1" className={classes.text}>
                                            {getDateString(settings[TaxFormFieldNames.settingsEffectDate], language)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={10}>
                                        <Typography variant="body1" className={classes.text}>
                                            {settings[TaxFormFieldNames.settingsChangeComment]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                    </>
                )}
            </Paper>
        </LayoutDefault>
    )
}
