export const ACTION_NAVIGATION_TOGGLE = 'ACTION_NAVIGATION_TOGGLE'
export const ACTION_MOBILE_NAVIGATION_TOGGLE = 'ACTION__MOBILE_NAVIGATION_TOGGLE'
export const ACTION_PRINT_PAGE = 'ACTION_PRINT_PAGE'
export const actionNavigationToggle = (expanded: boolean) => ({
    type: ACTION_NAVIGATION_TOGGLE,
    expanded,
})

export const actionMobileNavigationToggle = (expanded: boolean) => ({
    type: ACTION_MOBILE_NAVIGATION_TOGGLE,
    expanded,
})

export const actionPrintPage = (isPrint: boolean) => ({
    type: ACTION_PRINT_PAGE,
    isPrint,
})