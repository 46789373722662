import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { useDispatch } from 'react-redux'
import { actionSettingsGroupsGet } from 'shared-components/src/settings/groups/groups-actions'
import { RouteComponentProps } from '@reach/router'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { useCompanies } from 'app/app-selectors'
import { SettingsCompanyDetails } from './settings-company-details'
import { find } from 'lodash'
import { Grid } from '@material-ui/core'
import { SettingsCompanyEdit } from './settings-company-edit'

export const SettingsCompanyPage: React.FC<RouteComponentProps<{ id: string }>> = props => {
    const { id } = props
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actionSettingsGroupsGet())
    }, [dispatch])

    const company = find(useCompanies(), { id })

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={
                <TitleToolbar title={company ? company.name : ''}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>{company && <SettingsCompanyEdit company={company}></SettingsCompanyEdit>}</Grid>
                    </Grid>
                </TitleToolbar>
            }
        >
            {company && <SettingsCompanyDetails company={company}></SettingsCompanyDetails>}
        </LayoutDefault>
    )
}
