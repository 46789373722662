import React from 'react'
import { Link, LinkProps } from '@reach/router'
import List from '@material-ui/core/List'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PersonIcon from '@material-ui/icons/Person'
import SearchIcon from '@material-ui/icons/Search'
import DashboardIcon from '@material-ui/icons/Dashboard'
import CalendarToday from '@material-ui/icons/CalendarToday'
import SettingsIcon from '@material-ui/icons/Settings'
import ListItemText from '@material-ui/core/ListItemText'

import { makeStyles, Theme } from '@material-ui/core/styles'

import classNames from 'classnames'
import { tr } from 'utils/translations/translate'
import {
    TEXT_DASHBOARD,
    TEXT_LANGUAGE,
    TEXT_LOGOUT,
    TEXT_SEARCH,
    TEXT_SETTINGS,
    TEXT_CALENDAR,
    TEXT_USER,
    TEXT_REPORTS,
    TEXT_BILLING,
    TEXT_CASE_FLOW,
} from 'utils/translations/keys'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import config from 'config'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { actionSettingsSetLanguage } from 'shared-components/src/settings/language/language-actions'
import { Badge, Button, Typography } from '@material-ui/core'
import { actionAuthLogout } from '../../auth/auth-actions'
import LabelImportantIcon from '@material-ui/icons/LabelImportant'
import { AppState } from 'app/app-store'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { BarChart, Receipt } from '@material-ui/icons'
import { CubitCalendarEvent } from 'shared-components/src/models/calendar-event'
import { CubitCalendarExtendedProps } from 'models/calendar-event'
import { TenantSwitch } from './tenant-switch'

interface AdapterLinkProps extends LinkProps<{}> {
    active: string
}
type StylesProps = {
    padding?: number
}
type NavProps = {
    padding?: number
}

const useStyles = makeStyles((theme: Theme) => ({
    navigation: (props: StylesProps) => ({
        height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        flexFlow: 'column nowrap',
        overflow: 'hidden',
        paddingTop: props.padding ? props.padding : 0
    }),
    navigationLinks: {
        flex: 'auto',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    navigationItem: {
        padding: `${theme.spacing(1)}px  ${theme.spacing(3)}px`,
    },
    activeLink: {
        borderRight: `4px solid ${theme.palette.primary.main}`,

        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main,
        },
    },
    navigationIcon: {
        minWidth: '2rem',
    },
    navigationText: {
        opacity: 1,
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    navigationTextHidden: {
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    userProfileItem: {
        padding: theme.spacing(1),
    },
    languageLabel: {
        paddingRight: theme.spacing(1),
    },
    eventsNotification: {

    }
}))

export const AdapterExactLink = React.forwardRef<HTMLAnchorElement, AdapterLinkProps>((props, ref) => (
    <Link
        ref={ref as any}
        {...props}
        getProps={({ isCurrent }) =>
            isCurrent
                ? {
                    className: `${props.className} ${props.active} Mui-selected`,
                }
                : {}
        }
    />
))
const AdapterPartialLink = React.forwardRef<HTMLAnchorElement, AdapterLinkProps>((props, ref) => (
    <Link
        ref={ref as any}
        {...props}
        getProps={({ isPartiallyCurrent }) =>
            isPartiallyCurrent
                ? {
                    className: `${props.className} ${props.active} Mui-selected`,
                }
                : {}
        }
    />
))
const AdapterExternalLink = React.forwardRef<HTMLAnchorElement, AdapterLinkProps>((props, ref) => (
    <a
        ref={ref as any}
        href={props.to}
        {...props}
    >
        {props.children}
    </a>
))

const Navigation: React.FC<NavProps> = props => {
    const { padding } = props
    const styles = useStyles({ padding })

    const dispatch = useDispatch()
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    const menuExpanded = useSelector((state: { navigation: any }) => state.navigation.menuExpanded)
    const mobileMenuExpanded = useSelector((state: { navigation: any }) => state.navigation.mobileMenuExpanded)
    const expanded = menuExpanded || mobileMenuExpanded

    const user = useSelector((state: AppState) => state.auth.user)
    const tenant = useSelector((state: AppState) => state.auth.tenant)
    const sessionKey = useSelector((state: AppState) => state.auth.sessionKey)
    const events = useSelector((state: AppState) => state.planning.events)
    const calendarCounter = events.length ? events.reduce((acc: number, e: CubitCalendarEvent<CubitCalendarExtendedProps>) => !e.start ? acc + 1 : acc, 0) : 0

    const [userProfileMenuAnchorEl, setUserProfileMenuAnchorEl] = React.useState(null)

    const userProfileButtonClickHandler = (event: any) => {
        setUserProfileMenuAnchorEl(event.currentTarget)
    }

    const settingsMenuCloseHandler = () => {
        setUserProfileMenuAnchorEl(null)
    }

    const languageChangeHandler = (event: any, language: Language) => {
        if (!language) {
            return
        }
        dispatch(actionSettingsSetLanguage(language))

        // TODO use this in settings epic?
        localStorage.setItem(config.localStorageLangKey, language)

        settingsMenuCloseHandler()
    }

    const logout = () => dispatch(actionAuthLogout())

    const getBillingRoute = () => {
        const isProduction = tenant?.isProduction
        if (isProduction) {
            return `https://faktura.cubit.no/sso-login/${sessionKey}`
        }
        return `https://cubit-billing-v2-develop.azurewebsites.net/sso-login/${sessionKey}`
    }

    return (
        <div className={styles.navigation}>
            <div className={styles.navigationLinks}>
                <List disablePadding>
                    <Protected p={Permission.PageDashboard}>
                        <ListItem
                            button
                            component={AdapterExactLink}
                            to="/"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_DASHBOARD)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>

                    <Protected p={Permission.PageSearch}>
                        <ListItem
                            button
                            component={AdapterPartialLink}
                            to="/search"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_SEARCH)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>

                    <Protected p={Permission.PagePlanning}>
                        <ListItem
                            button
                            component={AdapterPartialLink}
                            to="/calendar"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <Badge badgeContent={calendarCounter} color="error">
                                    <CalendarToday />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_CALENDAR)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>

                    <Protected p={Permission.PageTribunal}>
                        <ListItem
                            button
                            component={AdapterPartialLink}
                            to="/tribunal"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <LabelImportantIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_CASE_FLOW)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>

                    <Protected p={Permission.Reports}>
                        <ListItem
                            button
                            component={AdapterExternalLink}
                            to="https://cubit-tax-simulation-v2.azurewebsites.net/"
                            target="_blank"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <BarChart />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_REPORTS)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>
                    <Protected p={Permission.Billing}>
                        <ListItem
                            button
                            component={AdapterExternalLink}
                            to={getBillingRoute()}
                            target="_blank"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <Receipt />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_BILLING)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>
                </List>
            </div>

            <Divider />

            <List disablePadding>
                <ListItem button className={styles.navigationItem} onClick={userProfileButtonClickHandler}>
                    <ListItemIcon className={styles.navigationIcon}>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={tr(TEXT_USER)}
                        className={classNames(styles.navigationText, {
                            [styles.navigationTextHidden]: !expanded,
                        })}
                    />
                </ListItem>

                <Menu
                    id="simple-menu"
                    anchorEl={userProfileMenuAnchorEl}
                    keepMounted
                    open={Boolean(userProfileMenuAnchorEl)}
                    onClose={settingsMenuCloseHandler}
                    MenuListProps={{ disablePadding: true }}
                >
                    <Grid container className={styles.userProfileItem} justifyContent="center">
                        <Grid item>
                            <Typography variant="subtitle1">{user && user.name}</Typography>
                        </Grid>

                    </Grid>
                    {user?.godMode && <Grid container justifyContent="center">
                        <Grid item>
                            <Typography variant="body2">{tenant?.name ?? 'N/A'}</Typography>
                        </Grid>
                    </Grid>}
                    <Divider></Divider>

                    {user?.godMode && <TenantSwitch />}

                    <Grid container className={styles.userProfileItem} alignItems="center" justifyContent="center">
                        <Grid item className={styles.languageLabel}>
                            {tr(TEXT_LANGUAGE)}:
                        </Grid>
                        <Grid item>
                            <ToggleButtonGroup size="small" value={language} exclusive onChange={languageChangeHandler}>
                                <ToggleButton key={1} value={Language.English}>
                                    EN
                                </ToggleButton>
                                <ToggleButton key={0} value={Language.Norwegian}>
                                    NO
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>

                    <Divider></Divider>

                    <Grid container className={styles.userProfileItem}>
                        <Grid item xs={12}>
                            <Button fullWidth={true} onClick={logout}>
                                {tr(TEXT_LOGOUT)}
                            </Button>
                        </Grid>
                    </Grid>
                </Menu>

                <Protected p={Permission.PageSettings}>
                    <ListItem
                        button
                        component={AdapterPartialLink}
                        to="/settings"
                        className={styles.navigationItem}
                        active={styles.activeLink}
                    >
                        <ListItemIcon className={styles.navigationIcon}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={tr(TEXT_SETTINGS)}
                            className={classNames(styles.navigationText, {
                                [styles.navigationTextHidden]: !expanded,
                            })}
                        />
                    </ListItem>
                </Protected>
            </List>
        </div>
    )
}

export default Navigation
