import { HasId } from './has-id'
import { Address } from './address'

export type AddOwner = {
    ownerId?: string
    title: string
    number: string
    name: string
    addressText: string
    postalCode: string
    email: string
    phone: string
    isDocumentRecipient: boolean
    isInvoiceRecipient: boolean
}

export type BaseOwner = {
    id: string
    number: string
    email: string
    phone: string
    status: string
    acceptElectronicCommunication: boolean
} & HasId

export type PrivateOwner = {
    kind: 'privateOwner'
    firstName: string
    lastName: string
    residentialAddress: Address
    postalAddress: Address
    birthDate?: Date
} & BaseOwner

export type CompanyOwner = {
    kind: 'companyOwner'
    name: string
    businessAddress: Address
    postalAddress: Address
    organizationNumber: string
} & BaseOwner

export type Owner = PrivateOwner | CompanyOwner

export const getOwnerDisplayName = (owner: any): any => {
    if (owner.firstName) {
        return owner.firstName + ' ' + (owner.lastName || '')
    } else {
        return owner.name
    }
    // switch (owner.kind) {
    //     case 'privateOwner':
    //         return `${owner.firstName} ${owner.lastName}`
    //     case 'companyOwner':
    //         return owner.name
    // }
}

export const getOwnerAddress = (owner: any): any => {
    if (owner.residentialAddress) {
        return owner.residentialAddress
    } else {
        return owner.businessAddress
    }
    // switch (owner.kind) {
    //     case 'privateOwner':
    //         return owner.residentialAddress
    //     case 'companyOwner':
    //         return owner.businessAddress
    // }
}

export const makePropertyOwnership = (owner: Owner) => {
    return {
        ownerId: owner.id,
        ownerType: 'custom owner',
        isDocumentRecipient: false,
        isInvoiceRecipient: false,
    }
}

export enum OwnershipType {
    MANUAL = 'Manuell'
}