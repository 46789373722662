import { Grid } from '@material-ui/core'
import { PictureAsPdfOutlined } from '@material-ui/icons'
import React from 'react'
import { TEXT_DOCUMENTATION } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

type ComplaintFilesProps = {
    cause: any
    styles: any
}

export const ComplaintFiles: React.FC<ComplaintFilesProps> = (props) => {
    const { cause, styles } = props

    return cause.files.length > 0 ? (
        <Grid container spacing={1} className={styles.filesContainer}>
            <Grid item xs={12} className={styles.panelContent__label}>
                {tr(TEXT_DOCUMENTATION)}:
            </Grid>
            {cause.files.map((file: any, index: number) => (
                <Grid item xs={12} key={index}>
                    <a className={styles.fileBox} href={file.url} target="_blank" rel="noopener noreferrer">
                        <Grid item xs={12} container alignItems="center">
                            <PictureAsPdfOutlined className={styles.icon} />
                            <Grid className={styles.fileName}>{file.fileName}</Grid>
                        </Grid>
                    </a>
                </Grid>
            ))}
        </Grid>
    ) : (
        <></>
    )
}
