import { AnyAction } from 'redux'
import { getParsedTemplate, getStringifiedTemplate } from './helper'

export const ACTION_GET_REPORTS_SETTINGS = 'ACTION_GET_REPORTS_SETTINGS'
export const ACTION_GET_REPORTS_SETTINGS_SUCCESS = 'ACTION_GET_REPORTS_SETTINGS_SUCCESS'
export const ACTION_UPDATE_REPORTS_SETTINGS = 'ACTION_UPDATE_REPORTS_SETTINGS'
export const ACTION_UPDATE_REPORTS_SETTINGS_SUCCESS = 'ACTION_UPDATE_REPORTS_SETTINGS_SUCCESS'
export const ACTION_GET_TAX_SETTINGS = 'ACTION_GET_TAX_SETTINGS'
export const ACTION_GET_TAX_SETTINGS_SUCCESS = 'ACTION_GET_TAX_SETTINGS_SUCCESS'
export const ACTION_GET_TAX_SETTINGS_HISTORY = 'ACTION_GET_TAX_SETTINGS_HISTORY'
export const ACTION_GET_TAX_SETTINGS_HISTORY_SUCCESS = 'ACTION_GET_TAX_SETTINGS_HISTORY_SUCCESS'
export const ACTION_UPDATE_TAX_SETTINGS = 'ACTION_UPDATE_TAX_SETTINGS'
export const ACTION_UPDATE_TAX_SETTINGS_SUCCESS = 'ACTION_UPDATE_TAX_SETTINGS_SUCCESS'
export const ACTION_TAX_SETTINGS_FORM_RESET = 'ACTION_TAX_SETTINGS_FORM_RESET'
export const ACTION_CLOSE_YEAR_SETTINGS = 'ACTION_CLOSE_YEAR_SETTINGS'
export const ACTION_GET_TEMPLATES = 'ACTION_GET_TEMPLATES'
export const ACTION_GET_TEMPLATES_BY_TYPE = 'ACTION_GET_TEMPLATES_BY_TYPE'
export const ACTION_GET_TEMPLATES_BY_TYPE_SUCCESS = 'ACTION_GET_TEMPLATES_BY_TYPE_SUCCESS'
export const ACTION_GET_TEMPLATE = 'ACTION_GET_TEMPLATE'
export const ACTION_CREATE_TEMPLATE = 'ACTION_CREATE_TEMPLATE'
export const ACTION_UPDATE_TEMPLATE = 'ACTION_UPDATE_TEMPLATE'
export const ACTION_CREATE_TEMPLATE_SUCCESS = 'ACTION_CREATE_TEMPLATE_SUCCESS'
export const ACTION_UPDATE_TEMPLATE_SUCCESS = 'ACTION_UPDATE_TEMPLATE_SUCCESS'
export const ACTION_GET_TEMPLATE_SUCCESS = 'ACTION_GET_TEMPLATE_SUCCESS'
export const ACTION_GET_TEMPLATES_SUCCESS = 'ACTION_GET_TEMPLATES_SUCCESS'
export const ACTION_NAVIGATE_TO_TEMPLATE = 'ACTION_NAVIGATE_TO_TEMPLATE'
export const ACTION_UPLOAD_TEMPLATE_IMAGE = 'ACTION_UPLOAD_TEMPLATE_IMAGE'
export const ACTION_DELETE_TEMPLATE = 'ACTION_DELETE_TEMPLATE'
export const ACTION_DELETE_TEMPLATE_SUCCESS = 'ACTION_DELETE_TEMPLATE_SUCCESS'
export const ACTION_GET_CASE_FLOW_ENTRIES = 'ACTION_GET_CASE_FLOW_ENTRIES'
export const ACTION_GET_CASE_FLOW_ENTRIES_SUCCESS = 'ACTION_GET_CASE_FLOW_ENTRIES_SUCCESS'
export const ACTION_UPDATE_CASE_FLOW_ENTRIES = 'ACTION_UPDATE_CASE_FLOW_ENTRIES'
export const ACTION_UPDATE_CASE_FLOW_ENTRIES_SUCCESS = 'ACTION_UPDATE_CASE_FLOW_ENTRIES_SUCCESS'
export const ACTION_UPLOAD_CSV_FILE = 'ACTION_UPLOAD_CSV_FILE'
export const ACTION_UPLOAD_CSV_FILE_SUCCESS = 'ACTION_UPLOAD_CSV_FILE_SUCCESS'
export const ACTION_UPLOAD_CSV_FILE_FAILED = 'ACTION_UPLOAD_CSV_FILE_FAILED'
export const ACTION_SET_ACTIVE_PANEL = 'ACTION_SET_ACTIVE_PANEL'
export const ACTION_CALCULATE_YEAR_TAX = 'ACTION_CALCULATE_YEAR_TAX'
export const ACTION_CALCULATE_YEAR_TAX_SUCCESS = 'ACTION_CALCULATE_YEAR_TAX_SUCCESS'

export const actionGetYearSettings = (): AnyAction => {
    return {
        type: ACTION_GET_REPORTS_SETTINGS,
    }
}

export const actionGetReportsSuccess = (settings: any): AnyAction => {
    return {
        type: ACTION_GET_REPORTS_SETTINGS_SUCCESS,
        settings,
    }
}

export const actionUpdateYearSettings = (settings: any): AnyAction => {
    return {
        type: ACTION_UPDATE_REPORTS_SETTINGS,
        settings,
    }
}

export const actionUpdateYearSettingsSuccess = (settings: any): AnyAction => {
    return {
        type: ACTION_UPDATE_REPORTS_SETTINGS_SUCCESS,
        settings,
    }
}

export const actionCloseYearSettings = (): AnyAction => {
    return {
        type: ACTION_CLOSE_YEAR_SETTINGS,
    }
}

export const actionGetTaxSettings = (): AnyAction => {
    return {
        type: ACTION_GET_TAX_SETTINGS,
    }
}

export const actionGetTaxSuccess = (settings: any): AnyAction => {
    return {
        type: ACTION_GET_TAX_SETTINGS_SUCCESS,
        settings,
    }
}

export const actionGetTaxSettingsHistory = (): AnyAction => {
    return {
        type: ACTION_GET_TAX_SETTINGS_HISTORY,
    }
}

export const actionGetTaxSettingsHistorySuccess = (settings: any): AnyAction => {
    return {
        type: ACTION_GET_TAX_SETTINGS_HISTORY_SUCCESS,
        settings,
    }
}

export const actionUpdateTaxSettings = (settings: any): AnyAction => {
    return {
        type: ACTION_UPDATE_TAX_SETTINGS,
        settings,
    }
}

export const actionUpdateTaxSettingsSuccess = (settings: any): AnyAction => {
    return {
        type: ACTION_UPDATE_TAX_SETTINGS_SUCCESS,
        settings,
    }
}

export const actionTaxSettingsFormReset = (reset: boolean): AnyAction => {
    return {
        type: ACTION_TAX_SETTINGS_FORM_RESET,
        reset: reset
    }
}

export const actionGetTemplates = (): AnyAction => {
    return {
        type: ACTION_GET_TEMPLATES,
    }
}

export const actionGetTemplatesByType = (documentType: string): AnyAction => {
    return {
        type: ACTION_GET_TEMPLATES_BY_TYPE,
        documentType: documentType
    }
}

export const actionGetTemplatesByTypeSuccess = (templates: any, documentType: string): AnyAction => {
    return {
        type: ACTION_GET_TEMPLATES_BY_TYPE_SUCCESS,
        templates: templates.map((t: any) => getParsedTemplate(t)),
        documentType: documentType
    }
}

export const actionGetTemplatesSuccess = (templates: any): AnyAction => {
    return {
        type: ACTION_GET_TEMPLATES_SUCCESS,
        templates: templates.map((t: any) => getParsedTemplate(t))
    }
}

export const actionGetTemplate = (id: string): AnyAction => {
    return {
        type: ACTION_GET_TEMPLATE,
        id: id
    }
}

export const actionGetTemplateSuccess = (template: any): AnyAction => {
    return {
        type: ACTION_GET_TEMPLATE_SUCCESS,
        template: getParsedTemplate(template)
    }
}

export const actionCreateTemplate = (template: any): AnyAction => {
    return {
        type: ACTION_CREATE_TEMPLATE,
        template: getStringifiedTemplate(template)
    }
}

export const actionUpdateTemplate = (template: any): AnyAction => {
   return {
        type: ACTION_UPDATE_TEMPLATE,
        template: getStringifiedTemplate(template)
    }
}

export const actionUpdateTemplateSuccess = (template: any): AnyAction => {
    return {
        type: ACTION_UPDATE_TEMPLATE_SUCCESS,
        template: getParsedTemplate(template)
    }
}

export const actionCreateTemplateSuccess = (template: any): AnyAction => {
    return {
        type: ACTION_CREATE_TEMPLATE_SUCCESS,
        template: getParsedTemplate(template)
    }
}

export const actionNavigateToTemplate = (template: any): AnyAction => {
    return {
        type: ACTION_NAVIGATE_TO_TEMPLATE,
        template: getParsedTemplate(template)
    }
}

export const actionUploadTemplateImage = (file: any): AnyAction => {
    return {
        type: ACTION_UPLOAD_TEMPLATE_IMAGE,
        file: file
    }
}

export const actionDeleteTemplate = (template: any): AnyAction => {
    return {
        type: ACTION_DELETE_TEMPLATE,
        template: template
    }
}

export const actionDeleteTemplateSuccess = (template: any): AnyAction => {
    return {
        type: ACTION_DELETE_TEMPLATE_SUCCESS,
        template: template
    }
}

export const actionGetCaseFlowEntries = (): AnyAction => {
    return {
        type: ACTION_GET_CASE_FLOW_ENTRIES,
    }
}

export const actionGetCaseFlowEntriesSuccess = (entries: any): AnyAction => {
    return {
        type: ACTION_GET_CASE_FLOW_ENTRIES_SUCCESS,
        entries: entries
    }
}
export const actionUpdateCaseFlowEntries = (entry: any): AnyAction => {
    return {
        type: ACTION_UPDATE_CASE_FLOW_ENTRIES,
        entry: entry
    }
}

export const actionUpdateCaseFlowEntriesSuccess = (entry: any): AnyAction => {
    return {
        type: ACTION_UPDATE_CASE_FLOW_ENTRIES_SUCCESS,
        entry: entry
    }
}

export const actionUploadCsvFile = (data: any): AnyAction => ({
    type: ACTION_UPLOAD_CSV_FILE,
    data,
})

export const actionUploadCsvFileSuccess = (entry: any): AnyAction => {
    return {
        type: ACTION_UPLOAD_CSV_FILE_SUCCESS,
        entry: entry
    }
}

export const actionUploadCsvFileFailed = (): AnyAction => {
    return {
        type: ACTION_UPLOAD_CSV_FILE_FAILED,
    }
}

export const actionSetActivePanel = (panel: string): AnyAction => {
    return {
        type: ACTION_SET_ACTIVE_PANEL,
        panel,
    }
}

export const actionCalculateYearTax = (): AnyAction => {
    return {
        type: ACTION_CALCULATE_YEAR_TAX,
    }
}

export const actionCalculateYearTaxSuccess = (): AnyAction => {
    return {
        type: ACTION_CALCULATE_YEAR_TAX_SUCCESS,
    }
}
