export enum BuildingStatus {
    applied = 'Tatt i bruk',
    completion = 'Ferdigattest',
    temporaryUsePermit = 'Midlertidig brukstillatelse',
    permissionToStart = 'Igangsettingstillatelse',
    buildingNumberExpired = 'Bygningsnummer utgått',
    notificationActionCompleted = 'Meldingssak tiltak fullført',
    buildingDemolished = 'Bygning revet/brent',
    notificationCaseMeasures = 'Meldingssak registrer tiltak',
    buildingCanceled = 'Bygging avlyst',
    buildingApprovedForDemolition = 'Bygning godkjent for riving/brenning',
    outlinePlanningPermission = 'Rammetillatelse',
    exemptToApply = 'Fritatt for søknadsplikt',
    buildingMoved = 'Bygning flyttet',
    measuresExempt = 'Tiltak unntatt fra byggesaksbehandling',
}

export enum ExemptionByReason {
    farmAndForestry = '§5h Gårdsbruk og skogbruk',
    building = '§7c bygg',
    historicValue = '§7b Bygn. med hist. verdi',
    municipalProperty = '§5d Kommunal eiend.',
    stateProperty = '§5a Statlig eiend.',
    institutions = '§7a Stiftelser og institusjoner',
    healthInstitutions = '§5g Helseforetak',
    fiveH = '§5h',
    churches = '§5c Kirker',
    buildingPermit = 'Byggetillatelse?',
    railwayProperties = '§5b Jernbaneeiendommer',
    sevenD = '§ 7d',
    section28 = '§ 28-fritak',
    fiveJ = '§5j',
    fiveK = '§5k',
    decision = 'Vedtak',
}
