import React from 'react'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { Grid, Button, CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { useSelector, useDispatch } from 'react-redux'
import { isDirty, isSubmitting, reset, submit } from 'redux-form'
import { TaxFormName } from 'common/enums/form-name.enum'
import { actionCloseYearSettings } from 'settings-page/settings-actions'
import { AppState } from 'app/app-store'

const useStyles = makeStyles(() => ({
    spinnerIcon: {
        height: '24px !important',
        width: '24px !important',
        color: '#fff !important',
    },
}))

type ReportsToolbarProps = {
    title: string
    hideActions: boolean
}
export const ReportsToolbar: React.FC<ReportsToolbarProps> = (props) => {
    const { title, hideActions } = props
    const styles = useStyles()
    const dirty: boolean = useSelector((state: AppState) => isDirty(TaxFormName.YearSettings)(state))
    const submitting: boolean = useSelector((state: AppState) => isSubmitting(TaxFormName.YearSettings)(state))
    const working = useSelector((state: AppState) => state.taxSettings.uploading)

    const dispatch = useDispatch()
    const closeYear = () => {
        //close the current tax year: add a flag that it's closed, disable all the fields and add the next year array
        dispatch(actionCloseYearSettings())
        //timeout for redux state to update
        setTimeout(() => {
            dispatch(submit(TaxFormName.YearSettings))
        }, 0)
    }

    return (
        <TitleToolbar title={title}>
            {!hideActions &&
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button
                            color="inherit"
                            type="button"
                            onClick={() => dispatch(reset(TaxFormName.YearSettings))}
                            disabled={!dirty || submitting}
                        >
                            {tr(t.TEXT_CANCEL)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="inherit"
                            type="button"
                            onClick={() => {
                                dispatch(submit(TaxFormName.YearSettings))
                            }}
                            disabled={!dirty || submitting}
                        >
                            {tr(t.TEXT_SAVE_CHANGES)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color={working ? undefined : 'inherit'}
                            type="button"
                            onClick={closeYear}
                            disabled={submitting || working}
                        >
                            {working ? <CircularProgress className={styles.spinnerIcon} /> : tr(t.TEXT_COMPLETE_TAX_YEAR)}
                        </Button>
                    </Grid>
                </Grid>
            }
        </TitleToolbar>
    )
}
