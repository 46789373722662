import React, { useEffect } from 'react'
import { Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import { reduxForm, Field, InjectedFormProps, WrappedFieldArrayProps, FieldArray, formValueSelector } from 'redux-form'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { DocumentTemplate } from 'models/document-template'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useState } from 'react'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { templateOptions } from './field-options'
import classNames from 'classnames'
import { dummyData } from './dummyData'
import DocumentTemplateComponent from './document-template'
import { Editor } from 'draft-js'
import { editorStyleMap, textFromTextTemplates } from './template-document-helper'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { navigate } from '@reach/router'
import { RouteNames } from 'common/enums/routeNames'

const useStyles = makeStyles((theme: Theme) => {
    return {
        button: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        header: {
            fontWeight: 600,
        },
        settingsLabel: {
            fontWeight: 600,
            fontSize: 17,
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
        },
        sidebar: {
            marginLeft: 20,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginBottom: 20,
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: 50,
            },
        },
        paperContainer: {
            padding: theme.spacing(2, 2, 1, 2),
        },
        textTemplate: {
            cursor: 'pointer',
            margin: theme.spacing(1, 0, 1, 0),
            padding: theme.spacing(1),
            backgroundColor: '#ffffff',
            borderRadius: 3,
        },
        selectedTemplate: {
            backgroundColor: '#f0f0f0',
        },
        editContainer: {
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse'
            },
        },
        midInput: {
            margin: theme.spacing(2, 0)
        },
        lastInput: {
            marginBottom: theme.spacing(2)
        },
        dropContainer: {
            minHeight: theme.spacing(5),
        },
        previewPaper: {
            padding: 30,
            maxWidth: 828,
        },
        docPreview :{
            marginTop: 20,
        },
        previewContainer: {
            maxWidth: 843,
            maxHeight: 'calc(100vh - 112px)',
            overflowY: 'auto',
            ' &::-webkit-scrollbar': {
                width: 8
            },
            ' &::-webkit-scrollbar-track': {
                backgroundColor: '#d8d8d8'
            },
            ' &::-webkit-scrollbar-thumb': {
                backgroundColor: '#898989',
                border: '2px solid #d8d8d8'
            },
            ' &::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#575757'
            },
        }
    }
})

type TemplatesProps = {
    textTemplates: DocumentTemplate[]
    classes: any
    onDragEnd: any
    pdfTemplates: any
    currentTemplate: DocumentTemplate | null
    currentTextTemplates: DocumentTemplate[]
}
const SelectedTextTemplatesList: React.FC<WrappedFieldArrayProps & TemplatesProps> = (props) => {
    const { fields, textTemplates, classes, onDragEnd, pdfTemplates, currentTemplate, currentTextTemplates } = props
    const previewTextTemplates = textFromTextTemplates(currentTextTemplates, dummyData, dummyData.taxation)
    return (
        <Grid container justifyContent="space-between" className={classes.editContainer}>
            <DragDropContext onDragEnd={(result: any) => onDragEnd(result, fields)}>
                <div></div>
                <Grid item md={true} sm={12} className={classes.previewContainer}>
                    <Paper elevation={0} className={classes.paperContainer}>
                        <Field 
                            name={TaxFormFieldNames.name} 
                            component={CubitTextFieldAdapter} 
                            label={tr(t.TEXT_NAME)} 
                        />
                        <Field
                            name={TaxFormFieldNames.title}
                            component={CubitTextFieldAdapter}
                            label={tr(t.TEXT_TITLE)}
                            className={classes.midInput}
                        />
                        <Field
                            name={TaxFormFieldNames.templateId}
                            component={CubitSelectAdapter}
                            label={tr(t.TEXT_DOCUMENT_TEMPLATES)}
                            options={templateOptions(pdfTemplates)}
                            valueIsObject={false}
                            className={classes.lastInput}
                        />
                        <Droppable droppableId="selectedList">
                            {(provided: any, snapshot: any) => (
                                <div
                                    ref={provided.innerRef}
                                    className={classes.dropContainer}
                                    //style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {fields.length ? fields.getAll().map((field: any, index: number) => {
                                        return (
                                            <Draggable key={field.id} draggableId={field.id} index={index}>
                                                {(provided: any, snapshot: any) => (
                                                    <div
                                                        className={classNames(classes.textTemplate, classes.selectedTemplate)}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            //maxWidth: snapshot.isDragging ? 380 : '100%',
                                                            ...provided.draggableProps.style
                                                        }}
                                                    >
                                                        <Typography variant="body2">{field.name}</Typography>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })
                                    :
                                        <Typography variant="body1">{tr(t.TEXT_ADD_TEXT)}</Typography>
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Paper>
                    <Grid container justifyContent="center" className={classes.docPreview}>
                        {currentTemplate && <Grid item>
                            <Typography variant="body1">{tr(t.TEXT_PREVIEW)}</Typography>
                            <DocumentTemplateComponent forPrint noBorder canvasWidth={828} template={currentTemplate} texts={textTemplates} data={dummyData} usersign='false' />
                            <Paper elevation={0} square={true} className={classes.previewPaper}>
                                {previewTextTemplates.map((text: DocumentTemplate, index: number) => 
                                    <Grid container onClick={() => navigate(`/${RouteNames.settings}/${RouteNames.TEXT}/${text.id}`)} key={index}>
                                        <Editor readOnly={true} customStyleMap={editorStyleMap} editorState={text.state} onChange={console.log}/>
                                    </Grid>
                                )}
                            </Paper>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item md={3} sm={12} className={classes.sidebar}>
                    <Droppable droppableId="sidebarList">
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                //style={getListStyle(snapshot.isDraggingOver)}
                            >
                                <Typography variant="h5">{tr(t.TEXT_TEXT_ELEMENTS)}</Typography>
                                {textTemplates.map((item: DocumentTemplate, index: number) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                className={classes.textTemplate}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={provided.draggableProps.style}
                                            >
                                                {item.name}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </Grid>
            </DragDropContext>
        </Grid>
    )
}

type TemplateFormProps = {
    selectedTextTemplates: any
    allTextTemplates: DocumentTemplate[]
    pdfTemplates: DocumentTemplate[]
}

const Form: React.FC<InjectedFormProps<any, TemplateFormProps> & TemplateFormProps> = (props) => {
    const { 
        handleSubmit,
        selectedTextTemplates,
        allTextTemplates,
        pdfTemplates,
    } = props
    const classes = useStyles()
    const selector = formValueSelector(TaxFormName.CaseTemplateForm)
    const currentTemplateId = useSelector((state: AppState) => selector(state, TaxFormFieldNames.templateId))
    const currentTemplate = pdfTemplates.length ? pdfTemplates.filter((template: DocumentTemplate) => template.id === currentTemplateId)[0] : null
    const currentTextTemplates = useSelector((state: AppState) => selector(state, TaxFormFieldNames.textIds))
    const [textTemplates, setTextTemplates] = useState<DocumentTemplate[]>([])

    useEffect(() => {
        if (allTextTemplates.length) {
            setTextTemplates(allTextTemplates.filter((t: DocumentTemplate) => !selectedTextTemplates.some((st: any) => st.id === t.id)))
        }
    }, [allTextTemplates, selectedTextTemplates])

    const reorder = (list: DocumentTemplate[], startIndex: number, endIndex: number): DocumentTemplate[] => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = (result: any, fields: any) => {
        const { source, destination } = result
        if (!destination) {
            return
        }

        if (source.droppableId === destination.droppableId) {
            //swap field array items or sidebar items accordingly
            if (source.droppableId !== 'sidebarList') {
                if (source.index !== destination.index) {
                    fields.swap(source.index, destination.index)
                }
            } else {
                if (source.index !== destination.index) {
                    const items = reorder(textTemplates, source.index, destination.index)
                    setTextTemplates(items)
                }
            }
        } else {
            if (destination.droppableId !== 'sidebarList') {
                fields.insert(destination.index, textTemplates[source.index])
                const result = Array.from(textTemplates)
                result.splice(source.index, 1)
                setTextTemplates(result)
            } else {
                const result = Array.from(textTemplates)
                result.splice(destination.index, 0, fields.get(source.index))
                setTextTemplates(result)
                fields.remove(source.index)
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container className="doc-preview">
                <FieldArray
                    name={TaxFormFieldNames.textIds}
                    component={SelectedTextTemplatesList}
                    textTemplates={textTemplates}
                    classes={classes}
                    onDragEnd={onDragEnd}
                    pdfTemplates={pdfTemplates}
                    currentTemplate={currentTemplate}
                    currentTextTemplates={currentTextTemplates}
                />
            </Grid>
        </form>
    )
}

interface FormProps {}

export const CaseDocumentForm = reduxForm<{}, FormProps & TemplateFormProps>({
    form: TaxFormName.CaseTemplateForm,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
