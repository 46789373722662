import React from 'react'

import { Field, formValueSelector } from 'redux-form'
import { Grid } from '@material-ui/core'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { CubitCheckboxAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import { ValueContainer } from 'common/value-container/value-container'
import { isTaxationEditingFinished, Taxation } from 'models/taxation'
import { useSelector } from 'react-redux'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { orderBy } from 'lodash'
import { AppState } from 'app/app-store'

type Factor = {
    name: string
    factor: string
}

const defaultFactors: any[] = [
    {
        label: '-',
        value: 1,
    },
]
const defaultNameFactors: any[] = [
    {
        label: '-',
        value: '',
    },
]

const standardFactorProps = (factors: any, buildingFieldName: string) => ({
    name: `${buildingFieldName}.standardFactor`,
    label: '',
    component: CubitSelectAdapter,
    valueIsObject: false,
    options: defaultFactors.concat(
        factors.map((factor: Factor) => ({
            label: `${factor.factor} ${factor.name}`,
            value: factor.factor,
        })),
    ),
})

const getFromToOptions = (settings: any, factorName: string): any => {
    const standardFactor: any = settings?.standardFactors?.find((f: any) => f.name === factorName) || {}
    let options: any = []
    for (let index = standardFactor.from; index <= standardFactor.to; index += 0.05) {
        options.push(index)
    }
    return defaultFactors.concat(
        options.map((factor: number) => ({
            label: factor.toFixed(2),
            value: factor,
        })),
    )
}

const getFactorNameOptions = (settings: any): any => {
    const standardFactors = settings?.standardFactors ? orderBy(settings.standardFactors, 'from', 'desc') : []

    return defaultNameFactors.concat(
        standardFactors.map((factor: Factor) => ({
            label: factor.name,
            value: factor.name,
        })),
    )
}

type TaxationPropertyStandardFactorsProps = {
    taxation: Taxation
    editable: boolean
    formName: string
    buildingFieldName: string
    verifyable?: boolean
}

export const TaxationPropertyStandardFactors: React.FC<TaxationPropertyStandardFactorsProps> = (props) => {
    const { taxation, editable, buildingFieldName, verifyable = false } = props
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
    const standardFactors =
        settings && settings.standardFactors ? orderBy(settings.standardFactors, 'factor', 'desc') : []
    const factorsFromTo = settings?.standardFactorsFromTo

    const selector = formValueSelector(`taxation-${taxation.id}`)
    const stateRef = useSelector((state: AppState) => state)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    return (
        <>
            <Grid container spacing={2} alignItems="center">
                {!factorsFromTo && (
                    <Grid item md={3} sm={6}>
                        <Field
                            {...standardFactorProps(standardFactors, buildingFieldName)}
                            defaultValue=""
                            label={tr(t.TEXT_STANDARD_FACTOR)}
                            disabled={!editable}
                        />
                    </Grid>
                )}
                {factorsFromTo && (
                    <>
                        <Grid item lg={3} sm={6} xs={9}>
                            <Field
                                options={getFactorNameOptions(settings)}
                                defaultValue=""
                                label={tr(t.TEXT_STANDARD_FACTOR)}
                                name={`${buildingFieldName}.standardFactorName`}
                                component={CubitSelectAdapter}
                                valueIsObject={false}
                                disabled={!editable}
                            />
                        </Grid>

                        <Grid item lg={3} sm={6} xs={9}>
                            <Field
                                options={getFromToOptions(
                                    settings,
                                    getFieldValue(`${buildingFieldName}.standardFactorName`),
                                )}
                                label={tr(t.TEXT_FACTOR)}
                                name={`${buildingFieldName}.standardFactor`}
                                component={CubitSelectAdapter}
                                valueIsObject={false}
                                disabled={!editable}
                            />
                        </Grid>
                    </>
                )}
                {verifyable && (
                    <Grid item xs>
                        <ValueContainer
                            label={tr(t.TEXT_VERIFIED)}
                            value={
                                <Field
                                    name={`${buildingFieldName}.verifiedValues.standardFactor`}
                                    component={CubitCheckboxAdapter}
                                    disabled={isTaxationEditingFinished(taxation)}
                                />
                            }
                        ></ValueContainer>
                    </Grid>
                )}
            </Grid>
        </>
    )
}
