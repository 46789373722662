import { combineEpics } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { filter, map, delay, mergeMap, catchError } from 'rxjs/operators'
import { AnyAction } from 'redux'
import {
    ACTION_SETTINGS_GROUPS_GET,
    actionSettingsGroupsGetSuccess,
} from 'shared-components/src/settings/groups/groups-actions'
import {
    ACTION_GET_REPORTS_SETTINGS,
    ACTION_UPDATE_REPORTS_SETTINGS,
    actionGetReportsSuccess,
    actionUpdateYearSettingsSuccess,
    ACTION_GET_TAX_SETTINGS,
    actionGetTaxSuccess,
    ACTION_UPDATE_TAX_SETTINGS,
    actionUpdateTaxSettingsSuccess,
    ACTION_GET_TAX_SETTINGS_HISTORY,
    actionGetTaxSettingsHistorySuccess,
    ACTION_GET_TEMPLATES,
    actionGetTemplatesSuccess,
    ACTION_GET_TEMPLATE,
    actionGetTemplateSuccess,
    ACTION_CREATE_TEMPLATE,
    ACTION_UPDATE_TEMPLATE,
    actionUpdateTemplateSuccess,
    ACTION_GET_TEMPLATES_BY_TYPE,
    actionGetTemplatesByTypeSuccess,
    actionNavigateToTemplate,
    actionCreateTemplateSuccess,
    ACTION_UPLOAD_TEMPLATE_IMAGE,
    ACTION_DELETE_TEMPLATE,
    actionDeleteTemplateSuccess,
    actionGetCaseFlowEntriesSuccess,
    actionUpdateCaseFlowEntriesSuccess,
    ACTION_GET_CASE_FLOW_ENTRIES,
    ACTION_UPDATE_CASE_FLOW_ENTRIES,
    ACTION_UPLOAD_CSV_FILE,
    actionUploadCsvFileSuccess,
    actionUploadCsvFileFailed,
    ACTION_CALCULATE_YEAR_TAX,
    actionCalculateYearTaxSuccess,
    actionCalculateYearTax,
} from './settings-actions'
import { httpGet, plantsUrl, httpPost, httpPut, httpUpload, httpDelete } from 'services/httpService'
import { defaultCatchError } from 'app/app-epics'
import { actionCubitSnackbarShow } from 'common/cubit-snackbar/cubit-snackbar-actions'
import { tr } from 'utils/translations/translate'
import { TEXT_CHANGES_SAVED, TEXT_ERROR_OCCURRED } from 'utils/translations/keys'
import { actionGoBack } from 'app/app-actions'

export const getSettingsGroupsEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_SETTINGS_GROUPS_GET),
        delay(300),
        // mergeMap(
        //     (): Observable<AnyAction> =>
        //         httpGet(plantsUrl('/groups/full')).pipe(
        //             map((data: any) => actionSettingsGroupsGetSuccess(data)),
        //             defaultCatchError(),
        //         ),
        // ),
        map(() => {
            return actionSettingsGroupsGetSuccess([{ name: 'Group A' }, { name: 'Group B' }, { name: 'Group C' }])
        }),
    )

export const getSettingsEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_GET_REPORTS_SETTINGS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/getYearSettings`)).pipe(
                    map((settings: any) => actionGetReportsSuccess(settings)),
                    defaultCatchError(),
                ),
        ),
    )

export const updateSettingsEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_UPDATE_REPORTS_SETTINGS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl(`/tax/updateYearSettings`), action.settings).pipe(
                    mergeMap((settings: any) =>
                        of(actionUpdateYearSettingsSuccess(settings), actionCalculateYearTax()),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const calculateYearTaxEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_CALCULATE_YEAR_TAX),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/calculateYearSettings`)).pipe(
                    mergeMap(() =>
                        of(actionCalculateYearTaxSuccess(), actionCubitSnackbarShow(tr(TEXT_CHANGES_SAVED)), actionGoBack()),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const getTaxSettingsEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_GET_TAX_SETTINGS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/getTaxSettings`)).pipe(
                    map((settings: any) => actionGetTaxSuccess(settings)),
                    defaultCatchError(),
                ),
        ),
    )

export const getTaxSettingsHistoryEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_GET_TAX_SETTINGS_HISTORY),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/getTaxSettingsHistory`)).pipe(
                    map((settings: any) => actionGetTaxSettingsHistorySuccess(settings)),
                    defaultCatchError(),
                ),
        ),
    )

export const updateTaxSettingsEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_UPDATE_TAX_SETTINGS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl(`/tax/saveTaxSettings`), action.settings).pipe(
                    mergeMap((settings: any) =>
                        of(actionUpdateTaxSettingsSuccess(settings), actionCubitSnackbarShow(tr(TEXT_CHANGES_SAVED))),
                    ),
                    defaultCatchError('Submit failed'),
                ),
        ),
    )

export const getTemplatesEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_GET_TEMPLATES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/documentTemplate/getAll`)).pipe(
                    map((templates: any) => actionGetTemplatesSuccess(templates)),
                    defaultCatchError(),
                ),
        ),
    )

export const getTemplatesByTypeEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_GET_TEMPLATES_BY_TYPE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/documentTemplate/getByType/${action.documentType}`)).pipe(
                    map((templates: any) => actionGetTemplatesByTypeSuccess(templates, action.documentType)),
                    defaultCatchError(),
                ),
        ),
    )

export const getTemplateEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_GET_TEMPLATE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/documentTemplate/get/${action.id}`)).pipe(
                    map((template: any) => actionGetTemplateSuccess(template)),
                    defaultCatchError(),
                ),
        ),
    )

export const createTemplateEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_CREATE_TEMPLATE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl(`/documentTemplate/add`), action.template).pipe(
                    mergeMap((template: any) =>
                        of(
                            actionCreateTemplateSuccess(template),
                            actionCubitSnackbarShow(tr(TEXT_CHANGES_SAVED)),
                            actionNavigateToTemplate(template),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const updateTemplateEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_UPDATE_TEMPLATE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(plantsUrl(`/documentTemplate/update/${action.template.id}`), action.template).pipe(
                    mergeMap((template: any) =>
                        of(actionUpdateTemplateSuccess(template), actionCubitSnackbarShow(tr(TEXT_CHANGES_SAVED))),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const uploadTemplateImageEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_UPLOAD_TEMPLATE_IMAGE),
        mergeMap((action): Observable<any> => {
            const request = httpUpload(plantsUrl('/documentTemplate/upload'), [action.file])
            return request.pipe(
                mergeMap((template: any) => of(actionCreateTemplateSuccess(template))),
                defaultCatchError(),
            )
        }),
    )

export const deleteTemplateEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_DELETE_TEMPLATE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpDelete(plantsUrl(`/documentTemplate/delete`), action.template).pipe(
                    mergeMap(() => of(actionDeleteTemplateSuccess(action.template))),
                    defaultCatchError(),
                ),
        ),
    )

export const getCaseFlowEntriesEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_GET_CASE_FLOW_ENTRIES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/documentTemplate/getCaseFlowEntries/`)).pipe(
                    map((entries: any) => actionGetCaseFlowEntriesSuccess(entries)),
                    defaultCatchError(),
                ),
        ),
    )

export const updateCaseFlowEntriesEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_UPDATE_CASE_FLOW_ENTRIES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(plantsUrl(`/documentTemplate/updateCaseFlowEntry/${action.entry.id}`), action.entry).pipe(
                    mergeMap((entry: any) =>
                        of(actionUpdateCaseFlowEntriesSuccess(entry), actionCubitSnackbarShow(tr(TEXT_CHANGES_SAVED))),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const uploadCsvFileEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_UPLOAD_CSV_FILE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl(`/upload`), action.data).pipe(
                    mergeMap((data: any) =>
                        of(actionUploadCsvFileSuccess(data), actionCubitSnackbarShow(tr(TEXT_CHANGES_SAVED))),
                    ),
                    catchError((error: any) =>
                        of(actionCubitSnackbarShow(tr(TEXT_ERROR_OCCURRED), 'error'), actionUploadCsvFileFailed()),
                    ),
                ),
        ),
    )
export const settingsEpics = combineEpics(
    getSettingsGroupsEpic,
    getSettingsEpic,
    updateSettingsEpic,
    getTaxSettingsEpic,
    updateTaxSettingsEpic,
    getTaxSettingsHistoryEpic,
    getTemplatesEpic,
    getTemplateEpic,
    createTemplateEpic,
    updateTemplateEpic,
    getTemplatesByTypeEpic,
    uploadTemplateImageEpic,
    deleteTemplateEpic,
    getCaseFlowEntriesEpic,
    updateCaseFlowEntriesEpic,
    uploadCsvFileEpic,
    calculateYearTaxEpic,
)
