import { makeReducer, arrayDistinct, getTribunalComplaintGroupType } from 'utils'
import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import * as actions from './tribunal-actions'
import { TribunalGroupType } from './models/tribunal-group'
import { findIndex, filter, includes, remove, uniq } from 'lodash'
import { TaxationState } from 'models/taxation'
import { Complaint } from './models/complaint.type'
import { TribunalGroupsAndTaxations } from './models/tribunal-groups-and-taxations.type'
import { ACTION_TAXATION_FINISH_SUCCESS } from 'property-taxation-edit-page/taxation-actions'
import { GroupStatus } from './enums/group-status'

export type TribunalState = TribunalGroupsAndTaxations & {
    complaints: Complaint[]
    loading: boolean
    loaded: boolean
    approved: any[]
    approvedDetails: any
    sendingLetters: boolean
}

type TribunalReducer = ActionReducer<TribunalState>

const getFull: TribunalReducer = (state) => ({
    ...state,
    loading: true,
    loaded: false,
})
const getFullSuccess: TribunalReducer = (state, action) => ({
    ...state,
    groups: action.data.groups,
    taxations: action.data.taxations,
    loading: false,
    loaded: true,
})

const getApproved: TribunalReducer = (state) => state
const getApprovedSuccess: TribunalReducer = (state, action) => ({
    ...state,
    approved: action.data
})

const getGroupDetails: TribunalReducer = (state) => ({
    ...state,
    approvedDetails: {
        loading: true
    }
})

const getGroupDetailsSuccess: TribunalReducer = (state, action) => ({
    ...state,
    approvedDetails: {
        ...action.data,
        loading: false
    }
})

const sendGroupLetters: TribunalReducer = (state) => ({
    ...state,
    sendingLetters: true
})

const sendGroupLettersSuccess: TribunalReducer = (state, action) => {
    //set the list item status if available
    const approvedItems = state.approved?.filter((a: any) => action.ids.includes(a.id))
    if (approvedItems.length) {
        approvedItems.forEach(i => i.group.status = GroupStatus.NOTIFIED)
    }
    //set single group view if available
    if (state.approvedDetails?.group) {
        state.approvedDetails.group.status = GroupStatus.NOTIFIED
    }
    state.sendingLetters = false

    return state
}

const getGroup: TribunalReducer = (state) => state
const getGroupSuccess: TribunalReducer = (state, action) => {
    const group = action.data.group
    const taxations = action.data.taxations

    const groupIndex = findIndex(state.groups, (g) => g.id === group.id)

    groupIndex < 0 ? state.groups.push(group) : (state.groups[groupIndex] = group)

    let groupTaxations = state.taxations[group.id]

    groupTaxations
        ? (groupTaxations = arrayDistinct([...groupTaxations, ...taxations]))
        : (state.taxations[group.id] = taxations)

    return state
}

const updateGroup: TribunalReducer = (state) => state
const updateGroupSuccess: TribunalReducer = (state, action) => {
    const groupIndex = findIndex(state.groups, (g) => g.id === action.group.id)

    groupIndex < 0 ? state.groups.push(action.group) : (state.groups[groupIndex] = action.group)

    return state
}

const finishConflictsReview: TribunalReducer = (state) => state
const finishConflictsReviewSuccess: TribunalReducer = (state, action) => {
    const groupIndex = findIndex(state.groups, (g) => g.id === action.group.id)

    groupIndex < 0 ? state.groups.push(action.group) : (state.groups[groupIndex] = action.group)

    return state
}

const createGroupAndAssignTaxations: TribunalReducer = (state) => state
const createGroupAndAssignTaxationsSuccess: TribunalReducer = (state, action) => {
    const { oldGroupId, newGroup, taxationsIds } = action

    const movedTaxations = filter(state.taxations[oldGroupId], (t) => includes(taxationsIds, t.id))

    remove(state.taxations[oldGroupId], (t) => includes(taxationsIds, t.id))

    state.groups.push(newGroup)
    state.taxations[newGroup.id] = movedTaxations

    return state
}

const assignTaxations: TribunalReducer = (state) => state
const assignTaxationsSuccess: TribunalReducer = (state, action) => {
    const { oldGroupId, newGroup, taxationsIds } = action

    const movedTaxations = filter(state.taxations[oldGroupId], (t) => includes(taxationsIds, t.id))

    remove(state.taxations[oldGroupId], (t) => includes(taxationsIds, t.id))

    state.taxations[newGroup.id]
        ? state.taxations[newGroup.id].concat(movedTaxations)
        : (state.taxations[newGroup.id] = movedTaxations)

    return state
}

const planTribunalGroup: TribunalReducer = (state) => state
const planTribunalGroupSuccess: TribunalReducer = (state, action) => {
    const { oldGroupId, newGroup } = action

    if (state.taxations[oldGroupId]) {
        state.taxations[oldGroupId] = state.taxations[oldGroupId].map((t) => ({ ...t, state: TaxationState.TribunalPlanned }))
    }
    let group = state.groups.find(g => g.id === oldGroupId)
    if (group) {
        group.name = newGroup.name
        group.description = newGroup.description
    }

    return state
}

const startTribunalGroup: TribunalReducer = (state) => state
const startTribunalGroupSuccess: TribunalReducer = (state, action) => {
    const { groupId } = action

    if (state.taxations[groupId]) {
        state.taxations[groupId] = state.taxations[groupId].map((t) => ({ ...t, state: TaxationState.TribunalStarted }))
    }

    return state
}

const readyForFinishTribunalGroup: TribunalReducer = (state) => state
const readyForFinishTribunalGroupSuccess: TribunalReducer = (state, action) => {
    const { groupId } = action

    if (state.taxations[groupId]) {
        state.taxations[groupId] = state.taxations[groupId].map((t) => ({
            ...t,
            state: TaxationState.TribunalReadyForFinishing,
        }))
    }

    return state
}

const finishTribunalGroup: TribunalReducer = (state) => state
const finishTribunalGroupSuccess: TribunalReducer = (state, action) => {
    const { groupId } = action

    if (state.taxations[groupId]) {
        state.taxations[groupId] = state.taxations[groupId].map((t) => ({
            ...t,
            state: TaxationState.TribunalFinished,
        }))
    }

    return state
}

const markTaxationsAsConflicted: TribunalReducer = (state) => state
const markTaxationsAsConflictedSuccess: TribunalReducer = (state, action) => {
    const { userId, groupId, taxationsIds } = action

    if (state.taxations[groupId]) {
        state.taxations[groupId] = state.taxations[groupId].map((t) => {
            if (includes(taxationsIds, t.id)) {
                return { ...t, conflictedUsers: uniq([...t.conflictedUsers, userId]) }
            } else {
                return t
            }
        })
    }

    return state
}

const removeTaxationsAsConflicted: TribunalReducer = (state) => state
const removeTaxationsAsConflictedSuccess: TribunalReducer = (state, action) => {
    const { userId, groupId, taxationsIds } = action

    if (state.taxations[groupId]) {
        state.taxations[groupId] = state.taxations[groupId].map((t) => {
            if (includes(taxationsIds, t.id)) {
                return {
                    ...t,
                    conflictedUsers: filter(t.conflictedUsers, (conflictedUserId) => conflictedUserId !== userId),
                }
            } else {
                return t
            }
        })
    }

    return state
}

const rescheduleTaxations: TribunalReducer = (state) => state
const rescheduleTaxationsSuccess: TribunalReducer = (state, action) => {
    const { groupId, taxationsIds } = action

    remove(state.taxations[groupId], (t) => includes(taxationsIds, t.id))

    return state
}

const markTaxationsAsFinished: TribunalReducer = (state) => state
const markTaxationsAsFinishedSuccess: TribunalReducer = (state, action) => {
    const { groupId, taxationsIds } = action

    if (state.taxations[groupId]) {
        state.taxations[groupId] = state.taxations[groupId].map((t) => {
            if (includes(taxationsIds, t.id)) {
                return { ...t, state: TaxationState.TribunalFinished }
            } else {
                return t
            }
        })
    }

    return state
}

const markTaxationsAsDeclined: TribunalReducer = (state) => state
const markTaxationsAsDeclinedSuccess: TribunalReducer = (state, action) => {
    const { groupId, declinedGroup, taxationsIds, declinedReason } = action

    const declinedTaxations = filter(state.taxations[groupId], (t) => includes(taxationsIds, t.id)).map((t) => ({
        ...t,
        state: TaxationState.TribunalDeclined,
        declinedReason: declinedReason,
    }))

    //remove(state.taxations[groupId], (t) => includes(taxationsIds, t.id))

    state.taxations[declinedGroup.id]
        ? state.taxations[declinedGroup.id].concat(declinedTaxations)
        : (state.taxations[declinedGroup.id] = declinedTaxations)

    // add declined group if it doesnt exist
    if (findIndex(state.groups, declinedGroup.id) === -1) {
        state.groups.push({
            id: declinedGroup.id,
            name: 'Declined',
            description: '',
            type: TribunalGroupType.Declined,
            conflictsReviewedBy: [],
        })
    }

    if (state.taxations[groupId]) {
        state.taxations[groupId] = state.taxations[groupId].map((t) => {
            if (includes(taxationsIds, t.id)) {
                return { ...t, state: TaxationState.TaxationFinished }
            } else {
                return t
            }
        })
    }

    return state
}

const newTaxationFinishSuccess: TribunalReducer = (state, action) => {
    state.loaded = false
    return state
}

//#region Complaints
const getComplaint: TribunalReducer = (state) => state
const getComplaintSuccess: TribunalReducer = (state, action) => {
    return state
}

const updateComplaint: TribunalReducer = (state) => state
const updateComplaintSuccess: TribunalReducer = (state, action) => {
    return state
}

const getAllComplaints: TribunalReducer = (state) => state
const getAllComplaintsSuccess: TribunalReducer = (state, action) => {
    state.complaints = action.complaints.map((c: Complaint) => ({
        ...c,
        complaintGroupType: getTribunalComplaintGroupType(c),
    }))
    return state
}

const getTaxationComplaints: TribunalReducer = (state) => state
const getTaxationComplaintsSuccess: TribunalReducer = (state, action) => {
    return state
}
//#endregion

const reducers: Reducers<TribunalState> = {
    [actions.ACTION_TRIBUNAL_GROUPS_GET_FULL]: getFull,
    [actions.ACTION_TRIBUNAL_GROUPS_GET_FULL_SUCCESS]: getFullSuccess,
    [actions.ACTION_TRIBUNAL_GROUP_GET]: getGroup,
    [actions.ACTION_TRIBUNAL_GROUP_GET_SUCCESS]: getGroupSuccess,
    [actions.ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS]: createGroupAndAssignTaxations,
    [actions.ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS_SUCCESS]: createGroupAndAssignTaxationsSuccess,
    [actions.ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH]: finishConflictsReview,
    [actions.ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH_SUCCESS]: finishConflictsReviewSuccess,
    [actions.ACTION_TRIBUNAL_GROUP_UPDATE]: updateGroup,
    [actions.ACTION_TRIBUNAL_GROUP_UPDATE_SUCCESS]: updateGroupSuccess,
    [actions.ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS]: assignTaxations,
    [actions.ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS_SUCCESS]: assignTaxationsSuccess,
    [actions.ACTION_TRIBUNAL_GROUP_PLAN]: planTribunalGroup,
    [actions.ACTION_TRIBUNAL_GROUP_PLAN_SUCCESS]: planTribunalGroupSuccess,
    [actions.ACTION_TRIBUNAL_GROUP_START]: startTribunalGroup,
    [actions.ACTION_TRIBUNAL_GROUP_START_SUCCESS]: startTribunalGroupSuccess,
    [actions.ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH]: readyForFinishTribunalGroup,
    [actions.ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH_SUCCESS]: readyForFinishTribunalGroupSuccess,
    [actions.ACTION_TRIBUNAL_GROUP_FINISH]: finishTribunalGroup,
    [actions.ACTION_TRIBUNAL_GROUP_FINISH_SUCCESS]: finishTribunalGroupSuccess,
    [actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED]: markTaxationsAsConflicted,
    [actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED_SUCCESS]: markTaxationsAsConflictedSuccess,
    [actions.ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED]: removeTaxationsAsConflicted,
    [actions.ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED_SUCCESS]: removeTaxationsAsConflictedSuccess,
    [actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED]: markTaxationsAsFinished,
    [actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED_SUCCESS]: markTaxationsAsFinishedSuccess,
    [actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED]: markTaxationsAsDeclined,
    [actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED_SUCCESS]: markTaxationsAsDeclinedSuccess,
    [actions.ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE]: rescheduleTaxations,
    [actions.ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE_SUCCESS]: rescheduleTaxationsSuccess,
    [ACTION_TAXATION_FINISH_SUCCESS]: newTaxationFinishSuccess,
    [actions.ACTION_TRIBUNAL_GET_APPROVED]: getApproved,
    [actions.ACTION_TRIBUNAL_GET_APPROVED_SUCCESS]: getApprovedSuccess,
    [actions.ACTION_TRIBUNAL_GET_GROUP_DETAILS]: getGroupDetails,
    [actions.ACTION_TRIBUNAL_GET_GROUP_DETAILS_SUCCESS]: getGroupDetailsSuccess,
    [actions.ACTION_TRIBUNAL_SEND_GROUP_LETTERS]: sendGroupLetters,
    [actions.ACTION_TRIBUNAL_SEND_GROUP_LETTERS_SUCCESS]: sendGroupLettersSuccess,
    //#region Complaints
    [actions.ACTION_TRIBUNAL_COMPLAINTS_GET]: getComplaint,
    [actions.ACTION_TRIBUNAL_COMPLAINTS_GET_SUCCESS]: getComplaintSuccess,
    [actions.ACTION_TRIBUNAL_COMPLAINTS_UPDATE]: updateComplaint,
    [actions.ACTION_TRIBUNAL_COMPLAINTS_UPDATE_SUCCESS]: updateComplaintSuccess,
    [actions.ACTION_TRIBUNAL_COMPLAINTS_GET_ALL]: getAllComplaints,
    [actions.ACTION_TRIBUNAL_COMPLAINTS_GET_ALL_SUCCESS]: getAllComplaintsSuccess,
    [actions.ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINTS]: getTaxationComplaints,
    [actions.ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINTS_SUCCESS]: getTaxationComplaintsSuccess,
    //#endregion
}

const initialState = { groups: [], taxations: {}, complaints: [], loading: false, loaded: false, approved: [], approvedDetails: undefined, sendingLetters: false }

export const tribunalReducer = makeReducer<TribunalState>(reducers, initialState)
