import React, { useState } from 'react'
import { Grid, Typography, IconButton, Button, Divider, InputAdornment } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { Field, formValueSelector } from 'redux-form'
import { Create, Close, Check } from '@material-ui/icons'
import { TaxSettingsRow } from './TaxSettingsRow'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { CubitCheckboxAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { DateTime } from 'luxon'
import { Language } from 'shared-components/src/settings/language/language.enum'

export const getDateString = (date: string, language: Language): string =>
    date ? DateTime.fromJSDate(new Date(date)).setLocale(language).toLocaleString(DateTime.DATE_SHORT) : ''

export const orderByProperty = (field: string) => (a: any, b: any) => {
    if (!b[field]) {
        return -1
    }
    if (a[field] > b[field]) {
        return -1
    }
    if (a[field] < b[field]) {
        return 1
    }
    return 0
}

export const ZonesColumn = (props: any) => {
    const { editMode = false, classes, content, input, isHeader = false } = props
    const endAdornment = content.endAdornment
        ? {
            endAdornment: <InputAdornment position="end">{content.endAdornment}</InputAdornment>,
        }
        : null
    return (
        <Grid container>
            <Grid item sm={4} xs={7}>
                {isHeader && (
                    <Typography variant="body1" className={classes.settingsLabel}>
                        {content.label}
                    </Typography>
                )}
                {!isHeader && !editMode && (
                    <Typography variant="body1" className={classes.zonesContent}>
                        {content.label}
                    </Typography>
                )}
                {!isHeader && editMode && (
                    <Field
                        name={input.label}
                        component={CubitTextFieldAdapter}
                        className={classes.shortTextInput}
                        InputProps={endAdornment}
                    />
                )}
            </Grid>
            <Grid item sm={1} xs={4}>
                {(!editMode || isHeader) && (
                    <Typography variant="body1" className={isHeader ? classes.settingsLabel : classes.zonesContent}>
                        {`${content.value} ${content.endAdornment ? content.endAdornment : ''}`}
                    </Typography>
                )}
                {editMode && !isHeader && (
                    <Field
                        name={input.value}
                        component={CubitTextFieldAdapter}
                        className={classes.shortTextInput}
                        InputProps={endAdornment}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export const RenderZoneFactorsRow = ({ classes, stateRef, rowLabels = {}, readOnly, canCancel }: any) => {
    const selector = formValueSelector(TaxFormName.TaxationSettings)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const [editing, setEditing] = useState(false)
    const resetEdit = useSelector((state: AppState) => state.taxSettings.taxFormReset)
    const defaultZones = stateRef.taxSettings?.taxSettings?.databaseZones
    const zonesCount = defaultZones ? defaultZones.length : 7
    if (resetEdit && editing) {
        setEditing(false)
    }
    return (
        <Grid container className={classes.settingsRow}>
            {!editing && !readOnly && (
                <Grid item className={classes.editButton}>
                    <IconButton
                        onClick={() => {
                            setEditing(true)
                            canCancel(true)
                        }}
                    >
                        <Create />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs={12}>
                <TaxSettingsRow classes={classes}>
                    <ZonesColumn
                        editMode={editing}
                        classes={classes}
                        content={{
                            label: rowLabels.label,
                            value: rowLabels.subLabel,
                        }}
                        isHeader={true}
                    />
                </TaxSettingsRow>
                {[...Array(zonesCount)].map((val: undefined, index: number) => (
                    <TaxSettingsRow classes={classes} key={index}>
                        <ZonesColumn
                            editMode={editing}
                            classes={classes}
                            content={{
                                label: getFieldValue(
                                    `${TaxFormFieldNames.zoneLabels}.${TaxFormFieldNames.zone}${index + 1}`,
                                ),
                                value: getFieldValue(
                                    `${TaxFormFieldNames.zoneFactors}.${TaxFormFieldNames.zone}${index + 1}`,
                                ),
                            }}
                            input={{
                                value: `${TaxFormFieldNames.zoneFactors}.${TaxFormFieldNames.zone}${index + 1}`,
                                label: `${TaxFormFieldNames.zoneLabels}.${TaxFormFieldNames.zone}${index + 1}`,
                            }}
                        />
                    </TaxSettingsRow>
                ))}
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.innerDivider} />
            </Grid>
        </Grid>
    )
}

export const RemoveField = ({ fields, index }: any) => (
    <IconButton onClick={() => fields.remove(index)}>
        <Close />
    </IconButton>
)

export const ColumnText = (props: any) => {
    const { text, className } = props
    return (
        <Typography variant="body1" className={className}>
            {text}
        </Typography>
    )
}

export const TwoColumnsRow = (props: any) => {
    const {
        editMode = false,
        classes,
        content,
        input = {
            label: '',
            value: '',
        },
        isHeader = false,
        fields,
        index,
        removable = false,
    } = props
    return (
        <Grid container>
            <Grid item sm={4} xs={7} className={classes.columnPadding}>
                {(!editMode || isHeader) && (
                    <ColumnText
                        text={content.label}
                        className={isHeader ? classes.settingsLabel : classes.itemPaddding}
                    />
                )}
                {editMode && !isHeader && <Field name={input.label} component={CubitTextFieldAdapter} />}
            </Grid>
            <Grid item sm={1} xs={4}>
                {(!editMode || isHeader) && (
                    <ColumnText
                        text={content.value}
                        className={isHeader ? classes.settingsLabel : classes.itemPaddding}
                    />
                )}
                {editMode && !isHeader && (
                    <Field name={input.value} component={CubitTextFieldAdapter} className={classes.shortTextInput} />
                )}
            </Grid>
            {editMode && removable && (
                <Grid item xs={1}>
                    <RemoveField fields={fields} index={index} />
                </Grid>
            )}
        </Grid>
    )
}

export const OthersColumn = (props: any) => {
    const {
        editMode = false,
        classes,
        content = {
            label: '',
            values: [],
        },
        input = {
            label: '',
            values: [],
        },
        isHeader = false,
        removable = false,
        fields,
        index,
    } = props
    return (
        <Grid container>
            <Grid item sm={4} xs={7}>
                {(!editMode || isHeader) && (
                    <ColumnText
                        text={content.label}
                        className={isHeader ? classes.settingsLabel : classes.itemPadding}
                    />
                )}
                {editMode && !isHeader && (
                    <Field name={input.label} component={CubitTextFieldAdapter} className={classes.columnPadding} />
                )}
            </Grid>
            {content.values &&
                content.values.map((value: any, i: number) => {
                    const currentValue: string = value ? value : '-'
                    return (
                        <Grid item sm={true} xs={6} key={i}>
                            {(!editMode || isHeader) && (
                                <ColumnText
                                    text={isHeader ? `${tr(t.TEXT_CHOICE)} ${i + 1}` : currentValue}
                                    className={isHeader ? classes.settingsLabel : classes.itemPadding}
                                />
                            )}
                            {editMode && !isHeader && (
                                <Field
                                    name={input.values[i]}
                                    component={CubitTextFieldAdapter}
                                    className={classes.columnPadding}
                                />
                            )}
                        </Grid>
                    )
                })}
            {editMode && removable && (
                <Grid item xs={1}>
                    <RemoveField fields={fields} index={index} />
                </Grid>
            )}
            {editMode && !removable && <Grid item xs={1}></Grid>}
        </Grid>
    )
}

export const RenderOtherFactorsRow = (props: any) => {
    const { fields, classes, stateRef, rowLabels = {}, removable = true, readOnly, canCancel } = props
    const selector = formValueSelector(TaxFormName.TaxationSettings)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const [editing, setEditing] = useState(false)
    const resetEdit = useSelector((state: AppState) => state.taxSettings.taxFormReset)
    if (resetEdit && editing) {
        setEditing(false)
    }
    return (
        <Grid container className={classes.settingsRow}>
            {!editing && !readOnly && (
                <Grid item className={classes.editButton}>
                    <IconButton
                        onClick={() => {
                            setEditing(true)
                            canCancel(true)
                        }}
                    >
                        <Create />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs={12}>
                <TaxSettingsRow classes={classes}>
                    <OthersColumn
                        editMode={editing}
                        classes={classes}
                        content={{
                            label: rowLabels.label,
                            values: [...Array(5)],
                        }}
                        isHeader={true}
                    />
                </TaxSettingsRow>
                {fields.map((field: any, index: number) => (
                    <TaxSettingsRow classes={classes} key={index}>
                        <OthersColumn
                            editMode={editing}
                            classes={classes}
                            content={{
                                label: getFieldValue(`${field}.${TaxFormFieldNames.name}`),
                                values: [
                                    getFieldValue(`${field}.${TaxFormFieldNames.choice1}`),
                                    getFieldValue(`${field}.${TaxFormFieldNames.choice2}`),
                                    getFieldValue(`${field}.${TaxFormFieldNames.choice3}`),
                                    getFieldValue(`${field}.${TaxFormFieldNames.choice4}`),
                                    getFieldValue(`${field}.${TaxFormFieldNames.choice5}`),
                                ],
                            }}
                            input={{
                                label: `${field}.${TaxFormFieldNames.name}`,
                                values: [
                                    `${field}.${TaxFormFieldNames.choice1}`,
                                    `${field}.${TaxFormFieldNames.choice2}`,
                                    `${field}.${TaxFormFieldNames.choice3}`,
                                    `${field}.${TaxFormFieldNames.choice4}`,
                                    `${field}.${TaxFormFieldNames.choice5}`,
                                ],
                            }}
                            removable={true}
                            fields={fields}
                            index={index}
                        />
                    </TaxSettingsRow>
                ))}
                {removable && editing && (
                    <Grid container>
                        <Button variant="outlined" onClick={() => fields.push()} className={classes.button}>
                            {tr(t.TEXT_ADD_FACTOR)}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.innerDivider} />
            </Grid>
        </Grid>
    )
}

export const BuildingUseTypeRow = (props: any) => {
    const {
        editMode = false,
        classes,
        content,
        input = {
            labelName: '',
            valueNames: [],
        },
        isHeader = false,
        fields,
        index,
        removable = false,
        buildingUsageByCode = false,
    } = props
    return (
        <Grid container>
            <Grid item sm={4} xs={6} className={classes.columnPadding}>
                {/* {(!editMode || isHeader) && ( */}
                <ColumnText
                    text={content.label}
                    className={isHeader ? classes.settingsLabel : classes.extraPadding}
                />
                {/* )} */}
                {/* {editMode && !isHeader && <Field name={input.labelName} component={CubitTextFieldAdapter} />} */}
            </Grid>
            {buildingUsageByCode && (
                <Grid item sm={2} xs={3} className={classes.extraPadding}>
                    {(!editMode || isHeader) && (
                        <Typography variant="body1" className={isHeader ? classes.settingsLabel : classes.itemPaddding}>
                            <span>{content.values[2]}</span>
                        </Typography>
                    )}
                    {editMode && !isHeader && <Field name={input.valueNames[2]} component={CubitTextFieldAdapter} />}
                </Grid>
            )}
            <Grid item sm={2} xs={3} className={classes.extraPadding}>
                {(!editMode || isHeader) && (
                    <Typography variant="body1" className={isHeader ? classes.settingsLabel : classes.itemPaddding}>
                        <span>{`${content.values[0]} ${!isHeader && content.values[0] ? 'kr' : ''}`}</span>
                    </Typography>
                )}
                {editMode && !isHeader && (
                    <Field
                        name={input.valueNames[0]}
                        component={CubitTextFieldAdapter}
                        className={classes.shortTextInput}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                        }}
                    />
                )}
            </Grid>
            <Grid item sm={2} xs={2}>
                {!editMode && !isHeader && content.values[1] && <Check />}
                {(!editMode || isHeader) && content.values[1] && (
                    <Typography variant="body1" className={isHeader ? classes.settingsLabel : classes.itemPaddding}>
                        <span>{content.values[1]}</span>
                    </Typography>
                )}
                {editMode && !isHeader && (
                    <Field
                        name={input.valueNames[1]}
                        component={CubitCheckboxAdapter}
                        className={classes.shortTextInput}
                    />
                )}
            </Grid>
            {editMode && removable && (
                <Grid item xs={1}>
                    <RemoveField fields={fields} index={index} />
                </Grid>
            )}
        </Grid>
    )
}

export const RenderBuildingUseTypeRow = ({
    fields,
    meta: { touched, error, submitFailed },
    classes,
    stateRef,
    rowLabels,
    removable = true,
    readOnly,
    canCancel,
}: any) => {
    const selector = formValueSelector(TaxFormName.TaxationSettings)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const [editing, setEditing] = useState(false)
    const resetEdit = useSelector((state: AppState) => state.taxSettings.taxFormReset)
    const buildingUsageByCode = getFieldValue(TaxFormFieldNames.buildingUsageByCode)
    if (resetEdit && editing) {
        setEditing(false)
    }
    return (
        <Grid container className={classes.settingsRow}>
            {!editing && !readOnly && (
                <Grid item className={classes.editButton}>
                    <IconButton
                        onClick={() => {
                            setEditing(true)
                            canCancel(true)
                        }}
                    >
                        <Create />
                    </IconButton>
                </Grid>
            )}
            {editing && (
                <Grid item xs={12}>
                    <Field
                        name={TaxFormFieldNames.buildingUsageByCode}
                        component={CubitRadioGroupAdapter}
                        direction="row"
                        labelPlacement="start"
                        options={[
                            {
                                label: tr(t.TEXT_BUILDING_USE_TYPE),
                                value: 'false',
                            },
                            {
                                label: tr(t.TEXT_BUILDING_CODE),
                                value: 'true',
                            },
                        ]}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <TaxSettingsRow classes={classes}>
                    <BuildingUseTypeRow
                        editMode={editing}
                        classes={classes}
                        content={{
                            label: rowLabels.label,
                            values: rowLabels.valueLabels,
                        }}
                        isHeader={true}
                        buildingUsageByCode={buildingUsageByCode}
                    />
                </TaxSettingsRow>
                {fields.map((fieldName: string, index: number) => (
                    <TaxSettingsRow classes={classes} key={index}>
                        <BuildingUseTypeRow
                            editMode={editing}
                            classes={classes}
                            content={{
                                label: getFieldValue(`${fieldName}.${TaxFormFieldNames.name}`),
                                values: [
                                    getFieldValue(`${fieldName}.${TaxFormFieldNames.price}`),
                                    getFieldValue(`${fieldName}.${TaxFormFieldNames.iIgnoreFloorFactor}`),
                                    getFieldValue(`${fieldName}.${TaxFormFieldNames.buildingCodes}`),
                                ],
                            }}
                            input={{
                                labelName: `${fieldName}.${TaxFormFieldNames.name}`,
                                valueNames: [
                                    `${fieldName}.${TaxFormFieldNames.price}`,
                                    `${fieldName}.${TaxFormFieldNames.iIgnoreFloorFactor}`,
                                    `${fieldName}.${TaxFormFieldNames.buildingCodes}`,
                                ],
                            }}
                            fields={fields}
                            index={index}
                            removable={removable}
                            buildingUsageByCode={buildingUsageByCode}
                        />
                    </TaxSettingsRow>
                ))}
                {removable && editing && (
                    <Grid container>
                        <Button variant="outlined" onClick={() => fields.push()} className={classes.button}>
                            {tr(t.TEXT_ADD_USAGE_TYPE)}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.innerDivider} />
            </Grid>
        </Grid>
    )
}

export const RenderStaticFields = (props: any) => {
    const { fields, classes, stateRef, settingLabel, readOnly, canCancel, lastItem = false } = props
    const selector = formValueSelector(TaxFormName.TaxationSettings)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const [editing, setEditing] = useState(false)
    const resetEdit = useSelector((state: AppState) => state.taxSettings.taxFormReset)
    if (resetEdit && editing) {
        setEditing(false)
    }
    return (
        <Grid container className={classes.settingsRow}>
            {!editing && !readOnly && (
                <Grid item className={classes.editButton}>
                    <IconButton
                        onClick={() => {
                            setEditing(true)
                            canCancel(true)
                        }}
                    >
                        <Create />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs={12}>
                {settingLabel && (
                    <TaxSettingsRow classes={classes}>
                        <ZonesColumn
                            editMode={editing}
                            classes={classes}
                            content={{
                                label: settingLabel,
                                value: '',
                            }}
                            isHeader={true}
                        />
                    </TaxSettingsRow>
                )}
                {/* [{label: string, name: string, InputProps: any}] */}
                {fields.map((field: any, index: number) => (
                    <TaxSettingsRow classes={classes} key={index}>
                        <ZonesColumn
                            editMode={editing}
                            classes={classes}
                            content={{
                                label: field.label,
                                value: getFieldValue(field.name),
                                endAdornment: field.endAdornment,
                            }}
                            input={{ value: field.name }}
                        />
                    </TaxSettingsRow>
                ))}
            </Grid>
            {!lastItem && (
                <Grid item xs={12}>
                    <Divider className={classes.innerDivider} />
                </Grid>
            )}
        </Grid>
    )
}
