import { Floor, VerifiableFloor } from './floor'
import { Coordinates, HousingUnitAddress } from './housing-unit-address'
import { UploadedFile } from './uploaded-file'
import { Verifiable } from './verifiable'
import { BuildingTaxInfo } from './taxation'
import { Factor } from './property'
import { getOfficeAdjustment } from './year-settings'
const ObjectID = require('bson-objectid')

export type Building = {
    buildingCode: number
    buildingCodeName: string
    buildingNumber: string
    comment: string
    exempt: boolean
    exemptReason: string
    files: UploadedFile[]
    floors: Floor[]
    id: string
    industrialType: string
    inspectionRate: number
    lastControl: any
    nextControl: any
    noOfHousingUnits: number
    riskAssessmentScore: number
    serialNumber: any
    specificFireObjectId: any
    standardFactor: number
    status: string
    statusDate: any
    taxInfo: BuildingTaxInfo
    otherStandardFactors: Factor[]
    builtDate: any
    attributes: string[]
    coordinates: Coordinates
    // mapped props
    address: HousingUnitAddress
    valueBeforeFactors: number
    value: number

    // flags used in frontend
    newlyCreated: boolean
}

export type VerifiableBuilding = Building &
    Verifiable & {
        floors: VerifiableFloor[]
    }

export enum BuildingStandardFactor {
    DemolitionObject = 0,
    AdvancedDecayRestoration = 0.4,
    Low = 0.8,
    Normal = 1,
    High = 1.2,
}

export const emptyBuilding = () =>
    (({
        id: ObjectID().str,
        floors: [],
        files: [],
        address: { addressText: '' } as HousingUnitAddress,
        exempt: false,
        standardFactor: 1,
        noOfHousingUnits: 0,
        newlyCreated: true,
    } as unknown) as VerifiableBuilding)

export const getFinalBuildingValue = (building: Building, allYearSettings: any) => {
    const adjustment = getOfficeAdjustment(allYearSettings)
    const multiplier = adjustment ? (1 + adjustment / 100) : 1
    return building.valueBeforeFactors * multiplier
}

export const unusedBuildingStatuses = [
    'Bygningsnummer utgått',
    'Bygning revet/brent',
    'Bygging avlyst'
]

export const getBuildingArea = (building: Building) => {
    const area = building.floors.reduce((acc: number, floor) => acc + floor.area, 0)
    return Math.round(area)
}

export const BuildingConstants = {
    smallArea: 15
}