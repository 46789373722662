import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import * as t from 'utils/translations/keys'
import { tr } from '../utils/translations/translate'
import { TEXT_DASHBOARD } from '../utils/translations/keys'
import Navigation from 'common/navigation/navigation'
import { RouteComponentProps, navigate } from '@reach/router'
import { Grid, Theme, Typography, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { actionGetFailedCorrespondence } from './dashboard-actions'
import { Mail } from '@material-ui/icons'
import { RouteNames } from 'common/enums/routeNames'
import { PanelElement } from './dasboard-panel'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContainer: {
            flexBasis: '25%',
            boxSizing: 'border-box',
            padding: 10,
            minWidth: 350,
        },
        panelHeader: {
            padding: '3px 10px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        headerText: {
            fontSize: '1.1rem',
        },
    }
})

const DashboardPage: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const failedCorrespondence = useSelector((state: AppState) => state.dashboard.failedCorrespondence)

    useEffect(() => {
        if (!failedCorrespondence) {
            setTimeout(() => {
                dispatch(actionGetFailedCorrespondence())
            }, 0)
        }
    }, [dispatch, failedCorrespondence])

    const element = {
        counter: failedCorrespondence?.length || 0,
        text: tr(t.TEXT_FAILED_CORRESPONDENCE),
        icon: <Mail />,
        action: () => navigate(`/${RouteNames.DASHBOARD}/${RouteNames.FAILED_CORRESPONDENCE}`),
    }

    return (
        <LayoutDefault navigation={<Navigation />} pageTitle={tr(TEXT_DASHBOARD)}>
            <Grid container>
                <div className={classes.panelContainer}>
                    <div>
                        <div className={classes.panelHeader}>
                            <Typography className={classes.headerText} variant="button">
                                {tr(t.TEXT_LETTER)}
                            </Typography>
                        </div>
                        <PanelElement {...element} />
                    </div>
                </div>
            </Grid>
        </LayoutDefault>
    )
}

export { DashboardPage }
