import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    IconButton,
    Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getYesNoBoolOptions, tr } from 'utils/translations/translate'
import {
    TEXT_PROPERTY_INFORMATION,
    TEXT_CADASTRE,
    TEXT_VALUE_TYPE,
    TEXT_ADDRESS,
    TEXT_ZONE,
    TEXT_POWERPLANTS_AND_PETROLIUM,
    TEXT_BENEFICIAL_TO_MUNICIPALITY,
    TEXT_STATUS,
    TEXT_ZIP_CODE,
    TEXT_CANCEL,
} from 'utils/translations/keys'
import { Property } from 'models/property'
import { getPropertyValueTypeTranslationKey } from 'utils'
import { Protected } from 'common/protected/protected'
import { Edit, Save } from '@material-ui/icons'
import { Permission } from 'common/enums/permission'
import { useDispatch, useSelector } from 'react-redux'
import { actionPropertySave } from 'property-page/property-actions'
import { submit, Field, reset } from 'redux-form'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { PropertyDetailsPanelForm } from './property-details-form'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { getPropertyValueTypeOptions, getStatusDate, getStatusText } from 'utils/property/property-helper'
import { AppState } from 'app/app-store'
import { actionGetTaxSettings } from 'settings-page/settings-actions'
import { getZoneOptions, getZoneLabel } from 'settings-page/helper'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'

type DetailsPanelProps = {
    styles: any
    prop: Property
    propertyAddresses: string
    propertyPostalCode: string
}

export const PropertyDetailsPanel: React.FC<DetailsPanelProps> = (props) => {
    const { styles, prop, propertyAddresses, propertyPostalCode } = props
    const [editing, setEditing] = useState(false)
    const [propertyPanelExpanded, setPropertyPanelExpanded] = useState(false)
    const dispatch = useDispatch()
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)
    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)

    useEffect(() => {
        if (!settings?.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [settings, dispatch])

    const startEditing = (e: React.MouseEvent) => {
        e.stopPropagation()
        setPropertyPanelExpanded(true)
        setEditing(true)
    }
    const handleFormSubmit = (values: any) => {
        setEditing(false)
        dispatch(actionPropertySave(prop, values))
    }
    const handleSaveClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch(submit(TaxFormName.PropertyEditPanel))
    }

    const handleCancelEdit = () => {
        setEditing(false)
        dispatch(reset(TaxFormName.PropertyEditPanel))
    }
    const zoneSelectOptions = getZoneOptions(settings)

    return (
        <Accordion expanded={propertyPanelExpanded} className={styles.semiRounded} elevation={0} square={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={styles.panelSummary}
                onClick={() => setPropertyPanelExpanded(!propertyPanelExpanded)}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>{tr(TEXT_PROPERTY_INFORMATION)}</Grid>
                    <Protected p={Permission.PropertyEdit}>
                        {!editing && (
                            <Grid item>
                                <IconButton size="small" onClick={startEditing} color="inherit">
                                    <Edit />
                                </IconButton>
                            </Grid>
                        )}
                        {editing && (
                            <Grid item>
                                <Button className={styles.cancelButton} onClick={handleCancelEdit} color="inherit">
                                    {tr(TEXT_CANCEL)}
                                </Button>
                                <IconButton size="small" onClick={handleSaveClick} color="inherit">
                                    <Save />
                                </IconButton>
                            </Grid>
                        )}
                    </Protected>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={styles.panelContent}>
                <PropertyDetailsPanelForm
                    form={TaxFormName.PropertyEditPanel}
                    onSubmit={handleFormSubmit}
                    initialValues={prop}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={3} className={styles.panelContent__label}>
                            {tr(TEXT_CADASTRE)}:
                        </Grid>
                        <Grid item xs={3}>
                            {prop.holdingNumber}/{prop.subHoldingNumber}/{prop.leaseNumber}/{prop.sectionNumber}
                        </Grid>
                        <Grid item xs={3} className={styles.panelContent__label}>
                            {tr(TEXT_VALUE_TYPE)}:
                        </Grid>
                        {!editing && (
                            <Grid item xs={3}>
                                {tr(getPropertyValueTypeTranslationKey(prop.valueType))}
                            </Grid>)}
                        {editing && (
                            <Grid item xs={3}>
                                <Field
                                    name={TaxFormFieldNames.valueType}
                                    component={CubitSelectAdapter}
                                    valueIsObject={false}
                                    options={getPropertyValueTypeOptions()}
                                />
                            </Grid>
                        )}
                        <Grid item xs={3} className={styles.panelContent__label}>
                            {tr(TEXT_ADDRESS)}:
                        </Grid>
                        <Grid item xs={3}>
                            {propertyAddresses}
                        </Grid>
                        <Grid item xs={3} className={styles.panelContent__label}>
                            {tr(TEXT_ZIP_CODE)}:
                        </Grid>
                        <Grid item xs={3}>
                            {propertyPostalCode}
                        </Grid>
                        <Grid item xs={3} className={styles.panelContent__label}>
                            {tr(TEXT_STATUS)}:
                        </Grid>
                        <Grid item xs={3}>
                            {`${getStatusText(prop)} ${getStatusDate(prop, language)}`}
                        </Grid>


                        {!editing && (
                            <>
                                <Grid item xs={3} className={styles.panelContent__label}>
                                    {tr(TEXT_ZONE)}:
                                </Grid>
                                <Grid item xs={3}>
                                    {getZoneLabel(settings, prop.zone)}
                                </Grid>
                                {prop && prop.otherPropertyFlags && prop.otherPropertyFlags.powerProduction && (
                                    <Grid container className={styles.container}>
                                        <Grid item xs={12}>
                                            {tr(TEXT_POWERPLANTS_AND_PETROLIUM)}
                                        </Grid>
                                    </Grid>
                                )}
                                {prop && prop.otherPropertyFlags && prop.otherPropertyFlags.governmentBeneficial && (
                                    <Grid container className={styles.container}>
                                        <Grid item xs={12}>
                                            {tr(TEXT_BENEFICIAL_TO_MUNICIPALITY)}
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        )}
                        {editing && (
                            <>
                                <Grid item xs={3} className={styles.panelContent__label}>
                                    {tr(TEXT_ZONE)}:
                                </Grid>
                                <Grid item xs={3} className={styles.pb3}>
                                    <Field
                                        name={TaxFormFieldNames.zone}
                                        component={CubitSelectAdapter}
                                        valueIsObject={false}
                                        options={zoneSelectOptions}
                                    />
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item sm={10} xs={12}>
                                        {tr(TEXT_POWERPLANTS_AND_PETROLIUM)}:
                                    </Grid>
                                    <Grid item sm={2} xs={12} className={styles.bold}>
                                        <Field
                                            name={`${[TaxFormFieldNames.otherPropertyFlags]}.${TaxFormFieldNames.powerProduction
                                                }`}
                                            component={CubitRadioGroupAdapter}
                                            options={getYesNoBoolOptions()}
                                            direction="row"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item sm={10} xs={12}>
                                        {tr(TEXT_BENEFICIAL_TO_MUNICIPALITY)}:
                                    </Grid>
                                    <Grid item sm={2} xs={12} className={styles.bold}>
                                        <Field
                                            name={`${[TaxFormFieldNames.otherPropertyFlags]}.${TaxFormFieldNames.governmentBeneficial
                                                }`}
                                            component={CubitRadioGroupAdapter}
                                            options={getYesNoBoolOptions()}
                                            direction="row"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </PropertyDetailsPanelForm>
            </AccordionDetails>
        </Accordion>
    )
}
