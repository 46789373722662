import React, { useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
} from '@material-ui/core'
import {
    TEXT_COMMUNICATION
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { Property } from 'models/property'
import { ExpandMore } from '@material-ui/icons'
import { PropertyCommunication } from './property-communication'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'


type PropertyCommunicationPanelProps = {
    styles: any
    property: Property
}

export const PropertyCommunicationPanel: React.FC<PropertyCommunicationPanelProps> = (props) => {
    const { styles, property } = props

    const data = useSelector((state: AppState) => state.properties[property.id]) || {}

    const communication = data.communication 
    const entries: any = communication ? communication.length : 0

    const [panelExpanded, setPanelExpanded] = useState(false)

    return (
        <Accordion className={styles.semiRounded} elevation={0} square={true} expanded={panelExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                className={styles.panelSummary}
                onClick={() => setPanelExpanded(!panelExpanded)}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>{tr(TEXT_COMMUNICATION)}</Grid>
                    <Grid item>({entries})</Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={styles.panelContent}>
                <PropertyCommunication property={property} />
            </AccordionDetails>
        </Accordion>
    )
}
