import { Grid } from '@material-ui/core'
import { AppState } from 'app/app-store'
import { Taxation } from 'models/taxation'
import { getBuildingPreviewText } from 'property-taxation-edit-page/helper-functions'
import React from 'react'
import { useSelector } from 'react-redux'
import { TEXT_BUILDING } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

type BuildingDetailsProps = {
    buildings: any
    styles: any
    taxation: Taxation
}

export const BuildingDetails: React.FC<BuildingDetailsProps> = (props) => {
    const { buildings, styles, taxation } = props
    const buildingCodesMap = useSelector((state: AppState) => state.appData.buildingCodesMap)

    return buildings.length > 0 ? (
        <>
            {buildings.map((building: any, index: number) => (
                <Grid container spacing={1} className={styles.innerContainer} key={index}>
                    <Grid item xs={12} sm={6} md={3}>
                        {tr(TEXT_BUILDING)}:
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                        {getBuildingPreviewText(building.id, taxation, buildingCodesMap)}
                    </Grid>
                </Grid>
            ))}
        </>
    ) : (
        <></>
    )
}
