import { VerifiableBuilding } from 'models/building'
import { VerifiableFloor } from 'models/floor'
import getVerifiedValuesCount from './get-verified-values-count'
import { BUILDING_VERIFIED_VALUES_TOTAL } from 'common/constants/constants'
import { DeletedBuilding, Taxation } from 'models/taxation'
import { untaxableBuildingsFilter } from 'property-page/property-helper'

export default (building: VerifiableBuilding, deletedBuildings: DeletedBuilding, taxation: Taxation): VerifiableBuilding => {
    const floors = mapFloorsVerifiedValues(building.floors)
    const isDeleted = deletedBuildings && building.id in deletedBuildings
    if (isDeleted || !untaxableBuildingsFilter(building, taxation.current.buildings, taxation.staticSettings)) {
        return {
            ...building,
            floors,
            verifiedInfo: {
                count: 0,
                total: 0,
            },
        } 
    }

    const verifiedFloorsCount = floors.reduce((prev, curr: VerifiableFloor) => prev + curr.verifiedInfo.count, 0)
    const verifiedBuildingPropsCount = getVerifiedValuesCount(building.verifiedValues)

    return {
        ...building,
        floors,
        verifiedInfo: {
            count: verifiedBuildingPropsCount + verifiedFloorsCount,
            total: floors.length + BUILDING_VERIFIED_VALUES_TOTAL,
        },
    }
}

const mapFloorsVerifiedValues = (floors: VerifiableFloor[]): VerifiableFloor[] => {
    return floors.map((f: VerifiableFloor) => ({
        ...f,
        verifiedInfo: { count: getVerifiedValuesCount(f.verifiedValues), total: 1 },
    }))
}
