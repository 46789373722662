import React, { useEffect, useState } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { RouteComponentProps, navigate } from '@reach/router'
import { Grid, Tabs, Tab, makeStyles, Theme, Button } from '@material-ui/core'
import Navigation from 'common/navigation/navigation'
import { useDispatch, useSelector } from 'react-redux'
import { ActivityLog } from 'property-page/activity-log/activity-log'
import { PropertyTaxationToolbar } from './property-taxation-edit-toolbar'
import { actionTaxationGetWithHistory, actionTaxationUpdate } from './taxation-actions'
import { AppState } from 'app/app-store'
import { getTaxationTab, isTaxationFinished, Taxation, TaxationType } from 'models/taxation'
import { PropertyTaxationEditPageForm } from './property-taxation-edit-page-form'
import { TaxationTabs } from 'common/enums/taxation'
import { PropertyTaxationComplaints } from './property-taxation-complaints'
import {
    TEXT_TAXATION,
    TEXT_COMPLAINTS,
    TEXT_OPEN_COMPLAINT,
    TEXT_NO_COMPLAINTS_HEADLINE,
    TEXT_CASE_DOCUMENTS,
    TEXT_COMMUNICATION,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { actionGetComplaints } from 'complaint-page/complaint-actions'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { actionGetTaxSettings } from 'settings-page/settings-actions'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { OfficeAdjustment } from './property-office-adjustment-tax'
import { CaseDocuments } from './property-taxation-case-documents'
import { Communication } from './communication/property-taxation-communication'
import { getComplaintFormsUrl } from './helper-functions'
import { LoadChecker } from './partials/print-taxation'
import { useQuery } from 'utils/hooks/hooks'

const useStyles = makeStyles((theme: Theme) => {
    return {
        customIndicator: {
            backgroundColor: '#ffffff',
        },
        root: {
            minHeight: 0,
        },
        hidden: {
            display: 'none',
        },
        complaintContainer: {
            fontSize: 25,
            textAlign: 'center',
            paddingTop: '15vh',
        },
        hasWidth: {
            width: 1,
        },
    }
})

export const TaxationPropertyPage: React.FC<RouteComponentProps<{ id: string; path: string }>> = (props) => {
    const { id = '', path } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
    const taxation = useSelector((state: AppState) => state.taxations[id])
    const complaints = useSelector((state: AppState) => state.complaints[id])
    
    //print helpers
    const [contentReady, setContentReady] = useState(false)
    const printing = useQuery('printing')

    useEffect(() => {
        if (!taxation) {
            dispatch(actionTaxationGetWithHistory(id))
            dispatch(actionGetComplaints(id))
        }
    }, [id, dispatch, taxation])

    useEffect(() => {
        if (!settings.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [dispatch, settings])

    useEffect(() => {
        if (contentReady && printing) {
            // @ts-ignore
            if (window.eoapi && eoapi.isEOPdf()) {
                setTimeout(() => {
                    console.log('Calling eoapi.convert() to let EO know that we are ready for conversion.')
                    // @ts-ignore
                    eoapi.convert()
                })
            } else {
                setTimeout(
                    () =>
                        console.log(
                            'We are not using EO here, but this is where eoapi.convert() would have been called to signal that we are ready to take the preview.',
                        ),
                    0,
                )
            }
        }
    }, [contentReady, printing])

    const formName = `taxation-${id}`
    const complaintFormName = `taxation-${id}-complaint`

    const handleFormSubmit = (taxation: Taxation) => {
        dispatch(actionTaxationUpdate(taxation))
    }
    const activeTab: TaxationTabs = getTaxationTab(path)
    const [currentTab, setCurrentTab] = useState(activeTab)
    const handleTabChange = (e: any, value: any) => {
        setCurrentTab(value)
        navigate(`/taxation/${taxation.id}/${value}`)
    }

    const TaxationTabsComponent = () => (
        <Tabs
            className={styles.root}
            classes={{ indicator: styles.customIndicator }}
            value={currentTab}
            onChange={handleTabChange}
            aria-label="Taxation tabs"
        >
            <Tab className={styles.root} value={TaxationTabs.taxation} label={tr(TEXT_TAXATION)} />

            <Tab className={styles.root} value={TaxationTabs.caseDocuments} label={tr(TEXT_CASE_DOCUMENTS)} />
            <Tab className={styles.root} value={TaxationTabs.communication} label={tr(TEXT_COMMUNICATION)} />

            {taxation && taxation.type !== TaxationType.OfficeAdjustment && (
                <Tab className={`${styles.root}`} value={TaxationTabs.complaints} label={tr(TEXT_COMPLAINTS)} />
            )}
        </Tabs>
    )

    const auth: any = useSelector((state: AppState) => state.auth)

    return (
        <LayoutDefault
            toolbar={taxation ? <PropertyTaxationToolbar currentTab={currentTab} taxation={taxation} /> : <></>}
            navigation={<Navigation padding={34} />}
            TaxationTabs={<TaxationTabsComponent />}
            contentPadding={[6, 3, 3, 3]}
            toolbarPaddingBottom={'0'}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        {taxation && taxation.type !== TaxationType.OfficeAdjustment && (
                            <Grid item lg={9} xs={12} className={styles.hasWidth}>
                                {taxation && currentTab === TaxationTabs.taxation && (
                                    <PropertyTaxationEditPageForm
                                        form={formName}
                                        initialValues={taxation}
                                        onSubmit={handleFormSubmit}
                                    />
                                )}
                                <Protected p={Permission.Complaints}>
                                    {taxation &&
                                        complaints &&
                                        complaints.length > 0 &&
                                        currentTab === TaxationTabs.complaints && (
                                            <PropertyTaxationComplaints
                                                form={complaintFormName}
                                                taxation={taxation}
                                                initialValues={{ complaints: complaints }}
                                                onSubmit={console.log}
                                            />
                                        )}
                                    {taxation &&
                                        !(complaints && complaints.length > 0) &&
                                        currentTab === TaxationTabs.complaints && (
                                            <div>
                                                <Grid container className={styles.complaintContainer}>
                                                    <Grid item xs={12}>
                                                        <p>{tr(TEXT_NO_COMPLAINTS_HEADLINE)}</p>
                                                    </Grid>
                                                </Grid>
                                                {isTaxationFinished(taxation) && (
                                                    <Grid container className={styles.complaintContainer}>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                variant="outlined"
                                                                href={getComplaintFormsUrl(id, auth)}
                                                                color="primary"
                                                                target="_blank"
                                                            >
                                                                {tr(TEXT_OPEN_COMPLAINT)}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </div>
                                        )}
                                </Protected>

                                {taxation && currentTab === TaxationTabs.caseDocuments && (
                                    <CaseDocuments taxation={taxation} />
                                )}

                                {taxation && currentTab === TaxationTabs.communication && (
                                    <Communication taxation={taxation} />
                                )}
                            </Grid>
                        )}

                        {taxation && taxation.type === TaxationType.OfficeAdjustment && (
                            <Grid item lg={9} xs={12} className={styles.hasWidth}>
                                <OfficeAdjustment taxation={taxation} />
                            </Grid>
                        )}

                        <Grid item md={12} lg={3}>
                            {taxation && <ActivityLog objectId={taxation.id} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {settings.loaded && taxation && printing ? <LoadChecker contentReady={contentReady} setContentReady={setContentReady} /> : <></>}
        </LayoutDefault>
    )
}
