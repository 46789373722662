import { AnyAction } from 'redux'
import { makeReducer } from 'utils'

import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import {
    ACTION_LOAD_APP_DATA,
    ACTION_LOAD_APP_DATA_SUCCESS,
    ACTION_LOAD_COMPANIES_AND_USERS,
    ACTION_LOAD_COMPANIES_AND_USERS_SUCCESS,
    ACTION_LOAD_ROLES,
    ACTION_LOAD_ROLES_SUCCESS,
    ACTION_CREATE_USER,
    ACTION_CREATE_USER_SUCCESS,
    ACTION_GET_WELCOME_USER,
    ACTION_GET_WELCOME_USER_SUCCESS,
    ACTION_WELCOME_USER_REGISTER,
    ACTION_WELCOME_USER_REGISTER_SUCCESS,
    ACTION_UPDATE_USER,
    ACTION_UPDATE_USER_SUCCESS,
    ACTION_CREATE_COMPANY,
    ACTION_CREATE_COMPANY_SUCCESS,
    ACTION_UPDATE_COMPANY,
    ACTION_UPDATE_COMPANY_SUCCESS,
    ACTION_SET_PREVIEW_PDF,
    ACTION_LOAD_TENANTS,
    ACTION_LOAD_TENANTS_SUCCESS,
} from './app-actions'
import { User } from 'models/user'
import { Company } from 'models/company'
import { WelcomeUser } from 'models/welcome-user'
import { findIndex } from 'lodash'
import { Role } from 'models/role'
import { Preview } from 'models/preview'

export type AppDataState = {
    buildingCodes: any[]
    buildingCodesMap: { [key: string]: string }
    consumptionCodes: any[]
    industrialCodes: any[]
    inspectionAreas: any[]
    plantTypes: any[]
    postalCodes: any[]
    users: { [key: string]: User }
    companies: Company[]
    roles: Role[]
    welcomeUser: WelcomeUser | null
    preview: Preview
    tenants?: any[]
}

const initialState = {
    buildingCodes: [],
    buildingCodesMap: {},
    consumptionCodes: [],
    industrialCodes: [],
    inspectionAreas: [],
    plantTypes: [],
    postalCodes: [],
    users: {},
    companies: [],
    roles: [],
    welcomeUser: null,
    preview: {
        id: undefined,
        name: undefined,
        url: undefined,
    },
    tenants: undefined,
}

type AppReducer = ActionReducer<AppDataState, AnyAction>

const loadData: AppReducer = (state) => state

const loadDataSuccess: AppReducer = (state, action) => {
    const { buildingCodes } = action.data

    const buildingCodesMap = buildingCodes.reduce((map: { [key: string]: string }, obj: any) => {
        map[obj.code] = obj.description
        return map
    }, {})
    // populate only building codes for now
    return { ...state, buildingCodes, buildingCodesMap }
}

const loadCompaniesAndUsers: AppReducer = (state) => state

const loadCompaniesAndUsersSuccess: AppReducer = (state, action) => {
    return { ...state, companies: action.data.companies, users: action.data.users }
}

const loadRoles: AppReducer = (state) => state

const loadRolesSuccess: AppReducer = (state, action) => {
    state.roles = action.roles
    return state
}

const createUser: AppReducer = (state) => state

const createUserSuccess: AppReducer = (state, action) => {
    const newUsers = action.user

    newUsers.forEach((user: User) => {
        state.users[user.id] = user
    })

    return state
}

const updateUser: AppReducer = (state) => state

const updateUserSuccess: AppReducer = (state, action) => {
    const updatedUser = action.user

    state.users[updatedUser.id] = updatedUser

    return state
}

const createCompany: AppReducer = (state) => state

const createCompanySuccess: AppReducer = (state, action) => {
    state.companies.push(action.company)
    return state
}

const updateCompany: AppReducer = (state) => state

const updateCompanySuccess: AppReducer = (state, action) => {
    const updatedCompanyIndex = findIndex(state.companies, { id: action.company.id })

    state.companies[updatedCompanyIndex] = action.company

    return state
}

const getWelcomeUser: AppReducer = (state) => state

const getWelcomeUserSuccess: AppReducer = (state, action) => {
    state.welcomeUser = action.welcomeUser
    return state
}

const welcomeUserRegister: AppReducer = (state) => state

const welcomeUserRegisterSuccess: AppReducer = (state) => state

const setPreviewPdf: AppReducer = (state, action) => {
    state.preview.id = action.fileId
    state.preview.name = action.fileName || ''
    state.preview.url = action.url || undefined
    return state
}

const loadTenants: AppReducer = (state) => ({ ...state, tenants: [] })

const loadTenantsSuccess: AppReducer = (state, action) => {
    return {
        ...state,
        tenants: action.tenants,
    }
}

const reducers: Reducers<AppDataState> = {
    [ACTION_LOAD_APP_DATA]: loadData,
    [ACTION_LOAD_APP_DATA_SUCCESS]: loadDataSuccess,
    [ACTION_LOAD_COMPANIES_AND_USERS]: loadCompaniesAndUsers,
    [ACTION_LOAD_COMPANIES_AND_USERS_SUCCESS]: loadCompaniesAndUsersSuccess,
    [ACTION_LOAD_ROLES]: loadRoles,
    [ACTION_LOAD_ROLES_SUCCESS]: loadRolesSuccess,
    [ACTION_CREATE_USER]: createUser,
    [ACTION_CREATE_USER_SUCCESS]: createUserSuccess,
    [ACTION_UPDATE_USER]: updateUser,
    [ACTION_UPDATE_USER_SUCCESS]: updateUserSuccess,
    [ACTION_CREATE_COMPANY]: createCompany,
    [ACTION_CREATE_COMPANY_SUCCESS]: createCompanySuccess,
    [ACTION_UPDATE_COMPANY]: updateCompany,
    [ACTION_UPDATE_COMPANY_SUCCESS]: updateCompanySuccess,
    [ACTION_GET_WELCOME_USER]: getWelcomeUser,
    [ACTION_GET_WELCOME_USER_SUCCESS]: getWelcomeUserSuccess,
    [ACTION_WELCOME_USER_REGISTER]: welcomeUserRegister,
    [ACTION_WELCOME_USER_REGISTER_SUCCESS]: welcomeUserRegisterSuccess,
    [ACTION_SET_PREVIEW_PDF]: setPreviewPdf,
    [ACTION_LOAD_TENANTS]: loadTenants,
    [ACTION_LOAD_TENANTS_SUCCESS]: loadTenantsSuccess,
}

export const appReducer = makeReducer<AppDataState>(reducers, initialState)
