import React, { useState } from 'react'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { Taxation, TaxationState } from 'models/taxation'
import { tr } from 'utils/translations/translate'
import { TEXT_REOPEN } from 'utils/translations/keys'
import { useDispatch } from 'react-redux'
import { actionTaxationReopen } from './taxation-actions'
import { getPropertyTitle, getPropertyAddresses, getSessionKey } from 'utils'
import PrintIcon from '@material-ui/icons/Print'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { plantsUrl } from 'services/httpService'

type PropertyTaxationToolbarProps = {
    taxation: Taxation
    currentTab: string
}

const useStyles = makeStyles(() => ({
    spinnerIcon: {
        height: '24px !important',
        width: '24px !important',
        color: '#fff',
    },
}))
export const PropertyTaxationToolbar: React.FC<PropertyTaxationToolbarProps> = (props) => {
    const { taxation, currentTab } = props
    const [isLoading, setIsLoading] = useState(false)
    const property = taxation.current?.property
    const dispatch = useDispatch()
    const taxationState = taxation.state
    const reopenTaxation =
        taxationState === TaxationState.TribunalDeclined ||
        taxationState === TaxationState.TribunalReadyForFinishing ||
        taxationState === TaxationState.TaxationFinished
    const styles = useStyles()
    const printPage = () => {
        fetch(plantsUrl('/tax/print'), {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authentication: `session ${getSessionKey()}`,
            },
            body: JSON.stringify({ url: window.location.href }),
        })
            .then((response: any) => response.blob())
            .then((myBlob) => {
                const url = URL.createObjectURL(myBlob)
                const link = document.createElement('a')
                link.href = url
                link.target = '_blank'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                setIsLoading(false)
            })
    }

    const handlePrint = () => {
        setIsLoading(true)
        printPage()
    }
    const title = getPropertyTitle(property, getPropertyAddresses(taxation.current.housingUnits))
    const handleTaxationReopen = () => {
        dispatch(actionTaxationReopen({ ...taxation, state: TaxationState.TaxationStarted }))
    }

    return (
        <TitleToolbar title={title}>
            {taxation && reopenTaxation && (
                <Button variant="outlined" color="inherit" type="button" onClick={handleTaxationReopen}>
                    {tr(TEXT_REOPEN)}
                </Button>
            )}
            {currentTab === 'edit' &&
                (!isLoading ? (
                    <Button onClick={handlePrint} color="inherit">
                        <PrintIcon />
                    </Button>
                ) : (
                    <Button disabled color="inherit">
                        <CircularProgress className={styles.spinnerIcon} />
                    </Button>
                ))}
        </TitleToolbar>
    )
}
