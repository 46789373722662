import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { RouteComponentProps } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { actionTaxationGet, actionTaxationUpdate } from 'property-taxation-edit-page/taxation-actions'
import { AppState } from 'app/app-store'
import { TribunalTaxationDetailsToolbar } from './tribunal-taxation-details-toolbar'
import { TribunalGroupState } from 'tribunal-page/tribunal-group-page/tribunal-group-state.enum'
import { TribunalTaxationDetails } from './tribunal-taxation-details/tribunal-taxation-details'
import { Taxation } from 'models/taxation'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { actionGetTaxSettings } from 'settings-page/settings-actions'

export const TribunalTaxationDetailsPage: React.FC<RouteComponentProps<{
    groupState: TribunalGroupState
    groupId: string
    taxationId: string
    location: any
}>> = props => {
    const { taxationId = '', groupId = '', groupState = TribunalGroupState.Initial } = props
    const dispatch = useDispatch()
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
        
    useEffect(() => {
        dispatch(actionTaxationGet(taxationId))
    }, [taxationId, dispatch])

    useEffect(() => {
        if (!settings.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [settings, dispatch])

    const taxation = useSelector((state: AppState) => state.taxations[taxationId])

    const formName = `taxation-${taxationId}`

    const handleFormSubmit = (taxation: Taxation) => {
        dispatch(actionTaxationUpdate(taxation))
    }

    return (
        <LayoutDefault
            toolbar={
                taxation && (
                    <TribunalTaxationDetailsToolbar taxation={taxation} groupState={groupState} groupId={groupId} />
                )
            }
            navigation={<Navigation />}
        >
            {taxation && (
                <TribunalTaxationDetails form={formName} initialValues={taxation} onSubmit={handleFormSubmit} />
            )}
        </LayoutDefault>
    )
}
