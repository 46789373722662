import { languageReducer, LanguageState } from './language/language-reducer'
import { combineReducers } from 'redux'
import { settingsGroupsReducer } from './groups/groups-reducer'
import { SettingsGroup } from './groups/settings-group.type'

export type SettingsState = {
    language: LanguageState
    groups: SettingsGroup[]
}

export const settingsReducer = combineReducers({
    language: languageReducer,
    groups: settingsGroupsReducer,
})
