import React, { useState } from 'react'
import { Grid, makeStyles, TextField, Theme } from '@material-ui/core'
import { Taxation } from 'models/taxation'
import { useDispatch } from 'react-redux'
import { actionLoadCommunication, actionLoadCorrespondance, actionSendCommunication } from '../taxation-actions'
import { CommunicationModal } from './communication-modal'
import { filterCommunication, getNewCommunicationData, getOwnerContacts } from './helper'
import { useUser } from 'auth/auth-selectors'
import { tr } from 'utils/translations/translate'
import { TEXT_SEARCH_FOR_LETTERS } from 'utils/translations/keys'
import { CommunicationEntry, Controls } from './partials'

const useStyles = makeStyles((theme: Theme) => {
    return {
        controls: {
            marginBottom: 10,
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                margin: '0 auto'
            }
        },
        button: {
            marginRight: 10,
            backgroundColor: 'white',
            [theme.breakpoints.down('sm')]: {
                marginBottom: 10,
                marginRight: 0
            }
        },
        buttonIcon: {
            marginRight: 10
        },
        commEntry: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            height: 50,
            width: '100%',
            marginBottom: 5,
            boxSizing:'border-box',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
                height: 'auto',
                padding: '8px'
            }
        },
        notLinked: {
            opacity: 0.5
        },
        redBall: {
            backgroundColor: 'red',
            width: 10,
            height: 10,
            borderRadius: 5,
            flexShrink: 0,
            marginRight: 5
        },
        commEntryIndicator: {
            flex: '0 0 50px',
            alignSelf: 'stretch',
            boxSizing: 'border-box',
            border: `2px solid ${theme.palette.primary.light}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                flex: '0'
            }
        },
        commEntryIndicatorIncoming: {
            backgroundColor: theme.palette.primary.light
        },
        commEntryIcon: {
            color: theme.palette.primary.light
        },
        commEntryIconIncoming: {
            color: 'white'
        },
        commEntrySender: {
            whiteSpace: 'nowrap',
            flexShrink: 0,
            margin: '0 30px 0 10px',
        },
        commEntrySubject: {
            fontWeight: 600,
            whiteSpace: 'nowrap',
            marginRight: 30,
            [theme.breakpoints.down('sm')]: {
                whiteSpace: 'normal',
            }
        },
        copy: {
            color: 'red',
            border: '2px solid red',
            padding: '0 7px',
            fontWeight: 600,
            marginRight: 15
        },
        commEntryBody: {
            flex: '1 1 100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        commEntryTimestamp: {
            margin: '0 10px',
            whiteSpace: 'nowrap',
            flex: '1 0 100px',
            textAlign: 'right'
        },
        commEntryAttachmentIcon: {
            margin: '0 10px',
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                margin: '0',
            }
        },
        sendingProgress: {
            marginBottom: 20
        },
        sendingProgressText: {
            textAlign: 'center'
        },
        filterTextField: {
            marginBottom: 10
        }
    }
})

export const Communication: React.FC<{
    taxation: Taxation
}> = (props) => {
    const { taxation } = props
    const classes = useStyles()
    const dispatch = useDispatch()
    const communication = taxation.communication
    const correspondance = taxation.correspondance
    const entries: any = communication && communication.length ? communication : []
    const [currentEntry, setCurrentEntry] = useState(undefined)
    const [responseMode, setResponseMode] = useState<string | undefined>(undefined)
    const [newEntry, setNewEntry] = useState<any | undefined>(undefined)
    const user = useUser()

    const newCommunication = (type: string) => {
        const recipients = getOwnerContacts(taxation.current.owners, type)
        setResponseMode(type)
        setNewEntry(getNewCommunicationData({type: type, recipients: recipients}, user, 'new'))
    }

    const respond = (type: string) => {
        setResponseMode(type)
    }

    const cancelNewEntry = () => {
        setResponseMode(undefined)
        setNewEntry(undefined)
    }

    const closeAll = () => {
        setResponseMode(undefined)
        setNewEntry(undefined)
        setCurrentEntry(undefined)
    }

    const cancelEntry = () => {
        setCurrentEntry(undefined)
        setResponseMode(undefined)
    }

    React.useEffect(() => {
        if (!communication) {
            dispatch(actionLoadCommunication(taxation.id))
        }
    }, [taxation.id, dispatch, communication])

    React.useEffect(() => {
        if (!correspondance) {
            dispatch(actionLoadCorrespondance(taxation.id))
        }
    }, [taxation.id, dispatch, correspondance])

    const [filter, setFilter] = useState('');

    const filteredEntries = filterCommunication(entries, filter);
    
    return (
        <Grid container>
            <Controls classes={classes} onCreate={newCommunication}/>
            <TextField fullWidth className={classes.filterTextField} margin='dense' variant='outlined' label={tr(TEXT_SEARCH_FOR_LETTERS)} value={filter} onChange={e => setFilter(e.target.value)} />
            {filteredEntries.filter((e: any) => e.type !== 'document').map((entry: any, i: number) =>
                <CommunicationEntry key={i} classes={classes} entry={entry} onClick={() => setCurrentEntry(entry)}/>
            )}
            {currentEntry && !responseMode &&
                <CommunicationModal entry={currentEntry} taxation={taxation} respond={respond} close={cancelEntry} cancel={cancelEntry} sendAction={actionSendCommunication} />
            }
            {responseMode && currentEntry &&
                <CommunicationModal entry={getNewCommunicationData(currentEntry, user, responseMode)} taxation={taxation} close={cancelEntry} cancel={cancelNewEntry} responseMode={responseMode} sendAction={actionSendCommunication} />
            }
            {responseMode && newEntry &&
                <CommunicationModal entry={newEntry} taxation={taxation} close={closeAll} cancel={cancelNewEntry} responseMode={responseMode} sendAction={actionSendCommunication} />
            }
        </Grid>
    );
}
