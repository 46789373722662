import { SelectOptions } from 'shared-components/src/cubit-inputs/cubit-form-field.types'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'

export const getSelectYearOptions = (max: Number): SelectOptions[] => {
    let options = []
    
      for (let i = 1; i <= max; i++) {
          options.push({ label: `${i} ${tr(t.TEXT_YEARS)}`, value: i })
      }

    return options
}
