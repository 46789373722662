import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import DocumentTemplate from './document-template'
import { makeStyles, Paper, Button, CircularProgress, IconButton, Grid, Box } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { dummyData } from './dummyData'
import './settings.css'
import {
    byProperty,
    dummywkparams,
    staticTemplateElements,
    textFromTextTemplates,
} from './template-document-helper'
import { arrayPush, arrayRemove, change, Field } from 'redux-form'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { DocumentConstants, TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { PhotoCamera } from '@material-ui/icons'
import Dropzone from 'react-dropzone'
import { actionDeleteTemplate, actionUploadTemplateImage } from 'settings-page/settings-actions'

const dragStart = (index: number, e: any) => {
    const elementPosition = e.target.getBoundingClientRect()
    const xofset = e.clientX - elementPosition.left
    const yofset = e.clientY - elementPosition.top
    const data = `${DocumentConstants.moved},${index},${xofset},${yofset}`
    e.dataTransfer.setData('text/plain', data)
    e.target.classList.add('dragging')
}
// actionType, label, id, width, height, type
const sidebarItemDragStart = (text: string, e: any) => {
    e.dataTransfer.setData('text/plain', text)
    e.target.classList.add('dragging')
}

const dragEnd = (e: any) => {
    e.target.classList.remove('dragging')
}

const dragOver = (e: any) => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
    return false
}

const useStyles = makeStyles((theme) => ({
    main: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            marginTop: 50,
        }
    },
    mainTemplate: {
        width: 900,
    },
    sideControls: {
        width: 400,
        height: 'calc(100vh - 84px)',
        right: 0,
        top: 0,
        overflowY: 'auto',
    },
    sideControlPaper: {
        padding: 10,
    },
    fileSelectorInput: {
        display: 'none',
    },
    cameraIcon: {
        marginRight: theme.spacing(1),
    },
    possibleImageText: {
        display: 'flex',
        alignItems: 'center',
    },
    possibleImageLoader: {
        marginRight: 10,
    },
    spacer: {
        flexGrow: 1,
    },
    warning: {
        backgroundColor: '#f55',
        color: '#fff',
        transition: 'opacity 0.2s linear',
        marginTop: 10,
        '&:hover': {
            opacity: 0.7,
            backgroundColor: '#f55',
        }
    }
}))

export const EditTemplate = ({ texts, template, templates, images }: any) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [selectedElement, setSelectedElement] = useState({name: 'elements', index: -1})

    const possibleelements = staticTemplateElements
    let possibleelementtexts = texts.filter((t: any) => t.type === 'templatetext').sort(byProperty('name'))

    let templatetexttemplates = [...template.elements, ...template.footerElements]
        .filter((e) => e.type === 'text')
        .map((e) => {
            let text = texts.find((t: any) => t.id === e.id)
            return { ...text, state: text.state }
        })
    let templateTexts = textFromTextTemplates(templatetexttemplates, dummyData, dummyData.taxation)

    const handleElementDropped = (pixelsPerMm: number, isFooter: boolean, e: any) => {
        const data = e.dataTransfer.getData('text/plain').split(',')
        const documentElement: any = document.getElementById(isFooter ? DocumentConstants.footercanvas : DocumentConstants.documentcanvas)
        const canvasPosition: any = documentElement !== null ? documentElement.getBoundingClientRect() : {left: 0, top: 0}
        let actualXpixels = e.clientX - canvasPosition.left
        let actualYpixels = e.clientY - canvasPosition.top
        const elementArray = isFooter ? TaxFormFieldNames.footerElements : TaxFormFieldNames.elements
        let actualX = actualXpixels / pixelsPerMm
        let actualY = actualYpixels / pixelsPerMm

        if (data[0] === DocumentConstants.added) {
            const objectToInsert = {
                name: data[1],
                left: actualX,
                top: actualY,
                id: data[2],
                width: data[3],
                height: data[4],
                type: data[5]
            }
           dispatch(arrayPush(TaxFormName.TemplateDocumentForm, elementArray, objectToInsert))
        }

        if (data[0] === DocumentConstants.moved) {
            actualXpixels -= Number(data[2])
            actualYpixels -= Number(data[3])
            actualX = actualXpixels / pixelsPerMm
            actualY = actualYpixels / pixelsPerMm
            const index = Number(data[1])

            const left = `${elementArray}[${index}].${DocumentConstants.left}`
            const top = `${elementArray}[${index}].${DocumentConstants.top}`
            dispatch(change(TaxFormName.TemplateDocumentForm, left, actualX))
            dispatch(change(TaxFormName.TemplateDocumentForm, top, actualY))
        }
    }

    const handleSelectElement = (index: number, isFooter: boolean) => {
        if (isFooter) {
            setSelectedElement({
                name: TaxFormFieldNames.footerElements, 
                index: index 
            })
        } else {
            setSelectedElement({
                name: TaxFormFieldNames.elements, 
                index: index 
            })
        }
    }

    const handleDeleteElement = (itemInfo: any) => {
        setSelectedElement({name: '', index: -1})
        dispatch(arrayRemove(TaxFormName.TemplateDocumentForm, itemInfo.name, itemInfo.index))
    }

    const handleFileDrop = (acceptedFiles: any) => {
        dispatch(actionUploadTemplateImage(acceptedFiles[0]))
    }

    const handleDeleteTemplateImage = (image: any) => {
        dispatch(actionDeleteTemplate(image));
    }

    return (
            <div className={classnames('entity', classes.main)}>
                <div></div>
                <div className={classes.mainTemplate}>
                    <DocumentTemplate
                        texts={templateTexts}
                        canvasWidth={900}
                        template={template}
                        data={dummyData}
                        dragStart={dragStart}
                        dragEnd={dragEnd}
                        dragOver={dragOver}
                        elementDropped={handleElementDropped}
                        selectElement={handleSelectElement}
                        selectedElement={selectedElement}
                    />
                    <div className="canvas a4" style={{ margin: '5px 0', height: 200, textAlign: 'center' }}>
                        <p style={{ lineHeight: '150px', fontWeight: 600, fontFamily: 'Open Sans' }}>
                            {tr(t.TEXT_LETTER_CONTENT)}
                        </p>
                    </div>
                    <DocumentTemplate
                        texts={templateTexts}
                        isFooter
                        canvasWidth={900}
                        template={template}
                        wkparams={dummywkparams}
                        data={dummyData}
                        dragStart={dragStart}
                        dragEnd={dragEnd}
                        dragOver={dragOver}
                        elementDropped={handleElementDropped}
                        selectElement={handleSelectElement}
                        selectedElement={selectedElement}
                    />
                </div>
                <div className={classes.sideControls}>
                    <Paper className={classes.sideControlPaper}>
                        <Field
                            name={TaxFormFieldNames.height} 
                            component={CubitTextFieldAdapter} 
                            label={tr(t.TEXT_HEIGHT_IN_MM)}
                        />
                        <Field
                            name={TaxFormFieldNames.footerHeight} 
                            component={CubitTextFieldAdapter} 
                            label={tr(t.TEXT_FOOTER_HEIGHT_IN_MM)}
                        />

                        {selectedElement.index > -1 ? (
                            <div>
                                <h3 style={{ fontWeight: 500 }}>{template[selectedElement.name][selectedElement.index].name}</h3>
                                <Field
                                    name={`${selectedElement.name}[${selectedElement.index}].${TaxFormFieldNames.top}`} 
                                    component={CubitTextFieldAdapter} 
                                    label={tr(t.TEXT_OFFSET_TOP_IN_MM)}
                                />
                                <Field
                                    name={`${selectedElement.name}[${selectedElement.index}].${TaxFormFieldNames.left}`} 
                                    component={CubitTextFieldAdapter}
                                    label={tr(t.TEXT_OFFSET_LEFT_IN_MM)}
                                />
                                <Field
                                    name={`${selectedElement.name}[${selectedElement.index}].${TaxFormFieldNames.width}`} 
                                    component={CubitTextFieldAdapter}
                                    label={tr(t.TEXT_WIDTH_IN_MM)}
                                />
                                <Field
                                    name={`${selectedElement.name}[${selectedElement.index}].${TaxFormFieldNames.height}`} 
                                    component={CubitTextFieldAdapter}
                                    label={tr(t.TEXT_HEIGHT_IN_MM)}
                                />
                                <div className="controls">
                                    <Button
                                        className={classes.warning}
                                        onClick={(_) => handleDeleteElement(selectedElement)}
                                    >
                                        {tr(t.TEXT_DELETE_ELEMENT)}
                                    </Button>
                                </div>
                            </div>
                        ) : null}

                        <h3 style={{ fontWeight: 500 }}>{tr(t.TEXT_DESIGN_ELEMENT)}</h3>
                        {possibleelements.map((element: any, i: number) => {
                            const data = `${DocumentConstants.added},${element.name},${element.id},40,20,${element.type}`
                            return (
                                <div
                                    key={i}
                                    className="unassignedelement"
                                    draggable={true}
                                    onDragStart={(e) => sidebarItemDragStart(data, e)}
                                >
                                    <div className="text">
                                        <div className="main">{element.label}</div>
                                    </div>
                                </div>
                            )
                        })}
                        <div style={{ height: '20px' }}></div>
                        <h3 style={{ fontWeight: 500 }}>{tr(t.TEXT_TEMPLATE_TEXT)}</h3>
                        {possibleelementtexts.map((text: any, i: number) => {
                            const data = `${DocumentConstants.added},${text.name},${text.id},40,20,${DocumentConstants.text}`
                            return (
                                <div
                                    key={i}
                                    className="unassignedelement"
                                    draggable={true}
                                    onDragStart={(e) => sidebarItemDragStart(data, e)}
                                >
                                    <div className="text">
                                        <div className="main">{text.name}</div>
                                    </div>
                                </div>
                            )
                        })}
                        <h3 style={{ fontWeight: 500 }}>{tr(t.TEXT_IMAGES)}</h3>
                        {images.map((image: any, i: number) => {
                            const inUse = templates.some(
                                (t: any) =>
                                    t.elements.some((e: any) => e.id === image.blobId) ||
                                    t.footerElements.some((e: any) => e.id === image.blobId),
                            )
                            const data = `${DocumentConstants.added},${image.name},${image.blobId},40,15,${DocumentConstants.image}`
                            return (
                                <div
                                    key={i}
                                    className="unassignedelement"
                                    draggable={Boolean(image.blobId)}
                                    onDragStart={(e) => sidebarItemDragStart(data, e)}
                                >
                                    <div className={classes.possibleImageText}>
                                        {!image.blobId && (
                                            <CircularProgress
                                                className={classes.possibleImageLoader}
                                                size={12}
                                                color="primary"
                                            />
                                        )}
                                        <div className="main">{image.name}</div>
                                        <div className={classes.spacer}></div>
                                        {!inUse && (
                                            <IconButton size="small" onClick={() => handleDeleteTemplateImage(image)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                        <Dropzone
                            accept="image/*"
                            onDrop={(files) => {
                                handleFileDrop(files)
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <Grid container direction="column" justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <Box {...getRootProps()}>
                                            <Button variant="outlined" color="primary">
                                                <input {...getInputProps()} />
                                                <PhotoCamera className={classes.cameraIcon} />
                                                {tr(t.TEXT_UPLOAD_IMAGE)}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Dropzone>
                    </Paper>
                </div>
            </div>
    )
}
