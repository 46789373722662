import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { ApplicationRoutes } from './app-routes'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'
import { useAuth, useUser } from '../auth/auth-selectors'
import { useSelector, useDispatch } from 'react-redux'
import { actionAuthInit } from '../auth/auth-actions'
import { AppState } from 'app/app-store'
import { CubitSnackbar } from 'common/cubit-snackbar/cubit-snackbar'
import { ApplicationRoutesPublic } from './app-routes-public'
import { PreviewWindow } from 'common/pdf-preview/preview'
import { useWindowQuery } from 'utils/hooks/hooks'
import { createTheme } from '@material-ui/core'
import { setSessionKey } from 'utils'
// @ts-ignore
import FreshChat from 'react-freshchat'


const App: React.FC = () => {
    const dispatch = useDispatch()

    const sessionKey = useWindowQuery('sessionKey')
    const sso = useWindowQuery('sso')
    const printing = useWindowQuery('printing')
    const isTest = useSelector((state: AppState) => state.auth.tenant && !state.auth.tenant.isProduction)
    const appTheme = isTest
        ? {
            palette: {
                primary: {
                    light: '#579d07',
                    main: '#FF006E',
                    dark: '#315804',
                    contrastText: '#fff',
                },
                secondary: {
                    light: '#579d07',
                    main: '#FF006E',
                    dark: '#315804',
                    contrastText: '#fff',
                },
            },
        }
        : {
            palette: {
                primary: {
                    light: '#579d07',
                    main: '#417505',
                    dark: '#315804',
                    contrastText: '#fff',
                },
                secondary: {
                    light: '#579d07',
                    main: '#417505',
                    dark: '#315804',
                    contrastText: '#fff',
                },
            },
        }

    useEffect(() => {
        //sso for support
        if (sso) {
            setSessionKey(sso)
            dispatch(actionAuthInit(true))
        }
    }, [dispatch, sso])

    useEffect(() => {
        //normal login process
        if (!sessionKey && !sso) {
            dispatch(actionAuthInit())
        }
    }, [dispatch, sessionKey, sso])

    useEffect(() => {
        //taxation print should log in with the sessionKey provided to get proper data
        if (sessionKey && printing) {
            setSessionKey(sessionKey)
            dispatch(actionAuthInit())
        }
    }, [dispatch, sessionKey, printing])

    // set html element tag lang attribute
    const language = useSelector((state: AppState) => state.settings.language)

    document.documentElement.lang = language

    const auth = useAuth()
    const user = useUser()

    const userPermissions = user ? user.tags : []

    return (
        <ThemeProvider theme={createTheme(appTheme)}>
            <MuiPickersUtilsProvider utils={LuxonUtils} locale={language}>
                {/* in case we came in from pdf generating service using sessionKey - load limited data */}
                {sessionKey && !printing ? (
                    <Router style={{ height: '100%' }}>
                        {ApplicationRoutesPublic.map((route, index) =>
                            route({
                                key: index,
                            }),
                        )}
                    </Router>
                ) : auth.isInitialized ? (
                    auth.sessionKey ? (
                        <>
                            <Router style={{ height: '100%' }}>
                                {ApplicationRoutes.map((route, index) =>
                                    route({
                                        key: index,
                                        userPermissions,
                                    }),
                                )}
                            </Router>
                            <CubitSnackbar></CubitSnackbar>
                            <PreviewWindow />
                            {user && <FreshChat
                                token={process.env.REACT_APP_FRESHCHAT_TOKEN}
                                firstName={`${user?.name} (${auth.tenant?.name})`}
                                email={user?.email}
                                onInit={(widget: any) => {
                                    widget.user.setProperties({
                                        email: user?.email,
                                        name: `${user?.name} (${auth.tenant?.name})`,
                                        externalId: user?.id,
                                    })
                                }}
                            />}
                        </>
                    ) : (
                        <Router style={{ height: '100%' }}>
                            {ApplicationRoutesPublic.map((route, index) =>
                                route({
                                    key: index,
                                }),
                            )}
                        </Router>
                    )
                ) : (
                    <>
                        <div className="index-spinner-container">
                            <svg
                                className="index-svg-circle-spinner  index-svg-circle-spinner-static"
                                viewBox="0 0 64 64"
                            >
                                <circle cx="32" cy="32" r="20" className="index-spinner-path"></circle>
                                <circle cx="32" cy="32" r="20" className="index-loading-spinner"></circle>
                            </svg>
                        </div>
                    </>
                )}
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    )
}

export default App
