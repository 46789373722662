import { makeStyles, Theme } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    suggestDiv: {
        padding: '5px 16px',
        cursor: 'pointer',
        ['&:hover']: {
            background: '#eee',
        },
    },
    highlightedSuggestion: {
        background: '#eee',
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        ['&:hover']: {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    inputRoot: {
        color: 'inherit',
        flexWrap: 'wrap',
        ['&.Mui-focused']: {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
    },
    inputInput: {
        padding: theme.spacing(2, 1, 2, 2),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 300,
            '&:focus': {
                width: 550,
            },
        },
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        maxHeight: '70vh',
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        overflowY: 'auto',
    },
    chipLabel: {
        maxWidth: '150px',
        paddingLeft: 0,
        paddingRight: 0,
        overflow: 'hidden',
        margin: theme.spacing(0, 1.5, 0, 1.5),
    },
    chip: {
        margin: theme.spacing(0.5, 0.25, 0.5, 1),
    },

    suggestionGroupTitle: {
        padding: theme.spacing(2),
    },
}))
