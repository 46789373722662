import React from 'react'
import {
    Grid,
    makeStyles,
    Paper,
    Theme,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { RichEditorField } from 'common/editor/editor'

const useStyles = makeStyles((theme: Theme) => {
    return {
        button: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        header: {
            fontWeight: 600,
        },
        settingsLabel: {
            fontWeight: 600,
            fontSize: 17,
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
        },
        sidebar: {
            marginLeft: 20,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginTop: 20,
            },
        },
        formContainer: {
            [theme.breakpoints.down('xs')]: {
                marginTop: 60
            },
        },
    }
})

type TemplateFormProps = {
    toolbarHidden?: boolean
    Sidebar?: any,
    TitleBar?: any
}

const Form: React.FC<InjectedFormProps<any, TemplateFormProps> &
TemplateFormProps> = props => {
    const { 
        handleSubmit, 
        toolbarHidden = false,
        Sidebar = null,
        TitleBar = null
    } = props

    const stateRef = useSelector((state: AppState) => state)
    const classes = useStyles()

    return (
        <form onSubmit={handleSubmit}>
            <Grid container className={classes.formContainer}>
                <Grid item md={true} xs={12}>
                    {TitleBar && <TitleBar />}
                    <Paper elevation={0}>
                        <Field
                            name={TaxFormFieldNames.body}
                            component={RichEditorField}
                            classes={classes}
                            stateRef={stateRef}
                            toolbarHidden={toolbarHidden}
                        />
                    </Paper>
                </Grid>
                {Sidebar && 
                    <Grid item md={3} sm={12} className={classes.sidebar}>
                        <Sidebar />
                    </Grid>
                }
            </Grid>
        </form>
    )
}

interface FormProps {}

export const TemplateForm = reduxForm<{}, FormProps & TemplateFormProps>({
    form: TaxFormName.TemplateForm,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
