import { AnyAction } from 'redux'
import { makeReducer } from 'utils'
import { Reducers } from 'shared-components/src/utils/make-reducer'
import * as actions from './attribute-actions'
import { Attributes, attributeKeys, AttributesKey } from './attribute'

export type AttributeState = Attributes

type AttributeReducer = (state: AttributeState, action: AnyAction) => AttributeState

const initialState: AttributeState = {
    [attributeKeys.PROPERTY]: [],
    [attributeKeys.BUILDING]: [],
    [attributeKeys.HOUSING_UNIT]: []
}

const getAttributes: AttributeReducer = (state: AttributeState) => state

const getAttributesSuccess: AttributeReducer = (state, action) => {
    state = {
        ...state,
        [action.attType]: action.attributes
    }

    return state
}

const createAttributes: AttributeReducer = (state: AttributeState) => state

const createAttributesSuccess: AttributeReducer = (state, action) => {
    const currentAtts = state[action.attType as AttributesKey]
    if (currentAtts.includes(action.attribute)) {
        return state
    }

    state = {
        ...state,
        [action.attType]: [...currentAtts, action.attribute]
    }

    return state
}

const deleteAttributes: AttributeReducer = (state: AttributeState) => state

const deleteAttributesSuccess: AttributeReducer = (state, action) => {
    const currentAtts = state[action.attType as AttributesKey]
    const updatedAtts = currentAtts.filter(a => a !== action.attribute)

    state = {
        ...state,
        [action.attType]: updatedAtts
    }

    return state
}

const reducers: Reducers<AttributeState> = {
    [actions.ACTION_GET_ATTRIBUTES]: getAttributes,
    [actions.ACTION_GET_ATTRIBUTES_SUCCESS]: getAttributesSuccess,
    [actions.ACTION_CREATE_ATTRIBUTE]: createAttributes,
    [actions.ACTION_CREATE_ATTRIBUTE_SUCCESS]: createAttributesSuccess,
    [actions.ACTION_DELETE_ATTRIBUTE]: deleteAttributes,
    [actions.ACTION_DELETE_ATTRIBUTE_SUCCESS]: deleteAttributesSuccess
}

export const attributeReducer = makeReducer(reducers, initialState)
