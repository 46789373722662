import React, { useState, useEffect } from 'react'
import { Dialog, Button, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_DECLINE_TAXATION, TEXT_CANCEL } from 'utils/translations/keys'
import { useDispatch, useSelector } from 'react-redux'
import { actionTribunalGroupsGetFull, actionTribunalTaxationsMarkAsDeclined } from 'tribunal-page/tribunal-actions'
import { submit } from 'redux-form'
import { isEmpty } from 'lodash'
import { AppState } from 'app/app-store'
import {
    TribunalTaxationDeclineFormProps,
    TribunalTaxationDeclineForm,
    TribunalTaxationDeclineFormName,
} from './tribunal-taxation-decline-form'
import { PageReference } from 'tribunal-page/page-reference.enum'

type TribunalTaxationDeclineProps = {
    /** Current group id */
    groupId: string
    taxationsIds: string[]
    pageReference: PageReference
    disabled?: boolean
}

export const TribunalTaxationDecline: React.FC<TribunalTaxationDeclineProps> = props => {
    const { groupId, taxationsIds, pageReference, disabled = false } = props
    const dispatch = useDispatch()
    const [declinedReasonDialogOpen, setDeclinedReasonDialogOpen] = useState(false)

    const groups = useSelector((state: AppState) => state.tribunal.groups)
    const taxationsMap = useSelector((state: AppState) => state.tribunal.taxations)

    useEffect(() => {
        if (groups.length === 0 || isEmpty(taxationsMap)) {
            dispatch(actionTribunalGroupsGetFull())
        }
    }, [dispatch, groups, taxationsMap])

    const handleClose = () => {
        setDeclinedReasonDialogOpen(false)
    }

    const handleOk = () => {
        dispatch(submit(TribunalTaxationDeclineFormName))
    }

    const handleSubmit = (formValue: TribunalTaxationDeclineFormProps) => {
        setDeclinedReasonDialogOpen(false)
        dispatch(actionTribunalTaxationsMarkAsDeclined(groupId, taxationsIds, formValue.reason, pageReference))
    }

    return (
        <>
            <Button 
                variant="text" 
                color="inherit" 
                type="button" 
                onClick={() => setDeclinedReasonDialogOpen(true)}
                disabled={disabled}
            >
                {tr(TEXT_DECLINE_TAXATION)}
            </Button>

            <Dialog onClose={handleClose} open={declinedReasonDialogOpen}>
                <DialogTitle>{tr(TEXT_DECLINE_TAXATION)}</DialogTitle>
                <DialogContent style={{ width: '480px' }}>
                    <TribunalTaxationDeclineForm initialValues={{ reason: '' }} onSubmit={handleSubmit} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit">
                        {tr(TEXT_CANCEL)}
                    </Button>
                    <Button onClick={handleOk} color="primary" autoFocus>
                        {tr(TEXT_DECLINE_TAXATION)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
