import React, { useEffect } from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../app/app-store'
import { navigate, RouteComponentProps } from '@reach/router'
import { actionGetFailedCorrespondence } from './dashboard-actions'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'
import { CubitTableColumn } from 'shared-components/src/cubit-table/cubit-table.types'
import { FailedCorrespondenceDetails } from 'models/dashboard'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { DashboardContextBar } from './dashboard-context-bar'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContainer: {
            flexBasis: '25%',
            boxSizing: 'border-box',
            padding: 10,
            flexGrow: 1,
            minWidth: 350,
        },
        panelHeader: {
            padding: '3px 10px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        headerText: {
            fontSize: '1.1rem',
        },
    }
})

export const FailedCorrespondenceTableName = 'failedCorrespondence'

export const FailedCorrespondence: React.FC<RouteComponentProps> = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const failedCorrespondence = useSelector((state: AppState) => state.dashboard.failedCorrespondence)

    useEffect(() => {
        if (!failedCorrespondence) {
            dispatch(actionGetFailedCorrespondence())
        }
    }, [dispatch, failedCorrespondence])

    const columns: CubitTableColumn[] = [
        {
            headerLabel: tr(t.TEXT_ADDRESS),
            key: 'matrikkelAddress',
        },
        {
            headerLabel: tr(t.TEXT_INSECTION_DATE),
            key: 'timestamp',
        },
        {
            headerLabel: tr(t.TEXT_OWNERS),
            key: 'receiver',
        },
        {
            headerLabel: tr(t.TEXT_ISSUE),
            key: 'issue',
        },
    ]

    const handleRowClick = (row: FailedCorrespondenceDetails) => {
        navigate(`/property/${row.id}`)
    }

    return (
        <LayoutDefault 
        navigation={<Navigation />} 
        pageTitle={tr(t.TEXT_DASHBOARD)}
        contextbar={<DashboardContextBar />}
        >
            <div className={classes.panelContainer}>
                <div>
                    <div className={classes.panelHeader}>
                        <Typography className={classes.headerText} variant="button">
                            {tr(t.TEXT_FAILED_CORRESPONDENCE)}
                        </Typography>
                    </div>
                    <CubitTable
                        sorting
                        columns={columns}
                        data={failedCorrespondence || []}
                        name={FailedCorrespondenceTableName}
                        selectable
                        onRowClick={handleRowClick}
                        style={{ tableLayout: 'fixed' }} />
                </div>
            </div>
        </LayoutDefault>
    )
}
