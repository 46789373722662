import React from 'react'
import classnames from 'classnames'
import { makeStyles, ButtonBase, Typography, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    panelElement: {
        display: 'flex',
        backgroundColor: 'white',
        color: '#404040',
        width: '100%',
        height: 60,
        marginBottom: 2,
    },
    counter: {
        flexBasis: 56,
        textAlign: 'right',
    },
    counterText: {
        fontSize: '1.5rem',
    },
    description: {
        flexGrow: 1,
        marginLeft: 15,
        textAlign: 'left',
    },
    icon: {
        width: 24,
        marginRight: 20,
        color: '#757575',
    },
    alertArea: {
        flexBasis: 4,
        alignSelf: 'stretch',
    },
    alertActive: {
        backgroundColor: '#d0021b',
    },
}))

type PanelElementProps = {
    counter: number
    text: string
    icon: React.ReactNode
    alert?: boolean
    action: () => void
}
export const PanelElement = (props: PanelElementProps) => {
    const { counter, text, icon, alert, action } = props
    const classes = useStyles()
    return (
        <ButtonBase className={classes.panelElement} onClick={action}>
            <div className={classnames(classes.alertArea, alert && classes.alertActive)}></div>
            <div className={classes.counter}>
                <Typography className={classes.counterText}>{counter}</Typography>
            </div>
            <div className={classes.description}>{text}</div>
            <div className={classes.icon}>{icon}</div>
        </ButtonBase>
    )
}
