import { Grid, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { getFlatReductionValue, isPropertyUsingFactors, isUsingPropertyFactors, Taxation } from 'models/taxation'
import { DateTime } from 'luxon'
import classnames from 'classnames'
import { formatToPercent, 
    getPermile, 
    getPrice, 
    getOtherValue, 
    getDwellingValue, 
    getPlotTableHeaderContent,
    getPlotOtherContent,
    getPlotDwellingContent,
    formatFactor,
    getPlotGeneralContent,
    getPlotTableSubHeaderContent
} from './functions'
import { TableRow } from './table-row'
import { TaxValue } from './tax-value'

const useStyles = makeStyles((theme: Theme) => {
    return {
        tableWrapper: {
            margin: '0 -16px',
            width: 'calc(100% + 48px)',
            maxWidth: 'calc(100% + 48px)',
            flexBasis: 'auto',
        },
        boldeded: {
            fontSize: 18,
            fontWeight: 600,
        },
        left: {
            textAlign: 'left'
        },
        right: {
            textAlign: 'right'
        },
        center: {
            textAlign: 'center'
        },
        lastRow: {
            border: 'none'
        },
        tableRow: {
            padding: theme.spacing(1),
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            '& .MuiGrid-item': {
                padding: theme.spacing(1, 2)
            }
        },
        tableHeader: {
            padding: theme.spacing(1),
            fontWeight: 600,
            color: '#4a4a4a',
            fontSize: 14,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            '& .MuiGrid-item': {
                padding: theme.spacing(1, 2)
            }
        },
        taxTableRow: {
            textAlign: 'right',
            '& .MuiGrid-item': {
                padding: theme.spacing(1, 2)
            }
        },
        spacer: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        tableContainer: {
            minWidth: 1000
        }
    }
})

type TaxationTablesProps = {
    taxation: Taxation
}

export const TaxationTables: React.FC<TaxationTablesProps> = (props) => {
    const { taxation } = props
    const styles = useStyles()

    return (
        <Grid container className={styles.tableContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={styles.boldeded}>
                        {tr(t.TEXT_PLOT)}
                    </Grid>
                    <Grid item xs={12} className={styles.tableWrapper}>

                        <TableRow containerClass={styles.tableHeader} content={getPlotTableHeaderContent(styles)} />
                        <TableRow
                            content={getPlotGeneralContent(styles, taxation)}
                            containerClass={styles.tableRow}
                        />
                        <TableRow containerClass={styles.tableHeader} content={getPlotTableSubHeaderContent(styles)} />

                        <TableRow
                            content={getPlotOtherContent(styles, taxation)}
                            containerClass={styles.tableRow}
                        />
                        <TableRow
                            content={getPlotDwellingContent(styles, taxation)}
                            containerClass={styles.tableRow}
                        />

                    </Grid>
                    <Grid item xs={12} />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} className={styles.boldeded}>
                        {`${tr(t.TEXT_TAX)} ${DateTime.fromJSDate(new Date(taxation.start)).year}`}
                    </Grid>
                    <Grid item xs={12} className={styles.tableWrapper}>
                        <Grid container className={classnames(styles.tableHeader, styles.taxTableRow)}>
                            <Grid item xs className={styles.left}>{tr(t.TEXT_TAX_RATE)}</Grid>
                            <Grid item xs>{tr(t.TEXT_VALUE)}</Grid>
                            <Grid item xs>{tr(t.TEXT_ZONE)}</Grid>
                            <Grid item xs>{tr(t.TEXT_LOCATION)}</Grid>
                            <Grid item xs>{tr(t.TEXT_REDUCTION_FACTOR)}</Grid>
                            <Grid item xs>{tr(t.TEXT_FLAT_REDUCTION)}</Grid>
                            <Grid item xs>{tr(t.TEXT_TAX_RATE)}</Grid>
                            <Grid item xs>{tr(t.TEXT_TAX)}</Grid>
                        </Grid>
                        <Grid container className={classnames(styles.tableRow, styles.taxTableRow)}>
                            <Grid item xs className={styles.left}>{tr(t.TEXT_ORDINARY_NORMAL)}</Grid>
                            <Grid item xs>{getOtherValue(taxation.calculationDetails, taxation.current.property)}</Grid>
                            <Grid item xs>{isPropertyUsingFactors(taxation.current.property) ? formatFactor(taxation.current.property.zoneFactor) : '-'}</Grid>
                            <Grid item xs>{isPropertyUsingFactors(taxation.current.property) ? formatFactor(taxation.current.property.locationFactor) : '-'}</Grid>
                            <Grid item xs>{formatToPercent(taxation.yearSettings?.reductionFactor)}</Grid>
                            <Grid item xs>{'-'}</Grid>
                            <Grid item xs>{getPermile(taxation.calculationDetails.otherTaxRate ? taxation.calculationDetails.otherTaxRate : taxation.yearSettings?.defaultTaxRate)}</Grid>
                            <Grid item xs>{getPrice(taxation.calculationDetails.otherTax)}</Grid>
                        </Grid>
                        <Grid container className={classnames(styles.tableRow, styles.taxTableRow)}>
                            <Grid item xs className={styles.left}>{tr(t.TEXT_LIVING)}</Grid>
                            <Grid item xs>{getDwellingValue(taxation.calculationDetails, taxation.current.property)}</Grid>
                            <Grid item xs>{isUsingPropertyFactors(taxation) ? formatFactor(taxation.current.property.zoneFactor) : '-'}</Grid>
                            <Grid item xs>{isUsingPropertyFactors(taxation) ? formatFactor(taxation.current.property.locationFactor) : '-'}</Grid>
                            <Grid item xs>{formatToPercent(taxation.yearSettings?.reductionFactor)}</Grid>
                            <Grid item xs>{getPrice(getFlatReductionValue(taxation))}</Grid>
                            <Grid item xs>{getPermile(taxation.calculationDetails.dwellingTaxRate)}</Grid>
                            <Grid item xs>{getPrice(taxation.calculationDetails.dwellingTax)}</Grid>
                        </Grid>
                        <Grid container className={classnames(styles.tableRow, styles.taxTableRow, styles.boldeded)}>
                            <Grid item sm xs={6} className={classnames(styles.left)}>{tr(t.TEXT_TAX)}</Grid>
                            <Grid item xs className={styles.spacer}>{}</Grid>
                            <Grid item xs className={styles.spacer}>{}</Grid>
                            <Grid item xs className={styles.spacer}>{}</Grid>
                            <Grid item xs className={styles.spacer}>{}</Grid>
                            <Grid item xs className={styles.spacer}>{}</Grid>
                            <Grid item xs className={styles.spacer}>{}</Grid>
                            <Grid item sm xs={6}><TaxValue calculation={taxation.calculationDetails} /></Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    )
}
