import { AnyAction } from 'redux'
import { Taxation } from 'models/taxation'
import { PropertyObjectWithTaxations } from 'models/property-object'
import { ContactInfo, Property, StateModel } from 'models/property'
import { CalendarEventType } from 'shared-components/src/models/calendar-event'
import { Building } from 'models/building'
import { AddOwner } from 'models/owner'

export const ACTION_PROPERTY_GET = 'ACTION_PROPERTY_GET'
export const ACTION_PROPERTY_GET_SUCCESS = 'ACTION_PROPERTY_GET_SUCCESS'
export const ACTION_PROPERTY_GET_CONTACT_INFO = 'ACTION_PROPERTY_GET_CONTACT_INFO'
export const ACTION_PROPERTY_GET_CONTACT_INFO_SUCCESS = 'ACTION_PROPERTY_GET_CONTACT_INFO_SUCCESS'
export const ACTION_PROPERTY_GET_TAXATION = 'ACTION_PROPERTY_GET_TAXATION'
export const ACTION_PROPERTY_GET_TAXATION_SUCCESS = 'ACTION_PROPERTY_GET_TAXATION_SUCCESS'
export const ACTION_PROPERTY_TAXATION_UPDATE = 'ACTION_PROPERTY_TAXATION_UPDATE'
export const ACTION_PROPERTY_TAXATION_UPDATE_SUCCESS = 'ACTION_PROPERTY_TAXATION_UPDATE_SUCCESS'
export const ACTION_PROPERTY_TAXATION_DELETE = 'ACTION_TAXATION_DELETE'
export const ACTION_PROPERTY_TAXATION_DELETE_SUCCESS = 'ACTION_TAXATION_DELETE_SUCCESS'
export const ACTION_ASSIGN_PROPERTY_SUCCESS = 'ACTION_ASSIGN_PROPERTY_SUCCESS'
export const ACTION_ASSIGN_PROPERTY = 'ACTION_ASSIGN_PROPERTY'
export const ACTION_PROPERTY_EVENTS_CREATE = 'ACTION_PROPERTY_EVENTS_CREATE'
export const ACTION_PROPERTY_EVENTS_CREATE_SUCCESS = 'ACTION_PROPERTY_EVENTS_CREATE_SUCCESS'
export const ACTION_PROPERTY_SAVE = 'ACTION_PROPERTY_SAVE'
export const ACTION_PROPERTY_SAVE_SUCCESS = 'ACTION_PROPERTY_SAVE_SUCCESS'
export const ACTION_PROPERTY_UPDATE = 'ACTION_PROPERTY_UPDATE'
export const ACTION_PROPERTY_UPDATE_SUCCESS = 'ACTION_PROPERTY_UPDATE_SUCCESS'
export const ACTION_BUILDING_SAVE = 'ACTION_BUILDING_SAVE'
export const ACTION_BUILDING_SAVE_SUCCESS = 'ACTION_BUILDING_SAVE_SUCCESS'
export const ACTION_SEND_LAST_TAX = 'ACTION_SEND_LAST_TAX'
export const ACTION_SEND_LAST_TAX_SUCCESS = 'ACTION_SEND_LAST_TAX_SUCCESS'
export const ACTION_LOAD_PROPERTY_COMMUNICATION = 'ACTION_LOAD_PROPERTY_COMMUNICATION'
export const ACTION_LOAD_PROPERTY_COMMUNICATION_SUCCESS = 'ACTION_LOAD_PROPERTY_COMMUNICATION_SUCCESS'
export const ACTION_SEND_PROPERTY_COMMUNICATION = 'ACTION_SEND_PROPERTY_COMMUNICATION'
export const ACTION_SEND_PROPERTY_COMMUNICATION_SUCCESS = 'ACTION_SEND_PROPERTY_COMMUNICATION_SUCCESS'
export const ACTION_CREATE_TAXATION = 'ACTION_CREATE_TAXATION'
export const ACTION_CREATE_TAXATION_SUCCESS = 'ACTION_CREATE_TAXATION_SUCCESS'
export const ACTION_PROPERTY_ADD_OWNERSHIP = 'ACTION_PROPERTY_ADD_OWNERSHIP'
export const ACTION_PROPERTY_UPDATE_OWNERSHIP = 'ACTION_PROPERTY_UPDATE_OWNERSHIP'
export const ACTION_PROPERTY_DELETE_OWNERSHIP = 'ACTION_PROPERTY_DELETE_OWNERSHIP'
export const ACTION_PROPERTY_UPDATE_OWNERSHIP_SUCCESS = 'ACTION_PROPERTY_UPDATE_OWNERSHIP_SUCCESS'
export const ACTION_PROPERTY_DELETE_OWNERSHIP_SUCCESS = 'ACTION_PROPERTY_DELETE_OWNERSHIP_SUCCESS'

export const actionPropertyGet = (propertyId: string): AnyAction => {
    return {
        type: ACTION_PROPERTY_GET,
        propertyId,
    }
}

export const actionPropertyGetSuccess = (data: PropertyObjectWithTaxations): AnyAction => {
    return {
        type: ACTION_PROPERTY_GET_SUCCESS,
        data,
    }
}

export const actionPropertyGetContactInfo = (propertyId: string, personalNr: number): AnyAction => {
    return {
        type: ACTION_PROPERTY_GET_CONTACT_INFO,
        propertyId,
        personalNr,
    }
}

export const actionPropertyGetContactInfoSuccess = (propertyId: string, contactInfo: ContactInfo): AnyAction => {
    return {
        type: ACTION_PROPERTY_GET_CONTACT_INFO_SUCCESS,
        propertyId,
        contactInfo,
    }
}

export const actionPropertyAddOwnership = (owner: AddOwner, propertyId: string): AnyAction => {
    return {
        type: ACTION_PROPERTY_ADD_OWNERSHIP,
        owner,
        propertyId,
    }
}

export const actionPropertyUpdateOwnership = (owner: AddOwner, propertyId: string): AnyAction => {
    return {
        type: ACTION_PROPERTY_UPDATE_OWNERSHIP,
        owner,
        propertyId,
    }
}

export const actionPropertyUpdateOwnershipSuccess = (owner: any, propertyId: any): AnyAction => {
    return {
        type: ACTION_PROPERTY_UPDATE_OWNERSHIP_SUCCESS,
        owner,
        propertyId,
    }
}
export const actionPropertyDeleteOwnership = (owner: AddOwner, propertyId: string): AnyAction => {
    return {
        type: ACTION_PROPERTY_DELETE_OWNERSHIP,
        owner,
        propertyId,
    }
}
export const actionPropertyDeleteOwnershipSuccess = (propertyId: string, ownerId: string): AnyAction => {
    return {
        type: ACTION_PROPERTY_DELETE_OWNERSHIP_SUCCESS,
        propertyId,
        ownerId,
    }
}
export const actionPropertyUpdate = (property: Property): AnyAction => {
    return {
        type: ACTION_PROPERTY_UPDATE,
        property,
    }
}

export const actionPropertyUpdateSuccess = (property: Property): AnyAction => {
    return {
        type: ACTION_PROPERTY_UPDATE_SUCCESS,
        property,
    }
}

export const actionPropertyTaxationUpdate = (propertyId: string, taxation: Taxation): AnyAction => {
    return {
        type: ACTION_PROPERTY_TAXATION_UPDATE,
        propertyId,
        taxation,
    }
}

export const actionPropertyTaxationUpdateSuccess = (propertyId: string, taxation: Taxation): AnyAction => {
    return {
        type: ACTION_PROPERTY_TAXATION_UPDATE_SUCCESS,
        propertyId,
        taxation,
    }
}

export const actionPropertyTaxationDelete = (propertyId: string, taxationId: string): AnyAction => {
    return {
        type: ACTION_PROPERTY_TAXATION_DELETE,
        propertyId,
        taxationId,
    }
}

export const actionPropertyTaxationDeleteSuccess = (propertyId: string, taxationId: string): AnyAction => {
    return {
        type: ACTION_PROPERTY_TAXATION_DELETE_SUCCESS,
        propertyId,
        taxationId,
    }
}

export const actionAssignProperty = (stateModels: StateModel[]): AnyAction => ({
    type: ACTION_ASSIGN_PROPERTY,
    stateModels,
})

export const actionAssignPropertySuccess = (property: Property[]): AnyAction => ({
    type: ACTION_ASSIGN_PROPERTY_SUCCESS,
    property,
})

export const actionPropertyEventsCreate = (items: any[], userId: string, eventType: CalendarEventType) => ({
    type: ACTION_PROPERTY_EVENTS_CREATE,
    items,
    userId,
    eventType,
})

export const actionPropertyEventsCreateSuccess = (items: any[]) => ({
    type: ACTION_PROPERTY_EVENTS_CREATE_SUCCESS,
    items,
})

export const actionPropertySave = (property: Property, values: any) => ({
    type: ACTION_PROPERTY_SAVE,
    property: property,
    values: values,
})

export const actionPropertySaveSuccess = (property: Property) => ({
    type: ACTION_PROPERTY_SAVE_SUCCESS,
    property,
})

export const actionBuildingSave = (property:string, building: Building, values: any) => ({
    type: ACTION_BUILDING_SAVE,
    building: building,
    values: values,
    property: property,
})

export const actionBuildingSaveSuccess = (building: Building, property:string) => ({
    type: ACTION_BUILDING_SAVE_SUCCESS,
    building,
    property,
})

export const actionSendLastTax = (id: string) => ({
    type: ACTION_SEND_LAST_TAX,
    id,
})

export const actionSendLastTaxSuccess = () => ({
    type: ACTION_SEND_LAST_TAX_SUCCESS,
})

export const actionLoadPropertyCommunication = (id: string): AnyAction => {
    return {
        type: ACTION_LOAD_PROPERTY_COMMUNICATION,
        id,
    }
}

export const actionLoadPropertyCommunicationSuccess = (id: string, communication: any): AnyAction => {
    return {
        type: ACTION_LOAD_PROPERTY_COMMUNICATION_SUCCESS,
        id,
        communication,
    }
}

export const actionSendPropertyCommunication = (entry: string): AnyAction => {
    return {
        type: ACTION_SEND_PROPERTY_COMMUNICATION,
        entry,
    }
}

export const actionSendPropertyCommunicationSuccess = (entries: any): AnyAction => {
    return {
        type: ACTION_SEND_PROPERTY_COMMUNICATION_SUCCESS,
        entries,
    }
}

export const actionCreateTaxation = (id: string) => ({
    type: ACTION_CREATE_TAXATION,
    id,
})

export const actionCreateTaxationSuccess = (id: string, taxation: Taxation) => ({
    type: ACTION_CREATE_TAXATION_SUCCESS,
    id,
    taxation,
})
