import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { MapContext } from 'map'
import { clusteredFeatures } from 'map/source'
import { Feature, getUid, MapBrowserEvent } from 'ol'
import { platformModifierKeyOnly } from 'ol/events/condition'
import { Geometry, Point } from 'ol/geom'
import DragBox, { DragBoxEvent } from 'ol/interaction/DragBox'
import OLVectorLayer from 'ol/layer/Vector'
import { Cluster } from 'ol/source'
import { useDispatch, useSelector } from 'react-redux'
import { actionCubitTableSelect } from 'shared-components/src/cubit-table/cubit-table-actions'
import { PropertiesTableName } from 'search-page/properties-results/properties-table'

interface PropertiesLayer {
    children: Feature<Point>[]
    distance?: number
    style?: any
}

const PropertiesLayer: React.FC<PropertiesLayer> = ({ children, distance, style }) => {
    const { map, loading } = useContext(MapContext)
    const [clusters, setClusters] = useState<Cluster | null>(null)
    const [layerId, setLayerId] = useState<string | undefined>(undefined)
    const dispatch = useDispatch()
    const selected = useSelector((state: any) =>
        state.table[PropertiesTableName] && state.table[PropertiesTableName].selection
            ? state.table[PropertiesTableName].selection
            : [],
    )
    const selectedRef = useRef<string[]>(selected)
    const layerIdRef = useRef<string | undefined>(layerId)

    const onBoxEnd = useCallback(
        (event: DragBoxEvent) => {
            event.preventDefault()
            event.stopPropagation()

            let extent = event.target.getGeometry().getExtent()
            let newSelected: string[] = [...selectedRef.current]
            const layer = event.mapBrowserEvent.map
                .getLayers()
                .getArray()
                .find((l) => getUid(l) === layerIdRef.current) as OLVectorLayer<Cluster>
            const source = layer.getSource()
            if (!source) return

            source.forEachFeatureIntersectingExtent(extent, (feature) => {
                feature
                    .get('features')
                    .filter((unit: Feature<Geometry>) => unit.get('selectable'))
                    .map((unit: Feature<Geometry>) => {
                        unit.set('selected', !unit.get('selected'))
                        if (unit.get('selected')) {
                            newSelected.push(unit.get('unitId'))
                        } else {
                            newSelected = newSelected.filter((id: string) => id !== unit.get('unitId'))
                        }

                        return unit
                    })
            })
            dispatch(actionCubitTableSelect(PropertiesTableName, newSelected))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layerId, selected],
    )

    const onClick = useCallback(
        (event: MapBrowserEvent<any>) => {
            event.preventDefault()
            event.stopPropagation()

            let isTouched = false // Temporariry workaround when using polygon tool to not trigger.
            let newSelected: string[] = [...selectedRef.current]
            if (
                event.map.hasFeatureAtPixel(event.pixel, {
                    layerFilter: (layer) => getUid(layer) === layerIdRef.current,
                })
            ) {
                isTouched = true
                const features = event.map.getFeaturesAtPixel(event.pixel) as Feature<Geometry>[]
                features.forEach((feature: Feature<Geometry>) => {
                    feature
                        .get('features')
                        .filter((unit: Feature<Geometry>) => unit.get('selectable'))
                        .map((unit: Feature<Geometry>) => {
                            unit.set('selected', !unit.get('selected'))
                            if (unit.get('selected')) {
                                newSelected.push(unit.get('unitId'))
                            } else {
                                newSelected = newSelected.filter((id: string) => id !== unit.get('unitId'))
                            }

                            return unit
                        })
                })
            }

            if (isTouched) {
                dispatch(actionCubitTableSelect(PropertiesTableName, newSelected))
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selected, layerId],
    )

    useEffect(() => {
        selectedRef.current = selected
        if (clusters && selected.length === 0) {
            clusters.getFeatures().forEach((clusteredFeatures) =>
                clusteredFeatures
                    .get('features')
                    .filter((feature: Feature<Geometry>) => feature.get('selectable') && feature.get('selected'))
                    .forEach((feature: Feature<Geometry>) => feature.set('selected', false)),
            )
        } else if (clusters && selected.length > 0) {
            clusters.getFeatures().forEach((clusteredFeatures) =>
                clusteredFeatures
                    .get('features')
                    .filter(
                        (feature: Feature<Geometry>) =>
                            feature.get('selectable') &&
                            feature.get('selected') &&
                            selected.indexOf(feature.get('unitId')) === -1,
                    )
                    .forEach((feature: Feature<Geometry>) => feature.set('selected', false)),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    useEffect(() => {
        const source = clusteredFeatures(children, distance)
        setClusters(source)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        layerIdRef.current = layerId
    }, [layerId])

    useEffect(() => {
        if (!map || loading) return
        const layer = map
            .getLayers()
            .getArray()
            .find((l) => getUid(l) === layerId) as OLVectorLayer<Cluster>
        if (layer) {
            const source = clusteredFeatures(children, distance)
            // source.once('addfeature', () => {
            //     map.getView().fit(source.getExtent())
            // })
            layer.setSource(source)
            setClusters(source)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children])

    useEffect(() => {
        if (!map || !clusters) return

        // clusters.once('addfeature', () => {
        //     map.getView().fit(clusters.getExtent())
        // })

        let vectorLayer = new OLVectorLayer({
            source: clusters,
            style,
        })
        const layerId = getUid(vectorLayer)
        setLayerId(layerId)
        map.addLayer(vectorLayer)

        let dragBox = new DragBox({
            condition: platformModifierKeyOnly,
        })
        dragBox.on('boxend', onBoxEnd)
        map.addInteraction(dragBox)

        map.on('singleclick', onClick)

        return () => {
            if (map) {
                map.removeLayer(vectorLayer)
                setLayerId(undefined)
                map.removeInteraction(dragBox)
                map.un('singleclick', onClick)
                if (selected.length > 0) {
                    dispatch(actionCubitTableSelect(PropertiesTableName, []))
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, style])

    return null
}
export default PropertiesLayer
