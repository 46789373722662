import { useContext, useEffect } from 'react'
import OLTileLayer from 'ol/layer/Tile'
import MapContext from '../map/map-context'

interface TileLayer {
    source: any
}
const TileLayer: React.FC<TileLayer> = ({ source }) => {
    const { map } = useContext(MapContext)

    useEffect(() => {
        if (!map) return

        let tileLayer = new OLTileLayer({
            source,
        })
        map.addLayer(tileLayer)

        return () => {
            if (map) {
                map.removeLayer(tileLayer)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map])
    return null
}
export default TileLayer
