import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => {
    return {
        contextBar: {
            backgroundColor: 'black',
            ...theme.mixins.toolbar,
            zIndex: theme.zIndex.drawer + 10,
            padding: theme.spacing(0, 3),
        },
    }
})

const ContextBar: React.FC = props => {
    const styles = useStyles()

    return (
        <AppBar position="fixed" className={styles.contextBar}>
            {props.children}
        </AppBar>
    )
}

export default ContextBar
