import React, { useState } from 'react'
import { Field, submit, reset } from 'redux-form'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { Grid, IconButton, Button } from '@material-ui/core'
import { TribunalTaxationComment } from './tribunal-taxation-comment.type'
import { useUser } from 'auth/auth-selectors'
import { userHasPermission } from 'utils'
import { Permission } from 'common/enums/permission'
import classes from './tribunal-taxation-comments.module.css'
import { TEXT_COMMENT_NOUN, TEXT_SAVE, TEXT_CANCEL } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { useUsers } from 'app/app-selectors'
import { find } from 'lodash'
import EditIcon from '@material-ui/icons/Edit'
import { useDispatch } from 'react-redux'

type TribunalTaxationCommentProps = {
    commentFieldName: string
    addingNewComment: boolean
    form: string
    commentIndex: number
    comment: TribunalTaxationComment
}

export const TribunalTaxationCommentDetails: React.FC<TribunalTaxationCommentProps> = props => {
    const { commentFieldName, addingNewComment, comment, form } = props
    const dispatch = useDispatch()
    const users = useUsers()
    const currentUser = useUser()

    const [editing, setEditing] = useState(false)

    const canSeeComment =
        currentUser && (userHasPermission(currentUser, Permission.ViewTribunalAll) || comment.userId === currentUser.id)
    const canEdit = currentUser && comment.userId === currentUser.id

    const commentUser = find(users, u => u.id === comment.userId)
    const commentUserName = commentUser ? commentUser.name : ''

    const submitForm = () => {
        dispatch(submit(form))
        setEditing(false)
    }

    const handleCancelEdit = () => {
        dispatch(reset(form))
        setEditing(false)
    }

    return (
        <>
            {canSeeComment ? (
                addingNewComment || editing ? (
                    <>
                        <Grid container className={classes.commentContainer}>
                            <Grid item xs={12}>
                                <div className={classes.hidden}>
                                    <Field
                                        name={`${commentFieldName}.userId`}
                                        component={CubitTextFieldAdapter}
                                        type="hidden"
                                    />
                                </div>

                                <Field
                                    name={`${commentFieldName}.text`}
                                    component={CubitTextFieldAdapter}
                                    label={tr(TEXT_COMMENT_NOUN)}
                                    variant="outlined"
                                    multiline
                                />
                            </Grid>
                        </Grid>
                        {editing && (
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <Button onClick={handleCancelEdit}>{tr(TEXT_CANCEL)}</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={submitForm} color="primary">
                                        {tr(TEXT_SAVE)}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </>
                ) : (
                    comment &&
                    !editing && (
                        <Grid container className={classes.commentContainer} justifyContent="space-between">
                            <Grid item xs={12} className={classes.commentUser}>
                                {!canEdit && commentUserName}
                            </Grid>

                            <Grid item xs className={classes.commentTextReadOnly}>
                                {comment.text}
                            </Grid>
                            <Grid item>
                                {canEdit && !editing && (
                                    <IconButton onClick={() => setEditing(true)} color="inherit" size="small">
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    )
                )
            ) : (
                <></>
            )}
        </>
    )
}
