import React, { useState, useEffect } from 'react'
import { FormControl, Select, OutlinedInput, MenuItem, Divider, Grid, makeStyles, Theme } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_ADD_TO_LIST, TEXT_CREATE_NEW_GROUP, TEXT_SAVE } from 'utils/translations/keys'
import { TribunalGroup, TribunalGroupType } from 'tribunal-page/models/tribunal-group'
import { useDispatch, useSelector } from 'react-redux'
import {
    actionTribunalGroupAssignTaxations,
    actionTribunalGroupCreateAndAssignTaxations,
    actionTribunalGroupsGetFull,
} from 'tribunal-page/tribunal-actions'
import { TribunalGroupDialog } from 'tribunal-page/tribunal-group-page/tribunal-group-dialog'
import { TribunalGroupForm, TribunalGroupFormName } from 'tribunal-page/tribunal-group-page/tribunal-group-form'
import AddIcon from '@material-ui/icons/Add'
import { submit } from 'redux-form'
import { find, isEmpty, filter } from 'lodash'
import { AppState } from 'app/app-store'
import { TaxationState } from 'models/taxation'
import { PageReference } from 'tribunal-page/page-reference.enum'

const ADD_TO_LIST = 'ADD_TO_LIST'
const CREATE_NEW = 'CREATE_NEW'

const useStyles = makeStyles((theme: Theme) => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
}))

type TribunalAssignToGroupProps = {
    /** Current group id */
    groupId: string
    taxationsIds: string[]
    pageReference: PageReference
}

export const TribunalAssignToGroup: React.FC<TribunalAssignToGroupProps> = props => {
    const { groupId, taxationsIds, pageReference } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const [groupDialogOpen, setGroupDialogOpen] = useState(false)

    const groups = useSelector((state: AppState) => state.tribunal.groups)
    const taxationsMap = useSelector((state: AppState) => state.tribunal.taxations)

    useEffect(() => {
        if (groups.length === 0 || isEmpty(taxationsMap)) {
            dispatch(actionTribunalGroupsGetFull())
        }
    }, [dispatch, groups, taxationsMap])

    const availableGroups = filter(groups, g => {
        const notCurrentGroup = groupId !== g.id
        const doesntHaveTaxations = !taxationsMap[g.id] || isEmpty(taxationsMap[g.id])
        const taxationsAreFinished =
            taxationsMap[g.id] &&
            taxationsMap[g.id][0] &&
            taxationsMap[g.id][0].state === TaxationState.TaxationFinished
        const notHardcodedGroups =
            g.type !== TribunalGroupType.HasChanges &&
            g.type !== TribunalGroupType.HasComment &&
            g.type !== TribunalGroupType.Declined

        return notCurrentGroup && (doesntHaveTaxations || taxationsAreFinished) && notHardcodedGroups
    })

    const handleClose = () => {
        setGroupDialogOpen(false)
    }

    const handleOk = () => {
        dispatch(submit(TribunalGroupFormName))
    }

    const handleSubmit = (newGroup: TribunalGroup) => {
        setGroupDialogOpen(false)
        dispatch(actionTribunalGroupCreateAndAssignTaxations(groupId, newGroup, taxationsIds, pageReference))
    }

    const handleSelectGroup = (selectedValue: string) => {
        if (selectedValue === ADD_TO_LIST) {
            return
        }

        switch (selectedValue) {
            case ADD_TO_LIST:
                break

            case CREATE_NEW:
                setGroupDialogOpen(true)
                break

            default:
                dispatch(
                    actionTribunalGroupAssignTaxations(
                        groupId,
                        find(groups, g => g.id === selectedValue) as TribunalGroup,
                        taxationsIds,
                        pageReference,
                    ),
                )
                break
        }
    }

    return (
        <>
            <FormControl className="whiteControl">
                <Select
                    value={ADD_TO_LIST}
                    onChange={(e: any) => handleSelectGroup(e.target.value)}
                    input={<OutlinedInput margin="dense" labelWidth={0} />}
                >
                    <MenuItem value={ADD_TO_LIST}>{tr(TEXT_ADD_TO_LIST)}</MenuItem>

                    {availableGroups.map((g: TribunalGroup, index: number) => (
                        <MenuItem key={index} value={g.id}>
                            {g.name}
                        </MenuItem>
                    ))}

                    <Divider></Divider>

                    <MenuItem className={styles.primaryColor} value={CREATE_NEW}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>{tr(TEXT_CREATE_NEW_GROUP)}</Grid>
                            <Grid item style={{ fontSize: 0 }}>
                                <AddIcon />
                            </Grid>
                        </Grid>
                    </MenuItem>
                </Select>
            </FormControl>

            <TribunalGroupDialog
                title={tr(TEXT_CREATE_NEW_GROUP)}
                open={groupDialogOpen}
                handleClose={handleClose}
                handleOk={handleOk}
                okText={tr(TEXT_SAVE)}
            >
                <TribunalGroupForm initialValues={{}} onSubmit={handleSubmit} />
            </TribunalGroupDialog>
        </>
    )
}
