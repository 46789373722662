import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import { InputType } from '../input-type.enum'
import { FormControlLabel } from '@material-ui/core'

export type CubitSwitchAdapterProps = { type: InputType.Switch }

const CubitSwitchAdapter: React.FC<any> = (props: any) => {
    const {
        input: { name, value, onChange, ...restInput },
        options,
        formcontrolprops,
        meta,
        label,
        ...rest
    } = props

    const textFiledProps = restInput as unknown as InputBaseComponentProps

    const val = value || false

    return (
        <FormControl>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            {...rest}
                            name={name}
                            onChange={(event: any) => onChange(event.target.checked)}
                            checked={val}
                            value={val}
                            inputProps={textFiledProps}
                        />
                    }
                    label={label}
                />
            </FormGroup>
        </FormControl>
    )
}

export { CubitSwitchAdapter }
