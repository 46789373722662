import { SuggestionGroup, Suggestions } from 'shared-components/src/search-input/search-input-types'
import React from 'react'
import { SearchInput } from 'shared-components/src/search-input/search-input'
import { Fields } from 'redux-form'
import { tr } from '../../utils/translations/translate'
import { TEXT_REGISTER_NUMBERS } from '../../utils/translations/keys'

const matchBuildingNumber2 = /^([0-9]+)\/([0-9]+)$/
const matchBuildingNumber3 = /^([0-9]+)\/([0-9]+)\/([0-9]+)$/
const matchBuildingNumber4 = /^([0-9]+)\/([0-9]+)\/([0-9]+)\/([0-9]+)$/

const createBuildingNumberSuggestionGroup = (value: any, label: string): SuggestionGroup => ({
    type: 'buildingNumbers',
    label: tr(TEXT_REGISTER_NUMBERS),
    items: [{ type: 'buildingNumbers', value, label: `Matrikkelnummer: ${label}` }],
})

const getGeneratedSuggestions = (inputValue: string): Suggestions => {
    if (matchBuildingNumber2.test(inputValue)) {
        const x = matchBuildingNumber2.exec(inputValue)
        // @ts-ignore
        const value = { holding: x[1], subHolding: x[2] }
        return [createBuildingNumberSuggestionGroup(value, inputValue)]
    } else if (matchBuildingNumber3.test(inputValue)) {
        const x = matchBuildingNumber3.exec(inputValue)
        // @ts-ignore
        const value = { holding: x[1], subHolding: x[2], lease: x[3] }
        return [createBuildingNumberSuggestionGroup(value, inputValue)]
    } else if (matchBuildingNumber4.test(inputValue)) {
        const x = matchBuildingNumber4.exec(inputValue)
        // @ts-ignore
        const value = { holding: x[1], subHolding: x[2], lease: x[3], section: x[4] }
        return [createBuildingNumberSuggestionGroup(value, inputValue)]
    }
    return []
}

type TaxSearchInputProps2 = {
    chips: any
    searchText: any
    suggestions: Suggestions
    autocomplete: Suggestions
    search: () => void
    map: any
}

const RenderFields: React.FC<TaxSearchInputProps2> = props => {
    const { chips, searchText, autocomplete, suggestions, search, map } = props
    return (
        <SearchInput
            suggestions={suggestions.concat(autocomplete)}
            selectedItems={chips.input.value}
            setSelectedItems={chips.input.onChange}
            inputValue={searchText.input.value}
            setInputValue={searchText.input.onChange}
            getGeneratedSuggestions={getGeneratedSuggestions}
            search={search}
            map={map}
        />
    )
}

type TaxSearchInputProps = {
    suggestions: Suggestions
    autocomplete: Suggestions
    search: () => void
    map: any
}

export const TaxSearchInput: React.FC<TaxSearchInputProps> = ({ suggestions, search, map, autocomplete }) => (
    <Fields
        names={['searchText', 'chips']}
        component={RenderFields}
        suggestions={suggestions}
        search={search}
        map={map}
        autocomplete={autocomplete}
    />
)
