import React from 'react'

import { AppState } from 'app/app-store'
import { useSelector } from 'react-redux'
import { CubitSnackbarWrapper } from './cubit-snackbar-wrapper'

export enum SnackbarVariants {
    success ='success',
    warning ='warning',
    error ='error',
    info ='info',

}

export const CubitSnackbar: React.FC = () => {
    const snackbar = useSelector((state: AppState) => state.snackBar)

    return <CubitSnackbarWrapper snackbar={snackbar}></CubitSnackbarWrapper>
}
