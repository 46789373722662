import { CalendarEventType, CalendarEventInfo } from 'shared-components/src/models/calendar-event'
import { CubitCalendarExtendedProps } from 'models/calendar-event'

export const ACTION_PLANNING_EVENTS_CREATE = 'ACTION_PLANNING_EVENTS_CREATE'
export const ACTION_PLANNING_EVENTS_CREATE_SUCCESS = 'ACTION_PLANNING_EVENTS_CREATE_SUCCESS'
export const ACTION_PLANNING_GET_USER_EVENTS = 'ACTION_PLANNING_GET_USER_EVENTS'
export const ACTION_PLANNING_GET_USER_EVENTS_SUCCESS = 'ACTION_PLANNING_GET_USER_EVENTS_SUCCESS'
export const ACTION_PLANNING_GET_UNPLANNED_EVENTS = 'ACTION_PLANNING_GET_UNPLANNED_EVENTS'
export const ACTION_PLANNING_GET_UNPLANNED_EVENTS_SUCCESS = 'ACTION_PLANNING_GET_UNPLANNED_EVENTS_SUCCESS'

export const actionPlanningEventsCreate = (items: any[], userId: string, eventType: CalendarEventType) => ({
    type: ACTION_PLANNING_EVENTS_CREATE,
    items,
    userId,
    eventType,
})

export const actionPlanningEventsCreateSuccess = (items: any[]) => ({
    type: ACTION_PLANNING_EVENTS_CREATE_SUCCESS,
    items,
})

export const actionPlanningGetUserEvents = (userId: string, year: number, month: number) => ({
    type: ACTION_PLANNING_GET_USER_EVENTS,
    userId,
    year,
    month,
})

export const actionPlanningGetUserEventsSuccess = (events: CalendarEventInfo<CubitCalendarExtendedProps>[]) => ({
    type: ACTION_PLANNING_GET_USER_EVENTS_SUCCESS,
    events,
})

export const actionPlanningGetUnplannedEvents = (userId: string) => ({
    type: ACTION_PLANNING_GET_UNPLANNED_EVENTS,
    userId,
})

export const actionPlanningGetUnplannedEventsSuccess = (events: CalendarEventInfo<CubitCalendarExtendedProps>[]) => ({
    type: ACTION_PLANNING_GET_UNPLANNED_EVENTS_SUCCESS,
    events,
})