import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actionTribunalComplaintsGetAll } from 'tribunal-page/tribunal-actions'
import { useTribunalPageStyles } from 'tribunal-page/tribunal-page'
import { Grid } from '@material-ui/core'
import { navigate } from '@reach/router'
import { useComplaints } from 'tribunal-page/tribunal-selectors'
import { tr } from 'utils/translations/translate'
import {
    TEXT_POLITICAL,
    TEXT_CADASTRAL_ERROR,
    TEXT_VALUATION_ERROR,
    TEXT_JUDGMENTAL_ERRORS,
    TEXT_COMPLAINT_BOARD,
    TEXT_COMBINED,
} from 'utils/translations/keys'
import { ComplaintGroupType } from 'tribunal-page/enums/complaint-group-type.enum'
import getGroupedComplaints from 'utils/tribunal/get-grouped-complaints'

export const ComplaintGroupLinks: React.FC = () => {
    const dispatch = useDispatch()
    const styles = useTribunalPageStyles()

    const complaints = useComplaints()
    useEffect(() => {
        if (!complaints?.length) {
            dispatch(actionTribunalComplaintsGetAll())
        }
    }, [dispatch, complaints])

    const groupedComplaints = getGroupedComplaints(complaints)

    const handleGroupClick = (groupType?: ComplaintGroupType) => {
        const link = groupType === undefined ? '/tribunal/complaints/all' : `/tribunal/complaints/${groupType}`
        navigate(link)
    }

    return (
        <>
            <Grid item className={styles.group} onClick={() => handleGroupClick(ComplaintGroupType.Political)}>
                <Grid container alignItems="center">
                    <Grid item className={styles.groupName}>
                        {tr(TEXT_POLITICAL)}
                    </Grid>
                    <Grid item className={styles.groupItemCount}>
                        {groupedComplaints.political.length}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={styles.group} onClick={() => handleGroupClick(ComplaintGroupType.CadastralError)}>
                <Grid container alignItems="center">
                    <Grid item className={styles.groupName}>
                        {tr(TEXT_CADASTRAL_ERROR)}
                    </Grid>
                    <Grid item className={styles.groupItemCount}>
                        {groupedComplaints.cadastralError.length}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={styles.group} onClick={() => handleGroupClick(ComplaintGroupType.ValuationError)}>
                <Grid container alignItems="center">
                    <Grid item className={styles.groupName}>
                        {tr(TEXT_VALUATION_ERROR)}
                    </Grid>
                    <Grid item className={styles.groupItemCount}>
                        {groupedComplaints.valuationError.length}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={styles.group} onClick={() => handleGroupClick(ComplaintGroupType.JudgmentalError)}>
                <Grid container alignItems="center">
                    <Grid item className={styles.groupName}>
                        {tr(TEXT_JUDGMENTAL_ERRORS)}
                    </Grid>
                    <Grid item className={styles.groupItemCount}>
                        {groupedComplaints.judgmentalError.length}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={styles.group} onClick={() => handleGroupClick(ComplaintGroupType.Combined)}>
                <Grid container alignItems="center">
                    <Grid item className={styles.groupName}>
                        {tr(TEXT_COMBINED)}
                    </Grid>
                    <Grid item className={styles.groupItemCount}>
                        {groupedComplaints.combined.length}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={styles.group} onClick={() => handleGroupClick(ComplaintGroupType.ComplaintBoard)}>
                <Grid container alignItems="center">
                    <Grid item className={styles.groupName}>
                        {tr(TEXT_COMPLAINT_BOARD)}
                    </Grid>
                    <Grid item className={styles.groupItemCount}>
                        {groupedComplaints.complaintBoard.length}
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid item className={styles.group} onClick={() => handleGroupClick()}>
                <Grid container alignItems="center">
                    <Grid item className={styles.groupName}>
                        {tr(TEXT_ALL_COMPLAINTS)}
                    </Grid>
                    <Grid item className={styles.groupItemCount}>
                        {complaints.length}
                    </Grid>
                </Grid>
            </Grid> */}
        </>
    )
}
