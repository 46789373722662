import React from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import { InjectedFormProps, reduxForm, Field, change } from 'redux-form'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import {
    TEXT_E_MAIL,
    TEXT_NAME,
    TEXT_ACTIVE,
    TEXT_INACTIVE,
    TEXT_RIGHTS,
    TEXT_USER_GROUP,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { User } from 'models/user'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { useRoles, useCompanies } from 'app/app-selectors'
import { getUserRoleTranslationKey } from 'utils'
import { find, includes } from 'lodash'
import { CompanyType } from 'models/company'
import { useDispatch } from 'react-redux'
import { RestrictedRoles, RoleNameKey } from 'common/enums/role-name-key'

const useStyles = makeStyles((theme: Theme) => {
    return {
        detailsContainer: {
            marginBottom: theme.spacing(4),
        },
    }
})

const Form: React.FC<InjectedFormProps<User>> = props => {
    const { handleSubmit } = props
    const styles = useStyles()
    const dispatch = useDispatch()

    const allRoles = useRoles()
    const taxationRoles = allRoles
        //.filter(r => r.nameKey === RoleNameKey.TaxationsAdministrator || r.nameKey === RoleNameKey.TaxationsUser)
        .filter(r => !includes(RestrictedRoles, r.nameKey))
        .map(r => ({ label: tr(getUserRoleTranslationKey(r.nameKey)), value: r.id }))

    const companies = useCompanies()
    const companiesOptions = companies.map(c => ({ label: c.name, value: c.id }))

    const handleUserGroupChange = (e: any, companyId: string) => {
        const newCompany = find(companies, { id: companyId })

        if (newCompany) {
            switch (newCompany.type) {
                case CompanyType.Admin:
                    setAdminRole()
                    break
                case CompanyType.Taxation:
                    setEmptyRoles()
                    break
                case CompanyType.Tribunal:
                    setTribunalRole()
                    break
            }
        }
    }

    const setEmptyRoles = () => {
        dispatch(change(SettingsUserFormName, 'roles', []))
    }

    const setAdminRole = () => {
        const adminRole = find(allRoles, r => r.nameKey === RoleNameKey.Administrator)
        dispatch(change(SettingsUserFormName, 'roles', [adminRole && adminRole.id]))
    }

    const setTribunalRole = () => {
        const tribunalRole = find(allRoles, r => r.nameKey === RoleNameKey.TribunalAdministrator)
        dispatch(change(SettingsUserFormName, 'roles', [tribunalRole && tribunalRole.id]))
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box className={styles.detailsContainer}>
                <Field name="name" component={CubitTextFieldAdapter} label={tr(TEXT_NAME)} autoComplete="off" />
            </Box>
            <Box className={styles.detailsContainer}>
                <Field name="email" component={CubitTextFieldAdapter} label={tr(TEXT_E_MAIL)} autoComplete="off" />
            </Box>
            <Box className={styles.detailsContainer}>
                <Field
                    name="taxCompanyId"
                    component={CubitSelectAdapter}
                    label={tr(TEXT_USER_GROUP)}
                    valueIsObject={false}
                    options={companiesOptions}
                    onChange={handleUserGroupChange as any}
                />
            </Box>
            {true && (
                <Box className={styles.detailsContainer}>
                    <Field
                        name="roles"
                        format={(value: any) => value ? value[0] : value}
                        normalize={(value: any) => [value]}
                        component={CubitSelectAdapter}
                        label={tr(TEXT_RIGHTS)}
                        valueIsObject={false}
                        options={taxationRoles}
                    />
                </Box>
            )}

            <Box className={styles.detailsContainer}>
                <Field
                    name="isActive"
                    component={CubitRadioGroupAdapter}
                    direction="row"
                    options={[
                        {
                            label: tr(TEXT_ACTIVE),
                            value: 'true',
                        },
                        {
                            label: tr(TEXT_INACTIVE),
                            value: 'false',
                        },
                    ]}
                />
            </Box>
        </form>
    )
}

export const SettingsUserFormName = 'settingsUserForm'

export const SettingsUserForm = reduxForm<User>({
    form: SettingsUserFormName,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
