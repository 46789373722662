import { Owner } from './owner'
import { PropertyObject, VerifiablePropertyObject } from './property-object'
import { Complaint } from 'tribunal-page/models/complaint.type'
import { YearSettings } from './year-settings'
import { tr } from 'utils/translations/translate'
import { TEXT_OFFICE_ADJUSTMENT_TAX, TEXT_TAXATIONS } from 'utils/translations/keys'
import { RouteNames } from 'common/enums/routeNames'
import { Correspondance } from './correspondance'
import { TaxationTabs } from 'common/enums/taxation'
import { Building, BuildingConstants, getBuildingArea } from './building'
import { untaxableBuildingsFilter } from 'property-page/property-helper'
import { Property, PropertyValueType } from './property'
import { HousingUnit } from './housing-unit'

export enum TaxationState {
    TaxationReadyForPlanning = 'TAXATION_READY_FOR_PLANNING',
    TaxationPlanned = 'TAXATION_PLANNED',
    TaxationStarted = 'TAXATION_STARTED',
    TaxationFinished = 'TAXATION_FINISHED',
    TribunalPlanned = 'TRIBUNAL_PLANNED',
    TribunalStarted = 'TRIBUNAL_STARTED',
    TribunalReadyForFinishing = 'TRIBUNAL_READY_FOR_FINISHING',
    TribunalFinished = 'TRIBUNAL_FINISHED',
    TribunalDeclined = 'TRIBUNAL_DECLINED',
}

export enum TaxationType {
    ComplaintTribunal = 'COMPLAINT_TRIBUNAL',
    OfficeAdjustment = 'OFFICE_ADJUSTMENT',
    ComplaintResolution = 'COMPLAINT_RESOLUTION',
    SettingsUpdateResult = 'SETTINGS_UPDATE',
    ManualTaxation = 'MANUAL_TAXATION'
}

export enum HousingUnitType {
    Leisure = 'Fritidsbolig',
    Residential = 'Bolig'
}

export enum StaticTemplateType {
    BO1 = 'BO1',
    BO2 = 'BO2',
    NE = 'NE',
    KO = 'KO',
    DETAILED = 'DETAILED',
    MA1 = 'MA1',
    MA2 = 'MA2',
    MA3 = 'MA3',
}

export type TaxationIssue = {
    userId: string
    comment: string
    date?: Date
}

export type Taxation = {
    created: Date
    current: VerifiablePropertyObject
    id: string
    old: VerifiablePropertyObject
    owners: Owner[]
    propertyId: string
    userId: string

    start: Date
    end: Date
    issue: TaxationIssue
    state: TaxationState

    commentChanged: boolean
    propertyChanged: boolean
    factorChanged: boolean

    conflictedUsers: string[]
    declinedReason: string
    participant: TaxationParticipant

    deletedBuildings: any
    complaints?: Complaint[]

    calculationDetails: CalculationDetails
    yearSettings: YearSettings
    staticSettings: any
    type: TaxationType


    correspondance: Correspondance[]
    communication: any
    /**
     * Mapped prop that holds lowercase concatenated values to use when filtering taxations
     */
    filterString: string
}

type Reason = {
    reason: string
}

export type DeletedBuilding = {
    [key: string]: Reason
}

export type CalculationDetails = {
    livingAreaPercentage: number
    dwellingTaxRate: number
    otherTaxRate: number
    taxValue: number
    plotPricePerM2: number
    plotSize: number
    exemptions: string[]
    dwellingBuildingsValue: number
    otherBuildingsValue: number
    dwellingLandValue: number
    otherLandValue: number
    dwellingTax: number
    otherTax: number
    totalValue: number
    // after reductions/factors
    finalValue: number
    flatReductionValue: number
}

export type PropertyTaxInfo = {
    area: string
    sun: string
    noise: string
    view: string
    lightPollution: string
    airPollution: string
    pollutionReason: string
    floodAndExposedArea: string
    difficultAccessRoad: string
    lotSizeComment: string
    tax: number
}

export type BuildingTaxInfo = {
    architecture: string
    maintenance: string
    outdoorArea: string
    exemptionComment: string
    propertyStatusComment: string
    unitsComment: string
}

export type TaxationParticipant = {
    type: string
    ownersIds: string[]
    description: string
}

export const getTaxationType = (taxation: Taxation) => {
    return taxation.type === TaxationType.OfficeAdjustment ? tr(TEXT_OFFICE_ADJUSTMENT_TAX) : tr(TEXT_TAXATIONS)
}

export const getTaxationUrl = (taxation: Taxation) => {
    return `/${RouteNames.taxation}/${taxation.id}/${RouteNames.edit}`
}

export const isTaxationFinished = (taxation: Taxation) => {
    const state = taxation && taxation.state ? taxation.state : ''
    return state === TaxationState.TribunalFinished
}

export const isTaxationEditingFinished = (taxation: Taxation) => {
    const state = taxation && taxation.state ? taxation.state : ''
    return state === TaxationState.TribunalFinished || state === TaxationState.TaxationFinished
}

export const getTaxationTab = (path: any) => {
    if (!path) {
        return TaxationTabs.taxation
    }
    const tab = Object.values(TaxationTabs).find((tab: string) => path.includes(tab))
    if (!tab) {
        return TaxationTabs.taxation
    }
    return tab
}

export const isBuildingExcludedFromTaxation = (taxation: Taxation, building: Building) => {
    const isDeleted = taxation.deletedBuildings && building.id in taxation.deletedBuildings
    const area = getBuildingArea(building)
    const minAreaSetting = taxation.staticSettings?.minBuildingArea ?? BuildingConstants.smallArea
    const tooSmall = area !== 0 && area < minAreaSetting
    return isDeleted || tooSmall
}

export const isBuildingMissingFiles = (taxation: Taxation, building: Building) => {
    const isDeleted = taxation.deletedBuildings && building.id in taxation.deletedBuildings
    const missingFiles = building.files.length < 2
    return !isDeleted && missingFiles && untaxableBuildingsFilter(building, taxation.current.buildings, taxation.staticSettings)
}

export enum Exemptions {
    //default exemption flag - the property is exempt during the calculation
    Exempt = 'Exempt'
}

export const isCalculationExempt = (calculation: CalculationDetails) => {
    return calculation.exemptions.includes(Exemptions.Exempt)
}

export const isCombinedProperty = (propertyType: PropertyValueType) => {
    return propertyType === PropertyValueType.KO
}

export const getBillingReceiver = (taxation: Taxation) => {
    let invoiceReceiver = taxation.current.owners.find((x: any) => x.id === taxation.current.property.ownerShips.find((x: any) => x.isInvoiceRecipient)?.ownerId)
    if (!invoiceReceiver) {
        invoiceReceiver = taxation.current.owners.find((x: any) => x.id === taxation.current.property.ownerShips.find((x: any) => x.isDocumentRecipient)?.ownerId)
    }
    if (!invoiceReceiver) {
        invoiceReceiver = taxation.current.owners[0]
    }
    
    return invoiceReceiver?.name ?? `${invoiceReceiver?.firstName} ${invoiceReceiver?.lastName}`
}
export const getDocumentReceiver = (taxation: Taxation) => {
    let receiver = taxation.current.owners.find((x: any) => x.id === taxation.current.property.ownerShips.find((x: any) => x.isDocumentRecipient)?.ownerId)
    if (!receiver) {
        receiver = taxation.current.owners[0]
    }
    
    return receiver?.name ?? `${receiver?.firstName} ${receiver?.lastName}`
}

export const getReductionFactor = (taxation: Taxation) => {
    return taxation.yearSettings.reductionFactor ?? 0
}   

export const getReductionSum = (taxation: Taxation) => {
    const reductionPercentage = taxation.yearSettings.reductionFactor / 100
    const calc = taxation.calculationDetails
    const valueBeforeReduction = calc.dwellingBuildingsValue + calc.otherBuildingsValue + calc.dwellingLandValue + calc.otherLandValue
    return reductionPercentage * valueBeforeReduction
}

export const getReductionFromValue = (taxation: Taxation, value: number) => {
    const reductionPercentage = taxation.yearSettings.reductionFactor / 100
    return reductionPercentage * value
}

export const getOtherValue = (taxation: Taxation) => {
    const details = taxation.calculationDetails
    const property = taxation.current.property
    if (!isPropertyUsingFactors(property)) {
        return details.otherLandValue + details.otherBuildingsValue
    }
    return (details.otherLandValue + details.otherBuildingsValue) / property.zoneFactor / property.locationFactor
}

export const getTotalValueAfterFactors = (taxation: Taxation) => {
    const zoneFactor = taxation.current.property.zoneFactor
    const locationFactor = taxation.current.property.locationFactor
    if (!isUsingPropertyFactors(taxation)) {
        return taxation.calculationDetails.totalValue
    }
    return taxation.calculationDetails.totalValue * zoneFactor * locationFactor
}

export const getTotalValueAfterFactorsAndReduction = (taxation: Taxation) => {
    const afterFactors = getTotalValueAfterFactors(taxation)
    const value = afterFactors - getReductionSum(taxation) - getFlatReductionValue(taxation)
    if (value < 0) {
        return 0
    }
    return value
}

export const getOtherValueAfterFactorsAndReduction = (taxation: Taxation) => {
    const details = taxation.calculationDetails
    const afterFactors = details.otherLandValue + details.otherBuildingsValue
    const reduction = 1 - (taxation.yearSettings.reductionFactor / 100)
    const value = afterFactors * reduction
    
    return value
}

export const getResidentialValueAfterFactorsAndReduction = (taxation: Taxation) => {
    const details = taxation.calculationDetails
    const afterFactors = details.dwellingLandValue + details.dwellingBuildingsValue
    const reduction = 1 - (taxation.yearSettings.reductionFactor / 100)
    const flatReduction = getFlatReductionValue(taxation)
    const value = afterFactors * reduction - flatReduction
    if (value < 0) {
        return 0
    }
    return value
}

export const getResidentialHousingUnitCount = (data: VerifiablePropertyObject | PropertyObject) => {
    if (data.property?.otherPropertyFlags?.deductibleHousingUnitsCount) {
        return data.property.otherPropertyFlags.deductibleHousingUnitsCount
    }
    const residentialTypes = [HousingUnitType.Residential, HousingUnitType.Leisure]
    const hus = data.housingUnits?.filter((x) => residentialTypes.includes(x.type as any))
    return hus?.length || 0
}

export const getResidentialValueAfterFactors = (taxation: Taxation) => {
    if (!isUsingPropertyFactors(taxation)) {
        return taxation.calculationDetails.dwellingLandValue + taxation.calculationDetails.dwellingBuildingsValue
    }
    const zoneFactor = taxation.current.property.zoneFactor
    const locationFactor = taxation.current.property.locationFactor
    return (taxation.calculationDetails.dwellingBuildingsValue + taxation.calculationDetails.dwellingLandValue) / zoneFactor / locationFactor
}

export const getFlatReduction = (taxation: Taxation) => {
    return taxation.yearSettings?.flatReductionValue ?? 0
}   

export const getFlatReductionValue = (taxation: Taxation) => {
    const reduction = taxation.yearSettings?.flatReductionValue ?? 0
    const hus = getResidentialHousingUnitCount(taxation.current)
    const deductionCounter = taxation.current.property.otherPropertyFlags?.deductibleHousingUnitsCount ?? hus
    return reduction * deductionCounter
}

export const isUsingManualValue = (taxation: Taxation) => {
    if(taxation.current.property.otherPropertyFlags?.nationalValuation) {
        return false
    }
    return taxation.current.property.otherPropertyFlags?.manualValue || taxation.current.property.otherPropertyFlags?.manualKoValue
}

export const isUsingPropertyFactors = (taxation: Taxation) => {
    if(taxation.current.property.otherPropertyFlags?.nationalValuation) {
        return false
    }
    return !(taxation.current.property.otherPropertyFlags?.manualValue || taxation.current.property.otherPropertyFlags?.manualKoValue)
}

export const isPropertyUsingFactors = (property: Property) => {
    if(property.otherPropertyFlags?.nationalValuation) {
        return false
    }
    return !(property.otherPropertyFlags?.manualValue || property.otherPropertyFlags?.manualKoValue)
}

export const isPropUsingManualValue = (property: Property) => {
    if(property.otherPropertyFlags?.nationalValuation) {
        return false
    }
    return property.otherPropertyFlags?.manualValue || property.otherPropertyFlags?.manualKoValue
}

export const getManualValueComment = (property: Property) => {
    if(property.otherPropertyFlags?.manualValue && property.otherPropertyFlags?.manualValueComment) {
        return property.otherPropertyFlags.manualValueComment
    }
    return ''
}

export const hasManualValue = (property: Property) => {
    return property.otherPropertyFlags?.manualValue
}

export const hasManualKoValue = (property: Property) => {
    return property.otherPropertyFlags?.manualKoValue
}

