import { makeReducer } from 'utils'
import {
    ACTION_PROPERTY_GET,
    ACTION_PROPERTY_GET_SUCCESS,
    ACTION_PROPERTY_GET_CONTACT_INFO,
    ACTION_PROPERTY_GET_CONTACT_INFO_SUCCESS,
    ACTION_PROPERTY_TAXATION_DELETE,
    ACTION_PROPERTY_TAXATION_DELETE_SUCCESS,
    ACTION_PROPERTY_TAXATION_UPDATE,
    ACTION_PROPERTY_TAXATION_UPDATE_SUCCESS,
    ACTION_ASSIGN_PROPERTY,
    ACTION_ASSIGN_PROPERTY_SUCCESS,
    ACTION_PROPERTY_EVENTS_CREATE,
    ACTION_PROPERTY_EVENTS_CREATE_SUCCESS,
    ACTION_PROPERTY_SAVE,
    ACTION_PROPERTY_SAVE_SUCCESS,
    ACTION_PROPERTY_UPDATE,
    ACTION_BUILDING_SAVE,
    ACTION_BUILDING_SAVE_SUCCESS,
    ACTION_SEND_LAST_TAX,
    ACTION_SEND_LAST_TAX_SUCCESS,
    ACTION_SEND_PROPERTY_COMMUNICATION,
    ACTION_SEND_PROPERTY_COMMUNICATION_SUCCESS,
    ACTION_LOAD_PROPERTY_COMMUNICATION,
    ACTION_LOAD_PROPERTY_COMMUNICATION_SUCCESS,
    ACTION_CREATE_TAXATION,
    ACTION_CREATE_TAXATION_SUCCESS,
    ACTION_PROPERTY_ADD_OWNERSHIP,
    ACTION_PROPERTY_UPDATE_OWNERSHIP,
    ACTION_PROPERTY_DELETE_OWNERSHIP,
    ACTION_PROPERTY_UPDATE_OWNERSHIP_SUCCESS,
    ACTION_PROPERTY_DELETE_OWNERSHIP_SUCCESS,
} from './property-actions'
import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import { PropertyObjectWithTaxations } from 'models/property-object'
import { remove, findIndex } from 'lodash'
import { Property, PropertyStatus } from 'models/property'
import { ACTION_UNASSIGN_PROPERTIES, ACTION_UNASSIGN_PROPERTIES_SUCCESS } from 'search-page/search-actions'
import { ACTION_ATTACH_ATTRIBUTES_SUCCESS } from 'common/attributes/attribute-actions'

export type PropertiesState = { [key: string]: PropertyObjectWithTaxations; contactInfo?: any }

type PropertyReducer = ActionReducer<PropertiesState>

const get: PropertyReducer = (state, action) => state

const getSuccess: PropertyReducer = (state, action) => ({ ...state, [action.data.property.id]: action.data })

const getContactInfo: PropertyReducer = (state, action) => state

const getContactInfoSuccess: PropertyReducer = (state, action) => ({
    ...state,
    [action.propertyId]: { ...state[action.propertyId], contactInfo: action.contactInfo },
})

const AddOwnership: PropertyReducer = (state) => state

const updateOwnership: PropertyReducer = (state) => state

const deleteOwnership: PropertyReducer = (state) => state

const updatePropertyTaxation: PropertyReducer = (state) => state

const updateProperty: PropertyReducer = (state) => state

const updatePropertyTaxationSuccess: PropertyReducer = (state, action) => {
    const propertyTaxations = state[action.propertyId].taxations
    const taxationIndex = findIndex(propertyTaxations, (t) => t.id === action.taxation.id)
    propertyTaxations[taxationIndex] = action.taxation
    return state
}

const updateOwnershipSuccess: PropertyReducer = (state, action) => {
    const propertyOwners = state[action.propertyId].owners
    const ownerIndex = findIndex(propertyOwners, (o: any) => o.id === action.owner.id)
    propertyOwners[ownerIndex] = action.owner

    return state
}

const deleteOwnershipSuccess: PropertyReducer = (state, action) => {
    const property = state[action.propertyId]?.property
    property.ownerShips = property.ownerShips.filter((o: any) => o.ownerId !== action.ownerId)

    return state
}
const deletePropertyTaxation: PropertyReducer = (state) => state

const deletePropertyTaxationSuccess: PropertyReducer = (state, action) => {
    remove(state[action.propertyId].taxations, (taxation) => taxation.id === action.taxationId)

    return state
}

const assignProperty: PropertyReducer = (state) => state

const assignPropertySuccess: PropertyReducer = (state, action) => {
    const assignedProperty: Property = action.property[0]
    const property: PropertyObjectWithTaxations = state[assignedProperty.id]
    property.property = { ...property.property, state: assignedProperty.state }

    return state
}

const unassignProperty: PropertyReducer = (state) => state

const unassignPropertySuccess: PropertyReducer = (state, action) => {
    const assignedProperty: string = action.ids[0]
    const property: PropertyObjectWithTaxations = state[assignedProperty]
    if (!property) {
        return state
    }
    // @ts-ignore
    property.property = { ...property.property, state: null }

    return state
}

const eventsCreate: PropertyReducer = (state, action) => state

const eventsCreateSuccess: PropertyReducer = (state, action) => {
    const propertyId = action.items[0].relatedEntityId
    const property: PropertyObjectWithTaxations = state[propertyId]
    property.property.state = { ...property.property.state, value: PropertyStatus.ACTIVE }
    return state
}

const propertySave: PropertyReducer = (state, action) => {
    let copyProp = { ...action.property }
    Object.keys(action.values).map((key) => {
        return (copyProp[key] = action.values[key])
    })
    action.property = copyProp
    return state
}

const propertySaveSuccess: PropertyReducer = (state, action) => ({
    ...state,

    [action.property.id]: {
        ...state[action.property.id],
        property: action.property,
    },
})

const buildingSave: PropertyReducer = (state, action) => {
    let copyBuilding = { ...action.building }
    Object.keys(action.values).map((key) => {
        return (copyBuilding[key] = action.values[key])
    })
    action.building = copyBuilding
    return state
}

const buildingSaveSuccess: PropertyReducer = (state, action) => {
    const propertyBuildings = state[action.property].buildings
    const buildingIndex = findIndex(propertyBuildings, (b: any) => b.id === action.building.id)
    propertyBuildings[buildingIndex] = action.building

    return state
}

const sendLastTax: PropertyReducer = (state, action) => state

const sendLastTaxSuccess: PropertyReducer = (state, action) => state

const loadPropertyCommunication: PropertyReducer = (state, action) => {
    return {
        ...state,
        [action.id]: {
            ...state[action.id],
            communication: {
                loading: true,
            },
        },
    }
}

const loadPropertyCommunicationSuccess: PropertyReducer = (state, action) => ({
    ...state,
    [action.id]: {
        ...state[action.id],
        communication: action.communication,
    },
})

const sendPropertyCommunication: PropertyReducer = (state) => state

const sendPropertyCommunicationSuccess: PropertyReducer = (state, action) => {
    const id = action.entries[0].forId
    const comms = state[id].communication
    state[id].communication = [...action.entries, ...comms]
    return state
}

const createTaxation: PropertyReducer = (state, action) => {
    const property = state[action.id]
    if (!property) {
        return state
    }
    property.property.state.value = PropertyStatus.ACTIVE

    return state
}

const createTaxationSuccess: PropertyReducer = (state, action) => {
    if (!state[action.id]) {
        return state
    }
    const propertyTaxations = state[action.id].taxations
    propertyTaxations.push(action.taxation)

    return state
}

const attachAttributesSuccess: PropertyReducer = (state, action) => {
    const property = state[action.propertyId]
    if (!property) {
        return state
    }
    const building = property.buildings.find(b => b.id === action.entityId)

    if (building) {
        building.attributes = action.attributes
    }
    return state
}

const reducers: Reducers<PropertiesState> = {
    [ACTION_PROPERTY_ADD_OWNERSHIP]: AddOwnership,
    [ACTION_PROPERTY_DELETE_OWNERSHIP_SUCCESS]: deleteOwnershipSuccess,
    [ACTION_PROPERTY_UPDATE_OWNERSHIP]: updateOwnership,
    [ACTION_PROPERTY_UPDATE_OWNERSHIP_SUCCESS]: updateOwnershipSuccess,
    [ACTION_PROPERTY_DELETE_OWNERSHIP]: deleteOwnership,
    [ACTION_PROPERTY_GET]: get,
    [ACTION_PROPERTY_GET_SUCCESS]: getSuccess,
    [ACTION_PROPERTY_GET_CONTACT_INFO]: getContactInfo,
    [ACTION_PROPERTY_GET_CONTACT_INFO_SUCCESS]: getContactInfoSuccess,
    [ACTION_PROPERTY_UPDATE]: updateProperty,
    [ACTION_PROPERTY_TAXATION_UPDATE]: updatePropertyTaxation,
    [ACTION_PROPERTY_TAXATION_UPDATE_SUCCESS]: updatePropertyTaxationSuccess,
    [ACTION_PROPERTY_TAXATION_DELETE]: deletePropertyTaxation,
    [ACTION_PROPERTY_TAXATION_DELETE_SUCCESS]: deletePropertyTaxationSuccess,
    [ACTION_ASSIGN_PROPERTY]: assignProperty,
    [ACTION_ASSIGN_PROPERTY_SUCCESS]: assignPropertySuccess,
    [ACTION_UNASSIGN_PROPERTIES]: unassignProperty,
    [ACTION_UNASSIGN_PROPERTIES_SUCCESS]: unassignPropertySuccess,
    [ACTION_PROPERTY_EVENTS_CREATE]: eventsCreate,
    [ACTION_PROPERTY_EVENTS_CREATE_SUCCESS]: eventsCreateSuccess,
    [ACTION_PROPERTY_SAVE]: propertySave,
    [ACTION_PROPERTY_SAVE_SUCCESS]: propertySaveSuccess,
    [ACTION_BUILDING_SAVE]: buildingSave,
    [ACTION_BUILDING_SAVE_SUCCESS]: buildingSaveSuccess,
    [ACTION_SEND_LAST_TAX]: sendLastTax,
    [ACTION_SEND_LAST_TAX_SUCCESS]: sendLastTaxSuccess,
    [ACTION_LOAD_PROPERTY_COMMUNICATION]: loadPropertyCommunication,
    [ACTION_LOAD_PROPERTY_COMMUNICATION_SUCCESS]: loadPropertyCommunicationSuccess,
    [ACTION_SEND_PROPERTY_COMMUNICATION]: sendPropertyCommunication,
    [ACTION_SEND_PROPERTY_COMMUNICATION_SUCCESS]: sendPropertyCommunicationSuccess,
    [ACTION_CREATE_TAXATION]: createTaxation,
    [ACTION_CREATE_TAXATION_SUCCESS]: createTaxationSuccess,
    [ACTION_ATTACH_ATTRIBUTES_SUCCESS]: attachAttributesSuccess,
}

export const propertyReducer = makeReducer<PropertiesState>(reducers, {})
