import React from 'react'
import {
    Grid,
} from '@material-ui/core'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { Field } from 'redux-form'
import { ColumnText, RemoveField } from 'settings-tax-page/helper-functions'

export const ThreeColumnsRow = (props: any) => {
    const {
        editMode = false,
        classes,
        content,
        input = {
            label: '',
            value1: '',
            value2: '',
        },
        isHeader = false,
        fields,
        index,
        removable = false,
    } = props
    return (
        <Grid container>
            <Grid item sm={4} xs={7} className={classes.columnPadding}>
                {(!editMode || isHeader) && (
                    <ColumnText
                        text={content.label}
                        className={isHeader ? classes.settingsLabel : classes.itemPaddding}
                    />
                )}
                {editMode && !isHeader && <Field name={input.label} component={CubitTextFieldAdapter} />}
            </Grid>
            <Grid item sm={1} xs={4}>
                {(!editMode || isHeader) && (
                    <ColumnText
                        text={content.value1}
                        className={isHeader ? classes.settingsLabel : classes.itemPaddding}
                    />
                )}
                {editMode && !isHeader && (
                    <Field name={input.value1} component={CubitTextFieldAdapter} className={classes.shortTextInput} />
                )}
            </Grid>
            <Grid item sm={1} xs={4}>
                {(!editMode || isHeader) && (
                    <ColumnText
                        text={content.value2}
                        className={isHeader ? classes.settingsLabel : classes.itemPaddding}
                    />
                )}
                {editMode && !isHeader && (
                    <Field name={input.value2} component={CubitTextFieldAdapter} className={classes.shortTextInput} />
                )}
            </Grid>
            {editMode && removable && (
                <Grid item xs={1}>
                    <RemoveField fields={fields} index={index} />
                </Grid>
            )}
        </Grid>
    )
}
