import { CubitCalendarExtendedProps } from 'models/calendar-event'
import { PropertyValueType } from 'models/property'
import { TaxationType } from 'models/taxation'

const propertyValueTypeColors: {[key: string]: string} = {
    [PropertyValueType.BO]: '#1C5253',
    [PropertyValueType.FR]: '#1C5253',
    [PropertyValueType.GB]: '#B38A58',
    [PropertyValueType.GF]: '#B38A58',
    [PropertyValueType.GU]: '#417505',
    [PropertyValueType.IV]: '#417505',
    [PropertyValueType.KO]: '#417505',
    [PropertyValueType.NE]: '#42493A',
    [PropertyValueType.UB]: '#42493A',
    [PropertyValueType.UF]: '#42493A',
    [PropertyValueType.UN]: '#6B2737',
    [PropertyValueType.UT]: '#6B2737',
}

export default (entity: CubitCalendarExtendedProps): string => {
    //ignore property type color in case of tribunal complaint taxation
    if (entity.taxationType === TaxationType.ComplaintTribunal) {
        return '#f44336'
    }
    return propertyValueTypeColors[entity.valueType] || '#1C5253'
}
