import React from 'react'
import { Grid, makeStyles, Theme } from '@material-ui/core'
import { reduxForm, InjectedFormProps, FieldArray, getFormValues } from 'redux-form'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { DocumentTemplate } from 'models/document-template'
import { EditTemplate } from './document-template-edit'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => {
    return {
        button: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        header: {
            fontWeight: 600,
        },
        settingsLabel: {
            fontWeight: 600,
            fontSize: 17,
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
        },
        sidebar: {
            marginLeft: 20,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginTop: 20,
            },
        },
        paperContainer: {
            padding: theme.spacing(2, 2, 1, 2),
        },
        formContainer: {
            // [theme.breakpoints.down('xs')]: {
            //     marginTop: 60,
            // },
        },
        textTemplate: {
            cursor: 'pointer',
            margin: theme.spacing(1, 0, 1, 0),
            padding: theme.spacing(1),
            backgroundColor: '#ffffff',
            borderRadius: 3,
        },
        selectedTemplate: {
            backgroundColor: '#f0f0f0',
        },
        noScroll: {
            overflow: 'hidden'
        },
        midInput: {
            margin: theme.spacing(2, 0)
        },
        lastInput: {
            marginBottom: theme.spacing(2)
        },
        dropContainer: {
            minHeight: theme.spacing(5),
        }
    }
})

type TemplateFormProps = {
    texts: DocumentTemplate[]
    templates: DocumentTemplate[]
    images: DocumentTemplate[]
}

const Form: React.FC<InjectedFormProps<any, TemplateFormProps> & TemplateFormProps> = (props) => {
    const { 
        handleSubmit,
        texts,
        templates,
        images,
    } = props
    const classes = useStyles()

    const formStateTemplate = useSelector(getFormValues(TaxFormName.TemplateDocumentForm)) as DocumentTemplate

    return (
        <form onSubmit={handleSubmit}>
            <Grid container className={classes.formContainer}>
                <FieldArray
                    name={TaxFormFieldNames.docTemplate}
                    component={EditTemplate}
                    texts={texts}
                    template={formStateTemplate}
                    templates={templates}
                    images={images}
                />
            </Grid>
        </form>
    )
}

interface FormProps {}

export const TemplateDocumentForm = reduxForm<{}, FormProps & TemplateFormProps>({
    form: TaxFormName.TemplateDocumentForm,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
