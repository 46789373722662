import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from 'utils/hooks/hooks'
import { setSessionKey } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { actionTaxationGet } from 'property-taxation-edit-page/taxation-actions'
import { actionGetTemplates } from 'settings-page/settings-actions'
import { DocumentTypes } from 'common/enums/document-types'
import DocumentTemplateComponent from '../settings-templates/document-template'
import { editorStyleMap, textFromTextTemplates } from 'settings-templates/template-document-helper'
import { Editor } from 'draft-js'
import './preview.css'
import { plantsUrl } from 'services/httpService'
import { StaticTemplate } from './static-template'
import { Divider } from '@material-ui/core'
import { StaticTemplateType } from 'models/taxation'

export const PrieviewPage: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch()
    //query variables
    const sessionKey = useQuery('sessionKey')
    const staticTemplate = useQuery('staticTemplate')
    const taxId = useQuery('taxId')
    const letterId = useQuery('letterId')
    const printFooter = useQuery('footer') !== ''
    const page = useQuery('page')
    const pages = useQuery('pages')
    const recipientId = useQuery('recipientId')

    const taxation = useSelector((state: AppState) => state.taxations[taxId])
    const docTemplates = useSelector((state: AppState) => state.taxSettings[DocumentTypes.CASE_DOCUMENT].templates)
    const texts = useSelector((state: AppState) => state.taxSettings[DocumentTypes.TEXT].templates)
    const letters = useSelector((state: AppState) => state.taxSettings[DocumentTypes.LETTER].templates)
    const documentTemplate = docTemplates ? docTemplates[letterId] : null
    const initialLetterTemplate = letters && documentTemplate ? letters[documentTemplate.templateId] : null
    let letterTemplate: any = null
    //use the letter template and case document content for the preview
    if (documentTemplate && initialLetterTemplate) {
        letterTemplate = {
            ...initialLetterTemplate,
            textIds: documentTemplate.textIds,
        }
    }

    useEffect(() => {
        if (sessionKey) {
            setSessionKey(sessionKey)
        }
    }, [sessionKey])

    useEffect(() => {
        if (taxId) {
            dispatch(actionTaxationGet(taxId))
            //load all templates to get templates and content
            dispatch(actionGetTemplates())
        }
    }, [dispatch, taxId])

    //set the flag on image load or data load since EO seem to check for DOM load only
    const [contentReady, setContentReady] = useState(false)
    const [imagesLoaded, setImagesLoaded] = useState({})

    useEffect(() => {
        if (contentReady) {
            // @ts-ignore
            if (window.eoapi && eoapi.isEOPdf()) {
                setTimeout(() => {
                    console.log('Calling eoapi.convert() to let EO know that we are ready for conversion.')
                    // @ts-ignore
                    eoapi.convert()
                })
            } else {
                setTimeout(
                    () =>
                        console.log(
                            'We are not using EO here, but this is where eoapi.convert() would have been called to signal that we are ready to take the preview.',
                        ),
                    0,
                )
            }
        }
    }, [contentReady])

    if (!taxation?.id || !initialLetterTemplate) {
        return <div>Loading...</div>
    }
    const images = letterTemplate?.elements?.filter((e: any) => e.type === DocumentTypes.IMAGE.toLowerCase()) || []
    const footerImages =
        letterTemplate?.footerElements?.filter((e: any) => e.type === DocumentTypes.IMAGE.toLowerCase()) || []
    const allImages = [...images, ...footerImages]

    const onImageLoad = (id: string) => {
        if (contentReady) {
            return true
        }
        const values = Object.values(imagesLoaded)
        const loaded = values.length === allImages.length + 1
        const keys = Object.keys(imagesLoaded)
        if (!loaded && !keys.includes(id)) {
            const newLoaded = {
                ...imagesLoaded,
                [id]: true,
            }
            
            setImagesLoaded(newLoaded)
        }
        if (loaded) {
            setContentReady(true)
        }
    }
    //add image onLoad check to be sure all the content is ready
    const LoadChecker = () => {
        if (taxation && letterTemplate) {
            const tenantId: string = taxation.staticSettings?.tenantId || ''
            const logo = tenantId ? `logo-${tenantId}.svg` : 'gridcompanylogo-demo.svg'
            const logoSrc = `/images/${logo}`

            return (
                <>
                    <img
                        key={-1}
                        src={logoSrc}
                        alt=""
                        onLoad={() => onImageLoad(logoSrc)}
                        style={{ width: 1, height: 1, visibility: 'hidden' }}
                    />
                    {allImages.map((e: any, index: number) => {
                        const imageSrc = plantsUrl('/documentTemplate/images/') + e.id
                        return (
                            <img
                                key={index}
                                src={imageSrc}
                                alt=""
                                onLoad={() => onImageLoad(imageSrc)}
                                style={{ width: 1, height: 1, visibility: 'hidden' }}
                            />
                        )
                    })}
                </>
            )
        }
        return <></>
    }

    //structure the data for the document
    const owner: any = taxation.current.owners.find((o: any) => o.id === recipientId)
    const postalAddress = {
        ...owner?.postalAddress,
        name: owner?.name,
        addressText: owner?.postalAddress?.addressText,
        postalInfo: owner?.postalAddress?.postalCode,
    }
    const data: any = {
        taxation: taxation,
        postalAddress: postalAddress,
        //TODO get correct company details or remove them if not needed
        company: {
            name: 'De Sakkyndige',
            phone: '88888888',
            webpage: 'desakkyndige.no',
            postalAddress: {
                addressText: 'Sakkyndigveien 32',
                postalCode: '1470',
            },
        },
        loggedinuser: {
            name: 'Bruker Navn',
            type: 'Company',
            title: 'Brukerens tittel',
        },
        loggedinusercompany: {
            name: 'De Sakkyndige',
            phone: '88888888',
            webpage: 'desakkyndige.no',
            postalAddress: {
                addressText: 'Sakkyndigveien 32',
                postalCode: '1470',
            },
            visitingAddress: {
                addressText: 'Sakkyndigveien 30',
                postalCode: '1470',
            },
        },
        loggedinusercompanypostalcode: {
            code: '1470',
            city: 'LØRENSKOG',
        },
    }

    const wkparams = page
        ? {
              page: page,
              pages: pages,
          }
        : null

    //get editor state
    const editorTexts = textFromTextTemplates(Object.values(texts), data, taxation)

    return (
        <div className="letter-container">
            <div className="letter-wrapper">
                {texts && taxation && letterTemplate && !printFooter && (
                    <>
                        <DocumentTemplateComponent
                            forPrint
                            noBorder
                            template={letterTemplate}
                            texts={editorTexts}
                            data={data}
                            usersign="false"
                        />

                        {editorTexts.length && !staticTemplate && (
                            <div className="texts">
                                {editorTexts
                                    .filter((text: any) => documentTemplate.textIds.includes(text.id))
                                    .map((t: any, i: number) => (
                                        <div key={i} className={t.pageBreak}>
                                            <Editor
                                                readOnly={true}
                                                customStyleMap={editorStyleMap}
                                                editorState={t.state}
                                                onChange={console.log}
                                            />
                                        </div>
                                    ))}
                            </div>
                        )}
                        {staticTemplate && <StaticTemplate taxation={taxation} templateType={staticTemplate as StaticTemplateType} />}
                        <LoadChecker />
                    </>
                )}
                {texts && taxation && letterTemplate && printFooter && (
                    <>
                        <Divider style={{backgroundColor: '#000'}} />
                        <DocumentTemplateComponent
                            forPrint
                            isFooter
                            noBorder
                            template={letterTemplate}
                            texts={editorTexts}
                            data={data}
                            wkparams={wkparams}
                            usersign="false"
                        />
                        <LoadChecker />
                    </>
                )}
            </div>
        </div>
    )
}
