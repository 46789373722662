import React, { memo, ReactElement, useCallback, useEffect, useRef } from 'react'
import { Draggable } from '@fullcalendar/interaction'
import { Grid } from '@material-ui/core'

type Props<T> = {
    events: T[]
    Component: React.FC<any>
    onSelect?: Function
}

type DraggableEventProps = {
    eventData: any
}

const DraggableEvent: React.FC<DraggableEventProps> = memo(({ children, eventData }) => {
    const ref = useRef(undefined)

    useEffect(() => {
        if (ref.current !== undefined) {
            const draggable = new Draggable(ref.current, { eventData })
            return () => draggable.destroy()
        }
    }, [ref, eventData])

    return (
        <div ref={ref} key={eventData.title}>
            {children}
        </div>
    )
})

export function DraggableEvents<T>(props: Props<T>): ReactElement {
    const { Component, events, onSelect } = props
    return (
        <Grid container direction="column">
            <Grid item>
                {events.map((item, i) => (
                    <DraggableEvent key={item.id} eventData={item}>
                        <Component data={item} onSelect={onSelect} />
                    </DraggableEvent>
                ))}
            </Grid>
        </Grid>
    )
}
