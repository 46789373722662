import { sortBy } from "lodash"

export type User = {
    id: string
    name: string
    email: string
    taxCompanyId: string
    isActive: boolean
    type: string
    tags: string[]
    roles: any[]
    tenantId: string
    godMode: boolean
    // mapped props
    taxCompanyName: string
}

export const getUsersArray = (users: {[key: string]: User}) => sortBy(sortBy(Object.keys(users).map(key => users[key]), u => u.name))