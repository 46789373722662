import { Verifiable } from './verifiable'

export type Floor = {
    floorNumber: string
    area: number
    usage: string
    pricePerM2: number
    usageFactor: number
    matrikkelUnitId?: string
    
    // mapped props
    id: number
    value: number
}

export type VerifiableFloor = Floor & Verifiable

export type ComplaintFloor = Floor & {
    correctArea: number
    correctUsage: string
    adjustedArea?: number
    correctUsageApproved: string
    usageFactor: string
}

export const floorBelongsToProperty = (floor: Floor, propId: string) => !floor.matrikkelUnitId || floor.matrikkelUnitId === propId