import React, { useRef, useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as ol from 'ol'
import { Coordinate } from 'ol/coordinate'
import MapContext from './map-context'
import { ProjectionLike } from 'ol/proj'

import 'ol/ol.css'

const useStyles = makeStyles((theme) => ({
    map: {
        position: 'absolute',
        width: 'calc(100% - 67px)',
        maxWidth: 'calc(100% - 67px)',
        height: 'calc(100vh - 64px)',
        top: 64,
        left: 67,
        '&.ol-zoom': {
            top: 85,
            left: 20,
        },
        [theme.breakpoints.down('sm')]: {
            left: 0,
            width: '100%',
            maxWidth: '100%',
        },
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        width: 'calc(100% - 67px)',
        maxWidth: 'calc(100% - 67px)',
        height: 'calc(100vh - 64px)',
        top: 64,
        left: 67,
        background: 'rgba(0,0,0,0.5)',
        zIndex: 2,
    },
}))

interface Map {
    children: React.ReactNode
    loading: Boolean
    zoom?: number
    initCenter?: Coordinate
    maxZoom?: number
    projection?: ProjectionLike
}

const Map: React.FC<Map> = ({
    children,
    loading,
    zoom = 0,
    initCenter = undefined,
    maxZoom = undefined,
    projection = undefined,
}) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const refInitCenter = useRef<Coordinate | undefined>(undefined)
    const [map, setMap] = useState<ol.Map | null>(null)
    const classes = useStyles()

    useEffect(() => {
        let view = new ol.View({ zoom, center: initCenter, projection })

        if (maxZoom) view.setMaxZoom(maxZoom)

        let mapObject: ol.Map = new ol.Map({ view })
        if (ref && ref.current) {
            mapObject.setTarget(ref.current)
        }
        setMap(mapObject)

        return () => {
            mapObject.setTarget(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!map) return
        map.getView().setZoom(zoom)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoom])

    useEffect(() => {
        if (!map || !initCenter || refInitCenter.current) return
        refInitCenter.current = initCenter
        map.getView().setCenter(initCenter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initCenter])

    useEffect(() => {
        if (!map || !maxZoom) return
        map.getView().setMaxZoom(maxZoom)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxZoom])

    return (
        <MapContext.Provider value={{ map, loading }}>
            {loading && (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            )}
            <div id="map" ref={ref} className={classes.map}>
                {children}
            </div>
        </MapContext.Provider>
    )
}
export default Map
