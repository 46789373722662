import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import FormGroup from '@material-ui/core/FormGroup'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => {
    const primaryColor = theme.palette.primary.main
    const grey = theme.palette.grey[300]

    return {
        slider: {
            '& .rc-slider-rail': { backgroundColor: grey },
            '& .rc-slider-track': { backgroundColor: primaryColor },
            '& .rc-slider-handle, & .rc-slider-dot-active ': { borderColor: primaryColor },
        },
        sliderWrapper: {
            height: '48px',
            width: '680px',
            padding: '8px 3px 4px 3px',
        },
    }
})

export const firstMarkStyle = {
    transform: 'none',
}

export const lastMarkStyle = {
    left: 'unset',
    right: 0,
    transform: 'none',
}

export type CubitSliderRangeAdapterProps = { sliderRange: boolean }

const CubitSliderRangeAdapter: React.FC<CubitSliderRangeAdapterProps> = (props: any) => {
    const {
        input: { name, value, onChange, ...restInput },
        options,
        defaultValue,
        formcontrolprops,
        meta,
        ...rest
    } = props

    const textFiledProps = (restInput as unknown) as InputBaseComponentProps

    const classes = useStyles()

    const val = value || defaultValue || []

    //@ts-ignore
    const SliderRange = Slider.Range

    return (
        <FormControl>
            <FormGroup row className={classes.sliderWrapper}>
                <SliderRange
                    className={classes.slider}
                    defaultValue={defaultValue}
                    value={val}
                    name={name}
                    onChange={(a: any) => {
                        onChange(a)
                    }}
                    inputProps={textFiledProps}
                    {...rest}
                />
            </FormGroup>
        </FormControl>
    )
}

export { CubitSliderRangeAdapter }
