import * as React from 'react'

import { useMemo, useRef, useEffect } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import LayoutDefault from '../common/layout/layout-default'
import Navigation from '../common/navigation/navigation'
import { calendarSelector, ReduxCalendar } from 'shared-components/src/cubit-calendar/redux-calendar'
import { Grid, makeStyles, Theme, Hidden } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { CalendarView } from 'shared-components/src/cubit-calendar/calendar'
import {
    actionCubitCalendarEventDropExternal,
    actionCubitCalendarEventsUnSelect,
    actionCubitCalendarSetDate,
    actionCubitCalendarSetView,
} from 'shared-components/src/cubit-calendar/calendar-actions'
import { AppState } from 'app/app-store'
import { InlineDatePicker } from 'common/inline-date-picker/inline-date-picker'
import { PlanningContextBar } from './planning-context-bar/planning-context-bar'
import { PlanningToolbar } from './planning-toolbar/planning-toolbar'
import { grey, red } from '@material-ui/core/colors'
import classnames from 'classnames'
import { tr } from 'utils/translations/translate'
import { TEXT_REMOVE_EVENT } from 'utils/translations/keys'
import { actionPlanningGetUserEvents } from './planning-actions'
import { CubitCalendarEvent } from 'shared-components/src/models/calendar-event'
import { DateTime } from 'luxon'

import { PlanningPageEvents } from './planning-page-events/planning-page-events'
import { getFormValues } from 'redux-form'
import { useUsers, useCompanies } from 'app/app-selectors'
import { sortBy } from 'lodash'
import {
    PlanningUserCalendarSelectFormName,
    PlanningUserCalendarSelectForm,
} from './planning-user-calendar-select/planning-user-calendar-select'
import { TaxationState } from 'models/taxation'
import { renderPlanningCalendarEvent } from './planning-calendar-event/planning-calendar-event'
import { useUserId } from 'auth/auth-selectors'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { CubitCalendarExtendedProps } from 'models/calendar-event'
import { Language } from '../../../shared-components/src/settings/language/language.enum'

const useStyles = makeStyles((theme: Theme) => ({
    planningConent: {
        height: '100%',
        maxHeight: 'calc(100vh - 56px)',
        overflow: 'hidden',
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            maxHeight: 'calc(100vh - 48px)',
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: 'calc(100vh - 64px)',
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            maxHeight: 'inherit',
            minHeight: 0,
            overflow: 'auto',
            flexDirection: 'row',
        },
    },
    contentLeft: {
        backgroundColor: '#f0f0f0',
        borderRight: '1px solid ' + grey[300],
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        width: '290px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '50%',
            backgroundColor: '#fff',
            width: '100%',
        },
    },
    contentCalendar: {
        backgroundColor: '#fff',
        maxHeight: '100%',
        minHeight: '100%',
        borderRight: '1px solid ' + grey[300],
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            minHeight: '500px',
        },
    },
    datePicker: {
        backgroundColor: '#fff',
        maxWidth: '360px',
        margin: '0 auto',
    },
    contentRight: {
        maxHeight: '100%',
        width: '320px',
    },
    eventsContainer: {
        maxHeight: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1),
        },
    },
    removeEventDropArea: {
        color: red[200],
        fontWeight: 300,
        textTransform: 'uppercase',
        backgroundColor: red[50],
        border: '2px dashed' + red[200],
        margin: '4px 4px 16px 4px',
        height: '100px',
        padding: theme.spacing(2, 2, 2, 2),
        width: 'calc(100% - 8px)',
    },
    eventsContainerReceivingDrop: {
        backgroundColor: 'rgba(255,255,255,0.45)',
        border: '2px dashed' + grey[300],
    },
}))

const onEventClick = (event: CubitCalendarEvent<CubitCalendarExtendedProps>) => {
    navigate(`/taxation/${event.id}/edit`)
}

export const PlanningPage: React.FC<RouteComponentProps> = () => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const calendar = useSelector(calendarSelector)
    const setDate = (x: string) => dispatch(actionCubitCalendarSetDate(x))
    const setView = (x: CalendarView) => dispatch(actionCubitCalendarSetView(x))

    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)
    const events = useSelector((state: AppState) => state.planning.events)
    const selectedEvents = events.filter(x => x.isSelected)

    const dateClickCallback = useMemo(
        () => ({
            cb: (calendarData: any) => {
                if (selectedEvents.length !== 0 && calendarData.view.type === CalendarView.Day) {
                    const date = DateTime.fromJSDate(new Date(calendarData.date)).setLocale(language).toLocaleString(DateTime.DATETIME_FULL)
                    //TODO get a translation/confirmation for this
                    if (window.confirm('Move selected to ' + date + '?')) {
                        const eventStart = {start: calendarData.date}
                        dispatch(actionCubitCalendarEventDropExternal(eventStart))
                        dispatch(actionCubitCalendarEventsUnSelect())
                    }
                }
            }
        }), [selectedEvents.length, language, dispatch]
    )

    const sortEventsByAddress = (a: CubitCalendarEvent<CubitCalendarExtendedProps>, b: CubitCalendarEvent<CubitCalendarExtendedProps>): number => {
        if (!b.extendedProps.relatedEntity.propertyAddresses) {
            return 1
        }
        if (a.extendedProps.relatedEntity.propertyAddresses > b.extendedProps.relatedEntity.propertyAddresses) {
            return 1
        }
        if (a.extendedProps.relatedEntity.propertyAddresses < b.extendedProps.relatedEntity.propertyAddresses) {
            return -1
        }
        return 0
    }

    const e = useMemo(
        () => ({
            planned: events
                .filter((x: CubitCalendarEvent<CubitCalendarExtendedProps>) => !!x.start)
                .map(e => {
                    const taxationState = e.extendedProps.relatedEntity.state
                    if (
                        taxationState !== TaxationState.TaxationPlanned &&
                        taxationState !== TaxationState.TaxationReadyForPlanning
                    ) {
                    //remove the end date to limit items height until we know how long the taxations might take
                    if (taxationState === TaxationState.TaxationFinished || taxationState === TaxationState.TribunalFinished) {
                        return { ...e, editable: false, end: undefined }
                    }
                    return { ...e, editable: false }
                    } else {
                        return {...e}
                    }
                }),
            notPlanned: events.filter((x: CubitCalendarEvent<CubitCalendarExtendedProps>) => !x.start).sort(sortEventsByAddress),
        }),
        [events],
    )

    const removeContainer = useRef(null)
    const unassignContainer = useRef(null)
    const removeContainerMobile = useRef(null)
    const unassignContainerMobile = useRef(null)

    const currentUserId = useUserId()
    const userSelectFormValue = useSelector(getFormValues(PlanningUserCalendarSelectFormName)) as {
        id: string
    }
    const selectedUserId = (userSelectFormValue && userSelectFormValue.id) || currentUserId
    const companies = useCompanies()
    const users = useUsers()
    const usersArray = sortBy(Object.keys(users).map(key => users[key]), u => u.id !== currentUserId && u.name)
    const usersWithoutCompanyArray = usersArray.filter(u => !u.taxCompanyId)

    const calendarScrollTime = ''

    const dateString = useSelector((state: AppState) => state.calendar.date)
    const date = DateTime.fromJSDate(new Date(dateString))
    const loadingPlanned = useSelector((state: AppState) => state.planning.loadingPlanned)

    useEffect(() => {
        if (!loadingPlanned) {
            dispatch(actionPlanningGetUserEvents(selectedUserId, date.year, date.month))
        }
        //we want this to be triggered by date change only as we handle user change elswhere
    }, [dispatch, date.year, date.month])

    return (
        <LayoutDefault
            contextbar={<PlanningContextBar events={e.planned} />}
            toolbar={<PlanningToolbar view={calendar.view} onChange={setView} />}
            navigation={<Navigation />}
            contentPadding={0}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={styles.planningConent}>
                <Grid item className={styles.contentLeft}>
                    <Grid item className={styles.datePicker}>
                        <InlineDatePicker
                            date={calendar.date}
                            onChange={(date: string) => setDate(date)}
                            view={calendar.view}
                        />
                    </Grid>

                    <Protected p={[Permission.TaxateAllTaxations, Permission.TaxateOwnCompanyTaxations]}>
                        <PlanningUserCalendarSelectForm
                            initialValues={{ id: selectedUserId }}
                            companies={companies}
                            users={users}
                            usersWithoutCompanyArray={usersWithoutCompanyArray}
                        />
                    </Protected>

                    {calendar.view !== CalendarView.List && (
                        <Hidden lgUp>
                            <Grid item>
                                {calendar.isDragging ? (
                                    <Grid
                                        container
                                        ref={removeContainerMobile}
                                        className={styles.removeEventDropArea}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item> {tr(TEXT_REMOVE_EVENT)}</Grid>
                                    </Grid>
                                ) : null}

                                <Grid
                                    ref={unassignContainerMobile}
                                    container
                                    className={classnames(styles.eventsContainer, {
                                        [styles.eventsContainerReceivingDrop]: calendar.isDragging,
                                    })}
                                >
                                    <PlanningPageEvents events={e.notPlanned}></PlanningPageEvents>
                                </Grid>
                            </Grid>
                        </Hidden>
                    )}
                </Grid>

                <Grid item xs className={styles.contentCalendar}>
                    <ReduxCalendar
                        removeContainer={removeContainer}
                        unassignContainer={unassignContainer}
                        removeContainerMobile={removeContainerMobile}
                        unassignContainerMobile={unassignContainerMobile}
                        events={e.planned}
                        onRender={renderPlanningCalendarEvent}
                        onDateClick={dateClickCallback.cb}
                        onEventClick={onEventClick}
                        scrollTime={calendarScrollTime}
                    />
                </Grid>

                {calendar.view !== CalendarView.List && (
                    <Hidden mdDown>
                        <Grid item className={styles.contentRight}>
                            {calendar.isDragging ? (
                                <Grid
                                    container
                                    ref={removeContainer}
                                    className={styles.removeEventDropArea}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item> {tr(TEXT_REMOVE_EVENT)}</Grid>
                                </Grid>
                            ) : null}

                            <Grid
                                ref={unassignContainer}
                                container
                                className={classnames(styles.eventsContainer, {
                                    [styles.eventsContainerReceivingDrop]: calendar.isDragging,
                                })}
                            >
                                <PlanningPageEvents events={e.notPlanned}></PlanningPageEvents>
                            </Grid>
                        </Grid>
                    </Hidden>
                )}
            </Grid>
        </LayoutDefault>
    )
}
