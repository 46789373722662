import React from 'react'
import { CubitTableColumn, CubitTableWrapperProps } from 'shared-components/src/cubit-table/cubit-table.types'
import CubitTable from 'shared-components/src/cubit-table/cubit-table'

import { navigate } from '@reach/router'
import { Property } from 'models/property'
import { Taxation, TaxationState } from 'models/taxation'
import {
    TEXT_PROPERTY,
    TEXT_VALUE_TYPE,
    TEXT_ZONE_FACTOR,
    TEXT_FACTOR_CHANGE,
    TEXT_METRIC_CHANGE,
    TEXT_NOTE_CHANGE,
    TEXT_YES,
    TEXT_LOCATION_FACTOR,
    TEXT_INCOMPETENT,
    TEXT_APPROVED,
    TEXT_DECLINED,
    TEXT_NOTE,
    TEXT_TARIFF,
    TEXT_LAST_TARIFF,
    TEXT_STANDARD_FACTOR,
    TEXT_FIRST_NAME,
    TEXT_LAST_NAME,
    TEXT_ADDRESS,
    TEXT_ZIP_CODE,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

import { formatCurrency } from 'shared-components/src/utils'
import { TribunalGroupState } from './tribunal-group-state.enum'
import { Tooltip } from '@material-ui/core'
import CommentIcon from '@material-ui/icons/Comment'
import { useUsers } from 'app/app-selectors'
import { isEmpty, includes } from 'lodash'
import {
    getPropertyValueTypeTranslationKey,
    getPropertyLocationFactorTranslationKey,
    getPropertyAddresses,
    getStandardFactorTranslationKey,
    userHasPermission,
} from 'utils'
import { Building } from 'models/building'
import { IntermediateTribunalGroupState } from './intermediate-tribunal-group-state.enum'
import { Owner } from 'models/owner'
import { Permission } from 'common/enums/permission'
import { useUser, useUserId } from 'auth/auth-selectors'
import { useSelector } from 'react-redux'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { simpleFormatNumber } from 'shared-components/src/utils/format/format-number'
import { getFullMatrikkelAddress, getTotalValue } from 'utils/tribunal/tribunal-helper'

const taxationColumns = (taxationSettings: any): CubitTableColumn[] => [
    {
        headerLabel: '',
        key: 'current.property.holdingNumber',
        getDisplayableElement: (row) => (
            <span>{getFullMatrikkelAddress(row.current.property)}</span>
        ),
    },
    {
        headerLabel: tr(TEXT_PROPERTY),
        key: 'address',
        getDisplayableElement: (row) => <span>{`${getPropertyAddresses(row.current.housingUnits)}`}</span>,
    },
    {
        headerLabel: tr(TEXT_VALUE_TYPE),
        key: 'current.property.valueType',
        getDisplayableElement: (row) => (
            <span>{tr(getPropertyValueTypeTranslationKey(row.current.property.valueType))}</span>
        ),
    },
    {
        headerLabel: tr(TEXT_TARIFF),
        key: 'current.property.totalValue',
        getDisplayableElement: (row) => <span>{formatCurrency(getTotalValue(row))}</span>,
    },
    {
        headerLabel: tr(TEXT_LAST_TARIFF),
        key: 'lastTax',
        getDisplayableElement: (row) => <span>{formatCurrency(0)}</span>,
        sortable: false,
    },
    {
        headerLabel: tr(TEXT_ZONE_FACTOR),
        key: 'current.property.zoneFactor',
        getDisplayableElement: (row) => <span>{row.current.property.zoneFactor}</span>,
    },
    {
        headerLabel: tr(TEXT_LOCATION_FACTOR),
        key: 'current.property.locationFactor',
        getDisplayableElement: (row) => (
            <span>
                {simpleFormatNumber(row.current.property.locationFactor)}{' '}
                {getPropertyLocationFactorTranslationKey(
                    row.current.property.locationFactor,
                    taxationSettings.locationFactors,
                )}
            </span>
        ),
    },
    {
        headerLabel: tr(TEXT_STANDARD_FACTOR),
        key: 'standardFactor',
        getDisplayableElement: (row) => (
            <span>
                {row.current.buildings.map((b: Building, index: number) => (
                    <div key={index}>
                        {simpleFormatNumber(b.standardFactor)}{' '}
                        {getStandardFactorTranslationKey(b.standardFactor, taxationSettings.standardFactors)}
                    </div>
                ))}
            </span>
        ),
    },
    {
        headerLabel: tr(TEXT_FACTOR_CHANGE),
        key: 'factorChanged',
        getDisplayableElement: (row) => <span>{row.factorChanged ? tr(TEXT_YES) : '-'}</span>,
        align: 'center',
    },
    {
        headerLabel: tr(TEXT_METRIC_CHANGE),
        key: 'propertyChanged',
        getDisplayableElement: (row) => <span>{row.propertyChanged ? tr(TEXT_YES) : '-'}</span>,
        align: 'center',
    },
    {
        headerLabel: tr(TEXT_NOTE_CHANGE),
        key: 'commentChanged',
        getDisplayableElement: (row) => <span>{row.commentChanged ? tr(TEXT_YES) : '-'}</span>,
        align: 'center',
    },
]

const taxationOwnersColumns = (): CubitTableColumn[] => [
    {
        headerLabel: tr(TEXT_LAST_NAME),
        key: 'firstName',
        getDisplayableElement: (row: Taxation) => (
            <>
                {row.current.owners.map((o: Owner, index) => (
                    <div key={index}>{o.firstName}</div>
                ))}
            </>
        ),
    },
    {
        headerLabel: tr(TEXT_FIRST_NAME),
        key: 'lastName',
        getDisplayableElement: (row: Taxation) => (
            <>
                {row.current.owners.map((o: Owner, index) => (
                    <div key={index}>{o.lastName}</div>
                ))}
            </>
        ),
    },
    {
        headerLabel: tr(TEXT_ADDRESS),
        key: 'address',
        getDisplayableElement: (row: Taxation) => (
            <>
                {row.current.owners.map((o: Owner, index) => (
                    <div key={index}>{(o.postalAddress && o.postalAddress.addressText) || ''}</div>
                ))}
            </>
        ),
    },
    {
        headerLabel: tr(TEXT_ZIP_CODE),
        key: 'postNumber',
        getDisplayableElement: (row: Taxation) => (
            <>
                {row.current.owners.map((o: Owner, index) => (
                    <div key={index}>{`${(o.postalAddress && o.postalAddress.postalCode) || ''} ${
                        (o.postalAddress && o.postalAddress.foreignCity) || ''
                    }`}</div>
                ))}
            </>
        ),
    },
]

export const TribunalGroupTablePrefix = 'tribunalGroupTable-'

export const TribunalGroupTaxationsTable: React.FC<
    CubitTableWrapperProps<Taxation[]> & {
        groupState: TribunalGroupState
        groupId: string
        tableName: string
        intermediateGroupState: IntermediateTribunalGroupState | null
    }
> = (props) => {
    const { results, groupState, groupId, tableName, intermediateGroupState } = props
    const taxationSettings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
    const taxationTableColumns =
        intermediateGroupState === IntermediateTribunalGroupState.PlannedConflictsReview
            ? taxationOwnersColumns()
            : taxationColumns(taxationSettings)
    const users = useUsers()
    const currentUser = useUser()
    const currentUserId = useUserId()

    const hasTribunalAllPermission = userHasPermission(currentUser, Permission.ViewTribunalAll)

    const tableSelectable = isTableSelectable({ groupState, hasTribunalAllPermission, intermediateGroupState })

    const tableClickable =
        intermediateGroupState !== IntermediateTribunalGroupState.PlannedConflictsReview || hasTribunalAllPermission

    const handleRowClick = tableClickable
        ? (row: Property) => {
              navigate(`/tribunal/group/${groupState}/${groupId}/taxation/${row.id}`)
          }
        : undefined

    if (intermediateGroupState === IntermediateTribunalGroupState.PlannedConflictsReview) {
        taxationTableColumns.push({
            headerLabel: tr(TEXT_INCOMPETENT),
            key: 'conflictedUsers',
            getDisplayableElement: (row: Taxation) => (
                <span>
                    {row.conflictedUsers.length > 0 && !isEmpty(users)
                        ? row.conflictedUsers
                              .filter((userId) => userId === currentUserId)
                              .map((userId) => (users[userId] ? users[userId].name : ''))
                              .join(', ') || '-'
                        : '-'}
                </span>
            ),
            align: 'center',
        })
    }

    if (groupState === TribunalGroupState.Planned && hasTribunalAllPermission) {
        taxationTableColumns.push({
            headerLabel: tr(TEXT_INCOMPETENT),
            key: 'conflictedUsers',
            getDisplayableElement: (row: Taxation) => (
                <span>
                    {row.conflictedUsers.length > 0 && !isEmpty(users)
                        ? row.conflictedUsers.map((userId) => (users[userId] ? users[userId].name : '')).join(', ')
                        : '-'}
                </span>
            ),
            align: 'center',
        })
    }

    if (groupState === TribunalGroupState.Started) {
        taxationTableColumns.push({
            headerLabel: tr(TEXT_APPROVED),
            key: 'state',
            getDisplayableElement: (row: Taxation) => (
                <span>
                    {row.state === TaxationState.TribunalFinished
                        ? tr(TEXT_YES)
                        : row.state === TaxationState.TribunalDeclined || row.state === TaxationState.TaxationFinished
                        ? tr(TEXT_DECLINED)
                        : '-'}
                </span>
            ),
            align: 'center',
        })
    }

    if (groupState === TribunalGroupState.Declined) {
        taxationTableColumns.push({
            headerLabel: `${tr(TEXT_DECLINED)} ${tr(TEXT_NOTE).toLowerCase()}`,
            key: 'declinedReason',
            getDisplayableElement: (row: Taxation) => (
                <span>
                    {row.declinedReason ? (
                        <Tooltip title={row.declinedReason}>
                            <CommentIcon></CommentIcon>
                        </Tooltip>
                    ) : (
                        '-'
                    )}
                </span>
            ),
            align: 'center',
        })
    }

    return (
        <CubitTable
            selectable={tableSelectable}
            sorting
            flat
            columns={taxationTableColumns}
            data={results}
            name={tableName}
            onRowClick={handleRowClick}
        />
    )
}

const isTableSelectable = (props: {
    groupState: TribunalGroupState
    intermediateGroupState: IntermediateTribunalGroupState | null
    hasTribunalAllPermission: boolean
}) => {
    const { groupState } = props

    const statesWhenSelectionShouldBeVisibleToAppAdministrator = [
        TribunalGroupState.Initial,
        TribunalGroupState.Finished,
        TribunalGroupState.Declined,
        TribunalGroupState.Started,
    ]

    const tableSelectable =
        props.intermediateGroupState === IntermediateTribunalGroupState.PlannedConflictsReview || // when tribunal administrator is reviewing conflicts, he can select and set conflicts of interest
        (props.hasTribunalAllPermission && includes(statesWhenSelectionShouldBeVisibleToAppAdministrator, groupState))
    if (!tableSelectable) {
        return false
    }
    if (groupState === TribunalGroupState.Started) {
        //unhandled taxation rows filter
        return (t: Taxation) => t.state !== TaxationState.TribunalFinished && t.state !== TaxationState.TaxationFinished
    }
    return true
}
