import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { RouteComponentProps } from '@reach/router'
import { Grid, makeStyles, Theme, Box } from '@material-ui/core'

import Navigation from 'common/navigation/navigation'
import { getPropertyAddresses } from 'utils'
import { getPropertyPostalCode } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { actionPropertyGet } from './property-actions'
import { ActivityLog } from './activity-log/activity-log'
import { PropertyBuildingDetails } from './property-building-details/property-building-details'
import { PropertyDetails } from './property-details/property-details'
import { PropertyToolbar } from './property-toolbar/property-toolbar'
import { PrivateOwner } from 'models/owner'
import { AppState } from 'app/app-store'
//import { PropertyTaxations } from './property-taxations/property-taxations'
import { actionGetTaxSettings, actionGetYearSettings } from 'settings-page/settings-actions'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { TaxationsPanels } from './taxations-history/taxations-panels'
import { PropertyTotalValuePanel } from './property-details/property-total-value-panel'
import { PropertyDetailsPanel } from './property-details/property-details-panel'
import { PropertyValuePanel } from './property-details/property-value-panel'
import { PropertyOwners } from './property-details/property-owners'
import { PropertyCommunicationPanel } from './property-communication/property-communication-panel'

export const usePropertyPageStyles = makeStyles((theme: Theme) => {
    return {
        propertyDataContainer: {
            marginBottom: 11,
        },
        panelContent: {
            fontSize: theme.spacing(2),
        },

        panelSummary: {
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 600,
        },

        panelContent__label: {
            fontWeight: 600,
            textAlign: 'left',
        },
        panelContent__editButton: {
            fontWeight: 600,
            textAlign: 'right',
        },
        pb3: {
            paddingBottom: theme.spacing(3),
        },
        bold: {
            fontWeight: 600,
        },
        container: {
            padding: theme.spacing(1),
        },
        mb2: {
            marginBottom: theme.spacing(2),
        },
        p2: {
            padding: theme.spacing(2),
        },
        iconSpace: {
            width: 30,
            display: 'inline-block',
        },
        semiRounded: {
            borderRadius: '4px 4px 0 0',
            ' &:before': {
                display: 'none',
            },
        },
        semiGrouped: {
            borderRadius: '4px 4px 0 0',
            marginBottom: 2,
        },
        lessGrouped: {
            borderRadius: '4px 4px 0 0',
            marginBottom: 4,
        },
        radioRight: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        center: {
            marginLeft: 'auto',
            display: 'block',
            [theme.breakpoints.down('xs')]: {
                margin: 0,
            },
        },
        alignStart: {
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'flex-start',
            justifyItems: 'flex-end',
            [theme.breakpoints.down('xs')]: {
                margin: 0,
            },
        },
        alignRight: {
            textAlign: 'right',
            ' & input': {
                textAlign: 'right',
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
                ' & input': {
                    textAlign: 'left',
                },
            },
        },
        gap: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        spacer: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        cancelButton: {
            paddingRight: '10px',
        },
    }
})

export const PropertyPage: React.FC<RouteComponentProps<{ id: string; location: any }>> = (props) => {
    const { id = '0' } = props

    const styles = usePropertyPageStyles()
    const dispatch = useDispatch()
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
    const yearSettings = useSelector((state: AppState) => state.taxSettings.yearSettings)
    const [openedBuilding, setOpenedBuilding] = React.useState<number | undefined>(undefined)
    const [expanded, setExpanded] = React.useState<string | false>(false)

    useEffect(() => {
        if (!yearSettings) {
            dispatch(actionGetYearSettings())
        }
    }, [yearSettings, dispatch])

    useEffect(() => {
        if (!settings.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [settings, dispatch])

    useEffect(() => {
        dispatch(actionPropertyGet(id))
    }, [id, dispatch])

    const data = useSelector((state: AppState) => state.properties[id]) || {}
    const property = data.property || []
    const buildings = data.buildings || []
    const isOpened = (index: number, building: any) => {
        if (index >= 0) {
            setOpenedBuilding(index)
            setExpanded(building)
        }
    }

    const housingUnits = data.housingUnits || []
    const owners = (data.owners as PrivateOwner[]) || ([] as PrivateOwner[])
    const taxations = data.taxations || []

    const propertyAddresses = getPropertyAddresses(housingUnits)
    const propertyPostalCode = getPropertyPostalCode(housingUnits)

    return (
        <LayoutDefault toolbar={<PropertyToolbar propertyData={data} />} navigation={<Navigation />}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item md={12} lg={9}>
                            <Box className={styles.propertyDataContainer}>
                                <PropertyDetailsPanel
                                    styles={styles}
                                    prop={property}
                                    propertyAddresses={propertyAddresses}
                                    propertyPostalCode={propertyPostalCode}
                                />
                            </Box>

                            <Box className={styles.propertyDataContainer}>
                                <PropertyDetails
                                    openedBuilding={openedBuilding}
                                    buildings={buildings}
                                    property={property}
                                    owners={owners}
                                    styles={styles}
                                />
                            </Box>

                            <Box className={styles.propertyDataContainer}>
                                <PropertyOwners owners={owners} styles={styles} property={property} />
                            </Box>

                            <Box className={styles.propertyDataContainer}>
                                <PropertyCommunicationPanel styles={styles} property={property} />
                            </Box>

                            <Box className={styles.propertyDataContainer}>
                                {property && property.plots && (
                                    <PropertyValuePanel property={property} styles={styles} />
                                )}

                                {buildings.map((building, index) => (
                                    <PropertyBuildingDetails
                                        expanded={expanded}
                                        isOpened={isOpened}
                                        index={index}
                                        key={index}
                                        building={building}
                                        property={property}
                                        parentStyle={styles}
                                    />
                                ))}
                            </Box>

                            <Box className={styles.propertyDataContainer}>
                                {property && <PropertyTotalValuePanel styles={styles} prop={property} propertyData={data} />}
                            </Box>

                            <TaxationsPanels taxations={taxations} />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <ActivityLog objectId={property ? property.id : ''} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LayoutDefault>
    )
}
