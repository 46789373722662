import { DateTime } from 'luxon'
import { Building } from 'models/building'
import { LightboxImage } from 'models/lightbox-image'
import { Plot } from 'models/plot'
import { Property } from 'models/property'
import { Taxation } from 'models/taxation'
import { User } from 'models/user'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import { ComplaintCause } from 'tribunal-page/models/complaint-cause.type'
import { coordsFromZone32to33, getPropertyAddresses, getSessionKey } from 'utils'
import { getPropertyBuildingsValue } from 'utils/property/get-property-buildings-value'
import {
    TEXT_TAX_COMPLAINT_CAUSE_1,
    TEXT_TAX_COMPLAINT_CAUSE_2,
    TEXT_TAX_COMPLAINT_CAUSE_3,
    TEXT_TAX_COMPLAINT_CAUSE_4,
    TEXT_TAX_COMPLAINT_CAUSE_5,
    TEXT_TAX_COMPLAINT_CAUSE_6,
    TEXT_OTHERS,
    TEXT_FLOOR,
    TEXT_USE,
    TEXT_ADJUSTED,
    TEXT_APPROVED,
    TEXT_AREA,
    TEXT_READJUSTED,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

export const getCauseTypeText = (causeType: string) => {
    switch (causeType) {
        case ComplaintCauseType.PlotArea:
            return tr(TEXT_TAX_COMPLAINT_CAUSE_1)
        case ComplaintCauseType.PlotType:
            return tr(TEXT_TAX_COMPLAINT_CAUSE_2)
        case ComplaintCauseType.PlotLocation:
            return tr(TEXT_TAX_COMPLAINT_CAUSE_3)
        case ComplaintCauseType.BuildingStandard:
            return tr(TEXT_TAX_COMPLAINT_CAUSE_4)
        case ComplaintCauseType.BuildingArea:
            return tr(TEXT_TAX_COMPLAINT_CAUSE_5)
        case ComplaintCauseType.BuildingPhotos:
            return tr(TEXT_TAX_COMPLAINT_CAUSE_6)
        case ComplaintCauseType.Other:
            return tr(TEXT_OTHERS)
        default:
            return tr(TEXT_OTHERS)
    }
}
export const getBuildingsValue = (buildings: any, taxation: Taxation) => {
    let buildingsValue = 0
    buildings.forEach((building: Building) => {
        //get standardFactor using from taxation buildings
        let standardFactor = taxation.current.buildings.filter((b: Building) => b.id === building.id)[0].standardFactor
        standardFactor = isNaN(standardFactor) ? 1 : standardFactor
        const valueBeforeFactors = building.floors.reduce((acc: number, floor: any) => {
            const area = floor.adjustedArea ? floor.adjustedArea : floor.correctArea ? floor.correctArea : floor.area
            return acc + parseFloat(floor.usageFactor) * Math.round(area) * floor.pricePerM2
        }, 0)

        buildingsValue += standardFactor * valueBeforeFactors
    })
    return buildingsValue
}
export const getDifference = (number1: number, number2: number) => Math.abs(number1 - number2)

export const getBuildingPreviewText = (id: string, taxation: Taxation, buildingCodesMap: { [key: string]: string }) => {
    const building = taxation.current.buildings.filter((building: Building) => building.id === id)[0]
    return `${taxation.current.property.holdingNumber}/${taxation.current.property.subHoldingNumber} - ${building.buildingNumber
        } ${building.buildingCode} ${buildingCodesMap[building.buildingCode]}`
}

export const getAdjustedAreaDifference = (plotSize: number, adjustedPlotSize: number | undefined, correctPlotSize: number | undefined = 0) => {
    const diff = adjustedPlotSize ? plotSize - adjustedPlotSize : plotSize - correctPlotSize
    return Math.abs(diff)
}

export const getPropertyCadastreDetails = (property: Property) => {
    return property && property.holdingNumber
        ? `${property.holdingNumber}/${property.subHoldingNumber}${property.leaseNumber > 0 ? `/${property.leaseNumber}` : ''
        }`
        : ''
}

export const getBuildingTableHeadItem = (index: number) => {
    const items = [
        tr(TEXT_FLOOR),
        tr(TEXT_USE),
        tr(TEXT_ADJUSTED),
        tr(TEXT_APPROVED),
        tr(TEXT_AREA),
        tr(TEXT_ADJUSTED),
        tr(TEXT_READJUSTED),
    ]
    return items[index]
}

export const getComplaintPhotos = (cause: ComplaintCause<ComplaintCauseType.BuildingPhotos>) => {
    const photosArray = cause && cause.buildingsPhotos ? cause.buildingsPhotos.map((photos: any) => photos.map((photo: any) => photo)) : []
    const buildingImages: LightboxImage[] = photosArray.map((file: any) => ({
        src: file,
        title: ' ',
        description: ' ',
    }))

    return buildingImages
}

export const getDate = (timeStamp: string, language: Language, format: any = DateTime.DATE_FULL) => (
    DateTime.fromJSDate(new Date(timeStamp))
        .setLocale(language)
        .toLocaleString(format)
)

export const getTaxationWorker = (taxation: Taxation, user: User) => {
    return user?.name
}

export const calculatePropValue = (taxation: Taxation) => {
    let prop = { ...taxation.current.property }
    let buildings = taxation.current.buildings.filter((building) => !(building.id in taxation.deletedBuildings))

    prop.address = getPropertyAddresses(buildings)

    prop.plotSize = Math.round(prop.plots.reduce((plotSize, plot) => plotSize + plot.area, 0))
    prop.plotValue = 0
    prop.buildingsValue = getPropertyBuildingsValue(prop, taxation.staticSettings, buildings)
    prop.valueBeforeFactors = prop.plotValue + prop.buildingsValue
    prop.totalValue = prop.valueBeforeFactors * prop.locationFactor * prop.zoneFactor
    prop.plotSquareMeterValue = prop.plotSize ? prop.plotValue / prop.plotSize : 0

    return prop
}

export const getComplaintFormsUrl = (taxId: string, auth: any) => {
    const tenantId: any = auth.isInitialized && auth.user.tenantId
    const prod = auth.tenant?.isProduction
    if (prod) {
        return `https://skjema.cubit.no/login/${getSessionKey()}/tax/${taxId}/${tenantId}`
    }
    return `https://skjema-dev.cubit.no/login/${getSessionKey()}/tax/${taxId}/${tenantId}`
}

export const getPropertyMapCoords = (plots: Plot[], buildings: Building[], buildingIndex: number | false) => {
    if (buildingIndex !== false && buildingIndex < buildings.length) {
        const building = buildings[buildingIndex]
        if (building.coordinates?.x && building.coordinates?.y) {
            return coordsFromZone32to33(building.coordinates?.x, building.coordinates?.y)
        }
    }

    if (plots && plots[0]) {
        return coordsFromZone32to33(plots[0].coordinates.x, plots[0].coordinates.y)
    }

    return null
}