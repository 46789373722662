import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import { makeStyles, Theme, Paper, Typography, Grid, Button } from '@material-ui/core'
import Navigation from 'common/navigation/navigation'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { useDispatch, useSelector } from 'react-redux'
import { actionGetTemplatesByType } from 'settings-page/settings-actions'
import { AppState } from '../app/app-store'
import { TemplatesToolbar } from './templates-toolbar'
import { navigate, RouteComponentProps } from '@reach/router'
import { DocumentTypes } from 'common/enums/document-types'
import { RouteNames } from 'common/enums/routeNames'
import { Add } from '@material-ui/icons'

type DocumentTemplatesListProps = {}

const useStyles = makeStyles((theme: Theme) => {
    return {
        title: {
            fontWeight: 'bold',
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        text: {
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        tableHead: {
            padding: theme.spacing(2, 0, 1, 0),
        },
        tableRow: {
            borderTop: '1px solid #f0f0f0',
            borderBottom: '1px solid #f0f0f0',
            cursor: 'pointer',
            padding: theme.spacing(1, 0, 1, 0),
        },
    }
})

export const DocumentTemplatesList: React.FC<RouteComponentProps<DocumentTemplatesListProps>> = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const templates = useSelector((state: AppState) => state.taxSettings[DocumentTypes.LETTER])
    const templatesArray = Object.values(templates.templates)

    useEffect(() => {
        if (!templates.loaded && !templates.loading) {
            dispatch(actionGetTemplatesByType(DocumentTypes.LETTER))
        }
    }, [dispatch, templates])

    const AddButton: React.FC = () => {
        return (
            <Button
                color="inherit"
                type="button"
                onClick={() => {
                    navigate(`/${RouteNames.settings}/${RouteNames.DOCUMENT_TEMPLATES}/new`)
                }}
            >
                <Add /> {tr(t.TEXT_ADD_DOCUMENT_TEMPLATE)}
            </Button>
        )
    }
    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={<TemplatesToolbar title={`${tr(t.TEXT_DOCUMENT_TEMPLATES)}`} addButton={<AddButton />} />}
        >
            <Paper elevation={0}>
                <Grid container className={classes.tableHead}>
                    <Grid item xs={5}>
                        <Typography variant="body1" className={classes.title}>
                            {tr(t.TEXT_TITLE)}
                        </Typography>
                    </Grid>
                </Grid>
                    {templatesArray.map((template: any, index: number) => (
                        <Grid
                            container
                            key={index}
                            className={classes.tableRow}
                            onClick={() => navigate(`/${RouteNames.settings}/${RouteNames.DOCUMENT_TEMPLATES}/${template.id}`)}
                        >
                            <Typography variant="body1" className={classes.text}>
                                {template.name}
                            </Typography>
                        </Grid>
                    ))}
            </Paper>
        </LayoutDefault>
    )
}
