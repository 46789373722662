import { AnyAction } from 'redux'
import { TribunalGroup } from './models/tribunal-group'
import { Taxation } from 'models/taxation'
import { PageReference } from './page-reference.enum'
import { Complaint } from './models/complaint.type'
import { TribunalGroupsAndTaxations } from './models/tribunal-groups-and-taxations.type'

export const ACTION_TRIBUNAL_GROUPS_GET_FULL = 'ACTION_TRIBUNAL_GROUPS_GET_FULL'
export const ACTION_TRIBUNAL_GROUPS_GET_FULL_SUCCESS = 'ACTION_TRIBUNAL_GROUPS_GET_FULL_SUCCESS'
export const ACTION_TRIBUNAL_GROUP_GET = 'ACTION_TRIBUNAL_GROUP_GET'
export const ACTION_TRIBUNAL_GROUP_GET_SUCCESS = 'ACTION_TRIBUNAL_GROUP_GET_SUCCESS'
export const ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS = 'ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS'
export const ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS_SUCCESS =
    'ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS_SUCCESS'
export const ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH = 'ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH'
export const ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH_SUCCESS = 'ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH_SUCCESS'
export const ACTION_TRIBUNAL_GROUP_UPDATE = 'ACTION_TRIBUNAL_GROUP_UPDATE'
export const ACTION_TRIBUNAL_GROUP_UPDATE_SUCCESS = 'ACTION_TRIBUNAL_GROUP_UPDATE_SUCCESS'
export const ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS = 'ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS'
export const ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS_SUCCESS = 'ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS_SUCCESS'
export const ACTION_TRIBUNAL_GROUP_PLAN = 'ACTION_TRIBUNAL_GROUP_PLAN'
export const ACTION_TRIBUNAL_GROUP_PLAN_SUCCESS = 'ACTION_TRIBUNAL_GROUP_PLAN_SUCCESS'
export const ACTION_TRIBUNAL_GROUP_START = 'ACTION_TRIBUNAL_GROUP_START'
export const ACTION_TRIBUNAL_GROUP_START_SUCCESS = 'ACTION_TRIBUNAL_GROUP_START_SUCCESS'
export const ACTION_TRIBUNAL_GROUP_FINISH = 'ACTION_TRIBUNAL_GROUP_FINISH'
export const ACTION_TRIBUNAL_GROUP_FINISH_SUCCESS = 'ACTION_TRIBUNAL_GROUP_FINISH_SUCCESS'
export const ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH = 'ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH'
export const ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH_SUCCESS = 'ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH_SUCCESS'
export const ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED = 'ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED'
export const ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED_SUCCESS =
    'ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED_SUCCESS'
export const ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED = 'ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED'
export const ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED_SUCCESS =
    'ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED_SUCCESS'
export const ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE = 'ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE'
export const ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE_SUCCESS = 'ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE_SUCCESS'
export const ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED = 'ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED'
export const ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED_SUCCESS = 'ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED_SUCCESS'
export const ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED = 'ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED'
export const ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED_SUCCESS = 'ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED_SUCCESS'
export const ACTION_TRIBUNAL_GET_APPROVED = 'ACTION_TRIBUNAL_GET_APPROVED'
export const ACTION_TRIBUNAL_GET_APPROVED_SUCCESS = 'ACTION_TRIBUNAL_GET_APPROVED_SUCCESS'
export const ACTION_TRIBUNAL_GET_GROUP_DETAILS = 'ACTION_TRIBUNAL_GET_GROUP_DETAILS'
export const ACTION_TRIBUNAL_GET_GROUP_DETAILS_SUCCESS = 'ACTION_TRIBUNAL_GET_GROUP_DETAILS_SUCCESS'
export const ACTION_TRIBUNAL_SEND_GROUP_LETTERS = 'ACTION_TRIBUNAL_SEND_GROUP_LETTERS'
export const ACTION_TRIBUNAL_SEND_GROUP_LETTERS_SUCCESS = 'ACTION_TRIBUNAL_SEND_GROUP_LETTERS_SUCCESS'

//#region Complaints
export const ACTION_TRIBUNAL_COMPLAINTS_GET = 'ACTION_TRIBUNAL_COMPLAINTS_GET'
export const ACTION_TRIBUNAL_COMPLAINTS_GET_SUCCESS = 'ACTION_TRIBUNAL_COMPLAINTS_GET_SUCCESS'
export const ACTION_TRIBUNAL_COMPLAINTS_UPDATE = 'ACTION_TRIBUNAL_COMPLAINTS_UPDATE'
export const ACTION_TRIBUNAL_COMPLAINTS_UPDATE_SUCCESS = 'ACTION_TRIBUNAL_COMPLAINTS_UPDATE_SUCCESS'
export const ACTION_TRIBUNAL_COMPLAINTS_GET_ALL = 'ACTION_TRIBUNAL_COMPLAINTS_GET_ALL'
export const ACTION_TRIBUNAL_COMPLAINTS_GET_ALL_SUCCESS = 'ACTION_TRIBUNAL_COMPLAINTS_GET_ALL_SUCCESS'
export const ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINTS = 'ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINT'
export const ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINTS_SUCCESS =
    'ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINT_SUCCESS'
//#endregion

export const actionTribunalGroupsGetFull = (): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUPS_GET_FULL,
})

export const actionTribunalGroupsGetFullSuccess = (data: TribunalGroupsAndTaxations): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUPS_GET_FULL_SUCCESS,
    data,
})

export const actionTribunalGroupGet = (id: string): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_GET,
    id,
})

export const actionTribunalGroupGetSuccess = (data: { groups: TribunalGroup; taxations: Taxation[] }): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_GET_SUCCESS,
    data,
})

export const actionTribunalConflictsReviewFinish = (groupId: string): AnyAction => ({
    type: ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH,
    groupId,
})

export const actionTribunalConflictsReviewFinishSuccess = (group: TribunalGroup): AnyAction => ({
    type: ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH_SUCCESS,
    group,
})

export const actionTribunalGroupUpdate = (group: TribunalGroup): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_UPDATE,
    group,
})

export const actionTribunalGroupUpdateSuccess = (group: TribunalGroup): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_UPDATE_SUCCESS,
    group,
})

export const actionTribunalGroupCreateAndAssignTaxations = (
    oldGroupId: string,
    newGroup: TribunalGroup,
    taxationsIds: string[],
    pageReference: PageReference,
): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS,
    oldGroupId,
    newGroup,
    taxationsIds,
    pageReference,
})

export const actionTribunalGroupCreateAndAssignTaxationsSuccess = (
    oldGroupId: string,
    newGroup: TribunalGroup,
    taxationsIds: string[],
): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS_SUCCESS,
    oldGroupId,
    newGroup,
    taxationsIds,
})

export const actionTribunalGroupAssignTaxations = (
    oldGroupId: string,
    newGroup: TribunalGroup,
    taxationsIds: string[],
    pageReference: PageReference,
): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS,
    oldGroupId,
    newGroup,
    taxationsIds,
    pageReference,
})

export const actionTribunalGroupAssignTaxationsSuccess = (
    oldGroupId: string,
    newGroup: TribunalGroup,
    taxationsIds: string[],
): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS_SUCCESS,
    oldGroupId,
    newGroup,
    taxationsIds,
})

export const actionTribunalGroupPlan = (groupId: string, newGroup: TribunalGroup): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_PLAN,
    groupId,
    newGroup,
})

export const actionTribunalGroupPlanSuccess = (oldGroupId: string, newGroup: TribunalGroup): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_PLAN_SUCCESS,
    oldGroupId,
    newGroup,
})

export const actionTribunalGroupStart = (groupId: string): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_START,
    groupId,
})

export const actionTribunalGroupStartSuccess = (groupId: string): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_START_SUCCESS,
    groupId,
})

export const actionTribunalGroupReadyForFinish = (groupId: string): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH,
    groupId,
})

export const actionTribunalGroupReadyForFinishSuccess = (groupId: string): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH_SUCCESS,
    groupId,
})

export const actionTribunalGroupFinish = (groupId: string): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_FINISH,
    groupId,
})

export const actionTribunalGroupFinishSuccess = (groupId: string): AnyAction => ({
    type: ACTION_TRIBUNAL_GROUP_FINISH_SUCCESS,
    groupId,
})

export const actionTribunalTaxationsMarkAsConflicted = (
    userId: string,
    groupId: string,
    taxationsIds: string[],
): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED,
    userId,
    groupId,
    taxationsIds,
})

export const actionTribunalTaxationsMarkAsConflictedSuccess = (
    userId: string,
    groupId: string,
    taxationsIds: string[],
): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED_SUCCESS,
    userId,
    groupId,
    taxationsIds,
})

export const actionTribunalTaxationsRemoveAsConflicted = (
    userId: string,
    groupId: string,
    taxationsIds: string[],
): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED,
    userId,
    groupId,
    taxationsIds,
})

export const actionTribunalTaxationsRemoveAsConflictedSuccess = (
    userId: string,
    groupId: string,
    taxationsIds: string[],
): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED_SUCCESS,
    userId,
    groupId,
    taxationsIds,
})

export const actionTribunalTaxationsReschedule = (
    userId: string,
    groupId: string,
    taxationsIds: string[],
): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE,
    userId,
    groupId,
    taxationsIds,
})

export const actionTribunalTaxationsRescheduleSuccess = (groupId: string, taxationsIds: string[], events: any[]): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE_SUCCESS,
    groupId,
    taxationsIds,
    events
})

export const actionTribunalTaxationsMarkAsFinished = (groupId: string, taxationsIds: string[]): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED,
    groupId,
    taxationsIds,
})

export const actionTribunalTaxationsMarkAsFinishedSuccess = (groupId: string, taxationsIds: string[]): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED_SUCCESS,
    groupId,
    taxationsIds,
})

export const actionTribunalTaxationsMarkAsDeclined = (
    groupId: string,
    taxationsIds: string[],
    declinedReason: string,
    pageReference: PageReference,
): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED,
    groupId,
    taxationsIds,
    declinedReason,
    pageReference,
})

export const actionTribunalTaxationsMarkAsDeclinedSuccess = (
    groupId: string,
    declinedGroup: TribunalGroup,
    taxationsIds: string[],
    declinedReason: string,
): AnyAction => ({
    type: ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED_SUCCESS,
    groupId,
    declinedGroup,
    taxationsIds,
    declinedReason,
})

export const actionTribunalGetApproved = (): AnyAction => ({
    type: ACTION_TRIBUNAL_GET_APPROVED,
})
export const actionTribunalGetApprovedSuccess = (data: any[]): AnyAction => ({
    type: ACTION_TRIBUNAL_GET_APPROVED_SUCCESS,
    data,
})
export const actionGetGroupDetails = (id: string): AnyAction => ({
    type: ACTION_TRIBUNAL_GET_GROUP_DETAILS,
    id
})
export const actionGetGroupDetailsSuccess = (data: any): AnyAction => ({
    type: ACTION_TRIBUNAL_GET_GROUP_DETAILS_SUCCESS,
    data,
})
export const actionSendGroupLetters = (ids: string[], tableName?: string): AnyAction => ({
    type: ACTION_TRIBUNAL_SEND_GROUP_LETTERS,
    ids,
    tableName
})
export const actionSendGroupLettersSuccess = (ids: string[]): AnyAction => ({
    type: ACTION_TRIBUNAL_SEND_GROUP_LETTERS_SUCCESS,
    ids,
})

//#region Complaints
export const actionTribunalComplaintsGet = (id: string): AnyAction => ({
    type: ACTION_TRIBUNAL_COMPLAINTS_GET,
    id,
})

export const actionTribunalComplaintsGetSuccess = (complaint: Complaint): AnyAction => ({
    type: ACTION_TRIBUNAL_COMPLAINTS_GET_SUCCESS,
    complaint,
})

export const actionTribunalComplaintsUpdate = (complaint: Complaint): AnyAction => ({
    type: ACTION_TRIBUNAL_COMPLAINTS_UPDATE,
    complaint,
})

export const actionTribunalComplaintsUpdateSuccess = (complaint: Complaint): AnyAction => ({
    type: ACTION_TRIBUNAL_COMPLAINTS_UPDATE_SUCCESS,
    complaint,
})

export const actionTribunalComplaintsGetAll = (): AnyAction => ({
    type: ACTION_TRIBUNAL_COMPLAINTS_GET_ALL,
})

export const actionTribunalComplaintsGetAllSuccess = (complaints: Complaint[]): AnyAction => ({
    type: ACTION_TRIBUNAL_COMPLAINTS_GET_ALL_SUCCESS,
    complaints,
})

export const actionTribunalComplaintsGetTaxationComplaints = (taxationId: string): AnyAction => ({
    type: ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINTS,
    taxationId,
})

export const actionTribunalComplaintsGetTaxationComplaintsSuccess = (complaints: Complaint[]): AnyAction => ({
    type: ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINTS_SUCCESS,
    complaints,
})

//#endregion
