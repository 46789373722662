import {
    YES,
    NO,
    POSITIVE,
    NEGATIVE,
    NORMAL,
    BAD,
    VERY_BAD,
    DEMOLITION_OBJECT,
    NOBODY,
    LEGAL_OWNER,
    OTHERS,
} from 'common/constants/constants'

import {
    TEXT_YES,
    TEXT_NO,
    TEXT_POSITIVE,
    TEXT_NEGATIVE,
    TEXT_NORMAL,
    TEXT_BAD,
    TEXT_VERY_BAD,
    TEXT_DEMOLITION_OBJECT,
    TEXT_NOBODY,
    TEXT_LEGAL_OWNER,
    TEXT_OTHERS,
} from 'utils/translations/keys'

const TaxInfoTranslationKeysByValue: { [key: string]: string } = {
    [YES]: TEXT_YES,
    [NO]: TEXT_NO,
    [POSITIVE]: TEXT_POSITIVE,
    [NEGATIVE]: TEXT_NEGATIVE,
    [NORMAL]: TEXT_NORMAL,
    [BAD]: TEXT_BAD,
    [VERY_BAD]: TEXT_VERY_BAD,
    [DEMOLITION_OBJECT]: TEXT_DEMOLITION_OBJECT,
    [NOBODY]: TEXT_NOBODY,
    [LEGAL_OWNER]: TEXT_LEGAL_OWNER,
    [OTHERS]: TEXT_OTHERS,
}

export default (value: string): string => TaxInfoTranslationKeysByValue[value] || ''
