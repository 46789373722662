import React, { useMemo } from 'react'
import Downshift from 'downshift'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import { useStyles } from './search-input-style'
import {
    RenderInputProps,
    RenderSuggestionProps,
    SearchInputProps,
    Suggestion,
    SuggestionGroup,
    Suggestions,
} from './search-input-types'
import { Divider, Grid, IconButton, Typography } from '@material-ui/core'
import { tr } from '../utils/translations/translate'
import { TEXT_SEARCH } from '../utils/translations/keys'
import classNames from 'classnames'
import { Search } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import MapIcon from '@material-ui/icons/Map';
import { actionSearchInputChanged } from './search-input-actions'

function renderInput(inputProps: RenderInputProps) {
    const { InputProps, classes, ref, ...other } = inputProps

    return (
        <div className={classes.search}>
            <TextField
                InputProps={{
                    name: 'searchAutocompleteInput',
                    inputRef: ref,
                    classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    },
                    ...InputProps,
                }}
                {...other}
            />
        </div>
    )
}

const renderSuggestion = (props: RenderSuggestionProps) => {
    const { suggestion, index, itemProps, highlightedIndex, classes } = props
    const isHighlighted = highlightedIndex === index
    return (
        <div
            {...itemProps}
            key={index}
            className={classNames(classes.suggestDiv, {
                [classes.highlightedSuggestion]: isHighlighted,
            })}
        >
            {suggestion.label}
        </div>
    )
}

export function getSuggestions(
    suggestions: Suggestions,
    value: string,
    getGeneratedSuggestions?: (inputValue: string) => Suggestions | undefined,
): Suggestions {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    const allSuggestions: Suggestions = getGeneratedSuggestions
        ? (getGeneratedSuggestions(inputValue) || []).concat(suggestions)
        : suggestions

    return inputLength < 2
        ? []
        : allSuggestions
              .map(suggestionsGroup => {
                  let count = 0
                  return {
                      ...suggestionsGroup,
                      items: suggestionsGroup.items.filter(suggestion => {
                          const keep = count < 30 && suggestion.label?.toLowerCase()?.includes(inputValue)

                          if (keep) {
                              count += 1
                          }

                          return keep
                      }),
                  }
              })
              .filter((x: SuggestionGroup) => x.items.length > 0)
}

type SuggestionsListProps = {
    suggestions: Suggestions
    getItemProps: any
    highlightedIndex: any
}

const SuggestionsList = (props: SuggestionsListProps) => {
    const { suggestions, getItemProps, highlightedIndex } = props
    const classes = useStyles()

    let suggestionIndex = -1

    return (
        <Paper className={classes.paper} square>
            {suggestions.map((group, groupIndex) => {
                if (group.items.length === 0) {
                    return null
                } else {
                    return (
                        <div key={groupIndex}>
                            <Typography variant="h6" className={classes.suggestionGroupTitle}>
                                {group.label}
                            </Typography>
                            {group.items.map(suggestion => {
                                suggestionIndex += 1
                                return renderSuggestion({
                                    classes,
                                    suggestion,
                                    index: suggestionIndex,
                                    itemProps: getItemProps({ item: suggestion }),
                                    highlightedIndex,
                                })
                            })}
                            <Divider />
                        </div>
                    )
                }
            })}
        </Paper>
    )
}

export const SearchInput = (props: SearchInputProps) => {
    const {
        suggestions,
        selectedItems,
        setSelectedItems,
        inputValue,
        setInputValue,
        getGeneratedSuggestions,
        search,
        map
    } = props
    const classes = useStyles()

    const dispatch = useDispatch()
    const onInputChange = (value: string) => dispatch(actionSearchInputChanged(value))

    const filteredSuggestions = useMemo(() => getSuggestions(suggestions, inputValue, getGeneratedSuggestions), [
        suggestions,
        inputValue,
        getGeneratedSuggestions,
    ])

    function handleKeyDown(event: React.KeyboardEvent) {
        if (selectedItems.length && !inputValue.length && event.key === 'Backspace') {
            setSelectedItems(selectedItems.slice(0, selectedItems.length - 1))
        } else if (event.key === 'Enter') {
            if (filteredSuggestions.length === 1 && filteredSuggestions[0].items.length === 1) {
                setSelectedItems([...selectedItems, filteredSuggestions[0].items[0]])
                setInputValue('')
            }
        }
    }

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        setInputValue(event.target.value)
    }

    function handleChange(item: Suggestion) {
        let newSelectedItem = [...selectedItems]
        if (newSelectedItem.indexOf(item) === -1) {
            newSelectedItem = [...newSelectedItem, item]
        }
        setInputValue('')
        setSelectedItems(newSelectedItem)
    }

    const handleDelete = (item: Suggestion) => () => {
        const newSelectedItem = [...selectedItems]
        newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
        setSelectedItems(newSelectedItem)
    }

    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs>
                <Downshift
                    onInputValueChange={onInputChange}
                    inputValue={inputValue}
                    onChange={handleChange}
                    selectedItem={selectedItems}
                    itemToString={item => (item ? item.label : '')}
                >
                    {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
                        <div className={classes.container}>
                            {renderInput({
                                fullWidth: true,
                                classes,
                                InputProps: getInputProps({
                                    startAdornment: selectedItems.map((item, index) => (
                                        item &&
                                            <Chip
                                                title={item.label}
                                                key={index}
                                                tabIndex={-1}
                                                label={item.label}
                                                classes={{ label: classes.chipLabel, root: classes.chip }}
                                                onDelete={handleDelete(item)}
                                            />
                                    )),
                                    onChange: handleInputChange,
                                    onKeyDown: handleKeyDown,
                                    placeholder: tr(TEXT_SEARCH),
                                    disableUnderline: true,
                                }),
                            })}
                            {isOpen ? (
                                <SuggestionsList
                                    suggestions={filteredSuggestions}
                                    getItemProps={getItemProps}
                                    highlightedIndex={highlightedIndex}
                                />
                            ) : null}
                        </div>
                    )}
                </Downshift>
            </Grid>
            <Grid item>
                <IconButton color="inherit" onClick={search}>
                    <Search />
                </IconButton>
                {map}
            </Grid>
        </Grid>
    )
}
