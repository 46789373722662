import { makeReducer } from 'utils'
import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import { FailedCorrespondenceDetails } from 'models/dashboard'
import { ACTION_GET_FAILED_CORRESPONDENCE, ACTION_GET_FAILED_CORRESPONDENCE_SUCCESS } from './dashboard-actions'
import { ACTION_SEND_YEAR_LETTERS_SUCCESS } from 'search-page/search-actions'
import { findIndex } from 'lodash'

export type DashboardState = { failedCorrespondence?: FailedCorrespondenceDetails[] }

type DashboardReducer = ActionReducer<DashboardState>

const getFailedCorrespondence: DashboardReducer = (state, action) => state

const getFailedCorrespondenceSuccess: DashboardReducer = (state, action) => ({
    ...state,
    failedCorrespondence: action.data,
})

const sendYearLettersSuccess: DashboardReducer = (state, action) => {
    const ids: string[] = action.ids
    const properties: FailedCorrespondenceDetails[] = state.failedCorrespondence || []
    const filtered = properties.filter((i) => !ids.includes(i.id))

    return {
        ...state,
        failedCorrespondence: filtered,
    }
}

const reducers: Reducers<DashboardState> = {
    [ACTION_GET_FAILED_CORRESPONDENCE]: getFailedCorrespondence,
    [ACTION_GET_FAILED_CORRESPONDENCE_SUCCESS]: getFailedCorrespondenceSuccess,
    [ACTION_SEND_YEAR_LETTERS_SUCCESS]: sendYearLettersSuccess,
}

export const dashboardReducer = makeReducer<DashboardState>(reducers, {})
