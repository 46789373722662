import { Property, PropertyValueType } from "models/property"
import { nonRegulatedPlotMultipliersByType, regulatedPlotMultipliersByType } from "./property-helper"

const propertyValueTypes = [
    PropertyValueType.BO,
    PropertyValueType.FR,
    PropertyValueType.GB,
    PropertyValueType.GF,
    PropertyValueType.GU,
    PropertyValueType.IV,
    PropertyValueType.KO,
    PropertyValueType.NE,
    PropertyValueType.UB,
    PropertyValueType.UF,
    PropertyValueType.UN,
    PropertyValueType.UT,
]

export default (prop: Property, customSize?: number): number => {
    let plotSize: number = customSize ? customSize : prop.plotSize ? prop.plotSize : prop.plots.reduce((plotSize, plot) => plotSize + plot.area, 0)
    plotSize = Math.round(plotSize)
    if (prop.valueType === null || (propertyValueTypes.indexOf(prop.valueType) === -1)) {
        return 0;
    }
    const isRegulated: boolean = prop.regulated
    //const propertyType: PropertyType = prop.type
    const valueType: PropertyValueType = prop.valueType

    const plotMultiplier = isRegulated ? regulatedPlotMultipliersByType : nonRegulatedPlotMultipliersByType

    const plotValue =
        plotSize > 1000
            ? 1000 * plotMultiplier[valueType][0] + (plotSize - 1000) * plotMultiplier[valueType][1]
            : plotSize * plotMultiplier[valueType][0]

            //TODO make sense of the lease settings
    return Math.round(plotValue)// * prop.zoneFactor * prop.locationFactor) //propertyType === PropertyType.Lease ? Math.round(plotValue / 2) : Math.round(plotValue)
}