import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PDFObject from 'pdfobject'
import { getSessionKey } from 'utils'
import { AppState } from 'app/app-store'
import { actionSetPreviewPdf } from 'app/app-actions'
import { IconButton, makeStyles } from '@material-ui/core'
import { plantsUrl } from 'services/httpService'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    fieldOfGrey: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%;',
        backgroundColor: 'rgba(0, 0, 0, 0.66667)',
        animation: 'appear 0.3s',
        zIndex: 2000,
    },
    popup: {
        textAlign: 'right',
        position: 'fixed',
        top: 0,
        left: '50%',
        margin: '50px 0 0 -300px',
        backgroundColor: '#f0f0f0',
        boxShadow: '2px 2px 10px rgb(0 0 0 / 67%)',
        borderRadius: 5,
        padding: '5px 20px',
        animation: 'appearFromBot 0.2s',
        height: '90%',
        marginTop: '2%',
        width: '1000px',
        marginLeft: '-500px',
        [theme.breakpoints.down('md')]: {
            left: 0,
            margin: '20px 0 0 0',
            width: '100%',
            boxSizing: 'border-box',
            overflow: 'auto',
        },
    },
    file: {
        height: '95%',
        width: '100%',
    },
}))

const pdfOptions = {
    fallbackLink:
        "<p>Denne nettlereren støtter ikke visning av PDF filer. Last ned PDF filen for å vise den: <a target='_blank' href='[url]'>Last ned PDF</a></p>",
}
export const PreviewWindow: React.FC = () => {
    const pdfDocumentId = useSelector((state: AppState) => state.appData.preview.id)
    const pdfUrl = useSelector((state: AppState) => state.appData.preview.url)
    const fileName = useSelector((state: AppState) => state.appData.preview.name)
    const dispatch = useDispatch()
    const styles = useStyles()
    React.useEffect(() => {
        if (pdfDocumentId) {
            const sessionKey = getSessionKey()
            const url = plantsUrl(`/correspondance/pdf/${pdfDocumentId}/${sessionKey}/${fileName}`)
            setTimeout(() => {
                // @ts-ignore
                PDFObject.embed(url, '#pdfcontainer', pdfOptions)
            }, 0)
        }
        if (pdfUrl) {
            setTimeout(() => {
                // @ts-ignore
                PDFObject.embed(pdfUrl, '#pdfcontainer', pdfOptions)
            }, 0)
        }
    }, [pdfDocumentId, fileName, pdfUrl])

    const closePreview = (e: any) => {
        e.stopPropagation()
        dispatch(actionSetPreviewPdf(undefined, undefined, undefined))
    }

    return pdfDocumentId || pdfUrl ? (
        <div className={styles.fieldOfGrey} onClick={closePreview}>
            <div className={styles.popup} onClick={(e: any) => e.stopPropagation()}>
                <IconButton onClick={closePreview}>
                    <Close />
                </IconButton>
                <div id="pdfcontainer" className={styles.file} />
            </div>
        </div>
    ) : (
        <></>
    )
}
