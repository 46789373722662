import { TEXT_EMAIL, TEXT_LETTER, TEXT_SENT_BY } from "utils/translations/keys"
import { tr } from "utils/translations/translate"

export type Correspondance = {
    taxationId: string
    type: string
    subType: string
    timeStamp: string
    title: string
    sent: boolean
    letterIds: string[]
    emailTemplateId: string
    attachmentId: string
    usersignId: string
    attachmentToSubtype: string
    forcedCommType: string
    recipientMissing: boolean
    externalProviderId: string
    archieveKey: string
}

export const getSentByText = (correspondance: Correspondance) => {
    const possibleConType = correspondance.forcedCommType || 'email'
    const comTypeText = possibleConType === 'email' || possibleConType === 'primary' ? TEXT_EMAIL : TEXT_LETTER
    return `${tr(TEXT_SENT_BY)} ${tr(comTypeText)}:`
}