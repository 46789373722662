import React from 'react'
import { Button, Grid, makeStyles, Theme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form'
import * as t from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { DocumentTypes } from 'common/enums/document-types'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { getChannelOptions, getTemplateOptions } from './helper'
import { RemoveField } from 'settings-tax-page/helper-functions'
import { Add } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => {
    return {
        header: {
            fontWeight: 600,
        },
        textAdd: {
            color: theme.palette.primary.main,
            fontSize: 14,
            fontWeight: 'normal',
        },
        iconButton: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 0,
        },
        shortTextInput: {
            width: '100px !important',
            '& input': {
                paddingLeft: 10,
            },
        },
        form: {
            width: '100%',
        },
        row: {
            marginBottom: 20,
        },
        button: {
            color: '#ffffff',
            backgroundColor: theme.palette.primary.main,
            fontWeight: 300,
            '&:hover': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
            },
        },
        capitalize: {
            textTransform: 'capitalize',
        },
    }
})

const Form = (props: any) => {
    const { handleSubmit, entry, form, reset, pristine, handleClose } = props
    const classes = useStyles()

    const caseDocuments = useSelector((state: AppState) => state.taxSettings[DocumentTypes.CASE_DOCUMENT])
    const smsTemplates = useSelector((state: AppState) => state.taxSettings[DocumentTypes.SMS])
    const emailTemplates = useSelector((state: AppState) => state.taxSettings[DocumentTypes.EMAIL])
    const letterTemplates = useSelector((state: AppState) => state.taxSettings[DocumentTypes.LETTER])

    

    const CaseDocumentsArray = (props: any) => {
        const { classes, fields } = props

        return (
            <>
                {fields.map((fieldName: string, index: number) => (
                    <Grid container alignItems="center" key={index} className={classes.row}>
                        <Grid item xs={11}>
                            <Field
                                name={`${fieldName}.${TaxFormFieldNames.letterId}`}
                                component={CubitSelectAdapter}
                                options={getTemplateOptions(
                                    DocumentTypes.CASE_DOCUMENT,
                                    caseDocuments,
                                    smsTemplates,
                                    emailTemplates,
                                    letterTemplates,
                                )}
                                valueIsObject={false}
                                label={tr(t.TEXT_CASE_DOCUMENT)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <RemoveField fields={fields} index={index} />
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button onClick={() => fields.push()} className={classes.iconButton}>
                        <Add color="primary" />
                        <span className={classes.textAdd}>{tr(t.TEXT_ADD_CASE_DOCUMENT)}</span>
                    </Button>
                </Grid>
            </>
        )
    }

    const ChannelsArray = ({ fields, classes }: any) => {
        const selector = formValueSelector(form)
        const stateRef = useSelector((state: AppState) => state)
        const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
        return (
            <>
                {fields.map((name: string, index: number) => {
                    return (
                    <Grid container key={index}>
                        <Grid item md={5} xs={11} className={classes.row}>
                            <Field
                                name={`${name}.${TaxFormFieldNames.type}`}
                                component={CubitSelectAdapter}
                                options={getChannelOptions()}
                                label={tr(t.TEXT_CHANNEL)}
                                valueIsObject={false}
                            />
                        </Grid>
                        <Grid item md={1} xs={11}></Grid>
                        <Grid item md={5} xs={11} className={classes.row}>
                            <Field
                                name={`${name}.${TaxFormFieldNames.templateId}`}
                                component={CubitSelectAdapter}
                                options={getTemplateOptions(
                                    getFieldValue(`${name}.${TaxFormFieldNames.type}`),
                                    caseDocuments,
                                    smsTemplates,
                                    emailTemplates,
                                    letterTemplates,
                                )}
                                label={tr(t.TEXT_TEMPLATE)}
                                valueIsObject={false}
                            />
                        </Grid>
                    </Grid>
                    )
                })}
            </>
        )
    }
    

    const cancel = () => {
        reset()
        handleClose()
    }

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container alignItems="flex-end" className={classes.row}>
                <Grid item sm={2} xs={3}>
                    <Field
                        name={TaxFormFieldNames.noOfDays}
                        component={CubitTextFieldAdapter}
                        className={classes.shortTextInput}
                        label={tr(t.TEXT_DAYS)}
                    />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7} className={classes.capitalize}>
                    {tr(entry.trigger)}
                </Grid>
            </Grid>

            <FieldArray name={TaxFormFieldNames.channels} component={ChannelsArray} classes={classes} />

            <Grid container className={classes.row}>
                <Grid item xs={12}>
                    <FieldArray name={TaxFormFieldNames.letters} component={CaseDocumentsArray} classes={classes} />
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="inherit" type="button" onClick={cancel}>
                        {tr(t.TEXT_CANCEL)}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="inherit"
                        type="submit"
                        className={classes.button}
                        disabled={pristine}
                    >
                        {tr(t.TEXT_SAVE_CHANGES)}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

interface FormProps {
    entry: any
    form: string
    handleClose: any
}
export const CaseFlowEditForm = reduxForm<{}, FormProps>({
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
