export const YES = 'YES'
export const NO = 'NO'
export const POSITIVE = 'POSITIVE'
export const NEGATIVE = 'NEGATIVE'
export const NORMAL = 'NORMAL'
export const BAD = 'BAD'
export const VERY_BAD = 'VERY_BAD'
export const DEMOLITION_OBJECT = 'DEMOLITION_OBJECT'
export const NOBODY = 'NOBODY'
export const LEGAL_OWNER = 'LEGAL_OWNER'
export const OTHERS = 'OTHERS'

export const PROPERTY_VERIFIED_VALUES_TOTAL = 2
export const BUILDING_VERIFIED_VALUES_TOTAL = 1

export const LOCAL_STORAGE_KEY_SESSION_KEY = 'sessionKey'

export const PROJ4_PROJECTION_32 = '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs'
export const PROJ4_PROJECTION_33 = '+proj=utm +zone=33 +ellps=GRS80 +units=m +no_defs'
export const PROJ4_PROJECTION_GOOGLE = 'EPSG:4326'

export const PROPERTY_TYPE_OPTION_VALUES = {
  A: 'a',
  B: 'b',
  C: 'c',
  D: 'd',
  E: 'e',
  F: 'f',
  G: 'g',
}

export const REPORTS_SETTINGS_RESTRICTIONS = {
  DEFAULT_TAX_RATE: [1, 7],
  DWELLING_TAX_RATE: [1, 5],
  PROPERTIES_WITH_BUILDINGS_TAX_RATE: [1, 7],
  PROPERTIES_WITHOUT_BUILDINGS_TAX_RATE: [1, 7],
  BUILDINGS_TAX_RATE: [1, 7],
  LAND_TAX_RATE: [1, 7],
  REDUCTION_FACTOR: 30
}

export const FORM_FILE_TYPES = {
  PDF: 'application/pdf',
  IMAGE: 'image/*'
}