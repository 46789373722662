import React, { useState } from 'react'
import { getOwnerDisplayName, Owner, OwnershipType } from 'models/owner'
import { Button, Divider, Grid, IconButton } from '@material-ui/core'
import {
    TEXT_ADDRESS,
    TEXT_ADD_DOCUMENT_RECEIVER,
    TEXT_DOCUMENT_RECEIVER,
    TEXT_E_MAIL,
    TEXT_INVOICE_RECEIVER,
    TEXT_NAME,
    TEXT_NO,
    TEXT_PHONE_NUMBER,
    TEXT_SEND_LAST_ASSESSMENT,
    TEXT_YES,
} from 'utils/translations/keys'
import { getYesNoBoolOptions, tr } from 'utils/translations/translate'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { useDispatch } from 'react-redux'
import { change, Field, WrappedFieldArrayProps } from 'redux-form'
import { Property } from 'models/property'
import { actionSendLastTax } from 'property-page/property-actions'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { AddOwner } from './property-add-owner'
import { Edit } from '@material-ui/icons'
import { UpdateOwner } from './property-update-owner'

const getOwnerAddress = (owner: Owner): string => {
    return `${owner.postalAddress?.addressText ? `${owner.postalAddress.addressText}, ` : ''}${
        owner.postalAddress?.postalCode || ''
    } ${owner.postalAddress?.foreignCity || ''}`
}

type PropertyOwnersProps = {
    owners: Owner[]
    styles: any
    property: Property
    editing: boolean
}

export const PropertyOwnersFieldArray: React.FC<WrappedFieldArrayProps & PropertyOwnersProps> = (props) => {
    const { fields, styles, editing, property, owners } = props

    //owner dialog
    const [addOwnerDialogOpen, setAddOwnerDialogOpen] = useState(false)
    const [ownerUpdateDialogOpen, setUpdateOwnerDialogOpen] = useState(false)
    const [ownerIndex, setOwnerIndex] = useState(null)
    const handleCancelAddOwner = () => {
        setAddOwnerDialogOpen(false)
    }
    const handleCancelOwnerUpdate = () => {
        setUpdateOwnerDialogOpen(false)
    }
    const handleAddOwnerClick = (e: any) => {
        e.stopPropagation()
        setAddOwnerDialogOpen(true)
    }
    const handleOwnerUpdateClick = (index: any) => {
        setUpdateOwnerDialogOpen(true)
        setOwnerIndex(index)
    }
    const dispatch = useDispatch()

    const sendLastTaxation = () => {
        dispatch(actionSendLastTax(property.id))
    }
    const setNewBillReceiver = (set: boolean, fieldName: string) => {
        if (set === true) {
            //unset the other fields if any were set
            fields.forEach((field) => {
                if (field !== fieldName) {
                    setTimeout(() => {
                        dispatch(
                            change(
                                TaxFormName.PropertyOwners,
                                `${field}.${TaxFormFieldNames.isInvoiceRecipient}`,
                                false,
                            ),
                        )
                    }, 0)
                }
            })
        }
        //set the new value for the correct field
        setTimeout(() => {
            change(TaxFormName.PropertyOwners, `${fieldName}.${TaxFormFieldNames.isInvoiceRecipient}`, set)
        }, 0)
    }

    return (
        <>
            {fields.map((fieldName: string, index: number) => {
                const ownership = fields.get(index)
                const owner = owners.find((o) => o.id === ownership.ownerId) || ({} as Owner)
                return (
                    <div key={index}>
                        {index > 0 ? (
                            <Divider style={{ margin: '24px -16px' }}></Divider>
                        ) : (
                            <Divider style={{ margin: '0 -16px 24px -16px' }}></Divider>
                        )}
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item sm={6} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} className={styles.panelContent__label}>
                                        {tr(TEXT_NAME)}:
                                    </Grid>
                                    <Grid item xs>
                                        {getOwnerDisplayName(owner)}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} className={styles.panelContent__label}>
                                        {tr(TEXT_ADDRESS)}:
                                    </Grid>
                                    <Grid item xs>
                                        {getOwnerAddress(owner)}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} className={styles.panelContent__label}>
                                        {tr(TEXT_PHONE_NUMBER)}:
                                    </Grid>
                                    <Grid item xs>
                                        {owner.phone || '-'}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} className={styles.panelContent__label}>
                                        {tr(TEXT_E_MAIL)}:
                                    </Grid>
                                    <Grid item xs>
                                        {owner.email || '-'}
                                    </Grid>
                                </Grid>
                                {!editing && (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} className={styles.panelContent__label}>
                                                {tr(TEXT_DOCUMENT_RECEIVER)}:
                                            </Grid>
                                            <Grid item xs>
                                                {ownership.isDocumentRecipient ? tr(TEXT_YES) : tr(TEXT_NO)}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} className={styles.panelContent__label}>
                                                {tr(TEXT_INVOICE_RECEIVER)}:
                                            </Grid>
                                            <Grid item xs>
                                                {ownership.isInvoiceRecipient ? tr(TEXT_YES) : tr(TEXT_NO)}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {editing && (
                                    <>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6} className={styles.panelContent__label}>
                                                {tr(TEXT_DOCUMENT_RECEIVER)}:
                                            </Grid>
                                            <Grid item xs>
                                                <Field
                                                    name={`${fieldName}.${TaxFormFieldNames.isDocumentRecipient}`}
                                                    component={CubitRadioGroupAdapter}
                                                    options={getYesNoBoolOptions()}
                                                    direction="row"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6} className={styles.panelContent__label}>
                                                {tr(TEXT_INVOICE_RECEIVER)}:
                                            </Grid>
                                            <Grid item xs>
                                                <Field
                                                    name={`${fieldName}.${TaxFormFieldNames.isInvoiceRecipient}`}
                                                    component={CubitRadioGroupAdapter}
                                                    options={getYesNoBoolOptions()}
                                                    direction="row"
                                                    onChange={(val: any) => setNewBillReceiver(val, fieldName)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid container className={styles.alignStart} justifyContent="flex-end" item sm={6} xs={12}>
                                {ownership.ownerType === OwnershipType.MANUAL && (
                                    <Grid container className={styles.alignStart} justifyContent="flex-end" item sm={6} xs={12}>
                                        <IconButton
                                            size="small"
                                            type='button'
                                            onClick={() => handleOwnerUpdateClick(index)}
                                            color="inherit"
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Grid>
                                )}

                                {editing && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={styles.center}
                                        onClick={sendLastTaxation}
                                    >
                                        {tr(TEXT_SEND_LAST_ASSESSMENT)}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        {ownerIndex && ownerIndex === index && !addOwnerDialogOpen && (
                        <UpdateOwner
                        handleClose={handleCancelOwnerUpdate}
                        id={'UpdateManualOwner'}
                        open={ownerUpdateDialogOpen}
                        propertyId={property.id}
                        owners={owners}
                        ownership={ownership}
                    />
                    )}
                        
                    </div>
                )
            })}
            {editing && (
                <Button variant="outlined" color="primary" className={styles.center} onClick={handleAddOwnerClick}>
                    {tr(TEXT_ADD_DOCUMENT_RECEIVER)}
                </Button>
            )}
            <AddOwner
                handleClose={handleCancelAddOwner}
                id={'AddNewRecipient'}
                open={addOwnerDialogOpen}
                propertyId={property.id}
            />
        </>
    )
}
