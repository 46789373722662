import { Taxation } from 'models/taxation'

export default (taxation: Taxation): number => {
    const prev = taxation.old.property
    const curr = taxation.current.property

    const previousComment = (prev.taxInfo && prev.taxInfo.lotSizeComment) || ''
    const currentComment = (curr.taxInfo && curr.taxInfo.lotSizeComment) || ''

    return previousComment !== currentComment ? 1 : 0
}
