import React from 'react'
import { Typography, Grid, IconButton } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { actionGoBack } from 'app/app-actions'
import { useDispatch } from 'react-redux'
import { Truncate } from 'common/truncate/truncate'

export type TitleToolbarProps = {
    title: string
    enableGoBack?: boolean
}
const TitleToolbar: React.FC<TitleToolbarProps> = props => {
    const { title, enableGoBack = true } = props

    const dispatch = useDispatch()

    const handleGoBack = () => {
        dispatch(actionGoBack())
    }

    return (
        <Grid container spacing={2} alignItems="center">
            {enableGoBack ? (
                <Grid item>
                    <IconButton onClick={handleGoBack} color="inherit">
                        <KeyboardBackspaceIcon />
                    </IconButton>
                </Grid>
            ) : null}
            <Grid item xs>
                <Typography variant="h6">
                    <Truncate>{title}</Truncate>
                </Typography>
            </Grid>
            <Grid item>{props.children}</Grid>
        </Grid>
    )
}

export default TitleToolbar
