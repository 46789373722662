import React from 'react'
import { MenuItem, Grid, Button, Divider, FormControlLabel, Switch, Menu, Fade, Hidden } from '@material-ui/core'

import { CalendarView } from 'shared-components/src/cubit-calendar/calendar'
import { tr } from 'utils/translations/translate'
import { TEXT_DAY, TEXT_WEEK, TEXT_MONTH, TEXT_TODAY, TEXT_LIST, TEXT_SHOW_WEEKENDS } from 'utils/translations/keys'

import { CalendarDateSwitcher } from './calendar-date-switcher'
import { useSelector, useDispatch } from 'react-redux'
import { calendarSelector } from 'shared-components/src/cubit-calendar/redux-calendar'
import {
    actionCubitCalendarSetDate,
    actionCubitCalendarSetShowWeekends,
} from 'shared-components/src/cubit-calendar/calendar-actions'

type PlanningToolbarProps = {
    view: string
    onChange: any
}

export const PlanningToolbar: React.FC<PlanningToolbarProps> = ({ onChange }) => {
    const dispatch = useDispatch()

    const calendar = useSelector(calendarSelector)
    const setDate = (x: string) => dispatch(actionCubitCalendarSetDate(x))

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleViewSelect = (calendarView: CalendarView) => {
        onChange(calendarView)
        handleClose()
    }

    const handleShowWeekendsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actionCubitCalendarSetShowWeekends(event.target.checked))
        handleClose()
    }

    const getButtonText = (calendarView: CalendarView): string => {
        const viewNames = {
            [CalendarView.List]: tr(TEXT_LIST),
            [CalendarView.Day]: tr(TEXT_DAY),
            [CalendarView.Week]: tr(TEXT_WEEK),
            [CalendarView.Month]: tr(TEXT_MONTH),
        }

        return viewNames[calendarView]
    }

    return (
        <Grid>
            <Grid item>
                <Grid container spacing={2} alignItems="center">
                    <Hidden smDown>
                        <Grid item>
                            <Button
                                style={{ width: '128px' }}
                                aria-controls="fade-menu"
                                aria-haspopup="true"
                                color="inherit"
                                variant="outlined"
                                onClick={handleClick}
                            >
                                {getButtonText(calendar.view)}
                            </Button>
                            <Menu
                                id="fade-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                                <MenuItem onClick={() => handleViewSelect(CalendarView.List)}>{tr(TEXT_LIST)}</MenuItem>
                                <MenuItem onClick={() => handleViewSelect(CalendarView.Day)}>{tr(TEXT_DAY)}</MenuItem>
                                <MenuItem onClick={() => handleViewSelect(CalendarView.Week)}>{tr(TEXT_WEEK)}</MenuItem>
                                <MenuItem onClick={() => handleViewSelect(CalendarView.Month)}>{tr(TEXT_MONTH)}</MenuItem>
                                <Divider></Divider>

                                <Grid container style={{ padding: '8px 16px 0px 16px' }}>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={calendar.showWeekends}
                                                    onChange={handleShowWeekendsChange}
                                                    value={calendar.showWeekends}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            }
                                            label={tr(TEXT_SHOW_WEEKENDS)}
                                        />
                                    </Grid>
                                </Grid>
                            </Menu>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="outlined"
                                color="inherit"
                                type="button"
                                onClick={() => setDate(new Date().toISOString())}
                            >
                                {tr(TEXT_TODAY)}
                            </Button>
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <CalendarDateSwitcher date={calendar.date} calendarView={calendar.view} onChange={setDate} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
