import { Taxation } from 'models/taxation'
import { Building } from 'models/building'
import { get } from 'lodash'

export default (taxation: Taxation, buildingIndex: number): number => {
    const prev = taxation.old.buildings[buildingIndex]
    const curr = taxation.current.buildings[buildingIndex]

    const prevBuildingComments = getBuildingComments(prev)
    const currBuildingComments = getBuildingComments(curr)

    return currBuildingComments.reduce((accumulator, currentValue, index) => {
        return currentValue !== prevBuildingComments[index] ? accumulator + 1 : accumulator
    }, 0)
}

const getBuildingComments = (building: Building): string[] => {
    const buildingCommentsPropsKeys = [
        'taxInfo.exemptionComment',
        'taxInfo.propertyStatusComment',
        'taxInfo.unitsComment',
        'comment',
    ]

    return buildingCommentsPropsKeys.map(propWithCommentKey => get(building, propWithCommentKey) || null)
}
