import { Taxation } from 'models/taxation'
import { mapProperty, mapBuildings } from 'utils'
import produce from 'immer'

export default (taxation: Taxation): Taxation => {
    const buildings = mapBuildings(taxation.current.buildings, taxation.current.housingUnits, taxation.current.property)
    const property = mapProperty(taxation.current.property, taxation.staticSettings, buildings)

    const oldBuildings = mapBuildings(taxation.old.buildings, taxation.old.housingUnits, taxation.old.property)
    const oldProperty = mapProperty(taxation.old.property, taxation.staticSettings, oldBuildings)

    return produce(taxation, newTaxation => {
        newTaxation.current.buildings = buildings
        newTaxation.current.property = property

        newTaxation.old.buildings = oldBuildings
        newTaxation.old.property = oldProperty
    })
}
