import { Grid } from '@material-ui/core'
import React from 'react'
import { TEXT_COMMENT_DESCRIPTION } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

type CommentDescriptionProps = {
    comment: string
    styles: any
}

export const CommentDescription: React.FC<CommentDescriptionProps> = props => {
    const {
        comment,
        styles
    } = props
    return comment ? (
        <Grid container spacing={1} className={styles.innerContainer}>
            <Grid item xs={12} sm={6} md={3} className={styles.nonPanelContent__label}>
                {tr(TEXT_COMMENT_DESCRIPTION)}:
            </Grid>
            <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                {comment}
            </Grid>
        </Grid>
    ) : (
        <></>
    )
}
