import { combineEpics } from 'redux-observable'
import { EMPTY, from, Observable, of } from 'rxjs'
import { debounceTime, filter, mergeMap, map, switchMap, withLatestFrom, catchError } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { httpPost, plantsUrl } from '../services/httpService'
import {
    ACTION_SEARCH,
    ACTION_SEARCH_ALL,
    ACTION_SEARCH_LOAD_MORE,
    actionSearch,
    actionSearchAutocompleteLoaded,
    actionSearchSuccess,
    ACTION_ASSIGN_PROPERTIES,
    actionAssignPropertiesSuccess,
    ACTION_SEND_NOTICES,
    actionSendNoticesSuccsess,
    ACTION_UNASSIGN_PROPERTIES,
    actionUnassignPropertiesSuccess,
    ACTION_SEND_RIKO_NOTICES,
    ACTION_SEND_YEAR_LETTERS,
    actionSendYearLettersSuccsess,
} from './search-actions'
import { defaultCatchError } from 'app/app-epics'
import { SearchToolbarFormName } from './search-toolbar/search-toolbar-form'
import { Suggestion } from 'shared-components/src/search-input/search-input-types'
import { SearchResultKey } from './search-reducer'
import {
    ACTION_CUBIT_TABLE_ORDER,
    actionCubitTableClearSelection,
} from 'shared-components/src/cubit-table/cubit-table-actions'
import { SearchFiltersPanelFormName } from 'shared-components/src/search-filters-panel/search-filters-panel'
import { ACTION_SEARCH_INPUT_CHANGED } from 'shared-components/src/search-input/search-input-actions'
import { Property } from 'models/property'
import { PropertiesTableName } from './properties-results/properties-table'
import { actionCubitSnackbarShow } from 'common/cubit-snackbar/cubit-snackbar-actions'
import { tr } from 'utils/translations/translate'
import { TEXT_ASSIGNED, TEXT_ERROR_OCCURRED, TEXT_SENT, TEXT_UNASSIGNED } from 'utils/translations/keys'

export type Paging = {
    skip: number
    take?: number
    order: {
        by: string
        direction: 'asc' | 'desc'
    }
}

const chipsReducer = (acc: { [key: string]: Suggestion[] }, chip: Suggestion) => {
    const type = chip?.type
    if (acc[type] === undefined) {
        acc[type] = []
    }
    acc[type].push(chip)
    return acc
}

const getEndpoints = (type: SearchResultKey) => {
    switch (type) {
        case 'buildings':
            return plantsUrl(`/tax/search/buildings`)
        case 'properties':
            return plantsUrl(`/tax/search/properties`)
        case 'housingUnits':
            return plantsUrl(`/tax/search/housingUnits`)
        case 'owners':
            return plantsUrl(`/tax/search/owners`)
    }
}

const search = ([action, state]: Array<any>): Observable<AnyAction> => {
    const SearchToolbarFromState = state.form[SearchToolbarFormName].values
    const searchFiltersFormState = state.form[SearchFiltersPanelFormName].values
    const chips = SearchToolbarFromState.chips.reduce(chipsReducer, {})
    const currentResults = state.search.results[action.tableName]
    const order = state.table[action.tableName] || {
        by: 'id',
        direction: 'asc',
    }

    const paging: Paging = {
        skip: currentResults.items.length,
        take: 200,
        order: {
            by: order.orderBy,
            direction: order.orderDirection,
        },
    }
    const filters = { ...SearchToolbarFromState, ...searchFiltersFormState, ...chips, paging }

    return httpPost(getEndpoints(action.tableName), filters).pipe(
        map((data) => actionSearchSuccess(action.tableName, data)),
        defaultCatchError(),
    )
}

export const searchEpic = (action$: Observable<AnyAction>, state$: Observable<any>): Observable<AnyAction> =>
    action$.pipe(
        filter(
            (x) =>
                x.type === ACTION_SEARCH ||
                x.type === ACTION_SEARCH_LOAD_MORE ||
                (x.type === ACTION_CUBIT_TABLE_ORDER && x.tableName === PropertiesTableName),
        ),
        withLatestFrom(state$),
        mergeMap(search),
    )

export const searchAllEpic = (action$: Observable<AnyAction>, state$: Observable<any>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_SEARCH_ALL),
        mergeMap(() => from([actionSearch('properties'), actionSearch('owners')])),
    )

const loadAutocomplete = ([action, state]: [AnyAction, any]): Observable<AnyAction> => {
    if (action.value === undefined) {
        return EMPTY
    }
    return httpPost(plantsUrl(`/tax/search/autocomplete`), { query: action.value }).pipe(
        map((x: string[]) => {
            const autocomplete = [
                {
                    type: 'autocomplete',
                    label: 'Autocomplete',
                    items: x.map((y) => ({
                        type: 'autocomplete',
                        value: y,
                        label: y,
                    })),
                },
            ]
            return actionSearchAutocompleteLoaded(autocomplete)
        }),
        defaultCatchError(),
    )
}

const loadAutocompleteEpic = (action$: Observable<AnyAction>, state$: Observable<any>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_SEARCH_INPUT_CHANGED),
        debounceTime(500),
        withLatestFrom(state$),
        switchMap(loadAutocomplete),
    )

const assignPropertiesEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_ASSIGN_PROPERTIES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/assign'), action.stateModels).pipe(
                    mergeMap((assignedProperties: Property[]) =>
                        of(
                            actionAssignPropertiesSuccess(assignedProperties),
                            actionCubitTableClearSelection(PropertiesTableName),
                            actionCubitSnackbarShow(tr(TEXT_ASSIGNED)),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

const unassignPropertiesEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_UNASSIGN_PROPERTIES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/planning/unassign'), action.ids).pipe(
                    mergeMap(() =>
                        of(
                            actionUnassignPropertiesSuccess(action.ids),
                            actionCubitTableClearSelection(PropertiesTableName),
                            actionCubitSnackbarShow(tr(TEXT_UNASSIGNED)),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

const sendNoticesEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_SEND_NOTICES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/communication/sendNotices'), action.ids).pipe(
                    mergeMap(() =>
                        of(
                            actionSendNoticesSuccsess(action.ids),
                            actionCubitTableClearSelection(PropertiesTableName),
                            actionCubitSnackbarShow(tr(TEXT_SENT)),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

const sendYearLettersEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_SEND_YEAR_LETTERS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/communication/sendYearTax'), action.data).pipe(
                    mergeMap(() =>
                        of(
                            actionSendYearLettersSuccsess(action.data.ids),
                            actionCubitTableClearSelection(action.tableName || PropertiesTableName),
                            actionCubitSnackbarShow(tr(TEXT_SENT)),
                        ),
                    ),
                    catchError((error) => of(
                        actionCubitSnackbarShow(tr(TEXT_ERROR_OCCURRED), 'error'),
                        actionCubitTableClearSelection(PropertiesTableName)
                    ))
                ),
        ),
    )

const sendRikoNoticesEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_SEND_RIKO_NOTICES),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/communication/sendMixUseNotices'), action.ids).pipe(
                    mergeMap(() =>
                        of(
                            actionSendNoticesSuccsess(action.ids),
                            actionCubitTableClearSelection(PropertiesTableName),
                            actionCubitSnackbarShow(tr(TEXT_SENT)),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const searchEpics = combineEpics(
    searchEpic,
    searchAllEpic,
    loadAutocompleteEpic,
    assignPropertiesEpic,
    sendNoticesEpic,
    unassignPropertiesEpic,
    sendRikoNoticesEpic,
    sendYearLettersEpic
)
