import React from 'react'
import { Divider, Grid, makeStyles, Paper, Typography, Theme } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_TAXATION_INFORMATION,
    TEXT_LAND_VALUE,
    TEXT_LAST_TAXATION,
    TEXT_NEW_TAXATION,
    TEXT_PROPERTY_VALUE,
    TEXT_TAXATION_DATE,
    TEXT_TAXATION_TYPE,
    TEXT_CHANGE,
} from 'utils/translations/keys'
import { Taxation } from 'models/taxation'
import { Building } from 'models/building'
import { uniqBy, filter } from 'lodash'
import { formatCurrency } from 'utils'
import { useSelector } from 'react-redux'
import { getTaxationTypeText, getTaxDate } from 'property-page/taxations-history/functions'
import { Language } from 'shared-components/src/settings/language/language.enum'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flexGrow: 1, 
            fontSize: '16px', 
            padding: '16px 24px',
            margin: '16px'
        },
        headline: {
            fontSize: '18px', 
            paddingBottom: theme.spacing(1),
            fontWeight: 600
        },
    }
})

export const OfficeAdjustment: React.FC<{
    taxation: Taxation
}> = (props) => {
    const { taxation } = props
    const styles = useStyles()
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    const allBuildings = uniqBy([...taxation.current.buildings, ...taxation.old.buildings], 'id')

    const getBuildingType = (buildingId: string): string => {
        const housingUnit = filter(taxation.old.housingUnits, (h) => h.buildingId === buildingId)[0]
        return housingUnit ? housingUnit.type : ''
    }

    const getOldBuildingValue = (buildingId: string): number => {
        const building = filter(taxation.old.buildings, (b) => b.id === buildingId)[0]
        return building ? building.value : 0
    }

    const getNewBuildingValue = (buildingId: string): number => {
        const building = filter(taxation.current.buildings, (b) => b.id === buildingId)[0]
        return building ? building.value : 0
    }

    return (
        <Paper className={styles.container} square={true}>
            <Typography variant="h5" className={styles.headline}>{tr(TEXT_TAXATION_INFORMATION)}</Typography>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={2}>
                    {tr(TEXT_TAXATION_DATE)}
                </Grid>

                <Grid item xs={6}>{getTaxDate(taxation, language)}</Grid>
                <Grid item xs={4} />
            </Grid>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={2}>
                    {tr(TEXT_TAXATION_TYPE)}
                </Grid>

                <Grid item xs={6}>{getTaxationTypeText(taxation)}</Grid>
                <Grid item xs={4} />
            </Grid>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={2}>
                    {tr(TEXT_CHANGE)}
                </Grid>

                <Grid item xs={6}>{`${taxation.yearSettings.municipalityAdjustmentValue} %`}</Grid>
                <Grid item xs={4} />
            </Grid>

            <Divider style={{ margin: '16px -16px' }}></Divider>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={8}></Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {tr(TEXT_LAST_TAXATION)}
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {tr(TEXT_NEW_TAXATION)}
                </Grid>

                <Grid item xs={8}>
                    {tr(TEXT_LAND_VALUE)}
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {formatCurrency(taxation.old.property.plotValue)}
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {formatCurrency(taxation.current.property.plotValue)}
                </Grid>
            </Grid>

            {allBuildings.map((building: Building, index: number) => (
                <Grid container spacing={4} alignItems="center" key={index}>
                    <Grid item xs={2}>
                        {building.buildingNumber}
                    </Grid>
                    <Grid item xs={6}>
                        {building.buildingCode}, {getBuildingType(building.id)}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {formatCurrency(getOldBuildingValue(building.id))}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {formatCurrency(getNewBuildingValue(building.id))}
                    </Grid>
                </Grid>
            ))}

            <Divider style={{ margin: '16px -16px' }}></Divider>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                    {tr(TEXT_PROPERTY_VALUE)}
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {formatCurrency(taxation.old.property.totalValue)}
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {formatCurrency(taxation.current.property.totalValue)}
                </Grid>
            </Grid>
        </Paper>
    )
}
