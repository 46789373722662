import { combineEpics } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { filter, mergeMap, map, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { httpGet, plantsUrl, httpPut, httpPost, httpDelete } from 'services/httpService'
import * as actions from './tribunal-actions'
import { TribunalGroup } from './models/tribunal-group'
import { defaultCatchError } from 'app/app-epics'
import { mapTaxation, mapTribunalGroupsTaxations } from 'utils'
import { Taxation } from 'models/taxation'
import { actionCubitSnackbarShow } from 'common/cubit-snackbar/cubit-snackbar-actions'
import { tr } from 'utils/translations/translate'
import { TEXT_ADDED_TO, TEXT_APPROVED, TEXT_DECLINED, TEXT_SCHEDULED_TAXATION_FOR } from 'utils/translations/keys'
import { navigate } from '@reach/router'
import { TribunalGroupState } from './tribunal-group-page/tribunal-group-state.enum'
import { actionCubitTableClearSelection } from 'shared-components/src/cubit-table/cubit-table-actions'
import { PageReference } from './page-reference.enum'
import { TribunalGroupTablePrefix } from './tribunal-group-page/tribunal-group-taxations-table'
import { AppState } from 'app/app-store'
import { Complaint } from './models/complaint.type'

export const getTribunalGroupsFullEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUPS_GET_FULL),
        mergeMap(
            (): Observable<AnyAction> =>
                httpGet(plantsUrl('/groups/full')).pipe(
                    map((data: { groups: TribunalGroup[]; taxations: { [key: string]: Taxation[] } }) =>
                        actions.actionTribunalGroupsGetFullSuccess({
                            ...data,
                            taxations: mapTribunalGroupsTaxations(data.taxations),
                        }),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const getTribunalGroupEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUP_GET),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/groups/${action.id}`)).pipe(
                    map((data: { groups: TribunalGroup; taxations: Taxation[] }) =>
                        actions.actionTribunalGroupGetSuccess({
                            ...data,
                            taxations: data.taxations.map(taxation => mapTaxation(taxation)),
                        }),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const finishTribunalConflictsReviewEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_CONFLICTS_REVIEW_FINISH),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl(`/groups/${action.groupId}/finishConflictsReview`), {}).pipe(
                    map((data: TribunalGroup) => actions.actionTribunalConflictsReviewFinishSuccess(data)),
                    defaultCatchError(),
                ),
        ),
    )

export const updateTribunalGroupEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUP_UPDATE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPut(plantsUrl(`/groups/${action.group.id}`), action.group).pipe(
                    map((data: TribunalGroup) => actions.actionTribunalGroupUpdateSuccess(data)),
                    defaultCatchError(),
                ),
        ),
    )

export const assignTaxationsToGroupEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUP_ASSIGN_TAXATIONS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/tribunal/group/assign'), {
                    groupId: action.newGroup.id,
                    taxationsIds: action.taxationsIds,
                }).pipe(
                    mergeMap(() => {
                        if (action.pageReference === PageReference.TribunalTaxationDetails) {
                            navigate(
                                `/tribunal/group/${TribunalGroupState.Initial}/${action.newGroup.id}/taxation/${action.taxationsIds[0]}`,
                                { replace: true },
                            )
                        }

                        return of(
                            actions.actionTribunalGroupAssignTaxationsSuccess(
                                action.oldGroupId,
                                action.newGroup,
                                action.taxationsIds,
                            ),
                            actionCubitSnackbarShow(`${tr(TEXT_ADDED_TO)}: ${action.newGroup.name}`),
                            actionCubitTableClearSelection(TribunalGroupTablePrefix + action.oldGroupId),
                        )
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const createGroupAndAssignTaxationsEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUP_CREATE_AND_ASSIGN_TAXATIONS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/groups'), action.newGroup).pipe(
                    mergeMap((createdGroup: TribunalGroup) =>
                        httpPost(plantsUrl('/tax/tribunal/group/assign'), {
                            groupId: createdGroup.id,
                            taxationsIds: action.taxationsIds,
                        }).pipe(
                            mergeMap(() => {
                                if (action.pageReference === PageReference.TribunalTaxationDetails) {
                                    navigate(
                                        `/tribunal/group/${TribunalGroupState.Initial}/${action.newGroup.id}/taxation/${action.taxationsIds[0]}`,
                                        { replace: true },
                                    )
                                }

                                return of(
                                    actions.actionTribunalGroupCreateAndAssignTaxationsSuccess(
                                        action.oldGroupId,
                                        createdGroup,
                                        action.taxationsIds,
                                    ),
                                    actionCubitSnackbarShow(`${tr(TEXT_ADDED_TO)}: ${createdGroup.name}`),
                                    actionCubitTableClearSelection(TribunalGroupTablePrefix + action.oldGroupId),
                                )
                            }),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const planTribunalGroupEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUP_PLAN),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/tribunal/group/plan'), {
                    groupId: action.groupId,
                    group: action.newGroup,
                }).pipe(
                    map((data: TribunalGroup) => {
                        navigate(`/tribunal/group/${TribunalGroupState.Planned}/${data.id}`, { replace: true })

                        return actions.actionTribunalGroupPlanSuccess(action.groupId, action.newGroup)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const startTribunalGroupEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUP_START),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/tribunal/group/start'), {
                    groupId: action.groupId,
                }).pipe(
                    map(() => {
                        navigate(`/tribunal/group/${TribunalGroupState.Started}/${action.groupId}`, { replace: true })

                        return actions.actionTribunalGroupStartSuccess(action.groupId)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const readyForFinishTribunalGroupEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUP_READY_FOR_FINISH),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/tribunal/group/preparefinish'), {
                    groupId: action.groupId,
                }).pipe(
                    mergeMap(() => {
                        navigate('/tribunal')
                        return of(
                            actions.actionTribunalGroupReadyForFinishSuccess(action.groupId),
                            actionCubitSnackbarShow(`${tr(TEXT_APPROVED)}`),
                        )
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const finishTribunalGroupEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GROUP_FINISH),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/tribunal/group/finish'), {
                    groupId: action.groupId,
                }).pipe(
                    mergeMap(() => {
                        navigate('/tribunal')
                        return of(
                            actions.actionTribunalGroupFinishSuccess(action.groupId),
                            actionCubitSnackbarShow(`${tr(TEXT_APPROVED)}`),
                        )
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const markTaxationsAsConflictedEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_CONFLICTED),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/taxations/conflictedUsers'), {
                    userId: action.userId,
                    taxationsIds: action.taxationsIds,
                }).pipe(
                    mergeMap(() =>
                        of(
                            actions.actionTribunalTaxationsMarkAsConflictedSuccess(
                                action.userId,
                                action.groupId,
                                action.taxationsIds,
                            ),
                            actionCubitTableClearSelection(TribunalGroupTablePrefix + action.groupId),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const removeConflictedFromTaxationsEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_TAXATIONS_REMOVE_AS_CONFLICTED),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpDelete(plantsUrl('/tax/taxations/conflictedUsers'), {
                    userId: action.userId,
                    taxationsIds: action.taxationsIds,
                }).pipe(
                    mergeMap(() =>
                        of(
                            actions.actionTribunalTaxationsRemoveAsConflictedSuccess(
                                action.userId,
                                action.groupId,
                                action.taxationsIds,
                            ),
                            actionCubitTableClearSelection(TribunalGroupTablePrefix + action.groupId),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const markTaxationsAsFinishedEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_FINISHED),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/tribunal/finish'), {
                    taxationsIds: action.taxationsIds,
                }).pipe(
                    mergeMap(() =>
                        of(
                            actions.actionTribunalTaxationsMarkAsFinishedSuccess(action.groupId, action.taxationsIds),
                            actionCubitSnackbarShow(`${tr(TEXT_APPROVED)}`),
                            actionCubitTableClearSelection(TribunalGroupTablePrefix + action.groupId),
                        ),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const markTaxationsAsDeclinedEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_TAXATIONS_MARK_AS_DECLINED),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/tribunal/decline'), {
                    taxationsIds: action.taxationsIds,
                    declinedReason: action.declinedReason,
                }).pipe(
                    mergeMap((declinedGroup: TribunalGroup) => {
                        if (action.pageReference === PageReference.TribunalTaxationDetails) {
                            navigate(
                                `/tribunal/group/${TribunalGroupState.Declined}/${declinedGroup.id}/taxation/${action.taxationsIds[0]}`,
                                { replace: true },
                            )
                        }

                        return of(
                            actions.actionTribunalTaxationsMarkAsDeclinedSuccess(
                                action.groupId,
                                declinedGroup,
                                action.taxationsIds,
                                action.declinedReason,
                            ),
                            actionCubitSnackbarShow(`${tr(TEXT_DECLINED)}`),
                            actionCubitTableClearSelection(TribunalGroupTablePrefix + action.groupId),
                        )
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const rescheduleTaxationsEpic = (
    action$: Observable<AnyAction>,
    state$: Observable<AppState>,
): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/reschedule'), {
                    userId: action.userId,
                    taxationsIds: action.taxationsIds,
                }).pipe(
                    mergeMap((events: any[]) => {
                        return of(
                            actions.actionTribunalTaxationsRescheduleSuccess(action.groupId, action.taxationsIds, events),
                            actionCubitSnackbarShow(
                                `${tr(TEXT_SCHEDULED_TAXATION_FOR)} ${state.appData.users[action.userId].name}`,
                            ),
                            actionCubitTableClearSelection(TribunalGroupTablePrefix + action.groupId),
                        )
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const getTribunalApprovedEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GET_APPROVED),
        mergeMap(
            (): Observable<AnyAction> =>
                httpGet(plantsUrl('/groups/preview/approved')).pipe(
                    map((data: any[]) =>
                        actions.actionTribunalGetApprovedSuccess(data),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const getTribunalGroupDetailsEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_GET_GROUP_DETAILS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/groups/groupDetails/${action.id}`)).pipe(
                    map((data: any) =>
                        actions.actionGetGroupDetailsSuccess(data),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const sendTribunalGroupLettersEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_SEND_GROUP_LETTERS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/communication/sendTribunalGroupLetters'), action.ids).pipe(
                    mergeMap(() =>
                        of(
                            actions.actionSendGroupLettersSuccess(action.ids),
                            actionCubitTableClearSelection(action.tableName)
                        )
                    ),
                    defaultCatchError(),
                ),
        ),
    )

//#region Complaints
export const getTribunalComplaintEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_COMPLAINTS_GET),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/complaints/${action.complaintId}`)).pipe(
                    map((complaint: Complaint) => actions.actionTribunalComplaintsGetSuccess(complaint)),
                    defaultCatchError(),
                ),
        ),
    )

export const updateTribunalComplaintEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_COMPLAINTS_UPDATE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/complaints'), action.complaint).pipe(
                    map((complaint: Complaint) => actions.actionTribunalComplaintsUpdateSuccess(complaint)),
                    defaultCatchError(),
                ),
        ),
    )

export const getAllTribunalComplaints = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_COMPLAINTS_GET_ALL),
        mergeMap(
            (): Observable<AnyAction> =>
                httpGet(plantsUrl('/tax/complaints')).pipe(
                    map((complaints: Complaint[]) => actions.actionTribunalComplaintsGetAllSuccess(complaints)),
                    defaultCatchError(),
                ),
        ),
    )

export const getTribunalTaxationComplaints = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === actions.ACTION_TRIBUNAL_COMPLAINTS_GET_TAXATION_COMPLAINTS),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/taxations/${action.taxationId}/complaints`)).pipe(
                    map((complaint: Complaint) => actions.actionTribunalComplaintsGetSuccess(complaint)),
                    defaultCatchError(),
                ),
        ),
    )
//#endregion

export const tribunalEpics = combineEpics(
    getTribunalGroupsFullEpic,
    getTribunalGroupEpic,
    finishTribunalConflictsReviewEpic,
    updateTribunalGroupEpic,
    assignTaxationsToGroupEpic,
    createGroupAndAssignTaxationsEpic,
    planTribunalGroupEpic,
    startTribunalGroupEpic,
    readyForFinishTribunalGroupEpic,
    finishTribunalGroupEpic,
    markTaxationsAsConflictedEpic,
    removeConflictedFromTaxationsEpic,
    markTaxationsAsFinishedEpic,
    markTaxationsAsDeclinedEpic,
    rescheduleTaxationsEpic,
    getTribunalComplaintEpic,
    updateTribunalComplaintEpic,
    getAllTribunalComplaints,
    getTribunalTaxationComplaints,
    getTribunalApprovedEpic,
    getTribunalGroupDetailsEpic,
    sendTribunalGroupLettersEpic
)
