import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import { ComplaintCause } from './complaint-cause.type'
import { ComplaintStatus } from 'tribunal-page/enums/complaint-status.enum'
import { ComplaintGroupType } from 'tribunal-page/enums/complaint-group-type.enum'

export type Complaint = {
    id: string
    propertyNumber: string
    address: string
    complaintId: string
    taxationId: string
    causes: ComplaintCause<ComplaintCauseType>[]
    status: ComplaintStatus

    // mapped props
    complaintGroupType?: ComplaintGroupType
}

export const isReadyForTribunal = (complaint: Complaint) => {
    const causes = complaint.causes
    const allProcessed = causes.every((cause: any) => cause.state !== null)
    return causes.some((cause: any) => cause.state === ComplaintStatus.ReadyForTribunal) && allProcessed
}

export const getComplantStatus = (complaint: Complaint) => {
    const causes = complaint.causes
    if (causes.length === 1) {
        return causes[0].state
    }
    // check if all the states are the same
    const firstCause = causes[0]
    if (causes.every((cause: any) => cause.state === firstCause.state)) {
        return firstCause.state
    }
    // multiple different states - return partial approval
    return ComplaintStatus.PartiallyAccepted
}

export const complaintCausessProccessed = (complaint: Complaint) => {
    const causes = complaint.causes
    const allProcessed = causes.every((cause: any) => cause.state !== null)
    return !causes.some((cause: any) => cause.state === ComplaintStatus.ReadyForTribunal) && allProcessed
}