import { combineEpics } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'

import { httpGet, plantsUrl } from 'services/httpService'
import { defaultCatchError } from 'app/app-epics'
import { AppState } from 'app/app-store'
import { ACTION_GET_FAILED_CORRESPONDENCE, actionGetFailedCorrespondenceSuccess } from './dashboard-actions'
import { FailedCorrespondenceDetails } from 'models/dashboard'

export const getFailedCorrespondenceEpic = (
    action$: Observable<AnyAction>,
    state$: Observable<AppState>,
): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_GET_FAILED_CORRESPONDENCE),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpGet(plantsUrl('/dashboard/getFailedCorrespondence')).pipe(
                    mergeMap((data: FailedCorrespondenceDetails[]) => {
                        return of(actionGetFailedCorrespondenceSuccess(data))
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const dashboardEpics = combineEpics(getFailedCorrespondenceEpic)
