import { makeReducer } from 'utils'
import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import {
    ACTION_TAXATION_CREATE,
    ACTION_TAXATION_CREATE_SUCCESS,
    ACTION_TAXATION_FINISH,
    ACTION_TAXATION_FINISH_SUCCESS,
    ACTION_TAXATION_GET_WITH_HISTORY,
    ACTION_TAXATION_GET_WITH_HISTORY_SUCCESS,
    ACTION_TAXATION_UPDATE,
    ACTION_TAXATION_UPDATE_SUCCESS,
    ACTION_TAXATION_UPLOAD,
    ACTION_TAXATION_UPLOAD_SUCCESS,
    ACTION_TAXATION_REOPEN,
    ACTION_TAXATION_REOPEN_SUCCESS,
    ACTION_TAXATION_GET,
    ACTION_TAXATION_GET_SUCCESS,
    ACTION_LOAD_CORRESPONDANCE,
    ACTION_LOAD_CORRESPONDANCE_SUCCESS,
    ACTION_LOAD_COMMUNICATION,
    ACTION_LOAD_COMMUNICATION_SUCCESS,
    ACTION_UPLOAD_ATTACHMENT,
    ACTION_UPLOAD_ATTACHMENT_SUCCESS,
    ACTION_SEND_COMMUNICATION,
    ACTION_SEND_COMMUNICATION_SUCCESS,
    ACTION_MANUAL_VALUE_SAVE,
    ACTION_MANUAL_VALUE_SAVE_SUCCESS,
    ACTION_DOCUMENT_SAVE,
    ACTION_DOCUMENT_SAVE_SUCCESS,
} from './taxation-actions'
import { Taxation, TaxationState as TaxationStateEnum } from 'models/taxation'

export type TaxationState = { [key: string]: Taxation }

type TaxationReducer = ActionReducer<TaxationState>

const createTaxation: TaxationReducer = state => state

const createTaxationuccess: TaxationReducer = (state, action) => ({ ...state, [action.taxation.id]: action.taxation })

const getTaxation: TaxationReducer = state => state

const getTaxationSuccess: TaxationReducer = (state, action) => ({
    ...state,
    [action.taxation.id]: { ...action.taxation },
})

const getTaxationWithHistory: TaxationReducer = state => state

const getTaxationWithHistorySuccess: TaxationReducer = (state, action) => ({
    ...state,
    [action.taxation.id]: action.taxation,
})

const updateTaxation: TaxationReducer = state => state

const updateTaxationSuccess: TaxationReducer = (state, action) => ({ ...state, [action.taxation.id]: action.taxation })

const finishTaxation: TaxationReducer = state => state

const finishTaxationSuccess: TaxationReducer = (state, action) => ({ ...state, [action.taxation.id]: action.taxation })

const reopenTaxation: TaxationReducer = state => state

const reopenTaxationSuccess: TaxationReducer = (state, action) => ({ ...state, [action.taxation.id]: action.taxation })

const uploadTaxationFile: TaxationReducer = state => state

const uploadTaxationFileSuccess: TaxationReducer = state => state

const loadCorrespondance: TaxationReducer = state => state

const loadCorrespondanceSuccess: TaxationReducer = (state, action) => (
    { 
        ...state, 
        [action.taxationId]: {
            ...state[action.taxationId],
            correspondance: action.correspondance
        }
    }
)

const loadCommunication: TaxationReducer = (state, action) => {
    return {
        ...state,
        [action.taxationId]: {
            ...state[action.taxationId],
            communication: {
                loading: true
            }
        }
    }
}

const loadCommunicationSuccess: TaxationReducer = (state, action) => (
    { 
        ...state, 
        [action.taxationId]: {
            ...state[action.taxationId],
            communication: action.communication
        }
    }
)

const uploadAttachment: TaxationReducer = state => state

const uploadAttachmentSuccess: TaxationReducer = state => state

const sendCommunication: TaxationReducer = state => state

const sendCommunicationSuccess: TaxationReducer = (state, action) => {
    const taxId = action.entries[0].forId
    let comms = state[taxId].communication
    state[taxId].communication = [
        ...action.entries,
        ...comms
    ]
    return state
}

const saveManualValueDoc: TaxationReducer = (state) => state

const saveManualValueDocSuccess: TaxationReducer = (state, action) => {
    const property = state[action.id]?.current?.property
    if (!property) {
        return state
    }
    
    property.otherPropertyFlags.manualValueDocument = action.file
    return state
}

const saveManualValue: TaxationReducer = (state) => state

const saveManualValueSuccess: TaxationReducer = (state, action) => {
    const property = state[action.id]?.current?.property
    if (!property) {
        return state
    }
    
    property.otherPropertyFlags.manualValue = action.value
    property.otherPropertyFlags.manualKoValue = action.manualKoValue
    property.otherPropertyFlags.manualValueComment = action.comment
    //taxation should be automatically closed after this
    state[action.id].state = TaxationStateEnum.TaxationFinished
    return state
}

const reducers: Reducers<TaxationState> = {
    [ACTION_TAXATION_CREATE]: createTaxation,
    [ACTION_TAXATION_CREATE_SUCCESS]: createTaxationuccess,
    [ACTION_TAXATION_GET]: getTaxation,
    [ACTION_TAXATION_GET_SUCCESS]: getTaxationSuccess,
    [ACTION_TAXATION_GET_WITH_HISTORY]: getTaxationWithHistory,
    [ACTION_TAXATION_GET_WITH_HISTORY_SUCCESS]: getTaxationWithHistorySuccess,
    [ACTION_TAXATION_UPDATE]: updateTaxation,
    [ACTION_TAXATION_UPDATE_SUCCESS]: updateTaxationSuccess,
    [ACTION_TAXATION_FINISH]: finishTaxation,
    [ACTION_TAXATION_FINISH_SUCCESS]: finishTaxationSuccess,
    [ACTION_TAXATION_REOPEN]: reopenTaxation,
    [ACTION_TAXATION_REOPEN_SUCCESS]: reopenTaxationSuccess,
    [ACTION_TAXATION_UPLOAD]: uploadTaxationFile,
    [ACTION_TAXATION_UPLOAD_SUCCESS]: uploadTaxationFileSuccess,
    [ACTION_LOAD_CORRESPONDANCE]: loadCorrespondance,
    [ACTION_LOAD_CORRESPONDANCE_SUCCESS]: loadCorrespondanceSuccess,
    [ACTION_LOAD_COMMUNICATION]: loadCommunication,
    [ACTION_LOAD_COMMUNICATION_SUCCESS]: loadCommunicationSuccess,
    [ACTION_UPLOAD_ATTACHMENT]: uploadAttachment,
    [ACTION_UPLOAD_ATTACHMENT_SUCCESS]: uploadAttachmentSuccess,
    [ACTION_SEND_COMMUNICATION]: sendCommunication,
    [ACTION_SEND_COMMUNICATION_SUCCESS]: sendCommunicationSuccess,
    [ACTION_DOCUMENT_SAVE]: saveManualValueDoc,
    [ACTION_DOCUMENT_SAVE_SUCCESS]: saveManualValueDocSuccess,
    [ACTION_MANUAL_VALUE_SAVE]: saveManualValue,
    [ACTION_MANUAL_VALUE_SAVE_SUCCESS]: saveManualValueSuccess
}

export const taxationReducer = makeReducer<TaxationState>(reducers, {})
