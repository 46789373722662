import { convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import draftToHtml from 'draftjs-to-html'
import { replaceMergeFields } from "settings-templates/template-document-helper";

export const suggestionOptions = {
    PAGE_NUMBER : 'bunntekst.sidetall',
    TOTAL_PAGES: 'bunntekst.antallsider',
    TAXATION_DATE: 'taksering.dato',
    PROPERTY_ADDRESS: 'eiendom.adresse',
    PROPERTY_MATRIKKEL_ADDRESS: 'eiendom.matrikkel',
    OWNER_ADDRESS: 'eier.adresse',
    CONTACT_PHONE: 'kunde.telefon',
    PROPERTY_VALUE: 'eiendom.takst',
    PROPERTY_TAX: 'eiendom.skatt',
    NOTICE_DATE: 'dokument_dato',
    USER_NAME: 'bruker_navn',
    MATRIKKEL: 'matrikkel',
    ADDRESS: 'adresse',
    OWNERS: 'eier',
    INVOICE_RECEIVER: 'faktura_mottaker',
    EXEMPT: 'fritak',
    VALUE_TYPE: 'verdi_type',
    UNITS_COUNT: 'antall_boenheter',
    TAX_DEPARTMENT_VALUE: 'formuegrunnlag',
    REDUCTION_FACTOR: 'reduksjons_faktor',
    REDUCTION: 'bunnfradrag',
    VALUE_AFTER_FACTORS: 'skatteverdi_etter_faktorer',
    RESIDENTIAL_TAX_RATE: 'skatte_rate_bolig',
    OTHER_TAX_RATE: 'skatte_rate_almindelig',
    YEARLY_TAX_VALUE: 'årlig_skatt',
    ZONE_FACTOR: 'sone_faktor',
    LOCATION_FACTOR: 'beliggenhets_faktor',
    VALUE: 'takst',
    MANUAL_TAX_VALUE: 'manuel_taksering',
}

const suggestions = [
    //text is what is seen in the editor, value is used as key for filtering
    { text: 'taksering.dato', value: 'taksering.dato'},
    { text: 'eiendom.adresse', value: 'eiendom.adresse'},
    { text: 'eiendom.matrikkel', value: 'eiendom.matrikkel'},
    { text: 'eier.adresse', value: 'eier.adresse'},
    { text: 'kunde.telefon', value: 'kunde.telefon'},
    { text: 'eiendom.takst', value: 'eiendom.takst'},
    { text: 'eiendom.skatt', value: 'eiendom.skatt'},
    { text: 'dokument_dato', value: 'dokument_dato'},
    { text: 'bruker_navn', value: 'bruker_navn'},
    { text: 'matrikkel', value: 'matrikkel'},
    { text: 'adresse', value: 'adresse'},
    { text: 'eier', value: 'eier'},
    { text: 'faktura_mottaker', value: 'faktura_mottaker'},
    { text: 'fritak', value: 'fritak'},
    { text: 'verdi_type', value: 'verdi_type'},
    { text: 'antall_boenheter', value: 'antall_boenheter'},
    { text: 'formuegrunnlag', value: 'formuegrunnlag'},
    { text: 'reduksjons_faktor', value: 'reduksjons_faktor'},
    { text: 'bunnfradrag', value: 'bunnfradrag'},
    { text: 'skatteverdi_etter_faktorer', value: 'skatteverdi_etter_faktorer'},
    { text: 'skatte_rate_bolig', value: 'skatte_rate_bolig'},
    { text: 'skatte_rate_almindelig', value: 'skatte_rate_almindelig'},
    { text: 'årlig_skatt', value: 'årlig_skatt'},
    { text: 'sone_faktor', value: 'sone_faktor'},
    { text: 'beliggenhets_faktor', value: 'beliggenhets_faktor'},
    { text: 'takst', value: 'takst'},
    { text: 'manuel_taksering', value: 'manuel_taksering'},
]

export const mergeFields: any = {
    separator: ' ',
    trigger: '@',
    suggestions: suggestions,
}

export const stripHtml = (html: any) => 
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

export const convertDraftToHtml = (editorState: any) => {
    return draftToHtml(convertToRaw(editorState))
}

const exportInlineStyles = {
    'BOLD': { style: {fontWeight: 'bold'}},
    'ITALIC': { style: {fontStyle: 'italic'}},
    'UNDERLINE': { style: {textDecoration: 'underline'}},
    'SIZE8': { style: {fontSize: '8pt' }},
    'SIZE9': { style: {fontSize: '9pt' }},
    'SIZE10': { style: {fontSize: '10pt' }},
    'SIZE11': { style: {fontSize: '11pt' }},
    'SIZE12': { style: {fontSize: '12pt' }},
    'SIZE13': { style: {fontSize: '13pt' }},
    'SIZE14': { style: {fontSize: '14pt' }},
    'SIZE15': { style: {fontSize: '15pt' }},
    'SIZE16': { style: {fontSize: '16pt' }},
    'COLOR#000000': { style: { color: '#000000'}},
    'COLOR#808080': { style: { color: '#808080'}},
    'COLOR#D0021B': { style: { color: '#D0021B'}},
    'COLOR#1163CC': { style: { color: '#1163CC'}},
    'COLOR#417505': { style: { color: '#417505'}},
    'LINK': { style: { textDecoration: 'underline', color: '#2b7bb9' }},
}

export const editorExportOptions = {
    inlineStyles: exportInlineStyles,
    defaultBlockTag: 'div'
}

export const draftJsToHtml = (editorState: any) => stateToHTML(editorState, editorExportOptions);

export const replaceMentions = (text: string, data: any) => {
    Object.values(suggestionOptions).forEach(target => {
        while (text.indexOf(target) > -1){
            // add the @
            const realTarget = `@${target}`
            text = text.replace(realTarget, replaceMergeFields(realTarget, data));
        }
    });
    return text;
};