import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { RouteComponentProps } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { actionTaxationGetWithHistory, actionTaxationReopen } from 'property-taxation-edit-page/taxation-actions'
import { AppState } from 'app/app-store'
import { TaxationDetails } from 'property-taxation-details-page/taxation-details/taxation-details'
import { getPropertyTitle, getPropertyAddresses } from 'utils'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { Grid, Button } from '@material-ui/core'
import { TaxationState } from 'models/taxation'
import { TEXT_REOPEN } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { actionGetTaxSettings } from 'settings-page/settings-actions'

export const PropertyTaxationDetailsPage: React.FC<RouteComponentProps<{ id: string; location: any }>> = props => {
    const { id = '' } = props
    const dispatch = useDispatch()
    const settings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)

    useEffect(() => {
        dispatch(actionTaxationGetWithHistory(id))
    }, [id, dispatch])

    useEffect(() => {
        if (!settings.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [settings, dispatch])

    const taxation = useSelector((state: AppState) => state.taxations[id])

    const title = taxation
        ? getPropertyTitle(taxation.current.property, getPropertyAddresses(taxation.current.housingUnits))
        : ''

    const handleTaxationReopen = () => {
        dispatch(actionTaxationReopen({ ...taxation, state: TaxationState.TaxationStarted }))
    }

    return (
        <LayoutDefault
            toolbar={
                <TitleToolbar title={title}>
                    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                        {taxation && taxation.state === TaxationState.TaxationFinished && (
                            <Grid item>
                                <Button variant="outlined" color="inherit" type="button" onClick={handleTaxationReopen}>
                                    {tr(TEXT_REOPEN)}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </TitleToolbar>
            }
            navigation={<Navigation />}
        >
            {taxation && <TaxationDetails taxation={taxation}></TaxationDetails>}
        </LayoutDefault>
    )
}
