import React from 'react'
import { Grid } from '@material-ui/core'

type TaxSettingsRowProps = {
    label?: string
    children?: any
    classes: any
    displayDivider?: boolean
}
export const TaxSettingsRow: React.FC<TaxSettingsRowProps> = (props) => {
    const { children } = props
    
    return (
        <Grid container>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    )
}
