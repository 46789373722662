import { DocumentTypes } from 'common/enums/document-types'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { capitalize } from 'lodash'
import { TEXT_DAYS, TEXT_EMAIL, TEXT_LETTER, TEXT_NOTICE, TEXT_SMS } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

export const getEntryTypeText = (type: string) => {
    switch (type) {
        case TaxFormFieldNames.notice:
            return tr(TEXT_NOTICE)

        default:
            return tr(TEXT_NOTICE)
    }
}

export const getEntryChannelText = (channel: string) => {
    switch (channel) {
        case DocumentTypes.SMS:
            return tr(TEXT_SMS)
        case DocumentTypes.EMAIL:
            return tr(TEXT_EMAIL)
        case DocumentTypes.LETTER:
            return tr(TEXT_LETTER)
        default:
            return tr(TEXT_EMAIL)
    }
}

export const getEntryTemplateText = (entry: any, templates: any) => {
    const template: any = templates.find((t: any) => t.id === entry.channels[0].templateId)
    return `${getEntryChannelText(entry.channels[0]?.type)}: ${template?.name || ''}`
}

export const getEntryTriggerText = (entry: any) => {
    if (entry.trigger === '') {
        return ''
    }
    return `${entry.noOfDays} ${tr(TEXT_DAYS)} ${tr(entry.trigger)}`
}

export const getChannelOptions = () => [
    {
        label: tr(TEXT_SMS),
        value: DocumentTypes.SMS,
    },
    {
        label: capitalize(tr(TEXT_EMAIL)),
        value: DocumentTypes.EMAIL,
    },
    {
        label: capitalize(tr(TEXT_LETTER)),
        value: DocumentTypes.LETTER,
    },
]

export const getTemplateOptions = (
    currentChannel: string,
    caseDocuments: any,
    smsTemplates: any,
    emailTemplates: any,
    letterTemplates: any,
) => {
    switch (currentChannel) {
        case DocumentTypes.SMS:
            return Object.values(smsTemplates.templates).map((template: any) => {
                return {
                    label: template.name,
                    value: template.id,
                }
            })
        case DocumentTypes.LETTER:
            return Object.values(letterTemplates.templates).map((template: any) => {
                return {
                    label: template.name,
                    value: template.id,
                }
            })

        case DocumentTypes.CASE_DOCUMENT:
            return Object.values(caseDocuments.templates).map((template: any) => {
                return {
                    label: template.name,
                    value: template.id,
                }
            })

        default:
            if (!emailTemplates.templates) {
                return [
                    {
                        label: '',
                        value: '',
                    },
                ]
            }
            return Object.values(emailTemplates.templates).map((template: any) => {
                return {
                    label: template.name,
                    value: template.id,
                }
            })
    }
}

export const getEntryCaseDocuments = (entry: any, templates: any) => {
    if (!entry.letters.length || !templates) {
        return ''
    }
    let names = ''
    entry.letters.forEach((letter: any, index: number) => {
        const template = templates[letter.letterId]
        names += template?.name || ''
        if (index < entry.letters.length - 1 && names !== '') {
            names += ', '
        }
    })
    return names
}
