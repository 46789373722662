import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Divider, Grid } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_TAXATION_INFORMATION,
    TEXT_LAND_VALUE,
    TEXT_LAST_TAXATION,
    TEXT_NEW_TAXATION,
    TEXT_PROPERTY_VALUE,
    TEXT_ZONE_FACTOR,
    TEXT_LOCATION_FACTOR,
    TEXT_PRESENT_AT_TAXATION,
} from 'utils/translations/keys'
import { ExpandMore } from '@material-ui/icons'
import { Taxation } from 'models/taxation'
import { Building } from 'models/building'
import { uniqBy, filter, find } from 'lodash'
import { formatCurrency, getTaxInfoTranslationKeyByValue, getPropertyLocationFactorTranslationKey } from 'utils'
import { Owner } from 'models/owner'
import { useSelector } from 'react-redux'
import { TaxSettingsState } from 'settings-page/settings-reducer'

const getOwnersNames = (ownersIds: string[], owners: Owner[]): string => {
    const matchedOwners = filter(owners, a => !!find(ownersIds, b => b === a.id))
    return matchedOwners.map(o => o.name || o.firstName + o.lastName || o.email || o.number).join(', ')
}

export const TaxationDetailsSummary: React.FC<{
    taxation: Taxation
}> = props => {
    const { taxation } = props
    const taxationSettings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)

    const allBuildings = uniqBy([...taxation.current.buildings, ...taxation.old.buildings], 'id')

    const getBuildingType = (buildingId: string): string => {
        const housingUnit = filter(taxation.old.housingUnits, h => h.buildingId === buildingId)[0]
        return housingUnit ? housingUnit.type : ''
    }

    const getOldBuildingValue = (buildingId: string): number => {
        const building = filter(taxation.old.buildings, b => b.id === buildingId)[0]
        return building ? building.value : 0
    }

    const getNewBuildingValue = (buildingId: string): number => {
        const building = filter(taxation.current.buildings, b => b.id === buildingId)[0]
        return building ? building.value : 0
    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} style={{ fontSize: '1.25rem', fontWeight: 500 }}>
                {tr(TEXT_TAXATION_INFORMATION)}
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ flexGrow: 1, fontSize: '16px' }}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            {tr(TEXT_LAST_TAXATION)}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            {tr(TEXT_NEW_TAXATION)}
                        </Grid>

                        <Grid item xs={8}>
                            {tr(TEXT_LAND_VALUE)}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {formatCurrency(taxation.old.property.plotValue)}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {formatCurrency(taxation.current.property.plotValue)}
                        </Grid>
                    </Grid>

                    {allBuildings.map((building: Building, index: number) => (
                        <Grid container spacing={4} alignItems="center" key={index}>
                            <Grid item xs={2}>
                                {building.buildingNumber}
                            </Grid>
                            <Grid item xs={6}>
                                {building.buildingCode}, {getBuildingType(building.id)}
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                {formatCurrency(getOldBuildingValue(building.id))}
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                {formatCurrency(getNewBuildingValue(building.id))}
                            </Grid>
                        </Grid>
                    ))}

                    <Divider style={{ margin: '16px -16px' }}></Divider>

                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                            {tr(TEXT_ZONE_FACTOR)}
                        </Grid>

                        <Grid item xs={6}></Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {taxation.old.property.zoneFactor}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {taxation.current.property.zoneFactor}
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                            {tr(TEXT_LOCATION_FACTOR)}
                        </Grid>

                        <Grid item xs={6}></Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {taxation.old.property.locationFactor}{' '}
                            {getPropertyLocationFactorTranslationKey(taxation.old.property.locationFactor, taxationSettings.locationFactors)}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {taxation.current.property.locationFactor}{' '}
                            {getPropertyLocationFactorTranslationKey(taxation.current.property.locationFactor, taxationSettings.locationFactors)}
                        </Grid>
                    </Grid>

                    <Divider style={{ margin: '16px -16px' }}></Divider>

                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                            {tr(TEXT_PROPERTY_VALUE)}
                        </Grid>

                        <Grid item xs={6}></Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {formatCurrency(taxation.old.property.totalValue)}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {formatCurrency(taxation.current.property.totalValue)}
                        </Grid>
                    </Grid>

                    {taxation.participant && (
                        <>
                            <Divider style={{ margin: '16px -16px' }}></Divider>

                            <Grid container spacing={4} alignItems="center">
                                <Grid item xs>
                                    {`${tr(TEXT_PRESENT_AT_TAXATION)} - ${tr(
                                        getTaxInfoTranslationKeyByValue(taxation.participant.type),
                                    ).toLowerCase()}`}
                                    {taxation.participant.description
                                        ? `: ${taxation.participant.description}`
                                        : taxation.participant.ownersIds
                                        ? `: ${getOwnersNames(taxation.participant.ownersIds, taxation.current.owners)}`
                                        : ''}
                                </Grid>
                            </Grid>
                        </>
                    )}
                </div>
            </AccordionDetails>
        </Accordion>
    )
}
