import { useCompanies, useUsers } from 'app/app-selectors'
import { AppState } from 'app/app-store'
import { MapCard } from 'map/components'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropertiesTable, { PropertiesTableName } from 'search-page/properties-results/properties-table'

interface PropertiesMapCard {}

const PropertiesMapCard: React.FC<PropertiesMapCard> = () => {
    const [show, setShow] = useState(false)
    const companies = useCompanies()
    const users = useUsers()
    const selected = useSelector((state: any) =>
        state.table[PropertiesTableName] && state.table[PropertiesTableName].selection
            ? state.table[PropertiesTableName].selection
            : [],
    )
    const selectedSearchResult = useSelector((state: AppState) =>
        state.search.results.properties.items.filter((i) => selected.indexOf(i.id) !== -1),
    )

    useEffect(() => {
        selectedSearchResult.length > 0 ? setShow(true) : setShow(false)
    }, [selectedSearchResult])

    return show ? (
        <MapCard
            onClose={() => setShow(false)}
            title={'Title'}
            content={
                <PropertiesTable
                    searchResult={{ items: selectedSearchResult, isLoading: false, total: selectedSearchResult.length }}
                    companies={companies}
                    users={users}
                />
            }
            fullWidth
        />
    ) : null
}

export default PropertiesMapCard
