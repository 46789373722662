import React, { useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Box,
    IconButton,
    Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getYesNoBoolOptions, tr } from 'utils/translations/translate'
import {
    TEXT_LAND_VALUE,
    TEXT_BUILDING_VALUE,
    TEXT_TOTAL_BEFORE_FACTOR,
    TEXT_ZONE_FACTOR,
    TEXT_LOCATION_FACTOR,
    TEXT_TOTAL_PROPERTY_VALUE,
    TEXT_EXEMPTION,
    TEXT_PROPERTY_VALUE_BEFORE_FACTORS,
    TEXT_REDUCTION_FACTOR,
    TEXT_NOTE,
    TEXT_VALUE,
    TEXT_USE_NATIONAL_SETTINGS,
    TEXT_MANUAL_VALUE,
    TEXT_CANCEL,
    TEXT_COMBINED,
    TEXT_RESIDENTIAL_UNITS,
} from 'utils/translations/keys'
import { formatCurrency } from 'utils'
import { Property } from 'models/property'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import { actionPropertySave } from 'property-page/property-actions'
import { submit, Field, formValueSelector, reset } from 'redux-form'
import { TaxFormName, TaxFormFieldNames } from 'common/enums/form-name.enum'
import { PropertyDetailsPanelForm } from './property-details-form'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { Edit, Info, Save } from '@material-ui/icons'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { formatPercentage } from 'year-settings-page/helper-functions'
import { getReductionFactor } from 'models/year-settings'
import {
    getExemptReasonOptions,
    getFinalPlotValue,
    getPropertyValueAfterFactors,
    getPropertyValueBeforeFactors,
} from 'utils/property/property-helper'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { GetExemption } from './property-get-exemption'
import { actionSetPreviewPdf } from 'app/app-actions'
import { simpleFormatNumber } from 'shared-components/src/utils/format/format-number'
import { getResidentialHousingUnitCount, isCombinedProperty } from 'models/taxation'
import { PropertyObjectWithTaxations } from 'models/property-object'

type PanelProps = {
    styles: any
    prop: Property
    propertyData: PropertyObjectWithTaxations
}

export const PropertyTotalValuePanel: React.FC<PanelProps> = (props) => {
    const { styles, prop, propertyData } = props
    const [editing, setEditing] = useState(false)
    const [propertyPanelExpanded, setPropertyPanelExpanded] = useState(false)
    const dispatch = useDispatch()
    const selector = formValueSelector(TaxFormName.PropertyTotalValuePanel)
    const stateRef = useSelector((state: AppState) => state)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const yearSettings = useSelector((state: AppState) => state.taxSettings.yearSettings)
    const taxSettings = useSelector((state: AppState) => state.taxSettings.taxSettings)

    const startEditing = (e: React.MouseEvent) => {
        e.stopPropagation()
        setPropertyPanelExpanded(true)
        setEditing(true)
    }
    const handleFormSubmit = (values: any) => {
        setEditing(false)
        dispatch(actionPropertySave(prop, values))
    }
    const handleSaveClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch(submit(TaxFormName.PropertyTotalValuePanel))
    }
    const handleCancelEdit = () => {
        setEditing(false)
        dispatch(reset(TaxFormName.PropertyTotalValuePanel))
    }

    return (
        <Accordion expanded={propertyPanelExpanded} square={true} elevation={0} className={styles.semiGrouped}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={styles.panelSummary}
                onClick={() => setPropertyPanelExpanded(!propertyPanelExpanded)}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs>
                        {tr(TEXT_TOTAL_PROPERTY_VALUE)}
                    </Grid>
                    <Grid item xs>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                            <GetExemption isExempt={prop.exempt} exemptReason={prop.exemptReason} />
                            <Grid item>
                                {formatCurrency(getPropertyValueAfterFactors(prop, taxSettings, yearSettings))}
                            </Grid>
                            <Protected p={Permission.Complaints}>
                                {!editing && (
                                    <Grid item>
                                        <IconButton size="small" onClick={startEditing} color="inherit">
                                            <Edit />
                                        </IconButton>
                                    </Grid>
                                )}
                                {editing && (
                                    <Grid item>
                                        <Button
                                            className={styles.cancelButton}
                                            onClick={handleCancelEdit}
                                            color="inherit"
                                        >
                                            {tr(TEXT_CANCEL)}
                                        </Button>
                                        <IconButton size="small" onClick={handleSaveClick} color="inherit">
                                            <Save />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Protected>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>

            <AccordionDetails className={styles.panelContent}>
                <Grid container spacing={2} className={styles.alignRight}>
                    <Grid item sm={3} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_LAND_VALUE)}:
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        {formatCurrency(getFinalPlotValue(prop, taxSettings, yearSettings))}
                    </Grid>

                    <Grid item sm={6} xs={12} />

                    <Grid item sm={3} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_BUILDING_VALUE)}:
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        {formatCurrency(prop.buildingsValue)}
                    </Grid>

                    <Grid item sm={6} xs={12} />

                    <Grid item sm={3} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_TOTAL_BEFORE_FACTOR)}:
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        {formatCurrency(getPropertyValueBeforeFactors(prop, taxSettings, yearSettings))}
                    </Grid>

                    <Grid container className={styles.mb2}></Grid>

                    <Grid item sm={3} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_ZONE_FACTOR)}:
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        {`${simpleFormatNumber(prop.zoneFactor)} x`}
                    </Grid>
                    <Grid item sm={6} xs={12} />

                    <Grid item sm={3} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_LOCATION_FACTOR)}:
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        {`${simpleFormatNumber(prop.locationFactor)}  x`}
                    </Grid>
                    <Grid container></Grid>

                    <Grid item sm={3} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_REDUCTION_FACTOR)}:
                    </Grid>
                    <Grid item sm={3} xs={6} className={styles.mb2}>
                        {formatPercentage(getReductionFactor(yearSettings))}
                    </Grid>
                    <Grid item sm={6} xs={12} />

                    <Grid item sm={3} xs={6} className={styles.panelContent__label}>
                        {tr(TEXT_PROPERTY_VALUE_BEFORE_FACTORS)}:
                    </Grid>
                    <Grid item sm={3} xs={6} className={styles.mb2}>
                        <Box fontWeight={600}>
                            {formatCurrency(getPropertyValueAfterFactors(prop, taxSettings, yearSettings))}
                        </Box>
                    </Grid>

                    {!editing && prop.exempt && (
                        <Grid container className={styles.container} spacing={2}>
                            <Grid item xs={3} className={styles.panelContent__label}>
                                {tr(TEXT_EXEMPTION)}:
                            </Grid>

                            <Grid item xs={3} className={styles.p2}>
                                {prop.exemptReason}
                            </Grid>
                        </Grid>
                    )}
                    {!editing && prop.otherPropertyFlags?.nationalValuation && (
                        <Grid container className={styles.container} spacing={2} alignItems="center">
                            <Grid item xs={3} className={styles.panelContent__label}>
                                {tr(TEXT_USE_NATIONAL_SETTINGS)}:
                            </Grid>

                            <Grid item xs={3} className={styles.p2}>
                                {formatCurrency(prop.otherPropertyFlags?.nationalValuation)}
                            </Grid>
                        </Grid>
                    )}
                    {!editing && prop.otherPropertyFlags?.manualValue && (
                        <Grid container className={styles.container} spacing={2} alignItems="center">
                            <Grid item xs={3} className={styles.panelContent__label}>
                                {tr(TEXT_MANUAL_VALUE)}:
                            </Grid>

                            <Grid item xs={3} className={styles.p2}>
                                {formatCurrency(prop.otherPropertyFlags?.manualValue)} {prop.otherPropertyFlags?.manualValueDocument?.url && <IconButton onClick={() => dispatch(actionSetPreviewPdf(undefined, undefined, prop.otherPropertyFlags.manualValueDocument.url))}><Info /></IconButton>}
                            </Grid>
                        </Grid>
                    )}
                    {!editing && prop.otherPropertyFlags?.manualKoValue && (
                        <Grid container className={styles.container} spacing={2} alignItems="center">
                            <Grid item xs={3} className={styles.panelContent__label}>
                                {tr(TEXT_COMBINED)}:
                            </Grid>

                            <Grid item xs={3} className={styles.p2}>
                                {formatCurrency(prop.otherPropertyFlags?.manualKoValue)} {prop.otherPropertyFlags?.manualValueDocument?.url && <IconButton onClick={() => dispatch(actionSetPreviewPdf(undefined, undefined, prop.otherPropertyFlags.manualValueDocument.url))}><Info /></IconButton>}
                            </Grid>
                        </Grid>
                    )}
                    {!editing && prop.otherPropertyFlags?.manualValueComment && (
                        <Grid container className={styles.container} spacing={2} alignItems="center">
                            <Grid item xs={3} className={styles.panelContent__label}>
                                {tr(TEXT_NOTE)}:
                            </Grid>

                            <Grid item xs={3} className={styles.p2}>
                                {prop.otherPropertyFlags?.manualValueComment}
                            </Grid>
                        </Grid>
                    )}
                    {!editing && (
                        <Grid container className={styles.container} spacing={2} alignItems="center">
                            <Grid item xs={3} className={styles.panelContent__label}>
                                {tr(TEXT_RESIDENTIAL_UNITS)}:
                            </Grid>

                            <Grid item xs={3} className={styles.p2}>
                                {prop.otherPropertyFlags?.deductibleHousingUnitsCount ?? getResidentialHousingUnitCount(propertyData)}
                            </Grid>
                        </Grid>
                    )}
                    {editing && (
                        <Grid container>
                            <PropertyDetailsPanelForm
                                form={TaxFormName.PropertyTotalValuePanel}
                                onSubmit={handleFormSubmit}
                                initialValues={prop}
                            >
                                <Grid container className={styles.container} spacing={2} alignItems="center">
                                    <Grid item sm={3} xs={6} className={styles.panelContent__label}>
                                        {tr(TEXT_EXEMPTION)}:
                                    </Grid>
                                    <Grid item sm={3} xs={6}>
                                        <Field
                                            name={TaxFormFieldNames.exempt}
                                            component={CubitRadioGroupAdapter}
                                            options={getYesNoBoolOptions()}
                                            direction="row"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} />
                                    {getFieldValue(TaxFormFieldNames.exempt) ? (
                                        <>
                                            <Grid item sm={6} xs={6}>
                                                <Grid container className={styles.container} spacing={2}>
                                                    <Field
                                                        component={CubitSelectAdapter}
                                                        valueIsObject={false}
                                                        options={getExemptReasonOptions()}
                                                        name={TaxFormFieldNames.exemptReason}
                                                        label={tr(TEXT_EXEMPTION)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={6} xs={12} />
                                        </>
                                    ) : null}
                                    <Grid item sm={3} xs={12} className={styles.panelContent__label}>
                                        {tr(TEXT_USE_NATIONAL_SETTINGS)}:
                                    </Grid>
                                    <Grid item sm={3} xs={12} className={styles.adjustLeft}>
                                        <Field
                                            className={styles.alignRight}
                                            name={`${TaxFormFieldNames.otherPropertyFlags}.${TaxFormFieldNames.nationalValuation}`}
                                            component={CubitTextFieldAdapter}
                                            label={tr(TEXT_VALUE)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} />
                                </Grid>
                                <Grid container className={styles.container} spacing={2} alignItems="center">
                                    <Grid item sm={3} xs={12} className={styles.panelContent__label}>
                                        {tr(TEXT_MANUAL_VALUE)}:
                                    </Grid>

                                    <Grid item sm={3} xs={12}>
                                        <Grid container className={styles.container} spacing={2}>
                                            <Field
                                                name={`${TaxFormFieldNames.otherPropertyFlags}.${TaxFormFieldNames.manualValue}`}
                                                component={CubitTextFieldAdapter}
                                                label={tr(TEXT_VALUE)}
                                                className={styles.field}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} xs={12} />
                                    {isCombinedProperty(prop.valueType) && <>
                                        <Grid item xs={3} className={styles.panelContent__label}>
                                            {tr(TEXT_COMBINED)}:
                                        </Grid>

                                        <Grid item sm={3} xs={12}>
                                            <Grid container className={styles.container} spacing={2}>
                                                <Field
                                                    name={`${TaxFormFieldNames.otherPropertyFlags}.${TaxFormFieldNames.manualKoValue}`}
                                                    component={CubitTextFieldAdapter}
                                                    label={tr(TEXT_VALUE)}
                                                    className={styles.field}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} />
                                    </>}
                                    <Grid item sm={6} xs={12}>
                                        <Grid container className={styles.container} spacing={2}>
                                            <Field
                                                name={`${TaxFormFieldNames.otherPropertyFlags}.${TaxFormFieldNames.manualValueComment}`}
                                                component={CubitTextFieldAdapter}
                                                type="text"
                                                label={tr(TEXT_NOTE)}
                                                multiline
                                                className={styles.field}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} xs={12} />
                                    <Grid item xs={3} className={styles.panelContent__label}>
                                        {tr(TEXT_RESIDENTIAL_UNITS)}:
                                    </Grid>

                                    <Grid item sm={3} xs={12}>
                                        <Grid container className={styles.container} spacing={2}>
                                            <Field
                                                name={`${TaxFormFieldNames.otherPropertyFlags}.${TaxFormFieldNames.deductibleHousingUnitsCount}`}
                                                component={CubitTextFieldAdapter}
                                                label={tr(TEXT_RESIDENTIAL_UNITS)}
                                                type="number"
                                                className={styles.field}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} />
                                </Grid>
                            </PropertyDetailsPanelForm>
                        </Grid>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}
