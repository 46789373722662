import React from 'react'
import { makeStyles, Theme, Grid, Button } from '@material-ui/core'
import { Taxation, TaxationState } from 'models/taxation'
import { tr } from 'utils/translations/translate'
import { useDispatch, useSelector } from 'react-redux'
import { actionTaxationUpdate, actionTaxationUpdateAfterComplaint } from '../taxation-actions'
// @ts-ignore
import 'react-lightbox-component/build/css/index.css'
import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import {
    TEXT_COMPLAINT_FOLLOWED,
    TEXT_COMPLAINT_NOT_FOLLOWED,
    TEXT_COMPLAINT_PARTIALLY_FOLLOWED,
    TEXT_SEND_TO_NEMND,
} from 'utils/translations/keys'
import {
    Complaint,
    complaintCausessProccessed,
    isReadyForTribunal,
} from 'tribunal-page/models/complaint.type'
import { Building } from 'models/building'
import { ComplaintFloor, Floor } from 'models/floor'
import { Plot } from 'models/plot'
import { actionComplaintUpdate } from 'complaint-page/complaint-actions'
import { AppState } from 'app/app-store'
import { ComplaintStatus } from 'tribunal-page/enums/complaint-status.enum'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            fontSize: theme.spacing(2),
        },
        buttonsList: {
            //margin: '-24px',
            padding: theme.spacing(0, 0, 3, 0),
        },
        complaintButton: {
            padding: theme.spacing(1, 2),
            minWidth: 200,
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 1.25,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginRight: 0,
            },
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            transition: 'border-color 0.2s ease-in',
            height: 44,
            '&:hover': {
                borderWidth: 2,
                borderColor: theme.palette.primary.light,
            },
        },
        appraiserSelect: {
            '& .MuiSelect-select': {
                color: theme.palette.primary.main,
                padding: '13px 37px 13px 13px',
                minWidth: 200,
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: 1.25,
                textTransform: 'uppercase',
                textAlign: 'center',
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                },
            },
            '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
                transition: 'border-color 0.2s ease-in',
            },
            '& .MuiSelect-icon': {
                color: theme.palette.primary.main,
                marginRight: 10,
            },
            '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.light,
            },
        },
        buttonContainer: {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            '&:last-child': {
                marginRight: 0,
            },
        },
        panelBox: {
            padding: theme.spacing(3),
        },
    }
})

type ComplaintCauseButtonsProps = {
    cause: any
    complaint: Complaint
    taxation: Taxation
}

export const ComplaintCauseButtons: React.FC<ComplaintCauseButtonsProps> = (props) => {
    const { cause, complaint, taxation } = props
    const dispatch = useDispatch()
    const styles = useStyles()
    const taxationComplaints = useSelector((state: AppState) => state.complaints[taxation.id])
    const complaintReadyForTribunal = !taxationComplaints.some((complaint: Complaint) => !isReadyForTribunal(complaint))

    const getCauseActionButtons = () => {
        let actionButtons: any = []

        // Photos of a wrong building can create an event to start a new taxation
        if (cause.type === ComplaintCauseType.BuildingPhotos) {
            //open up the taxation for editing and set the cause as processed
            actionButtons.push(
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleWrongImagesComplaint()}
                    className={styles.complaintButton}
                >
                    {tr(TEXT_COMPLAINT_FOLLOWED)}
                </Button>,
            )
        }
        // standard changes get sent to tribunal that can either approve the change or sent out a new taxation
        if (cause.type === ComplaintCauseType.BuildingStandard || cause.type === ComplaintCauseType.PlotLocation) {
            actionButtons.push(
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCauseProccessed()}
                    className={styles.complaintButton}
                >
                    {tr(TEXT_SEND_TO_NEMND)}
                </Button>,
            )
        }
        // plot area and building area causes can be partially approved also
        if (cause.type === ComplaintCauseType.BuildingArea || cause.type === ComplaintCauseType.PlotArea) {
            //get building area required fields for partial acception
            const buildingAreaValuesAdded: boolean =
                cause.type === ComplaintCauseType.BuildingArea
                    ? cause.buildings.some((building: any) =>
                          building.floors.some((floor: Floor) => (floor as ComplaintFloor).adjustedArea),
                      )
                    : false
            const buildingCorrectUsageSelected: boolean =
                cause.type === ComplaintCauseType.BuildingArea
                    ? cause.buildings.some((building: any) =>
                          building.floors.some((floor: Floor) => {
                              const approved = (floor as ComplaintFloor).correctUsageApproved
                              const correct = (floor as ComplaintFloor).correctUsage
                              return approved && approved !== correct
                          }),
                      )
                    : false
            //get plotArea required fields for partial acception
            const plotAreaValuesAdded: boolean = cause.type === ComplaintCauseType.PlotArea && !!cause.adjustedPlotSize
            actionButtons.push(
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleComplaintPartialApproval()}
                    className={styles.complaintButton}
                    disabled={!(buildingAreaValuesAdded || buildingCorrectUsageSelected || plotAreaValuesAdded)}
                >
                    {tr(TEXT_COMPLAINT_PARTIALLY_FOLLOWED)}
                </Button>,
            )
            actionButtons.push(
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCauseApproval()}
                    className={styles.complaintButton}
                >
                    {tr(TEXT_COMPLAINT_FOLLOWED)}
                </Button>,
            )
        }
        // rest of the causes sets the comment only
        if (cause.type === ComplaintCauseType.Other || cause.type === ComplaintCauseType.PlotType) {
            actionButtons.push(
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCauseApproval()}
                    className={styles.complaintButton}
                >
                    {tr(TEXT_COMPLAINT_FOLLOWED)}
                </Button>,
            )
        }
        //every cause have a decline button
        actionButtons.push(
            <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCauseRejected()}
                className={styles.complaintButton}
            >
                {tr(TEXT_COMPLAINT_NOT_FOLLOWED)}
            </Button>,
        )

        return actionButtons
    }
    const causeApprovalActions = () => {
        let updated = false
        if (cause.type === ComplaintCauseType.PlotArea) {
            //set first plot area with the new value and others to zero so backend would use the updated value
            const plots = taxation.current.property.plots
            plots.forEach((plot: Plot, index: number) => {
                if (index === 0) {
                    plot.area = cause.correctPlotSize
                } else {
                    plot.area = 0
                }
            })
            //save the old value for preview purposes
            cause.originalPlotSize = cause.correctPlotSize
            updated = true
        }
        if (cause.type === ComplaintCauseType.PlotType) {
            const property = taxation.current.property
            property.valueType = cause.correctValueType ? cause.correctValueType : property.valueType
            updated = true
        }
        if (cause.type === ComplaintCauseType.BuildingArea) {
            const causeBuildings: Building[] = cause.buildings
            causeBuildings.forEach((building: Building) => {
                const propertyBuilding: Building = taxation.current.buildings.find(
                    (b: Building) => b.id === building.id,
                ) as Building
                //swap floors area and usage with corrected values
                propertyBuilding.floors.forEach((floor: Floor, index: number) => {
                    const complaintFloor: ComplaintFloor = building.floors[index] as ComplaintFloor
                    floor.usage = complaintFloor.correctUsage ? complaintFloor.correctUsage : floor.usage
                    floor.area = complaintFloor.correctArea ? complaintFloor.correctArea : floor.area
                })
            })
            updated = true
        }
        //save the changes and check if all complaints are done now
        if (updated) {
            updateTaxationState()
        }
    }
    const causePartialApprovalActions = () => {
        let updated = false
        if (cause.type === ComplaintCauseType.PlotArea) {
            if (cause.adjustedPlotSize) {
                //set the accepted plot area to the first plot and others to zero
                const plots = taxation.current.property.plots
                plots.forEach((plot: Plot, index: number) => {
                    if (index === 0) {
                        plot.area = cause.adjustedPlotSize
                    } else {
                        plot.area = 0
                    }
                })
            }
            updated = true
        }
        if (cause.type === ComplaintCauseType.BuildingArea) {
            const valuesAdded: boolean = cause.buildings.some((building: Building) =>
                building.floors.some(
                    (floor: Floor) => (floor as ComplaintFloor).adjustedArea || (floor as ComplaintFloor).correctUsage,
                ),
            )
            if (valuesAdded) {
                const causeBuildings: Building[] = cause.buildings
                causeBuildings.forEach((building: Building) => {
                    const propertyBuilding: Building = taxation.current.buildings.find(
                        (b: Building) => b.id === building.id,
                    ) as Building
                    //swap floors area and usage with input values if available or corrected values if not
                    propertyBuilding.floors.forEach((floor: Floor, index: number) => {
                        const complaintFloor: ComplaintFloor = building.floors[index] as ComplaintFloor
                        // check if usage is approved
                        floor.usage = complaintFloor.correctUsageApproved
                            ? complaintFloor.correctUsageApproved
                            : complaintFloor.correctUsage
                            ? complaintFloor.correctUsage
                            : floor.usage
                        // check if adjusted values are input
                        if (complaintFloor.adjustedArea) {
                            floor.area = complaintFloor.adjustedArea
                        }
                    })
                })
            }
            updated = true
        }
        if (updated) {
            updateTaxationState()
        }
    }
    const setCauseStatus = (state: ComplaintStatus) => {
        cause.state = state
        //check if all the causes are processed
        if (isReadyForTribunal(complaint)) {
            complaint.status = ComplaintStatus.ReadyForTribunal
        }
        if (complaintCausessProccessed(complaint)) {
            complaint.status = ComplaintStatus.Processed
        }
        dispatch(actionComplaintUpdate(complaint))
    }
    const updateTaxationState = () => {
        if (complaintReadyForTribunal) {
            taxation.state = TaxationState.TribunalReadyForFinishing
        }
        // log the changes to buildings and/or property to history as well
        dispatch(actionTaxationUpdateAfterComplaint(taxation))
    }
    const handleCauseApproval = () => {
        setCauseStatus(ComplaintStatus.Accepted)
        causeApprovalActions()
    }
    const handleCauseProccessed = () => {
        setCauseStatus(ComplaintStatus.ReadyForTribunal)
    }
    const handleCauseRejected = () => {
        setCauseStatus(ComplaintStatus.Declined)
    }
    const handleComplaintPartialApproval = () => {
        setCauseStatus(ComplaintStatus.PartiallyAccepted)
        causePartialApprovalActions()
    }

    const handleWrongImagesComplaint = () => {
        setCauseStatus(ComplaintStatus.Accepted)
        taxation.state = TaxationState.TaxationStarted
        dispatch(actionTaxationUpdate(taxation))
    }

    return (
        <Grid container spacing={2} className={styles.panelBox} justifyContent="flex-end">
            {!cause.state &&
                getCauseActionButtons().map((button: any, index: number) => (
                    <span key={index} className={styles.buttonContainer}>
                        {button}
                    </span>
                ))}
        </Grid>
    )
}
