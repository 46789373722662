import { Coordinate } from 'ol/coordinate'
import { fromLonLat } from 'ol/proj'
import { register } from 'ol/proj/proj4'
import proj4 from 'proj4'

export const SEARCH_MAP_PROJECTION = 'EPSG:3857'
export const HOUSING_UNIT_PROJECTION = 'EPSG:6172'

export const toSearchMapProjection = (coordinates: Coordinate) => fromLonLat(coordinates, SEARCH_MAP_PROJECTION)

export const registerProjections = () => {
    proj4.defs(
        HOUSING_UNIT_PROJECTION,
        '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs',
    )
    register(proj4)
}
