import React from 'react'
import {
    Grid,
    Typography,
    Modal,
    Theme,
    makeStyles,
} from '@material-ui/core'
import { TaxFormName } from 'common/enums/form-name.enum'
import { useDispatch } from 'react-redux'
import { CaseFlowEditForm } from './case-flow-edit-form'
import { actionUpdateCaseFlowEntries } from 'settings-page/settings-actions'

const useStyles = makeStyles((theme: Theme) => {
    return {
        modal: {
            top: theme.spacing(20),
            width: '100%',
            maxWidth: 627,
            margin: '0 auto',
            left: 0,
            right: 0,
            position: 'fixed',
            padding: theme.spacing(6, 3, 3, 6),
            backgroundColor: '#ffffff',
            outline: 'none',
        },
        title: {
            fontWeight: 'bold',
            fontSize: 24,
            lineHeight: '0.83',
            color: theme.palette.primary.main,
            marginBottom: 20
        }
    }
})
export const CaseFlowEditModal = ({entry, close}: any) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const formName = `${TaxFormName.CaseFlowEdit}-${entry.id}`

    const handleClose = () => {
        close()
    }
    const handleSave = (values: any) => {
        dispatch(actionUpdateCaseFlowEntries(values))
        close()
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="case-flow-edit"
            aria-describedby="case-flow-edit"
        >
            <Grid container className={classes.modal}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>{entry.name}</Typography>
                </Grid>
                <CaseFlowEditForm initialValues={entry} onSubmit={handleSave} entry={entry} form={formName} handleClose={handleClose} />

            </Grid>
        </Modal>
    )
}
