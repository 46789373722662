import React from 'react'
import { FieldArray } from 'redux-form'
import { TribunalTaxationCommentsFieldArray } from './tribunal-taxation-comments-field-array'

export const TribunalTaxationComments: React.FC<{
    formName: string
    fieldName: string
    commentsFieldName: string
}> = props => {
    const { formName, fieldName, commentsFieldName } = props

    const fieldArrayName = `${fieldName}.taxInfo.${commentsFieldName}`

    return <FieldArray name={fieldArrayName} component={TribunalTaxationCommentsFieldArray} form={formName} />
}
