import React, { useState } from 'react'
import { Grid, IconButton, Button, Divider } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { formValueSelector } from 'redux-form'
import { Create } from '@material-ui/icons'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { ThreeColumnsRow } from './three-columns-row'
import { TaxSettingsRow } from 'settings-tax-page/TaxSettingsRow'

export const FromToRow = ({
    fields,
    meta: { touched, error, submitFailed },
    classes,
    stateRef,
    rowLabels = {},
    removable = true,
    hideDivider = false,
    readOnly,
    canCancel,
}: any) => {
    const selector = formValueSelector(TaxFormName.TaxationSettings)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const [editing, setEditing] = useState(false)
    const resetEdit = useSelector((state: AppState) => state.taxSettings.taxFormReset)
    if (resetEdit && editing) {
        setEditing(false)
    }
    return (
        <Grid container className={classes.settingsRow}>
            {!editing && !readOnly && (
                <Grid item className={classes.editButton}>
                    <IconButton
                        onClick={() => {
                            setEditing(true)
                            canCancel(true)
                        }}
                    >
                        <Create />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs={12}>
                <TaxSettingsRow classes={classes}>
                    <ThreeColumnsRow
                        editMode={editing}
                        classes={classes}
                        content={{
                            label: rowLabels.label,
                            value1: tr(t.TEXT_FROM),
                            value2: tr(t.TEXT_TO),
                        }}
                        isHeader={true}
                    />
                </TaxSettingsRow>
                {fields.map((fieldName: string, index: number) => (
                    <TaxSettingsRow classes={classes} key={index}>
                        <ThreeColumnsRow
                            editMode={editing}
                            classes={classes}
                            content={{
                                label: getFieldValue(`${fieldName}.${TaxFormFieldNames.name}`),
                                value1: getFieldValue(`${fieldName}.${TaxFormFieldNames.from}`),
                                value2: getFieldValue(`${fieldName}.${TaxFormFieldNames.to}`),
                            }}
                            input={{
                                label: `${fieldName}.${TaxFormFieldNames.name}`,
                                value1: `${fieldName}.${TaxFormFieldNames.from}`,
                                value2: `${fieldName}.${TaxFormFieldNames.to}`,
                            }}
                            fields={fields}
                            index={index}
                            removable={removable}
                        />
                    </TaxSettingsRow>
                ))}
                {removable && editing && (
                    <Grid container>
                        <Button variant="outlined" onClick={() => fields.push()} className={classes.button}>
                            {tr(t.TEXT_ADD_FACTOR)}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {!hideDivider && <Divider className={classes.innerDivider} />}
                </Grid>
            </Grid>
        </Grid>
    )
}
