export enum TaxFormName {
    PropertyEditPanel = 'taxpropertyedit',
    PropertyValuePanel = 'property-value-panel-form',
    PropertyTotalValuePanel = 'property-total-value-panel-form',
    YearSettings = 'report-settings',
    TaxationSettings = 'taxation-settings',
    TemplateForm = 'template-form',
    PropertyBuildingEditPanel = 'property-building-edit',
    TemplateDocumentForm = 'template-document-form',
    CaseTemplateForm = 'case-template-form',
    PropertyOwners = 'property-owners-form',
    CaseFlowEdit = 'case-flow-edit',
    CommunicationForm = 'communication-form',
}

export enum TaxFormFieldNames {
    zone = 'zone',
    valueType = 'valueType',
    // report settings
    yearSettings = 'settings',
    startYear = 'startYear',
    year = 'year',
    municipalityAdjustment = 'municipalityAdjustment',
    municipalityAdjustmentValue = 'municipalityAdjustmentValue',
    nationalValuation = 'nationalValuation',
    minimumTax = 'minimumTax',
    propertyType = 'propertyType',
    propertyTypeZones = 'propertyTypeZones',
    differentRateForDwelling = 'differentRateForDwelling',
    differentRateWithoutBuildings = 'differentRateWithoutBuildings',
    differentRateForLand = 'differentRateForLand',
    differentRateForZones = 'differentRateForZones',
    exemptZones = 'exemptZones',
    zoneRates = 'zoneRates',
    defaultTaxRate = 'defaultTaxRate',
    dwellingTaxRate = 'dwellingTaxRate',
    rateWithBuildings = 'rateWithBuildings',
    rateWithoutBuildings = 'rateWithoutBuildings',
    buildingsRate = 'buildingsRate',
    landRate = 'landRate',
    reductionFactor = 'reductionFactor',
    useFlatReduction = 'useFlatReduction',
    flatReductionValue = 'flatReductionValue',
    exemptionGovernmentBeneficial = 'exemptionGovernmentBeneficial',
    exemptionHistoricValue = 'exemptionHistoricValue',
    exemptionNewBuildings = 'exemptionNewBuildings',
    exemptionNewBuildingsValue = 'exemptionNewBuildingsValue',
    exemptionZones = 'exemptionZones',
    exemptionSummerHouses = 'exemptionSummerHouses',
    minBuildingArea = 'minBuildingArea',
    livingSpacePlot = 'livingSpacePlot',
    maxHousingSize = 'maxHousingSize',
    attachmentPlotValue = 'attachmentPlotValue',
    pointlessSize = 'pointlessSize',
    unknownPlotSize = 'unknownPlotSize',
    valuePerParkingSpace = 'valuePerParkingSpace',
    numberOfTerms = 'numberOfTerms',
    //taxation settings
    zoneFactors = 'zoneFactors',
    zoneLabels = 'zoneLabels',
    locationFactors = 'locationFactors',
    locationFactorsFromTo = 'locationFactorsFromTo',
    standardFactorsFromTo = 'standardFactorsFromTo',
    locationModifiers = 'locationModifiers',
    propertyModifiers = 'propertyModifiers',
    otherFactors = 'otherFactors',
    standardFactors = 'standardFactors',
    otherFactors2 = 'otherFactors2',
    buildingUse = 'buildingUsages',
    floorFactors = 'floorFactors',
    name = 'name',
    factor = 'factor',
    choice1 = 'choice1',
    choice2 = 'choice2',
    choice3 = 'choice3',
    choice4 = 'choice4',
    choice5 = 'choice5',
    price = 'price',
    pricePerM2 = 'pricePerM2',
    iIgnoreFloorFactor = 'ignoreFloorFactor',
    zone1 = 'zone1',
    zone2 = 'zone2',
    zone3 = 'zone3',
    zone4 = 'zone4',
    zone5 = 'zone5',
    useAreaFactors = 'useAreaFactors',
    useAreaFactorsPrice = 'useAreaFactorsPrice',
    areaFactors = 'areaFactors',
    from = 'from',
    to = 'to',
    settingsEffectDate = 'settingsEffectDate',
    settingsChangeComment = 'settingsChangeComment',
    buildingUsageByCode = 'buildingUsageByCode',
    buildingCodes = 'buildingCodes',
    //new taxation field names
    otherPropertyFlags = 'otherPropertyFlags',
    governmentBeneficial = 'governmentBeneficial',
    historicValue = 'historicValue',
    hasParking = 'hasParking',
    parkingSpaces = 'parkingSpaces',
    powerProduction = 'powerProduction',
    manualValue = 'manualValue',
    manualKoValue = 'manualKoValue',
    manualValueComment = 'manualValueComment',
    manualValueDocument = 'manualValueDocument',
    deductibleHousingUnitsCount = 'deductibleHousingUnitsCount',
    //templates fields
    title = 'title',
    body = 'body',
    type = 'type',
    pageBreak = 'pageBreak',
    templateId = 'templateId',
    height = 'height',
    elements = 'elements',
    footerHeight = 'footerHeight',
    footerElements = 'footerElements',
    textIds = 'textIds',
    docTemplate = 'docTemplate',
    left = 'left',
    top = 'top',
    width = 'width',
    //property details
    exempt = 'exempt',
    exemptReason = 'exemptReason',
    floorNumber = 'floorNumber',
    usage = 'usage',
    usageFactor = 'usageFactor',
    area = 'area',
    ownerships = 'ownerShips',
    isDocumentRecipient = 'isDocumentRecipient',
    isInvoiceRecipient = 'isInvoiceRecipient',
    //complaints
    adjustedPlotSize = 'adjustedPlotSize',
    correctUsageApproved = 'correctUsageApproved',
    adjustedArea = 'adjustedArea',
    //case flow edit
    caseFlowEntries = 'caseFlowEntries',
    noOfDays = 'noOfDays',
    notice = 'notice',
    channels = 'channels',
    sms = 'sms',
    email = 'email',
    letters = 'letters',
    caseDocuments = 'caseDocuments',
    letterId = 'letterId',
    editorState = 'editorState',
    phone = 'phone',
    subject = 'subject',
    attachments = 'attachments',
    incoming = 'incoming',
    recipients = 'recipients',
    copyRecipients = 'copyRecipients',
}

export enum DocumentConstants {
    moved = 'moved',
    added = 'added',
    text = 'text',
    image = 'image',
    defaultId = '000000000000000000000000',
    left = 'left',
    top = 'top',
    footercanvas = 'footercanvas',
    documentcanvas = 'documentcanvas',
    new = 'new',
}
