import { getOwnerDisplayName, Owner } from 'models/owner'
import { User } from 'models/user'
import { TEXT_FORWARDED, TEXT_REPLY } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

export const getNewCommunicationData = (entry: any, user: User | undefined, type: string | undefined) => {
    const sender = {
        name: user?.name,
        address: user?.email,
    }
    switch (type) {
        case 'sender':
            //reply
            return {
                recipients: [entry.sender],
                copyRecipients: [],
                subject: tr(TEXT_REPLY) + ': ' + entry.subject,
                attachments: [],
                type: entry.type,
                body: entry.body,
                textBody: entry.textBody,
                timeStamp: entry.timeStamp,
                sender: sender,
            }
        case 'all':
            //reply all
            return {
                recipients: [entry.sender],
                copyRecipients: [...entry.recipients.filter((r: any) => r.type !== 'tenant'), ...entry.copyRecipients],
                subject: tr(TEXT_REPLY) + ': ' + entry.subject,
                attachments: [],
                type: entry.type,
                body: entry.body,
                textBody: entry.textBody,
                timeStamp: entry.timeStamp,
                sender: sender,
            }
        case 'new':
            return {
                recipients: entry.recipients,
                copyRecipients: [],
                attachments: [],
                type: entry.type,
                sender: sender,
                incoming: false,
            }
        default:
            //forward
            return {
                recipients: [],
                copyRecipients: [],
                subject: tr(TEXT_FORWARDED) + ': ' + entry.subject,
                attachments: [...entry.attachments],
                type: entry.type,
                body: entry.body,
                textBody: entry.textBody,
                timeStamp: entry.timeStamp,
                sender: sender,
            }
    }
}

export const getOwnerContacts = (owners: Owner[], comType: string) => {
    switch (comType) {
        case 'email':
            return owners
                .filter((o: Owner) => o.email)
                .map((owner: Owner) => {
                    return {
                        name: owner.name,
                        address: owner.email,
                    }
                })
        case 'sms':
            return owners
                .filter((o: Owner) => o.phone)
                .map((owner: Owner) => {
                    return {
                        name: owner.name,
                        address: owner.phone,
                    }
                })
        case 'letter':
            const owner = owners.filter((o: Owner) => o.postalAddress.addressText)[0] || undefined
            if (owner) {
                return [
                    {
                        name: getOwnerDisplayName(owner),
                        address: `${owner.postalAddress.addressText}, ${owner.postalAddress.postalCode}`,
                        postalAddress: owner.postalAddress,
                    }
                ]
            }
            return []
        case 'phone':
            const recipients = owners
                .filter((o: Owner) => o.phone)
                .map((owner: Owner) => {
                    return {
                        name: owner.name,
                    }
                })
            return recipients.length ? recipients : [{ name: '' }]
        default:
            return []
    }
}

export const senderAsString = (entry: any) => {
    if (entry.type === 'phone' && entry.recipients && entry.recipients.length) {
        return entry.recipients[0].name
    }
    if (!entry.sender) return ''
    if (entry.sender.name) return entry.sender.name
    return entry.sender.address
}

export const anyRecipientContainsWord = (recipients: any, word: string) => {
    for (const recipient of recipients) {
        if (recipient.name && recipient.name.toLowerCase().includes(word)) return true
        if (recipient.address && recipient.address.toLowerCase().includes(word)) return true
    }
    return false
}

export const filterCommunication = (entries: any, filterText: string) => {
    if (!entries) return []
    const filterWords = filterText.toLowerCase().split(' ')
    return entries.filter((e: any) => {
        for (const word of filterWords) {
            if (e.userName && e.userName.toLowerCase().includes(word)) continue
            if (e.textBody && e.textBody.toLowerCase().includes(word)) continue
            if (e.subject && e.subject.toLowerCase().includes(word)) continue
            if (e.sender && e.sender.name && e.sender.name.toLowerCase().includes(word)) continue
            if (e.sender && e.sender.address && e.sender.address.toLowerCase().includes(word)) continue
            if (anyRecipientContainsWord(e.recipients, word)) continue
            return false
        }
        return true
    })
}