import { AnyAction } from 'redux'
import { User } from 'models/user'
import { WelcomeUser } from 'models/welcome-user'
import { Company } from 'models/company'

export const ACTION_SHOW_ERROR = 'ACTION_SHOW_ERROR'
export const ACTION_GO_BACK = 'ACTION_GO_BACK'
export const ACTION_LOAD_APP_DATA = 'ACTION_LOAD_APP_DATA'
export const ACTION_LOAD_APP_DATA_SUCCESS = 'ACTION_LOAD_APP_DATA_SUCCESS'

export const ACTION_LOAD_COMPANIES_AND_USERS = 'ACTION_LOAD_COMPANIES_AND_USERS'
export const ACTION_LOAD_COMPANIES_AND_USERS_SUCCESS = 'ACTION_LOAD_COMPANIES_AND_USERS_SUCCESS'

export const ACTION_LOAD_ROLES = 'ACTION_LOAD_ROLES'
export const ACTION_LOAD_ROLES_SUCCESS = 'ACTION_LOAD_ROLES_SUCCESS'

export const ACTION_CREATE_USER = 'ACTION_CREATE_USER'
export const ACTION_CREATE_USER_SUCCESS = 'ACTION_CREATE_USER_SUCCESS'

export const ACTION_UPDATE_USER = 'ACTION_UPDATE_USER'
export const ACTION_UPDATE_USER_SUCCESS = 'ACTION_UPDATE_USER_SUCCESS'

export const ACTION_CREATE_COMPANY = 'ACTION_CREATE_COMPANY'
export const ACTION_CREATE_COMPANY_SUCCESS = 'ACTION_CREATE_COMPANY_SUCCESS'

export const ACTION_UPDATE_COMPANY = 'ACTION_UPDATE_COMPANY'
export const ACTION_UPDATE_COMPANY_SUCCESS = 'ACTION_UPDATE_COMPANY_SUCCESS'

export const ACTION_GET_WELCOME_USER = 'ACTION_GET_WELCOME_USER'
export const ACTION_GET_WELCOME_USER_SUCCESS = 'ACTION_GET_WELCOME_USER_SUCCESS'

export const ACTION_WELCOME_USER_REGISTER = 'ACTION_WELCOME_USER_REGISTER'
export const ACTION_WELCOME_USER_REGISTER_SUCCESS = 'ACTION_WELCOME_USER_REGISTER_SUCCESS'

export const ACTION_SET_PREVIEW_PDF = 'ACTION_SET_PREVIEW_PDF'

export const ACTION_LOAD_TENANTS = 'ACTION_LOAD_TENANTS'
export const ACTION_LOAD_TENANTS_SUCCESS = 'ACTION_LOAD_TENANTS_SUCCESS'
export const ACTION_SWITCH_TENANT = 'ACTION_SWITCH_TENANT'
export const ACTION_SWITCH_TENANT_SUCCESS = 'ACTION_SWITCH_TENANT_SUCCESS'

export const actionShowError = (message?: string): AnyAction => ({
    type: ACTION_SHOW_ERROR,
    message,
})

export const actionGoBack = (): AnyAction => ({
    type: ACTION_GO_BACK,
})

export const actionLoadAppData = (): AnyAction => ({
    type: ACTION_LOAD_APP_DATA,
})

export const actionLoadAppDataSuccess = (data: any): AnyAction => ({
    type: ACTION_LOAD_APP_DATA_SUCCESS,
    data,
})

export const actionLoadCompaniesAndUsers = (): AnyAction => ({
    type: ACTION_LOAD_COMPANIES_AND_USERS,
})

export const actionLoadCompaniesAndUsersSuccess = (data: any): AnyAction => ({
    type: ACTION_LOAD_COMPANIES_AND_USERS_SUCCESS,
    data,
})

export const actionLoadRoles = (): AnyAction => ({
    type: ACTION_LOAD_ROLES,
})

export const actionLoadRolesSuccess = (roles: string[]): AnyAction => ({
    type: ACTION_LOAD_ROLES_SUCCESS,
    roles,
})

export const actionCreateUser = (user: User): AnyAction => ({
    type: ACTION_CREATE_USER,
    user,
})

export const actionCreateUserSuccess = (user: User): AnyAction => ({
    type: ACTION_CREATE_USER_SUCCESS,
    user,
})

export const actionUpdateUser = (user: User): AnyAction => ({
    type: ACTION_UPDATE_USER,
    user,
})

export const actionUpdateUserSuccess = (user: User): AnyAction => ({
    type: ACTION_UPDATE_USER_SUCCESS,
    user,
})
export const actionCreateCompany = (company: Company): AnyAction => ({
    type: ACTION_CREATE_COMPANY,
    company,
})

export const actionCreateCompanySuccess = (company: Company): AnyAction => ({
    type: ACTION_CREATE_COMPANY_SUCCESS,
    company,
})

export const actionUpdateCompany = (company: Company): AnyAction => ({
    type: ACTION_UPDATE_COMPANY,
    company,
})

export const actionUpdateCompanySuccess = (company: Company): AnyAction => ({
    type: ACTION_UPDATE_COMPANY_SUCCESS,
    company,
})

export const actionGetWelcomeUser = (newUserKey: string): AnyAction => ({
    type: ACTION_GET_WELCOME_USER,
    newUserKey,
})

export const actionGetWelcomeUserSuccess = (welcomeUser: WelcomeUser): AnyAction => ({
    type: ACTION_GET_WELCOME_USER_SUCCESS,
    welcomeUser,
})

export const actionWelcomeUserRegister = (email: string, password: string, newUserKey: string): AnyAction => ({
    type: ACTION_WELCOME_USER_REGISTER,
    email,
    password,
    newUserKey,
})

export const actionWelcomeUserRegisterSuccess = (): AnyAction => ({
    type: ACTION_WELCOME_USER_REGISTER_SUCCESS,
})

export const actionSetPreviewPdf = (fileId: string | undefined, fileName: string | undefined, url: string | undefined = undefined): AnyAction => ({
    type: ACTION_SET_PREVIEW_PDF,
    fileId,
    fileName,
    url
})

export const actionLoadTenants = (): AnyAction => ({
    type: ACTION_LOAD_TENANTS,
})

export const actionLoadTenantsSuccess = (tenants: any[]): AnyAction => ({
    type: ACTION_LOAD_TENANTS_SUCCESS,
    tenants,
})

export const actionSwitchTenant = (tenant: any): AnyAction => ({
    type: ACTION_SWITCH_TENANT,
    tenant
})
