import * as keys from '../keys'

export const en: { [index: string]: string } = {}

en[keys.TEXT_ENABLE] = 'Enable'
en[keys.TEXT_FILTERS] = 'Filters'
en[keys.TEXT_SEARCH] = 'Search'
en[keys.TEXT_NO_RESULTS] = 'No results'

// en[keys.TEXT_SAVE] = 'Save'
// en[keys.TEXT_DASHBOARD] = 'Dashboard'
// en[keys.TEXT_LANGUAGE] = 'Language'
// en[keys.TEXT_OBJECT_TYPE] = 'Object type'
// en[keys.TEXT_HOUSE] = 'House'
// en[keys.TEXT_HOLIDAY_HOME] = 'Holiday home'
// en[keys.TEXT_COMMERCIAL] = 'Commercial'

// en[keys.TEXT_ZONE] = 'Zone'
// en[keys.TEXT_ZONE1] = 'Zone 1'
// en[keys.TEXT_ZONE2] = 'Zone 2'
// en[keys.TEXT_ZONE3] = 'Zone 3'
// en[keys.TEXT_ZONE4] = 'Zone 4'
// en[keys.TEXT_ZONE5] = 'Zone 5'

// en[keys.TEXT_ALL] = 'All'
// en[keys.TEXT_MINE] = 'Mine'

// en[keys.TEXT_STATUS] = 'Status'
// en[keys.TEXT_NO_STATUS] = 'No status'
// en[keys.TEXT_PICKED] = 'Picked'
// en[keys.TEXT_AWARDED] = 'Awarded'
// en[keys.TEXT_ACTIVE_CASE] = 'Active case'

// en[keys.TEXT_EVALUATION] = 'Evaluation'
// en[keys.TEXT_EVALUATION_SOURCE] = 'Evaluation source'
// en[keys.TEXT_TAX_ADMINISTRATION] = 'Tax administration'

// en[keys.TEXT_PROPERTY_VALUE] = 'Property value'

// en[keys.TEXT_TAX_SUM] = 'Tax sum'

// en[keys.TEXT_MASS_REGISTRATION] = 'Mass registration'

// en[keys.TEXT_EXEMPTION] = 'Exemption'

// en[keys.TEXT_OWNER_CATEGORY] = 'Owner category'
// en[keys.TEXT_MUNICIPALITY] = 'Municipality'
// en[keys.TEXT_PUBLIC] = 'Public'
// en[keys.TEXT_PRIVATE] = 'Private'
// en[keys.TEXT_DEAD_OWNERS] = 'Dead owners'

// en[keys.TEXT_STANDARD] = 'Standard'
// en[keys.TEXT_LOW] = 'Low'
// en[keys.TEXT_NORMAL] = 'Normal'
// en[keys.TEXT_HIGH] = 'High'

// en[keys.TEXT_LOCATION] = 'Location'

// en[keys.TEXT_PROPERTY] = 'Property'
// en[keys.TEXT_PROPERTIES] = 'Properties'

// en[keys.TEXT_OWNERS] = 'Owners'

// en[keys.TEXT_FROM] = 'From'
// en[keys.TEXT_TO] = 'To'
// en[keys.TEXT_ASSIGNED] = 'Assigned'
// en[keys.TEXT_EVALUATION_DATE] = 'Evaluation date'

// en[keys.TEXT_DATE_FROM] = 'Date from'
// en[keys.TEXT_DATE_TO] = 'Date to'
// en[keys.TEXT_SETTINGS] = 'Settings'
// en[keys.TEXT_CLEAR_ALL_FILTERS] = 'Clear all filters'

// en[keys.TEXT_NAME] = 'Name'
// en[keys.TEXT_E_POST] = 'E-post'
// en[keys.TEXT_ADDRESS] = 'Address'
// en[keys.TEXT_NUMBER] = 'Number'
// en[keys.TEXT_PHONE_NUMBER] = 'Phone number'
// en[keys.TEXT_TAX] = 'Tax'
// en[keys.TEXT_APPRAISERS] = 'Appraisers'
// en[keys.TEXT_USE_OF_LAND] = 'Use of land'

// en[keys.TEXT_REGISTER_NUMBERS] = 'Register numbers'
// en[keys.TEXT_SELECTED] = 'Selected'
// en[keys.TEXT_SCHEDULE_ASSESSMENT] = 'Schedule assessment'

// en[keys.TEXT_0457] = 'Industry Codes'
// en[keys.TEXT_0777] = 'Address'
// en[keys.TEXT_0778] = 'The Construction / equipment types'
// en[keys.TEXT_0779] = 'Result Areas'
// en[keys.TEXT_0780] = 'The system / activity profiles'
// en[keys.TEXT_0781] = 'Building Codes'
// en[keys.TEXT_0782] = 'El-business status'
// en[keys.TEXT_0783] = 'El-business tasks'
