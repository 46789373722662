import { plantsUrl } from "services/httpService"
import { getSessionKey } from "utils"
import { attributeKeys } from "./attribute"

export const getSetUrl = (type: string, entityId: string) => {
    const sessionKey = getSessionKey()
    if (type === attributeKeys.BUILDING) return plantsUrl(`/building/setAttributes/${sessionKey}/${entityId}`)
    if (type === attributeKeys.HOUSING_UNIT) return plantsUrl(`/housingUnit/setAttributes/${sessionKey}/${entityId}`)
    throw new Error(`Not supported type: ${type}`)
}

export const getAttributesFiltersOptions = (attributes: string[]) => {
    return attributes.map((attribute: string) => {
        return {
            label: attribute,
            value: attribute,
        }
    })
}