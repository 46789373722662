import { PropertyValueType } from 'models/property'
import {
    TEXT_RESIDENTIAL_PROPERTY,
    TEXT_LEISURE_PROPERTY,
    TEXT_FARMING_FORESTRY_WITH_HOUSING,
    TEXT_FARM_USE_FORESTRY_WITH_LEISURE,
    TEXT_FARMS_OR_FORESTRY,
    TEXT_NOT_SELECTED,
    TEXT_COMBINED_BUILT_PLOT,
    TEXT_COMMERCIAL,
    TEXT_UNDEVELOPED_PLOT_HOUSING,
    TEXT_UNDEVELOPED_PLOT_LEISURE,
    TEXT_UNDEVELOPED_PLOT_INDUSTRY,
    TEXT_UNDEVELOPED_PLOT,
} from 'utils/translations/keys'

const propertyValueTypeTranslationKeysByType = {
    [PropertyValueType.BO]: TEXT_RESIDENTIAL_PROPERTY,
    [PropertyValueType.FR]: TEXT_LEISURE_PROPERTY,
    [PropertyValueType.GB]: TEXT_FARMING_FORESTRY_WITH_HOUSING,
    [PropertyValueType.GF]: TEXT_FARM_USE_FORESTRY_WITH_LEISURE,
    [PropertyValueType.GU]: TEXT_FARMS_OR_FORESTRY,
    [PropertyValueType.IV]: TEXT_NOT_SELECTED,
    [PropertyValueType.KO]: TEXT_COMBINED_BUILT_PLOT,
    [PropertyValueType.NE]: TEXT_COMMERCIAL,
    [PropertyValueType.UB]: TEXT_UNDEVELOPED_PLOT_HOUSING,
    [PropertyValueType.UF]: TEXT_UNDEVELOPED_PLOT_LEISURE,
    [PropertyValueType.UN]: TEXT_UNDEVELOPED_PLOT_INDUSTRY,
    [PropertyValueType.UT]: TEXT_UNDEVELOPED_PLOT,
}

export default (propertyValueType: PropertyValueType): string =>
    propertyValueTypeTranslationKeysByType[propertyValueType] || ''
