import { ACTION_NAVIGATION_TOGGLE, ACTION_MOBILE_NAVIGATION_TOGGLE, ACTION_PRINT_PAGE } from './navigation-actions'
import { AnyAction } from 'redux'
import { makeReducer } from 'utils'
import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'

export type NavigationState = {
    menuExpanded: boolean
    mobileMenuExpanded: boolean
    isPrint: boolean
}

type NavigationReducer = ActionReducer<NavigationState, AnyAction>

const initialState: NavigationState = {
    menuExpanded: false,
    mobileMenuExpanded: false,
    isPrint: false,
}

const toggleNavigation: NavigationReducer = (state, action) => ({
    ...state,
    menuExpanded: action.expanded,
})
const toggleMobileNavigation: NavigationReducer = (state, action) => ({
    ...state,
    mobileMenuExpanded: action.expanded,
})
const printPage: NavigationReducer = (state, action) => ({
    ...state,
    isPrint: action.isPrint,
})
const reducers: Reducers<NavigationState> = {
    [ACTION_NAVIGATION_TOGGLE]: toggleNavigation,
    [ACTION_MOBILE_NAVIGATION_TOGGLE]: toggleMobileNavigation,
    [ACTION_PRINT_PAGE]: printPage,
}

export const navigationReducer = makeReducer(reducers, initialState)
