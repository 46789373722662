import React, { useEffect } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { tr } from 'utils/translations/translate'
import { TEXT_ACCESS, TEXT_USERS, TEXT_USER_GROUPS } from 'utils/translations/keys'
import { useDispatch } from 'react-redux'
import { actionSettingsGroupsGet } from 'shared-components/src/settings/groups/groups-actions'
import { RouteComponentProps, navigate } from '@reach/router'
import TitleToolbar from 'common/title-toolbar/title-toolbar'
import { useCompanies } from 'app/app-selectors'
import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import { Card, Grid, Theme } from '@material-ui/core'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'

const useStyles = makeStyles((theme: Theme) => ({
    category: {
        padding: theme.spacing(1, 2, 1, 3),
        marginBottom: theme.spacing(1),
        boxShadow: 'none',
    },

    categoryName: {
        cursor: 'pointer',
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 'bold',
        maxWidth: '160px',

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    group: {
        cursor: 'pointer',
        color: grey[700],

        border: '1px solid' + grey[400],
        padding: theme.spacing(0.5, 1.5),
        margin: theme.spacing(0.5, 1),

        '&:hover': {
            color: grey[900],
            border: '1px solid' + grey[600],
        },
    },
    groupName: {
        paddingTop: '1px',
        fontSize: theme.typography.subtitle1.fontSize,
    },
}))

export const SettingsAccessPage: React.FC<RouteComponentProps> = () => {
    const styles = useStyles()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actionSettingsGroupsGet())
    }, [dispatch])

    const companies = useCompanies()

    const handleGroupClick = (linkTo: string) => navigate(linkTo)

    return (
        <LayoutDefault navigation={<Navigation />} toolbar={<TitleToolbar title={tr(TEXT_ACCESS)} />}>
            <Card className={styles.category}>
                <Grid container alignItems="center">
                    <Grid
                        item
                        xs={12}
                        className={styles.categoryName}
                        onClick={() => handleGroupClick('/settings/companies')}
                    >
                        {tr(TEXT_USER_GROUPS)}
                    </Grid>
                    {companies.map((c, index) => (
                        <Grid
                            item
                            key={index}
                            className={styles.group}
                            onClick={() => handleGroupClick(`/settings/companies/${c.id}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {c.name}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Card>

            <Protected p={Permission.AdministrateUsers}>
                <Card className={styles.category}>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={12}
                            className={styles.categoryName}
                            onClick={() => handleGroupClick('/settings/users')}
                        >
                            {tr(TEXT_USERS)}
                        </Grid>
                    </Grid>
                </Card>
            </Protected>
        </LayoutDefault>
    )
}
