import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core'
import { Taxation } from 'models/taxation'
import {
    getCauseTypeText,
} from 'property-taxation-edit-page/helper-functions'
import React from 'react'
import {
    TEXT_COMMENT, TEXT_STATUS,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { ComplaintHeader } from './complaint-header'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Field } from 'redux-form'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { Complaint } from 'tribunal-page/models/complaint.type'
import { ComplaintCauseButtons } from './complaint-cause-buttons'
import { getComplaintStatusText } from 'tribunal-page/enums/complaint-status.enum'

type ComplaintPanelProps = {
    styles: any
    cause: any
    taxation: Taxation
    complaint: Complaint
    index: number
    complaintIndex: number
}

export const ComplaintPanel: React.FC<ComplaintPanelProps> = (props) => {
    const { styles, cause, taxation, complaint, index, complaintIndex } = props
    return (
        <Accordion elevation={0} defaultExpanded={false} className={styles.Accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={styles.AccordionSummary}
            >
                <Grid container spacing={2}>
                    <Grid item className={styles.panelContent__label}>
                        {getCauseTypeText(cause.type)}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={styles.AccordionContent}>
                <ComplaintHeader styles={styles} taxation={taxation} />

                {props.children}

                <Grid container spacing={1} className={styles.panelBox}>
                    <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                        {tr(TEXT_STATUS)}:
                    </Grid>
                    <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                        {getComplaintStatusText(cause.state)}
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={styles.panelBox}>
                    <Grid item xs={12} className={styles.panelContent__label}>
                        {tr(TEXT_COMMENT)}:
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            component={CubitTextFieldAdapter}
                            name={`complaints.[${complaintIndex}].causes[${index}].administratorComment`}
                            multiline={true}
                            classes={{ root: styles.boxedText }}
                            type="text"
                            rows={5}
                            disabled={cause.state !== null}
                        />
                    </Grid>
                </Grid>

                {/* <Grid container spacing={2} className={styles.panelBox} justifyContent="flex-end">
                    {getActionButtons(cause).map((button: any, index: number) => (
                        <span key={index} className={styles.buttonContainer}>
                            {button}
                        </span>
                    ))}
                </Grid> */}
                <ComplaintCauseButtons cause={cause} complaint={complaint} taxation={taxation} />
            </AccordionDetails>
        </Accordion>
    )
}
