import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Divider, makeStyles, Theme, Table, TableBody } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_ARCHITECTURE,
    TEXT_EXEMPTION,
    TEXT_MAINTENANCE,
    TEXT_METRICS_STATUS,
    TEXT_NO,
    TEXT_NOTE,
    TEXT_OUTDOOR_AREA,
    TEXT_STANDARD_FACTOR,
    TEXT_UNITS,
    TEXT_FLOOR,
    TEXT_USE,
    TEXT_FLOOR_FACTOR,
    TEXT_M2_PRICE,
    TEXT_VALUE,
    TEXT_COMMENT_NOUN,
    TEXT_COMMENTS,
    TEXT_METRIC_CHANGES,
    TEXT_METRIC_CHANGE,
} from 'utils/translations/keys'
import { Building } from 'models/building'
import { useSelector } from 'react-redux'
// @ts-ignore
import Lightbox from 'react-lightbox-component'
import 'react-lightbox-component/build/css/index.css'
import { AppState } from 'app/app-store'
import {
    getStandardFactorTranslationKey,
    formatCurrency,
    formatArea,
    getTaxInfoTranslationKeyByValue,
    getTaxationBuildingMetricChangesCount,
} from 'utils'
import { CubitTableHeader } from 'shared-components/src/cubit-table/cubit-table-header'
import tableStyles from 'shared-components/src/cubit-table/cubit-table.module.css'
import { CubitTableHeaderProps } from 'shared-components/src/cubit-table/cubit-table.types'
import { Floor } from 'models/floor'
import { LightboxImage } from 'models/lightbox-image'
import { Diff } from 'common/diff/diff'
import { Taxation } from 'models/taxation'
import getTaxationBuildingNewCommentsCount from 'utils/taxation/get-taxation-building-new-comments-count'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { getFactor } from 'property-page/taxations-history/functions'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
            flexDirection: 'column',
        },
        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },

        panelContent__label: {
            fontWeight: 500,
        },

        tableWrapper: {
            margin: '0 -16px',
            width: 'calc(100% + 48px)',
            maxWidth: 'calc(100% + 48px)',
            flexBasis: 'auto',
        },

        button: {
            color: theme.palette.grey[400],
        },
        cancelButton: {
            marginRight: theme.spacing(2),
        },

        cameraIcon: {
            marginRight: theme.spacing(1),
        },

        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        boldeded: {
            fontSize: '1.125rem',
            fontWeight: 500,
        },
    }
})

export const TaxationDetailsBuilding: React.FC<{
    index: number
    building: Building
    taxation: Taxation
}> = props => {
    const { index, building, taxation } = props
    const styles = useStyles()
    const taxationSettings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
    const prev = taxation.old.buildings[index]
    const curr = taxation.current.buildings[index]

    const buildingCodesMap = useSelector((state: AppState) => state.appData.buildingCodesMap)

    const buildingImages: LightboxImage[] = building.files.map((file: any) => ({
        src: file.url,
        title: ' ',
        description: ' ',
    }))

    const tableHeaderProps: CubitTableHeaderProps = {
        columns: [
            {
                headerLabel: tr(TEXT_FLOOR),
                key: 'floorNumber',
            },
            {
                headerLabel: tr(TEXT_USE),
                key: 'usage',
            },
            {
                headerLabel: tr(TEXT_FLOOR_FACTOR),
                key: 'usageFactor',
            },
            {
                headerLabel: tr(TEXT_M2_PRICE),
                key: 'pricePerM2',
            },
            {
                headerLabel: 'BRA',
                key: 'area',
            },
            {
                headerLabel: tr(TEXT_VALUE),
                key: 'value',
                align: 'right',
            },
        ],
    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.panelSummary}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>{building.buildingNumber}</Grid>
                            <Grid item>{building.buildingCode}</Grid>
                            <Grid item>{buildingCodesMap[building.buildingCode]}</Grid>
                        </Grid>
                    </Grid>
                    {renderBuildingNewCommentsAndMetricChangesCount(taxation, index)}
                </Grid>
            </AccordionSummary>

            <AccordionDetails className={styles.panelContent}>
                <div style={{ flexGrow: 1 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} className={styles.boldeded}>
                            {tr(TEXT_EXEMPTION)}
                        </Grid>

                        <Grid item>{building.exempt ? building.exemptReason : tr(TEXT_NO)}</Grid>

                        {building.taxInfo && building.taxInfo.exemptionComment && (
                            <Grid item>
                                <Diff prop="taxInfo.exemptionComment" prev={prev} curr={curr}></Diff>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} className={styles.boldeded}>
                            {tr(TEXT_METRICS_STATUS)}
                        </Grid>

                        <Grid item>{building.status}</Grid>
                        {building.taxInfo && building.taxInfo.propertyStatusComment && (
                            <Grid item>
                                <Diff prop="taxInfo.propertyStatusComment" prev={prev} curr={curr}></Diff>
                            </Grid>
                        )}
                    </Grid>

                    <Divider style={{ margin: '24px -16px' }}></Divider>

                    <Grid container spacing={2} alignItems="center" className={styles.boldeded}>
                        <Grid item xs={3}>
                            {tr(TEXT_STANDARD_FACTOR)}
                        </Grid>
                        <Grid item xs>
                            <Diff
                                prop="standardFactor"
                                prev={prev}
                                curr={curr}
                                translationKeysFn={getStandardFactorTranslationKey}
                                factors={taxationSettings.standardFactors}
                            ></Diff>
                        </Grid>
                    </Grid>

                    {building.taxInfo && (
                        <>
                            {building.taxInfo.architecture && (
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3}>
                                        {tr(TEXT_ARCHITECTURE)}
                                    </Grid>
                                    <Grid item xs>
                                        {tr(getTaxInfoTranslationKeyByValue(building.taxInfo.architecture))}
                                    </Grid>
                                </Grid>
                            )}

                            {building.taxInfo.maintenance && (
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3}>
                                        {tr(TEXT_MAINTENANCE)}
                                    </Grid>
                                    <Grid item xs>
                                        {tr(getTaxInfoTranslationKeyByValue(building.taxInfo.maintenance))}
                                    </Grid>
                                </Grid>
                            )}
                            {building.taxInfo.outdoorArea && (
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3}>
                                        {tr(TEXT_OUTDOOR_AREA)}
                                    </Grid>
                                    <Grid item xs>
                                        {tr(getTaxInfoTranslationKeyByValue(building.taxInfo.outdoorArea))}
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}

                    <Divider style={{ margin: '24px -16px' }}></Divider>

                    <Grid container spacing={2} alignItems="center" className={styles.boldeded}>
                        <Grid item xs={3}>
                            {tr(TEXT_UNITS)}
                        </Grid>

                        <Grid item>{building.noOfHousingUnits}</Grid>

                        {building.taxInfo && building.taxInfo.unitsComment && (
                            <Grid item>
                                <Diff prop="taxInfo.unitsComment" prev={prev} curr={curr}></Diff>
                            </Grid>
                        )}
                    </Grid>

                    <Divider style={{ margin: '24px -16px' }}></Divider>

                    {building.floors && building.floors.length > 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={styles.tableWrapper}>
                                <Table className="tablePadding24">
                                    <CubitTableHeader {...tableHeaderProps} />
                                    <TableBody>
                                        {building.floors.filter(f => !f.matrikkelUnitId || f.matrikkelUnitId === taxation.propertyId).map((floor: Floor, index: number) => (
                                            <tr key={index} className={tableStyles.tableRow}>
                                                <td className={tableStyles.tableCell}>
                                                    <Diff
                                                        prop={`floors[${index}].floorNumber`}
                                                        prev={prev}
                                                        curr={curr}
                                                    ></Diff>
                                                </td>
                                                <td className={tableStyles.tableCell}>
                                                    <Diff
                                                        prop={`floors[${index}].usage`}
                                                        prev={prev}
                                                        curr={curr}
                                                    ></Diff>
                                                </td>
                                                <td className={tableStyles.tableCell}>{getFactor(floor.usageFactor)}</td>
                                                <td className={tableStyles.tableCell}>{floor.pricePerM2}</td>
                                                <td className={tableStyles.tableCell}>
                                                    <Diff
                                                        prop={`floors[${index}].area`}
                                                        prev={prev}
                                                        curr={curr}
                                                        formatFn={formatArea}
                                                    ></Diff>
                                                </td>
                                                <td className={tableStyles.tableCell} style={{ textAlign: 'right' }}>
                                                    {formatCurrency(floor.value)}
                                                </td>
                                            </tr>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>

                            <Grid item xs={12} />
                        </Grid>
                    )}

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} className={styles.boldeded}>
                            {tr(TEXT_NOTE)}
                        </Grid>
                        <Grid item xs>
                            <Diff prop="comment" prev={prev} curr={curr}></Diff>
                        </Grid>
                    </Grid>

                    {buildingImages.length > 0 && <Divider style={{ margin: '24px -16px' }}></Divider>}

                    <Grid container spacing={2} className={styles.marginBottom}>
                        <Grid item xs={12}>
                            <Lightbox
                                images={buildingImages}
                                thumbnailWidth="384px"
                                thumbnailHeight="216px"
                                renderImageFunc={(
                                    idx: number,
                                    image: LightboxImage,
                                    toggleLightbox: any,
                                    width: string,
                                    height: string,
                                ) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="lightbox-img-thumbnail  lightbox-img-thumbnail--cubit"
                                            style={{
                                                backgroundImage: `url(${image.src})`,
                                                width: width,
                                                height: height,
                                            }}
                                            onClick={toggleLightbox.bind(null, idx)}
                                        ></div>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

const renderBuildingNewCommentsAndMetricChangesCount = (
    taxation: Taxation,
    buildingIndex: number,
): JSX.Element | null => {
    const commentsAndMetricChangesTexts: string[] = []
    const newBuildingCommentsCount = getTaxationBuildingNewCommentsCount(taxation, buildingIndex)
    const newBuildingMetricChangesCount = getTaxationBuildingMetricChangesCount(taxation, buildingIndex)

    const commentsText =
        newBuildingCommentsCount > 1
            ? `${newBuildingCommentsCount} ${tr(TEXT_COMMENTS).toLowerCase()}`
            : newBuildingCommentsCount === 1
            ? `${newBuildingCommentsCount} ${tr(TEXT_COMMENT_NOUN).toLowerCase()}`
            : null

    commentsText && commentsAndMetricChangesTexts.push(commentsText)

    const metricsText =
        newBuildingMetricChangesCount > 1
            ? `${newBuildingMetricChangesCount} ${tr(TEXT_METRIC_CHANGES).toLowerCase()}`
            : newBuildingMetricChangesCount === 1
            ? `${newBuildingMetricChangesCount} ${tr(TEXT_METRIC_CHANGE).toLowerCase()}`
            : null

    metricsText && commentsAndMetricChangesTexts.push(metricsText)

    return (
        (commentsAndMetricChangesTexts.length > 0 && (
            <Grid item style={{ fontSize: '16px' }}>
                {commentsAndMetricChangesTexts.join(', ')}
            </Grid>
        )) ||
        null
    )
}
