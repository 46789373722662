import React from 'react'
import { WrappedFieldArrayProps } from 'redux-form'
import { Building, emptyBuilding } from 'models/building'
import { TaxationPropertyBuildingDetails } from './property-taxation-building-edit'
import { isTaxationEditingFinished, Taxation } from '../../models/taxation'
import { tr } from '../../utils/translations/translate'
import { TEXT_ADD_BUILDING } from '../../utils/translations/keys'
import { Button, Grid, Box } from '@material-ui/core'

type TaxationPropertyBuildingsFieldArrayProps = {
    form: string
    taxation: Taxation
    setExpandedBuildingIndex: any
    expandedBuildingIndex: number | false
}

export const TaxationPropertyBuildingsFieldArray: React.FC<
    WrappedFieldArrayProps<Building> & TaxationPropertyBuildingsFieldArrayProps
> = props => {
    const { form, fields, taxation, setExpandedBuildingIndex, expandedBuildingIndex } = props

    const handleAddBuilding = () => {
        fields.push(emptyBuilding())
    }

    return (
        <>
            {fields.map((buildingFieldName: string, index: number, fields) => (
                <TaxationPropertyBuildingDetails
                    fields={fields}
                    key={index}
                    index={index}
                    buildingFieldName={buildingFieldName}
                    form={form}
                    setExpandedBuildingIndex={setExpandedBuildingIndex}
                    expandedBuildingIndex={expandedBuildingIndex}
                />
            ))}

            {fields.length > 0 && <Box marginBottom={4}></Box>}

            {!isTaxationEditingFinished(taxation) && (
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button variant="outlined" onClick={handleAddBuilding}>
                                {tr(TEXT_ADD_BUILDING)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
