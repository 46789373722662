import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCubitTableClearSelection } from 'shared-components/src/cubit-table/cubit-table-actions'
import ContextBar from 'common/context-bar/context-bar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { TEXT_PROPERTIES, TEXT_SELECTED, TEXT_SEND_YEAR_TAX } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import { AppState } from 'app/app-store'
import { CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { actionSendYearLetters } from 'search-page/search-actions'
import { FailedCorrespondenceTableName } from './dashboard-failed-correspondence'

const useStyles = makeStyles((theme: Theme) => ({
    spinnerButton: {
        '&.Mui-disabled': {
            color: '#fff',
            borderColor: '#fff',
        },
    },
    spinnerIcon: {
        height: '24px !important',
        width: '24px !important',
        marginLeft: 10,
    },
    contextBarItem: {
        paddingLeft: 36,
        minWidth: 150,
    },
    contextBarContainer: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 5,
        },
    },
}))

export const DashboardContextBar: React.FC = () => {
    const styles = useStyles()
    const dispatch = useDispatch()

    const selectedPropertiesIds: string[] = useSelector((state: AppState) =>
        state.table.failedCorrespondence && state.table.failedCorrespondence.selection ? state.table.failedCorrespondence.selection : [],
    )

    const [sending, setSending] = useState(false)
    useEffect(() => {
        setSending(false)
    }, [selectedPropertiesIds])

    const clearSelectedItemsHandler = (tableName: string) => {
        dispatch(actionCubitTableClearSelection(tableName))
    }

    const handleSendyearTax = () => {
        setSending(true)
        dispatch(actionSendYearLetters(selectedPropertiesIds, FailedCorrespondenceTableName))
    }

    if (selectedPropertiesIds.length) {
        return (
            <ContextBar>
                <Grid className={styles.contextBarContainer} container alignItems="center" spacing={2}>
                    <Grid item>
                        <IconButton
                            onClick={() => clearSelectedItemsHandler(FailedCorrespondenceTableName)}
                            color="inherit"
                            edge="start"
                            aria-label="Clear"
                            disabled={sending}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid className={styles.contextBarItem} item xs>
                        <Typography variant="h6">
                            {`${selectedPropertiesIds.length} ${tr(TEXT_PROPERTIES)} ${tr(
                                TEXT_SELECTED,
                            )}`.toLowerCase()}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Button
                            variant="outlined"
                            color="inherit"
                            type="button"
                            onClick={handleSendyearTax}
                            disabled={sending}
                            className={styles.spinnerButton}
                        >
                            {`${tr(TEXT_SEND_YEAR_TAX)} ${selectedPropertiesIds.length}`}{' '}
                            {sending && <CircularProgress className={styles.spinnerIcon} />}
                        </Button>
                    </Grid>
                </Grid>
            </ContextBar>
        )
    }

    return <></>
}
