import React from 'react'
import { Permission } from 'common/enums/permission'
import { useUser } from 'auth/auth-selectors'
import { findIndex } from 'lodash'

export const Protected: React.FC<{ p: Permission | Permission[] }> = props => {
    const { children, p } = props
    const permissions = Array.isArray(p) ? p : [p]
    const user = useUser()
    const userPermissions = user ? user.tags : []

    const hasPermission =
        findIndex(userPermissions, userPermission => findIndex(permissions, p => p === userPermission) > -1) > -1

    return hasPermission ? <>{children}</> : <></>
}
