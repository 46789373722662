import { FailedCorrespondenceDetails } from 'models/dashboard'
import { AnyAction } from 'redux'

export const ACTION_GET_FAILED_CORRESPONDENCE = 'ACTION_GET_FAILED_CORRESPONDENCE'
export const ACTION_GET_FAILED_CORRESPONDENCE_SUCCESS = 'ACTION_GET_FAILED_CORRESPONDENCE_SUCCESS'

export const actionGetFailedCorrespondence = (): AnyAction => {
    return {
        type: ACTION_GET_FAILED_CORRESPONDENCE,
    }
}

export const actionGetFailedCorrespondenceSuccess = (data: FailedCorrespondenceDetails[]): AnyAction => {
    return {
        type: ACTION_GET_FAILED_CORRESPONDENCE_SUCCESS,
        data,
    }
}
