import React, { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Button,
    Modal,
    Theme,
    makeStyles,
} from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { Field, formValueSelector, isSubmitting, stopSubmit, submit } from 'redux-form'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import CubitDatePickerAdapter from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-date-picker-adapter'

const useStyles = makeStyles((theme: Theme) => {
    return {
        modal: {
            top: theme.spacing(20),
            width: '100%',
            maxWidth: theme.spacing(72),
            margin: '0 auto',
            left: 0,
            right: 0,
            position: 'fixed',
            padding: theme.spacing(3),
            backgroundColor: '#ffffff',
            outline: 'none',
        },
        button: {
            color: '#ffffff',
            backgroundColor: theme.palette.primary.main,
            fontWeight: 300,
            '&:hover': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
            }
        },
        actionButtons: {
            marginTop: theme.spacing(2)
        },
        field: {
            marginTop: theme.spacing(2)
        }
    }
})
export const SettingsCommentModal = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const submitting: boolean = useSelector((state: any) => isSubmitting(TaxFormName.TaxationSettings)(state))
    const selector = formValueSelector(TaxFormName.TaxationSettings)
    const stateRef = useSelector((state: AppState) => state)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    
    const [open, setOpen] = useState(true)

    const handleClose = () => {
        setOpen(false)
        dispatch(stopSubmit(TaxFormName.TaxationSettings))
    }
    const handleSave = () => {
        setOpen(false)
        dispatch(stopSubmit(TaxFormName.TaxationSettings))
        dispatch(submit(TaxFormName.TaxationSettings))
    }
    useEffect(() => {
        setOpen(submitting)
    }, [submitting])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="settings-change-comment"
            aria-describedby="settings-change-comment"
        >
            <Grid container className={classes.modal}>
                <Grid item xs={12}>
                    <Typography variant="h5">{tr(t.TEXT_SAVE_TAXATION_SETTINGS)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name={TaxFormFieldNames.settingsEffectDate}
                        component={CubitDatePickerAdapter}
                        label={tr(t.TEXT_SETTINGS_TAKE_EFFECT)}
                        variant="filled"
                        className={classes.field}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name={TaxFormFieldNames.settingsChangeComment}
                        component={CubitTextFieldAdapter}
                        label={tr(t.TEXT_SETTINGS_CHANGE_COMMENT)}
                        required={true}
                        multiline={true}
                        type="text"
                        rows={5}
                        className={classes.field}
                    />
                </Grid>
                <Grid container justifyContent="flex-end" spacing={2} className={classes.actionButtons}>
                    <Grid item>
                        <Button color="inherit" type="button" onClick={handleClose}>
                            {tr(t.TEXT_CANCEL)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="inherit"
                            type="button"
                            className={classes.button}
                            onClick={handleSave}
                            disabled={!getFieldValue(TaxFormFieldNames.settingsChangeComment)}
                        >
                            {tr(t.TEXT_SAVE_CHANGES)}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
