import React from 'react'
import { Taxation } from 'models/taxation'
import { getPropertyTitle, getPropertyAddresses } from 'utils'
import { Grid, Button, IconButton, Typography } from '@material-ui/core'
import { TribunalAssignToGroup } from 'tribunal-page/tribunal-assign-to-group/tribunal-assign-to-group'
import { TribunalGroupState } from 'tribunal-page/tribunal-group-page/tribunal-group-state.enum'
import { PageReference } from 'tribunal-page/page-reference.enum'
import { useDispatch, useSelector } from 'react-redux'
import {
    actionTribunalTaxationsMarkAsFinished,
    actionTribunalTaxationsReschedule,
} from 'tribunal-page/tribunal-actions'
import { tr } from 'utils/translations/translate'
import { TEXT_APPROVE_TAXATION, TEXT_PREVIOUS, TEXT_NEXT } from 'utils/translations/keys'
import { TribunalTaxationDecline } from 'tribunal-taxation-details-page/tribunal-taxation-decline/tribunal-taxation-decline'
import { UserSelection } from 'common/user-selection/user-selection'
import { useUsers } from 'app/app-selectors'
import { sortBy, findIndex } from 'lodash'
import { useUserId } from 'auth/auth-selectors'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { actionGoBack } from 'app/app-actions'
import { useTribunalGroupTaxations } from 'tribunal-page/tribunal-selectors'
import { navigate } from '@reach/router'
import { TribunalGroupTablePrefix } from 'tribunal-page/tribunal-group-page/tribunal-group-taxations-table'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { Truncate } from 'common/truncate/truncate'
import { isActive } from 'utils/tribunal/tribunal-helper'

type TribunalTaxationDetailsToolbarProps = {
    taxation: Taxation
    groupId: string
    groupState: TribunalGroupState
}

export const TribunalTaxationDetailsToolbar: React.FC<TribunalTaxationDetailsToolbarProps> = props => {
    const { taxation, groupId, groupState } = props
    const dispatch = useDispatch()

    const property = taxation.current.property
    const title = getPropertyTitle(property, getPropertyAddresses(taxation.current.housingUnits))
    const currentUserId = useUserId()
    const users = useUsers()
    const usersArray = sortBy(
        sortBy(
            Object.keys(users).map(key => users[key]),
            u => u.name,
        ),
        u => u.id !== currentUserId,
    )

    const tribunalGroupTableName = `${TribunalGroupTablePrefix}${groupId}`
    const tribunalGroupTable = useSelector((state: any) => state.table[tribunalGroupTableName])

    const groupTaxationsOrderDirection = (tribunalGroupTable && tribunalGroupTable.orderDirection) || 'asc'
    const groupTaxationsOrderBy = (tribunalGroupTable && tribunalGroupTable.orderBy) || 'current.property.holdingNumber'
    const groupTaxations = useTribunalGroupTaxations(groupId)

    const sortedGroupTaxations =
        groupTaxationsOrderDirection === 'asc'
            ? (sortBy(groupTaxations, [groupTaxationsOrderBy]) as Taxation[])
            : (sortBy(groupTaxations, [groupTaxationsOrderBy]).reverse() as Taxation[])

    const taxationIndexInGroup = findIndex(sortedGroupTaxations, t => t.id === taxation.id)

    const handleGoBack = () => {
        dispatch(actionGoBack())
    }

    const handleMarkAsFinished = () => {
        dispatch(actionTribunalTaxationsMarkAsFinished(groupId, [taxation.id]))
    }

    const handleRescheduleTaxation = (userId: string) => {
        if (!userId) {
            return
        }

        dispatch(actionTribunalTaxationsReschedule(userId, groupId, [taxation.id]))
    }

    const handleNavigatePrev = (index: number) => {
        const prevId = sortedGroupTaxations[index - 1].id
        navigate(`/tribunal/group/${groupState}/${groupId}/taxation/${prevId}`, { replace: true })
    }

    const handleNavigateNext = (index: number) => {
        const nextId = sortedGroupTaxations[index + 1].id
        navigate(`/tribunal/group/${groupState}/${groupId}/taxation/${nextId}`, { replace: true })
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item lg={9}>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xs>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <IconButton onClick={handleGoBack} color="inherit">
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h6">
                                    <Truncate>{title}</Truncate>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            {groupState === TribunalGroupState.Initial && (
                                <Grid item>
                                    <TribunalAssignToGroup
                                        groupId={groupId}
                                        taxationsIds={[taxation.id]}
                                        pageReference={PageReference.TribunalTaxationDetails}
                                    ></TribunalAssignToGroup>
                                </Grid>
                            )}

                            {groupState === TribunalGroupState.Started && (
                                <Protected p={Permission.TribunalApproveDecline}>
                                    <Grid item>
                                        <TribunalTaxationDecline
                                            groupId={groupId}
                                            taxationsIds={[taxation.id]}
                                            pageReference={PageReference.TribunalTaxationDetails}
                                            disabled={!isActive(taxation.state)}
                                        ></TribunalTaxationDecline>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            type="button"
                                            onClick={handleMarkAsFinished}
                                            disabled={!isActive(taxation.state)}
                                        >
                                            {tr(TEXT_APPROVE_TAXATION)}
                                        </Button>
                                    </Grid>
                                </Protected>
                            )}

                            {groupState === TribunalGroupState.Declined && (
                                <Grid item>
                                    <UserSelection
                                        usersArray={usersArray}
                                        onChange={handleRescheduleTaxation}
                                    ></UserSelection>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={3}>
                {groupTaxations && (
                    <Grid container spacing={2} justifyContent="flex-end">
                        {hasPreviousItem(taxationIndexInGroup) && (
                            <Grid item>
                                <Button color="inherit" onClick={() => handleNavigatePrev(taxationIndexInGroup)}>
                                    <ArrowBackIosIcon />
                                    {tr(TEXT_PREVIOUS)}
                                </Button>
                            </Grid>
                        )}
                        {hasNextItem(taxationIndexInGroup, groupTaxations.length) && (
                            <Grid item>
                                <Button color="inherit" onClick={() => handleNavigateNext(taxationIndexInGroup)}>
                                    {tr(TEXT_NEXT)}
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

const hasPreviousItem = (itemIndex: number) => {
    return itemIndex > 0
}
const hasNextItem = (itemIndex: number, itemsLenght: number) => {
    return itemIndex + 1 !== itemsLenght
}
