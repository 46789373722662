import { Property, PropertyStatus } from "models/property"

export const isRingerike = (id: string): boolean => {
    const ids = ['5e33ee7b6b92105480de385d', '5b5197fb394c3c43ac9d8215']
    return ids.includes(id)
}

export const allAssigned = (properties: Property[]) => {
    if (!properties || !properties.length) {
        return false
    }
    return !properties.some(p => p.state?.value !== PropertyStatus.ASSIGNED_COMPANY && p.state?.value !== PropertyStatus.ASSIGNED_USER && p.state?.value !== PropertyStatus.PICKED)
}

export const anyNotified = (props: Property[]) => {
    if (!props || !props.length) {
        return true
    }
    return props.some((p: Property) => p.otherPropertyFlags?.taxNoticeSent)
}

export const anyUnsentYearLetters = (props: Property[]) => {
    if (!props || !props.length) {
        return false
    }
    return props.some((p: Property) => !p.otherPropertyFlags?.yearLetterSent)
}

export const GetUnsentYearLettersIds = (props: Property[]) => {
    if (!props || !props.length) {
        return []
    }
    return props.filter((p: Property) => !p.otherPropertyFlags?.yearLetterSent).map(x => x.id)
}