import React, { useState } from 'react'
import {
    Divider,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
    Theme,
    IconButton,
    Button,
} from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_LOCATION_FACTOR,
    TEXT_PLOT_INFORMATION,
    TEXT_USE,
    TEXT_REGULATED,
    TEXT_M2_PRICE,
    TEXT_PLOT_SIZE,
    TEXT_VALUE,
    TEXT_YES,
    TEXT_NO,
    TEXT_TOTAL_LAND_VALUE,
    TEXT_IMAGES,
    TEXT_CANCEL,
    TEXT_SAVE,
    TEXT_NOTE,
} from 'utils/translations/keys'
import { Taxation } from 'models/taxation'
import {
    getPropertyLocationFactorTranslationKey,
    getPropertyValueTypeTranslationKey,
    formatArea,
    formatCurrency,
} from 'utils'
import './tribunal-taxation-details.css'
import { Property } from 'models/property'
import { LightboxImage } from 'models/lightbox-image'
// @ts-ignore
import Lightbox from 'react-lightbox-component'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classes from 'shared-components/src/cubit-table/cubit-table.module.css'
import { Edit } from '@material-ui/icons'
import { Field, isPristine, isInvalid, submit, reset, getFormValues } from 'redux-form'
import { FormField } from 'shared-components/src/cubit-inputs/cubit-form-field.types'
import { InputType } from 'shared-components/src/cubit-inputs/input-type.enum'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { useSelector, useDispatch } from 'react-redux'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { TaxationPropertyLocationFactors } from 'property-taxation-edit-page/property-taxation-edit/property-taxation-locationFactor-fields'
import { TaxationLocationOtherFactors } from 'property-taxation-edit-page/property-taxation-edit/property-taxation-locationFactor-otherFactors'
import { TaxationOtherFactorsList } from 'property-taxation-edit-page/property-taxation-edit/property-taxation-locationFactor-otherFactors-list'
import { getPropertyValueTypeOptions } from 'utils/property/property-helper'
import { simpleFormatNumber } from 'shared-components/src/utils/format/format-number'

const valueType = (): FormField<InputType.Select> => ({
    type: InputType.Select,
    props: {
        name: 'current.property.valueType',
        label: '',
        component: CubitSelectAdapter,
        valueIsObject: false,
        options: getPropertyValueTypeOptions(),
    },
})

const useStyles = makeStyles((theme: Theme) => {
    return {
        Accordion: {
            margin: theme.spacing(0, -3),
            border: 0,
            boxShadow: 'none',
            '&.Mui-expanded': {
                margin: theme.spacing(0, -3),
            },
            '&:before': {
                display: 'none',
            },
        },
        summaryRoot: {
            '&.Mui-expanded': {
                minHeight: '32px',
            },
        },
        summaryContent: {
            '&.Mui-expanded': {
                margin: theme.spacing(0),
            },
        },
    }
})

export const TribunalTaxationDetailsPlotInfo: React.FC<{
    formName: string
}> = (props) => {
    const { formName } = props
    const styles = useStyles()
    const dispatch = useDispatch()

    const taxationSettings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)
    const taxation = useSelector(getFormValues(formName)) as Taxation
    const property = taxation ? taxation.current.property : ({} as Property)
    const oldProperty = taxation.old.property

    const locationFactorHasBeenChanged = property.locationFactor !== oldProperty.locationFactor

    const [editing, setEditing] = useState(false)

    const propertyImages: LightboxImage[] = taxation
        ? taxation.current.property.files.map((file: any) => ({
              src: file.url,
              title: ' ',
              description: ' ',
          }))
        : []

    const isFormPristine = useSelector(isPristine(formName))
    const isFormInvalid = useSelector(isInvalid(formName))

    const submitForm = () => {
        dispatch(submit(formName))
        setEditing(false)
    }

    const handleCancelEdit = () => {
        dispatch(reset(formName))
        setEditing(false)
    }

    return (
        <div className="tribunalTaxationDetailsContent">
            <Grid container justifyContent="space-between" alignItems="center" className="mb-1">
                <Grid item>
                    <Typography variant="h6">{tr(TEXT_PLOT_INFORMATION)}</Typography>
                </Grid>
                {!editing && (
                    <Protected p={Permission.ViewTribunalAll}>
                        <Grid item>
                            <IconButton size="small" onClick={() => setEditing(true)} color="inherit">
                                <Edit />
                            </IconButton>
                        </Grid>
                    </Protected>
                )}
            </Grid>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3} className="bolder">
                    {tr(TEXT_LOCATION_FACTOR)}
                </Grid>
                <Grid item xs={9}>
                    {editing ? (
                        <TaxationPropertyLocationFactors taxation={taxation} editable={editing} formName={formName} />
                    ) : (
                        simpleFormatNumber(property.locationFactor) +
                        ' ' +
                        getPropertyLocationFactorTranslationKey(
                            property.locationFactor,
                            taxationSettings.locationFactors,
                        )
                    )}
                </Grid>
            </Grid>

            {editing && locationFactorHasBeenChanged ? (
                <TaxationLocationOtherFactors
                    taxation={taxation}
                    editable={editing}
                    formName={formName}
                    locationFactorHasBeenChanged={locationFactorHasBeenChanged}
                />
            ) : (
                locationFactorHasBeenChanged && <TaxationOtherFactorsList factors={property.otherLocationFactors} />
            )}

            <Divider className="divider-2"></Divider>

            <Grid container>
                <Grid item xs={4}>
                    {tr(TEXT_USE)}
                </Grid>
                <Grid item xs={2}>
                    {tr(TEXT_REGULATED)}
                </Grid>
                <Grid item xs={2}>
                    {tr(TEXT_M2_PRICE)}
                </Grid>
                <Grid item xs={2}>
                    {tr(TEXT_PLOT_SIZE)}
                </Grid>
                <Grid item xs={2} className="textRight">
                    {tr(TEXT_VALUE)}
                </Grid>
            </Grid>

            <Divider className="divider-2"></Divider>

            <Grid container>
                <Grid item xs={4}>
                    {editing ? (
                        <Field {...valueType().props} valueIsObject={false} />
                    ) : (
                        tr(getPropertyValueTypeTranslationKey(property.valueType))
                    )}
                </Grid>
                <Grid item xs={2}>
                    {property.regulated ? tr(TEXT_YES) : tr(TEXT_NO)}
                </Grid>
                <Grid item xs={2}>
                    {formatCurrency(property.plotSquareMeterValue)}
                </Grid>
                <Grid item xs={2}>
                    {formatArea(property.plotSize)}
                </Grid>
                <Grid item xs={2} className="textRight">
                    {formatCurrency(property.plotValue)}
                </Grid>
            </Grid>

            <Divider className="divider-2"></Divider>

            <Grid container>
                <Grid item xs={10} className="bolder">
                    {tr(TEXT_TOTAL_LAND_VALUE)}
                </Grid>

                <Grid item xs={2} className="bolder  textRight">
                    {formatCurrency(property.plotValue)}
                </Grid>
            </Grid>
            {(property.taxInfo?.lotSizeComment || property.comment) && (
                <>
                    <Divider className="divider-2"></Divider>

                    <Grid container>
                        <Grid item xs={10} className="bolder">
                            {tr(TEXT_NOTE)}
                        </Grid>

                        <Grid item xs={2} className="bolder  textRight">
                            {property.taxInfo?.lotSizeComment ? property.taxInfo?.lotSizeComment : property.comment}
                        </Grid>
                    </Grid>
                </>
            )}
            {property.comment && (
                <>
                    <Divider className="divider-2"></Divider>

                    <Grid container>
                        <Grid item xs={10} className="bolder">
                            {tr(TEXT_NOTE)}
                        </Grid>

                        <Grid item xs={2} className="bolder  textRight">
                            {property.comment}
                        </Grid>
                    </Grid>
                </>
            )}

            {propertyImages.length > 0 && <Divider className="divider-2"></Divider>}

            {propertyImages.length > 0 && (
                <Accordion classes={{ root: styles.Accordion }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{ root: styles.summaryRoot, content: styles.summaryContent }}
                    >
                        <Typography className={classes.heading}>{`${propertyImages.length} ${tr(
                            TEXT_IMAGES,
                        )}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Lightbox
                                    images={propertyImages}
                                    thumbnailWidth="384px"
                                    thumbnailHeight="216px"
                                    renderImageFunc={(
                                        idx: number,
                                        image: LightboxImage,
                                        toggleLightbox: any,
                                        width: string,
                                        height: string,
                                    ) => {
                                        return (
                                            <div
                                                key={idx}
                                                className="lightbox-img-thumbnail  lightbox-img-thumbnail--cubit"
                                                style={{
                                                    backgroundImage: `url(${image.src})`,
                                                    width: width,
                                                    height: height,
                                                }}
                                                onClick={toggleLightbox.bind(null, idx)}
                                            ></div>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )}

            {editing && (
                <>
                    <Divider className="divider-2"></Divider>

                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={handleCancelEdit}>{tr(TEXT_CANCEL)}</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={submitForm}
                                variant="contained"
                                color="primary"
                                disabled={isFormPristine || isFormInvalid}
                            >
                                {tr(TEXT_SAVE)}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    )
}
