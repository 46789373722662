import React, { useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    makeStyles,
    Theme,
    Divider,
    Grid,
} from '@material-ui/core'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { useDispatch, useSelector } from 'react-redux'
import { actionPlanningGetUnplannedEvents, actionPlanningGetUserEvents } from 'planning-page/planning-actions'
import { tr } from 'utils/translations/translate'
import { TEXT_CALENDARS, TEXT_ME } from 'utils/translations/keys'
import { Company } from 'models/company'
import { User } from 'models/user'
import { ExpandMore, CalendarToday } from '@material-ui/icons'
import { grey } from '@material-ui/core/colors'
import classnames from 'classnames'
import { AppState } from 'app/app-store'
import { useUserId } from 'auth/auth-selectors'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme: Theme) => ({
    currentUser: {
        borderTop: '1px solid ' + grey[300],
        backgroundColor: '#ffffff',
        padding: theme.spacing(1),
    },
    currentUserName: {
        paddingLeft: theme.spacing(1),
    },
    form: {
        width: '100%',
    },
    parentPanel: {
        borderTop: '1px solid ' + grey[300],
        borderBottom: '1px solid ' + grey[300],
        padding: theme.spacing(1),
        paddingRight: '16px',
    },
    panel: {
        boxShadow: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        borderRadius: '0px !important',

        '&:before': {
            display: 'none',
        },

        '&.Mui-expanded': {
            marginTop: 0,
        },
    },
    summaryRoot: {
        padding: 0,
        '&.Mui-expanded': {
            minHeight: '48px',
        },
    },
    summaryContent: {
        padding: 0,
        '&.Mui-expanded': {
            margin: theme.spacing(1.5, 0),
        },
    },
    detailsRootParent: {
        padding: theme.spacing(1),
    },
    detailsRoot: {
        padding: 0,
    },
}))

type PlanningUserCalendarSelectProps = {
    initialValues: { id: string }
    companies: Company[]
    users: { [key: string]: User }
    usersWithoutCompanyArray: User[]
}

const PlanningUserCalendarSelect: React.FC<
    InjectedFormProps<any, PlanningUserCalendarSelectProps> & PlanningUserCalendarSelectProps
> = props => {
    const { initialValues, companies, users, usersWithoutCompanyArray } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const currentUserId = useUserId()

    const [selectedUserId, setSelectedUserId] = useState(initialValues.id)

    const language = useSelector((state: AppState) => state.settings.language)
    const dateString = useSelector((state: AppState) => state.calendar.date)
    const date = DateTime.fromJSDate(new Date(dateString))
    const loadingPlanned = useSelector((state: AppState) => state.planning.loadingPlanned)
    const loadingUnplanned = useSelector((state: AppState) => state.planning.loadingUnplanned)

    const handleUserChange = (userId: any) => {
        if (userId !== selectedUserId && !loadingPlanned && !loadingUnplanned) {
            setSelectedUserId(userId)
            dispatch(actionPlanningGetUserEvents(userId, date.year, date.month))
            dispatch(actionPlanningGetUnplannedEvents(userId))
        }

    }

    return (
        <>
            {users[selectedUserId] && (
                <Grid className={styles.currentUser} container alignItems="center">
                    <Grid item>
                        <CalendarToday></CalendarToday>
                    </Grid>
                    <Grid item className={styles.currentUserName}>
                        {users[selectedUserId].name}
                    </Grid>
                </Grid>
            )}

            <Accordion className={classnames(styles.parentPanel, styles.panel)} lang={language} defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    classes={{ root: styles.summaryRoot, content: styles.summaryContent }}
                >
                    <Typography>{tr(TEXT_CALENDARS)}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: styles.detailsRootParent }}>
                    <form className={styles.form}>
                        {/* <Grid container>
                    <Grid item>
                            <Field
                                {...usersAndCompaniesField.props}
                                value={initialValues.value}
                                onChange={handleUserChange}
                            />
                    </Grid>
                </Grid> */}
                        {companies.map((c, index) =>
                            c.users.length > 0 ? (
                                <div key={index}>
                                    <Accordion key={index} className={styles.panel} defaultExpanded={c.users.some((userId: string) => userId === currentUserId)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            classes={{ root: styles.summaryRoot, content: styles.summaryContent }}
                                        >
                                            <Typography>{c.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: styles.detailsRoot }}>
                                            <Field
                                                name="id"
                                                component={CubitRadioGroupAdapter}
                                                value={initialValues.value}
                                                options={c.users.map(userId => ({
                                                    label: userId !== currentUserId ? users[userId].name : tr(TEXT_ME),
                                                    value: users[userId].id,
                                                }))}
                                                onChange={handleUserChange}
                                            />
                                        </AccordionDetails>
                                    </Accordion>

                                    <Divider></Divider>
                                </div>
                            ) : null,
                        )}

                        <Field
                            name="id"
                            component={CubitRadioGroupAdapter}
                            value={initialValues.value}
                            options={usersWithoutCompanyArray.map(u => ({
                                label: u.id !== currentUserId ? u.name: tr(TEXT_ME) ,
                                value: u.id,
                            }))}
                            onChange={handleUserChange}
                        />
                    </form>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export const PlanningUserCalendarSelectFormName = 'planningUserCalendarSelectForm'

export const PlanningUserCalendarSelectForm = reduxForm<{ id: string }, PlanningUserCalendarSelectProps>({
    form: PlanningUserCalendarSelectFormName,
    destroyOnUnmount: false,
})(PlanningUserCalendarSelect)
