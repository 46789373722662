/* eslint-disable */
import * as keys from '../keys'

export const en: { [index: string]: string } = {}

en[keys.TEXT_SAVE] = 'Save'
en[keys.TEXT_CHANGES_SAVED] = 'Changes saved'
en[keys.TEXT_DASHBOARD] = 'Dashboard'
en[keys.TEXT_FILTERS] = 'Filters'
en[keys.TEXT_SEARCH] = 'Search'
en[keys.TEXT_LANGUAGE] = 'Language'
en[keys.TEXT_LOGOUT] = 'Logout'
en[keys.TEXT_OBJECT_TYPES] = 'Object types'
en[keys.TEXT_PLOT_TYPE] = 'Plot type'
en[keys.TEXT_HOUSE] = 'House'
en[keys.TEXT_HOLIDAY_HOME] = 'Holiday home'
en[keys.TEXT_LOGIN] = 'Login'

en[keys.TEXT_ZONE] = 'Zone'
en[keys.TEXT_ZONE0] = 'Zone 0'
en[keys.TEXT_ZONE1] = 'Zone 1'
en[keys.TEXT_ZONE2] = 'Zone 2'
en[keys.TEXT_ZONE3] = 'Zone 3'
en[keys.TEXT_ZONE4] = 'Zone 4'
en[keys.TEXT_ZONE5] = 'Zone 5'
en[keys.TEXT_ZONE6] = 'Zone 6'

en[keys.TEXT_ALL] = 'All'
en[keys.TEXT_ME] = 'Me'

en[keys.TEXT_YES] = 'Yes'
en[keys.TEXT_NO] = 'No'

en[keys.TEXT_STATUS] = 'Status'
en[keys.TEXT_NO_STATUS] = 'No status'
en[keys.TEXT_PICKED] = 'Picked'
en[keys.TEXT_AWARDED] = 'Awarded'
en[keys.TEXT_ACTIVE_CASE] = 'Active case'
en[keys.TEXT_APPROVED_BY_TRIBUNAL] = 'Approved by NEMND'
en[keys.TEXT_COMPLAINT_RECEIVED] = 'Complaint received'

en[keys.TEXT_TAXATION] = 'Evaluation'
en[keys.TEXT_NO_TAXATION] = 'No taxation'
en[keys.TEXT_TAXATION_SOURCE] = 'Evaluation source'
en[keys.TEXT_TAX_ADMINISTRATION] = 'Tax administration'

en[keys.TEXT_PROPERTY_VALUE] = 'Property value'

en[keys.TEXT_TAX_SUM] = 'Tax sum'

en[keys.TEXT_MASS_REGISTRATION] = 'Mass registration'

en[keys.TEXT_EXEMPTION] = 'Exemption'
en[keys.TEXT_NO_EXEMPTION] = 'No exemption'
en[keys.TEXT_EXEMPTION_BY_REASON] = 'Exemption by reason'

en[keys.TEXT_IGANGSETTELSESTILATELSE] = 'Igangsettingstillatelse'
en[keys.TEXT_YEARS_SINCE_STATUS_WAS_SET] = 'Years since status'
en[keys.TEXT_MATRIKKEL_STATUS] = 'Matrikkel status'
en[keys.TEXT_STATUS_DATE] = 'Last change of matrikkel status'

en[keys.TEXT_OWNER_CATEGORY] = 'Owner category'
en[keys.TEXT_MUNICIPALITY] = 'Municipality'
en[keys.TEXT_PUBLIC] = 'Public'
en[keys.TEXT_PRIVATE] = 'Private'
en[keys.TEXT_DEAD_OWNERS] = 'Dead owners'

en[keys.TEXT_STANDARD] = 'Standard'
en[keys.TEXT_LOW] = 'Low'
en[keys.TEXT_NORMAL] = 'Normal'
en[keys.TEXT_HIGH] = 'High'

en[keys.TEXT_LOCATION] = 'Location'

en[keys.TEXT_PROPERTY] = 'Property'
en[keys.TEXT_PROPERTIES] = 'Properties'

en[keys.TEXT_OWNERS] = 'Owners'

en[keys.TEXT_ENABLE] = 'Enable'

en[keys.TEXT_FROM] = 'From'
en[keys.TEXT_TO] = 'To'
en[keys.TEXT_ASSIGN] = 'Assign'
en[keys.TEXT_ASSIGNED] = 'Assigned'
en[keys.TEXT_TAXATION_DATE] = 'Evaluation date'

en[keys.TEXT_DATE_FROM] = 'Date from'
en[keys.TEXT_DATE_TO] = 'Date to'
en[keys.TEXT_SETTINGS] = 'Settings'
en[keys.TEXT_CLEAR_ALL_FILTERS] = 'Clear all filters'

en[keys.TEXT_NAME] = 'Name'
en[keys.TEXT_FIRST_NAME] = 'First name'
en[keys.TEXT_LAST_NAME] = 'Last name'
en[keys.TEXT_ADDRESS] = 'Address'
en[keys.TEXT_ADDRESSES] = 'Addresses'
en[keys.TEXT_NUMBER] = 'Number'
en[keys.TEXT_PHONE_NUMBER] = 'Phone number'
en[keys.TEXT_E_MAIL] = 'E-mail'
en[keys.TEXT_TAX] = 'Tax'
en[keys.TEXT_TARIFF] = 'Tariff'
en[keys.TEXT_LAST_TARIFF] = 'Last tariff'
en[keys.TEXT_APPRAISERS] = 'Appraisers'
en[keys.TEXT_USE_OF_LAND] = 'Use of land'
en[keys.TEXT_USE] = 'Use'

en[keys.TEXT_REGISTER_NUMBERS] = 'Register numbers'
en[keys.TEXT_SELECTED] = 'Selected'
en[keys.TEXT_SCHEDULE_TAXATION] = 'Schedule assessment'
en[keys.TEXT_PARTICULARLY_FAVORABLE] = 'Particularly favorable'
en[keys.TEXT_PARTICULARLY_UNFAVORABLE] = 'Particularly unfavorable'

en[keys.TEXT_0457] = 'Industry Codes'
en[keys.TEXT_0777] = 'Address'
en[keys.TEXT_0778] = 'The Construction / equipment types'
en[keys.TEXT_0779] = 'Result Areas'
en[keys.TEXT_0780] = 'The system / activity profiles'
en[keys.TEXT_0781] = 'Building Codes'
en[keys.TEXT_0782] = 'El-business status'
en[keys.TEXT_0783] = 'El-business tasks'

en[keys.TEXT_PROPERTY_INFORMATION] = 'Property Information'
en[keys.TEXT_PLOT_INFORMATION] = 'Plot Information'
en[keys.TEXT_CADASTRE] = 'Cadastre'
en[keys.TEXT_VALUE_TYPE] = 'Value type'
en[keys.TEXT_VALUE] = 'Value'
en[keys.TEXT_M2_PRICE] = 'm² price'
en[keys.TEXT_FLOOR_FACTOR] = 'Floor factor'
en[keys.TEXT_FLOOR] = 'Floor'
en[keys.TEXT_LAST_REVIEWED] = 'Last reviewed'
en[keys.TEXT_MAP] = 'Map'
en[keys.TEXT_LAND_VALUE] = 'Land value'
en[keys.TEXT_BUILDING_VALUE] = 'Building value'
en[keys.TEXT_TOTAL_BUILDING_VALUE] = 'Total building value'
en[keys.TEXT_TOTAL_BEFORE_FACTOR] = 'Total before factor'
en[keys.TEXT_ZONE_FACTOR] = 'Zone factor'
en[keys.TEXT_LOCATION_FACTOR] = 'Location factor'
en[keys.TEXT_TOTAL_PROPERTY_VALUE] = 'Total property value'
en[keys.TEXT_EXCEPTION] = 'Exception'
en[keys.TEXT_VALUE_PER_M2] = 'Value per m²'
en[keys.TEXT_PLOT_SIZE] = 'Plot size'
en[keys.TEXT_TOTAL_LAND_VALUE] = 'Total land value'
en[keys.TEXT_VALUE_BASED_LAYERS] = 'Value-based layers'
en[keys.TEXT_ADD_NEW_COMMENT] = 'Add new comment'
en[keys.TEXT_VIEW_ALL_COMMENTS_AND_NOTES] = 'View all comments and notes'
en[keys.TEXT_STATE] = 'Sate'
en[keys.TEXT_STANDARD_FACTOR] = 'Standard factor'
en[keys.TEXT_ADD_TABLE] = 'Add table'
en[keys.TEXT_NOTE] = 'Note'
en[keys.TEXT_CALENDAR] = 'Calendar'
en[keys.TEXT_CALENDARS] = 'Calendars'
en[keys.TEXT_LIST] = 'List'
en[keys.TEXT_DAY] = 'Day'
en[keys.TEXT_WEEK] = 'Week'
en[keys.TEXT_MONTH] = 'Month'
en[keys.TEXT_REMOVE_EVENT] = 'Remove event'
en[keys.TEXT_REMOVE_EVENTS] = 'Remove events'
en[keys.TEXT_CONFIRM_CHANGES] = 'Confirm changes'
en[keys.TEXT_SEND_NOTICE] = 'Send notice ${params[0]} days prior to inspection'
en[keys.TEXT_TODAY] = 'Today'

en[keys.TEXT_RESIDENTIAL_PROPERTY] = 'Residential property'
en[keys.TEXT_LEISURE_PROPERTY] = 'Leisure Property'
en[keys.TEXT_FARMING_FORESTRY_WITH_HOUSING] = 'Farming / forestry with housing'
en[keys.TEXT_FARM_USE_FORESTRY_WITH_LEISURE] = 'Farm use / forestry with leisure'
en[keys.TEXT_FARMS_OR_FORESTRY] = 'Farms or forestry'
en[keys.TEXT_NOT_SELECTED] = 'Not selected'
en[keys.TEXT_COMBINED_BUILT_PLOT] = 'Combined built plot'
en[keys.TEXT_COMMERCIAL] = 'Commercial'
en[keys.TEXT_UNDEVELOPED_PLOT_HOUSING] = 'Undeveloped plot, housing'
en[keys.TEXT_UNDEVELOPED_PLOT_LEISURE] = 'Undeveloped plot, leisure'
en[keys.TEXT_UNDEVELOPED_PLOT_INDUSTRY] = 'Undeveloped plot, industry'
en[keys.TEXT_UNDEVELOPED_PLOT] = 'Undeveloped plot'

en[keys.TEXT_DIRECTIONS] = 'Directions'
en[keys.TEXT_START_TAXATION] = 'Start taxation'
en[keys.TEXT_BILLING_ADDRESS] = 'Billing address'
en[keys.TEXT_BILLING] = 'Billing'
en[keys.TEXT_ZIP_CODE] = 'Zip code'
en[keys.TEXT_CANCEL] = 'Cancel'
en[keys.TEXT_ROLLBACK] = 'Rollback'
en[keys.TEXT_VERIFIED] = 'Verified'
en[keys.TEXT_UNITS] = 'Units'
en[keys.TEXT_ADVANCED_DECAY_RESTORATION] = 'Advanced decay / restoration object'
en[keys.TEXT_DEMOLITION_OBJECT] = 'Demolition object'
en[keys.TEXT_ADD_FLOOR] = 'Add floor'
en[keys.TEXT_ADD_BUILDING] = 'Add building'
en[keys.TEXT_ADD_PICTURE] = 'Add picture'
en[keys.TEXT_OF] = 'of'
en[keys.TEXT_2_IMAGES_REQUIRED] = 'Atleast 2 images are required'
en[keys.TEXT_FINISH_TAXATION] = 'Finish evaluation'
en[keys.TEXT_SHOW_WEEKENDS] = 'Show weekends'
en[keys.TEXT_BUSINESS] = 'Business'

en[keys.TEXT_SELECT_FOR_TAXATION] = 'Select for evaluation'
en[keys.TEXT_SCHEDULE_TAXATION_FOR] = 'Schedule evaluation for'
en[keys.TEXT_SCHEDULED_TAXATION_FOR] = 'Scheduled evaluation for'
en[keys.TEXT_COMPANIES] = 'Companies'
en[keys.TEXT_USERS] = 'Users'
en[keys.TEXT_USER] = 'User'

en[keys.TEXT_AREA] = 'Area'
en[keys.TEXT_SUN] = 'Sun'
en[keys.TEXT_NOISE] = 'Noise'
en[keys.TEXT_VIEW] = 'View'
en[keys.TEXT_LIGHT_POLLUTION] = 'Light pollution'
en[keys.TEXT_AIR_POLLUTION] = 'Air pollution'
en[keys.TEXT_POLLUTION_REASON] = 'Pollution reason'
en[keys.TEXT_FLOOD_AND_EXPOSED_AREA] = 'Flood and exposed area'
en[keys.TEXT_DIFFICULT_ACCESS_ROAD] = 'Difficult access road'
en[keys.TEXT_ARCHITECTURE] = 'Architecture'
en[keys.TEXT_MAINTENANCE] = 'Maintenance'
en[keys.TEXT_OUTDOOR_AREA] = 'Outdoor area'

en[keys.TEXT_POSITIVE] = 'Positive'
en[keys.TEXT_NEGATIVE] = 'Negative'

en[keys.TEXT_METRICS_STATUS] = 'Metrics status'
en[keys.TEXT_BAD] = 'Bad'
en[keys.TEXT_VERY_BAD] = 'Very bad'

en[keys.TEXT_PRESENT_AT_TAXATION] = 'Present at evaluation'
en[keys.TEXT_NOBODY] = 'Nobody'
en[keys.TEXT_LEGAL_OWNER] = 'Legal owner'
en[keys.TEXT_OTHERS] = 'Other'
en[keys.TEXT_TAXATION_INFORMATION] = 'Evaluation information'

en[keys.TEXT_LAST_TAXATION] = 'Last evaluation'
en[keys.TEXT_NEW_TAXATION] = 'New evaluation'
en[keys.TEXT_ERASE_BUILDING] = 'Erase building'

en[keys.TEXT_WELCOME_TO] = 'Welcome to'
en[keys.TEXT_CUBIT_TAX] = 'Cubit tax'
en[keys.TEXT_PASSWORD] = 'Password'

en[keys.TEXT_IMAGES] = 'Images'

en[keys.TEXT_COULD_NOT_TAXATE] = 'Could not be evaluated'
en[keys.TEXT_DID_NOT_GET_TAXATED] = 'Did not get assessed'

en[keys.TEXT_TRIBUNAL] = 'Tribunal'
en[keys.TEXT_EDIT_GROUP] = 'Edit group'
en[keys.TEXT_GROUP_NAME] = 'Group name'
en[keys.TEXT_GROUP_DESCRIPTION] = 'Group description'
en[keys.TEXT_APPROVED_TRIBUNAL] = 'Approved by tribunal'
en[keys.TEXT_APPROVAL_DATE] = 'Approval date'
en[keys.TEXT_NUMBER_OF_PROPERTIES] = 'Number of properties'

en[keys.TEXT_FACTOR_CHANGE] = 'Factor change'
en[keys.TEXT_METRIC_CHANGE] = 'Metric change'
en[keys.TEXT_METRIC_CHANGES] = 'Metric changes'
en[keys.TEXT_NOTE_CHANGE] = 'Note change'

en[keys.TEXT_FILTER] = 'Filter'
en[keys.TEXT_ADD_TO_LIST] = 'Add to list'
en[keys.TEXT_CREATE_NEW_GROUP] = 'Create new group'

en[keys.TEXT_ADDED_TO] = 'Added to'
en[keys.TEXT_FINISHED_TAXATION] = 'Finished evaluation'
en[keys.TEXT_READY_FOR_TRIBUNAL] = 'Ready for the tribunal'
en[keys.TEXT_COMPLAINTS] = 'Complaints'

en[keys.TEXT_INCOMPETENT] = 'Incompetent'
en[keys.TEXT_SET_AS_INCOMPETENT] = 'Set as incompetent'
en[keys.TEXT_REMOVE_AS_INCOMPETENT] = 'Remove as incompetent'
en[keys.TEXT_SET_AS_INCOMPETENT_FOR_OBJECT] = 'Set as incompetent for the object'
en[keys.TEXT_START_TRIBUNAL] = 'Start tribunal'
en[keys.TEXT_APPROVE_TRIBUNAL] = 'Approve tribunal'
en[keys.TEXT_APPROVE_TAXATION] = 'Approve evaluation'
en[keys.TEXT_DECLINE_TAXATION] = 'Decline evaluation'
en[keys.TEXT_DECLINED] = 'Declined'
en[keys.TEXT_APPROVED] = 'Approved'

en[keys.TEXT_HAS_COMMENT] = 'Has comment'
en[keys.TEXT_HAS_CHANGE] = 'Has change'
en[keys.TEXT_HAS_METRICS_CHANGE] = 'Has metrics change'
en[keys.TEXT_UNASSIGNED] = 'Unassigned'
en[keys.TEXT_REJECTED_BY_THE_TRIBUNAL] = 'Rejected by the tribunal'
en[keys.TEXT_REASON_FOR_FAILED_TAXATION] = 'Reason for declined evaluation'

en[keys.TEXT_PLANNED] = 'Planned'
en[keys.TEXT_STARTED] = 'Started'
en[keys.TEXT_COMPLETED] = 'Completed'

en[keys.TEXT_REOPEN] = 'Reopen'
en[keys.TEXT_FILTER_EVENTS] = 'Filter events'
en[keys.TEXT_FILTER_TAXATIONS] = 'Filter by address, value type...'
en[keys.TEXT_REJECTED] = 'Rejected'

en[keys.TEXT_COMMENT_NOUN] = 'Comment'
en[keys.TEXT_COMMENTS] = 'Comments'

en[keys.TEXT_ACCESS] = 'Access'
en[keys.TEXT_ACCESSES] = 'Accesses'
en[keys.TEXT_COMPANY] = 'Company'
en[keys.TEXT_ZONES] = 'Zones'
en[keys.TEXT_RIGHTS] = 'Rights'
en[keys.TEXT_ACTIVE] = 'Active'
en[keys.TEXT_INACTIVE] = 'Inactive'
en[keys.TEXT_SHOW_ACTIVE_USERS_ONLY] = 'Show active users only'

en[keys.TEXT_ADD_USER] = 'Add user'
en[keys.TEXT_REGISTER] = 'Register'
en[keys.TEXT_REPEAT_PASSWORD] = 'Repeat password'
en[keys.TEXT_PASSWORD_DOESNT_MATCH] = 'Password does not match'
en[keys.TEXT_REQUIRED] = 'Required'
en[keys.TEXT_MINIMUM_LENGTH] = 'Minumum length'
en[keys.TEXT_ADD_COMPANY] = 'Add company'
en[keys.TEXT_EDIT_COMPANY] = 'Edit company'

en[keys.TEXT_ADMINISTRATOR] = 'Administrator'
en[keys.TEXT_TAXATIONS_ADMINISTRATOR] = 'Evaluation administrator'
en[keys.TEXT_TRIBUNAL_ADMINISTRATOR] = 'Tribunal administrator'
en[keys.TEXT_TAXATIONS_USER] = 'Evaluation user'

en[keys.TEXT_UNAUTHORIZED] = 'Unauthorized'
en[keys.TEXT_FINISH_REVIEW] = 'Finish review'

en[keys.TEXT_PREVIOUS] = 'Previous'
en[keys.TEXT_NEXT] = 'Next'

en[keys.TEXT_REGULATED] = 'Regulated'

en[keys.TEXT_USER_GROUPS] = 'User groups'
en[keys.TEXT_USER_GROUP] = 'User group'
en[keys.TEXT_NEW_USER_GROUP] = 'New user group'

en[keys.TEXT_TYPE] = 'Type'
en[keys.TEXT_EVALUATION_COMPANY] = 'Evaluation company'

en[keys.TEXT_SHOWING] = 'Showing'
en[keys.TEXT_LOAD_MORE] = 'Load more'

en[keys.TEXT_POLITICAL] = 'Other'
en[keys.TEXT_CADASTRAL_ERROR] = 'Cadastral error'
en[keys.TEXT_VALUATION_ERROR] = 'Valuation error'
en[keys.TEXT_JUDGMENTAL_ERRORS] = 'Judgmental errors'
en[keys.TEXT_COMPLAINT_BOARD] = 'Complaint board'
en[keys.TEXT_COMBINED] = 'Combined'
en[keys.TEXT_ALL_COMPLAINTS] = 'All complaints'

en[keys.TEXT_CAUSE] = 'Cause'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_1] = 'Plot area is not correct'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_2] = 'Plot type is not correct'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_3] = 'The plot and buildings have been given an unreasonably good location'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_4] = 'Building has reached an unreasonably high standard'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_5] = 'Incorrect area of building'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_6] = 'Photos are from wrong property'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_7] = 'Other corrections/complaints'

en[keys.TEXT_APPLIED] = 'Tatt i bruk'
en[keys.TEXT_COMPLETION] = 'Ferdigattest'
en[keys.TEXT_TEMPORARY_USE_PERMIT] = 'Midlertidig brukstillatelse'
en[keys.TEXT_PERMISSION_TO_START] = 'Igangsettingstillatelse'
en[keys.TEXT_BUILDING_NUMBER_EXPIRED] = 'Bygningsnummer utgått'
en[keys.TEXT_NOTIFICATION_ACTION_COMPLETED] = 'Meldingssak tiltak fullført'
en[keys.TEXT_BUILDING_DEMOLISHED] = 'Bygning revet/brent'
en[keys.TEXT_NOTIFICATION_CASE_MEASURES] = 'Meldingssak registrer tiltak'
en[keys.TEXT_BUILDING_CANCELED] = 'Bygging avlyst'
en[keys.TEXT_BUILDING_APPROVED_FOR_DEMOLITION] = 'Bygning godkjent for riving/brenning'
en[keys.TEXT_OUTLINE_PLANNING_PERMISSION] = 'Rammetillatelse'
en[keys.TEXT_EXEMPT_TO_APPLY] = 'Fritatt for søknadsplikt'
en[keys.TEXT_BUILDING_MOVED] = 'Bygning flyttet'
en[keys.TEXT_MEASURES_EXEMPT] = 'Tiltak unntatt fra byggesaksbehandling'

en[keys.TEXT_FARM_AND_FORESTRY] = '§5h Gårdsbruk og skogbruk'
en[keys.TEXT_BUILDING_7C] = '§7c bygg'
en[keys.TEXT_HISTORIC_VALUE] = '§7b Bygn. med hist. verdi'
en[keys.TEXT_MUNICIPAL_PROPERTY] = '§5d Kommunal eiend.'
en[keys.TEXT_STATE_PROPERTY] = '§5a Statlig eiend.'
en[keys.TEXT_INSTITUTIONS] = '§7a Stiftelser og institusjoner'
en[keys.TEXT_HEALTH_INSTITUTIONS] = '§5g Helseforetak'
en[keys.TEXT_FIVE_H] = '§5h'
en[keys.TEXT_CHURCHES] = '§5c Kirker'
en[keys.TEXT_BUILDING_PERMIT] = 'Byggetillatelse?'
en[keys.TEXT_RAILWAY_PROPERTIES] = '§5b Jernbaneeiendommer'
en[keys.TEXT_SEVEN_D] = '§7d'
en[keys.TEXT_SECTION_28] = '§28-fritak'
en[keys.TEXT_FIVE_J] = '§5j'
en[keys.TEXT_FIVE_K] = '§5k'
en[keys.TEXT_COMPLAINT] = 'Complaint'
en[keys.TEXT_BUILDING_PHOTOS] = 'Photos from wrong property'
en[keys.TEXT_BUILDING_PHOTOS_LABEL] = 'The following pictures are from the wrong property'
en[keys.TEXT_DECISION] = 'Vedtak'

en[keys.TEXT_SEND_TO_APPRAISER] = 'Send to appraiser'
en[keys.TEXT_COMPLAINT_FOLLOWED] = 'Complaint followed'
en[keys.TEXT_COMPLAINT_NOT_FOLLOWED] = 'Complaint not followed'
en[keys.TEXT_COMPLAINT_PARTIALLY_FOLLOWED] = 'Complaint partially followed'
en[keys.TEXT_COMMENT_DESCRIPTION] = 'Comment / description'
en[keys.TEXT_GROUNDS] = 'Grounds'
en[keys.TEXT_DOCUMENTATION] = 'Documentation'
en[keys.TEXT_BUILDING] = 'Building'
en[keys.TEXT_TAXATION_PLOT_TYPE] = 'Taxation plot type'
en[keys.TEXT_CORRECT_PLOT_TYPE] = 'Correct plot type'
en[keys.TEXT_ADJUSTED] = 'Adjusted'
en[keys.TEXT_READJUSTED] = 'Readjusted'
en[keys.TEXT_DIFFERENCE] = 'Difference'
en[keys.TEXT_ORIGINAL_TAXATION] = 'Original taxation'
en[keys.TEXT_UPDATED_TAXATION] = 'After adjustements'
en[keys.TEXT_TAXATION_AREA] = 'Taxation area'
en[keys.TEXT_CORRECTED_AREA] = 'Corrected area'
en[keys.TEXT_ADJUSTED_AREA] = 'Adjusted area'

en[keys.TEXT_REPORTS] = 'Reports'
en[keys.TEXT_EXPORT_TO_EXCEL] = 'Export to excel'
en[keys.TEXT_SIMULATION] = 'Simulation'
en[keys.TEXT_RUN_SIMULATION] = 'Run simulation'
en[keys.TEXT_MUNICIPALITY_ADJUSTMENT] = 'Municipality adjustment'
en[keys.TEXT_ADJUSTMENT_PERCENTAGE] = 'Adjustment percentage'
en[keys.TEXT_USE_NATIONAL_SETTINGS] = 'Use national settings'
en[keys.TEXT_USES_NATIONAL_SETTINGS] = 'Uses national valuation'
en[keys.TEXT_DOES_NOT_USE_NATIONAL_SETTINGS] = 'Does not use national valuation'
en[keys.TEXT_MUNICIPALITY_OWNER] = 'Municipality owner'
en[keys.TEXT_MINIMUM_TAX] = 'Minimum tax'
en[keys.TEXT_PROPERTY_TYPE] = 'Property type'
en[keys.TEXT_PROPERTY_TYPE_OPTION_A] = 'a) All property in the municipality'
en[keys.TEXT_PROPERTY_TYPE_OPTION_B] = 'b) Properties within given zones that are built up in a city like demeanour'
en[keys.TEXT_PROPERTY_TYPE_OPTION_C] = 'c) Only powerproduction'
en[keys.TEXT_PROPERTY_TYPE_OPTION_D] = 'd) Businesses and powerproduction'
en[keys.TEXT_PROPERTY_TYPE_OPTION_E] = 'e) b and c'
en[keys.TEXT_PROPERTY_TYPE_OPTION_F] = 'f) b and d'
en[keys.TEXT_PROPERTY_TYPE_OPTION_G] = 'g) All property except businesses and powerproduction'
en[keys.TEXT_RATES] = 'Rates'
en[keys.TEXT_RATES_OPTION_1] = 'Use different rates for property parts used for dwelling'
en[keys.TEXT_RATES_OPTION_2] = 'Use different rates for properties that have no buildings on them'
en[keys.TEXT_RATES_OPTION_3] = 'Use separate rates for property land and buildings'
en[keys.TEXT_RATES_OPTION_4] = 'Use different rates for different zones'
en[keys.TEXT_DEFAULT_TAX_RATE] = 'Default tax rate'
en[keys.TEXT_TAX_PER_ZONE] = 'Tax rate per zone'
en[keys.TEXT_DWELLING_TAX_RATE] = 'Dwelling tax rate'
en[keys.TEXT_PROPERTIES_WITH_BUILDINGS] = 'Properties with buildings'
en[keys.TEXT_PROPERTIES_WITHOUT_BUILDINGS] = 'Properties without buildings'
en[keys.TEXT_BUILDINGS_TAX_RATE] = 'Buildings tax rate'
en[keys.TEXT_LAND_TAX_RATE] = 'Land tax rate'
// eslint-disable-next-line
en[keys.TEXT_BETWEEN_SOMETHING_AND] = 'Between ${params[0]} and ${params[1]}'
en[keys.TEXT_REDUCTION_FACTOR] = 'Reduction factor'
en[keys.TEXT_FLAT_REDUCTION] = 'Use flat tax reduction'
en[keys.TEXT_FLAT_REDUCTION_VALUE] = 'Flat tax reduction value'
// eslint-disable-next-line
en[keys.TEXT_MINIMUM_SOMETHING] = 'Minimum ${params[0]}'
// eslint-disable-next-line
en[keys.TEXT_SOMETHING_EXEMPTION] = '${params[0]} exemption'
en[keys.TEXT_EXEMPTION_OPTION_A] = 'a) Properties that is owned by institutions that are beneficial to the government'
en[keys.TEXT_EXEMPTION_OPTION_B] = 'b) Buildings of historic value'
en[keys.TEXT_EXEMPTION_OPTION_C] = 'c) New buildings x years after they are built'
en[keys.TEXT_EXEMPTION_OPTION_D] = 'd) Buildings and land in certain areas of the municipality'
en[keys.TEXT_EXEMPTION_OPTION_E] = 'e) Summer houses'
en[keys.TEXT_YEARS] = 'years'
en[keys.TEXT_REMOVE] = 'Remove'
en[keys.TEXT_TAX_SETTINGS] = 'Tax settings'
en[keys.TEXT_ADD_USAGE_TYPE] = 'ADD USAGE'
en[keys.TEXT_FACTOR] = 'Factor'
en[keys.TEXT_ADD_FACTOR] = 'ADD FACTOR'
en[keys.TEXT_CHOICE] = 'Choice'
en[keys.TEXT_FACTORS] = 'Factors'
en[keys.TEXT_DONT_USE_FLOOR_FACTOR] = "Don't use floor factor"
en[keys.TEXT_BUILDING_USE_TYPE] = 'Building use type'
en[keys.TEXT_ERROR_OCCURRED] = 'An unexpected error occurred'
en[keys.TEXT_MIN_BUILDING_AREA] = 'Minimum building area'
en[keys.TEXT_FARM] = 'Farm'
en[keys.TEXT_PLOT_AS_DWELLING] = 'Plot calculated as living space'
en[keys.TEXT_MAX_HOUSING_SIZE] = 'Maximum housing size'
en[keys.TEXT_ATTACHMENT_PLOT_VALUE] = 'Attachment plot value'
en[keys.TEXT_POINTLESS_SIZE] = 'Pointless size'
en[keys.TEXT_UNKNOWN_PLOT_SIZE] = 'Unknown plot size'
en[keys.TEXT_VALUE_PER_PARKING] = 'Value per parking space'
en[keys.TEXT_NUMBER_OF_TERMS] = 'Number of terms'
en[keys.TEXT_USE_FLOOR_FACTOR] = 'Use floor factors'
en[keys.TEXT_USE_AREA_FACTOR] = 'Use area factors'
en[keys.TEXT_FROM_AREA] = 'From area'
en[keys.TEXT_TO_AREA] = 'To area'
en[keys.TEXT_SAVE_CHANGES] = 'Save changes'
en[keys.TEXT_COMPLETE_TAX_YEAR] = 'Complete tax year'
en[keys.TEXT_SETTINGS_CHANGE_COMMENT] = 'Reason for change'
en[keys.TEXT_SETTINGS_TAKE_EFFECT] = 'When do the settings take effect?'
en[keys.TEXT_SAVE_TAXATION_SETTINGS] = 'Save taxation settings'
en[keys.TEXT_VALID_FROM] = 'Valid from'
en[keys.TEXT_POWERPLANTS_AND_PETROLIUM] =
    'Power plants, wind power plants, the power grid and plants covered by special tax rules for petroleum'
en[keys.TEXT_BENEFICIAL_TO_MUNICIPALITY] =
    'Properties owned by foundations or institutions that benefit the municipality, county or state'
en[keys.TEXT_USED_FOR_PARKING] = 'Is the plot used for parking activities'
en[keys.TEXT_PARKING_SPACES] = 'Number of parking spaces'
en[keys.TEXT_YEAR] = 'Year'
en[keys.TEXT_MUNICIPALITY_ADJUSTED] = 'Municipality adjustment'
en[keys.TEXT_GENERAL_RATE] = 'General rate'
en[keys.TEXT_OTHER_RATES] = 'Other rates'
en[keys.TEXT_FLOOR_USE] = 'Floor use'
en[keys.TEXT_HOUSING_TAX] = 'housing tax'
en[keys.TEXT_PERCENTAGE_SHARE] = 'Percentage'
en[keys.TEXT_PLOT] = 'Plot'
en[keys.TEXT_LIVING] = 'Living'
en[keys.TEXT_ORDINARY] = 'Ordinary'
en[keys.TEXT_ORDINARY_NORMAL] = 'Ordinary / normal'
en[keys.TEXT_TAX_RATE] = 'Tax rate'
en[keys.TEXT_BUILDING_DELETED_UNDO] = 'Building was deleted. Undo?'
en[keys.TEXT_LETTER_TEMPLATES] = 'Letter templates'
en[keys.TEXT_TEXTS] = 'Texts'
en[keys.TEXT_CASE_DOCUMENTS] = 'Case documents'
en[keys.TEXT_EMAILS] = 'Emails'
en[keys.TEXT_SMS] = 'SMS'
en[keys.TEXT_TEMPLATES] = 'Templates'
en[keys.TEXT_TITLE] = 'Title'
en[keys.TEXT_PERSONAL_NR] = 'Personal/org number'
en[keys.TEXT_ADD_SMS] = 'ADD SMS'
en[keys.TEXT_ADD_EMAIL] = 'ADD EMAIL'
en[keys.TEXT_ADD_TEXT] = 'ADD TEXT'
en[keys.TEXT_ADD_CASE_DOCUMENT] = 'ADD CASE DOCUMENT'
en[keys.TEXT_ADD_DOCUMENT_TEMPLATE] = 'ADD DOCUMENT TEMPLATE'
en[keys.TEXT_DOCUMENT_TEMPLATES] = 'Templates'
en[keys.TEXT_FLOW] = 'Flow'
en[keys.TEXT_COMPANY2] = 'Comapny'
en[keys.TEXT_TEMPLATE] = 'Template'
en[keys.TEXT_ERROR_HEADER] = 'Error header'
en[keys.TEXT_ERROR_DESCRIPTION] = 'Error description'
en[keys.TEXT_REMARK_HEADER] = 'Remark header'
en[keys.TEXT_REMARK_DESCRIPTION] = 'Remark description'
en[keys.TEXT_GENERAL_REMARK] = 'General remark'
en[keys.TEXT_TEFT_DESCRIPTION] = 'Teft description'
en[keys.TEXT_COPY_RECIPIENTS] = 'Copy recipients'
en[keys.TEXT_CONTACT_DEFINED] = 'Contact defined'
en[keys.TEXT_CONTACT_NOT_DEFINED] = 'Contact not defined'
en[keys.TEXT_TEMPLATE_TEXT] = 'Template text'
en[keys.TEXT_ALWAYS_AFTER] = 'Always after'
en[keys.TEXT_ALWAYS_BEFORE] = 'Always before'
en[keys.TEXT_NOT_INSIDE] = 'Not inside'

en[keys.TEXT_GRID_OWNER_LOGO] = 'Grid owner logo'
en[keys.TEXT_POST_ADDRESS] = 'Postal Address'
en[keys.TEXT_HEADLINE] = 'Headline'
en[keys.TEXT_SUMMARY] = 'Summary'
en[keys.TEXT_RETURN_POST_ADDRESS] = 'Return post address'
en[keys.TEXT_HORIZONTAL_LINE] = 'Horizontal line'
en[keys.TEXT_DELETE_ELEMENT] = 'Delete element'
en[keys.TEXT_DESIGN_ELEMENT] = 'Design element'
en[keys.TEXT_HEIGHT_IN_MM] = 'Height in mm'
en[keys.TEXT_FOOTER_HEIGHT_IN_MM] = 'Footer height in mm'
en[keys.TEXT_OFFSET_TOP_IN_MM] = 'Top offset in mm'
en[keys.TEXT_OFFSET_LEFT_IN_MM] = 'Left offset in mm'
en[keys.TEXT_WIDTH_IN_MM] = 'Width in mm'
en[keys.TEXT_LETTER_CONTENT] = 'Letter content'
en[keys.TEXT_PREVIEW] = 'Preview'
en[keys.TEXT_TEXT_ELEMENTS] = 'Text elements'

en[keys.TEXT_BUILDING_BUILT] = 'Building built'
en[keys.TEXT_HAS_PARKING] = 'The plot is used for parking activities'
en[keys.TEXT_VALUE_UNDER] = 'Value under'
en[keys.TEXT_VALUE_OVER] = 'Value over'
en[keys.TEXT_TAXATIONS] = 'Taxations'
en[keys.TEXT_FINISHED] = 'Finished'
en[keys.TEXT_SENT_OUT] = 'Sent out'
en[keys.TEXT_OPEN_COMPLAINT] = 'OPEN A COMPLAINT'
en[keys.TEXT_NO_COMPLAINTS_HEADLINE] = 'No complaints received for this assessment'
en[keys.TEXT_DOCUMENT_RECEIVER] = 'Document receiver'
en[keys.TEXT_INVOICE_RECEIVER] = 'Invoice receiver'
en[keys.TEXT_ADD_DOCUMENT_RECEIVER] = 'Add document receiver'
en[keys.TEXT_SEND_LAST_ASSESSMENT] = 'SEND LAST ASSESSMENT'
en[keys.TEXT_COMMENT] = 'Comment'
en[keys.TEXT_SEND_TO_NEMND] = 'SEND TO NEMND'
en[keys.TEXT_CREATED] = 'Created'
en[keys.TEXT_COMPLAINTS_TRIBUNAL] = 'Complaints tribunal'
en[keys.TEXT_DESCRIPTION] = 'Description'
en[keys.TEXT_OFFICE_ADJUSTMENT_TAX] = 'Office adjustment'
en[keys.TEXT_TAXATION_TYPE] = 'Taxation type'
en[keys.TEXT_CHANGE] = 'Adjustment'
en[keys.TEXT_UPLOAD_IMAGE] = 'Upload image'
en[keys.TEXT_UPLOAD_DOCUMENT] = 'Upload document'
en[keys.TEXT_UPLOAD] = 'Upload'
en[keys.TEXT_DOCUMENTS] = 'Documents'

en[keys.TEXT_VIEW_ONLY] = 'Vew only'
en[keys.TEXT_REPORTS_USER] = 'Reports user'
en[keys.TEXT_TAXATION_PLANNER] = 'Taxation planner'
en[keys.TEXT_TAXATION_PEOPLE] = 'Taxation people'
en[keys.TEXT_NEMND_TRIBUNAL] = 'NEMND tribunal'
en[keys.TEXT_CASE_WORKER] = 'Case worker'

en[keys.TEXT_MYSELF] = 'Myself'
en[keys.TEXT_WHO] = 'Who'

en[keys.TEXT_TAXATION_METHOD] = 'Taxation method'
en[keys.TEXT_TAXATION_CASE_WORKER] = 'Case worker'
en[keys.TEXT_INSECTION_DATE] = 'Inspection date'
en[keys.TEXT_SENT_BY] = 'Sent by'
en[keys.TEXT_EMAIL] = 'email'
en[keys.TEXT_LETTER] = 'letter'

en[keys.TEXT_CASE_FLOW] = 'Case flow'

en[keys.TEXT_NOTICE] = 'Notice'
en[keys.TEXT_CHANNEL] = 'Channel'
en[keys.TEXT_PAPER_LETTER] = 'Letter'
en[keys.TEXT_TRIGGER] = 'Trigger'
en[keys.TEXT_CASE_DOCUMENT] = 'Case document'
en[keys.TEXT_DAYS] = 'days'
en[keys.TEXT_UNTIL_TAXATION] = 'until taxation'
en[keys.TEXT_AFTER_APPROVED_BY_TRIBUNAL] = 'after approved by tribunal'
en[keys.TEXT_AFTER_COMPLAINT_RECEIVED] = 'after complaint received'
en[keys.TEXT_AFTER_COMPLAINT_APPROVED] = 'after complaint approved'
en[keys.TEXT_AFTER_COMPLAINT_REJECTED] = 'after complaint rejected'
en[keys.TEXT_AFTER_NEW_TAX] = 'after new tax'
en[keys.TEXT_AFTER_OWNERSHIP_CHANGE] = 'after ownership change'
en[keys.TEXT_AFTER_APPLICATION_RECEIVED] = 'after application received'

en[keys.TEXT_COMMUNICATION] = 'Communication'
en[keys.TEXT_RECIPIENT] = 'Recipient'
en[keys.TEXT_COPY_RECIPIENT] = 'Copy recipient'
en[keys.TEXT_SEND_EMAIL] = 'SEND EMAIL'
en[keys.TEXT_SEND_SMS] = 'SEND SMS'
en[keys.TEXT_SEND_LETTER] = 'SEND LETTER'
en[keys.TEXT_ADD_RECIPIENT_EMAIL] = 'Add recipient email'
en[keys.TEXT_ADD_RECIPIENT_PHONENUMBER] = 'Add recipient phonenumber'
en[keys.TEXT_SENT] = 'Sent'
en[keys.TEXT_SENDER] = 'Sender'
en[keys.TEXT_SUBJECT] = 'Subject'
en[keys.TEXT_FORWARDED] = 'Forwarded'
en[keys.TEXT_WROTE] = 'wrote'
en[keys.TEXT_REPLY] = 'Reply'
en[keys.TEXT_ATTACH_FILE_FROM_COMPUTER] = 'Attach file'
en[keys.TEXT_ATTACH_CASE_DOCUMENT] = 'Attach case document'
en[keys.TEXT_SEARCH_FOR_LETTERS] = 'Search for letters, emails, text messages'
en[keys.TEXT_INBOUND] = 'Incoming'
en[keys.TEXT_OUTBOUND] = 'Outgoing'

en[keys.TEXT_NEW_EMAIL] = 'New email'
en[keys.TEXT_NEW_SMS] = 'New SMS'
en[keys.TEXT_NEW_LETTER] = 'New letter'
en[keys.TEXT_REGISTER_PHONECALL] = 'Register phone call'
en[keys.TEXT_NEW_COMMUNICATION] = 'New communication'
en[keys.TEXT_FORWARD] = 'Forward'
en[keys.TEXT_REPLY_ALL] = 'Reply all'
en[keys.TEXT_COPY] = 'COPY'

en[keys.TEXT_ATTACHMENT_PLOT] = 'Lease attachment'
en[keys.TEXT_TOTAL_PLOT_VALUE] = 'Total plot value'
en[keys.TEXT_PROPERTY_VALUE_BEFORE_FACTORS] = 'Property value before factors'
en[keys.TEXT_OFFICE_ADJUSTMENT] = 'Office adjustment'
en[keys.TEXT_NOTIFICATION_SENT] = 'Notification sent'
en[keys.TEXT_ROUTE] = 'Route'
en[keys.TEXT_BUILDING_CODE] = 'Building code'
en[keys.TEXT_SEND_TAXATION_NOTICE] = 'Send taxation notice'
en[keys.TEXT_MODIFYING_FACTORS] = 'Modifying factors'

en[keys.TEXT_UNASSIGN] = 'Unassign'
en[keys.TEXT_MANUAL_VALUE] = 'Manual value'
en[keys.TEXT_SEND_BUSINESS_TAXATION_NOTICE] = 'Send business taxation notice'
en[keys.TEXT_SEND_BUSINESS_TAXATION_NOTICE2] = 'Send business taxation notice'
en[keys.TEXT_ATTRIBUTES] = 'Attributes'
en[keys.TEXT_NO_ATTRIBUTES] = 'No attributes'
en[keys.TEXT_NEW_ATTRIBUTE] = 'New attribute'
en[keys.TEXT_SAVE_NEW_ATTRIBUTE] = 'Save new attribute'
en[keys.TEXT_EDIT_ATTRIBUTE] = 'Edit attribute'
en[keys.TEXT_EXIT_EDIT_ATTRIBUTE] = 'Cancel editing'

en[keys.TEXT_SEND_NEW_TAX] = 'Send new taxation'
en[keys.TEXT_GROUPS] = 'groups'
en[keys.TEXT_SELECT_TENANT] = 'Select tenant'

en[keys.TEXT_DELETED] = 'Deleted'
en[keys.TEXT_SEND_YEAR_TAX] = 'Send tax letter'
en[keys.TEXT_YEAR_TAX_SENT] = 'Tax letter sent'
en[keys.TEXT_YEAR_TAX_NOT_SENT] = 'Tax letter not sent'
en[keys.TEXT_TAX_LETTER_PREVIEW] = 'Tax letter preview'
en[keys.TEXT_DETAILED_TAX_LETTER] = 'Send detailed year tax'
en[keys.TEXT_RESIDENTIAL_UNITS] = 'Residential units'
en[keys.TEXT_FAILED_CORRESPONDENCE] = 'Failed correspondence'
en[keys.TEXT_ISSUE] = 'Issue'
en[keys.TEXT_CALCULATE] = 'Recalculate the included properties'
en[keys.TEXT_MATRIKKEL_CHANGED] = 'Registry data changed'
