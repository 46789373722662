import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import {
    Divider,
    makeStyles,
    Theme,
    Table,
    TableBody,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Button,
    Card,
} from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import {
    TEXT_EXEMPTION,
    TEXT_METRICS_STATUS,
    TEXT_NO,
    TEXT_NOTE,
    TEXT_STANDARD_FACTOR,
    TEXT_FLOOR,
    TEXT_USE,
    TEXT_FLOOR_FACTOR,
    TEXT_M2_PRICE,
    TEXT_VALUE,
    TEXT_IMAGES,
    TEXT_CANCEL,
    TEXT_SAVE,
} from 'utils/translations/keys'
import { useSelector, useDispatch } from 'react-redux'
// @ts-ignore
import Lightbox from 'react-lightbox-component'
import 'react-lightbox-component/build/css/index.css'
import { AppState } from 'app/app-store'
import { getStandardFactorTranslationKey, formatCurrency, formatArea } from 'utils'
import { CubitTableHeader } from 'shared-components/src/cubit-table/cubit-table-header'
import tableStyles from 'shared-components/src/cubit-table/cubit-table.module.css'
import { CubitTableHeaderProps } from 'shared-components/src/cubit-table/cubit-table.types'
import { Floor } from 'models/floor'
import { LightboxImage } from 'models/lightbox-image'
import './tribunal-taxation-details.css'
import classes from 'shared-components/src/cubit-table/cubit-table.module.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getFormValues, submit, reset, isPristine, isInvalid, FieldArray } from 'redux-form'
import { Taxation } from 'models/taxation'
import { get } from 'lodash'
import { Edit } from '@material-ui/icons'
import { Permission } from 'common/enums/permission'
import { Protected } from 'common/protected/protected'
import { Building } from 'models/building'
import { TribunalTaxationBuildingFloors } from './tribunal-taxation-building-floors'
import { TribunalTaxationComments } from 'tribunal-taxation-details-page/tribunal-taxation-comments/tribunal-taxation-comments'
import { TaxSettingsState } from 'settings-page/settings-reducer'
import { TaxationPropertyStandardFactors } from 'property-taxation-edit-page/property-taxation-building-edit/property-taxation-standardFactor-fields'
import { TaxationStandardOtherFactors } from 'property-taxation-edit-page/property-taxation-building-edit/property-taxation-standardFactor-otherFactors'
import { TaxationOtherFactorsList } from 'property-taxation-edit-page/property-taxation-edit/property-taxation-locationFactor-otherFactors-list'
import { getFactor } from 'property-page/taxations-history/functions'
import { simpleFormatNumber } from 'shared-components/src/utils/format/format-number'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panel: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(2),
            boxShadow: theme.shadows[2],
            borderRadius: 0,
        },
        panelContent: {
            fontSize: theme.spacing(2),
            flexDirection: 'column',
        },
        panelSummary: {
            height: theme.spacing(7),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },

        panelContent__label: {
            fontWeight: 500,
        },

        tableWrapper: {
            margin: '0 -16px',
            width: 'calc(100% + 48px)',
            maxWidth: 'calc(100% + 48px)',
            flexBasis: 'auto',
        },

        button: {
            color: theme.palette.grey[400],
        },
        cancelButton: {
            marginRight: theme.spacing(2),
        },

        cameraIcon: {
            marginRight: theme.spacing(1),
        },

        marginBottom: {
            marginBottom: theme.spacing(2),
        },

        tableCell: {
            padding: '8px 40px 8px 16px',
            fontSize: '0.875rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
            verticalAlign: 'inherit',
        },
        Accordion: {
            margin: theme.spacing(0, -3),
            border: 0,
            boxShadow: 'none',
            '&.Mui-expanded': {
                margin: theme.spacing(0, -3),
            },
            '&:before': {
                display: 'none',
            },
        },
        summaryRoot: {
            '&.Mui-expanded': {
                minHeight: '32px',
            },
        },
        summaryContent: {
            '&.Mui-expanded': {
                margin: theme.spacing(0),
            },
        },
        tableContainer: {
            minWidth: 800
        }
    }
})

type TribunalTaxationDetailsBuildingProps = {
    buildingFieldName: string
    form: string
    buildingIndex: number
}

export const TribunalTaxationDetailsBuilding: React.FC<TribunalTaxationDetailsBuildingProps> = (props) => {
    const { buildingFieldName, form, buildingIndex } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const taxationSettings = useSelector((state: TaxSettingsState) => state.taxSettings.taxSettings)

    const [editing, setEditing] = useState(false)

    const taxation = useSelector(getFormValues(form)) as Taxation
    const building = get(taxation, `current.buildings[${buildingIndex}]`) as Building
    const oldBuilding = get(taxation, `old.buildings[${buildingIndex}]`) as Building

    const standardFactorHasBeenChanged = oldBuilding && building.standardFactor !== oldBuilding.standardFactor

    const buildingCodesMap = useSelector((state: AppState) => state.appData.buildingCodesMap)

    const buildingImages: LightboxImage[] = building.files.map((file: any) => ({
        src: file.url,
        title: ' ',
        description: ' ',
    }))

    const tableHeaderProps: CubitTableHeaderProps = {
        columns: [
            {
                headerLabel: tr(TEXT_FLOOR),
                key: 'floorNumber',
            },
            {
                headerLabel: tr(TEXT_USE),
                key: 'usage',
            },
            {
                headerLabel: tr(TEXT_FLOOR_FACTOR),
                key: 'usageFactor',
            },
            {
                headerLabel: tr(TEXT_M2_PRICE),
                key: 'pricePerM2',
            },
            {
                headerLabel: 'BRA',
                key: 'area',
            },
            {
                headerLabel: tr(TEXT_VALUE),
                key: 'value',
                align: 'right',
            },
        ],
    }

    const isFormPristine = useSelector(isPristine(form))
    const isFormInvalid = useSelector(isInvalid(form))

    const submitForm = () => {
        dispatch(submit(form))
        setEditing(false)
    }

    const handleCancelEdit = () => {
        dispatch(reset(form))
        setEditing(false)
    }

    return (
        <Grid container className={styles.tableContainer}>
            <Grid item lg={9} md={12} xs={12}>
                <Card className={styles.panel}>
                    <Typography variant="h6">
                        <Grid container justifyContent="space-between" alignItems="center" className="mb-1">
                            <Grid item>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>{building.buildingNumber}</Grid>
                                    <Grid item>{building.buildingCode}</Grid>
                                    <Grid item>{buildingCodesMap[building.buildingCode]}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                {!editing && (
                                    <Protected p={Permission.ViewTribunalAll}>
                                        <Grid item>
                                            <IconButton size="small" onClick={() => setEditing(true)} color="inherit">
                                                <Edit />
                                            </IconButton>
                                        </Grid>
                                    </Protected>
                                )}
                            </Grid>
                        </Grid>
                    </Typography>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} className="bolder">
                            {tr(TEXT_METRICS_STATUS)}
                        </Grid>

                        <Grid item>{building.status}</Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} className="bolder">
                            {tr(TEXT_EXEMPTION)}
                        </Grid>

                        <Grid item>{building.exempt ? building.exemptReason : tr(TEXT_NO)}</Grid>
                    </Grid>

                    <Divider className="divider-2"></Divider>

                    <Grid container spacing={2} alignItems="center" className="bolder">
                        <Grid item xs={3}>
                            {tr(TEXT_STANDARD_FACTOR)}
                        </Grid>
                        <Grid item xs={9}>
                            {editing ? (
                                <TaxationPropertyStandardFactors
                                    editable={editing}
                                    taxation={taxation}
                                    formName={form}
                                    buildingFieldName={buildingFieldName}
                                />
                            ) : (
                                `${simpleFormatNumber(building.standardFactor)} ${getStandardFactorTranslationKey(
                                    building.standardFactor,
                                    taxationSettings.standardFactors,
                                )}`
                            )}
                        </Grid>
                    </Grid>

                    {editing && standardFactorHasBeenChanged ? (
                        <TaxationStandardOtherFactors
                            taxation={taxation}
                            editable={editing}
                            formName={form}
                            buildingFieldName={buildingFieldName}
                        />
                    ) : (
                        standardFactorHasBeenChanged && (
                            <TaxationOtherFactorsList factors={building.otherStandardFactors} />
                        )
                    )}

                    {editing ? (
                        <FieldArray
                            name={`${buildingFieldName}.floors`}
                            component={TribunalTaxationBuildingFloors}
                            form={form}
                        />
                    ) : (
                        <>
                            {building.floors && building.floors.length > 0 && <Divider className="divider-2"></Divider>}

                            {building.floors && building.floors.length > 0 && (
                                <Grid container>
                                    <Grid item xs={12} className={styles.tableWrapper}>
                                        <Table className="tablePadding24">
                                            <CubitTableHeader {...tableHeaderProps} />
                                            <TableBody>
                                                {building.floors
                                                    .filter(
                                                        (f) =>
                                                            !f.matrikkelUnitId ||
                                                            f.matrikkelUnitId === taxation.propertyId,
                                                    )
                                                    .map((floor: Floor, index: number) => (
                                                        <tr key={index} className={tableStyles.tableRow}>
                                                            <td className={styles.tableCell}>{floor.floorNumber}</td>
                                                            <td className={styles.tableCell}>{floor.usage}</td>
                                                            <td className={styles.tableCell}>
                                                                {getFactor(floor.usageFactor)}
                                                            </td>
                                                            <td className={styles.tableCell}>{floor.pricePerM2}</td>
                                                            <td className={styles.tableCell}>
                                                                {formatArea(floor.area)}
                                                            </td>
                                                            <td className={`${styles.tableCell}  textRight`}>
                                                                {formatCurrency(floor.value)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}

                    {building.comment && (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item className="bolder">
                                {tr(TEXT_NOTE)}:
                            </Grid>
                            <Grid item>{building.comment}</Grid>
                        </Grid>
                    )}
                    {building.taxInfo?.unitsComment && (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item className="bolder">
                                {tr(TEXT_NOTE)}:
                            </Grid>
                            <Grid item>{building.taxInfo?.unitsComment}</Grid>
                        </Grid>
                    )}
                    {building.taxInfo?.propertyStatusComment && (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item className="bolder">
                                {tr(TEXT_NOTE)}:
                            </Grid>
                            <Grid item>{building.taxInfo?.propertyStatusComment}</Grid>
                        </Grid>
                    )}

                    {buildingImages.length > 0 &&
                        (building.comment || !building.floors || building.floors.length === 0) && (
                            <Divider className="divider-2"></Divider>
                        )}

                    {buildingImages.length > 0 && (
                        <Accordion classes={{ root: styles.Accordion }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                classes={{ root: styles.summaryRoot, content: styles.summaryContent }}
                            >
                                <Typography className={classes.heading}>{`${buildingImages.length} ${tr(
                                    TEXT_IMAGES,
                                )}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} className={styles.marginBottom}>
                                    <Grid item xs={12}>
                                        <Lightbox
                                            images={buildingImages}
                                            thumbnailWidth="384px"
                                            thumbnailHeight="216px"
                                            renderImageFunc={(
                                                idx: number,
                                                image: LightboxImage,
                                                toggleLightbox: any,
                                                width: string,
                                                height: string,
                                            ) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        className="lightbox-img-thumbnail  lightbox-img-thumbnail--cubit"
                                                        style={{
                                                            backgroundImage: `url(${image.src})`,
                                                            width: width,
                                                            height: height,
                                                        }}
                                                        onClick={toggleLightbox.bind(null, idx)}
                                                    ></div>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}

                    {editing && (
                        <>
                            <Divider className="divider-2"></Divider>

                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <Button onClick={handleCancelEdit}>{tr(TEXT_CANCEL)}</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                        disabled={isFormPristine || isFormInvalid}
                                    >
                                        {tr(TEXT_SAVE)}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Card>
            </Grid>
            <Grid item lg={3} md={12} xs={12}>
                <TribunalTaxationComments
                    formName={form}
                    fieldName={buildingFieldName}
                    commentsFieldName="tribunalComments"
                ></TribunalTaxationComments>
            </Grid>
        </Grid>
    )
}
