import { tr } from "utils/translations/translate"
import * as t from 'utils/translations/keys'
import { TextTemplateTypes } from "common/enums/document-types"
import { SelectOptions } from "shared-components/src/cubit-inputs/cubit-form-field.types"
import { DocumentTemplate } from "models/document-template"

export const typeOptions = [
    {
        label: tr(t.TEXT_NORMAL),
        value: TextTemplateTypes.NORMAL,
    },
    {
        label: tr(t.TEXT_ERROR_HEADER),
        value: TextTemplateTypes.ERROR_HEADER,
    },
    {
        label: tr(t.TEXT_ERROR_DESCRIPTION),
        value: TextTemplateTypes.ERROR_DESCRIPTION,
    },
    {
        label: tr(t.TEXT_REMARK_HEADER),
        value: TextTemplateTypes.REMARK_HEADER,
    },
    {
        label: tr(t.TEXT_REMARK_DESCRIPTION),
        value: TextTemplateTypes.REMARK_DESCRIPTION,
    },
    {
        label: tr(t.TEXT_GENERAL_REMARK),
        value: TextTemplateTypes.GENERAL_REMARK,
    },
    {
        label: tr(t.TEXT_TEFT_DESCRIPTION),
        value: TextTemplateTypes.TEFT_DESCRIPTION,
    },
    {
        label: tr(t.TEXT_COPY_RECIPIENTS),
        value: TextTemplateTypes.COPY_RECIPIENTS,
    },
    {
        label: tr(t.TEXT_CONTACT_DEFINED),
        value: TextTemplateTypes.CONTACT_DEFINED,
    },
    {
        label: tr(t.TEXT_CONTACT_NOT_DEFINED),
        value: TextTemplateTypes.CONTACT_NOT_DEFINED,
    },
    {
        label: tr(t.TEXT_TEMPLATE_TEXT),
        value: TextTemplateTypes.TEMPLATE_TEXT,
    },
]

export const flowOptions = [
    {
        label: tr(t.TEXT_NORMAL),
        value: TextTemplateTypes.NORMAL,
    },
    {
        label: tr(t.TEXT_ALWAYS_AFTER),
        value: TextTemplateTypes.ALWAYS_AFTER,
    },
    {
        label: tr(t.TEXT_ALWAYS_BEFORE),
        value: TextTemplateTypes.ALWAYS_BEFORE,
    },
    {
        label: tr(t.TEXT_NOT_INSIDE),
        value: TextTemplateTypes.NOT_INSIDE,
    },
]

export const templateOptions = (templates: DocumentTemplate[]): SelectOptions[] => {
    return templates.map((template: DocumentTemplate) => (
        {
            label: template.name,
            value: template.id
        }
    ))
}