import { applyMiddleware, combineReducers, compose, createStore, AnyAction } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { appEpics } from './app-epics'
import { navigationReducer, NavigationState } from '../navigation/navigation.reducer'
import { searchReducer, SearchState } from '../search-page/search-reducer'
import { FormStateMap, reducer as formReducer } from 'redux-form'
import { authReducer, AuthState } from '../auth/auth-reducer'
import { cubitTableReducer, CubitTableState } from 'shared-components/src/cubit-table/cubit-table-reducer'
import { settingsReducer, SettingsState } from 'shared-components/src/settings/settings-reducer'
import { PropertiesState, propertyReducer } from 'property-page/property-reducer'
import { calendarReducer, CubitCalendarState } from 'shared-components/src/cubit-calendar/calendar-recuder'
import { planningReducer, PlanningState } from '../planning-page/planning-reducer'
import { taxationReducer, TaxationState } from 'property-taxation-edit-page/taxation-reducer'
import { complaintReducer, ComplaintState } from 'complaint-page/complaint-reducer'
import { AppDataState, appReducer } from './app-reducer'
import { cubitSnackbarReducer, CubitSnackbarState } from 'common/cubit-snackbar/cubit-snackbar-reducer'
import { tribunalReducer, TribunalState } from 'tribunal-page/tribunal-reducer'
import { activityLogReducer, ActivityLogState } from '../property-page/activity-log/activity-log-reducer'
import { ACTION_AUTH_LOGOUT } from 'auth/auth-actions'
import { TaxSettingsState, taxSettingsReducer } from 'settings-page/settings-reducer'
import { attributeReducer, AttributeState } from 'common/attributes/attribute-reducer'
import { DashboardState, dashboardReducer } from 'dashboard-page/dashboard-reducer'

export type AppState = {
    appData: AppDataState
    auth: AuthState
    calendar: CubitCalendarState
    planning: PlanningState
    form: FormStateMap
    navigation: NavigationState
    search: SearchState
    settings: SettingsState
    table: CubitTableState
    properties: PropertiesState
    taxations: TaxationState
    complaints: ComplaintState
    snackBar: CubitSnackbarState
    tribunal: TribunalState
    activityLog: ActivityLogState
    taxSettings: TaxSettingsState
    attributes: AttributeState
    dashboard: DashboardState
}

const mainReducer = combineReducers<AppState>({
    appData: appReducer,
    auth: authReducer,
    calendar: calendarReducer,
    planning: planningReducer,
    form: formReducer,
    navigation: navigationReducer,
    search: searchReducer,
    settings: settingsReducer,
    table: cubitTableReducer,
    properties: propertyReducer,
    taxations: taxationReducer,
    complaints: complaintReducer,
    snackBar: cubitSnackbarReducer,
    tribunal: tribunalReducer,
    activityLog: activityLogReducer,
    taxSettings: taxSettingsReducer,
    attributes: attributeReducer,
    dashboard: dashboardReducer,
})

const rootReducer = (state: AppState, action: AnyAction) => {
    if (action.type === ACTION_AUTH_LOGOUT) {
        state = {} as AppState
    }

    return mainReducer(state, action)
}

const initialState = {}

export const createAppStore = () => {
    // @ts-ignore
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const epicMiddleware = createEpicMiddleware()
    // @ts-ignore
    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(epicMiddleware)))
    epicMiddleware.run(appEpics)
    return store
}
