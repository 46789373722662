import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCubitTableClearSelection } from 'shared-components/src/cubit-table/cubit-table-actions'
import ContextBar from 'common/context-bar/context-bar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {
    TEXT_GROUPS,
    TEXT_SELECTED,
    TEXT_SEND_NEW_TAX,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import ClearIcon from '@material-ui/icons/Clear'
import { AppState } from 'app/app-store'
import { Button, CircularProgress } from '@material-ui/core'

import {
    actionSendGroupLetters,
} from 'tribunal-page/tribunal-actions'

type TribunalApprovedContextBarProps = {

}

export const TribunalApprovedContextBar: React.FC<TribunalApprovedContextBarProps> = () => {
    const dispatch = useDispatch()

    const tableName = 'approvedTribunal'

    const selectedGroupIds: string[] = useSelector(
        (state: AppState) => (state.table[tableName] && state.table[tableName].selection) || [],
    )
    const sendingLetters = useSelector((state: AppState) => state.tribunal.sendingLetters)

    const clearSelectedItemsHandler = (tableName: string) => {
        dispatch(actionCubitTableClearSelection(tableName))
    }

    const sendLetters = () => {
        dispatch(actionSendGroupLetters(selectedGroupIds, tableName))
    }

    if (selectedGroupIds.length > 0) {
        return <ContextBar>
            <Grid style={{ flexGrow: 1 }} container alignItems="center" spacing={2}>
                <Grid item>
                    <IconButton
                        onClick={() => clearSelectedItemsHandler(tableName)}
                        color="inherit"
                        edge="start"
                        aria-label="Clear"
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>
                <Grid style={{ paddingLeft: '36px' }} item xs>
                    <Typography variant="h6">
                        {`${selectedGroupIds.length} ${tr(TEXT_GROUPS)} ${tr(TEXT_SELECTED)}`.toLowerCase()}{' '}
                    </Typography>
                </Grid>

                <Grid item>
                    <Button
                        variant="outlined"
                        color="inherit"
                        type="button"
                        disabled={sendingLetters}
                        onClick={sendLetters}
                    >
                        {!sendingLetters ? tr(TEXT_SEND_NEW_TAX) : <CircularProgress />}
                    </Button>
                </Grid>


            </Grid>
        </ContextBar>
    }

    return <></>
}
