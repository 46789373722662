export enum RoleNameKey {
    Administrator = 'ADMINISTRATOR',
    TaxationsAdministrator = 'TAXATIONS_ADMINISTRATOR',
    TribunalAdministrator = 'TRIBUNAL_ADMINISTRATOR',
    TaxationsUser = 'TAXATIONS_USER',
    ViewOnly = 'VIEW_ONLY',
    ReportsUser = 'REPORTS_USER',
    TaxationPlanner = 'TAXATION_PLANNER',
    TaxationPeople = 'TAXATION_PEOPLE',
    NemndTribunal = 'NEMND_TRIBUNAL',
    CaseWorker = 'CASE_WORKER',
    BillingUser = 'BILLING_USER',
}

export const RestrictedRoles = [
    RoleNameKey.BillingUser,
    RoleNameKey.Administrator
]