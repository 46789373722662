import React from 'react'
import { Grid } from '@material-ui/core'
import {
    TEXT_ZONES,
    TEXT_TYPE,
    TEXT_MUNICIPALITY,
    TEXT_TRIBUNAL,
    TEXT_EVALUATION_COMPANY,
    TEXT_VALUE_TYPE,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { Company, CompanyType } from 'models/company'
import { getPropertyValueTypeTranslationKey } from 'utils'
import { getZoneLabel } from 'settings-page/helper'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'

export const companyTypeTranslationKeys = {
    [CompanyType.Admin]: TEXT_MUNICIPALITY,
    [CompanyType.Taxation]: TEXT_EVALUATION_COMPANY,
    [CompanyType.Tribunal]: TEXT_TRIBUNAL,
}

export const SettingsCompanyDetails: React.FC<{ company: Company }> = (props) => {
    const { company } = props

    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)

    const companyZonesNames = (company && company.zones.map((z: string) => getZoneLabel(settings, z))) || []

    const companyPropertyValueTypeNames =
        (company && company.propertyTypes.map((pt) => tr(getPropertyValueTypeTranslationKey(pt)))) || []

    return (
        <Grid item>
            {company.type && (
                <Grid container spacing={2}>
                    <Grid item>{tr(TEXT_TYPE)}:</Grid>
                    <Grid item>{tr(companyTypeTranslationKeys[company.type]) || ''}</Grid>
                </Grid>
            )}
            {companyZonesNames && companyZonesNames.length > 0 && (
                <Grid container spacing={2}>
                    <Grid item>{tr(TEXT_ZONES)}:</Grid>
                    <Grid item>{companyZonesNames.join(', ')}</Grid>
                </Grid>
            )}
            {companyPropertyValueTypeNames && companyPropertyValueTypeNames.length > 0 && (
                <Grid container spacing={2}>
                    <Grid item>{tr(TEXT_VALUE_TYPE)}:</Grid>
                    <Grid item>{companyPropertyValueTypeNames.join(', ')}</Grid>
                </Grid>
            )}
        </Grid>
    )
}
