import React from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
    Theme,
    Typography,
    Grid,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AccordionProps } from '@material-ui/core/Accordion'

const useStyles = makeStyles((theme: Theme) => {
    return {
        label: {
            fontWeight: 500,
        },
        panel: {
            boxShadow: 'none',
        },
        summaryRoot: {
            padding: theme.spacing(0, 3),
            '&.Mui-expanded': {
                minHeight: '48px',
            },
        },
        summaryContent: {
            '&.Mui-expanded': {
                margin: theme.spacing(1.5, 0),
            },
        },
        detailsRoot: {
            padding: theme.spacing(0, 3),
        },
    }
})

export const CubitExpandablePanel: React.FC<{
    label: string
    panelSummary?: JSX.Element
    AccordionProps?: AccordionProps
}> = props => {
    const { label, panelSummary, AccordionProps, children } = props
    const styles = useStyles()
    return (
        <Accordion className={styles.panel} {...AccordionProps}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ root: styles.summaryRoot, content: styles.summaryContent }}
            >
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="h6">{label}</Typography>
                    </Grid>
                    {panelSummary && (
                        <Grid item xs>
                            {panelSummary}
                        </Grid>
                    )}
                </Grid>
            </AccordionSummary>
            <AccordionDetails classes={{ root: styles.detailsRoot }}>{children}</AccordionDetails>
        </Accordion>
    )
}
