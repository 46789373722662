import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Button, IconButton, makeStyles, Theme } from '@material-ui/core'
import { getYesNoBoolOptions, tr } from 'utils/translations/translate'
import {
    TEXT_TOTAL_BEFORE_FACTOR,
    TEXT_STANDARD_FACTOR,
    TEXT_BUILDING_VALUE,
    TEXT_NOTE,
    TEXT_EXEMPTION,
    TEXT_MATRIKKEL_STATUS,
    TEXT_BUILDING_BUILT,
    TEXT_OFFICE_ADJUSTMENT,
    TEXT_CANCEL,
} from 'utils/translations/keys'
import { BuildingFloorsTable } from './building-floors-table'
import { Building, getFinalBuildingValue } from 'models/building'
import { formatCurrency, getFactorName } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { DateTime } from 'luxon'
import { Edit, Save } from '@material-ui/icons'
import { Property } from 'models/property'
import { actionBuildingSave } from 'property-page/property-actions'
import { Field, reset, submit } from 'redux-form'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { PropertyDetailsPanelForm } from 'property-page/property-details/property-details-form'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { formatPercentage } from 'year-settings-page/helper-functions'
import { getOfficeAdjustment } from 'models/year-settings'
import { getExemptReasonOptions } from 'utils/property/property-helper'
import { floorBelongsToProperty } from 'models/floor'
import { GetExemption } from 'property-page/property-details/property-get-exemption'
import { Attributes } from 'common/attributes/attributes'
import { attributeKeys } from 'common/attributes/attribute'
import { simpleFormatNumber } from 'shared-components/src/utils/format/format-number'

const useStyles = makeStyles((theme: Theme) => {
    return {
        panelContent: {
            fontSize: theme.spacing(2),
        },
        panelSummary: {
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
        },

        panelContent__label: {
            fontWeight: 500,
        },

        tableWrapper: {
            margin: '0 -16px',
            width: 'calc(100% + 48px)',
            maxWidth: 'calc(100% + 48px)',
            flexBasis: 'auto',
        },
        iconSpace: {
            width: 30,
            display: 'inline-block',
        },
        adjustLeft: {
            marginLeft: -16,
        },
        mt: {
            marginTop: 4,
        },
        semiGrouped: {
            borderRadius: '4px 4px 0 0',
            marginBottom: 2,
        },
        gap: {
            marginRight: 30,
        },
        cancelButton: {
            paddingRight: '10px',
        },
    }
})

type PropertyBuildingDetailsProps = {
    isOpened: any
    building: Building
    property: Property
    parentStyle: any
    index: number
    expanded: any
}

export const PropertyBuildingDetails: React.FC<PropertyBuildingDetailsProps> = ({
    isOpened,
    index,
    building,
    property,
    parentStyle,
    expanded,
}) => {
    const styles = useStyles()
    const [editing, setEditing] = useState(false)
    const [propertyPanelExpanded, setPropertyPanelExpanded] = useState(false)
    const dispatch = useDispatch()
    const formName = `${TaxFormName.PropertyBuildingEditPanel}-${building.id}`
    const yearSettings = useSelector((state: AppState) => state.taxSettings.yearSettings)
    const taxSettings = useSelector((state: AppState) => state.taxSettings.taxSettings)
    useEffect(() => {
        if (propertyPanelExpanded) {
            isOpened(index, `building${index}`)
        }
    }, [propertyPanelExpanded, index, isOpened])

    const startEditing = (e: React.MouseEvent) => {
        e.stopPropagation()
        setPropertyPanelExpanded(true)
        setEditing(true)
    }
    const handleFormSubmit = (values: any) => {
        setEditing(false)
        dispatch(actionBuildingSave(property.id, building, values))
    }
    const handleSave = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch(submit(formName))
    }
    const handlePanelClose = () => {
        setPropertyPanelExpanded(!propertyPanelExpanded)
        isOpened(false)
    }
    const buildingCodesMap = useSelector((state: AppState) => state.appData.buildingCodesMap)
    const handleCancelEdit = () => {
        setEditing(false)
        dispatch(reset(formName))
    }
    return (
        <PropertyDetailsPanelForm
            form={formName}
            onSubmit={handleFormSubmit}
            initialValues={{
                exempt: building.exempt,
                exemptReason: building.exemptReason,
                floors: building.floors,
            }}
        >
            <Accordion
                expanded={expanded === `building${index}`}
                square={true}
                elevation={0}
                className={styles.semiGrouped}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.panelSummary}
                    onClick={handlePanelClose}
                >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>{building.buildingNumber}</Grid>
                                <Grid item>{building.buildingCode}</Grid>
                                <Grid item>{buildingCodesMap[building.buildingCode]}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                                <GetExemption isExempt={building.exempt} exemptReason={building.exemptReason} />
                                <Grid item>{formatCurrency(getFinalBuildingValue(building, yearSettings))}</Grid>
                                <Protected p={Permission.Complaints}>
                                    <Grid item>
                                        {!editing && (
                                            <Grid item>
                                                <IconButton size="small" onClick={startEditing} color="inherit">
                                                    <Edit />
                                                </IconButton>
                                            </Grid>
                                        )}
                                        {editing && (
                                            <Grid item>
                                                <Button
                                                    className={styles.cancelButton}
                                                    onClick={handleCancelEdit}
                                                    color="inherit"
                                                >
                                                    {tr(TEXT_CANCEL)}
                                                </Button>
                                                <IconButton size="small" onClick={handleSave} color="inherit">
                                                    <Save />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Protected>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={styles.panelContent}>
                    <Grid container spacing={2} className={parentStyle.alignRight}>
                        <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                            {tr(TEXT_MATRIKKEL_STATUS)}:
                        </Grid>
                        <Grid item md={3} xs={6}>
                            {building.status}
                        </Grid>
                        <Grid item md={6} xs={12} />

                        <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                            {tr(TEXT_BUILDING_BUILT)}:
                        </Grid>
                        <Grid item md={3} xs={6}>
                            {building.builtDate ? DateTime.fromJSDate(new Date(building.builtDate)).year : ''}
                        </Grid>
                        <Grid item md={6} xs={12} className={parentStyle.mb20} />

                        <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                            {tr(TEXT_TOTAL_BEFORE_FACTOR)}:
                        </Grid>
                        <Grid item md={3} xs={6}>
                            {formatCurrency(building.valueBeforeFactors)}
                        </Grid>
                        <Grid item md={6} xs={12} />

                        <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                            {tr(TEXT_STANDARD_FACTOR)}:
                        </Grid>
                        <Grid item md={3} xs={6}>
                            {`${getFactorName(taxSettings.standardFactors, building.standardFactor)} ${
                                simpleFormatNumber(building.standardFactor)
                            }`}
                        </Grid>
                        <Grid item md={6} xs={12} />

                        <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                            {tr(TEXT_BUILDING_VALUE)}:
                        </Grid>
                        <Grid item md={3} xs={6}>
                            {formatCurrency(building.value)}
                        </Grid>
                        <Grid item md={6} xs={12} />

                        <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                            {tr(TEXT_OFFICE_ADJUSTMENT)}
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <span>
                                {formatPercentage(getOfficeAdjustment(yearSettings))}
                                <span className={styles.gap}></span>{' '}
                                {formatCurrency(getFinalBuildingValue(building, yearSettings))}
                            </span>
                        </Grid>
                        <Grid item md={6} xs={12} />

                        {building.exempt && !editing && (
                            <>
                                <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                                    {tr(TEXT_EXEMPTION)}:
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    {building.exemptReason}
                                </Grid>
                                <Grid item md={6} xs={12} />
                            </>
                        )}
                        {editing && (
                            <>
                                <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                                    {tr(TEXT_EXEMPTION)}:
                                </Grid>
                                <Grid item sm={2} xs={12} className={styles.adjustLeft}>
                                    <Field
                                        name={TaxFormFieldNames.exempt}
                                        component={CubitRadioGroupAdapter}
                                        options={getYesNoBoolOptions()}
                                        direction="row"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item md={3} xs={6} />
                                <Grid item sm={5} xs={12}>
                                    <Field
                                        component={CubitSelectAdapter}
                                        valueIsObject={false}
                                        options={getExemptReasonOptions()}
                                        name={TaxFormFieldNames.exemptReason}
                                        label={tr(TEXT_EXEMPTION)}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} className={parentStyle.panelContent__label}>
                            <Attributes
                                id={building.id}
                                attributes={building.attributes}
                                type={attributeKeys.BUILDING}
                                propertyId={property.id}
                            />
                        </Grid>

                        <Grid item xs={12} className={styles.tableWrapper}>
                            <BuildingFloorsTable
                                floors={building.floors.filter((f) => floorBelongsToProperty(f, property.id))}
                                editing={editing}
                                formName={formName}
                            />
                        </Grid>

                        <Grid item xs={12} />

                        <Grid item md={3} xs={6} className={parentStyle.panelContent__label}>
                            {tr(TEXT_NOTE)}:
                        </Grid>
                        <Grid item md={3} xs={6}>
                            {building.comment}
                        </Grid>
                        <Grid item md={6} xs={12} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </PropertyDetailsPanelForm>
    )
}
