import { Divider, Grid } from '@material-ui/core'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { Taxation } from 'models/taxation'
import {
    getAdjustedAreaDifference,
} from 'property-taxation-edit-page/helper-functions'
import React, { useState } from 'react'
import { Field } from 'redux-form'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { formatArea, formatCurrency } from 'shared-components/src/utils'
import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import { ComplaintCause } from 'tribunal-page/models/complaint-cause.type'
import { Complaint } from 'tribunal-page/models/complaint.type'
import {
    TEXT_TAXATION_AREA,
    TEXT_CORRECTED_AREA,
    TEXT_ADJUSTED_AREA,
    TEXT_DIFFERENCE,
    TEXT_ORIGINAL_TAXATION,
    TEXT_UPDATED_TAXATION,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { CauseType } from './cause-type'
import { ComplaintFiles } from './complaint-files'
import { ComplaintPanel } from './complaint-panel'

type PlotAreaComplaintProps = {
    styles: any
    cause: ComplaintCause<ComplaintCauseType.PlotArea>
    taxation: Taxation
    complaint: Complaint
    index: number
    complaintIndex: number
}

export const PlotAreaComplaint: React.FC<PlotAreaComplaintProps> = (props) => {
    const { styles, cause, taxation, complaint, index, complaintIndex } = props
    const [adjustedPlotSize, setAdjustedPlotSize] = useState<number | undefined>(undefined)
    const handlePlotSizeChange = (value: number) => {
        setAdjustedPlotSize(value)
        cause.originalPlotSize = taxation.current.property.plotSize
    }
    const causeProcessed = !!cause.state

    return (
        <ComplaintPanel styles={styles} cause={cause} taxation={taxation} complaint={complaint} index={index} complaintIndex={complaintIndex} >
            <Grid container spacing={2} className={styles.panelBox}>
                <CauseType causeType={cause.type} styles={styles} />
                <Grid container spacing={2} className={styles.differenceCalculations}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_TAXATION_AREA)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {formatArea(cause.originalPlotSize || taxation.current.property.plotSize)}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_CORRECTED_AREA)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {formatArea(cause.correctPlotSize || 0)}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_ADJUSTED_AREA)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            <Field
                                name={`complaints.[${complaintIndex}].causes[${index}].${TaxFormFieldNames.adjustedPlotSize}`}
                                component={CubitTextFieldAdapter}
                                className={styles.dashedInput}
                                InputProps={{ classes: { underline: styles.underline } }}
                                onChange={handlePlotSizeChange as any}
                                parse={(value: any) => value ? parseFloat(value) : value}
                                variant="standard"
                                type="number"
                                disabled={causeProcessed}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_DIFFERENCE)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {formatArea(
                                getAdjustedAreaDifference(
                                    taxation.current.property.plotSize,
                                    adjustedPlotSize || cause.originalPlotSize,
                                    cause.correctPlotSize,
                                ),
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={styles.divider} />
            <Grid container spacing={2} className={styles.panelBox}>
                <Grid container spacing={2} className={styles.differenceCalculations}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_ORIGINAL_TAXATION)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {formatCurrency(taxation.current.property.plotValue)}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_UPDATED_TAXATION)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {formatCurrency(0)}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} className={styles.panelContent__label}>
                            {tr(TEXT_DIFFERENCE)}:
                        </Grid>
                        <Grid item xs={12} sm={6} md={9} className={styles.panelContent_value}>
                            {formatCurrency(0)}
                        </Grid>
                    </Grid>
                </Grid>
                <ComplaintFiles cause={cause} styles={styles} />
            </Grid>
        </ComplaintPanel>
    )
}
