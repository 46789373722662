import React, { useEffect, useRef, useState } from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { useDispatch } from 'react-redux'
import { actionSettingsGroupsGet } from 'shared-components/src/settings/groups/groups-actions'
import { RouteComponentProps, navigate } from '@reach/router'
import {
    Button,
    ButtonGroup,
    Card,
    Grid,
    Grow,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Theme,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Permission } from 'common/enums/permission'
import { Protected } from 'common/protected/protected'
import { RouteNames } from 'common/enums/routeNames'
import { plantsUrl } from 'services/httpService'
import { getSessionKey } from 'utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles((theme: Theme) => ({
    category: {
        padding: theme.spacing(1, 2, 1, 3),
        marginBottom: theme.spacing(1),
        boxShadow: 'none',
    },

    categoryName: {
        cursor: 'pointer',
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 'bold',
        maxWidth: '160px',

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    group: {
        cursor: 'pointer',
        color: grey[700],

        border: '1px solid' + grey[400],
        padding: theme.spacing(0.5, 1.5),
        margin: theme.spacing(0.5, 1),

        '&:hover': {
            color: grey[900],
            border: '1px solid' + grey[600],
        },
    },
    buttonGroup: {
        cursor: 'pointer',
        color: grey[700],

        // border: '1px solid' + grey[400],
        // padding: theme.spacing(0.5, 1.5),
        margin: theme.spacing(0.5, 1),

        '&:hover': {
            color: grey[900],
            // border: '1px solid' + grey[600],
        },
    },
    groupName: {
        paddingTop: '1px',
        fontSize: theme.typography.subtitle1.fontSize,
    },
    pop: {
        marginTop: '5px',
        fontSize: theme.typography.subtitle1.fontSize,
    },
    menuItem: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    button: {
        backgroundColor: '#f0f0f0',
        color: grey[900],
        '&:hover': {
            color: grey[900],

            backgroundColor: '#f0f0f0',
        },
    },
}))

const sessionKey = getSessionKey()

export const SettingsPage: React.FC<RouteComponentProps> = () => {
    const styles = useStyles()
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const anchorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        dispatch(actionSettingsGroupsGet())
    }, [dispatch])

    const handleGroupClick = (linkTo: string) => navigate(linkTo)

    return (
        <LayoutDefault navigation={<Navigation />} pageTitle={tr(t.TEXT_TAX_SETTINGS)}>
            <Card className={styles.category}>
                <Grid container alignItems="center">
                    <Grid
                        item
                        xs={12}
                        className={styles.categoryName}
                        onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.access}`)}
                    >
                        {tr(t.TEXT_ACCESS)}
                    </Grid>
                    <Grid
                        item
                        className={styles.group}
                        onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.companies}`)}
                    >
                        <Grid container alignItems="center">
                            <Grid item className={styles.groupName}>
                                {tr(t.TEXT_USER_GROUPS)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Protected p={Permission.AdministrateUsers}>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.users}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_USERS)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Protected>
                </Grid>
            </Card>
            <Protected p={Permission.PageSettings}>
                <Card className={styles.category}>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={12}
                            className={styles.categoryName}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.yearSettings}`)}
                        >
                            {tr(t.TEXT_SETTINGS)}
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.yearSettings}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_TAX_YEAR)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.taxSettings}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_TAX_SETTINGS)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Card className={styles.category}>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={12}
                            className={styles.categoryName}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.DOCUMENT_TEMPLATES}`)}
                        >
                            {tr(t.TEXT_TEMPLATES)}
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.DOCUMENT_TEMPLATES}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_LETTER_TEMPLATES)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.TEXT}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_TEXTS)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.CASE_DOCUMENT}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_CASE_DOCUMENTS)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.EMAIL}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_EMAILS)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.SMS}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_SMS)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Card className={styles.category}>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={12}
                            className={styles.categoryName}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.CASE_FLOW}`)}
                        >
                            {tr(t.TEXT_CASE_FLOW)}
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.CASE_FLOW}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_TAXATIONS)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                <Card className={styles.category}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} className={styles.categoryName}>
                            {tr(t.TEXT_PROPERTY)}
                        </Grid>
                        <Grid item className={styles.buttonGroup}>
                            <Grid container alignItems="center">
                                <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                                    <Button
                                        className={styles.button}
                                        href={plantsUrl(`/tax/exportPropertiesPdf/${sessionKey}`)}
                                        target="_blank"
                                        variant="contained"
                                        color="primary"
                                    >
                                        PDF
                                    </Button>
                                    <Button className={styles.button} size="small" onClick={handleToggle}>
                                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </Button>
                                </ButtonGroup>
                                <Popper
                                    className={styles.pop}
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    transition
                                    disablePortal
                                    onBlur={() => setOpen(false)}
                                >
                                    {({ TransitionProps }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin: 'top',
                                            }}
                                        >
                                            <Paper>
                                                <MenuList autoFocusItem>
                                                    <MenuItem className={styles.menuItem}>
                                                        <Button
                                                            className={styles.button}
                                                            href={plantsUrl(`/tax/exportPropertiesCSV/${sessionKey}`)}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            CSV
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem className={styles.menuItem}>
                                                        <Button
                                                            className={styles.button}
                                                            href={plantsUrl(`/tax/exportExemptPropertiesCSV/${sessionKey}`)}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            {tr(t.TEXT_EXEMPTION)}
                                                        </Button>
                                                    </MenuItem>
                                                </MenuList>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                <Card className={styles.category}>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={12}
                            className={styles.categoryName}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.CASE_FLOW}`)}
                        >
                            {tr(t.TEXT_DOCUMENTS)}
                        </Grid>
                        <Grid
                            item
                            className={styles.group}
                            onClick={() => handleGroupClick(`/${RouteNames.settings}/${RouteNames.UPLOAD_DOCUMENT}`)}
                        >
                            <Grid container alignItems="center">
                                <Grid item className={styles.groupName}>
                                    {tr(t.TEXT_UPLOAD)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Protected>
        </LayoutDefault>
    )
}
