import React, { useState } from 'react'
import { Owner } from 'models/owner'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton } from '@material-ui/core'
import { TEXT_CANCEL, TEXT_OWNERS } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { PropertyDetailsPanelForm } from './property-details-form'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { useDispatch } from 'react-redux'
import { FieldArray, reset, submit } from 'redux-form'
import { Property } from 'models/property'
import { actionPropertySave } from 'property-page/property-actions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { Edit, Save } from '@material-ui/icons'
import { PropertyOwnersFieldArray } from './property-owners-field-array'

type PropertyOwnersProps = {
    owners: Owner[]
    styles: any
    property: Property
}

export const PropertyOwners: React.FC<PropertyOwnersProps> = (props) => {
    const { owners, styles, property } = props

    const [editing, setEditing] = useState(false)
    const [panelExpanded, setPanelExpanded] = useState(false)
    const dispatch = useDispatch()
    const startEditing = (e: React.MouseEvent) => {
        e.stopPropagation()
        setPanelExpanded(true)
        setEditing(true)
    }
    const handleFormSubmit = (values: any) => {
        setEditing(false)
        dispatch(actionPropertySave(property, values))
    }
    const handleSaveClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch(submit(TaxFormName.PropertyOwners))
    }
    const handleCancelEdit = () => {
        setEditing(false)
        dispatch(reset(TaxFormName.PropertyOwners))
    }
    return (
        <Accordion className={styles.semiRounded} elevation={0} square={true} expanded={panelExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={styles.panelSummary}
                onClick={() => setPanelExpanded(!panelExpanded)}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>{tr(TEXT_OWNERS)}</Grid>
                    <Protected p={Permission.PropertyEdit}>
                        {!editing && (
                            <Grid item>
                                <IconButton size="small" onClick={startEditing} color="inherit">
                                    <Edit />
                                </IconButton>
                            </Grid>
                        )}
                        {editing && (
                            <Grid item>
                                <Button className={styles.cancelButton} onClick={handleCancelEdit} color="inherit">
                                    {tr(TEXT_CANCEL)}
                                </Button>
                                <IconButton size="small" onClick={handleSaveClick} color="inherit">
                                    <Save />
                                </IconButton>
                            </Grid>
                        )}
                    </Protected>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={styles.panelContent}>
                <PropertyDetailsPanelForm
                    form={TaxFormName.PropertyOwners}
                    onSubmit={handleFormSubmit}
                    initialValues={{
                        ownerShips: property.ownerShips,
                    }}
                >
                    <FieldArray
                        name={TaxFormFieldNames.ownerships}
                        component={PropertyOwnersFieldArray}
                        styles={styles}
                        editing={editing}
                        property={property}
                        owners={owners}
                    />
                </PropertyDetailsPanelForm>
            </AccordionDetails>
        </Accordion>
    )
}
