import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    makeStyles,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
    CircularProgress,
    Chip,
    TextField,
    Theme,
    Typography,
    Grid,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { tr } from 'utils/translations/translate'
import * as keys from 'utils/translations/keys'
import { Edit } from '@material-ui/icons'
import {
    actionAttachAttributes,
    actionCreateAttribute,
    actionDeleteAttribute,
    actionGetAttributes,
} from './attribute-actions'
import { AttributeDialogProps, AttributesProps } from './attribute'
import { AppState } from 'app/app-store'

const useStyles = makeStyles((theme: Theme) => ({
    loader: {
        textAlign: 'center',
    },
    chip: {
        marginRight: 10,
        borderRadius: 2,
    },
    newAttributeContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0',
    },
    newAttributeInput: {
        flexGrow: 1,
        marginRight: 20,
    },
    label: {
        fontWeight: 500,
        marginRight: 20
    },
    info: {
        fontWeight: 400,
        lineHeight: '24px',
        marginRight: 20
    }
}))

const AttributeDialog = (props: AttributeDialogProps) => {
    const { label, entityId, type, attributes, propertyId, close, afterEdit } = props
    const classes = useStyles()
    const dispatch = useDispatch()
    const allAttributes: string[] = useSelector((state: AppState) => state.attributes[type])
    const [selectedAttributes, setSelectedAttributes] = useState(attributes || [])
    const [editMode, setEditMode] = useState(false)
    const [newAttribute, setNewAttribute] = useState('')

    useEffect(() => {
        dispatch(actionGetAttributes(type))
    }, [])

    const toggleAttribute = (a: string) => {
        const index = selectedAttributes.indexOf(a)
        if (index > -1) {
            setSelectedAttributes([...selectedAttributes.slice(0, index), ...selectedAttributes.slice(index + 1)])
        } else {
            setSelectedAttributes([...selectedAttributes, a])
        }
    }
    const handleSave = () => {
        close()
        dispatch(actionAttachAttributes(selectedAttributes, attributes, type, entityId, propertyId, afterEdit))
    }

    const handleCreate = () => {
        dispatch(actionCreateAttribute(type, newAttribute))
        setNewAttribute('')
    }

    const handleDelete = (attribute: string) => {
        dispatch(actionDeleteAttribute(type, attribute))
    }

    const anyChanged = selectedAttributes.some((sa: string) => !attributes.includes(sa)) || selectedAttributes.length !== attributes.length

    return (
        <Dialog open={true} fullWidth maxWidth="xs">
            <DialogTitle>{label}</DialogTitle>
            <DialogContent>
                {allAttributes?.length ? (
                    <List dense>
                        {allAttributes.map((a, i) =>
                            editMode ? (
                                <ListItem dense key={i} onClick={(_) => toggleAttribute(a)}>
                                    <ListItemText>{a}</ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={(_) => handleDelete(a)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ) : (
                                <ListItem dense button key={i} onClick={(_) => toggleAttribute(a)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            color="primary"
                                            edge="start"
                                            checked={selectedAttributes.includes(a)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText>{a}</ListItemText>
                                </ListItem>
                            ),
                        )}
                    </List>
                ) : (
                    <div className={classes.loader}>
                        <CircularProgress color="primary" size={24} />
                    </div>
                )}
                {editMode && (
                    <div className={classes.newAttributeContainer}>
                        <TextField
                            className={classes.newAttributeInput}
                            label={tr(keys.TEXT_NEW_ATTRIBUTE)}
                            value={newAttribute}
                            onChange={(e) => setNewAttribute(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={newAttribute.length < 3}
                            onClick={handleCreate}
                        >
                            {tr(keys.TEXT_SAVE_NEW_ATTRIBUTE)}
                        </Button>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                {!editMode && (
                    <Button color="primary" onClick={(_) => setEditMode(true)}>
                        {tr(keys.TEXT_EDIT_ATTRIBUTE)}
                    </Button>
                )}
                {editMode && (
                    <Button color="primary" onClick={(_) => setEditMode(false)}>
                        {tr(keys.TEXT_EXIT_EDIT_ATTRIBUTE)}
                    </Button>
                )}
                <Button color="primary" onClick={close}>
                    {tr(keys.TEXT_CANCEL)}
                </Button>
                <Button color="primary" variant="contained" onClick={handleSave} disabled={!anyChanged}>
                    {tr(keys.TEXT_SAVE)}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const Attributes = (props: AttributesProps) => {
    const { id, type, attributes, label = tr(keys.TEXT_ATTRIBUTES), propertyId, afterEdit } = props
    const [editAttributes, setEditAttributes] = useState(false)
    const classes = useStyles()

    return (
        <Grid container alignItems="center">
            {editAttributes && (
                <AttributeDialog
                    label={label}
                    entityId={id}
                    type={type}
                    attributes={attributes}
                    propertyId={propertyId}
                    close={() => setEditAttributes(false)}
                    afterEdit={afterEdit}
                />
            )}
            <Typography variant="body1" className={classes.label}>{label}</Typography>

            {attributes?.length ? (
                attributes.map((a: string, i: number) => (
                    <Chip key={i} size="small" className={classes.chip} label={a} color="primary" />
                ))
            ) : (
                <i className={classes.info}>{tr(keys.TEXT_NO_ATTRIBUTES)}</i>
            )}
            <IconButton size="small" onClick={() => setEditAttributes(true)}>
                <Edit />
            </IconButton>
        </Grid>
    )
}
