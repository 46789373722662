import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_CANCEL, TEXT_SAVE } from 'utils/translations/keys'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { actionUpdateCompany } from 'app/app-actions'
import { SettingsCompanyFormName, SettingsCompanyForm } from './settings-company-form'
import { Company } from 'models/company'
import { Edit } from '@material-ui/icons'

export const SettingsCompanyEdit: React.FC<{ company: Company }> = props => {
    const { company } = props
    const dispatch = useDispatch()
    const [editCompanyDialogOpen, setEditCompanyDialogOpen] = useState(false)

    const handleClose = () => {
        setEditCompanyDialogOpen(false)
    }

    const handleOk = () => {
        dispatch(submit(SettingsCompanyFormName))
    }

    const handleSubmit = (company: Company) => {
        dispatch(actionUpdateCompany(company))
        setEditCompanyDialogOpen(false)
    }

    return (
        <>
            <IconButton
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation()
                    setEditCompanyDialogOpen(true)
                }}
                color="inherit"
            >
                <Edit />
            </IconButton>

            <Dialog onClose={handleClose} open={editCompanyDialogOpen} disableBackdropClick>
                <DialogTitle>{company.name}</DialogTitle>
                <DialogContent style={{ width: '480px' }}>
                    <SettingsCompanyForm initialValues={company} onSubmit={handleSubmit} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit">
                        {tr(TEXT_CANCEL)}
                    </Button>
                    <Button onClick={handleOk} color="primary" autoFocus>
                        {tr(TEXT_SAVE)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
