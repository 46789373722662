import React, { useEffect, useState } from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import { InjectedFormProps, reduxForm, Field, change } from 'redux-form'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import {
    TEXT_NAME,
    TEXT_ZONES,
    TEXT_MUNICIPALITY,
    TEXT_EVALUATION_COMPANY,
    TEXT_TRIBUNAL,
    TEXT_TYPE,
    TEXT_VALUE_TYPE,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import { CubitSelectAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { Company, CompanyType } from 'models/company'
import { useDispatch, useSelector } from 'react-redux'
import { getPropertyValueTypeOptions } from 'utils/property/property-helper'
import { AppState } from 'app/app-store'
import { actionGetTaxSettings } from 'settings-page/settings-actions'
import { getZoneOptions } from 'settings-page/helper'

const useStyles = makeStyles((theme: Theme) => {
    return {
        detailsContainer: {
            marginBottom: theme.spacing(4),
        },
    }
})

const Form: React.FC<InjectedFormProps<Company>> = (props) => {
    const { handleSubmit, initialValues } = props
    const styles = useStyles()
    const dispatch = useDispatch()

    const [zonesFieldDisabled, setZonesFieldDisabled] = useState(
        initialValues.type && initialValues.type !== CompanyType.Taxation,
    )

    const settings = useSelector((state: AppState) => state.taxSettings.taxSettings)
    useEffect(() => {
        if (!settings?.loaded) {
            dispatch(actionGetTaxSettings())
        }
    }, [settings, dispatch])

    const types = [
        { label: tr(TEXT_MUNICIPALITY), value: CompanyType.Admin },
        { label: tr(TEXT_EVALUATION_COMPANY), value: CompanyType.Taxation },
        { label: tr(TEXT_TRIBUNAL), value: CompanyType.Tribunal },
    ]

    const zones = getZoneOptions(settings)

    const handleTypeChange = (event: any, newValue: any) => {
        switch (newValue) {
            case CompanyType.Admin:
                setAllZones()
                break
            case CompanyType.Taxation:
                setEmptyZones()
                break
            case CompanyType.Tribunal:
                setAllZones()
                break
        }
    }

    const setAllZones = () => {
        dispatch(
            change(
                SettingsCompanyFormName,
                'zones',
                zones.map((z: any) => z.value),
            ),
        )
        setZonesFieldDisabled(true)
    }

    const setEmptyZones = () => {
        dispatch(change(SettingsCompanyFormName, 'zones', []))
        setZonesFieldDisabled(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box className={styles.detailsContainer}>
                <Field name="name" component={CubitTextFieldAdapter} label={tr(TEXT_NAME)} autoComplete="off" />
            </Box>

            <Box className={styles.detailsContainer}>
                <Field
                    name="type"
                    format={(value: any) => value || []}
                    component={CubitSelectAdapter}
                    label={tr(TEXT_TYPE)}
                    valueIsObject={false}
                    options={types}
                    onChange={handleTypeChange as any}
                />
            </Box>

            <Box className={styles.detailsContainer}>
                <Field
                    name="zones"
                    multiple
                    format={(value: any) => value || []}
                    component={CubitSelectAdapter}
                    label={tr(TEXT_ZONES)}
                    valueIsObject={false}
                    options={zones}
                    disabled={zonesFieldDisabled}
                />
            </Box>

            <Box className={styles.detailsContainer}>
                <Field
                    name="propertyTypes"
                    multiple
                    format={(value: any) => value || []}
                    component={CubitSelectAdapter}
                    label={tr(TEXT_VALUE_TYPE)}
                    valueIsObject={false}
                    options={getPropertyValueTypeOptions()}
                />
            </Box>
        </form>
    )
}

export const SettingsCompanyFormName = 'settingsCompanyForm'

export const SettingsCompanyForm = reduxForm<Company>({
    form: SettingsCompanyFormName,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
