import { sortBy } from "lodash"

export const getParsedTemplate = (template: any) => {
    if (!template.state) {
        return template
    }
    return {
        ...template,
        state: {
            ...template.state,
            entityMap: template.state && template.state.entityMap !== '' ? JSON.parse(template.state.entityMap) : {},
        },
    }
}

export const getStringifiedTemplate = (template: any) => {
    return {
        ...template,
        state: {
            ...template.state,
            entityMap: JSON.stringify(template.state?.entityMap ?? {}),
        },
    }
}

export const getZoneOptions = (settings: any) => {
    if (!settings?.loaded || !settings.zoneFactors) {
        return [
            {
                label: '',
                value: '',
            },
        ]
    }
    if (!settings.zoneLabels && settings.databaseZones) {
        //no custom labels - return default tenant zones values
        return settings.databaseZones.map((zone: any) => {
            return {
                label: zone.name,
                value: zone.name,
            }
        })
    }
    //use custom labels, DB names as value
    const keys = Object.keys(settings.zoneLabels)
    const sortedDbZones = sortBy(settings.databaseZones, 'number')
    return keys.map((key: string, index: number) => {
        if (index >= sortedDbZones.length) {
            return {
                label: '',
                value: ''
            }
        }
        return {
            label: settings.zoneLabels ? settings.zoneLabels[key] : key,
            value: settings.databaseZones ? sortedDbZones[index].name : key,
        }

    })
}

export const getZoneLabel = (settings: any, zone: string): string => {
    if (!settings || !settings?.loaded || !settings?.zoneLabels) {
        return zone
    }
    const labels = Object.values(settings.zoneLabels)
    if (!settings.databaseZones) {
        throw new Error("Database zones table entries are not found")
    }
    const dbZone = settings.databaseZones.find((z: any) => z.name === zone)
    if (!dbZone) {
        return zone
    }
    const label: string = labels[dbZone.number - 1] as string
    return label
}
