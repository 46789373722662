export type Attribute = {
    id: string
    text: string
    type: string
    created: Date
    createdBy: string
    lastUpdatedOn: Date
}

export enum attributeKeys {
    PROPERTY = 'Property',
    HOUSING_UNIT = 'HousingUnit',
    BUILDING = 'Building',
}

export type Attributes = Record<attributeKeys.PROPERTY | attributeKeys.HOUSING_UNIT | attributeKeys.BUILDING, string[]>

export type AttributesKey = attributeKeys.PROPERTY | attributeKeys.HOUSING_UNIT | attributeKeys.BUILDING

export type AttributeDialogProps = {
    label: string
    entityId: string
    type: AttributesKey
    attributes: string[]
    propertyId: string
    close: any
    afterEdit?: any
}

export type AttributesProps = {
    id: string
    type: AttributesKey
    label?: string
    attributes: string[]
    propertyId: string
    afterEdit?: any
}
