import { AnyAction } from 'redux'
import {
    ACTION_CUBIT_CALENDAR_EVENT_DROP,
    ACTION_CUBIT_CALENDAR_EVENT_DROP_EXTERNAL,
    ACTION_CUBIT_CALENDAR_EVENTS_REMOVE,
    ACTION_CUBIT_CALENDAR_EVENT_RESIZE,
    ACTION_CUBIT_CALENDAR_EVENT_SELECT,
    ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN,
    ACTION_CUBIT_CALENDAR_EVENTS_RESET,
    ACTION_CUBIT_CALENDAR_EVENTS_SAVE,
    ACTION_CUBIT_CALENDAR_EVENTS_SAVE_SUCCESS,
    ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN_SUCCESS,
    ACTION_CUBIT_CALENDAR_EVENTS_REMOVE_SUCCESS,
    ACTION_CUBIT_CALENDAR_EVENT_SELECT_BATCH,
    ACTION_CUBIT_CALENDAR_EVENTS_UNSELECT,
} from 'shared-components/src/cubit-calendar/calendar-actions'
import { makeReducer } from 'shared-components/src/utils'
import { arrayMapWhere } from 'shared-components/src/utils/array-distinct'
import { ActionReducer, Reducers } from 'shared-components/src/utils/make-reducer'
import {
    ACTION_PLANNING_EVENTS_CREATE,
    ACTION_PLANNING_EVENTS_CREATE_SUCCESS,
    ACTION_PLANNING_GET_UNPLANNED_EVENTS,
    ACTION_PLANNING_GET_UNPLANNED_EVENTS_SUCCESS,
    ACTION_PLANNING_GET_USER_EVENTS,
    ACTION_PLANNING_GET_USER_EVENTS_SUCCESS,
} from './planning-actions'
import { CubitCalendarEvent, CalendarEventInfo } from 'shared-components/src/models/calendar-event'
import { find, filter } from 'lodash'
import { CubitCalendarExtendedProps } from 'models/calendar-event'
import { ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE_SUCCESS } from 'tribunal-page/tribunal-actions'

export type PlanningState = {
    events: CubitCalendarEvent<CubitCalendarExtendedProps>[]
    loadingPlanned: boolean
    loadingUnplanned: boolean
    loadedPlanned: boolean
}

type PlanningReducer = ActionReducer<PlanningState, AnyAction>

const eventSelect: PlanningReducer = (state, action) => {
    const event = state.events.find(x => x.id === action.event.id)
    if (event !== undefined) {
        event.isSelected = action.isSelected
    }
    return state
}

const unSelectEvents: PlanningReducer = (state) => {
    state.events.forEach((item: any) => {
        item.isSelected = false
    })
    return state
}

const eventSelectBatch: PlanningReducer = (state, action) => {
    action.events.forEach((item: any) => {
        const event = state.events.find(x => x.id === item.id)
        if (event !== undefined) {
            event.isSelected = action.isSelected
        }
    })
    return state
}

const eventDrop: PlanningReducer = (state, action) => {
    const event = state.events.find(x => x.id === action.event.id)
    if (event !== undefined) {
        event.start = action.event.start
        event.end = action.event.end
    }
    return state
}

const eventsRemove: PlanningReducer = (state, action) => {
    const removedEvents = action.events

    state.events = filter(state.events, event => !find(removedEvents, e => e.id === event.id))

    return state
}

const eventsRemoveSuccess: PlanningReducer = state => state

const eventUnassign: PlanningReducer = (state, action) => {
    const event = state.events.find(x => x.id === action.event.id)
    if (event !== undefined) {
        event.start = undefined
        event.end = undefined
        event.extendedProps.start = undefined
    }
    return state
}

const eventUnassignSuccess: PlanningReducer = state => state

const eventResize: PlanningReducer = (state, action) => {
    const event = state.events.find(x => x.id === action.event.id)
    if (event !== undefined) {
        event.start = action.event.start
        event.end = action.event.end
    }
    return state
}

const eventDropExternal: PlanningReducer = (state, action) => {
    const selected = state.events.filter(x => x.isSelected)
    const draggingSelected = selected.some((e: any) => e.id === action.event.id)
    if (selected.length === 0 || !draggingSelected) {
        // simple case
        const predicate = (x: any) => x.id === action.event.id
        const map = (x: any) => ({ ...x, start: action.event.start })
        state.events = arrayMapWhere(state.events, predicate, map)
    } else {
        // multi drag
        const predicate = (x: any) => selected.some(selected => selected.id === x.id)
        const map = (x: any) => ({ ...x, start: action.event.start, selected: false })
        state.events = arrayMapWhere(state.events, predicate, map)
    }
    return state
}

const eventsSaveChanges: PlanningReducer = state => {
    state.events.forEach(x => {
        const eventDateMismatches = x.start !== x.extendedProps.start || x.end !== x.extendedProps.end

        if (x.start && x.end && eventDateMismatches) {
            x.extendedProps.start = x.start
            x.extendedProps.end = x.end
        }
    })
    return state
}

const eventsSaveChangesSuccess: PlanningReducer = (state, action) => {
    const updatedEvents: CalendarEventInfo<CubitCalendarExtendedProps>[] = action.events

    const predicate = (x: CubitCalendarEvent<CubitCalendarExtendedProps>): any => updatedEvents.filter(y => y.id === x.id)
    const map = (x: CubitCalendarEvent<CubitCalendarExtendedProps>): CubitCalendarEvent<CubitCalendarExtendedProps> => {
        const updatedEvent = find(updatedEvents, { id: x.id })

        if (updatedEvent) {
            return {
                id: updatedEvent.id,
                title: updatedEvent.relatedEntity.title,
                allDay: false,
                isSelected: false,
                start: updatedEvent.start,
                end: updatedEvent.end,
                extendedProps: updatedEvent,
            }
        } else {
            return x
        }
    }
    state.events = arrayMapWhere(state.events, predicate, map)

    return state
}

const eventsResetChanges: PlanningReducer = state => {
    state.events.forEach(x => {
        const eventDateMismatches = (x.start !== x.extendedProps.start || x.end !== x.extendedProps.end) && !x.extendedProps.start

        if (eventDateMismatches) {
            x.start = undefined
            x.end =  undefined
        }
    })
    return state
}

const eventsCreate: PlanningReducer = (state, action) => state

const eventsCreateSuccess: PlanningReducer = (state, action) => {
    const newEvents = action.items.map(
        (x: any): CubitCalendarEvent<CubitCalendarExtendedProps> => ({
            id: x.id,
            title: x.name,
            allDay: false,
            isSelected: false,
            start: undefined,
            end: undefined,
            extendedProps: x,
        }),
    )
    return {
        ...state,
        events: [
            ...state.events,
            ...newEvents
        ]
    }
}

const getCurrentUserEvents: PlanningReducer = (state, action) => {
    const unplanned = state.events.filter((e: CubitCalendarEvent<CubitCalendarExtendedProps>) => !e.start)
    return {
        ...state,
        events: [
            ...unplanned
        ],
        loadingPlanned: true,
        loadedPlanned: false
    }
}

const getCurrentUserEventsSuccess: PlanningReducer = (state, action) => {
    const events = action.events.map(
        (x: CalendarEventInfo<CubitCalendarExtendedProps>): CubitCalendarEvent<CubitCalendarExtendedProps> => ({
            id: x.id,
            title: x.relatedEntity.title,
            allDay: false,
            isSelected: false,
            start: x.start,
            end: x.end,
            extendedProps: x,
        }),
    )
    const unplanned = state.events.filter((e: CubitCalendarEvent<CubitCalendarExtendedProps>) => !e.start)

    return {
        ...state,
        events: [
            ...unplanned,
            ...events
        ],
        loadingPlanned: false,
        loadedPlanned: true
    }
}

const getUnplanned: PlanningReducer = (state, action) => {
    const planned = state.events.filter((e: CubitCalendarEvent<CubitCalendarExtendedProps>) => e.start)
    return {
        ...state,
        events: [
            ...planned
        ],
        loadingUnplanned: true
    }
}

const getUnplannedSuccess: PlanningReducer = (state, action) => {
    
    const unplanned = action.events.map(
        (x: CalendarEventInfo<CubitCalendarExtendedProps>): CubitCalendarEvent<CubitCalendarExtendedProps> => ({
            id: x.id,
            title: x.relatedEntity.title,
            allDay: false,
            isSelected: false,
            start: x.start,
            end: x.end,
            extendedProps: x,
        }),
    )
    const planned = state.events.filter((e: CubitCalendarEvent<CubitCalendarExtendedProps>) => e.start)
    return {
        ...state,
        events: [
            ...planned,
            ...unplanned
        ],
        loadingUnplanned: false
    }
}

const rescheduleTaxationsSuccess: PlanningReducer = (state, action) => {
    
    const unplanned = action.events.map(
        (x: CalendarEventInfo<CubitCalendarExtendedProps>): CubitCalendarEvent<CubitCalendarExtendedProps> => ({
            id: x.id,
            title: x.relatedEntity.title,
            allDay: false,
            isSelected: false,
            start: x.start,
            end: x.end,
            extendedProps: x,
        }),
    )

    return {
        ...state,
        events: [
            ...state.events,
            ...unplanned
        ],
    }
}

const reducers: Reducers<PlanningState> = {
    [ACTION_PLANNING_GET_USER_EVENTS]: getCurrentUserEvents,
    [ACTION_PLANNING_GET_USER_EVENTS_SUCCESS]: getCurrentUserEventsSuccess,
    [ACTION_PLANNING_EVENTS_CREATE]: eventsCreate,
    [ACTION_PLANNING_EVENTS_CREATE_SUCCESS]: eventsCreateSuccess,
    [ACTION_CUBIT_CALENDAR_EVENT_SELECT]: eventSelect,
    [ACTION_CUBIT_CALENDAR_EVENT_SELECT_BATCH]: eventSelectBatch,
    [ACTION_CUBIT_CALENDAR_EVENT_RESIZE]: eventResize,
    [ACTION_CUBIT_CALENDAR_EVENT_DROP]: eventDrop,
    [ACTION_CUBIT_CALENDAR_EVENT_DROP_EXTERNAL]: eventDropExternal,
    [ACTION_CUBIT_CALENDAR_EVENTS_SAVE]: eventsSaveChanges,
    [ACTION_CUBIT_CALENDAR_EVENTS_SAVE_SUCCESS]: eventsSaveChangesSuccess,
    [ACTION_CUBIT_CALENDAR_EVENTS_RESET]: eventsResetChanges,
    [ACTION_CUBIT_CALENDAR_EVENTS_REMOVE]: eventsRemove,
    [ACTION_CUBIT_CALENDAR_EVENTS_REMOVE_SUCCESS]: eventsRemoveSuccess,
    [ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN]: eventUnassign,
    [ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN_SUCCESS]: eventUnassignSuccess,
    [ACTION_CUBIT_CALENDAR_EVENTS_UNSELECT]: unSelectEvents,
    [ACTION_PLANNING_GET_UNPLANNED_EVENTS]: getUnplanned,
    [ACTION_PLANNING_GET_UNPLANNED_EVENTS_SUCCESS]: getUnplannedSuccess,
    [ACTION_TRIBUNAL_TAXATIONS_RESCHEDULE_SUCCESS]: rescheduleTaxationsSuccess,
}

export const planningReducer = makeReducer<PlanningState>(
    reducers, 
    { 
        events: [], 
        loadingPlanned: false,
        loadingUnplanned: false,
        loadedPlanned: false
    }
)
