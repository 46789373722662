import React from 'react'
import classes from './value-container.module.css'

type ValueContainerProps = {
    label?: string
    value: string | number | JSX.Element
}

export const ValueContainer: React.FC<ValueContainerProps> = props => {
    const { label, value } = props

    return (
        <div className={classes.valueContainer}>
            <label className={classes.label}>{label}</label>
            <div className={classes.valueWrapper}>
                <div className={classes.value}>{value}</div>
            </div>
        </div>
    )
}
