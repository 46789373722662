import React, { useState } from 'react'
import { DraggableEvents } from 'shared-components/src/cubit-calendar/draggable-events'
import { PlanningDraggableEvent } from 'planning-page/planning-draggable-event/planning-draggable-event'
import { useDispatch } from 'react-redux'
import {
    actionCubitCalendarEventSelect,
    actionCubitCalendarEventSelectBatch,
    actionCubitCalendarEventsRemove,
} from 'shared-components/src/cubit-calendar/calendar-actions'
import { CubitCalendarEvent } from 'shared-components/src/models/calendar-event'
import { includes } from 'lodash'
import { TextField, InputAdornment, IconButton, Button } from '@material-ui/core'
import { Clear, Delete } from '@material-ui/icons'
import { tr } from 'utils/translations/translate'
import { TEXT_REMOVE_EVENT, TEXT_REMOVE_EVENTS, TEXT_FILTER_EVENTS } from 'utils/translations/keys'
import { CubitCalendarExtendedProps } from 'models/calendar-event'

type PlanningPageEventsProps = {
    events: CubitCalendarEvent<CubitCalendarExtendedProps>[]
}

export const PlanningPageEvents: React.FC<PlanningPageEventsProps> = props => {
    const { events } = props
    const dispatch = useDispatch()
    
    const [lastSelectindex, setLastSelectindex] = useState(0)
    const [filterText, setFilterText] = useState('')

    const filteredEvents = filterText
        ? events.filter(e => {
              return (
                  e.title &&
                  (includes(e.title.toLowerCase(), filterText.toLowerCase()) ||
                      includes(
                          e.extendedProps.relatedEntity.propertyAddresses.toLowerCase(),
                          filterText.toLowerCase(),
                      ))
              )
          })
        : events

    const selectedEvents = filteredEvents.filter(e => e.isSelected)
    const selectEvent = (item: any, event: React.ChangeEvent<HTMLInputElement>) => {
        const nativeEvent = (event.nativeEvent as unknown) as React.MouseEvent
        const isShiftKeyPressed = nativeEvent.shiftKey
        const clickedIndex = filteredEvents.indexOf(item)
        
        if (isShiftKeyPressed) {
            if (clickedIndex === lastSelectindex) {
                dispatch(actionCubitCalendarEventSelect(item, event.target.checked))
            } else {
                const items = filteredEvents.filter((e: any, index: number) => clickedIndex > lastSelectindex ? index <= clickedIndex && index >= lastSelectindex : index >= clickedIndex && index <= lastSelectindex)
                dispatch(actionCubitCalendarEventSelectBatch(items, event.target.checked))
            }
        } else {
            dispatch(actionCubitCalendarEventSelect(item, event.target.checked))
        }
        setLastSelectindex(clickedIndex)
    }

    const handleEventsRemove = (events: CubitCalendarEvent<CubitCalendarExtendedProps>[]) => {
        dispatch(actionCubitCalendarEventsRemove(events))
    }

    return (
        <>
            {selectedEvents.length ? (
                <Button
                    variant="text"
                    style={{ width: '100%', height: '56px', marginBottom: '8px' }}
                    onClick={() => handleEventsRemove(selectedEvents)}
                >
                    {selectedEvents.length === 1 ? tr(TEXT_REMOVE_EVENT) : tr(TEXT_REMOVE_EVENTS)}

                    <span style={{ position: 'relative' }}>
                        <span
                            style={{
                                color: '#fff',
                                position: 'absolute',
                                top: '5px',
                                right: 0,
                                bottom: 0,
                                left: '-1px',
                                margin: 0,
                                letterSpacing: '-0.3px',
                                fontSize: '10px',
                            }}
                        >
                            {selectedEvents.length > 1 ? selectedEvents.length : ''}
                        </span>
                        <Delete></Delete>
                    </span>
                </Button>
            ) : (
                <TextField
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    placeholder={tr(TEXT_FILTER_EVENTS)}
                    margin="normal"
                    variant="outlined"
                    style={{ margin: '0px 0px 8px 0px' }}
                    InputProps={{
                        endAdornment: filterText && (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setFilterText('')}>
                                    <Clear fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            <DraggableEvents events={filteredEvents} Component={PlanningDraggableEvent} onSelect={selectEvent} />
        </>
    )
}
