import { AvailableInputTypes, FormField } from '../../cubit-inputs/cubit-form-field.types'
import { ChipGroupType } from '../search-filters-chips'
import { InputType } from '../../cubit-inputs/input-type.enum'
import { get } from 'lodash'

export const getChipGroup: <T extends AvailableInputTypes>(fieldState: any, field: FormField<T>) => ChipGroupType = (
    fieldState,
    fieldDefinition,
) => {
    const a: any = {
        [InputType.Switch]: () => {
            const field = fieldDefinition as FormField<InputType.Switch>
            const label = field.props.label
            const name = field.props.name

            const chipGroup: ChipGroupType = {
                label: '',
                name,
                chips: [
                    {
                        key: name,
                        label: label,
                        value: fieldState,
                        type: InputType.Switch,
                    },
                ],
            }

            return fieldState ? chipGroup : null
        },
        [InputType.DateRange]: () => {
            const field = fieldDefinition as FormField<InputType.DateRange>
            const label = field.label
            const name = field.name

            const chipGroup: ChipGroupType = {
                label,
                name,
                chips: Object.keys(fieldState).map((inputName: string) => {
                    const inputDefinition = get(field, inputName)
                    return {
                        ...fieldState[inputName],
                        key: inputDefinition.props.name,
                        label: `${inputDefinition.props.label}: ${fieldState[inputName].toISODate()}`,
                        type: InputType.DateRange,
                    }
                }),
            }

            return fieldState ? chipGroup : null
        },
        [InputType.NumberRange]: () => {
            const field = fieldDefinition as FormField<InputType.NumberRange>
            const label = field.label
            const name = field.name

            const chipGroup: ChipGroupType = {
                label,
                name,
                chips: Object.keys(fieldState).map((inputName: string) => {
                    const inputDefinition = get(field, inputName)
                    return {
                        ...fieldState[inputName],
                        key: inputDefinition.props.name,
                        label: `${inputDefinition.props.label}: ${fieldState[inputName]}`,
                        type: InputType.NumberRange,
                    }
                }),
            }

            return fieldState ? chipGroup : null
        },
        [InputType.SliderRange]: () => {
            const field = fieldDefinition as FormField<InputType.SliderRange>

            const { label, name, marks } = field.props

            const firstIndex = fieldState[0]
            const lastIndex = fieldState[fieldState.length - 1]

            const firstMark = get(marks, firstIndex) || ''
            const lastMark = get(marks, lastIndex) || ''

            const firstLabel = typeof firstMark === 'object' ? firstMark.label || '' : firstMark
            const lastLabel = typeof lastMark === 'object' ? lastMark.label || '' : lastMark

            const chipGroup: ChipGroupType = {
                label,
                name,
                chips: [
                    {
                        key: name,
                        label: firstIndex === lastIndex ? firstLabel : `${firstLabel} — ${lastLabel}`,
                        value: fieldState,
                        type: InputType.SliderRange,
                    },
                ],
            }

            return fieldState ? chipGroup : null
        },
        [InputType.Switch]: () => {
            const field = fieldDefinition as FormField<InputType.Switch>
            const label = ''
            const name = field.props.name

            const chipGroup: ChipGroupType = {
                label,
                name,
                chips: [
                    {
                        ...fieldState,
                        key: name,
                        label: field.props.label,
                        type: InputType.Switch,
                    },
                ],
            }

            return fieldState ? chipGroup : null
        },
        [InputType.Select]: () => {
            const field = fieldDefinition as FormField<InputType.Select>
            const label = field.props.label
            const name = field.props.name

            const chipGroup: ChipGroupType = {
                label,
                name,
                chips: [{ ...fieldState, key: name }],
            }

            return fieldState ? chipGroup : null
        },
        [InputType.SelectGroup]: () => {
            const field = fieldDefinition as FormField<InputType.SelectGroup>
            const label = field.props.label
            const name = field.props.name
            
            const chipGroup: ChipGroupType = {
                label,
                name,
                chips: Object.keys(fieldState).map((key: string) => ({
                    ...fieldState[key],
                    key: name,
                })),
            }
            return fieldState ? chipGroup : null
        },
        [InputType.Checkbox]: () => {
            const field = fieldDefinition as FormField<InputType.Checkbox>
            const label = field.props.label
            const name = field.props.name

            const chipGroup: ChipGroupType = {
                label,
                name,
                chips: fieldState.map((state: any) => ({
                    ...state,
                    key: name,
                })),
            }

            return fieldState ? chipGroup : null
        },
    }
    return a[fieldDefinition.type]() || null
}
