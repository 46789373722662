import React, { useEffect, useState } from 'react'
import { fromEvent, interval } from 'rxjs'
import { debounce } from 'rxjs/operators'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { TEXT_SHOWING, TEXT_OF, TEXT_LOAD_MORE } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

const EVENT_DEBOUNCE_TIME = 50

type LoadMoreProps = {
    count: number
    totalCount: number
    onLoadMore: () => void
}

const LoadMore: React.FC<LoadMoreProps> = props => {
    const { onLoadMore, count, totalCount } = props
    const [autoload, setAutoload] = useState(false)

    const loadOnClick = () => {
        setAutoload(true)
        onLoadMore()
    }

    useEffect(() => {
        const checkIfLoadingElementIsInViewport = () => {
            const isInViewPort = isElementInViewport(document.querySelector('#loadingToggle'))
            if (isInViewPort && autoload) {
                onLoadMore()
            }
        }

        const scrollSubscription = fromEvent(window, 'scroll')
            .pipe(debounce(() => interval(EVENT_DEBOUNCE_TIME)))
            .subscribe(() => checkIfLoadingElementIsInViewport())

        return () => {
            scrollSubscription.unsubscribe()
        }
    }, [autoload, onLoadMore])

    // TODO this doesn't unsubscribe? needs to be in the parent
    if (totalCount === 0) {
        return null
    }

    const message = `${tr(TEXT_SHOWING)} ${count} ${tr(TEXT_OF)} ${totalCount}`

    return (
        <Grid id="loadingToggle" container justifyContent="center" style={{ padding: '32px' }}>
            <Grid item xs={12}>
                <Typography align="center" gutterBottom>
                    {message}
                </Typography>
            </Grid>
            {autoload || count === totalCount ? null : (
                <Grid item>
                    <Button variant="contained" onClick={loadOnClick}>
                        {tr(TEXT_LOAD_MORE)}
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default LoadMore

const isElementInViewport = (el: any) => {
    if (!el) return false
    const rect = el.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}
