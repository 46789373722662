import { arrayDistinct } from 'shared-components/src/utils'
import { HousingUnitAddress } from './housing-unit-address'

export type HousingUnit = {
    address: HousingUnitAddress
    buildingId: string
    fireplaces: any[]
    id: string
    inspectionRate: number
    lastControl: any // null
    matrikkelUnitId: string
    nextControl: any
    noOfBathrooms: number
    noOfRooms: number
    noOfToilets: number
    riskAssessmentScore: number
    type: string
    usableArea: number
}

export const getHousingUnitsAddresses = (housingUnits: HousingUnit[]) => {
    const relevant = housingUnits.filter(x => x.address?.addressText)
    return arrayDistinct(relevant.map((x) => `${x.address.addressText} ${x.address.postalCode ?? ''}`))
        .filter((x) => !!x)
        .join(', ')
}