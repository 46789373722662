import { AnyAction } from 'redux'
import { Language } from './language.enum'
import { ACTION_SETTINGS_LANGUAGE_SET } from './language-actions'

import config from 'config'
import { makeReducer } from '../../utils'

export type LanguageState = Language | string
type LanguageReducer = (state: LanguageState, action: AnyAction) => LanguageState

const language = localStorage.getItem(config.localStorageLangKey) || config.language
const initialState: LanguageState = language

const setLanguage: LanguageReducer = (state, action) => action.language

const reducers: { [key: string]: LanguageReducer } = {
    [ACTION_SETTINGS_LANGUAGE_SET]: setLanguage,
}

export const languageReducer = makeReducer(reducers, initialState)
