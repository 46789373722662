import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { find } from 'lodash'

export const useTribunalGroup = (groupId: string) =>
    useSelector((state: AppState) => find(state.tribunal.groups, { id: groupId }))

export const useTribunalGroupTaxations = (groupId: string) =>
    useSelector((state: AppState) => state.tribunal.taxations[groupId])

export const useComplaints = () => useSelector((state: AppState) => state.tribunal.complaints)
