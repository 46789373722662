import { CalendarEvent } from './../models/calendar-event'

export const ACTION_CUBIT_CALENDAR_SET_VIEW = 'ACTION_CUBIT_CALENDAR_SET_VIEW'
export const ACTION_CUBIT_CALENDAR_SET_DATE = 'ACTION_CUBIT_CALENDAR_SET_DATE'
export const ACTION_CUBIT_CALENDAR_SET_SHOW_WEEKENDS = 'ACTION_CUBIT_CALENDAR_SET_SHOW_WEEKENDS'
export const ACTION_CUBIT_CALENDAR_NEXT_RANGE = 'ACTION_CUBIT_CALENDAR_NEXT_RANGE'
export const ACTION_CUBIT_CALENDAR_PREVIOUS_RANGE = 'ACTION_CUBIT_CALENDAR_PREVIOUS_RANGE'
export const ACTION_CUBIT_CALENDAR_SET_DRAGGING = 'ACTION_CUBIT_CALENDAR_SET_DRAGGING'
export const ACTION_CUBIT_CALENDAR_EVENTS_SAVE = 'ACTION_CUBIT_CALENDAR_EVENTS_SAVE'
export const ACTION_CUBIT_CALENDAR_EVENTS_SAVE_SUCCESS = 'ACTION_CUBIT_CALENDAR_EVENTS_SAVE_SUCCESS'
export const ACTION_CUBIT_CALENDAR_EVENTS_RESET = 'ACTION_CUBIT_CALENDAR_EVENTS_RESET'
export const ACTION_CUBIT_CALENDAR_EVENT_SELECT = 'ACTION_CUBIT_CALENDAR_EVENT_SELECT'
export const ACTION_CUBIT_CALENDAR_EVENT_SELECT_BATCH = 'ACTION_CUBIT_CALENDAR_EVENT_SELECT_BATCH'
export const ACTION_CUBIT_CALENDAR_EVENT_RESIZE = 'ACTION_CUBIT_CALENDAR_EVENT_RESIZE'
export const ACTION_CUBIT_CALENDAR_EVENT_DROP = 'ACTION_CUBIT_CALENDAR_EVENT_DROP'
export const ACTION_CUBIT_CALENDAR_EVENT_DROP_EXTERNAL = 'ACTION_CUBIT_CALENDAR_EVENT_DROP_EXTERNAL'
export const ACTION_CUBIT_CALENDAR_EVENTS_REMOVE = 'ACTION_CUBIT_CALENDAR_EVENTS_REMOVE'
export const ACTION_CUBIT_CALENDAR_EVENTS_REMOVE_SUCCESS = 'ACTION_CUBIT_CALENDAR_EVENTS_REMOVE_SUCCESS'
export const ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN = 'ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN'
export const ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN_SUCCESS = 'ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN_SUCCESS'
export const ACTION_CUBIT_CALENDAR_EVENTS_UNSELECT = 'ACTION_CUBIT_CALENDAR_EVENT_UNSELECT'

export const actionCubitCalendarSetView = (view: string) => ({
    type: ACTION_CUBIT_CALENDAR_SET_VIEW,
    view,
})

export const actionCubitCalendarSetShowWeekends = (showWeekends: boolean) => ({
    type: ACTION_CUBIT_CALENDAR_SET_SHOW_WEEKENDS,
    showWeekends,
})

export const actionCubitCalendarSetDate = (date: string) => ({
    type: ACTION_CUBIT_CALENDAR_SET_DATE,
    date,
})

export const actionCubitCalendarSetDragging = (isDragging: boolean) => ({
    type: ACTION_CUBIT_CALENDAR_SET_DRAGGING,
    isDragging,
})

export const actionCubitCalendarEventSelect = (event: any, isSelected: boolean) => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_SELECT,
    event,
    isSelected,
})

export const actionCubitCalendarEventSelectBatch = (events: any, isSelected: boolean) => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_SELECT_BATCH,
    events,
    isSelected,
})

export const actionCubitCalendarEventsUnSelect = () => ({
    type: ACTION_CUBIT_CALENDAR_EVENTS_UNSELECT,
})

export const actionCubitCalendarEventDrop = (event: any) => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_DROP,
    event,
})

export const actionCubitCalendarEventResize = (event: any) => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_RESIZE,
    event,
})

export const actionCubitCalendarEventDropExternal = (event: any) => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_DROP_EXTERNAL,
    event,
})

export const actionCubitCalendarNextRange = () => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_DROP_EXTERNAL,
})

export const actionCubitCalendarPreviousRange = () => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_DROP_EXTERNAL,
})

export const actionCubitCalendarSave = (modifiedEvents: CalendarEvent[]) => ({
    type: ACTION_CUBIT_CALENDAR_EVENTS_SAVE,
    events: modifiedEvents,
})

export const actionCubitCalendarSaveSuccess = (updatedEvents: any[]) => ({
    type: ACTION_CUBIT_CALENDAR_EVENTS_SAVE_SUCCESS,
    events: updatedEvents,
})

export const actionCubitCalendarReset = () => ({
    type: ACTION_CUBIT_CALENDAR_EVENTS_RESET,
})

export const actionCubitCalendarEventsRemove = (events: any) => ({
    type: ACTION_CUBIT_CALENDAR_EVENTS_REMOVE,
    events,
})

export const actionCubitCalendarEventsRemoveSuccess = (deletedEventIds: number[]) => ({
    type: ACTION_CUBIT_CALENDAR_EVENTS_REMOVE_SUCCESS,
    deletedEventIds,
})

export const actionCubitCalendarEventUnassign = (event: any) => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN,
    event,
})

export const actionCubitCalendarEventUnassignSuccess = (events: any[]) => ({
    type: ACTION_CUBIT_CALENDAR_EVENT_UNASSIGN_SUCCESS,
    events,
})
