import React from 'react'
import { Grid, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { CalculationDetails, isCalculationExempt, Taxation } from 'models/taxation'
import { formatCurrency } from 'shared-components/src/utils'
import { Info, Refresh } from '@material-ui/icons'
import { TaxPreview } from './tax-preview'
import { useDispatch } from 'react-redux'
import { actionRecalculate } from 'property-taxation-edit-page/taxation-actions'
import { getDateTimeFromISOString } from 'utils'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme: Theme) => {
    return {
        iconSpace: {
            width: 30,
            display: 'inline-block',
        },
        flexed: {
            display: 'flex',
            alignItems: 'center'
        },
    }
})

type TaxValueProps = {
    calculation: CalculationDetails
    taxation?: Taxation
}

export const TaxValue: React.FC<TaxValueProps> = (props) => {
    const {
        calculation,
        taxation
    } = props

    const styles = useStyles()
    const dispatch = useDispatch()

    const getExemptions: any = () => {
        return (
            <span>
                {calculation.exemptions ? calculation.exemptions.map((e: string, index: number) => <span key={index}>{e}<br /></span>) : ''}
            </span>
        )
    }

    const isExempt = isCalculationExempt(calculation)
    const tax = isExempt ? 0 : calculation.taxValue
    return (
        <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
            <Grid item>{formatCurrency(tax)}</Grid>
            <Grid item className={styles.flexed}>
                <IconButton 
                    onClick={(e: any) => {
                        e.stopPropagation()
                        return dispatch(actionRecalculate(taxation?.propertyId))
                    }}
                    disabled={!taxation?.propertyId || (taxation?.start && new Date(taxation.start).getFullYear() < new Date().getFullYear())}
                >
                    <Refresh />
                </IconButton>
                <TaxPreview taxation={taxation} />
                <Tooltip title={getExemptions()}>
                    <Info />
                </Tooltip>
                {/* <span className={styles.iconSpace}></span> */}
            </Grid>
        </Grid>
    )
}