import React from 'react'
import LayoutDefault from 'common/layout/layout-default'
import Navigation from 'common/navigation/navigation'
import { RouteComponentProps } from '@reach/router'
import { TEXT_UNAUTHORIZED } from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'

export const UnauthorizedPage: React.FC<RouteComponentProps & { default?: boolean }> = () => {
    return <LayoutDefault navigation={<Navigation />} pageTitle={tr(TEXT_UNAUTHORIZED)} />
}
