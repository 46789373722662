import { combineEpics, ofType } from 'redux-observable'
import { Observable, zip, from, of, concat } from 'rxjs'
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import {
    ACTION_PROPERTY_GET,
    ACTION_PROPERTY_GET_CONTACT_INFO,
    actionPropertyGetContactInfoSuccess,
    ACTION_PROPERTY_TAXATION_DELETE,
    ACTION_PROPERTY_TAXATION_UPDATE,
    actionPropertyGetSuccess,
    actionPropertyTaxationDeleteSuccess,
    actionPropertyTaxationUpdateSuccess,
    ACTION_ASSIGN_PROPERTY,
    actionAssignPropertySuccess,
    actionPropertyEventsCreateSuccess,
    ACTION_PROPERTY_EVENTS_CREATE,
    ACTION_PROPERTY_SAVE,
    actionPropertySaveSuccess,
    ACTION_PROPERTY_UPDATE,
    actionPropertyUpdateSuccess,
    ACTION_BUILDING_SAVE,
    actionBuildingSaveSuccess,
    ACTION_SEND_LAST_TAX,
    ACTION_SEND_PROPERTY_COMMUNICATION,
    actionSendPropertyCommunicationSuccess,
    actionLoadPropertyCommunicationSuccess,
    ACTION_LOAD_PROPERTY_COMMUNICATION,
    ACTION_CREATE_TAXATION,
    actionCreateTaxationSuccess,
    ACTION_PROPERTY_ADD_OWNERSHIP,
    ACTION_PROPERTY_UPDATE_OWNERSHIP,
    ACTION_PROPERTY_DELETE_OWNERSHIP,
    actionPropertyUpdateOwnershipSuccess,
    actionPropertyDeleteOwnershipSuccess,
} from './property-actions'
import { httpDelete, httpGet, httpPut, plantsUrl, httpPost } from 'services/httpService'
import { mapBuildings, mapProperty, mapTaxation } from 'utils'
import { Taxation } from 'models/taxation'
import { PropertyObjectWithTaxations } from 'models/property-object'
import { defaultCatchError } from 'app/app-epics'
import { AppState } from 'app/app-store'
import { actionActivityLogLoadSuccess } from './activity-log/activity-log-actions'
import { ActivityLog } from 'models/activity-log'
import { actionCubitSnackbarShow } from 'common/cubit-snackbar/cubit-snackbar-actions'
import {
    TEXT_ASSIGNED,
    TEXT_SCHEDULED_TAXATION_FOR,
    TEXT_CHANGES_SAVED,
    TEXT_SENT,
    TEXT_SEND_LAST_ASSESSMENT,
    TEXT_ERROR_OCCURRED,
} from 'utils/translations/keys'
import { ContactInfo, Property } from 'models/property'
import { tr } from 'utils/translations/translate'
import { actionPlanningEventsCreateSuccess } from 'planning-page/planning-actions'
import { SnackbarVariants } from 'common/cubit-snackbar/cubit-snackbar'
import { change } from 'redux-form'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { AddOwner } from 'models/owner'
import { navigate } from '@reach/router'

export const getPropertyEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_GET),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                zip(
                    httpGet(plantsUrl(`/tax/properties/${action.propertyId}`)),
                    httpGet(plantsUrl(`/history/${state.auth.sessionKey}/${action.propertyId}`)),
                ).pipe(
                    mergeMap(([data, activityLog]: [PropertyObjectWithTaxations, ActivityLog]) => {
                        const buildings = mapBuildings(data.buildings, data.housingUnits, data.property)
                        const property = mapProperty(data.property, data.taxations[0]?.staticSettings, buildings)

                        return from([
                            actionPropertyGetSuccess({ ...data, property, buildings }),
                            actionActivityLogLoadSuccess(activityLog, action.propertyId),
                        ])
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const getPropertyOwnerInfoEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_GET_CONTACT_INFO),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/taxProperty/getContactInfo/${action.personalNr}`)).pipe(
                    map((contactInfo: ContactInfo) => {
                        return actionPropertyGetContactInfoSuccess(action.propertyId, contactInfo)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const addOwnershipEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_ADD_OWNERSHIP),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl(`/taxProperty/addOwnerships`), {
                    ownershipsData: action.owner,
                    propertyId: action.propertyId,
                }).pipe(
                    mergeMap((property: PropertyObjectWithTaxations) => {
                        return of(
                            change(
                                TaxFormName.PropertyOwners,
                                TaxFormFieldNames.ownerships,
                                property.property.ownerShips,
                            ),
                            actionPropertyGetSuccess(property),
                        )
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const updateOwnershipEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_UPDATE_OWNERSHIP),
        mergeMap((action): Observable<AnyAction> => {
            return httpPut(plantsUrl(`/taxOwner`), {
                ownershipsData: action.owner,
                propertyId: action.propertyId,
            }).pipe(
                map((owner: AddOwner) => actionPropertyUpdateOwnershipSuccess(owner, action.propertyId)),
                defaultCatchError(),
            )
        }),
    )
export const deletePropertyOwnershipEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_DELETE_OWNERSHIP),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpDelete(plantsUrl(`/taxOwner`), {
                    ownershipsData: action.owner,
                    propertyId: action.propertyId,
                }).pipe(
                    map(() => actionPropertyDeleteOwnershipSuccess(action.propertyId, action.owner.ownerId)),
                    defaultCatchError(),
                ),
        ),
    )
export const updatePropertyTaxation = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_TAXATION_UPDATE),
        mergeMap((action): Observable<AnyAction> => {
            return httpPut(plantsUrl(`/tax/taxations/${action.taxation.id}`), action.taxation).pipe(
                map((taxation: Taxation) =>
                    actionPropertyTaxationUpdateSuccess(action.propertyId, mapTaxation(taxation)),
                ),
                defaultCatchError(),
            )
        }),
    )

export const updateProperty = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_UPDATE),
        mergeMap((action): Observable<AnyAction> => {
            return httpPut(plantsUrl(`/tax/updateproperty/${action.property.id}`), action.property).pipe(
                map((property: Property) => actionPropertyUpdateSuccess(property)),
                defaultCatchError(),
            )
        }),
    )

export const deletePropertyTaxation = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_TAXATION_DELETE),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpDelete(plantsUrl(`/tax/taxations/${action.taxationId}`), {}).pipe(
                    map(() => actionPropertyTaxationDeleteSuccess(action.propertyId, action.taxationId)),
                    defaultCatchError(),
                ),
        ),
    )

const assignPropertyEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_ASSIGN_PROPERTY),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/tax/assign'), action.stateModels).pipe(
                    mergeMap((assignedProperties: Property[]) =>
                        of(actionAssignPropertySuccess(assignedProperties), actionCubitSnackbarShow(tr(TEXT_ASSIGNED))),
                    ),
                    defaultCatchError(),
                ),
        ),
    )

export const createEventsEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_EVENTS_CREATE),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpPost(
                    plantsUrl('/tax/events/'),
                    action.items.map((property: Property) => ({
                        relatedEntityId: property.id,
                        userId: action.userId,
                        type: action.eventType,
                    })),
                ).pipe(
                    mergeMap((items: any[]) =>
                        of(
                            actionPropertyEventsCreateSuccess(items),
                            // update the events state too
                            actionPlanningEventsCreateSuccess(items),
                            actionCubitSnackbarShow(
                                `${tr(TEXT_SCHEDULED_TAXATION_FOR)} ${state.appData.users[action.userId].name}`,
                            ),
                        ),
                    ),
                ),
        ),
    )

export const savePropertyEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_PROPERTY_SAVE),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpPost(plantsUrl(`/tax/updateproperty/${action.property.id}`), action.property).pipe(
                    mergeMap((property: Property) =>
                        of(actionPropertySaveSuccess(action.property), actionCubitSnackbarShow(tr(TEXT_CHANGES_SAVED))),
                    ),
                ),
        ),
    )

export const saveBuildingEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_BUILDING_SAVE),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> =>
                httpPost(plantsUrl(`/tax/updateBuilding/${action.building.id}`), action.building).pipe(
                    mergeMap(() =>
                        of(actionBuildingSaveSuccess(action.building, action.property), actionCubitSnackbarShow(tr(TEXT_CHANGES_SAVED))),
                    ),
                ),
        ),
    )

const sendLastTaxEpic = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        ofType(ACTION_SEND_LAST_TAX),
        concatMap(
            (action): Observable<AnyAction> =>
                concat(
                    of(actionCubitSnackbarShow(tr(TEXT_SEND_LAST_ASSESSMENT))),
                    httpPost(plantsUrl(`/communication/sendLastTaxation`), { propertyId: action.id }).pipe(
                        map(() => actionCubitSnackbarShow(tr(TEXT_SENT))),
                        catchError((error): Observable<AnyAction> => {
                            console.error(error)
                            return of(actionCubitSnackbarShow(tr(TEXT_ERROR_OCCURRED), SnackbarVariants.error))
                        }),
                    ),
                ),
        ),
    )

const loadTaxationCommunication = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_LOAD_PROPERTY_COMMUNICATION),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpGet(plantsUrl(`/communication/get/${action.id}`)).pipe(
                    map((communication: any) => {
                        return actionLoadPropertyCommunicationSuccess(action.id, communication)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

const sendPropertyCommunication = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_SEND_PROPERTY_COMMUNICATION),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(plantsUrl('/communication/sendCommunication'), action.entry).pipe(
                    map((entries: any) => actionSendPropertyCommunicationSuccess(entries)),
                    defaultCatchError(),
                ),
        ),
    )

export const createPropertyTaxation = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter((x) => x.type === ACTION_CREATE_TAXATION),
        mergeMap((action): Observable<AnyAction> => {
            return httpPost(plantsUrl('/tax/taxation'), { propertyId: action.id }).pipe(
                map((taxation: Taxation) => {
                    navigate(`/taxation/${taxation.id}/edit`)
                    return actionCreateTaxationSuccess(action.id, mapTaxation(taxation))
                }),
                defaultCatchError(),
            )
        }),
    )

export const propertyEpics = combineEpics(
    updateOwnershipEpic,
    deletePropertyOwnershipEpic,
    getPropertyEpic,
    getPropertyOwnerInfoEpic,
    updatePropertyTaxation,
    deletePropertyTaxation,
    assignPropertyEpic,
    createEventsEpic,
    savePropertyEpic,
    saveBuildingEpic,
    sendLastTaxEpic,
    loadTaxationCommunication,
    sendPropertyCommunication,
    createPropertyTaxation,
    addOwnershipEpic,
)
