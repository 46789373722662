import React, { useState } from 'react'
import { Grid, IconButton, Button, Divider } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { Field, formValueSelector } from 'redux-form'
import { Create } from '@material-ui/icons'
import { TaxSettingsRow } from '../TaxSettingsRow'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { TwoColumnsRow } from 'settings-tax-page/helper-functions'
import { ThreeColumnsRow } from './three-columns-row'

export const StandardFactors = ({
    fields,
    meta: { touched, error, submitFailed },
    classes,
    stateRef,
    rowLabels = {},
    removable = true,
    hideDivider = false,
    readOnly,
    canCancel,
}: any) => {
    const selector = formValueSelector(TaxFormName.TaxationSettings)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const [editing, setEditing] = useState(false)
    const resetEdit = useSelector((state: AppState) => state.taxSettings.taxFormReset)
    if (resetEdit && editing) {
        setEditing(false)
    }
    const fromToFactors = getFieldValue(TaxFormFieldNames.standardFactorsFromTo)
    return (
        <Grid container className={classes.settingsRow}>
            {!editing && !readOnly && (
                <Grid item className={classes.editButton}>
                    <IconButton
                        onClick={() => {
                            setEditing(true)
                            canCancel(true)
                        }}
                    >
                        <Create />
                    </IconButton>
                </Grid>
            )}
            {editing && (
                <Grid item xs={12}>
                    <Field
                        name={TaxFormFieldNames.standardFactorsFromTo}
                        component={CubitRadioGroupAdapter}
                        direction="row"
                        labelPlacement="start"
                        options={[
                            {
                                label: tr(t.TEXT_STANDARD_FACTOR),
                                value: 'false',
                            },
                            {
                                label: `${tr(t.TEXT_FROM)} / ${tr(t.TEXT_TO)}`,
                                value: 'true',
                            },
                        ]}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                {!fromToFactors && (
                    <>
                        <TaxSettingsRow classes={classes}>
                            <TwoColumnsRow
                                editMode={editing}
                                classes={classes}
                                content={{
                                    label: rowLabels.label,
                                    value: rowLabels.subLabel,
                                }}
                                isHeader={true}
                            />
                        </TaxSettingsRow>
                        {fields.map((fieldName: string, index: number) => (
                            <TaxSettingsRow classes={classes} key={index}>
                                <TwoColumnsRow
                                    editMode={editing}
                                    classes={classes}
                                    content={{
                                        label: getFieldValue(`${fieldName}.${TaxFormFieldNames.name}`),
                                        value: getFieldValue(`${fieldName}.${TaxFormFieldNames.factor}`),
                                    }}
                                    input={{
                                        label: `${fieldName}.${TaxFormFieldNames.name}`,
                                        value: `${fieldName}.${TaxFormFieldNames.factor}`,
                                    }}
                                    fields={fields}
                                    index={index}
                                    removable={removable}
                                />
                            </TaxSettingsRow>
                        ))}
                    </>
                )}
                {fromToFactors && (
                    <>
                        <TaxSettingsRow classes={classes}>
                            <ThreeColumnsRow
                                editMode={editing}
                                classes={classes}
                                content={{
                                    label: rowLabels.label,
                                    value1: rowLabels.from,
                                    value2: rowLabels.to,
                                }}
                                isHeader={true}
                            />
                        </TaxSettingsRow>
                        {fields.map((fieldName: string, index: number) => (
                            <TaxSettingsRow classes={classes} key={index}>
                                <ThreeColumnsRow
                                    editMode={editing}
                                    classes={classes}
                                    content={{
                                        label: getFieldValue(`${fieldName}.${TaxFormFieldNames.name}`),
                                        value1: getFieldValue(`${fieldName}.${TaxFormFieldNames.from}`),
                                        value2: getFieldValue(`${fieldName}.${TaxFormFieldNames.to}`),
                                    }}
                                    input={{
                                        label: `${fieldName}.${TaxFormFieldNames.name}`,
                                        value1: `${fieldName}.${TaxFormFieldNames.from}`,
                                        value2: `${fieldName}.${TaxFormFieldNames.to}`,
                                    }}
                                    fields={fields}
                                    index={index}
                                    removable={removable}
                                />
                            </TaxSettingsRow>
                        ))}
                    </>
                )}
                {removable && editing && (
                    <Grid container>
                        <Button variant="outlined" onClick={() => fields.push()} className={classes.button}>
                            {tr(t.TEXT_ADD_FACTOR)}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {!hideDivider && <Divider className={classes.innerDivider} />}
                </Grid>
            </Grid>
        </Grid>
    )
}
