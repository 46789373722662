import { ComplaintCauseType } from 'tribunal-page/enums/complaint-cause-type.enum'
import {
    TEXT_TAX_COMPLAINT_CAUSE_1,
    TEXT_TAX_COMPLAINT_CAUSE_2,
    TEXT_TAX_COMPLAINT_CAUSE_3,
    TEXT_TAX_COMPLAINT_CAUSE_4,
    TEXT_TAX_COMPLAINT_CAUSE_5,
    TEXT_TAX_COMPLAINT_CAUSE_6,
    TEXT_TAX_COMPLAINT_CAUSE_7,
} from 'utils/translations/keys'

const complaintCauseTranslationKeysByType = {
    [ComplaintCauseType.PlotArea]: TEXT_TAX_COMPLAINT_CAUSE_1,
    [ComplaintCauseType.PlotType]: TEXT_TAX_COMPLAINT_CAUSE_2,
    [ComplaintCauseType.PlotLocation]: TEXT_TAX_COMPLAINT_CAUSE_3,
    [ComplaintCauseType.BuildingStandard]: TEXT_TAX_COMPLAINT_CAUSE_4,
    [ComplaintCauseType.BuildingArea]: TEXT_TAX_COMPLAINT_CAUSE_5,
    [ComplaintCauseType.BuildingPhotos]: TEXT_TAX_COMPLAINT_CAUSE_6,
    [ComplaintCauseType.Other]: TEXT_TAX_COMPLAINT_CAUSE_7,
}

export default (causeType: ComplaintCauseType): string => complaintCauseTranslationKeysByType[causeType] || ''
