import { AnyAction } from 'redux'
import { Taxation } from 'models/taxation'
import { Attachment, UploadedFile } from 'models/uploaded-file'
import { Correspondance } from 'models/correspondance'

export const ACTION_TAXATION_CREATE = 'ACTION_TAXATION_CREATE'
export const ACTION_TAXATION_CREATE_SUCCESS = 'ACTION_TAXATION_CREATE_SUCCESS'
export const ACTION_TAXATION_GET = 'ACTION_TAXATION_GET'
export const ACTION_TAXATION_GET_SUCCESS = 'ACTION_TAXATION_GET_SUCCESS'
export const ACTION_TAXATION_GET_WITH_HISTORY = 'ACTION_TAXATION_GET_WITH_HISTORY'
export const ACTION_TAXATION_GET_WITH_HISTORY_SUCCESS = 'ACTION_TAXATION_GET_WITH_HISTORY_SUCCESS'
export const ACTION_TAXATION_UPDATE = 'ACTION_TAXATION_UPDATE'
export const ACTION_TAXATION_UPDATE_SUCCESS = 'ACTION_TAXATION_UPDATE_SUCCESS'
export const ACTION_TAXATION_FINISH = 'ACTION_TAXATION_FINISH'
export const ACTION_TAXATION_FINISH_SUCCESS = 'ACTION_TAXATION_FINISH_SUCCESS'
export const ACTION_TAXATION_REOPEN = 'ACTION_TAXATION_REOPEN'
export const ACTION_TAXATION_REOPEN_SUCCESS = 'ACTION_TAXATION_REOPEN_SUCCESS'
export const ACTION_TAXATION_UPLOAD = 'ACTION_TAXATION_UPLOAD'
export const ACTION_TAXATION_UPLOAD_SUCCESS = 'ACTION_TAXATION_UPLOAD_SUCCESS'
export const ACTION_TAXATION_UPDATE_AFTER_COMPLAINT = 'ACTION_TAXATION_UPDATE_AFTER_COMPLAINT'

export const ACTION_LOAD_CORRESPONDANCE = 'ACTION_LOAD_CORRESPONDANCE'
export const ACTION_LOAD_CORRESPONDANCE_SUCCESS = 'ACTION_LOAD_CORRESPONDANCE_SUCCESS'
export const ACTION_LOAD_COMMUNICATION = 'ACTION_LOAD_COMMUNICATION'
export const ACTION_LOAD_COMMUNICATION_SUCCESS = 'ACTION_LOAD_COMMUNICATION_SUCCESS'
export const ACTION_UPLOAD_ATTACHMENT = 'ACTION_UPLOAD_ATTACHMENT'
export const ACTION_UPLOAD_ATTACHMENT_SUCCESS = 'ACTION_UPLOAD_ATTACHMENT_SUCCESS'
export const ACTION_SEND_COMMUNICATION = 'ACTION_SEND_COMMUNICATION'
export const ACTION_SEND_COMMUNICATION_SUCCESS = 'ACTION_SEND_COMMUNICATION_SUCCESS'
export const ACTION_DOCUMENT_SAVE = 'ACTION_DOCUMENT_SAVE'
export const ACTION_DOCUMENT_SAVE_SUCCESS = 'ACTION_DOCUMENT_SAVE_SUCCESS'
export const ACTION_MANUAL_VALUE_SAVE = 'ACTION_MANUAL_VALUE_SAVE'
export const ACTION_MANUAL_VALUE_SAVE_SUCCESS = 'ACTION_MANUAL_VALUE_SAVE_SUCCESS'
export const ACTION_RECALCULATE = 'ACTION_RECALCULATE'

export const actionTaxationCreate = (propertyId: string, taxationId: string): AnyAction => {
    return {
        type: ACTION_TAXATION_CREATE,
        propertyId,
        taxationId,
    }
}

export const actionTaxationCreateSuccess = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_CREATE_SUCCESS,
        taxation,
    }
}

export const actionTaxationGet = (taxationId: string): AnyAction => {
    return {
        type: ACTION_TAXATION_GET,
        taxationId,
    }
}

export const actionTaxationGetSuccess = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_GET_SUCCESS,
        taxation,
    }
}

export const actionTaxationGetWithHistory = (taxationId: string): AnyAction => {
    return {
        type: ACTION_TAXATION_GET_WITH_HISTORY,
        taxationId,
    }
}

export const actionTaxationGetWithHistorySuccess = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_GET_WITH_HISTORY_SUCCESS,
        taxation,
    }
}

export const actionTaxationUpdate = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_UPDATE,
        taxation,
    }
}

export const actionTaxationUpdateAfterComplaint = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_UPDATE_AFTER_COMPLAINT,
        taxation,
    }
}

export const actionTaxationUpdateSuccess = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_UPDATE_SUCCESS,
        taxation,
    }
}

export const actionTaxationFinish = (taxationId: string): AnyAction => {
    return {
        type: ACTION_TAXATION_FINISH,
        taxationId,
    }
}

export const actionTaxationFinishSuccess = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_FINISH_SUCCESS,
        taxation,
    }
}

export const actionTaxationReopen = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_REOPEN,
        taxation,
    }
}

export const actionTaxationReopenSuccess = (taxation: Taxation): AnyAction => {
    return {
        type: ACTION_TAXATION_REOPEN_SUCCESS,
        taxation,
    }
}

export const actionTaxationUploadFiles = (formName: string, fieldName: string, files: any): AnyAction => {
    return {
        type: ACTION_TAXATION_UPLOAD,
        formName,
        fieldName,
        files,
    }
}

export const actionTaxationUploadFilesSuccess = (
    files: UploadedFile[],
    taxationId: string,
    buildingId: string,
): AnyAction => {
    return {
        type: ACTION_TAXATION_UPLOAD_SUCCESS,
        files,
        taxationId,
        buildingId,
    }
}

export const actionLoadCorrespondance = (taxationId: string): AnyAction => {
    return {
        type: ACTION_LOAD_CORRESPONDANCE,
        taxationId,
    }
}

export const actionLoadCorrespondanceSuccess = (taxationId: string, correspondance: Correspondance[]): AnyAction => {
    return {
        type: ACTION_LOAD_CORRESPONDANCE_SUCCESS,
        taxationId,
        correspondance
    }
}

export const actionLoadCommunication = (taxationId: string): AnyAction => {
    return {
        type: ACTION_LOAD_COMMUNICATION,
        taxationId,
    }
}

export const actionLoadCommunicationSuccess = (taxationId: string, communication: any): AnyAction => {
    return {
        type: ACTION_LOAD_COMMUNICATION_SUCCESS,
        taxationId,
        communication
    }
}

export const actionUploadAttachment = (files: any, formName: string, fieldName: string): AnyAction => {
    return {
        type: ACTION_UPLOAD_ATTACHMENT,
        files: files,
        formName,
        fieldName
    }
}

export const actionUploadAttachmentSuccess = (attachments: Attachment[], taxationId: string): AnyAction => {
    return {
        type: ACTION_UPLOAD_ATTACHMENT_SUCCESS,
        attachments: attachments,
        taxationId
    }
}

export const actionSendCommunication = (entry: string): AnyAction => {
    return {
        type: ACTION_SEND_COMMUNICATION,
        entry,
    }
}

export const actionSendCommunicationSuccess = (entries: any): AnyAction => {
    return {
        type: ACTION_SEND_COMMUNICATION_SUCCESS,
        entries
    }
}

export const actionDocumentSave = (id: string, file: any) => ({
    type: ACTION_DOCUMENT_SAVE,
    id,
    file,
})

export const actionDocumentSaveSuccess = (id: string, file: UploadedFile) => ({
    type: ACTION_DOCUMENT_SAVE_SUCCESS,
    id,
    file,
})

export const actionManualClose = (id: string, value: number, comment: string, manualKoValue: number) => ({
    type: ACTION_MANUAL_VALUE_SAVE,
    id,
    value,
    comment,
    manualKoValue
})

export const actionManualCloseSuccess = (id: string, value: number, comment: string, manualKoValue: number) => ({
    type: ACTION_MANUAL_VALUE_SAVE_SUCCESS,
    id,
    value,
    comment,
    manualKoValue
})

export const actionRecalculate = (propertyId: string | undefined) => ({
    type: ACTION_RECALCULATE,
    propertyId,
})
