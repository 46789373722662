import React, { useState } from 'react'
import { Grid, IconButton, Divider, Button } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import * as t from 'utils/translations/keys'
import { Field, formValueSelector, FieldArray } from 'redux-form'
import { Create } from '@material-ui/icons'
import { TaxFormFieldNames, TaxFormName } from 'common/enums/form-name.enum'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitRadioGroupAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { TaxSettingsRow } from 'settings-tax-page/TaxSettingsRow'
import { ColumnText, RemoveField, TwoColumnsRow } from 'settings-tax-page/helper-functions'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'

export const RenderFloorFactors = (props: any) => {
    const { classes, editing, rowLabels, fields, getFieldValue } = props
    return (
        <Grid item xs={12}>
            <TaxSettingsRow classes={classes}>
                <TwoColumnsRow
                    editMode={editing}
                    classes={classes}
                    content={{
                        label: rowLabels.label,
                        value: rowLabels.subLabel,
                    }}
                    isHeader={true}
                />
            </TaxSettingsRow>
            {fields.map((fieldName: string, index: number) => (
                <TaxSettingsRow classes={classes} key={index}>
                    <TwoColumnsRow
                        editMode={editing}
                        classes={classes}
                        content={{
                            label: getFieldValue(`${fieldName}.${TaxFormFieldNames.name}`),
                            value: getFieldValue(`${fieldName}.${TaxFormFieldNames.factor}`),
                        }}
                        input={{
                            label: `${fieldName}.${TaxFormFieldNames.name}`,
                            value: `${fieldName}.${TaxFormFieldNames.factor}`,
                        }}
                        fields={fields}
                        index={index}
                        removable={true}
                    />
                </TaxSettingsRow>
            ))}
            {editing && (
                <Grid container>
                    <Button variant="outlined" onClick={() => fields.push()} className={classes.button}>
                        {tr(t.TEXT_ADD_FACTOR)}
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export const RenderAreaFactors = (props: any) => {
    const { classes, editing, rowLabels, fields, getFieldValue } = props
    return (
        <Grid item xs={12}>
            <Grid container>
                {rowLabels.map((label: string, index: number) => (
                    <Grid key={index} item md={2} sm={3} xs={6} className={classes.columnPadding}>
                        <ColumnText text={label} className={classes.settingsLabel} />
                    </Grid>
                ))}
            </Grid>

            {fields.map((fieldName: string, index: number) => (
                <TaxSettingsRow classes={classes} key={index}>
                    <Grid container>
                        <Grid item md={2} sm={3} xs={6} className={classes.columnPadding}>
                            {!editing && (
                                <ColumnText
                                    text={getFieldValue(`${fieldName}.${TaxFormFieldNames.from}`)}
                                    className={classes.itemPaddding}
                                />
                            )}
                            {editing && (
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.from}`}
                                    component={CubitTextFieldAdapter}
                                    className={classes.fromToTextInput}
                                />
                            )}
                        </Grid>
                        <Grid item md={2} sm={3} xs={6}>
                            {!editing && (
                                <ColumnText
                                    text={getFieldValue(`${fieldName}.${TaxFormFieldNames.to}`)}
                                    className={classes.itemPaddding}
                                />
                            )}
                            {editing && (
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.to}`}
                                    component={CubitTextFieldAdapter}
                                    className={classes.fromToTextInput}
                                />
                            )}
                        </Grid>
                        <Grid item md={1} sm={2} xs={6}>
                            {!editing && (
                                <ColumnText
                                    text={getFieldValue(`${fieldName}.${TaxFormFieldNames.factor}`)}
                                    className={classes.itemPaddding}
                                />
                            )}
                            {editing && (
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.factor}`}
                                    component={CubitTextFieldAdapter}
                                    className={classes.shortTextInput}
                                />
                            )}
                        </Grid>
                        {editing && (
                            <Grid item xs={1}>
                                <RemoveField fields={fields} index={index} />
                            </Grid>
                        )}
                    </Grid>
                </TaxSettingsRow>
            ))}
            {editing && (
                <Grid container>
                    <Button variant="outlined" onClick={() => fields.push()} className={classes.button}>
                        {tr(t.TEXT_ADD_FACTOR)}
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

const RenderAreaFactorsPrice = (props: any) => {
    const { classes, editing, rowLabels, fields, getFieldValue } = props
    return (
        <Grid item xs={12}>
            <Grid container>
                {rowLabels.map((label: string, index: number) => (
                    <Grid key={index} item md={2} sm={3} xs={6} className={classes.columnPadding}>
                        <ColumnText text={label} className={classes.settingsLabel} />
                    </Grid>
                ))}
            </Grid>

            {fields.map((fieldName: string, index: number) => (
                <TaxSettingsRow classes={classes} key={index}>
                    <Grid container>
                    <Grid item md={2} sm={3} xs={6} className={classes.columnPadding}>
                            {!editing && (
                                <ColumnText
                                    text={getFieldValue(`${fieldName}.${TaxFormFieldNames.name}`)}
                                    className={classes.itemPaddding}
                                />
                            )}
                            {editing && (
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.name}`}
                                    component={CubitTextFieldAdapter}
                                    className={classes.fromToTextInput}
                                />
                            )}
                        </Grid>
                        <Grid item md={2} sm={3} xs={6} className={classes.columnPadding}>
                            {!editing && (
                                <ColumnText
                                    text={getFieldValue(`${fieldName}.${TaxFormFieldNames.from}`)}
                                    className={classes.itemPaddding}
                                />
                            )}
                            {editing && (
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.from}`}
                                    component={CubitTextFieldAdapter}
                                    className={classes.fromToTextInput}
                                />
                            )}
                        </Grid>
                        <Grid item md={2} sm={3} xs={6}>
                            {!editing && (
                                <ColumnText
                                    text={getFieldValue(`${fieldName}.${TaxFormFieldNames.to}`)}
                                    className={classes.itemPaddding}
                                />
                            )}
                            {editing && (
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.to}`}
                                    component={CubitTextFieldAdapter}
                                    className={classes.fromToTextInput}
                                />
                            )}
                        </Grid>
                        <Grid item md={1} sm={2} xs={6}>
                            {!editing && (
                                <ColumnText
                                    text={getFieldValue(`${fieldName}.${TaxFormFieldNames.price}`)}
                                    className={classes.itemPaddding}
                                />
                            )}
                            {editing && (
                                <Field
                                    name={`${fieldName}.${TaxFormFieldNames.price}`}
                                    component={CubitTextFieldAdapter}
                                    className={classes.shortTextInput}
                                />
                            )}
                        </Grid>
                        {editing && (
                            <Grid item xs={1}>
                                <RemoveField fields={fields} index={index} />
                            </Grid>
                        )}
                    </Grid>
                </TaxSettingsRow>
            ))}
            {editing && (
                <Grid container>
                    <Button variant="outlined" onClick={() => fields.push()} className={classes.button}>
                        {tr(t.TEXT_ADD_FACTOR)}
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export const AreaFloorFactors = (props: any) => {
    const { classes, stateRef, readOnly, canCancel } = props
    const selector = formValueSelector(TaxFormName.TaxationSettings)
    const getFieldValue = (fieldName: string) => selector(stateRef, fieldName)
    const [editing, setEditing] = useState(false)
    const resetEdit = useSelector((state: AppState) => state.taxSettings.taxFormReset)
    if (resetEdit && editing) {
        setEditing(false)
    }
    const areaFactorsSelected = getFieldValue(TaxFormFieldNames.useAreaFactors)
    const useAreaFactorsPrice = getFieldValue(TaxFormFieldNames.useAreaFactorsPrice)
    return (
        <Grid container className={classes.settingsRow}>
            {!editing && !readOnly && (
                <Grid item className={classes.editButton}>
                    <IconButton
                        onClick={() => {
                            setEditing(true)
                            canCancel(true)
                        }}
                    >
                        <Create />
                    </IconButton>
                </Grid>
            )}
            {editing && (
                <Grid item xs={12}>
                    <Field
                        name={TaxFormFieldNames.useAreaFactors}
                        component={CubitRadioGroupAdapter}
                        direction="row"
                        labelPlacement="start"
                        options={[
                            {
                                label: tr(t.TEXT_USE_FLOOR_FACTOR),
                                value: 'false',
                            },
                            {
                                label: tr(t.TEXT_USE_AREA_FACTOR),
                                value: 'true',
                            },
                        ]}
                    />
                    {areaFactorsSelected && (
                        <Field
                            name={TaxFormFieldNames.useAreaFactorsPrice}
                            component={CubitRadioGroupAdapter}
                            direction="row"
                            labelPlacement="start"
                            options={[
                                {
                                    label: tr(t.TEXT_USE_FLOOR_FACTOR),
                                    value: 'false',
                                },
                                {
                                    label: tr(t.TEXT_M2_PRICE),
                                    value: 'true',
                                },
                            ]}
                        />
                    )}
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container>
                    {!areaFactorsSelected && (
                        <FieldArray
                            name={TaxFormFieldNames.floorFactors}
                            component={RenderFloorFactors}
                            classes={classes}
                            stateRef={stateRef}
                            rowLabels={{
                                label: tr(t.TEXT_FLOOR_USE),
                                subLabel: tr(t.TEXT_FACTOR),
                            }}
                            getFieldValue={getFieldValue}
                            editing={editing}
                        />
                    )}
                    {areaFactorsSelected && useAreaFactorsPrice && (
                        <FieldArray
                            name={TaxFormFieldNames.areaFactors}
                            component={RenderAreaFactorsPrice}
                            classes={classes}
                            stateRef={stateRef}
                            rowLabels={[tr(t.TEXT_FACTOR), tr(t.TEXT_FROM_AREA), tr(t.TEXT_TO_AREA), tr(t.TEXT_M2_PRICE)]}
                            getFieldValue={getFieldValue}
                            editing={editing}
                        />
                    )}
                    {areaFactorsSelected && !useAreaFactorsPrice && (
                        <FieldArray
                            name={TaxFormFieldNames.areaFactors}
                            component={RenderAreaFactors}
                            classes={classes}
                            stateRef={stateRef}
                            rowLabels={[tr(t.TEXT_FROM_AREA), tr(t.TEXT_TO_AREA), tr(t.TEXT_FACTOR)]}
                            getFieldValue={getFieldValue}
                            editing={editing}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.innerDivider} />
            </Grid>
        </Grid>
    )
}
