import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCubitTableClearSelection } from 'shared-components/src/cubit-table/cubit-table-actions'
import ContextBar from 'common/context-bar/context-bar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {
    TEXT_SELECTED,
    TEXT_TAXATION,
    TEXT_SET_AS_INCOMPETENT,
    TEXT_REMOVE_AS_INCOMPETENT,
    TEXT_APPROVE_TAXATION,
} from 'utils/translations/keys'
import { tr } from 'utils/translations/translate'
import ClearIcon from '@material-ui/icons/Clear'
import { AppState } from 'app/app-store'
import { Button } from '@material-ui/core'

import {
    actionTribunalTaxationsMarkAsConflicted,
    actionTribunalTaxationsReschedule,
    actionTribunalTaxationsRemoveAsConflicted,
    actionTribunalTaxationsMarkAsFinished,
} from 'tribunal-page/tribunal-actions'
import { TribunalGroupState } from './tribunal-group-state.enum'
import { TribunalAssignToGroup } from 'tribunal-page/tribunal-assign-to-group/tribunal-assign-to-group'
import { PageReference } from 'tribunal-page/page-reference.enum'
import { TribunalGroupTablePrefix } from './tribunal-group-taxations-table'
import { UserSelection } from 'common/user-selection/user-selection'
import { sortBy } from 'lodash'
import { useUsers } from 'app/app-selectors'
import { useUserId } from 'auth/auth-selectors'
import { IntermediateTribunalGroupState } from './intermediate-tribunal-group-state.enum'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { TribunalTaxationDecline } from 'tribunal-taxation-details-page/tribunal-taxation-decline/tribunal-taxation-decline'

export const TribunalGroupPageContextBar: React.FC<{
    groupId: string
    groupState: TribunalGroupState
    intermediateGroupState: IntermediateTribunalGroupState | null
}> = props => {
    const dispatch = useDispatch()
    const { groupId, groupState, intermediateGroupState } = props

    const tableName = TribunalGroupTablePrefix + groupId

    const currentUserId = useUserId()
    const users = useUsers()
    const usersArray = sortBy(
        sortBy(
            Object.keys(users).map(key => users[key]),
            u => u.name,
        ),
        u => u.id !== currentUserId,
    )

    const selectedTaxationsIds: string[] = useSelector(
        (state: AppState) => (state.table[tableName] && state.table[tableName].selection) || [],
    )

    const selectedGroupIds: string[] = useSelector(
        (state: AppState) => (state.table['approvedTribunal'] && state.table['approvedTribunal'].selection) || [],
    )
    if (selectedGroupIds) {
        console.log('ids', selectedGroupIds)
    }

    const clearSelectedItemsHandler = (tableName: string) => {
        dispatch(actionCubitTableClearSelection(tableName))
    }

    const handleRemoveAsConflicted = () => {
        dispatch(actionTribunalTaxationsRemoveAsConflicted(currentUserId, groupId, selectedTaxationsIds))
    }

    const handleMarkAsConflicted = () => {
        dispatch(actionTribunalTaxationsMarkAsConflicted(currentUserId, groupId, selectedTaxationsIds))
    }

    const handleRescheduleTaxation = (userId: string) => {
        if (!userId) {
            return
        }

        dispatch(actionTribunalTaxationsReschedule(userId, groupId, selectedTaxationsIds))
    }

    return selectedTaxationsIds.length > 0 ? (
        <ContextBar>
            <Grid style={{ flexGrow: 1 }} container alignItems="center" spacing={2}>
                <Grid item>
                    <IconButton
                        onClick={() => clearSelectedItemsHandler(tableName)}
                        color="inherit"
                        edge="start"
                        aria-label="Clear"
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>
                <Grid style={{ paddingLeft: '36px' }} item xs>
                    <Typography variant="h6">
                        {`${selectedTaxationsIds.length} ${tr(TEXT_TAXATION)} ${tr(TEXT_SELECTED)}`.toLowerCase()}{' '}
                    </Typography>
                </Grid>

                {groupState === TribunalGroupState.Initial && (
                    <Grid item>
                        <TribunalAssignToGroup
                            groupId={groupId}
                            taxationsIds={selectedTaxationsIds}
                            pageReference={PageReference.TribunalGroupPage}
                        ></TribunalAssignToGroup>
                    </Grid>
                )}

                {groupState === TribunalGroupState.Started && (
                    <Protected p={Permission.TribunalApproveDecline}>
                        <Grid item>
                            <TribunalTaxationDecline
                                groupId={groupId}
                                taxationsIds={selectedTaxationsIds}
                                pageReference={PageReference.TribunalGroupPage}
                            ></TribunalTaxationDecline>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="outlined"
                                color="inherit"
                                type="button"
                                onClick={() => dispatch(actionTribunalTaxationsMarkAsFinished(groupId, selectedTaxationsIds))}
                            >
                                {tr(TEXT_APPROVE_TAXATION)}
                            </Button>
                        </Grid>
                    </Protected>
                )}

                {intermediateGroupState === IntermediateTribunalGroupState.PlannedConflictsReview && (
                    <>
                        <Grid item>
                            <Button color="inherit" type="button" onClick={handleRemoveAsConflicted}>
                                {tr(TEXT_REMOVE_AS_INCOMPETENT)}
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant="outlined" color="inherit" type="button" onClick={handleMarkAsConflicted}>
                                {tr(TEXT_SET_AS_INCOMPETENT)}
                            </Button>
                        </Grid>
                    </>
                )}

                {groupState === TribunalGroupState.Declined && (
                    <Grid item>
                        <UserSelection usersArray={usersArray} onChange={handleRescheduleTaxation}></UserSelection>
                    </Grid>
                )}
            </Grid>
        </ContextBar>
    ) : null
}
