import { AnyAction } from 'redux'
import { Complaint } from 'tribunal-page/models/complaint.type'

export const ACTION_GET_COMPLAINTS = 'ACTION_GET_COMPLAINTS'
export const ACTION_GET_COMPLAINTS_SUCCESS = 'ACTION_GET_COMPLAINTS_SUCCESS'
export const ACTION_UPDATE_COMPLAINT = 'ACTION_UPDATE_COMPLAINT'
export const ACTION_UPDATE_COMPLAINT_SUCCESS = 'ACTION_UPDATE_COMPLAINT_SUCCESS'
export const ACTION_SET_COMPLAINT_VALUE = 'ACTION_SET_COMPLAINT_VALUE'

export const actionGetComplaints = (taxationId: string): AnyAction => {
    return {
        type: ACTION_GET_COMPLAINTS,
        taxationId,
    }
}

export const actionGetComplaintsSuccess = (complaints: Complaint[], id: string): AnyAction => {
    return {
        type: ACTION_GET_COMPLAINTS_SUCCESS,
        complaints,
        id: id,
    }
}

export const actionComplaintUpdate = (complaint: Complaint): AnyAction => {
    return {
        type: ACTION_UPDATE_COMPLAINT,
        complaint,
    }
}

export const actionComplaintUpdateSuccess = (complaint: Complaint): AnyAction => {
    return {
        type: ACTION_UPDATE_COMPLAINT_SUCCESS,
        complaint,
    }
}

export const actionComplaintSetValue = (complaint: Complaint): AnyAction => {
    return {
        type: ACTION_SET_COMPLAINT_VALUE,
        complaint,
    }
}