import { combineEpics } from 'redux-observable'
import { catchError, filter, mergeMap, map, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import {
    ACTION_AUTH_INIT,
    ACTION_AUTH_LOGIN,
    ACTION_AUTH_LOGIN_SUCCESS,
    ACTION_AUTH_LOGOUT,
    actionAuthInitialized,
    actionAuthLoginError,
    actionAuthLoginSuccess,
} from './auth-actions'
import { EMPTY, Observable, of } from 'rxjs'
import { httpPost, lanternUrl } from '../services/httpService'
import { getSessionKey, removeSessionKey, setSessionKey } from 'utils'
import { actionLoadAppData, actionLoadCompaniesAndUsers, actionLoadRoles } from 'app/app-actions'
import { navigate } from '@reach/router'
import { actionPlanningGetUnplannedEvents } from 'planning-page/planning-actions'

export const authLoginEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGIN),
        mergeMap(
            (action): Observable<AnyAction> =>
                httpPost(lanternUrl('/signin'), action.request).pipe(
                    map(data => actionAuthLoginSuccess(data)),
                    catchError(error => of(actionAuthLoginError())),
                ),
        ),
    )

export const authLoginSuccessEpic = (action$: Observable<AnyAction>, state$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGIN_SUCCESS),
        withLatestFrom(state$),
        mergeMap(
            ([action, state]): Observable<AnyAction> => {
                setSessionKey(action.data.session.sessionKey)
                return of(
                    actionLoadCompaniesAndUsers(),
                    actionLoadAppData(),
                    actionLoadRoles(),
                    actionPlanningGetUnplannedEvents(state.auth.user ? state.auth.user.id : '')
                )
            },
        ),
    )

export const authLogoutEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGOUT),
        mergeMap(
            (action): Observable<AnyAction> => {
                removeSessionKey()
                navigate('/')
                return EMPTY
            },
        ),
    )

export const authInitEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_INIT),
        mergeMap(
            (action): Observable<AnyAction> => {
                const sessionKey = getSessionKey()
                if (sessionKey === undefined) {
                    return of(actionAuthInitialized())
                } else {
                    return httpPost(lanternUrl('/signin'), { sessionKey }).pipe(
                        map((data) => {
                            if (action.refresh) {
                                navigate('/')
                            }
                            return actionAuthLoginSuccess(data)
                        }),
                        catchError(error => of(actionAuthInitialized())),
                    )
                }
            },
        ),
    )

export const authEpics = combineEpics(authInitEpic, authLoginSuccessEpic, authLoginEpic, authLogoutEpic)
