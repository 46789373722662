import { makeReducer } from '../utils'
import { AnyAction } from 'redux'
import { CalendarView } from './calendar'
import {
    ACTION_CUBIT_CALENDAR_NEXT_RANGE,
    ACTION_CUBIT_CALENDAR_PREVIOUS_RANGE,
    ACTION_CUBIT_CALENDAR_SET_DATE,
    ACTION_CUBIT_CALENDAR_SET_DRAGGING,
    ACTION_CUBIT_CALENDAR_SET_SHOW_WEEKENDS,
    ACTION_CUBIT_CALENDAR_SET_VIEW,
} from './calendar-actions'
import { ActionReducer } from '../utils/make-reducer'

export type CubitCalendarState = {
    view: CalendarView
    date: string
    showWeekends: boolean
    isDragging: boolean
}

type CubitTableReducer = ActionReducer<CubitCalendarState, AnyAction>

const initialState: CubitCalendarState = {
    view: window.innerWidth < 960 ? CalendarView.Day : CalendarView.Week,
    date: new Date().toString(),
    showWeekends: false,
    isDragging: false,
}

const setView: CubitTableReducer = (state, action) => {
    state.view = action.view
    return state
}

const setDate: CubitTableReducer = (state, action) => {
    state.date = action.date
    return state
}

const setShowWeekends: CubitTableReducer = (state, action) => {
    state.showWeekends = action.showWeekends
    return state
}

const setDragging: CubitTableReducer = (state, action) => {
    state.isDragging = action.isDragging
    return state
}

const nextRange: CubitTableReducer = (state, action) => {
    return state
}

const previousRange: CubitTableReducer = (state, action) => {
    return state
}

const reducers = {
    [ACTION_CUBIT_CALENDAR_SET_VIEW]: setView,
    [ACTION_CUBIT_CALENDAR_SET_DATE]: setDate,
    [ACTION_CUBIT_CALENDAR_SET_SHOW_WEEKENDS]: setShowWeekends,
    [ACTION_CUBIT_CALENDAR_SET_DRAGGING]: setDragging,
    [ACTION_CUBIT_CALENDAR_NEXT_RANGE]: nextRange,
    [ACTION_CUBIT_CALENDAR_PREVIOUS_RANGE]: previousRange,
}

export const calendarReducer = makeReducer(reducers, initialState)
