import React from 'react'
import { Done } from '@material-ui/icons'
import { Tooltip, Box } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_VERIFIED, TEXT_OF } from 'utils/translations/keys'

type VerifiedCountProps = {
    count: number
    total: number
    style?: React.CSSProperties
}

export const VerifiedCount: React.FC<VerifiedCountProps> = props => {
    const { count, total, style } = props

    let result: JSX.Element

    if (total) {
        if (count === total) {
            result = <Done />
        } else {
            result = (
                <>
                    {count}/ {total}
                </>
            )
        }

        const tooltipText = `${tr(TEXT_VERIFIED)} ${count} ${tr(TEXT_OF)} ${total}`

        return (
            <Tooltip title={tooltipText}>
                <Box style={{ ...style, cursor: 'default' }}>{result}</Box>
            </Tooltip>
        )
    } else {
        result = <></>
    }

    return result
}
