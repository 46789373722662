import { Form, Field } from 'react-final-form'
import React, { useEffect, useRef, useState } from 'react'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import {
    TEXT_ADDRESS,
    TEXT_CANCEL,
    TEXT_DOCUMENT_RECEIVER,
    TEXT_EMAIL,
    TEXT_INVOICE_RECEIVER,
    TEXT_NAME,
    TEXT_PERSONAL_NR,
    TEXT_PHONE_NUMBER,
    TEXT_SAVE,
    TEXT_TITLE,
    TEXT_ZIP_CODE,
} from 'utils/translations/keys'
import { getYesNoBoolOptions, tr } from 'utils/translations/translate'
import { TaxFormFieldNames } from 'common/enums/form-name.enum'
import { composeValidators, isOrgOrPersonalNumber, required } from 'utils/form/form-utils'
import { CubitSwitchAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-switch-adapter'
import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles, Theme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { actionPropertyAddOwnership, actionPropertyGetContactInfo } from 'property-page/property-actions'
import { AppState } from 'app/app-store'
const useStyles = makeStyles((theme: Theme) => ({
    field: {
        marginBottom: '5px',
        marginTop: '5px',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        maxWidth: '100%'
    }
}))
export const AddOwner: React.FC<{ handleClose: any; open: boolean; id: string; propertyId: string }> = ({
    handleClose,
    open,
    id,
    propertyId,
}) => {
    const dispatch = useDispatch()
    const contactInfo = useSelector((state: AppState) => state.properties[propertyId]?.contactInfo)
    const formRef = useRef<any>(null)
    const [addOwnerDialogOpen, setAddOwnerDialogOpen] = useState(false)
    const onSubmit = (values: any) => {
        dispatch(actionPropertyAddOwnership(values, propertyId))
        handleClose()
    }
    // @ts-ignore
    const update = ([name, value], state, { changeValue }) => {
        changeValue(state, name, () => value)
    }
    useEffect(() => {
        if (open) {
            setAddOwnerDialogOpen(true)
        } else {
            setAddOwnerDialogOpen(false)
        }
    }, [open])
    useEffect(() => {
        if (contactInfo) {
            formRef?.current.mutators.update('email', contactInfo?.email)
            formRef?.current.mutators.update('phone', contactInfo?.phone)
        }
    }, [contactInfo])

    const styles = useStyles()
    return (
            <Dialog open={addOwnerDialogOpen}>
                <DialogTitle>{tr(TEXT_DOCUMENT_RECEIVER)}</DialogTitle>
                <DialogContent style={{ width: '480px' }}>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            isInvoiceRecipient: false,
                            isDocumentRecipient: false,
                        }}
                        mutators={{ update }}
                        render={({ submitting, pristine, form, handleSubmit, values }) => {
                            formRef.current = form
                            return (
                                <form id={id} onSubmit={handleSubmit}>
                                    <Field
                                        name={'title'}
                                        component={CubitTextFieldAdapter}
                                        type="text"
                                        label={tr(TEXT_TITLE)}
                                        multiline
                                        className={styles.field}
                                    />
                                    <Field
                                        name={'number'}
                                        component={CubitTextFieldAdapter}
                                        type="text"
                                        validate={composeValidators(required, isOrgOrPersonalNumber)}
                                        label={tr(TEXT_PERSONAL_NR)}
                                        multiline
                                        className={styles.field}
                                        customOnBlur={(e: any) => {
                                            if (!isOrgOrPersonalNumber(values.number) || !required) {
                                                dispatch(actionPropertyGetContactInfo(propertyId, values.number))
                                            }
                                        }}
                                    />
                                    <Field
                                        name={'name'}
                                        component={CubitTextFieldAdapter}
                                        type="text"
                                        label={tr(TEXT_NAME)}
                                        multiline
                                        className={styles.field}
                                    />
                                    <Field
                                        name={'addressText'}
                                        component={CubitTextFieldAdapter}
                                        type="text"
                                        label={tr(TEXT_ADDRESS)}
                                        multiline
                                        className={styles.field}
                                    />
                                    <Field
                                        name={'postalCode'}
                                        component={CubitTextFieldAdapter}
                                        type="text"
                                        label={tr(TEXT_ZIP_CODE)}
                                        multiline
                                        className={styles.field}
                                    />
                                    <Field
                                        name={'email'}
                                        component={CubitTextFieldAdapter}
                                        type="text"
                                        label={tr(TEXT_EMAIL)}
                                        multiline
                                        className={styles.field}
                                    />
                                    <Field
                                        name={'phone'}
                                        component={CubitTextFieldAdapter}
                                        type="text"
                                        label={tr(TEXT_PHONE_NUMBER)}
                                        multiline
                                        className={styles.field}
                                    />
                                    <Grid item xs={6} className={styles.field}>
                                        {tr(TEXT_DOCUMENT_RECEIVER)}
                                    </Grid>
                                    <Field
                                        name={`${TaxFormFieldNames.isDocumentRecipient}`}
                                        component={CubitSwitchAdapter}
                                        options={getYesNoBoolOptions()}
                                        direction="row"
                                        className={styles.field}
                                    />

                                    <Grid item xs={6} className={styles.field}>
                                        {tr(TEXT_INVOICE_RECEIVER)}
                                    </Grid>

                                    <Field
                                        name={`${TaxFormFieldNames.isInvoiceRecipient}`}
                                        component={CubitSwitchAdapter}
                                        options={getYesNoBoolOptions()}
                                        direction="row"
                                    />
                                    <Grid justifyContent="flex-end" item xs={6} className={styles.buttons}>
                                        <Button onClick={handleClose} color="inherit">
                                            {tr(TEXT_CANCEL)}
                                        </Button>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            disabled={submitting || pristine}
                                            autoFocus
                                        >
                                            {tr(TEXT_SAVE)}
                                        </Button>
                                    </Grid>
                                </form>
                            )
                        }}
                    />
                </DialogContent>
            </Dialog>
    )
}
